export const convertLanguageCode = (code: string): AppLanguage => {
  switch (code) {
    case 'ja':
      return APP_LANGUAGES.ja;
    case 'th':
      return APP_LANGUAGES.th;
    case 'id':
      return APP_LANGUAGES.id;
    case 'vi':
      return APP_LANGUAGES.vi;
    case 'zh-TW':
    case 'zh-HK':
    case 'zh':
      return APP_LANGUAGES.tw;
    case 'zh-CN':
      return APP_LANGUAGES.ch;
    case 'km':
      return APP_LANGUAGES.km;
    case 'my':
      return APP_LANGUAGES.my;
    case 'ar':
      return APP_LANGUAGES.ar;
    default:
      return APP_LANGUAGES.en;
  }
};

export const APP_LANGUAGES = {
  en: 'en',
  ja: 'ja',
  vi: 'vi',
  th: 'th',
  tw: 'tw',
  ch: 'ch',
  km: 'km',
  my: 'my',
  id: 'id',
  ar: 'ar',
  pseudo: 'en-JA',
} as const;

export type AppLanguage = (typeof APP_LANGUAGES)[keyof typeof APP_LANGUAGES];
