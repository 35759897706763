import * as React from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { css } from '@emotion/css';
import closeIcon from '@src/assets/icons/close.svg';
import errorToastify from '@src/assets/icons/errorToastify.svg';
import warningIcon from '@src/assets/icons/warningOrange.svg';
import { TextCutter } from '@src/components/atoms';
import 'react-toastify/dist/ReactToastify.css';

const Notification = () => (
  <ToastContainer
    className={classnames.root}
    closeButton={() => <img alt="closeIcon" src={closeIcon} style={{ filter: 'contrast(0.5)' }} />}
    closeOnClick
    draggable
    hideProgressBar
    limit={3}
    position="top-right"
  />
);

export const customIcons = {
  [toast.TYPE.SUCCESS]: { icon: undefined, header: 'Success' },
  [toast.TYPE.ERROR]: { icon: <img src={errorToastify} alt="error icon" width={24} height={24} />, header: 'Error' },
  [toast.TYPE.INFO]: { icon: undefined, header: 'Information' },
  [toast.TYPE.WARNING]: {
    icon: <img src={warningIcon} alt="warning icon" width={24} height={24} />,
    header: 'Warning',
  },
  [toast.TYPE.DEFAULT]: { icon: undefined, header: '' },
};

interface ToastWrapperProps {
  header: string;
  message: string;
}

export const ToastWrapper = ({ header, message }: ToastWrapperProps) => (
  <div>
    {header ? <h3 className={classnames.toastHeader}>{header}</h3> : null}
    <TextCutter lines={2} text={message} className={classnames.toastContent} />
  </div>
);

const classnames = {
  root: css({
    '.Toastify__toast-body': {
      padding: 0,
    },

    '.Toastify__toast--error': {
      borderLeft: '3px solid #ff5f5f',
    },

    '.Toastify__toast--warning': {
      borderLeft: '3px solid #ffb63d',
    },

    '.Toastify__toast--success': {
      borderLeft: '3px solid #40b87c',
    },

    '.Toastify__toast--info': {
      borderLeft: '3px solid #3892e5',
    },
  }),
  toastHeader: css({
    color: '#27313b',
    fontSize: 14,
    fontWeight: 600,
    margin: 0,
  }),
  toastContent: css({
    color: '#27313b',
    fontSize: 12,
    margin: 0,
  }),
};

export default Notification;
