import { useMediaQuery } from 'react-responsive';
import { ViewportType } from '@src/libs/theme';

const usePageLayout = () => {
  const isMobileView = useMediaQuery({ maxWidth: ViewportType.TABLET });

  return { isMobileView };
};

export default usePageLayout;
