import { css } from '@emotion/css';
import * as React from 'react';
import logo from '@src/assets/images/anyCreatorWhite.png';

const InitialLoading = () => (
  <div className={classes.wrapper}>
    <img src={logo} width="185px" alt="" />
    <span className={classes.text}>Loading...</span>
  </div>
);

const classes = {
  text: css({
    marginTop: 16,
    fontSize: 14,
    lineHeight: 1.29,
    color: '#fff',
  }),
  wrapper: css({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: 1000,
    background: '#27313b',
  }),
};

export default InitialLoading;
