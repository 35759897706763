import React from 'react';
import styled from '@emotion/styled';

interface EmptyProps {
  className?: string;
  lines?: number;
  text: string | null;
  onClick?: () => void;
}
const TextCutter = ({
  className, lines, text, onClick,
}: EmptyProps) => (
  <Wrapper className={className} lines={lines} title={text || ''} onClick={onClick}>
    {text}
  </Wrapper>
);

const Wrapper = styled.p<{ lines?: number }>(({ lines }) => ({
  display: '-webkit-box',
  height: 'fit-content',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  wordBreak: 'break-all',
  '-webkit-line-clamp': lines || 1,
  '-webkit-box-orient': 'vertical',
}));

export default TextCutter;
