import React from 'react';
import { css } from '@emotion/css';
import styled from '@emotion/styled';

interface AvatarProps {
  size?: number;
  src?: string;
  title?: string;
}

const Avatar = ({ size = 50, src, title }: AvatarProps) => {
  const name = title ? title.charAt(0).toUpperCase() : '';
  const colors = [
    '#f6f8fa',
    '#ffb900',
    '#d83b01',
    '#b50e0e',
    '#e81123',
    '#b4009e',
    '#5c2d91',
    '#0078d7',
    '#00b4ff',
    '#008272',
    '#107c10',
  ];

  function calculateColor(value: string) {
    let sum = 0;
    for (let i = 0; i < value.length; i++) {
      sum += value.charCodeAt(i);
    }

    return colors[sum % colors.length];
  }

  return src ? (
    <img alt="avatar" className={classnames.avatar} height={size} src={src} width={size} />
  ) : (
    <AvatarTitle color={calculateColor(title || '')} size={size}>
      {name}
    </AvatarTitle>
  );
};

const AvatarTitle = styled.div<{ color: string; size: number }>(({ color, size }) => ({
  alignItems: 'center',
  backgroundColor: color,
  borderRadius: '50%',
  color: '#fff',
  display: 'flex',
  fontSize: Math.min(size - 15, 35),
  height: size,
  justifyContent: 'center',
  width: size,
}));

const classnames = {
  avatar: css({
    borderRadius: '50%',
  }),
};

export default Avatar;
