import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { css } from '@emotion/css';
import pageNotFound from '@src/assets/images/linkInBio/pageNotFound.svg';
import { anyCreatorUrl } from '@src/libs/constant';
import { usePageLayout } from '@src/libs/hooks';

const PageNotFound = () => {
  const { t } = useTranslation();
  const { isMobileView } = usePageLayout();
  const size = isMobileView ? 148 : 180;

  return (
    <div className={classes.container}>
      <div>
        <div>
          <img alt="img" height={size} src={pageNotFound} width={size} />
        </div>
        <span>{t('Annotation.The page cannot be displayed')}</span>
        <Link
          className={classes.createdBy}
          rel="noopener noreferrer"
          target="_blank"
          to={anyCreatorUrl}
        >
          Creater by AnyCreator
        </Link>
      </div>
    </div>
  );
};

const classes = {
  createdBy: css({
    color: '#27313b',
    fontSize: 10,
  }),
  container: css({
    alignItems: 'center',
    display: 'flex',
    height: '100vh',
    justifyContent: 'center',

    '& > div': {
      display: 'grid',
      justifyContent: 'center',
      textAlign: 'center',

      '& > span': {
        color: '#27313b',
        fontSize: 16,
        fontWeight: 600,
        margin: '16px 0 32px 0',
      },
    },
  }),
};

export default PageNotFound;
