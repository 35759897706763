import React, { Fragment } from 'react';
import * as SiIcon from 'react-icons/si';
import { Link, Navigate } from 'react-router-dom';
import isPropValid from '@emotion/is-prop-valid';
import { css } from '@emotion/css';
import styled from '@emotion/styled';
import { Avatar } from '@src/components/atoms';
import {
  useGetPublicInfluencerBioQuery, BioButtonStyle, BioSectionType, BioSocialMediaType,
} from '@src/__generated__/globalTypes';
import { anyCreatorUrl } from '@src/libs/constant';
import { usePageLayout, useQueryHelper } from '@src/libs/hooks';
import { ROUTES } from '@src/shares/routes';
import {
  buttonOptions, fontOptions, themeOptions, themeV2Options,
} from './helpers';

const LinkInBio = () => {
  const { isMobileView } = usePageLayout();
  const { enqueueSnackbar, params, t } = useQueryHelper();
  const { data, loading } = useGetPublicInfluencerBioQuery({
    variables: {
      input: {
        linkName: params.username as string,
      },
    },
    onError: (err) => {
      enqueueSnackbar(t(err.message), { variant: 'error' });
    },
  });

  if (loading) {
    return null;
  }

  if (!data?.getPublicInfluencerBio) {
    return <Navigate to={ROUTES.ROOT} />;
  }

  const socialMediaIcon = (socialMediaType: BioSocialMediaType) => {
    switch (socialMediaType) {
      case BioSocialMediaType.FACEBOOK:
        return <SiIcon.SiFacebook size={16} />;
      case BioSocialMediaType.INSTAGRAM:
        return <SiIcon.SiInstagram size={16} />;
      case BioSocialMediaType.TIKTOK:
        return <SiIcon.SiTiktok size={16} />;
      case BioSocialMediaType.TWITTER:
        return <SiIcon.SiTwitter size={16} />;
      default:
        return <SiIcon.SiYoutube size={16} />;
    }
  };

  const {
    getPublicInfluencerBio: {
      profile, sections, themes, themeV2,
    },
  } = data;
  const selectedTheme = (themes ? themeOptions : themeV2Options).find((option) => option.value === (themes?.theme || themeV2));
  const isBorderColor = [
    BioButtonStyle.WHITE_FULL_ROUNDED_EDGE_BUTTON,
    BioButtonStyle.WHITE_ROUNDED_EDGE_BUTTON,
    BioButtonStyle.WHITE_SHARP_EDGE_BUTTON,
  ].includes((selectedTheme?.button || themes?.button) as BioButtonStyle);
  const borderRadius = buttonOptions.find((option) => option.value === (selectedTheme?.button || themes?.button))?.borderRadius;
  const colors = selectedTheme?.colors || themes?.color;
  const fontFamily = fontOptions.find((option) => option.value === (selectedTheme?.font || themes?.font))?.fontFamily;

  return (
    <Container
      background={selectedTheme ? isMobileView ? selectedTheme?.backgroundMobile : selectedTheme?.background : themes?.bg.media}
      backgroundColor={themes?.bg.bgColor}
      isCustomTheme={!isMobileView && !selectedTheme}
    >
      <div className={classnames.contentContainer}>
        <div style={{ minHeight: '80vh' }}>
          <div className={classnames.profileContainer}>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Avatar size={100} src={profile?.avatar || ''} />
            </div>
            {profile?.title && (
            <Title fontColor={colors?.fontColor} fontFamily={fontFamily}>
              {profile.title}
            </Title>
            )}
            {profile?.description && (
            <Description fontColor={colors?.fontColor} fontFamily={fontFamily}>
              {profile.description}
            </Description>
            )}
          </div>
          {sections.map((section) => {
            const {
              id, image, link, sectionType, socialMedia, text,
            } = section;
            const isLink = sectionType === BioSectionType.LINK;

            return (
              <Fragment key={`${sectionType}_${id}`}>
                {sectionType === BioSectionType.IMAGE ? (
                  <ImageContainer
                    disabled={!image?.linkUrl}
                    rel="noopener noreferrer"
                    target="_blank"
                    to={image?.linkUrl || ''}
                  >
                    <img alt="img" src={image?.image} />
                  </ImageContainer>
                ) : sectionType === BioSectionType.TEXT ? (
                  <Text fontColor={colors?.fontColor} fontFamily={fontFamily}>
                    {text?.text}
                  </Text>
                ) : (
                  <LinkButton
                    backgroundColor={!isBorderColor ? colors?.buttonColor : ''}
                    borderColor={isBorderColor ? colors?.buttonColor : ''}
                    borderRadius={borderRadius}
                    fontColor={colors?.buttonFontColor}
                    rel="noreferrer noopener"
                    target="_blank"
                    to={(isLink ? link?.linkUrl : socialMedia?.linkUrl) || ''}
                  >
                    {sectionType === BioSectionType.SOCIAL_MEDIA
                    && socialMedia?.socialMedia
                    && socialMediaIcon(socialMedia.socialMedia)}
                    <span>{isLink ? link?.linkName : socialMedia?.linkName}</span>
                  </LinkButton>
                )}
              </Fragment>
            );
          })}
        </div>

        <CreateBy
          fontColor={colors?.fontColor}
          fontFamily={fontFamily}
          rel="noopener noreferrer"
          target="_blank"
          to={anyCreatorUrl}
        >
          Created by AnyCreator
        </CreateBy>
      </div>
    </Container>
  );
};

const Container = styled.div<{ background?: string | null; backgroundColor?: string | null; isCustomTheme: boolean }>(({
  background,
  backgroundColor,
  isCustomTheme,
}) => ({
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  backgroundSize: isCustomTheme ? 'contain' : 'cover',
  display: 'flex',
  height: '100vh',
  justifyContent: 'center',
  overflow: 'auto',
  ...(background && { backgroundImage: `url(${background})` }),
  ...(backgroundColor && { backgroundColor }),
}));

const CreateBy = styled(Link, { shouldForwardProp: (prop) => isPropValid(prop) })<{
  fontColor?: string | null;
  fontFamily?: string;
}>(({ fontColor, fontFamily }) => ({
  display: 'flex',
  fontSize: 10,
  justifyContent: 'center',
  textAlign: 'center',
  ...(fontColor && { color: fontColor }),
  ...(fontFamily && { fontFamily }),
}));

const Description = styled.div<{ fontColor?: string | null; fontFamily?: string }>(({ fontColor, fontFamily }) => ({
  fontSize: 14,
  marginTop: 8,
  whiteSpace: 'pre-line',
  wordBreak: 'break-word',
  ...(fontColor && { color: fontColor }),
  ...(fontFamily && { fontFamily }),
}));

const ImageContainer = styled(Link, { shouldForwardProp: (prop) => isPropValid(prop) })<{ disabled: boolean }>(({ disabled }) => ({
  backgroundColor: '#eef3f7',
  borderRadius: 7,
  display: 'block',
  height: 125,
  marginBottom: 24,
  width: 'fill-available',
  ...(disabled && { pointerEvents: 'none' }),

  '& > img': {
    height: 125,
    objectFit: 'contain',
    width: '100%',
  },
}));

const LinkButton = styled(Link, { shouldForwardProp: (prop) => isPropValid(prop) })<{
  backgroundColor?: string | null;
  borderColor?: string | null;
  borderRadius?: number;
  fontColor?: string | null;
}>(({
  backgroundColor, borderColor, borderRadius, fontColor,
}) => ({
  alignItems: 'center',
  backgroundColor: backgroundColor || 'transparent',
  border: `1px solid ${borderColor || backgroundColor}`,
  display: 'flex',
  fontSize: 14,
  fontWeight: 500,
  height: 48,
  justifyContent: 'center',
  marginBottom: 16,
  overflow: 'hidden',
  position: 'relative',
  textDecoration: 'none',
  ...(borderRadius && { borderRadius }),
  ...(fontColor && { color: fontColor }),

  '& > svg': {
    left: 16,
    position: 'absolute',
  },

  '& > span': {
    overflow: 'hidden',
    textAlign: 'center',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    width: '65%',
  },
}));

const Text = styled.div<{ fontColor?: string | null; fontFamily?: string }>(({ fontColor, fontFamily }) => ({
  fontSize: 14,
  marginBottom: 24,
  whiteSpace: 'pre-line',
  wordBreak: 'break-word',
  ...(fontColor && { color: fontColor }),
  ...(fontFamily && { fontFamily }),
}));

const Title = styled.div<{ fontColor?: string | null; fontFamily?: string }>(({ fontColor, fontFamily }) => ({
  fontSize: 16,
  fontWeight: 600,
  marginTop: 24,
  whiteSpace: 'pre-line',
  wordBreak: 'break-word',
  ...(fontColor && { color: fontColor }),
  ...(fontFamily && { fontFamily }),
}));

const classnames = {
  contentContainer: css({
    height: 'fit-content',
    padding: 40,
    width: 295,
  }),
  profileContainer: css({
    display: 'grid',
    justifyContent: 'center',
    marginBottom: 24,
    textAlign: 'center',
  }),
};

export default LinkInBio;
