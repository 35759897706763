import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  BigInt: { input: any; output: any; }
  Date: { input: any; output: any; }
  DateTime: { input: any; output: any; }
  JSON: { input: any; output: any; }
  Long: { input: any; output: any; }
  _FieldSet: { input: any; output: any; }
};

export type AccountAnalyticsAccountManager = {
  __typename?: 'AccountAnalyticsAccountManager';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type AccountAnalyticsAdvertiser = {
  __typename?: 'AccountAnalyticsAdvertiser';
  country: Country;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type AccountAnalyticsAdvertiserDetails = {
  __typename?: 'AccountAnalyticsAdvertiserDetails';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type AccountAnalyticsDetail = {
  __typename?: 'AccountAnalyticsDetail';
  accountManagers: Array<AccountAnalyticsAccountManager>;
  accountName: Scalars['String']['output'];
  advertiser: AccountAnalyticsAdvertiserDetails;
  id: Scalars['Int']['output'];
  socialMediaAccounts: AccountAnalyticsSocialMediaAccount;
  subscriptionPlan: AnalyticsSubscriptionPlanType;
};

export type AccountAnalyticsFacebookPageAccount = {
  __typename?: 'AccountAnalyticsFacebookPageAccount';
  avatar: Scalars['String']['output'];
  fbPageId: Scalars['String']['output'];
  fbPageName: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  needReconnect: Scalars['Boolean']['output'];
  pageUrl: Scalars['String']['output'];
};

export type AccountAnalyticsForAdvertiserPayload = {
  __typename?: 'AccountAnalyticsForAdvertiserPayload';
  accountName: Scalars['String']['output'];
  avatar: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  socialMediaAccounts: AccountAnalyticsSocialMediaAccount;
  subscriptionPlan: AnalyticsSubscriptionPlanType;
};

export type AccountAnalyticsInstagramAccount = {
  __typename?: 'AccountAnalyticsInstagramAccount';
  avatar: Scalars['String']['output'];
  businessAccountId: Scalars['String']['output'];
  fbPageId: Scalars['String']['output'];
  fbPageName: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  instagramAccountName: Scalars['String']['output'];
  needReconnect: Scalars['Boolean']['output'];
};

export type AccountAnalyticsPayload = {
  __typename?: 'AccountAnalyticsPayload';
  accountManagers: Array<AccountAnalyticsAccountManager>;
  accountName: Scalars['String']['output'];
  advertiser: AccountAnalyticsAdvertiser;
  avatar: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  socialMediaAccounts: AccountAnalyticsSocialMediaAccount;
  subscriptionPlan: AnalyticsSubscriptionPlanType;
};

export type AccountAnalyticsSocialMediaAccount = {
  __typename?: 'AccountAnalyticsSocialMediaAccount';
  facebookPage?: Maybe<AccountAnalyticsFacebookPageAccount>;
  instagram?: Maybe<AccountAnalyticsInstagramAccount>;
  twitter?: Maybe<AccountAnalyticsTwitterAccount>;
  youtube?: Maybe<AccountAnalyticsYouTubeAccount>;
};

export type AccountAnalyticsTwitterAccount = {
  __typename?: 'AccountAnalyticsTwitterAccount';
  avatar: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  needReconnect: Scalars['Boolean']['output'];
  twitterUserId: Scalars['String']['output'];
  username: Scalars['String']['output'];
};

export type AccountAnalyticsYouTubeAccount = {
  __typename?: 'AccountAnalyticsYouTubeAccount';
  avatar: Scalars['String']['output'];
  channelId: Scalars['String']['output'];
  channelName: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  needReconnect: Scalars['Boolean']['output'];
};

export type ActionLabelInput = {
  label: Scalars['String']['input'];
  url: Scalars['String']['input'];
};

export type ActionLabelPayload = {
  __typename?: 'ActionLabelPayload';
  label: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type ActivityChange = {
  __typename?: 'ActivityChange';
  key: ChangeType;
  newValue?: Maybe<Scalars['String']['output']>;
  oldValue?: Maybe<Scalars['String']['output']>;
};

export type AddAllSelectedInfluencers = {
  __typename?: 'AddAllSelectedInfluencers';
  ok: Scalars['Boolean']['output'];
};

export type AddAllSelectedInfluencersInput = {
  marketplaceId: Scalars['Int']['input'];
};

export type AddCommentInput = {
  comment: Scalars['String']['input'];
  fanId: Scalars['Long']['input'];
};

export type AddCommentPayload = {
  __typename?: 'AddCommentPayload';
  ok: Scalars['Boolean']['output'];
};

export type AddCompareInstagramAccountAnalytics = {
  __typename?: 'AddCompareInstagramAccountAnalytics';
  ok: Scalars['Boolean']['output'];
};

export type AddEmailTemplateInput = {
  fans: Array<Scalars['Long']['input']>;
  isDraft: Scalars['Boolean']['input'];
  message: Scalars['String']['input'];
  scheduleDate?: InputMaybe<Scalars['String']['input']>;
  sendNow: Scalars['Boolean']['input'];
  sender: Scalars['String']['input'];
  subject: Scalars['String']['input'];
  tags: Array<Scalars['Long']['input']>;
};

export type AddEmailTemplatePayload = {
  __typename?: 'AddEmailTemplatePayload';
  ok: Scalars['Boolean']['output'];
};

export type AddFanInput = {
  contactNumber?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
};

export type AddFanPayload = {
  __typename?: 'AddFanPayload';
  ok: Scalars['Boolean']['output'];
};

export type AddFanTagsInput = {
  fanIds: Array<Scalars['Long']['input']>;
  tags: Array<Scalars['String']['input']>;
};

export type AddFanTagsPayload = {
  __typename?: 'AddFanTagsPayload';
  ok: Scalars['Boolean']['output'];
};

export type AddFormInput = {
  description: Scalars['String']['input'];
  hash: Scalars['String']['input'];
  questions: Array<QuestionsInput>;
  thankDescription: Scalars['String']['input'];
  thankTitle: Scalars['String']['input'];
  title: Scalars['String']['input'];
};

export type AddFormPayload = {
  __typename?: 'AddFormPayload';
  ok: Scalars['Boolean']['output'];
};

export type AddInstagramCompareAccountAnalyticsInput = {
  /** an Instagram username of an account that we want to compare */
  compareAccountIgUsername: Scalars['String']['input'];
  /** an analytics account's system id that will be compared with the selected Instagram account */
  mainAnalyticsAccountId: Scalars['Int']['input'];
};

export type AddInstagramHashtagAnalytics = {
  __typename?: 'AddInstagramHashtagAnalytics';
  ok: Scalars['Boolean']['output'];
};

export type AddInstagramHashtagAnalyticsInput = {
  /** an analytic account's system id where the selected hashtag will be added to */
  analyticsAccountId: Scalars['Int']['input'];
  /** a hashtag that we want to add */
  hashtag: Scalars['String']['input'];
};

export type AddMarketplacePostUrl = {
  __typename?: 'AddMarketplacePostUrl';
  ok: Scalars['Boolean']['output'];
};

export type AddMarketplacePostUrlForInfluencer = {
  __typename?: 'AddMarketplacePostUrlForInfluencer';
  ok: Scalars['Boolean']['output'];
};

export type AddMarketplacePostUrlInput = {
  influencerId: Scalars['Int']['input'];
  marketplaceId: Scalars['Int']['input'];
  postUrl: Scalars['String']['input'];
};

export type AddMarketplacePostUrlInputForInfluencer = {
  marketplaceId: Scalars['Int']['input'];
  postUrl: Scalars['String']['input'];
};

export type AddReadTutorial = {
  __typename?: 'AddReadTutorial';
  ok: Scalars['Boolean']['output'];
};

export type AddReadTutorialInput = {
  tutorialName: TutorialName;
};

export type AddSelectedInfluencers = {
  __typename?: 'AddSelectedInfluencers';
  ok: Scalars['Boolean']['output'];
};

export type AddSelectedInfluencersInput = {
  influencerIds: Array<Scalars['Int']['input']>;
  marketplaceId: Scalars['Int']['input'];
};

export type AddStaffAdvertisersToAnotherStaff = {
  __typename?: 'AddStaffAdvertisersToAnotherStaff';
  ok: Scalars['Boolean']['output'];
};

export type AddStaffAdvertisersToAnotherStaffInput = {
  /** List of added advertiser ids */
  advertiserIds: Array<Scalars['Int']['input']>;
  /** The id of a staff user who we need to add advertisers to, */
  otherStaffUserId: Scalars['Int']['input'];
};

export type AddYoutubeCompareAccountAnalyticsInput = {
  mainAccountId: Scalars['Int']['input'];
  url: Scalars['String']['input'];
};

export type AddYoutubeCompareAccountAnalyticsOutput = {
  __typename?: 'AddYoutubeCompareAccountAnalyticsOutput';
  ok: Scalars['Boolean']['output'];
};

export type Address = {
  __typename?: 'Address';
  info?: Maybe<AddressDetails>;
  isEditable: Scalars['Boolean']['output'];
};

export type AddressDetails = {
  __typename?: 'AddressDetails';
  address: Scalars['String']['output'];
  address2: Scalars['String']['output'];
  city: Scalars['String']['output'];
  fullName: Scalars['String']['output'];
  postalCode: Scalars['String']['output'];
  province: Scalars['String']['output'];
};

export type AddressForInfluencer = {
  __typename?: 'AddressForInfluencer';
  address: Scalars['String']['output'];
  address2: Scalars['String']['output'];
  city: Scalars['String']['output'];
  fullName: Scalars['String']['output'];
  postalCode: Scalars['String']['output'];
  province: Scalars['String']['output'];
};

export type AddressInput = {
  address: Scalars['String']['input'];
  address2: Scalars['String']['input'];
  city: Scalars['String']['input'];
  fullName: Scalars['String']['input'];
  postalCode: Scalars['String']['input'];
  province: Scalars['String']['input'];
};

export type Advertiser = {
  __typename?: 'Advertiser';
  anyxMasterId?: Maybe<Scalars['Int']['output']>;
  anyxMasterName?: Maybe<Scalars['String']['output']>;
  companyUrl?: Maybe<Scalars['String']['output']>;
  country: CountryName;
  hubspotId?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  internalNetsuiteId?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  phoneNumber?: Maybe<Scalars['String']['output']>;
  users: Array<AdvertiserUser>;
};

export type AdvertiserForAdvertiserUser = {
  __typename?: 'AdvertiserForAdvertiserUser';
  country: CountryName;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  phoneNumber?: Maybe<Scalars['String']['output']>;
};

export type AdvertiserForAdvertiserUserSettings = {
  __typename?: 'AdvertiserForAdvertiserUserSettings';
  companyUrl?: Maybe<Scalars['String']['output']>;
  country: CountryName;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  phoneNumber: Scalars['String']['output'];
};

export type AdvertiserForSearch = {
  __typename?: 'AdvertiserForSearch';
  companyUrl?: Maybe<Scalars['String']['output']>;
  country: CountryName;
  hubspotId?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  phoneNumber?: Maybe<Scalars['String']['output']>;
};

export type AdvertiserName = {
  __typename?: 'AdvertiserName';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type AdvertiserUser = {
  __typename?: 'AdvertiserUser';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type AdvertiserUserInput = {
  email: Scalars['String']['input'];
  hasInvitationEmail: Scalars['Boolean']['input'];
  password?: InputMaybe<Scalars['String']['input']>;
  userName: Scalars['String']['input'];
};

export type AdvertiserUserSettingsDetails = {
  __typename?: 'AdvertiserUserSettingsDetails';
  advertiser: AdvertiserForAdvertiserUserSettings;
  country: CountryName;
  email: Scalars['String']['output'];
  hasStripeSubscriptionPlan: Scalars['Boolean']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  notificationSetting: Scalars['Boolean']['output'];
  subscriptionPlan?: Maybe<SubscriptionPlanForAdvertiserUserSettings>;
};

export type AdvertiserUserWithRole = {
  __typename?: 'AdvertiserUserWithRole';
  companyName?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  role: UserRoles;
};

export type AdvertiserWithNSVerification = {
  __typename?: 'AdvertiserWithNSVerification';
  companyUrl?: Maybe<Scalars['String']['output']>;
  country: CountryName;
  hubspotId?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  internalNetsuiteId?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  phoneNumber?: Maybe<Scalars['String']['output']>;
  users: Scalars['Int']['output'];
  verifiedStatus?: Maybe<NetSuiteVerifiedStatus>;
};

export type AffiliateCampaignChartReport = {
  __typename?: 'AffiliateCampaignChartReport';
  cost: Scalars['Float']['output'];
  date: Scalars['Date']['output'];
  grossProfit: Scalars['Float']['output'];
  sales: Scalars['Float']['output'];
};

/** An enumeration. */
export enum AffiliateReportShowBy {
  DATE = 'DATE',
  INFLUENCER = 'INFLUENCER',
}

export type AgenciesForSearch = {
  __typename?: 'AgenciesForSearch';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type AgencyLogo = {
  __typename?: 'AgencyLogo';
  logoUrl?: Maybe<Scalars['String']['output']>;
};

export type AgencyUser = {
  __typename?: 'AgencyUser';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type AgencyUserWithRole = {
  __typename?: 'AgencyUserWithRole';
  companyName?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  role: UserRoles;
};

export type AgencyWithAdvertisers = {
  __typename?: 'AgencyWithAdvertisers';
  advertiserIds: Array<Scalars['Int']['output']>;
  companyUrl?: Maybe<Scalars['String']['output']>;
  country: CountryName;
  hubspotId?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  internalNetsuiteId?: Maybe<Scalars['String']['output']>;
  logo?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  phoneNumber?: Maybe<Scalars['String']['output']>;
  users: Array<AgencyUser>;
};

export type AgencyWithNSVerification = {
  __typename?: 'AgencyWithNSVerification';
  companyUrl?: Maybe<Scalars['String']['output']>;
  country: CountryName;
  hubspotId?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  internalNetsuiteId?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  phoneNumber?: Maybe<Scalars['String']['output']>;
  users: Scalars['Int']['output'];
  verifiedStatus?: Maybe<NetSuiteVerifiedStatus>;
};

export type AllAccountAnalyticsForAdminPayload = {
  __typename?: 'AllAccountAnalyticsForAdminPayload';
  accounts: Array<AccountAnalyticsPayload>;
};

export type AllAccountAnalyticsForAdvertiserPayload = {
  __typename?: 'AllAccountAnalyticsForAdvertiserPayload';
  accounts: Array<AccountAnalyticsForAdvertiserPayload>;
};

export type AllAdvertisersForSearch = {
  __typename?: 'AllAdvertisersForSearch';
  advertisers: Array<AdvertiserForSearch>;
  includedAdvertisers: Array<AdvertiserForSearch>;
};

/** An enumeration. */
export enum AllCampaignStatus {
  DEMO = 'DEMO',
  DRAFT = 'DRAFT',
  FINISHED = 'FINISHED',
  LOST = 'LOST',
  ONGOING = 'ONGOING',
  OUT_OF_COUPON = 'OUT_OF_COUPON',
  REVIEWING = 'REVIEWING',
  SUSPENDED = 'SUSPENDED',
  UPCOMING = 'UPCOMING',
}

export type AllCampaignsReportSummary = {
  __typename?: 'AllCampaignsReportSummary';
  chartData: Array<CampaignChartReport>;
  currency: Scalars['String']['output'];
  summary?: Maybe<CampaignReportSummary>;
  total: Scalars['Int']['output'];
};

export enum AllCampaignsSearchJobsOrderBy {
  CREATED_DATE = 'CREATED_DATE',
  HIGH_REWARD = 'HIGH_REWARD',
  ORDER_NUMBER = 'ORDER_NUMBER',
  POPULAR = 'POPULAR',
}

export type AllInfluencerV4 = {
  __typename?: 'AllInfluencerV4';
  influencers: Array<InfluencerV4>;
  totalCount: Scalars['Int']['output'];
};

export type AllStaffDetail = {
  __typename?: 'AllStaffDetail';
  countryId: Scalars['String']['output'];
  email: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type AllUserDetail = {
  __typename?: 'AllUserDetail';
  advertisersCount: Scalars['Int']['output'];
  country: CountryName;
  email: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  role: UserRoles;
};

export type AllowEngagementInfluencerProposalList = {
  __typename?: 'AllowEngagementInfluencerProposalList';
  ok: Scalars['Boolean']['output'];
};

export type AllowEngagementInfluencerProposalListInput = {
  campaignId: Scalars['Int']['input'];
  influencerIds: Array<Scalars['Int']['input']>;
};

export type AnalyticSubscriptionAvailableFeature = {
  __typename?: 'AnalyticSubscriptionAvailableFeature';
  compareEnabled: Scalars['Boolean']['output'];
  dashboardEnabled: Scalars['Boolean']['output'];
  hashtagsEnabled: Scalars['Boolean']['output'];
  interactionEnabled: Scalars['Boolean']['output'];
  trendEnabled: Scalars['Boolean']['output'];
};

export type AnalyticSubscriptionAvailableFeatures = {
  __typename?: 'AnalyticSubscriptionAvailableFeatures';
  compareEnabled: Scalars['Boolean']['output'];
  dashboardEnabled: Scalars['Boolean']['output'];
  hashtagsEnabled: Scalars['Boolean']['output'];
  interactionEnabled: Scalars['Boolean']['output'];
};

export type AnalyticSubscriptionCapabilities = {
  __typename?: 'AnalyticSubscriptionCapabilities';
  maxCompareAccounts: Scalars['Int']['output'];
  maxHashtags: Scalars['Int']['output'];
};

export type AnalyticSubscriptionCapability = {
  __typename?: 'AnalyticSubscriptionCapability';
  maxCompareAccounts: Scalars['Int']['output'];
  maxHashtags: Scalars['Int']['output'];
};

export enum AnalyticsAuthSocialAccountType {
  FACEBOOK_PAGE = 'FACEBOOK_PAGE',
  INSTAGRAM = 'INSTAGRAM',
  TWITTER = 'TWITTER',
  YOUTUBE = 'YOUTUBE',
}

export enum AnalyticsRedirectType {
  ANALYTICS_CONNECT = 'ANALYTICS_CONNECT',
  ANALYTICS_LIST_CONNECT = 'ANALYTICS_LIST_CONNECT',
  ANALYTICS_SIGNIN = 'ANALYTICS_SIGNIN',
}

export type AnalyticsSelectSubscriptionPlan = {
  __typename?: 'AnalyticsSelectSubscriptionPlan';
  ok: Scalars['Boolean']['output'];
};

export type AnalyticsSelectSubscriptionPlanInput = {
  /** advertiser's system id */
  advertiserId: Scalars['Int']['input'];
  /** the selected plan */
  plan: AnalyticsSubscriptionPlanType;
};

/**  Stores a redirect URL that FE uses to communicate with social network APIs */
export type AnalyticsSocialAuthRedirectUrlPayload = {
  __typename?: 'AnalyticsSocialAuthRedirectUrlPayload';
  redirectUri: Scalars['String']['output'];
};

export type AnalyticsSubscriptionPlan = {
  __typename?: 'AnalyticsSubscriptionPlan';
  availableFeatures: AnalyticSubscriptionAvailableFeatures;
  capabilities: AnalyticSubscriptionCapabilities;
  /** subscription plan ID */
  id: Scalars['Int']['output'];
  type: AnalyticsSubscriptionPlanType;
};

export type AnalyticsSubscriptionPlanDetails = {
  __typename?: 'AnalyticsSubscriptionPlanDetails';
  availableFeatures: AnalyticSubscriptionAvailableFeature;
  capabilities: AnalyticSubscriptionCapability;
  id: Scalars['Int']['output'];
  type: AnalyticsSubscriptionPlanType;
};

export type AnalyticsSubscriptionPlanName = {
  __typename?: 'AnalyticsSubscriptionPlanName';
  name?: Maybe<Scalars['String']['output']>;
  type: AnalyticsSubscriptionPlanType;
};

export enum AnalyticsSubscriptionPlanType {
  BASIC = 'BASIC',
  BASIC_PLUS = 'BASIC_PLUS',
  BASIC_PLUS_PLUS = 'BASIC_PLUS_PLUS',
  BASIC_V2 = 'BASIC_V2',
  ENTERPRISE = 'ENTERPRISE',
  ENTERPRISE_PLUS = 'ENTERPRISE_PLUS',
  ENTERPRISE_PLUS_PLUS = 'ENTERPRISE_PLUS_PLUS',
  ENTERPRISE_V2 = 'ENTERPRISE_V2',
  FREE = 'FREE',
  TRIAL = 'TRIAL',
}

export type AnyXAccountInfoPayload = {
  __typename?: 'AnyXAccountInfoPayload';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export enum AnyXAuthSocialAccountType {
  APPLE = 'APPLE',
  FACEBOOK = 'FACEBOOK',
  INSTAGRAM = 'INSTAGRAM',
  TIKTOK = 'TIKTOK',
  TWITTER = 'TWITTER',
  YOUTUBE = 'YOUTUBE',
}

export type AnyXCheckConnectedSocialAccountsForLinkBioPayload = {
  __typename?: 'AnyXCheckConnectedSocialAccountsForLinkBioPayload';
  facebooks: Array<AnyXConnectedFacebookAccountAndPageForLinkBio>;
  instagramAccounts: Array<AnyXConnectedSocialAccountForLinkBio>;
  tiktokAccounts: Array<AnyXConnectedSocialAccountForLinkBio>;
  twitterAccounts: Array<AnyXConnectedSocialAccountForLinkBio>;
  youtubeAccounts: Array<AnyXConnectedSocialAccountForLinkBio>;
};

export type AnyXCheckConnectedSocialAccountsPayload = {
  __typename?: 'AnyXCheckConnectedSocialAccountsPayload';
  facebookPages: Array<AnyXConnectedFacebookPage>;
  facebooks: Array<AnyXConnectedFacebookAccount>;
  instagramAccounts: Array<AnyXConnectedInstagramAccount>;
  tiktokAccounts: Array<AnyXConnectedTikTokAccount>;
  twitterAccounts: Array<AnyXConnectedSocialAccount>;
  youtubeAccounts: Array<AnyXConnectedSocialAccount>;
};

/**  ANY-X */
export type AnyXCheckEmailInput = {
  email: Scalars['String']['input'];
};

export type AnyXCheckEmailPayload = {
  __typename?: 'AnyXCheckEmailPayload';
  ok: Scalars['Boolean']['output'];
};

export type AnyXConnectedFacebookAccount = {
  __typename?: 'AnyXConnectedFacebookAccount';
  avatar?: Maybe<Scalars['String']['output']>;
  followersCount?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  needReconnect: Scalars['Boolean']['output'];
  url?: Maybe<Scalars['String']['output']>;
};

export type AnyXConnectedFacebookAccountAndPageForLinkBio = {
  __typename?: 'AnyXConnectedFacebookAccountAndPageForLinkBio';
  avatar: Scalars['String']['output'];
  followersCount?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  needReconnect: Scalars['Boolean']['output'];
  post?: Maybe<FacebookPostForLinkBio>;
  socialAccountType: SocialAccountType;
  url: Scalars['String']['output'];
};

export type AnyXConnectedFacebookPage = {
  __typename?: 'AnyXConnectedFacebookPage';
  avatar?: Maybe<Scalars['String']['output']>;
  followersCount?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  needReconnect: Scalars['Boolean']['output'];
  url?: Maybe<Scalars['String']['output']>;
};

export type AnyXConnectedInstagramAccount = {
  __typename?: 'AnyXConnectedInstagramAccount';
  avatar?: Maybe<Scalars['String']['output']>;
  followersCount?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  isBusiness?: Maybe<Scalars['Boolean']['output']>;
  name: Scalars['String']['output'];
  needReconnect: Scalars['Boolean']['output'];
  url?: Maybe<Scalars['String']['output']>;
};

export type AnyXConnectedSocialAccount = {
  __typename?: 'AnyXConnectedSocialAccount';
  avatar?: Maybe<Scalars['String']['output']>;
  followersCount?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  url?: Maybe<Scalars['String']['output']>;
};

export type AnyXConnectedSocialAccountForLinkBio = {
  __typename?: 'AnyXConnectedSocialAccountForLinkBio';
  avatar: Scalars['String']['output'];
  followersCount?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  needReconnect: Scalars['Boolean']['output'];
  url: Scalars['String']['output'];
};

export type AnyXConnectedTikTokAccount = {
  __typename?: 'AnyXConnectedTikTokAccount';
  avatar?: Maybe<Scalars['String']['output']>;
  followersCount?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  needReconnect: Scalars['Boolean']['output'];
  url?: Maybe<Scalars['String']['output']>;
};

/** An enumeration. */
export enum AnyXEngagementCampaignStatus {
  DRAFTING = 'DRAFTING',
  EFFECTIVE = 'EFFECTIVE',
  INEFFECTIVE = 'INEFFECTIVE',
  POSTING = 'POSTING',
  REVIEWING = 'REVIEWING',
  WARNING_EFFECTIVE = 'WARNING_EFFECTIVE',
  WARNING_REVIEWING = 'WARNING_REVIEWING',
}

/** An enumeration. */
export enum AnyXMarketplaceCampaignStatus {
  APPROVED = 'APPROVED',
  ASSESSING = 'ASSESSING',
  EFFECTIVE = 'EFFECTIVE',
  INEFFECTIVE = 'INEFFECTIVE',
  OUT_OF_COUPON = 'OUT_OF_COUPON',
  POSTING = 'POSTING',
  REVIEWING = 'REVIEWING',
  SHIPPED = 'SHIPPED',
  WAITING_FOR_SHIPMENT = 'WAITING_FOR_SHIPMENT',
  WARNING_EFFECTIVE = 'WARNING_EFFECTIVE',
}

export type AnyXSignIn = {
  __typename?: 'AnyXSignIn';
  token: Scalars['String']['output'];
};

export type AnyXSignUp = {
  __typename?: 'AnyXSignUp';
  email?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  uuid: Scalars['ID']['output'];
};

export type AnyXSocialAuthConnectForLinkBioInput = {
  callbackUrl?: InputMaybe<Scalars['String']['input']>;
  provider: AnyXAuthSocialAccountType;
  response: Scalars['String']['input'];
};

export type AnyXSocialAuthConnectForLinkBioPayload = {
  __typename?: 'AnyXSocialAuthConnectForLinkBioPayload';
  connectedAccounts: Array<ConnectedAccountForLinkBio>;
};

export type AnyXSocialAuthConnectInput = {
  callbackUrl?: InputMaybe<Scalars['String']['input']>;
  provider: AnyXAuthSocialAccountType;
  response: Scalars['String']['input'];
};

export type AnyXSocialAuthConnectPayload = {
  __typename?: 'AnyXSocialAuthConnectPayload';
  ok: Scalars['Boolean']['output'];
};

export type AnyXSocialAuthReconnectForLinkBioInput = {
  callbackUrl?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  response: Scalars['String']['input'];
  socialAccountType: SocialAccountType;
};

export type AnyXSocialAuthReconnectForLinkBioPayload = {
  __typename?: 'AnyXSocialAuthReconnectForLinkBioPayload';
  accounts: Array<ReconnectedAccountForLinkBio>;
};

export type AnyXSocialAuthReconnectTTCMInput = {
  id: Scalars['ID']['input'];
  response: Scalars['String']['input'];
};

export type AnyXSocialAuthReconnectTTCMPayload = {
  __typename?: 'AnyXSocialAuthReconnectTTCMPayload';
  ok: Scalars['Boolean']['output'];
};

export type AnyXSocialAuthReconnectTikTokInput = {
  id: Scalars['ID']['input'];
  response: Scalars['String']['input'];
};

export type AnyXSocialAuthReconnectTikTokPayload = {
  __typename?: 'AnyXSocialAuthReconnectTikTokPayload';
  ok: Scalars['Boolean']['output'];
};

export type AnyXSocialAuthSignInInput = {
  callbackUrl?: InputMaybe<Scalars['String']['input']>;
  provider: AnyXAuthSocialAccountType;
  response: Scalars['String']['input'];
};

export type AnyXSocialAuthSignInInstagramLineInput = {
  fbOauth?: InputMaybe<FacebookOauthInput>;
  lineIDToken: Scalars['String']['input'];
};

export type AnyXSocialAuthSignInInstagramLinePayload = {
  __typename?: 'AnyXSocialAuthSignInInstagramLinePayload';
  ok: Scalars['Boolean']['output'];
};

export type AnyXSocialAuthSignInOrSignUpInput = {
  callbackUrl?: InputMaybe<Scalars['String']['input']>;
  provider: AnyXAuthSocialAccountType;
  response: Scalars['String']['input'];
};

export type AnyXSocialAuthSignInOrSignUpPayload = {
  __typename?: 'AnyXSocialAuthSignInOrSignUpPayload';
  hasIgAccount: Scalars['Boolean']['output'];
  signIn?: Maybe<AnyXSignIn>;
  signUp?: Maybe<AnyXSignUp>;
};

export type AnyXSocialAuthSignInPayload = {
  __typename?: 'AnyXSocialAuthSignInPayload';
  token: Scalars['String']['output'];
};

export type AnyXSocialAuthSignUpInput = {
  categoryIds: Array<Scalars['Int']['input']>;
  countryId: Scalars['String']['input'];
  dateOfBirth: Scalars['String']['input'];
  email: Scalars['String']['input'];
  gender: Genders;
  name: Scalars['String']['input'];
  phoneNumber: Scalars['String']['input'];
  provider: AnyXAuthSocialAccountType;
  regionId: Scalars['Int']['input'];
  uuid: Scalars['ID']['input'];
};

export type AnyXSocialAuthSignUpPartnerInput = {
  categoryIds: Array<Scalars['Int']['input']>;
  countryId: Scalars['String']['input'];
  dateOfBirth: Scalars['String']['input'];
  email: Scalars['String']['input'];
  gender: Genders;
  name: Scalars['String']['input'];
  partnerUserOwnerHash: Scalars['String']['input'];
  phoneNumber: Scalars['String']['input'];
  provider: AnyXAuthSocialAccountType;
  regionId: Scalars['Int']['input'];
  uuid: Scalars['ID']['input'];
};

export type AnyXSocialAuthSignUpPartnerPayload = {
  __typename?: 'AnyXSocialAuthSignUpPartnerPayload';
  token: Scalars['String']['output'];
};

export type AnyXSocialAuthSignUpPayload = {
  __typename?: 'AnyXSocialAuthSignUpPayload';
  token: Scalars['String']['output'];
};

export type AnyXSocialAuthSignUpTalentInput = {
  categoryIds: Array<Scalars['Int']['input']>;
  countryId: Scalars['String']['input'];
  dateOfBirth: Scalars['String']['input'];
  email: Scalars['String']['input'];
  gender: Genders;
  name: Scalars['String']['input'];
  phoneNumber: Scalars['String']['input'];
  provider: AnyXAuthSocialAccountType;
  regionId: Scalars['Int']['input'];
  talentAgencyOwnerHash: Scalars['String']['input'];
  uuid: Scalars['ID']['input'];
};

export type AnyXSocialAuthSignUpTalentPayload = {
  __typename?: 'AnyXSocialAuthSignUpTalentPayload';
  token: Scalars['String']['output'];
};

/** An enumeration. */
export enum AppName {
  ANYCREATOR = 'ANYCREATOR',
  ANYTAG = 'ANYTAG',
}

export enum AppType {
  ENGAGEMENT_CAMPAIGN = 'ENGAGEMENT_CAMPAIGN',
  MARKETPLACE_CAMPAIGN = 'MARKETPLACE_CAMPAIGN',
}

export type ApplicantInfluencer = {
  __typename?: 'ApplicantInfluencer';
  age?: Maybe<Scalars['Int']['output']>;
  appliedStatus: MarketplaceCampaignAppliedStatus;
  avatar?: Maybe<Scalars['String']['output']>;
  categories: Array<CategoryName>;
  country: CountryNameForInfluencer;
  email?: Maybe<Scalars['Boolean']['output']>;
  engagement: InfluencerEngagement;
  facebook?: Maybe<SocialAccountV2>;
  followersCount?: Maybe<Scalars['Int']['output']>;
  gender: Genders;
  id: Scalars['Int']['output'];
  instagram?: Maybe<SocialAccountV2>;
  marketplaceJoinedCount?: Maybe<Scalars['Int']['output']>;
  marketplacePostedCount?: Maybe<Scalars['Int']['output']>;
  name: Scalars['String']['output'];
  status: SocialAccountStatus;
  tiktok?: Maybe<SocialAccountV2>;
  twitter?: Maybe<SocialAccountV2>;
  youtube?: Maybe<SocialAccountV2>;
};

export type ApproveApplicantInfluencers = {
  __typename?: 'ApproveApplicantInfluencers';
  ok: Scalars['Boolean']['output'];
};

export type ApproveApplicantInfluencersInput = {
  influencerIds: Array<Scalars['Int']['input']>;
  marketplaceCampaignId: Scalars['Int']['input'];
};

export type ApproveAutoManagedPayout = {
  __typename?: 'ApproveAutoManagedPayout';
  ok: Scalars['Boolean']['output'];
};

export type ApproveAutoManagedPayoutInput = {
  coveragePeriods: Array<AutoManagedPayoutPeriodInput>;
  id: Scalars['Int']['input'];
};

export type ApproveMarketplace = {
  __typename?: 'ApproveMarketplace';
  ok: Scalars['Boolean']['output'];
};

export type ApproveMarketplaceAffiliatePayout = {
  __typename?: 'ApproveMarketplaceAffiliatePayout';
  ok: Scalars['Boolean']['output'];
};

export type ApproveMarketplaceAffiliatePayoutInput = {
  coveragePeriods: Array<MarketplaceAffiliatePayoutPeriodInput>;
  marketplaceId: Scalars['Int']['input'];
};

export type ApproveMarketplaceDraftPost = {
  __typename?: 'ApproveMarketplaceDraftPost';
  ok: Scalars['Boolean']['output'];
};

export type ApproveMarketplaceDraftPostInput = {
  draftId: Scalars['Int']['input'];
};

export type ApproveMarketplaceInput = {
  id: Scalars['Int']['input'];
};

export type AssignAdvertiserForStaffs = {
  __typename?: 'AssignAdvertiserForStaffs';
  ok: Scalars['Boolean']['output'];
};

export type AssignAdvertiserForStaffsInput = {
  advertiserId: Scalars['Int']['input'];
  staffUserIds: Array<Scalars['Int']['input']>;
};

export type AssignChatPICInput = {
  chatId: Scalars['String']['input'];
};

export type AssignChatPICPayload = {
  __typename?: 'AssignChatPICPayload';
  ok: Scalars['Boolean']['output'];
};

export type AssociateCompany = {
  __typename?: 'AssociateCompany';
  companyType: AssociateCompanyType;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  phoneNumber?: Maybe<Scalars['String']['output']>;
  usersCount: Scalars['Int']['output'];
};

/** An enumeration. */
export enum AssociateCompanyType {
  ADVERTISER = 'ADVERTISER',
  AGENCY = 'AGENCY',
}

export type AudienceBreakdownAgeGenderRates = {
  __typename?: 'AudienceBreakdownAgeGenderRates';
  ageGroups: Array<AudienceBreakdownAgeGroup>;
  femaleRates: Array<Scalars['Float']['output']>;
  maleRates: Array<Scalars['Float']['output']>;
};

export type AudienceBreakdownAgeGroup = {
  __typename?: 'AudienceBreakdownAgeGroup';
  end: Scalars['Int']['output'];
  start: Scalars['Int']['output'];
};

export type AudienceBreakdownAgeRates = {
  __typename?: 'AudienceBreakdownAgeRates';
  ageGroups: Array<AudienceBreakdownAgeGroup>;
  rates: Array<Scalars['Float']['output']>;
};

export type AudienceBreakdownAreaRate = {
  __typename?: 'AudienceBreakdownAreaRate';
  countryId: Scalars['String']['output'];
  rate: Scalars['Float']['output'];
};

export type AudienceBreakdownGenderRates = {
  __typename?: 'AudienceBreakdownGenderRates';
  femaleRate: Scalars['Float']['output'];
  maleRate: Scalars['Float']['output'];
};

export type Auth0ToLocalJWT = {
  __typename?: 'Auth0ToLocalJWT';
  email: Scalars['String']['output'];
  hash?: Maybe<Scalars['String']['output']>;
  role: UserRoles;
  token: Scalars['String']['output'];
  userId: Scalars['Int']['output'];
};

export type Auth0ToLocalJWTInput = {
  idToken: Scalars['String']['input'];
};

export enum AuthSocialAccountType {
  FACEBOOK = 'FACEBOOK',
  TIKTOK = 'TIKTOK',
  TTCM = 'TTCM',
  TWITTER = 'TWITTER',
  YOUTUBE = 'YOUTUBE',
}

export enum AutoManagedAffiliateCampaignStatus {
  ACTIVE = 'ACTIVE',
  FINISHED = 'FINISHED',
  INACTIVE = 'INACTIVE',
}

export type AutoManagedAffiliateCommissionRateInput = {
  commissionRate: Scalars['Float']['input'];
  influencerId: Scalars['Int']['input'];
};

export type AutoManagedCampaignCountry = {
  __typename?: 'AutoManagedCampaignCountry';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type AutoManagedCampaignForInfluencerSearchJobPayload = {
  __typename?: 'AutoManagedCampaignForInfluencerSearchJobPayload';
  campaignUrl?: Maybe<Scalars['String']['output']>;
  commissionRate?: Maybe<Scalars['Float']['output']>;
  createdDate: Scalars['String']['output'];
  currencyId?: Maybe<Scalars['String']['output']>;
  endDate?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isJoined: Scalars['Boolean']['output'];
  minimumPaymentAmount: Scalars['Float']['output'];
  requirement?: Maybe<Scalars['String']['output']>;
  serviceInfo?: Maybe<Scalars['String']['output']>;
  startDate?: Maybe<Scalars['String']['output']>;
  status: AutoManagedAffiliateCampaignStatus;
  thumbNail?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  type: AutoManagedCampaignType;
};

export type AutoManagedCampaignForInfluencerYourJobPayload = {
  __typename?: 'AutoManagedCampaignForInfluencerYourJobPayload';
  campaignUrl?: Maybe<Scalars['String']['output']>;
  commissionRate?: Maybe<Scalars['Float']['output']>;
  createdDate: Scalars['String']['output'];
  currencyId?: Maybe<Scalars['String']['output']>;
  endDate?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isJoined: Scalars['Boolean']['output'];
  minimumPaymentAmount: Scalars['Float']['output'];
  requirement?: Maybe<Scalars['String']['output']>;
  serviceInfo?: Maybe<Scalars['String']['output']>;
  startDate?: Maybe<Scalars['String']['output']>;
  status: AutoManagedAffiliateCampaignStatus;
  thumbNail?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  trackingUrl: Scalars['String']['output'];
  type: AutoManagedCampaignType;
};

export type AutoManagedCampaignInfoPayload = {
  __typename?: 'AutoManagedCampaignInfoPayload';
  autoManagedCampaignType: AutoManagedCampaignType;
  category: Category;
  commissionRate: Scalars['Float']['output'];
  country: AutoManagedCampaignCountry;
  endDate?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  landingPageUrl?: Maybe<Scalars['String']['output']>;
  numberOfJoinedCreators: Scalars['Int']['output'];
  orders: Scalars['Int']['output'];
  price: AutoManagedCampaignMoney;
  revenue: AutoManagedCampaignMoney;
  sales: AutoManagedCampaignMoney;
  sellerName: Scalars['String']['output'];
  startDate?: Maybe<Scalars['String']['output']>;
  status: AutoManagedCampaignStatus;
  thumbnailUrl?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
};

export type AutoManagedCampaignMoney = {
  __typename?: 'AutoManagedCampaignMoney';
  amount: Scalars['Float']['output'];
  currencyId: Scalars['String']['output'];
};

export type AutoManagedCampaignPayload = {
  __typename?: 'AutoManagedCampaignPayload';
  autoManagedCampaignType: AutoManagedCampaignType;
  campaignThumbnailUrl?: Maybe<Scalars['String']['output']>;
  campaignUrl?: Maybe<Scalars['String']['output']>;
  category: Category;
  commissionRate?: Maybe<Scalars['Float']['output']>;
  country: Country;
  createdDate: Scalars['String']['output'];
  currency: Scalars['String']['output'];
  endDate?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  influencerCommissionRate?: Maybe<Scalars['Float']['output']>;
  landingPageUrl?: Maybe<Scalars['String']['output']>;
  marginRate?: Maybe<Scalars['Float']['output']>;
  requirement?: Maybe<Scalars['String']['output']>;
  sellerName?: Maybe<Scalars['String']['output']>;
  serviceInfo?: Maybe<Scalars['String']['output']>;
  startDate?: Maybe<Scalars['String']['output']>;
  status: AutoManagedCampaignStatus;
  title: Scalars['String']['output'];
};

export enum AutoManagedCampaignStatus {
  ACTIVE = 'ACTIVE',
  FINISHED = 'FINISHED',
  INACTIVE = 'INACTIVE',
}

export enum AutoManagedCampaignType {
  LAZADA = 'LAZADA',
  SHOPEE = 'SHOPEE',
}

export type AutoManagedCommissionInfluencer = {
  __typename?: 'AutoManagedCommissionInfluencer';
  avatar: Scalars['String']['output'];
  currency: Scalars['String']['output'];
  endCoverageDate: Scalars['Date']['output'];
  fixedDate: Scalars['Date']['output'];
  influencerId: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  postId: Scalars['Int']['output'];
  revenue: Scalars['Float']['output'];
  startCoverageDate: Scalars['Date']['output'];
  status: AutoManagedCommissionStatus;
};

/** An enumeration. */
export enum AutoManagedCommissionStatus {
  APPROVED = 'APPROVED',
  UNAPPROVED = 'UNAPPROVED',
}

export type AutoManagedInfluencerInfo = {
  __typename?: 'AutoManagedInfluencerInfo';
  avatar: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type AutoManagedJoinedInfluencer = {
  __typename?: 'AutoManagedJoinedInfluencer';
  influencer: AutoManagedInfluencerInfo;
  joinedDate: Scalars['Date']['output'];
  promotionMethods: Array<CampaignPromotionMethod>;
};

export type AutoManagedJoinedMethodsInput = {
  campaignId: Scalars['ID']['input'];
};

export type AutoManagedJoinedMethodsPayload = {
  __typename?: 'AutoManagedJoinedMethodsPayload';
  emailNewsLetters: Array<JoinedPromotionMethodEmailNewsLetterPayload>;
  facebookAccount?: Maybe<JoinedPromotionMethodSocialAccountPayload>;
  facebookPages: Array<JoinedPromotionMethodSocialAccountPayload>;
  instagramAccounts: Array<JoinedPromotionMethodSocialAccountPayload>;
  mobileApps: Array<JoinedPromotionMethodMobileAppPayload>;
  offlines: Array<JoinedPromotionMethodOfflinePayload>;
  podCasts: Array<JoinedPromotionMethodPodCastPayload>;
  tiktokAccounts: Array<JoinedPromotionMethodSocialAccountPayload>;
  twitterAccounts: Array<JoinedPromotionMethodSocialAccountPayload>;
  websites: Array<JoinedPromotionMethodWebsitePayload>;
  youtubeAccounts: Array<JoinedPromotionMethodSocialAccountPayload>;
};

export type AutoManagedPayoutPeriodInput = {
  fixedDate: Scalars['Date']['input'];
  postId: Scalars['Int']['input'];
};

export type AutoManagedReportByDate = {
  __typename?: 'AutoManagedReportByDate';
  clicks: Scalars['Int']['output'];
  conversions: Scalars['Int']['output'];
  cost: Scalars['Float']['output'];
  cvr?: Maybe<Scalars['Float']['output']>;
  date: Scalars['Date']['output'];
  grossProfit: Scalars['Float']['output'];
  influencerCost: Scalars['Float']['output'];
  sales: Scalars['Float']['output'];
};

export type AutoManagedReportByInfluencer = {
  __typename?: 'AutoManagedReportByInfluencer';
  clicks: Scalars['Int']['output'];
  commissionRate: Scalars['Float']['output'];
  conversions: Scalars['Int']['output'];
  cost: Scalars['Float']['output'];
  cvr?: Maybe<Scalars['Float']['output']>;
  influencerCost: Scalars['Float']['output'];
  influencerInfo: CampaignInfluencerInfo;
  promotionMethods: Array<CampaignPromotionMethod>;
  sales: Scalars['Float']['output'];
};

export type AutoManagedReportForInfluencer = {
  __typename?: 'AutoManagedReportForInfluencer';
  clicks: Scalars['Int']['output'];
  commission: Scalars['Float']['output'];
  conversions: Scalars['Int']['output'];
  date: Scalars['Date']['output'];
};

export type AutoManagedReportSummary = {
  __typename?: 'AutoManagedReportSummary';
  clicks: Scalars['Int']['output'];
  conversions: Scalars['Int']['output'];
  cost: Scalars['Float']['output'];
  cvr?: Maybe<Scalars['Float']['output']>;
  grossProfit: Scalars['Float']['output'];
  influencerCost: Scalars['Float']['output'];
  joinedCount: Scalars['Int']['output'];
  sales: Scalars['Float']['output'];
};

export type AutoManagedReportSummaryForInfluencer = {
  __typename?: 'AutoManagedReportSummaryForInfluencer';
  clicks: Scalars['Int']['output'];
  commission: Scalars['Float']['output'];
  conversions: Scalars['Int']['output'];
  currency: Scalars['String']['output'];
};

export type AvailableInfluencer = {
  __typename?: 'AvailableInfluencer';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  selected: Scalars['Boolean']['output'];
};

export type AvailableInfluencersForStaffPayload = {
  __typename?: 'AvailableInfluencersForStaffPayload';
  influencers: Array<AvailableInfluencer>;
};

export type Bank = {
  __typename?: 'Bank';
  hasBranches: Scalars['Boolean']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type BankBranch = {
  __typename?: 'BankBranch';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type BankBranchForInfluencer = {
  __typename?: 'BankBranchForInfluencer';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type BankForInfluencer = {
  __typename?: 'BankForInfluencer';
  hasBranches: Scalars['Boolean']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type BasicAccountAnalyticsPayload = {
  __typename?: 'BasicAccountAnalyticsPayload';
  brandAccountId: Scalars['Int']['output'];
  brandAccountName: Scalars['String']['output'];
  country: Scalars['String']['output'];
  socialMediaAccounts: AccountAnalyticsSocialMediaAccount;
  subscriptionPlan: AnalyticsSubscriptionPlanDetails;
};

/** An enumeration. */
export enum BcaRequestStatus {
  APPROVED = 'APPROVED',
  REQUESTED = 'REQUESTED',
  REVOKED = 'REVOKED',
}

export type BillingInformation = {
  __typename?: 'BillingInformation';
  cardBrand: Scalars['String']['output'];
  cardCountry?: Maybe<Scalars['String']['output']>;
  cardExpMonth: Scalars['Int']['output'];
  cardExpYear: Scalars['Int']['output'];
  cardLast4: Scalars['String']['output'];
};

export type BioAnalyticsCity = {
  __typename?: 'BioAnalyticsCity';
  city: Scalars['String']['output'];
  count: Scalars['Float']['output'];
};

export type BioAnalyticsCountry = {
  __typename?: 'BioAnalyticsCountry';
  count: Scalars['Float']['output'];
  country: Scalars['String']['output'];
};

export type BioAnalyticsOtherLinkClicks = {
  __typename?: 'BioAnalyticsOtherLinkClicks';
  clicks: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  linkName: Scalars['String']['output'];
};

export type BioAnalyticsReferral = {
  __typename?: 'BioAnalyticsReferral';
  count: Scalars['Int']['output'];
  url: Scalars['String']['output'];
};

export type BioAnalyticsSocialMediaClicks = {
  __typename?: 'BioAnalyticsSocialMediaClicks';
  clicks: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  linkName: Scalars['String']['output'];
  socialMediaType: BioSocialMediaType;
};

export type BioAnalyticsTotalClicks = {
  __typename?: 'BioAnalyticsTotalClicks';
  clicks: Scalars['Int']['output'];
  sectionType: BioSectionType;
};

export enum BioButtonStyle {
  BLACK_FULL_ROUNDED_EDGE_BUTTON = 'BLACK_FULL_ROUNDED_EDGE_BUTTON',
  BLACK_ROUNDED_EDGE_BUTTON = 'BLACK_ROUNDED_EDGE_BUTTON',
  BLACK_SHARP_EDGE_BUTTON = 'BLACK_SHARP_EDGE_BUTTON',
  WHITE_FULL_ROUNDED_EDGE_BUTTON = 'WHITE_FULL_ROUNDED_EDGE_BUTTON',
  WHITE_ROUNDED_EDGE_BUTTON = 'WHITE_ROUNDED_EDGE_BUTTON',
  WHITE_SHARP_EDGE_BUTTON = 'WHITE_SHARP_EDGE_BUTTON',
}

export enum BioFontStyle {
  KAISEI_DECOL = 'KAISEI_DECOL',
  KOSUGI = 'KOSUGI',
  M_PLUS_1 = 'M_PLUS_1',
  NOTO_SANS_JP = 'NOTO_SANS_JP',
  NOTO_SERIF_JP = 'NOTO_SERIF_JP',
  POTTA_ONE = 'POTTA_ONE',
  SAWARABI_MINCHO = 'SAWARABI_MINCHO',
  SHIPPORI_MINCHO = 'SHIPPORI_MINCHO',
  TRAIN_ONE = 'TRAIN_ONE',
}

export type BioImage = {
  __typename?: 'BioImage';
  image: Scalars['String']['output'];
  linkUrl?: Maybe<Scalars['String']['output']>;
};

export type BioImageInput = {
  image: Scalars['String']['input'];
  linkUrl?: InputMaybe<Scalars['String']['input']>;
};

export type BioInfluencerProfile = {
  __typename?: 'BioInfluencerProfile';
  avatar?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export enum BioLinkIcon {
  BOOK = 'BOOK',
  CALENDAR = 'CALENDAR',
  CART = 'CART',
  CHAT = 'CHAT',
  CLOUD = 'CLOUD',
  COFFEE = 'COFFEE',
  DOCUMENT = 'DOCUMENT',
  ENVELOPE = 'ENVELOPE',
  HEART = 'HEART',
  HOUSE = 'HOUSE',
  KEY = 'KEY',
  LOCATION = 'LOCATION',
  MOON = 'MOON',
  MUSIC_NOTE = 'MUSIC_NOTE',
  PHONE = 'PHONE',
  SMILE_EMOJI = 'SMILE_EMOJI',
  STAR = 'STAR',
  SUN = 'SUN',
  USER = 'USER',
  VIDEO_CAMERA = 'VIDEO_CAMERA',
}

export type BioLinkInput = {
  linkIcon?: InputMaybe<BioLinkIcon>;
  linkName: Scalars['String']['input'];
  linkUrl: Scalars['String']['input'];
};

export type BioProfileInput = {
  avatar?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type BioSection = {
  __typename?: 'BioSection';
  id: Scalars['Int']['output'];
  image?: Maybe<BioImage>;
  isPublic: Scalars['Boolean']['output'];
  link?: Maybe<BioUrlLink>;
  order: Scalars['Int']['output'];
  profile?: Maybe<BioInfluencerProfile>;
  sectionType: BioSectionType;
  socialMedia?: Maybe<BioSocialMedia>;
  text?: Maybe<BioText>;
};

export enum BioSectionType {
  IMAGE = 'IMAGE',
  LINK = 'LINK',
  PROFILE = 'PROFILE',
  SOCIAL_MEDIA = 'SOCIAL_MEDIA',
  TEXT = 'TEXT',
}

export type BioSectionV2 = {
  __typename?: 'BioSectionV2';
  id: Scalars['Int']['output'];
  image?: Maybe<BioImage>;
  link?: Maybe<BioUrlLink>;
  order: Scalars['Int']['output'];
  sectionType: BioSectionType;
  socialMedia?: Maybe<BioSocialMedia>;
  text?: Maybe<BioText>;
};

export type BioSectionsInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  image?: InputMaybe<BioImageInput>;
  isPublic: Scalars['Boolean']['input'];
  link?: InputMaybe<BioLinkInput>;
  order: Scalars['Int']['input'];
  profile?: InputMaybe<BioProfileInput>;
  sectionType: BioSectionType;
  socialMedia?: InputMaybe<BioSocialMediaInput>;
  text?: InputMaybe<BioTextInput>;
};

export type BioSectionsInputV2 = {
  id?: InputMaybe<Scalars['Int']['input']>;
  image?: InputMaybe<BioImageInput>;
  link?: InputMaybe<BioLinkInput>;
  order: Scalars['Int']['input'];
  sectionType: BioSectionType;
  socialMedia?: InputMaybe<BioSocialMediaInput>;
  text?: InputMaybe<BioTextInput>;
};

export type BioSocialMedia = {
  __typename?: 'BioSocialMedia';
  linkName: Scalars['String']['output'];
  linkUrl: Scalars['String']['output'];
  socialAccountId?: Maybe<Scalars['String']['output']>;
  socialAccountType?: Maybe<SocialAccountType>;
  socialMedia: BioSocialMediaType;
};

export type BioSocialMediaInput = {
  linkName: Scalars['String']['input'];
  linkUrl: Scalars['String']['input'];
  socialAccountId?: InputMaybe<Scalars['String']['input']>;
  socialAccountType?: InputMaybe<SocialAccountType>;
  socialMedia: BioSocialMediaType;
};

export enum BioSocialMediaType {
  FACEBOOK = 'FACEBOOK',
  INSTAGRAM = 'INSTAGRAM',
  LINE = 'LINE',
  TIKTOK = 'TIKTOK',
  TWITTER = 'TWITTER',
  YOUTUBE = 'YOUTUBE',
}

export type BioText = {
  __typename?: 'BioText';
  text: Scalars['String']['output'];
};

export type BioTextInput = {
  text: Scalars['String']['input'];
};

export enum BioTheme {
  THEME_1 = 'THEME_1',
  THEME_2 = 'THEME_2',
  THEME_3 = 'THEME_3',
  THEME_4 = 'THEME_4',
  THEME_5 = 'THEME_5',
  THEME_6 = 'THEME_6',
  THEME_7 = 'THEME_7',
  THEME_8 = 'THEME_8',
  THEME_9 = 'THEME_9',
  THEME_10 = 'THEME_10',
  THEME_11 = 'THEME_11',
}

export enum BioThemeV2 {
  THEME2_1 = 'THEME2_1',
  THEME2_2 = 'THEME2_2',
  THEME2_3 = 'THEME2_3',
  THEME2_4 = 'THEME2_4',
  THEME2_5 = 'THEME2_5',
  THEME2_6 = 'THEME2_6',
  THEME2_7 = 'THEME2_7',
  THEME2_8 = 'THEME2_8',
  THEME2_9 = 'THEME2_9',
  THEME2_10 = 'THEME2_10',
  THEME2_11 = 'THEME2_11',
  THEME2_12 = 'THEME2_12',
  THEME2_13 = 'THEME2_13',
  THEME2_14 = 'THEME2_14',
  THEME2_15 = 'THEME2_15',
  THEME2_16 = 'THEME2_16',
  THEME2_17 = 'THEME2_17',
}

export type BioUrlLink = {
  __typename?: 'BioUrlLink';
  linkIcon?: Maybe<BioLinkIcon>;
  linkName: Scalars['String']['output'];
  linkUrl: Scalars['String']['output'];
};

export type BotTextMessageEvent = ChatEvent & {
  __typename?: 'BotTextMessageEvent';
  authorId: Scalars['Int']['output'];
  authorName?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  text: Scalars['String']['output'];
  timestamp: Scalars['String']['output'];
};

export type BrandAccount = {
  __typename?: 'BrandAccount';
  avatar: Scalars['String']['output'];
  averageEngagementRate: Scalars['Float']['output'];
  /** mentioned brand account id */
  id: Scalars['Int']['output'];
  mentionPostsCount: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  postIds: Array<Scalars['Int']['output']>;
};

export type BulkSocialAccount = {
  influencerId: Scalars['Int']['input'];
  socialAccountId: Scalars['Int']['input'];
  socialAccountMedia: EngagementProposalSocialMediaType;
};

export type BulkUpdateEngagementInfluencersBudget = {
  __typename?: 'BulkUpdateEngagementInfluencersBudget';
  ok: Scalars['Boolean']['output'];
};

export type BulkUpdateEngagementInfluencersBudgetInput = {
  campaignId: Scalars['Int']['input'];
  influencerCost: Scalars['Float']['input'];
  influencerIds: Array<Scalars['Int']['input']>;
  netBudget: Scalars['Float']['input'];
  profit: Scalars['Float']['input'];
};

export type BulkUpdateEngagementSocialAccountsBudget = {
  __typename?: 'BulkUpdateEngagementSocialAccountsBudget';
  ok: Scalars['Boolean']['output'];
};

export type BulkUpdateEngagementSocialAccountsBudgetInput = {
  campaignId: Scalars['Int']['input'];
  influencerCost: Scalars['Float']['input'];
  netBudget: Scalars['Float']['input'];
  profit: Scalars['Float']['input'];
  socialAccounts: Array<BulkSocialAccount>;
};

export type ButtonMessageInput = {
  cards: Array<CardInput>;
};

export type ButtonMessagePayload = {
  __typename?: 'ButtonMessagePayload';
  cards: Array<CardPayload>;
};

export enum CRMChatTypeFilter {
  ACTIVATED = 'ACTIVATED',
  ASSIGNED_TO_ME = 'ASSIGNED_TO_ME',
}

export type CampaignCategoryName = {
  __typename?: 'CampaignCategoryName';
  categoryName: Scalars['String']['output'];
  id: Scalars['Int']['output'];
};

export type CampaignChartReport = {
  __typename?: 'CampaignChartReport';
  averageViewDurationSeconds?: Maybe<Scalars['Int']['output']>;
  comment?: Maybe<Scalars['Int']['output']>;
  cost: Scalars['Float']['output'];
  date: Scalars['Date']['output'];
  engagement: Scalars['Float']['output'];
  like?: Maybe<Scalars['Int']['output']>;
  share?: Maybe<Scalars['Int']['output']>;
  view?: Maybe<Scalars['Int']['output']>;
};

export type CampaignChartReportForAllTime = {
  __typename?: 'CampaignChartReportForAllTime';
  cost: Scalars['Float']['output'];
  engagement: Scalars['Float']['output'];
  midDate: Scalars['Date']['output'];
};

export type CampaignCreator = {
  __typename?: 'CampaignCreator';
  id: Scalars['Int']['output'];
  role: UserRoles;
};

export type CampaignCreatorWithName = {
  __typename?: 'CampaignCreatorWithName';
  id: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
  role: UserRoles;
};

export type CampaignDailyStats = {
  __typename?: 'CampaignDailyStats';
  click?: Maybe<Scalars['Int']['output']>;
  comment: Scalars['Int']['output'];
  conversion?: Maybe<Scalars['Int']['output']>;
  cost: Scalars['Float']['output'];
  costsPerAction?: Maybe<Scalars['Float']['output']>;
  costsPerClick?: Maybe<Scalars['Float']['output']>;
  /** For Vietnam campaigns */
  costsPerComment?: Maybe<Scalars['Float']['output']>;
  costsPerEngagement?: Maybe<Scalars['Float']['output']>;
  costsPerFollower?: Maybe<Scalars['Float']['output']>;
  costsPerImpressions?: Maybe<Scalars['Float']['output']>;
  costsPerLike?: Maybe<Scalars['Float']['output']>;
  costsPerReach?: Maybe<Scalars['Float']['output']>;
  costsPerSaved?: Maybe<Scalars['Float']['output']>;
  costsPerShare?: Maybe<Scalars['Float']['output']>;
  costsPerView?: Maybe<Scalars['Float']['output']>;
  date: Scalars['Date']['output'];
  engagement: Scalars['Float']['output'];
  engagementRate?: Maybe<Scalars['Float']['output']>;
  followers: Scalars['Int']['output'];
  impressions: Scalars['Int']['output'];
  like: Scalars['Int']['output'];
  /** For admin or staff user, and EngagementCampaign */
  net?: Maybe<Scalars['Float']['output']>;
  /** For admin or staff user, and EngagementCampaign */
  profit?: Maybe<Scalars['Float']['output']>;
  /** For admin or staff user, and EngagementCampaign */
  profitRate?: Maybe<Scalars['Float']['output']>;
  reach: Scalars['Float']['output'];
  saved?: Maybe<Scalars['Int']['output']>;
  share: Scalars['Int']['output'];
  view: Scalars['Int']['output'];
};

export type CampaignDetailReport = {
  __typename?: 'CampaignDetailReport';
  age35UpPercentage?: Maybe<Scalars['Float']['output']>;
  age1824Percentage?: Maybe<Scalars['Float']['output']>;
  age2534Percentage?: Maybe<Scalars['Float']['output']>;
  averageViewDurationMinutes?: Maybe<Scalars['Int']['output']>;
  averageViewDurationPercentage?: Maybe<Scalars['Float']['output']>;
  averageViewDurationSeconds?: Maybe<Scalars['Int']['output']>;
  back?: Maybe<Scalars['Int']['output']>;
  campaign: CampaignReportCampaignInfo;
  click?: Maybe<Scalars['Int']['output']>;
  comment: Scalars['Int']['output'];
  conversion?: Maybe<Scalars['Int']['output']>;
  cost: Scalars['Float']['output'];
  costsPerAction?: Maybe<Scalars['Float']['output']>;
  costsPerClick?: Maybe<Scalars['Float']['output']>;
  /** For Vietnam campaigns */
  costsPerComment?: Maybe<Scalars['Float']['output']>;
  costsPerEngagement?: Maybe<Scalars['Float']['output']>;
  costsPerFollower?: Maybe<Scalars['Float']['output']>;
  costsPerImpressions?: Maybe<Scalars['Float']['output']>;
  costsPerLike?: Maybe<Scalars['Float']['output']>;
  costsPerOrder?: Maybe<Scalars['Float']['output']>;
  costsPerReach?: Maybe<Scalars['Float']['output']>;
  costsPerSale?: Maybe<Scalars['Float']['output']>;
  costsPerSaleReferral?: Maybe<Scalars['Float']['output']>;
  costsPerSaleTune?: Maybe<Scalars['Float']['output']>;
  costsPerSaved?: Maybe<Scalars['Float']['output']>;
  costsPerShare?: Maybe<Scalars['Float']['output']>;
  costsPerView?: Maybe<Scalars['Float']['output']>;
  daily: Array<CampaignDailyStats>;
  dislikes?: Maybe<Scalars['Int']['output']>;
  engagement: Scalars['Float']['output'];
  engagementRate?: Maybe<Scalars['Float']['output']>;
  exited?: Maybe<Scalars['Int']['output']>;
  femalePercentage?: Maybe<Scalars['Float']['output']>;
  followers: Scalars['Float']['output'];
  forward?: Maybe<Scalars['Int']['output']>;
  impressionRate?: Maybe<Scalars['Float']['output']>;
  impressions?: Maybe<Scalars['Int']['output']>;
  impressionsFromDiscovery?: Maybe<Scalars['Int']['output']>;
  impressionsFromHashtag?: Maybe<Scalars['Int']['output']>;
  impressionsFromHome?: Maybe<Scalars['Int']['output']>;
  impressionsFromOther?: Maybe<Scalars['Int']['output']>;
  impressionsFromProfile?: Maybe<Scalars['Int']['output']>;
  interaction?: Maybe<Scalars['Int']['output']>;
  like?: Maybe<Scalars['Int']['output']>;
  linkClicks?: Maybe<Scalars['Int']['output']>;
  malePercentage?: Maybe<Scalars['Float']['output']>;
  navigation?: Maybe<Scalars['Int']['output']>;
  /** For admin or staff user, and EngagementCampaign */
  net?: Maybe<Scalars['Float']['output']>;
  nextStory?: Maybe<Scalars['Int']['output']>;
  nonFollowerReach?: Maybe<Scalars['Int']['output']>;
  nonFollowerReachRate?: Maybe<Scalars['Float']['output']>;
  orders?: Maybe<Scalars['Int']['output']>;
  profileActivity?: Maybe<Scalars['Int']['output']>;
  /** For admin or staff user, and EngagementCampaign */
  profit?: Maybe<Scalars['Float']['output']>;
  /** For admin or staff user, and EngagementCampaign */
  profitRate?: Maybe<Scalars['Float']['output']>;
  reach?: Maybe<Scalars['Float']['output']>;
  reachRate?: Maybe<Scalars['Float']['output']>;
  /** @deprecated please use sales_tune or sales_referral */
  sales?: Maybe<Scalars['Float']['output']>;
  salesReferral?: Maybe<Scalars['Float']['output']>;
  salesTune?: Maybe<Scalars['Float']['output']>;
  saved?: Maybe<Scalars['Int']['output']>;
  share?: Maybe<Scalars['Int']['output']>;
  stickerTaps?: Maybe<Scalars['Int']['output']>;
  view?: Maybe<Scalars['Float']['output']>;
};

export type CampaignFilter = {
  id: Scalars['Int']['input'];
  type: CampaignType;
};

export type CampaignForInfluencerForYourJob = {
  __typename?: 'CampaignForInfluencerForYourJob';
  autoManaged?: Maybe<NewAutoManagedCampaignForInfluencerForYourJob>;
  createdDate: Scalars['Date']['output'];
  engagement?: Maybe<NewEngagementCampaignForInfluencerForYourJob>;
  marketplace?: Maybe<NewMarketplaceCampaignForInfluencerForYourJob>;
  type: CampaignType;
};

export type CampaignForInfluencerForYourJobCounts = {
  __typename?: 'CampaignForInfluencerForYourJobCounts';
  finishedCampaignCount: Scalars['Int']['output'];
  progressCampaignCount: Scalars['Int']['output'];
};

export type CampaignForPostFilter = {
  __typename?: 'CampaignForPostFilter';
  campaigns: Array<CampaignForSearch>;
  includedCampaigns?: Maybe<Array<CampaignForSearch>>;
};

export type CampaignForSearch = {
  __typename?: 'CampaignForSearch';
  endDate?: Maybe<Scalars['Date']['output']>;
  id: Scalars['Int']['output'];
  startDate?: Maybe<Scalars['Date']['output']>;
  status: CampaignStatusForReport;
  title: Scalars['String']['output'];
  type: CampaignType;
};

export type CampaignGridReport = {
  __typename?: 'CampaignGridReport';
  /** For all campaign SNSs */
  engagementRate: PostsMetricsGridReport;
  reachRate?: Maybe<PostsMetricsGridReport>;
  sales?: Maybe<PostsMetricsGridReport>;
  saveRate?: Maybe<PostsMetricsGridReport>;
  shareRate?: Maybe<PostsMetricsGridReport>;
  viewRate?: Maybe<PostsMetricsGridReport>;
};

export type CampaignIdentifier = {
  __typename?: 'CampaignIdentifier';
  campaignId: Scalars['Int']['output'];
  campaignType: CampaignIdentifierType;
};

export type CampaignIdentifierInput = {
  campaignId?: InputMaybe<Scalars['Int']['input']>;
  campaignType?: InputMaybe<CampaignIdentifierType>;
};

export enum CampaignIdentifierType {
  ENGAGEMENT = 'ENGAGEMENT',
  MARKETPLACE = 'MARKETPLACE',
}

export type CampaignInfluencerInfo = {
  __typename?: 'CampaignInfluencerInfo';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  thumbNail: Scalars['String']['output'];
};

export type CampaignInfluencerReport = {
  __typename?: 'CampaignInfluencerReport';
  InternalInfo?: Maybe<Array<Scalars['String']['output']>>;
  influencerInfo: CampaignInfluencerInfo;
  postCount?: Maybe<Scalars['Int']['output']>;
  postReports?: Maybe<Array<NewCampaignPostReport>>;
  summary: NewCampaignStatsItemRow;
  summaryDaily?: Maybe<Array<CampaignSummaryDaily>>;
};

export type CampaignInfluencerV2 = {
  __typename?: 'CampaignInfluencerV2';
  age?: Maybe<Scalars['Int']['output']>;
  avatar?: Maybe<Scalars['String']['output']>;
  categories: Array<CategoryName>;
  country: CountryNameForInfluencer;
  email?: Maybe<Scalars['Boolean']['output']>;
  engagement: InfluencerEngagement;
  facebook?: Maybe<SocialAccountV2>;
  followersCount?: Maybe<Scalars['Int']['output']>;
  gender: Genders;
  id: Scalars['Int']['output'];
  instagram?: Maybe<SocialAccountV2>;
  marketplaceJoinedCount?: Maybe<Scalars['Int']['output']>;
  marketplacePostedCount?: Maybe<Scalars['Int']['output']>;
  name: Scalars['String']['output'];
  status: SocialAccountStatus;
  tiktok?: Maybe<SocialAccountV2>;
  twitter?: Maybe<SocialAccountV2>;
  youtube?: Maybe<SocialAccountV2>;
};

export type CampaignPayment = {
  __typename?: 'CampaignPayment';
  campaignType: CampaignType;
  /** Marketplace OR AutoManaged campaign */
  id: Scalars['Int']['output'];
  revenue: Scalars['Float']['output'];
  title: Scalars['String']['output'];
};

export type CampaignPost = {
  __typename?: 'CampaignPost';
  actions?: Maybe<Scalars['Int']['output']>;
  affiliatePaidStatus?: Maybe<CommissionStatus>;
  age18to24Percentage?: Maybe<Scalars['Float']['output']>;
  age25to34Percentage?: Maybe<Scalars['Float']['output']>;
  age35upPercentage?: Maybe<Scalars['Float']['output']>;
  audienceRetention?: Maybe<Scalars['Float']['output']>;
  avatar: Scalars['String']['output'];
  averageViewDurationPercentage?: Maybe<Scalars['Float']['output']>;
  averageViewDurationSeconds?: Maybe<Scalars['Int']['output']>;
  averageViewTime?: Maybe<Scalars['Float']['output']>;
  back?: Maybe<Scalars['Int']['output']>;
  campaignIdentifier: CampaignIdentifier;
  campaignName: Scalars['String']['output'];
  clicks: Scalars['Int']['output'];
  comments: Scalars['Int']['output'];
  content: Scalars['String']['output'];
  conversions?: Maybe<Scalars['Int']['output']>;
  cost?: Maybe<Scalars['Float']['output']>;
  currencyId: Scalars['String']['output'];
  dislikes?: Maybe<Scalars['Int']['output']>;
  engagement: Scalars['Int']['output'];
  engagementRate: Scalars['Float']['output'];
  exited?: Maybe<Scalars['Int']['output']>;
  femalePercentage?: Maybe<Scalars['Float']['output']>;
  followers: Scalars['Int']['output'];
  forward?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  impressions: Scalars['Int']['output'];
  impressionsClickThroughRate?: Maybe<Scalars['Float']['output']>;
  impressionsFromDiscovery?: Maybe<Scalars['Int']['output']>;
  impressionsFromHashtag?: Maybe<Scalars['Int']['output']>;
  impressionsFromHome?: Maybe<Scalars['Int']['output']>;
  impressionsFromOther?: Maybe<Scalars['Int']['output']>;
  impressionsFromProfile?: Maybe<Scalars['Int']['output']>;
  impressionsRate: Scalars['Float']['output'];
  influencerId: Scalars['Int']['output'];
  influencerName: Scalars['String']['output'];
  interaction?: Maybe<Scalars['Int']['output']>;
  likes: Scalars['Int']['output'];
  linkClicks?: Maybe<Scalars['Int']['output']>;
  malePercentage?: Maybe<Scalars['Float']['output']>;
  navigation?: Maybe<Scalars['Int']['output']>;
  nextStory?: Maybe<Scalars['Int']['output']>;
  nonFollowerReach?: Maybe<Scalars['Int']['output']>;
  nonFollowerReachRate?: Maybe<Scalars['Float']['output']>;
  orders?: Maybe<Scalars['Int']['output']>;
  postDate: Scalars['Date']['output'];
  postUrl: Scalars['String']['output'];
  profileActivity?: Maybe<Scalars['Int']['output']>;
  reach: Scalars['Int']['output'];
  reachRate: Scalars['Float']['output'];
  revenue: Scalars['Float']['output'];
  sales: Scalars['Float']['output'];
  salesCost?: Maybe<Scalars['Float']['output']>;
  salesReferral?: Maybe<Scalars['Float']['output']>;
  saved: Scalars['Int']['output'];
  shares: Scalars['Int']['output'];
  socialPostType: CampaignPostSocialType;
  stickerTaps?: Maybe<Scalars['Int']['output']>;
  thumbnail?: Maybe<Scalars['String']['output']>;
  totalPlayTime?: Maybe<Scalars['Int']['output']>;
  videoCompletionRate?: Maybe<Scalars['Float']['output']>;
  views: Scalars['Int']['output'];
};

export type CampaignPostGridInfo = {
  __typename?: 'CampaignPostGridInfo';
  content: Scalars['String']['output'];
  hasTrafficSource?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['Int']['output'];
  influencerId: Scalars['Int']['output'];
  insightDataAcquisition?: Maybe<Scalars['Date']['output']>;
  postUrl: Scalars['String']['output'];
  socialAccountAvatar?: Maybe<Scalars['String']['output']>;
  socialAccountId: Scalars['Int']['output'];
  socialMedia: CampaignSocialMediaType;
  socialUsername: Scalars['String']['output'];
  thumbNail?: Maybe<Scalars['String']['output']>;
};

export type CampaignPostInfo = {
  __typename?: 'CampaignPostInfo';
  content: Scalars['String']['output'];
  hasTrafficSource?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['Int']['output'];
  influencerId: Scalars['Int']['output'];
  insightDataAcquisition?: Maybe<Scalars['Date']['output']>;
  postUrl: Scalars['String']['output'];
  socialAccountId: Scalars['Int']['output'];
  socialMedia: CampaignSocialMediaType;
  thumbNail?: Maybe<Scalars['String']['output']>;
};

export type CampaignPostMetrics = {
  __typename?: 'CampaignPostMetrics';
  actions?: Maybe<Scalars['Float']['output']>;
  age18to24Percentage?: Maybe<Scalars['Float']['output']>;
  age25to34Percentage?: Maybe<Scalars['Float']['output']>;
  age35upPercentage?: Maybe<Scalars['Float']['output']>;
  audienceRetention?: Maybe<Scalars['Float']['output']>;
  averageViewDurationPercentage?: Maybe<Scalars['Float']['output']>;
  averageViewDurationSeconds?: Maybe<Scalars['Float']['output']>;
  averageViewTime?: Maybe<Scalars['Float']['output']>;
  back?: Maybe<Scalars['Float']['output']>;
  clicks: Scalars['Float']['output'];
  comments: Scalars['Float']['output'];
  conversions?: Maybe<Scalars['Float']['output']>;
  cost?: Maybe<Scalars['Float']['output']>;
  dislikes?: Maybe<Scalars['Float']['output']>;
  engagement: Scalars['Float']['output'];
  engagementRate: Scalars['Float']['output'];
  exited?: Maybe<Scalars['Float']['output']>;
  femalePercentage?: Maybe<Scalars['Float']['output']>;
  followers: Scalars['Float']['output'];
  forward?: Maybe<Scalars['Float']['output']>;
  impressions: Scalars['Float']['output'];
  impressionsClickThroughRate?: Maybe<Scalars['Float']['output']>;
  impressionsFromDiscovery?: Maybe<Scalars['Float']['output']>;
  impressionsFromHashtag?: Maybe<Scalars['Float']['output']>;
  impressionsFromHome?: Maybe<Scalars['Float']['output']>;
  impressionsFromOther?: Maybe<Scalars['Float']['output']>;
  impressionsFromProfile?: Maybe<Scalars['Float']['output']>;
  impressionsRate: Scalars['Float']['output'];
  interaction?: Maybe<Scalars['Float']['output']>;
  likes: Scalars['Float']['output'];
  linkClicks?: Maybe<Scalars['Float']['output']>;
  malePercentage?: Maybe<Scalars['Float']['output']>;
  navigation?: Maybe<Scalars['Float']['output']>;
  nextStory?: Maybe<Scalars['Float']['output']>;
  nonFollowerReach?: Maybe<Scalars['Float']['output']>;
  nonFollowerReachRate?: Maybe<Scalars['Float']['output']>;
  orders?: Maybe<Scalars['Float']['output']>;
  profileActivity?: Maybe<Scalars['Float']['output']>;
  reach: Scalars['Float']['output'];
  reachRate: Scalars['Float']['output'];
  revenue: Scalars['Float']['output'];
  sales: Scalars['Float']['output'];
  salesCost?: Maybe<Scalars['Float']['output']>;
  salesReferral?: Maybe<Scalars['Float']['output']>;
  saved: Scalars['Float']['output'];
  shares: Scalars['Float']['output'];
  stickerTaps?: Maybe<Scalars['Float']['output']>;
  totalPlayTime?: Maybe<Scalars['Float']['output']>;
  videoCompletionRate?: Maybe<Scalars['Float']['output']>;
  views: Scalars['Float']['output'];
};

export type CampaignPostOrderBy = {
  field?: InputMaybe<CampaignPostSortField>;
  order?: InputMaybe<Order>;
};

export type CampaignPostReportGrid = {
  __typename?: 'CampaignPostReportGrid';
  /** For all SNSs */
  comment: Scalars['Int']['output'];
  conversion?: Maybe<Scalars['Int']['output']>;
  /** For all SNSs */
  engagementRate: Scalars['Float']['output'];
  impressions?: Maybe<Scalars['Int']['output']>;
  like?: Maybe<Scalars['Int']['output']>;
  orders?: Maybe<Scalars['Int']['output']>;
  postInfo: CampaignPostGridInfo;
  /** Rate of current Comparable metrics */
  rate?: Maybe<Scalars['Float']['output']>;
  reach?: Maybe<Scalars['Int']['output']>;
  /** @deprecated please use sales_tune or sales_referral */
  sales?: Maybe<Scalars['Float']['output']>;
  salesReferral?: Maybe<Scalars['Float']['output']>;
  salesTune?: Maybe<Scalars['Float']['output']>;
  saved?: Maybe<Scalars['Int']['output']>;
  share?: Maybe<Scalars['Int']['output']>;
  view?: Maybe<Scalars['Int']['output']>;
};

export enum CampaignPostSocialType {
  FACEBOOK = 'FACEBOOK',
  FACEBOOK_PAGE = 'FACEBOOK_PAGE',
  INSTAGRAM = 'INSTAGRAM',
  THREADS = 'THREADS',
  TIKTOK = 'TIKTOK',
  TWITTER = 'TWITTER',
  YOUTUBE = 'YOUTUBE',
}

export enum CampaignPostSortField {
  ACTIONS = 'ACTIONS',
  AFFILIATE_PAID_STATUS = 'AFFILIATE_PAID_STATUS',
  AGE_18_TO_24_PERCENTAGE = 'AGE_18_TO_24_PERCENTAGE',
  AGE_25_TO_34_PERCENTAGE = 'AGE_25_TO_34_PERCENTAGE',
  AGE_35_UP_PERCENTAGE = 'AGE_35_UP_PERCENTAGE',
  AUDIENCE_RETENTION = 'AUDIENCE_RETENTION',
  AVERAGE_VIEW_DURATION_PERCENTAGE = 'AVERAGE_VIEW_DURATION_PERCENTAGE',
  AVERAGE_VIEW_DURATION_SECONDS = 'AVERAGE_VIEW_DURATION_SECONDS',
  AVERAGE_VIEW_TIME = 'AVERAGE_VIEW_TIME',
  BACK = 'BACK',
  CLICKS = 'CLICKS',
  COMMENTS = 'COMMENTS',
  CONVERSIONS = 'CONVERSIONS',
  COST = 'COST',
  DATE = 'DATE',
  DISLIKES = 'DISLIKES',
  ENGAGEMENT = 'ENGAGEMENT',
  ENGAGEMENT_RATE = 'ENGAGEMENT_RATE',
  EXITED = 'EXITED',
  FEMALE_PERCENTAGE = 'FEMALE_PERCENTAGE',
  FOLLOWERS = 'FOLLOWERS',
  FORWARD = 'FORWARD',
  IMPRESSIONS = 'IMPRESSIONS',
  IMPRESSIONS_CLICK_THROUGH_RATE = 'IMPRESSIONS_CLICK_THROUGH_RATE',
  IMPRESSIONS_FROM_DISCOVERY = 'IMPRESSIONS_FROM_DISCOVERY',
  IMPRESSIONS_FROM_HASHTAG = 'IMPRESSIONS_FROM_HASHTAG',
  IMPRESSIONS_FROM_HOME = 'IMPRESSIONS_FROM_HOME',
  IMPRESSIONS_FROM_OTHER = 'IMPRESSIONS_FROM_OTHER',
  IMPRESSIONS_FROM_PROFILE = 'IMPRESSIONS_FROM_PROFILE',
  IMPRESSIONS_RATE = 'IMPRESSIONS_RATE',
  INTERACTION = 'INTERACTION',
  LIKES = 'LIKES',
  LINK_CLICKS = 'LINK_CLICKS',
  MALE_PERCENTAGE = 'MALE_PERCENTAGE',
  NAVIGATION = 'NAVIGATION',
  NEXT_STORY = 'NEXT_STORY',
  NON_FOLLOWER_REACH = 'NON_FOLLOWER_REACH',
  NON_FOLLOWER_REACH_RATE = 'NON_FOLLOWER_REACH_RATE',
  ORDERS = 'ORDERS',
  PROFILE_ACTIVITY = 'PROFILE_ACTIVITY',
  REACH = 'REACH',
  REACH_RATE = 'REACH_RATE',
  REVENUE = 'REVENUE',
  SALES = 'SALES',
  SALES_COST = 'SALES_COST',
  SALES_REFERRAL = 'SALES_REFERRAL',
  SAVED = 'SAVED',
  SHARES = 'SHARES',
  STICKER_TAPS = 'STICKER_TAPS',
  TOTAL_PLAY_TIME = 'TOTAL_PLAY_TIME',
  VIDEO_COMPLETION_RATE = 'VIDEO_COMPLETION_RATE',
  VIEWS = 'VIEWS',
}

/** An enumeration. */
export enum CampaignPostStatusForInfluencer {
  EFFECTIVE = 'EFFECTIVE',
  INEFFECTIVE = 'INEFFECTIVE',
  REVIEWING = 'REVIEWING',
  WARNING = 'WARNING',
}

export type CampaignPosts = {
  __typename?: 'CampaignPosts';
  posts: Array<CampaignPost>;
  summary: CampaignPostsSummary;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type CampaignPostsSummary = {
  __typename?: 'CampaignPostsSummary';
  average: CampaignPostMetrics;
  currencyId: Scalars['String']['output'];
  sum: CampaignPostMetrics;
};

export enum CampaignPromotionMethod {
  EMAIL_NEWSLETTER = 'EMAIL_NEWSLETTER',
  FACEBOOK = 'FACEBOOK',
  FACEBOOK_PAGE = 'FACEBOOK_PAGE',
  INSTAGRAM = 'INSTAGRAM',
  INSTAGRAM_STORY = 'INSTAGRAM_STORY',
  MOBILE_APP = 'MOBILE_APP',
  OFFLINE = 'OFFLINE',
  OTHER_SOCIAL_MEDIA = 'OTHER_SOCIAL_MEDIA',
  PODCAST = 'PODCAST',
  TIKTOK = 'TIKTOK',
  TWITTER = 'TWITTER',
  WEBSITE = 'WEBSITE',
  YOUTUBE = 'YOUTUBE',
}

export type CampaignReportCampaignInfo = {
  __typename?: 'CampaignReportCampaignInfo';
  endDate?: Maybe<Scalars['Date']['output']>;
  hasTrafficSource?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['Int']['output'];
  socialMedias: Array<CampaignSocialMediaType>;
  startDate?: Maybe<Scalars['Date']['output']>;
  status: CampaignStatusForReport;
  thumbNail: Scalars['String']['output'];
  title: Scalars['String']['output'];
  type: CampaignType;
};

export type CampaignReportCampaignInfoForInfluencer = {
  __typename?: 'CampaignReportCampaignInfoForInfluencer';
  endDate: Scalars['Date']['output'];
  id: Scalars['Int']['output'];
  socialMedias: Array<Maybe<CampaignSocialMediaType>>;
  startDate: Scalars['Date']['output'];
  status: CampaignStatusForInfluencer;
  suspendedDate?: Maybe<Scalars['Date']['output']>;
  thumbNail: Scalars['String']['output'];
  title: Scalars['String']['output'];
  type: CampaignType;
};

export type CampaignReportOrderBy = {
  field?: InputMaybe<CampaignReportOrderField>;
  order?: InputMaybe<ORDER>;
};

/** An enumeration. */
export enum CampaignReportOrderField {
  AGE_18_24_PERCENTAGE = 'AGE_18_24_PERCENTAGE',
  AGE_25_34_PERCENTAGE = 'AGE_25_34_PERCENTAGE',
  AGE_35_UP_PERCENTAGE = 'AGE_35_UP_PERCENTAGE',
  AVERAGE_VIEW_DURATION_MINUTES = 'AVERAGE_VIEW_DURATION_MINUTES',
  AVERAGE_VIEW_DURATION_PERCENTAGE = 'AVERAGE_VIEW_DURATION_PERCENTAGE',
  AVERAGE_VIEW_DURATION_SECONDS = 'AVERAGE_VIEW_DURATION_SECONDS',
  AVERAGE_VIEW_TIME = 'AVERAGE_VIEW_TIME',
  BACK = 'BACK',
  CLICK = 'CLICK',
  COMMENT = 'COMMENT',
  CONVERSION = 'CONVERSION',
  COST = 'COST',
  COST_PER_ACTION = 'COST_PER_ACTION',
  COST_PER_CLICK = 'COST_PER_CLICK',
  COST_PER_COMMENT = 'COST_PER_COMMENT',
  COST_PER_ENGAGEMENT = 'COST_PER_ENGAGEMENT',
  COST_PER_FOLLOWER = 'COST_PER_FOLLOWER',
  COST_PER_IMPRESSIONS = 'COST_PER_IMPRESSIONS',
  COST_PER_LIKE = 'COST_PER_LIKE',
  COST_PER_ORDER = 'COST_PER_ORDER',
  COST_PER_REACH = 'COST_PER_REACH',
  COST_PER_SALE = 'COST_PER_SALE',
  COST_PER_SALE_REFERRAL = 'COST_PER_SALE_REFERRAL',
  COST_PER_SALE_TUNE = 'COST_PER_SALE_TUNE',
  COST_PER_SAVED = 'COST_PER_SAVED',
  COST_PER_SHARE = 'COST_PER_SHARE',
  COST_PER_VIEW = 'COST_PER_VIEW',
  DATE = 'DATE',
  DISLIKES = 'DISLIKES',
  ENGAGEMENT = 'ENGAGEMENT',
  ENGAGEMENT_RATE = 'ENGAGEMENT_RATE',
  EXITED = 'EXITED',
  FEMALE_PERCENTAGE = 'FEMALE_PERCENTAGE',
  FOLLOWERS = 'FOLLOWERS',
  FOLLOWER_REACH = 'FOLLOWER_REACH',
  FOLLOWER_REACH_RATE = 'FOLLOWER_REACH_RATE',
  FORWARD = 'FORWARD',
  IMPRESSIONS = 'IMPRESSIONS',
  IMPRESSIONS_FROM_DISCOVERY = 'IMPRESSIONS_FROM_DISCOVERY',
  IMPRESSIONS_FROM_HASHTAG = 'IMPRESSIONS_FROM_HASHTAG',
  IMPRESSIONS_FROM_HOME = 'IMPRESSIONS_FROM_HOME',
  IMPRESSIONS_FROM_OTHER = 'IMPRESSIONS_FROM_OTHER',
  IMPRESSIONS_FROM_PROFILE = 'IMPRESSIONS_FROM_PROFILE',
  IMPRESSION_RATE = 'IMPRESSION_RATE',
  INTERACTION = 'INTERACTION',
  LIKE = 'LIKE',
  LINK_CLICKS = 'LINK_CLICKS',
  MALE_PERCENTAGE = 'MALE_PERCENTAGE',
  NAVIGATION = 'NAVIGATION',
  NET = 'NET',
  NEW_FOLLOWERS = 'NEW_FOLLOWERS',
  NEXT_STORY = 'NEXT_STORY',
  NON_FOLLOWER_REACH = 'NON_FOLLOWER_REACH',
  NON_FOLLOWER_REACH_RATE = 'NON_FOLLOWER_REACH_RATE',
  ORDER = 'ORDER',
  PROFILE_ACTIVITY = 'PROFILE_ACTIVITY',
  PROFIT = 'PROFIT',
  PROFIT_RATE = 'PROFIT_RATE',
  REACH = 'REACH',
  REACH_RATE = 'REACH_RATE',
  SALES = 'SALES',
  SALES_REFERRAL = 'SALES_REFERRAL',
  SALES_TUNE = 'SALES_TUNE',
  SAVED = 'SAVED',
  SHARE = 'SHARE',
  STICKER_TAPS = 'STICKER_TAPS',
  TOTAL_PLAY_TIME = 'TOTAL_PLAY_TIME',
  VIDEO_COMPLETION_RATE = 'VIDEO_COMPLETION_RATE',
  VIEW = 'VIEW',
}

export type CampaignReportSummary = {
  __typename?: 'CampaignReportSummary';
  age35UpPercentage?: Maybe<Scalars['Float']['output']>;
  age1824Percentage?: Maybe<Scalars['Float']['output']>;
  age2534Percentage?: Maybe<Scalars['Float']['output']>;
  averageViewDurationMinutes?: Maybe<Scalars['Int']['output']>;
  averageViewDurationPercentage?: Maybe<Scalars['Float']['output']>;
  averageViewDurationSeconds?: Maybe<Scalars['Int']['output']>;
  back?: Maybe<Scalars['Int']['output']>;
  click?: Maybe<Scalars['Int']['output']>;
  comment: Scalars['Int']['output'];
  conversion?: Maybe<Scalars['Int']['output']>;
  cost: Scalars['Float']['output'];
  costsPerAction?: Maybe<Scalars['Float']['output']>;
  costsPerClick?: Maybe<Scalars['Float']['output']>;
  /** For Vietnam campaigns */
  costsPerComment?: Maybe<Scalars['Float']['output']>;
  costsPerEngagement?: Maybe<Scalars['Float']['output']>;
  costsPerFollower?: Maybe<Scalars['Float']['output']>;
  costsPerImpressions?: Maybe<Scalars['Float']['output']>;
  costsPerLike?: Maybe<Scalars['Float']['output']>;
  costsPerOrder?: Maybe<Scalars['Float']['output']>;
  costsPerReach?: Maybe<Scalars['Float']['output']>;
  costsPerSale?: Maybe<Scalars['Float']['output']>;
  costsPerSaleReferral?: Maybe<Scalars['Float']['output']>;
  costsPerSaleTune?: Maybe<Scalars['Float']['output']>;
  costsPerSaved?: Maybe<Scalars['Float']['output']>;
  costsPerShare?: Maybe<Scalars['Float']['output']>;
  costsPerView?: Maybe<Scalars['Float']['output']>;
  dislikes?: Maybe<Scalars['Int']['output']>;
  engagement: Scalars['Float']['output'];
  engagementRate?: Maybe<Scalars['Float']['output']>;
  exited?: Maybe<Scalars['Int']['output']>;
  femalePercentage?: Maybe<Scalars['Float']['output']>;
  followers: Scalars['Float']['output'];
  forward?: Maybe<Scalars['Int']['output']>;
  impressionRate?: Maybe<Scalars['Float']['output']>;
  impressions?: Maybe<Scalars['Int']['output']>;
  impressionsFromDiscovery?: Maybe<Scalars['Int']['output']>;
  impressionsFromHashtag?: Maybe<Scalars['Int']['output']>;
  impressionsFromHome?: Maybe<Scalars['Int']['output']>;
  impressionsFromOther?: Maybe<Scalars['Int']['output']>;
  impressionsFromProfile?: Maybe<Scalars['Int']['output']>;
  interaction?: Maybe<Scalars['Int']['output']>;
  like?: Maybe<Scalars['Int']['output']>;
  linkClicks?: Maybe<Scalars['Int']['output']>;
  malePercentage?: Maybe<Scalars['Float']['output']>;
  navigation?: Maybe<Scalars['Int']['output']>;
  /** For admin or staff user, and EngagementCampaign */
  net?: Maybe<Scalars['Float']['output']>;
  nextStory?: Maybe<Scalars['Int']['output']>;
  nonFollowerReach?: Maybe<Scalars['Int']['output']>;
  nonFollowerReachRate?: Maybe<Scalars['Float']['output']>;
  orders?: Maybe<Scalars['Int']['output']>;
  profileActivity?: Maybe<Scalars['Int']['output']>;
  /** For admin or staff user, and EngagementCampaign */
  profit?: Maybe<Scalars['Float']['output']>;
  /** For admin or staff user, and EngagementCampaign */
  profitRate?: Maybe<Scalars['Float']['output']>;
  reach?: Maybe<Scalars['Float']['output']>;
  reachRate?: Maybe<Scalars['Float']['output']>;
  /** @deprecated please use sales_tune or sales_referral */
  sales?: Maybe<Scalars['Float']['output']>;
  salesReferral?: Maybe<Scalars['Float']['output']>;
  salesTune?: Maybe<Scalars['Float']['output']>;
  saved?: Maybe<Scalars['Int']['output']>;
  share?: Maybe<Scalars['Int']['output']>;
  stickerTaps?: Maybe<Scalars['Int']['output']>;
  view?: Maybe<Scalars['Float']['output']>;
};

export enum CampaignSocialMediaType {
  FACEBOOK = 'FACEBOOK',
  INSTAGRAM = 'INSTAGRAM',
  INSTAGRAM_STORY = 'INSTAGRAM_STORY',
  OTHER = 'OTHER',
  THREADS = 'THREADS',
  TIKTOK = 'TIKTOK',
  TWITTER = 'TWITTER',
  UNSELECT = 'UNSELECT',
  YOUTUBE = 'YOUTUBE',
}

/** An enumeration. */
export enum CampaignStatusForInfluencer {
  FINISHED = 'FINISHED',
  ONGOING = 'ONGOING',
  OUT_OF_COUPON = 'OUT_OF_COUPON',
  SUSPENDED = 'SUSPENDED',
  UPCOMING = 'UPCOMING',
}

/** An enumeration. */
export enum CampaignStatusForReport {
  FINISHED = 'FINISHED',
  ONGOING = 'ONGOING',
  OUT_OF_COUPON = 'OUT_OF_COUPON',
  OVER_BUDGET = 'OVER_BUDGET',
}

export type CampaignSummaryDaily = {
  __typename?: 'CampaignSummaryDaily';
  date: Scalars['Date']['output'];
  stats: NewCampaignStatsItemRow;
};

export enum CampaignTrackingOption {
  POST = 'POST',
  REFERRAL_CODE = 'REFERRAL_CODE',
  TUNE = 'TUNE',
}

export enum CampaignType {
  AFFILIATE = 'AFFILIATE',
  AUTO_MANAGED = 'AUTO_MANAGED',
  ENGAGEMENT = 'ENGAGEMENT',
  MARKETPLACE = 'MARKETPLACE',
}

export type CampaignsForSearchJobPayload = {
  __typename?: 'CampaignsForSearchJobPayload';
  commissionRate?: Maybe<Scalars['Float']['output']>;
  createdDate: Scalars['String']['output'];
  currency: Scalars['String']['output'];
  endDate?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  isAutoInfluencerApproval: Scalars['Boolean']['output'];
  isGiftingCampaign: Scalars['Boolean']['output'];
  isSelectionCampaign: Scalars['Boolean']['output'];
  marketplaceCampaignType?: Maybe<MarketplaceCampaignDetailType>;
  minFollowers?: Maybe<Scalars['Int']['output']>;
  preLaunchDate?: Maybe<Scalars['String']['output']>;
  revenuePerAction?: Maybe<Scalars['Float']['output']>;
  revenuePerClick?: Maybe<Scalars['Float']['output']>;
  revenuePerComment?: Maybe<Scalars['Float']['output']>;
  revenuePerFollower?: Maybe<Scalars['Float']['output']>;
  revenuePerLike?: Maybe<Scalars['Float']['output']>;
  revenuePerOrder?: Maybe<Scalars['Float']['output']>;
  revenuePerPost?: Maybe<Scalars['Float']['output']>;
  revenuePerShare?: Maybe<Scalars['Float']['output']>;
  revenuePerView?: Maybe<Scalars['Float']['output']>;
  /**  null for auto-managed */
  socialMedias: Array<CampaignSocialMediaType>;
  startDate?: Maybe<Scalars['String']['output']>;
  status: MarketplaceCampaignStatus;
  thumbnail: Scalars['String']['output'];
  title: Scalars['String']['output'];
  trackingOptions: Array<CampaignTrackingOption>;
  type: CampaignType;
};

export type CancelSubscriptionPlan = {
  __typename?: 'CancelSubscriptionPlan';
  ok: Scalars['Boolean']['output'];
};

export type CancelSubscriptionPlanInput = {
  planType: PaymentPlanType;
};

export type CardInput = {
  actionLabels: Array<ActionLabelInput>;
  description?: InputMaybe<TextComponentInput>;
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<TextComponentInput>;
};

export type CardPayload = {
  __typename?: 'CardPayload';
  actionLabels: Array<ActionLabelPayload>;
  description?: Maybe<TextComponentPayload>;
  imageUrl?: Maybe<Scalars['String']['output']>;
  title?: Maybe<TextComponentPayload>;
};

export type Category = {
  __typename?: 'Category';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type CategoryName = {
  __typename?: 'CategoryName';
  description: Scalars['String']['output'];
  id: Scalars['Int']['output'];
};

export type ChangeInstagramUGCManagementPostStatusInput = {
  analyticsAccountId: Scalars['Int']['input'];
  postSnsId: Scalars['String']['input'];
  status: UGCPostStatus;
};

export type ChangeInstagramUGCManagementPostStatusOutput = {
  __typename?: 'ChangeInstagramUGCManagementPostStatusOutput';
  ok: Scalars['Boolean']['output'];
};

export enum ChangeType {
  ADDRESS1 = 'ADDRESS1',
  ADDRESS2 = 'ADDRESS2',
  BIRTHDAY = 'BIRTHDAY',
  CITY = 'CITY',
  COMMENT = 'COMMENT',
  COUNTRY_ID = 'COUNTRY_ID',
  EMAIL = 'EMAIL',
  FIRST_NAME = 'FIRST_NAME',
  GENDER = 'GENDER',
  LAST_NAME = 'LAST_NAME',
  ORDER_ID = 'ORDER_ID',
  PHONE = 'PHONE',
  PROVINCE = 'PROVINCE',
  TAGS = 'TAGS',
  ZIP = 'ZIP',
}

export type ChatEvent = {
  id: Scalars['String']['output'];
  timestamp: Scalars['String']['output'];
};

export type CheckBioLinkNameAvailabilityInput = {
  linkName: Scalars['String']['input'];
};

export type CheckBioLinkNameAvailabilityPayload = {
  __typename?: 'CheckBioLinkNameAvailabilityPayload';
  isAvailable: Scalars['Boolean']['output'];
};

export type City = {
  __typename?: 'City';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type CityForInfluencer = {
  __typename?: 'CityForInfluencer';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type ClearCmsChannelAssetOwner = {
  __typename?: 'ClearCmsChannelAssetOwner';
  ok: Scalars['Boolean']['output'];
};

export type ClearCmsChannelAssetOwnerInput = {
  channelId: Scalars['String']['input'];
  /** Asset System Id */
  id: Scalars['Int']['input'];
};

/**  `ClickNotificationByEventIdInput` input defines the uuid string `eventId` to be clicked. */
export type ClickNotificationByEventIdInput = {
  eventId: Scalars['String']['input'];
};

/**  `ClickNotificationByEventIdPayload` payload houses an `ok` boolean to determine the success of the operation. */
export type ClickNotificationByEventIdPayload = {
  __typename?: 'ClickNotificationByEventIdPayload';
  ok: Scalars['Boolean']['output'];
};

/**  `ClickNotificationInput` input defines the `notificationId` to be clicked. */
export type ClickNotificationInput = {
  id: Scalars['Long']['input'];
};

/**  `ClickNotificationPayload` payload houses an `ok` boolean to determine the success of the operation. */
export type ClickNotificationPayload = {
  __typename?: 'ClickNotificationPayload';
  ok: Scalars['Boolean']['output'];
};

export type CmsAssetShareRateInput = {
  /** Asset System Id */
  id: Scalars['Int']['input'];
  shareRate: Scalars['Float']['input'];
};

export type CmsChannelAssetInput = {
  channelAvatar?: InputMaybe<Scalars['String']['input']>;
  channelId: Scalars['String']['input'];
  channelName?: InputMaybe<Scalars['String']['input']>;
  /** Asset System Id */
  id: Scalars['Int']['input'];
};

export type CmsChannelShareRateInput = {
  /** Channel System Id */
  id: Scalars['Int']['input'];
  shareRate: Scalars['Float']['input'];
};

export type CommentContent = {
  __typename?: 'CommentContent';
  content?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  username?: Maybe<Scalars['String']['output']>;
};

export enum CommissionStatus {
  APPROVED = 'APPROVED',
  UNAPPROVED = 'UNAPPROVED',
}

export type CompanyAndRolePair = {
  /** id of company */
  companyId: Scalars['Int']['input'];
  /** company role */
  role: UserRoles;
};

export type ConnectFacebookPagesAndInstagramAccountsInput = {
  fbPageIds: Array<Scalars['String']['input']>;
  igUserIds: Array<Scalars['String']['input']>;
};

export type ConnectFacebookPagesAndInstagramAccountsPayload = {
  __typename?: 'ConnectFacebookPagesAndInstagramAccountsPayload';
  ok: Scalars['Boolean']['output'];
};

export type ConnectNewShopifyAccountPayload = {
  __typename?: 'ConnectNewShopifyAccountPayload';
  ok: Scalars['Boolean']['output'];
};

export type ConnectPackageToEngagementProposal = {
  __typename?: 'ConnectPackageToEngagementProposal';
  ok: Scalars['Boolean']['output'];
};

export type ConnectPackageToEngagementProposalInput = {
  engagementCampaignId: Scalars['Int']['input'];
  packageId: Scalars['Int']['input'];
};

export type ConnectPackageWithInfluencerAccounts = {
  __typename?: 'ConnectPackageWithInfluencerAccounts';
  ok: Scalars['Boolean']['output'];
};

export type ConnectPackageWithInfluencerAccountsInput = {
  influencerIds: Array<Scalars['Int']['input']>;
  packageId: Scalars['Int']['input'];
};

export type ConnectPackageWithSocialAccounts = {
  __typename?: 'ConnectPackageWithSocialAccounts';
  ok: Scalars['Boolean']['output'];
};

export type ConnectPackageWithSocialAccountsInput = {
  packageId: Scalars['Int']['input'];
  socialPairs?: InputMaybe<Array<SocialAccountPairInput>>;
};

export type ConnectPromotionMethodsInput = {
  methods: Array<PromotionMethodInput>;
};

export type ConnectPromotionMethodsPayload = {
  __typename?: 'ConnectPromotionMethodsPayload';
  ok: Scalars['Boolean']['output'];
};

export type ConnectableFacebookPage = {
  __typename?: 'ConnectableFacebookPage';
  avatar?: Maybe<Scalars['String']['output']>;
  followersCount: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  isAlreadyConnected: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
};

export type ConnectableFacebookPageAccount = {
  __typename?: 'ConnectableFacebookPageAccount';
  avatar: Scalars['String']['output'];
  followers: Scalars['Int']['output'];
  id: Scalars['String']['output'];
  isAlreadyUsed: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
};

export type ConnectableFacebookPageAndInstagramAccount = {
  __typename?: 'ConnectableFacebookPageAndInstagramAccount';
  igAccount?: Maybe<ConnectableInstagramAccount>;
  page: ConnectableFacebookPage;
};

export type ConnectableFacebookPageMainAccount = {
  __typename?: 'ConnectableFacebookPageMainAccount';
  avatar: Scalars['String']['output'];
  friendCount: Scalars['Int']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type ConnectableFacebookPagesAndInstagramAccountsPayload = {
  __typename?: 'ConnectableFacebookPagesAndInstagramAccountsPayload';
  accounts: Array<ConnectableFacebookPageAndInstagramAccount>;
};

export type ConnectableFacebookPagesPayload = {
  __typename?: 'ConnectableFacebookPagesPayload';
  pages: Array<ConnectableFacebookPage>;
};

export type ConnectableInstagramAccount = {
  __typename?: 'ConnectableInstagramAccount';
  avatar?: Maybe<Scalars['String']['output']>;
  followersCount: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  isAlreadyConnected: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
};

export type ConnectableInstagramAccountWithNoConnectedFlag = {
  __typename?: 'ConnectableInstagramAccountWithNoConnectedFlag';
  avatar?: Maybe<Scalars['String']['output']>;
  followersCount: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type ConnectableInstagramAccountsPayload = {
  __typename?: 'ConnectableInstagramAccountsPayload';
  igAccounts: Array<ConnectableInstagramAccountWithNoConnectedFlag>;
};

/**
 *  `id`: social network account id
 *  `avatar`: account's avatar URL from a remote social network server
 *  `name`: account's name
 *  `followersCount`: number of followers
 */
export type ConnectableOauthAccount = {
  __typename?: 'ConnectableOauthAccount';
  avatar?: Maybe<Scalars['String']['output']>;
  followersCount: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type ConnectableOauthAccountForTalentSignIn = {
  __typename?: 'ConnectableOauthAccountForTalentSignIn';
  avatar?: Maybe<Scalars['String']['output']>;
  followersCount: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  status: SocialAccountStatus;
};

export type ConnectableOauthFacebookAccountForTalentSignIn = {
  __typename?: 'ConnectableOauthFacebookAccountForTalentSignIn';
  fbName: Scalars['String']['output'];
  fbUserId: Scalars['String']['output'];
  status: SocialAccountStatus;
};

export type ConnectableOauthInstagramAccountForTalentSignIn = {
  __typename?: 'ConnectableOauthInstagramAccountForTalentSignIn';
  avatar?: Maybe<Scalars['String']['output']>;
  followersCount: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  status: SocialAccountStatus;
  username: Scalars['String']['output'];
};

/**
 *  `page`: Instagram account's Facebook page
 *  `igAccount`: Instagram account data
 *  `isAlreadyUsed`: a flag that tells if an account has been used/connected/created in analytics by someone else.
 */
export type ConnectableOauthPageAndIgAccount = {
  __typename?: 'ConnectableOauthPageAndIgAccount';
  igAccount: ConnectableOauthAccount;
  isAlreadyUsed: Scalars['Boolean']['output'];
  page: ConnectableOauthAccount;
};

export type ConnectableOauthPageAndIgAccountForSignInFacebook = {
  __typename?: 'ConnectableOauthPageAndIgAccountForSignInFacebook';
  igAccount?: Maybe<ConnectableOauthAccountForTalentSignIn>;
  isPageAlreadyConnected: Scalars['Boolean']['output'];
  page: ConnectableOauthAccountForTalentSignIn;
};

export type ConnectableOauthPageAndIgAccountForTalentSignIn = {
  __typename?: 'ConnectableOauthPageAndIgAccountForTalentSignIn';
  igAccount: ConnectableOauthInstagramAccountForTalentSignIn;
  isAlreadyUsed: Scalars['Boolean']['output'];
  page: ConnectableOauthAccountForTalentSignIn;
};

export type ConnectableOauthYouTubeAccountForTalentSignIn = {
  __typename?: 'ConnectableOauthYouTubeAccountForTalentSignIn';
  avatar: Scalars['String']['output'];
  channelId: Scalars['String']['output'];
  channelName: Scalars['String']['output'];
  isUsed: Scalars['Boolean']['output'];
  status: SocialAccountStatus;
  subscribersCount: Scalars['Int']['output'];
};

export type ConnectedAccountForLinkBio = {
  __typename?: 'ConnectedAccountForLinkBio';
  avatar: Scalars['String']['output'];
  followersCount?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  socialAccountType: SocialAccountType;
  url: Scalars['String']['output'];
};

export type ConnectedFacebook = {
  __typename?: 'ConnectedFacebook';
  fbName: Scalars['String']['output'];
  fbUserId?: Maybe<Scalars['String']['output']>;
  status: SocialAccountStatus;
};

export type ConnectedFacebookPage = {
  __typename?: 'ConnectedFacebookPage';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type ConnectedPage = {
  __typename?: 'ConnectedPage';
  pageId?: Maybe<Scalars['String']['output']>;
  pageName: Scalars['String']['output'];
  status: SocialAccountStatus;
};

export type ConnectedPageAndIgAccount = {
  __typename?: 'ConnectedPageAndIgAccount';
  accountId: Scalars['Int']['output'];
  igName: Scalars['String']['output'];
  igUserId?: Maybe<Scalars['String']['output']>;
  pageId?: Maybe<Scalars['String']['output']>;
  pageName?: Maybe<Scalars['String']['output']>;
  status: SocialAccountStatus;
};

export type ConnectedTwitter = {
  __typename?: 'ConnectedTwitter';
  status: SocialAccountStatus;
  twName: Scalars['String']['output'];
  twUserId?: Maybe<Scalars['String']['output']>;
};

export type ConnectedYouTube = {
  __typename?: 'ConnectedYouTube';
  channelId: Scalars['String']['output'];
  channelName: Scalars['String']['output'];
  status: SocialAccountStatus;
};

/** An enumeration. */
export enum ConstantHasEstimateMessage {
  YT_HAVE_DATA_PLEASE_WAIT_1_2_DAYS = 'YT_HAVE_DATA_PLEASE_WAIT_1_2_DAYS',
  YT_HAVE_NO_DATA_FOLLOWERS_LESS_THAN_10K = 'YT_HAVE_NO_DATA_FOLLOWERS_LESS_THAN_10K',
  YT_HAVE_NO_DATA_LATEST_POST_OVER_1_YEAR = 'YT_HAVE_NO_DATA_LATEST_POST_OVER_1_YEAR',
  YT_HAVE_NO_DATA_NOT_SUPPORTED_COUNTRY = 'YT_HAVE_NO_DATA_NOT_SUPPORTED_COUNTRY',
}

/**  `CountUnreadNotificationsByAppInput` input accepts `appType`: a enum type which represents the notification scope of application */
export type CountUnreadNotificationsByAppInput = {
  appType: AppType;
};

export type CountriesPayload = {
  __typename?: 'CountriesPayload';
  countries: Array<Country>;
};

export type Country = {
  __typename?: 'Country';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type CountryAndCurrency = {
  __typename?: 'CountryAndCurrency';
  currency: Scalars['String']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type CountryName = {
  __typename?: 'CountryName';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type CountryNameForInfluencer = {
  __typename?: 'CountryNameForInfluencer';
  id: Scalars['String']['output'];
  isMena: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
};

export type CreateAdmin = {
  __typename?: 'CreateAdmin';
  ok: Scalars['Boolean']['output'];
};

export type CreateAdminInput = {
  countryId: Scalars['String']['input'];
  email: Scalars['String']['input'];
  hasInvitationEmail: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  password?: InputMaybe<Scalars['String']['input']>;
  permissions?: InputMaybe<CustomPermissionsInput>;
};

export type CreateAdvertiser = {
  __typename?: 'CreateAdvertiser';
  ok: Scalars['Boolean']['output'];
};

export type CreateAdvertiserInput = {
  companyUrl?: InputMaybe<Scalars['String']['input']>;
  countryId: Scalars['String']['input'];
  name: Scalars['String']['input'];
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
};

export type CreateAdvertiserInputV2 = {
  advertiserName: Scalars['String']['input'];
  anyxMasterId?: InputMaybe<Scalars['Int']['input']>;
  companyUrl?: InputMaybe<Scalars['String']['input']>;
  countryId: Scalars['String']['input'];
  hubspotId?: InputMaybe<Scalars['String']['input']>;
  internalNetsuiteId?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
};

export type CreateAdvertiserUser = {
  __typename?: 'CreateAdvertiserUser';
  ok: Scalars['Boolean']['output'];
};

export type CreateAdvertiserUserInput = {
  advertiserId: Scalars['Int']['input'];
  countryId: Scalars['String']['input'];
  email: Scalars['String']['input'];
  hasInvitationEmail: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  notificationSetting: Scalars['Boolean']['input'];
  password?: InputMaybe<Scalars['String']['input']>;
  permissions?: InputMaybe<CustomPermissionsInput>;
};

export type CreateAdvertiserWithUser = {
  __typename?: 'CreateAdvertiserWithUser';
  ok: Scalars['Boolean']['output'];
};

export type CreateAdvertiserWithUserInput = {
  advertiserName: Scalars['String']['input'];
  anyxMasterId?: InputMaybe<Scalars['Int']['input']>;
  companyUrl?: InputMaybe<Scalars['String']['input']>;
  countryId: Scalars['String']['input'];
  hubspotId?: InputMaybe<Scalars['String']['input']>;
  internalNetsuiteId?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  user?: InputMaybe<AdvertiserUserInput>;
};

export type CreateAdvertiserWithUserInputV2 = {
  /** Needed when want to create new Advertiser */
  newAdvertiserInput?: InputMaybe<CreateAdvertiserInputV2>;
  /** None means we will create new Advertiser */
  selectedAdvertiserId?: InputMaybe<Scalars['Int']['input']>;
  user?: InputMaybe<AdvertiserUserInput>;
};

export type CreateAdvertiserWithUserV2 = {
  __typename?: 'CreateAdvertiserWithUserV2';
  ok: Scalars['Boolean']['output'];
};

export type CreateAgency = {
  __typename?: 'CreateAgency';
  ok: Scalars['Boolean']['output'];
};

export type CreateAgencyInput = {
  agencyId: Scalars['Int']['input'];
  countryId: Scalars['String']['input'];
  email: Scalars['String']['input'];
  hasInvitationEmail: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  password?: InputMaybe<Scalars['String']['input']>;
  permissions?: InputMaybe<CustomPermissionsInput>;
};

export type CreateAgencyWithUser = {
  __typename?: 'CreateAgencyWithUser';
  ok: Scalars['Boolean']['output'];
};

export type CreateAgencyWithUserInput = {
  advertiserIds: Array<Scalars['Int']['input']>;
  agencyName: Scalars['String']['input'];
  companyUrl?: InputMaybe<Scalars['String']['input']>;
  countryId: Scalars['String']['input'];
  email: Scalars['String']['input'];
  hasInvitationEmail: Scalars['Boolean']['input'];
  hubspotId?: InputMaybe<Scalars['String']['input']>;
  internalNetsuiteId?: InputMaybe<Scalars['String']['input']>;
  logo?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  password?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
};

export type CreateCreatorStaffInput = {
  email: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type CreateCreatorStaffPayload = {
  __typename?: 'CreateCreatorStaffPayload';
  ok: Scalars['Boolean']['output'];
};

export type CreateEngagement = {
  __typename?: 'CreateEngagement';
  id: Scalars['Int']['output'];
  ok: Scalars['Boolean']['output'];
};

export type CreateEngagementInput = {
  adAgencyMarginRate?: InputMaybe<Scalars['Float']['input']>;
  advertiserId: Scalars['Int']['input'];
  budget: Scalars['Float']['input'];
  campaignCategoryId: Scalars['Int']['input'];
  categoryIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  countryIds: Array<Scalars['String']['input']>;
  couponList?: InputMaybe<Scalars['String']['input']>;
  couponUploadFiles?: InputMaybe<Array<Scalars['String']['input']>>;
  currency?: InputMaybe<Scalars['String']['input']>;
  defaultPostRequirement?: InputMaybe<Scalars['String']['input']>;
  endDate: Scalars['Date']['input'];
  hashtags?: InputMaybe<Array<Scalars['String']['input']>>;
  hubspotDealIds?: InputMaybe<Array<Scalars['String']['input']>>;
  influencerManagementPicIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  isTtcmCampaign?: InputMaybe<Scalars['Boolean']['input']>;
  landingPageUrl?: InputMaybe<Scalars['String']['input']>;
  marginRate?: InputMaybe<Scalars['Float']['input']>;
  materialUrl?: InputMaybe<Scalars['String']['input']>;
  materials?: InputMaybe<Array<Scalars['String']['input']>>;
  numberOfInfluencers?: InputMaybe<Scalars['Int']['input']>;
  objective: Scalars['String']['input'];
  salesPicIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  serviceInformation?: InputMaybe<Scalars['String']['input']>;
  serviceUrl?: InputMaybe<Scalars['String']['input']>;
  socialMedias: Array<CampaignSocialMediaType>;
  startDate: Scalars['Date']['input'];
  thumbNail: Scalars['String']['input'];
  title: Scalars['String']['input'];
  trackingOptions?: InputMaybe<Array<CampaignTrackingOption>>;
};

export type CreateEngagementPostForInfluencerV2 = {
  __typename?: 'CreateEngagementPostForInfluencerV2';
  ok: Scalars['Boolean']['output'];
};

export type CreateEngagementPostInputForInfluencerV2 = {
  campaignId: Scalars['Int']['input'];
  content: Scalars['String']['input'];
  materialsUrl?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  planedPostDate: Scalars['Date']['input'];
  /** Represents social account system id */
  socialAccountId: Scalars['Int']['input'];
  /** Represents social account media type */
  socialAccountMedia: SocialAccountType;
  socialMedia: CampaignSocialMediaType;
};

export type CreateEngagementPostInputV2 = {
  campaignId: Scalars['Int']['input'];
  content?: InputMaybe<Scalars['String']['input']>;
  influencerId: Scalars['Int']['input'];
  insightDataAcquisition?: InputMaybe<Scalars['Date']['input']>;
  insightUrls?: InputMaybe<Array<Scalars['String']['input']>>;
  materialsUrl?: InputMaybe<Array<Scalars['String']['input']>>;
  planedPostDate?: InputMaybe<Scalars['Date']['input']>;
  postUrl?: InputMaybe<Scalars['String']['input']>;
  /** Represents social account system id */
  socialAccountId: Scalars['Int']['input'];
  /** Represents social account media type */
  socialAccountMedia: SocialAccountType;
};

export type CreateEngagementPostV2 = {
  __typename?: 'CreateEngagementPostV2';
  ok: Scalars['Boolean']['output'];
};

export type CreateInfluencerBioInput = {
  linkName: Scalars['String']['input'];
};

export type CreateInfluencerBioPayload = {
  __typename?: 'CreateInfluencerBioPayload';
  ok: Scalars['Boolean']['output'];
};

export type CreateInfluencerInputV2 = {
  addressInformation?: InputMaybe<AddressInput>;
  campaignDisplay: Scalars['Boolean']['input'];
  categoryIds: Array<Scalars['Int']['input']>;
  countryId: Scalars['String']['input'];
  dateOfBirth?: InputMaybe<Scalars['Date']['input']>;
  defaultEngagementSelectionReason?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  facebookAccount?: InputMaybe<InfluencerSocialNetworkAccountInput>;
  facebookPages?: InputMaybe<Array<InfluencerSocialNetworkAccountInput>>;
  gender: Genders;
  instagramAccounts?: InputMaybe<Array<InfluencerSocialNetworkAccountInput>>;
  internalMemo?: InputMaybe<Scalars['String']['input']>;
  introduce?: InputMaybe<Scalars['String']['input']>;
  isBrandAccount?: InputMaybe<Scalars['Boolean']['input']>;
  managerIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  name: Scalars['String']['input'];
  notificationSetting: Scalars['Boolean']['input'];
  partnerIds: Array<Scalars['Int']['input']>;
  paymentInformation?: InputMaybe<PaymentInformationInput>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  rateCards?: InputMaybe<InfluencerRateCardsInput>;
  regionId?: InputMaybe<Scalars['Int']['input']>;
  tags: Array<Scalars['String']['input']>;
  talentAgencyIds: Array<Scalars['Int']['input']>;
  threadsAccounts?: InputMaybe<Array<InfluencerSocialNetworkAccountInput>>;
  tiktokAccounts?: InputMaybe<Array<InfluencerSocialNetworkAccountInput>>;
  twitterAccounts?: InputMaybe<Array<InfluencerSocialNetworkAccountInput>>;
  youtubeAccounts?: InputMaybe<Array<InfluencerSocialNetworkAccountInput>>;
};

export type CreateInfluencerStaffInput = {
  email: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type CreateInfluencerStaffPayload = {
  __typename?: 'CreateInfluencerStaffPayload';
  ok: Scalars['Boolean']['output'];
};

export type CreateInfluencerV2 = {
  __typename?: 'CreateInfluencerV2';
  ok: Scalars['Boolean']['output'];
};

export type CreateInstagramUGCManagementHashtagInput = {
  analyticsAccountId: Scalars['Int']['input'];
  hashtag: Scalars['String']['input'];
};

export type CreateInstagramUGCManagementHashtagOutput = {
  __typename?: 'CreateInstagramUGCManagementHashtagOutput';
  ok: Scalars['Boolean']['output'];
};

export type CreateLineBroadcastMessageInput = {
  deliveryTiming: DeliveryTimingItemInput;
  lineContent: Array<LineContentItemInput>;
  recipient: RecipientItemInput;
  title: Scalars['String']['input'];
};

export type CreateLineBroadcastMessagePayload = {
  __typename?: 'CreateLineBroadcastMessagePayload';
  messageId: Scalars['String']['output'];
};

export type CreateLineChannelInput = {
  liffId: Scalars['String']['input'];
  lineChannelId: Scalars['String']['input'];
  lineChannelSecret: Scalars['String']['input'];
  lineKid: Scalars['String']['input'];
  lineLoginChannelId: Scalars['String']['input'];
  publicKeyId: Scalars['Long']['input'];
};

export type CreateLineChannelPayload = {
  __typename?: 'CreateLineChannelPayload';
  ok: Scalars['Boolean']['output'];
};

export type CreateMarketplace = {
  __typename?: 'CreateMarketplace';
  ok: Scalars['Boolean']['output'];
};

export type CreateMarketplaceDraftPost = {
  __typename?: 'CreateMarketplaceDraftPost';
  ok: Scalars['Boolean']['output'];
};

export type CreateMarketplaceDraftPostInput = {
  campaignId: Scalars['Int']['input'];
  caption: Scalars['String']['input'];
  materialsUrl: Array<Scalars['String']['input']>;
};

export type CreateMarketplaceInput = {
  advertiserId: Scalars['Int']['input'];
  agencyMarginRate?: InputMaybe<Scalars['Float']['input']>;
  allowNewInfluencer: Scalars['Boolean']['input'];
  brandName?: InputMaybe<Scalars['String']['input']>;
  budget: Scalars['Float']['input'];
  campaignCategoryId: Scalars['Int']['input'];
  campaignType: MarketplaceCampaignDetailType;
  campaignUrl?: InputMaybe<Scalars['String']['input']>;
  categoryIds: Array<Scalars['Int']['input']>;
  clickUrl?: InputMaybe<Scalars['String']['input']>;
  /** for TUNE Tracking */
  costPerAction?: InputMaybe<Scalars['Float']['input']>;
  /** for TUNE Tracking */
  costPerClick?: InputMaybe<Scalars['Float']['input']>;
  costPerComment?: InputMaybe<Scalars['Float']['input']>;
  costPerFollower?: InputMaybe<Scalars['Float']['input']>;
  costPerLike?: InputMaybe<Scalars['Float']['input']>;
  /** for referral code Tracking */
  costPerOrder?: InputMaybe<Scalars['Float']['input']>;
  costPerPost?: InputMaybe<Scalars['Float']['input']>;
  /** for referral code Tracking */
  costPerSaleReferral?: InputMaybe<Scalars['Float']['input']>;
  /** for TUNE Tracking */
  costPerSaleTune?: InputMaybe<Scalars['Float']['input']>;
  costPerShare?: InputMaybe<Scalars['Float']['input']>;
  costPerView?: InputMaybe<Scalars['Float']['input']>;
  countryId: Scalars['String']['input'];
  couponUploadFiles?: InputMaybe<Array<Scalars['String']['input']>>;
  endDate?: InputMaybe<Scalars['Date']['input']>;
  genders: Array<Genders>;
  hashtags: Array<Scalars['String']['input']>;
  hubspotDealIds?: InputMaybe<Array<Scalars['String']['input']>>;
  influencerManagementPicIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  isAutoInfluencerApproval?: InputMaybe<Scalars['Boolean']['input']>;
  isGiftingCampaign?: InputMaybe<Scalars['Boolean']['input']>;
  landingPageUrl?: InputMaybe<Scalars['String']['input']>;
  marginRate: Scalars['Float']['input'];
  materialUrl?: InputMaybe<Scalars['String']['input']>;
  materials?: InputMaybe<Array<Scalars['String']['input']>>;
  maxAge: Scalars['Int']['input'];
  maxFollowers: Scalars['Int']['input'];
  maximumRevenuePerInfluencer?: InputMaybe<Scalars['Float']['input']>;
  minAge: Scalars['Int']['input'];
  minFollowers: Scalars['Int']['input'];
  mkpServiceCampaignId?: InputMaybe<Scalars['Int']['input']>;
  preLaunchDate?: InputMaybe<Scalars['Date']['input']>;
  productThumbNail?: InputMaybe<Scalars['String']['input']>;
  regionIds: Array<Scalars['Int']['input']>;
  requireDraftPost?: InputMaybe<Scalars['Boolean']['input']>;
  requirement: Scalars['String']['input'];
  salesPicIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  sampleUrl?: InputMaybe<Scalars['String']['input']>;
  serviceInformation: Scalars['String']['input'];
  socialMedia: CampaignSocialMediaType;
  startDate: Scalars['Date']['input'];
  thumbnails: Array<Scalars['String']['input']>;
  title: Scalars['String']['input'];
  trackingOptions?: InputMaybe<Array<CampaignTrackingOption>>;
};

export type CreatePackage = {
  __typename?: 'CreatePackage';
  ok: Scalars['Boolean']['output'];
};

export type CreatePackageInput = {
  companyAndRolePairs: Array<CompanyAndRolePair>;
  countryId: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type CreatePackageWithInfluencerAccounts = {
  __typename?: 'CreatePackageWithInfluencerAccounts';
  ok: Scalars['Boolean']['output'];
};

export type CreatePackageWithInfluencerAccountsInput = {
  companyAndRolePairs: Array<CompanyAndRolePair>;
  countryId: Scalars['String']['input'];
  influencerIds: Array<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
};

export type CreatePackageWithSocialAccounts = {
  __typename?: 'CreatePackageWithSocialAccounts';
  ok: Scalars['Boolean']['output'];
};

export type CreatePackageWithSocialAccountsInput = {
  companyAndRolePairs: Array<CompanyAndRolePair>;
  countryId: Scalars['String']['input'];
  name: Scalars['String']['input'];
  socialPairs?: InputMaybe<Array<SocialAccountPairInput>>;
};

export type CreatePartnerUser = {
  __typename?: 'CreatePartnerUser';
  ok: Scalars['Boolean']['output'];
};

export type CreatePartnerUserInput = {
  countryId: Scalars['String']['input'];
  email: Scalars['String']['input'];
  hasInvitationEmail: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  /** Partner Agency Id */
  partnerId: Scalars['Int']['input'];
  password?: InputMaybe<Scalars['String']['input']>;
  permissions?: InputMaybe<CustomPermissionsInput>;
};

export type CreatePaymentRequest = {
  __typename?: 'CreatePaymentRequest';
  ok: Scalars['Boolean']['output'];
};

export type CreateSelfRegisteredAdvertiserUser = {
  __typename?: 'CreateSelfRegisteredAdvertiserUser';
  ok: Scalars['Boolean']['output'];
};

export type CreateSelfRegisteredAdvertiserUserInput = {
  advertiserCompanyName: Scalars['String']['input'];
  advertiserCompanyUrl: Scalars['String']['input'];
  advertiserPhoneNumber: Scalars['String']['input'];
  /** Represents country of Advertiser and its User */
  countryId: Scalars['String']['input'];
  userEmail: Scalars['String']['input'];
  userName: Scalars['String']['input'];
};

export type CreateSocialAccountAnalyticsAdminInputV2 = {
  accountManagersPICs?: InputMaybe<Array<Scalars['Int']['input']>>;
  accountName: Scalars['String']['input'];
  advertiserId: Scalars['Int']['input'];
  facebookPageId?: InputMaybe<Scalars['String']['input']>;
  instagramUserId?: InputMaybe<Scalars['String']['input']>;
  twitterUserId?: InputMaybe<Scalars['String']['input']>;
  youtubeChannelId?: InputMaybe<Scalars['String']['input']>;
};

export type CreateSocialAccountAnalyticsAdminOutputV2 = {
  __typename?: 'CreateSocialAccountAnalyticsAdminOutputV2';
  ok: Scalars['Boolean']['output'];
};

export type CreateSocialAccountAnalyticsAdvertiserInputV2 = {
  accountName: Scalars['String']['input'];
  facebookPageId?: InputMaybe<Scalars['String']['input']>;
  instagramUserId?: InputMaybe<Scalars['String']['input']>;
  twitterUserId?: InputMaybe<Scalars['String']['input']>;
  youtubeChannelId?: InputMaybe<Scalars['String']['input']>;
};

export type CreateSocialAccountAnalyticsAdvertiserOutputV2 = {
  __typename?: 'CreateSocialAccountAnalyticsAdvertiserOutputV2';
  ok: Scalars['Boolean']['output'];
};

export type CreateStaff = {
  __typename?: 'CreateStaff';
  ok: Scalars['Boolean']['output'];
};

export type CreateStaffInput = {
  advertiserIds: Array<Scalars['Int']['input']>;
  autoLinkToAdvertiser: Scalars['Boolean']['input'];
  countryId: Scalars['String']['input'];
  email: Scalars['String']['input'];
  hasInvitationEmail: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  password?: InputMaybe<Scalars['String']['input']>;
  permissions?: InputMaybe<CustomPermissionsInput>;
};

export type CreateTalentAgencyUser = {
  __typename?: 'CreateTalentAgencyUser';
  ok: Scalars['Boolean']['output'];
};

export type CreateTalentAgencyUserInput = {
  countryId: Scalars['String']['input'];
  email: Scalars['String']['input'];
  hasInvitationEmail: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  password?: InputMaybe<Scalars['String']['input']>;
  permissions?: InputMaybe<CustomPermissionsInput>;
  /** Talent Agency Id */
  talentAgencyId: Scalars['Int']['input'];
};

export type CreateTalentAgencyWithUser = {
  __typename?: 'CreateTalentAgencyWithUser';
  ok: Scalars['Boolean']['output'];
};

export type CreateTalentAgencyWithUserInput = {
  advertiserIds: Array<Scalars['Int']['input']>;
  agencyIds: Array<Scalars['Int']['input']>;
  companyUrl?: InputMaybe<Scalars['String']['input']>;
  countryId: Scalars['String']['input'];
  email: Scalars['String']['input'];
  hasInvitationEmail: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  netsuiteId?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  talentAgencyName: Scalars['String']['input'];
};

export type CreateTalentInfluencerInput = {
  categoryIds: Array<Scalars['Int']['input']>;
  countryId: Scalars['String']['input'];
  dateOfBirth: Scalars['String']['input'];
  defaultEngagementSelectionReason?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  gender: Genders;
  internalMemo?: InputMaybe<Scalars['String']['input']>;
  introduce?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  phoneNumber: Scalars['String']['input'];
  rateCards?: InputMaybe<RateCardsInput>;
  regionId: Scalars['Int']['input'];
  selectedSocialAccounts: SelectedSocialAccounts;
  tags: Array<Scalars['String']['input']>;
};

export type CreateTalentInfluencerPayload = {
  __typename?: 'CreateTalentInfluencerPayload';
  ok: Scalars['Boolean']['output'];
};

export type CreatorAddressPayload = {
  __typename?: 'CreatorAddressPayload';
  address: Scalars['String']['output'];
  address2: Scalars['String']['output'];
  city: Scalars['String']['output'];
  fullName: Scalars['String']['output'];
  phoneNumber?: Maybe<Scalars['String']['output']>;
  postalCode: Scalars['String']['output'];
  province: Scalars['String']['output'];
};

export type CreatorStaff = {
  __typename?: 'CreatorStaff';
  email: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type CreatorStaffsCountPayload = {
  __typename?: 'CreatorStaffsCountPayload';
  total: Scalars['Int']['output'];
};

export type CreatorStaffsInput = {
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
};

export type CreatorStaffsPayload = {
  __typename?: 'CreatorStaffsPayload';
  avatar: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  staffs: Array<CreatorStaff>;
};

export type CurrentUserCurrency = {
  __typename?: 'CurrentUserCurrency';
  currency: Scalars['String']['output'];
};

export type CustomPermissionLevel = {
  __typename?: 'CustomPermissionLevel';
  manage?: Maybe<Scalars['Boolean']['output']>;
  manageAndDelete?: Maybe<Scalars['Boolean']['output']>;
  noView?: Maybe<Scalars['Boolean']['output']>;
  view?: Maybe<Scalars['Boolean']['output']>;
};

export type CustomPermissionLevelInput = {
  manage?: InputMaybe<Scalars['Boolean']['input']>;
  manageAndDelete?: InputMaybe<Scalars['Boolean']['input']>;
  noView?: InputMaybe<Scalars['Boolean']['input']>;
  view?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CustomPermissions = {
  __typename?: 'CustomPermissions';
  advertiser?: Maybe<CustomPermissionLevel>;
  agency?: Maybe<CustomPermissionLevel>;
  analytics?: Maybe<CustomPermissionLevel>;
  customizeUserPermissions?: Maybe<CustomPermissionLevel>;
  engagement?: Maybe<CustomPermissionLevel>;
  influencer?: Maybe<CustomPermissionLevel>;
  influencerAddressAndPaymentInformation?: Maybe<CustomPermissionLevel>;
  influencerPackage?: Maybe<CustomPermissionLevel>;
  marketplace?: Maybe<CustomPermissionLevel>;
  partnerAgency?: Maybe<CustomPermissionLevel>;
  payment?: Maybe<CustomPermissionLevel>;
  report?: Maybe<CustomPermissionLevel>;
  talentAgency?: Maybe<CustomPermissionLevel>;
  user?: Maybe<CustomPermissionLevel>;
};

export type CustomPermissionsInput = {
  advertiser?: InputMaybe<CustomPermissionLevelInput>;
  agency?: InputMaybe<CustomPermissionLevelInput>;
  analytics?: InputMaybe<CustomPermissionLevelInput>;
  customizeUserPermissions?: InputMaybe<CustomPermissionLevelInput>;
  engagement?: InputMaybe<CustomPermissionLevelInput>;
  influencer?: InputMaybe<CustomPermissionLevelInput>;
  influencerAddressAndPaymentInformation?: InputMaybe<CustomPermissionLevelInput>;
  influencerPackage?: InputMaybe<CustomPermissionLevelInput>;
  marketplace?: InputMaybe<CustomPermissionLevelInput>;
  partnerAgency?: InputMaybe<CustomPermissionLevelInput>;
  payment?: InputMaybe<CustomPermissionLevelInput>;
  report?: InputMaybe<CustomPermissionLevelInput>;
  talentAgency?: InputMaybe<CustomPermissionLevelInput>;
  user?: InputMaybe<CustomPermissionLevelInput>;
};

export enum CustomerTagOrder {
  BY_ALPHABETICAL = 'BY_ALPHABETICAL',
  BY_FREQUENTLY_USED = 'BY_FREQUENTLY_USED',
}

export type CustomerTagPayload = {
  __typename?: 'CustomerTagPayload';
  name: Scalars['String']['output'];
};

export type DeleteAdvertiser = {
  __typename?: 'DeleteAdvertiser';
  ok: Scalars['Boolean']['output'];
};

export type DeleteAgency = {
  __typename?: 'DeleteAgency';
  ok: Scalars['Boolean']['output'];
};

export type DeleteBrandAccountAnalyticsInput = {
  brandAccountId: Scalars['Int']['input'];
};

export type DeleteBrandAccountAnalyticsOutput = {
  __typename?: 'DeleteBrandAccountAnalyticsOutput';
  ok: Scalars['Boolean']['output'];
};

export type DeleteCommentInput = {
  id: Scalars['Long']['input'];
};

export type DeleteCommentPayload = {
  __typename?: 'DeleteCommentPayload';
  ok: Scalars['Boolean']['output'];
};

export type DeleteCompareInstagramAccountAnalytics = {
  __typename?: 'DeleteCompareInstagramAccountAnalytics';
  ok: Scalars['Boolean']['output'];
};

export type DeleteEngagement = {
  __typename?: 'DeleteEngagement';
  ok: Scalars['Boolean']['output'];
};

export type DeleteEngagementInfluencerProposal = {
  __typename?: 'DeleteEngagementInfluencerProposal';
  ok: Scalars['Boolean']['output'];
};

export type DeleteEngagementInfluencerProposalInput = {
  campaignId: Scalars['Int']['input'];
  influencerIds: Array<Scalars['Int']['input']>;
};

export type DeleteEngagementPostForInfluencer = {
  __typename?: 'DeleteEngagementPostForInfluencer';
  ok: Scalars['Boolean']['output'];
};

export type DeleteEngagementProposalJoinedAccount = {
  __typename?: 'DeleteEngagementProposalJoinedAccount';
  ok: Scalars['Boolean']['output'];
};

export type DeleteEngagementProposalJoinedAccountInput = {
  campaignId: Scalars['Int']['input'];
  influencerId: Scalars['Int']['input'];
  socialAccountId: Scalars['Int']['input'];
  socialAccountMedia: EngagementProposalSocialMediaType;
};

export type DeleteEngagementSocialAccountProposal = {
  __typename?: 'DeleteEngagementSocialAccountProposal';
  ok: Scalars['Boolean']['output'];
};

export type DeleteEngagementSocialAccountProposalInput = {
  campaignId: Scalars['Int']['input'];
  influencerIds: Array<Scalars['Int']['input']>;
  socialAccountIds: Array<Scalars['Int']['input']>;
  socialAccountMedia: SocialAccountType;
};

export type DeleteFanTagsInput = {
  fanIds: Array<Scalars['Long']['input']>;
  tagIds: Array<Scalars['Long']['input']>;
};

export type DeleteFanTagsPayload = {
  __typename?: 'DeleteFanTagsPayload';
  ok: Scalars['Boolean']['output'];
};

export type DeleteInfluencer = {
  __typename?: 'DeleteInfluencer';
  ok: Scalars['Boolean']['output'];
};

export type DeleteInstagramCompareAccountAnalyticsInput = {
  /** system id of a compare account that we want to delete */
  compareAccountId: Scalars['Int']['input'];
  /** an analytics account system id the selected compare account was connected to */
  mainAnalyticsAccountId: Scalars['Int']['input'];
};

export type DeleteInstagramHashtagAnalytics = {
  __typename?: 'DeleteInstagramHashtagAnalytics';
  ok: Scalars['Boolean']['output'];
};

export type DeleteInstagramHashtagAnalyticsInput = {
  /** an analytics account system id the selected hashtag was connected to */
  analyticsAccountId: Scalars['Int']['input'];
  /** a hashtag's system id that we want to delete */
  hashtagId: Scalars['Int']['input'];
};

export type DeleteInstagramUGCManagementHashtagInput = {
  analyticsAccountId: Scalars['Int']['input'];
  hashtagSystemId: Scalars['Int']['input'];
};

export type DeleteInstagramUGCManagementHashtagOutput = {
  __typename?: 'DeleteInstagramUGCManagementHashtagOutput';
  ok: Scalars['Boolean']['output'];
};

export type DeleteMarketplace = {
  __typename?: 'DeleteMarketplace';
  ok: Scalars['Boolean']['output'];
};

export type DeletePackage = {
  __typename?: 'DeletePackage';
  ok: Scalars['Boolean']['output'];
};

export type DeleteSocialAccountPackage = {
  __typename?: 'DeleteSocialAccountPackage';
  ok: Scalars['Boolean']['output'];
};

export type DeleteTalentAgency = {
  __typename?: 'DeleteTalentAgency';
  ok: Scalars['Boolean']['output'];
};

export type DeleteUser = {
  __typename?: 'DeleteUser';
  ok: Scalars['Boolean']['output'];
};

export type DeleteYoutubeCompareAccountAnalyticsInput = {
  compareAccountId: Scalars['Int']['input'];
  mainAccountId: Scalars['Int']['input'];
};

export type DeleteYoutubeCompareAccountAnalyticsOutput = {
  __typename?: 'DeleteYoutubeCompareAccountAnalyticsOutput';
  ok: Scalars['Boolean']['output'];
};

export type DeliveryTimingItemInput = {
  scheduleDate?: InputMaybe<Scalars['String']['input']>;
  type: DeliveryTimingType;
};

export type DeliveryTimingItemPayload = {
  __typename?: 'DeliveryTimingItemPayload';
  scheduleDate?: Maybe<Scalars['String']['output']>;
  type: DeliveryTimingType;
};

export enum DeliveryTimingType {
  IMMEDIATELY_SEND = 'IMMEDIATELY_SEND',
  SCHEDULE_SEND = 'SCHEDULE_SEND',
}

export type DenyEngagementInfluencerProposalList = {
  __typename?: 'DenyEngagementInfluencerProposalList';
  ok: Scalars['Boolean']['output'];
};

export type DenyEngagementInfluencerProposalListInput = {
  campaignId: Scalars['Int']['input'];
  deniedReason: Scalars['String']['input'];
  influencerIds: Array<Scalars['Int']['input']>;
};

export enum DisplayLanguage {
  ARABIC = 'ARABIC',
  BAHASA = 'BAHASA',
  CAMBODIA = 'CAMBODIA',
  ENGLISH = 'ENGLISH',
  JAPANESE = 'JAPANESE',
  MYANMAR = 'MYANMAR',
  SIMPLIFIED_CHINESE = 'SIMPLIFIED_CHINESE',
  THAI = 'THAI',
  TRADITIONAL_CHINESE = 'TRADITIONAL_CHINESE',
  VIETNAMESE = 'VIETNAMESE',
}

export type EditSocialAccountAnalyticsAdminInputV2 = {
  accountManagersPICs?: InputMaybe<Array<Scalars['Int']['input']>>;
  accountName: Scalars['String']['input'];
  brandAccountId: Scalars['Int']['input'];
  facebookPageId?: InputMaybe<Scalars['String']['input']>;
  igFacebookPageId?: InputMaybe<Scalars['String']['input']>;
  twitterUserId?: InputMaybe<Scalars['String']['input']>;
  youtubeChannelId?: InputMaybe<Scalars['String']['input']>;
};

export type EditSocialAccountAnalyticsAdminOutputV2 = {
  __typename?: 'EditSocialAccountAnalyticsAdminOutputV2';
  ok: Scalars['Boolean']['output'];
};

export type EditSocialAccountAnalyticsAdvertiserInputV2 = {
  accountName: Scalars['String']['input'];
  brandAccountId: Scalars['Int']['input'];
  facebookPageId?: InputMaybe<Scalars['String']['input']>;
  igFacebookPageId?: InputMaybe<Scalars['String']['input']>;
  twitterUserId?: InputMaybe<Scalars['String']['input']>;
  youtubeChannelId?: InputMaybe<Scalars['String']['input']>;
};

export type EditSocialAccountAnalyticsAdvertiserOutputV2 = {
  __typename?: 'EditSocialAccountAnalyticsAdvertiserOutputV2';
  ok: Scalars['Boolean']['output'];
};

export enum EmailEventStatus {
  BLOCKED = 'BLOCKED',
  BOUNCE = 'BOUNCE',
  CLICK = 'CLICK',
  DEFERRED = 'DEFERRED',
  DELIVERED = 'DELIVERED',
  DROPPED = 'DROPPED',
  GROUP_RESUBSCRIBE = 'GROUP_RESUBSCRIBE',
  GROUP_UNSUBSCRIBE = 'GROUP_UNSUBSCRIBE',
  OPEN = 'OPEN',
  PROCESSED = 'PROCESSED',
  SPAMREPORT = 'SPAMREPORT',
  UNSUBSCRIBE = 'UNSUBSCRIBE',
}

export enum EmailEventStatusForSearch {
  BOUNCE = 'BOUNCE',
  CLICK = 'CLICK',
  OPEN = 'OPEN',
}

export type EmailInfo = {
  __typename?: 'EmailInfo';
  fromEmail: Scalars['String']['output'];
  message: Scalars['String']['output'];
  sentAt: Scalars['String']['output'];
  subject: Scalars['String']['output'];
  tags?: Maybe<Array<Scalars['String']['output']>>;
  toEmails?: Maybe<Array<Scalars['String']['output']>>;
};

export type EmailInfoForList = {
  __typename?: 'EmailInfoForList';
  clickRate?: Maybe<Scalars['Float']['output']>;
  id: Scalars['Long']['output'];
  openRate?: Maybe<Scalars['Float']['output']>;
  scheduleDate?: Maybe<Scalars['String']['output']>;
  sentAt?: Maybe<Scalars['String']['output']>;
  status: EmailTemplateStatus;
  subject: Scalars['String']['output'];
};

export type EmailListOrderBy = {
  field: EmailListSortableField;
  order: Order;
};

export enum EmailListSortableField {
  CLICK_RATE = 'CLICK_RATE',
  CREATED = 'CREATED',
  OPEN_RATE = 'OPEN_RATE',
}

export type EmailStatistics = {
  __typename?: 'EmailStatistics';
  bounceCount: Scalars['Long']['output'];
  clickCount: Scalars['Long']['output'];
  openCount: Scalars['Long']['output'];
  recipientsCount: Scalars['Long']['output'];
  unsubscribeCount: Scalars['Long']['output'];
};

export enum EmailTemplateStatus {
  DRAFT = 'DRAFT',
  PUBLISHED = 'PUBLISHED',
  SCHEDULED = 'SCHEDULED',
}

export type Engagement = {
  __typename?: 'Engagement';
  advertiser: AdvertiserName;
  budget: Scalars['Float']['output'];
  countries: Array<CountryName>;
  currency?: Maybe<Scalars['String']['output']>;
  defaultPostRequirement?: Maybe<Scalars['String']['output']>;
  endDate: Scalars['Date']['output'];
  id: Scalars['Int']['output'];
  influencerManagementPics?: Maybe<Array<PicName>>;
  salesPics?: Maybe<Array<PicName>>;
  socialMedias: Array<CampaignSocialMediaType>;
  startDate: Scalars['Date']['output'];
  status: EngagementCampaignStatus;
  title: Scalars['String']['output'];
};

export type EngagementAddInfluencersToProposalFromAnotherCampaign = {
  __typename?: 'EngagementAddInfluencersToProposalFromAnotherCampaign';
  ok: Scalars['Boolean']['output'];
};

export type EngagementAddInfluencersToProposalFromAnotherCampaignInput = {
  /** Get influencers from campaign */
  fromCampaignId: Scalars['Int']['input'];
  /** Put influencers to campaign */
  toCampaignId: Scalars['Int']['input'];
};

export type EngagementCampaignCouponTrackingInput = {
  campaignId: Scalars['Int']['input'];
  couponCode: Scalars['String']['input'];
  saleAmount: Scalars['Float']['input'];
  transactionId: Scalars['String']['input'];
};

export type EngagementCampaignCouponTrackingPayload = {
  __typename?: 'EngagementCampaignCouponTrackingPayload';
  ok: Scalars['Boolean']['output'];
};

export type EngagementCampaignPostReportAudienceBreakdown = {
  __typename?: 'EngagementCampaignPostReportAudienceBreakdown';
  tiktok?: Maybe<EngagementCampaignPostReportAudienceBreakdownForTikTok>;
  youtube?: Maybe<EngagementCampaignPostReportAudienceBreakdownForYouTube>;
};

export type EngagementCampaignPostReportAudienceBreakdownForTikTok = {
  __typename?: 'EngagementCampaignPostReportAudienceBreakdownForTikTok';
  ageRates: AudienceBreakdownAgeRates;
  areaRates: Array<AudienceBreakdownAreaRate>;
  genderRates: AudienceBreakdownGenderRates;
};

export type EngagementCampaignPostReportAudienceBreakdownForYouTube = {
  __typename?: 'EngagementCampaignPostReportAudienceBreakdownForYouTube';
  ageGenderRates: AudienceBreakdownAgeGenderRates;
  areaRates: Array<AudienceBreakdownAreaRate>;
  genderRates: AudienceBreakdownGenderRates;
};

export type EngagementCampaignPostReportForInfluencer = {
  __typename?: 'EngagementCampaignPostReportForInfluencer';
  currency: Scalars['String']['output'];
  /** campaign ID */
  id: Scalars['Int']['output'];
  postReport: Array<InfluencerPostReportStatsEngagement>;
  /** campaign title */
  title: Scalars['String']['output'];
};

/** An enumeration. */
export enum EngagementCampaignPostStatus {
  APPROVED = 'APPROVED',
  EFFECTIVE = 'EFFECTIVE',
  INEFFECTIVE = 'INEFFECTIVE',
  READY_TO_POST = 'READY_TO_POST',
  REJECT = 'REJECT',
  REVIEWING = 'REVIEWING',
  TTCM_REVIEWING = 'TTCM_REVIEWING',
  WARNING = 'WARNING',
  WARNING_SOLVED = 'WARNING_SOLVED',
}

/** An enumeration. */
export enum EngagementCampaignPostStatusForInsightCheck {
  CONFIRMED = 'CONFIRMED',
  UNCONFIRMED = 'UNCONFIRMED',
}

/** An enumeration. */
export enum EngagementCampaignPostStatusForSearch {
  AD_FRAUD = 'AD_FRAUD',
  API_ERROR = 'API_ERROR',
  APPROVED = 'APPROVED',
  DELETED_POST = 'DELETED_POST',
  EFFECTIVE = 'EFFECTIVE',
  EXPIRED_TOKEN = 'EXPIRED_TOKEN',
  INEFFECTIVE = 'INEFFECTIVE',
  INVALID_CONTENT = 'INVALID_CONTENT',
  JOINED = 'JOINED',
  READY_TO_POST = 'READY_TO_POST',
  REJECT = 'REJECT',
  REVIEWING = 'REVIEWING',
  SCRAPING_ERROR = 'SCRAPING_ERROR',
  WARNING = 'WARNING',
  WARNING_SOLVED = 'WARNING_SOLVED',
}

export type EngagementCampaignPostsForInsightCheck = {
  __typename?: 'EngagementCampaignPostsForInsightCheck';
  campaignTitle: Scalars['String']['output'];
  posts: Array<EngagementPostForInsightCheck>;
};

export type EngagementCampaignReport = {
  __typename?: 'EngagementCampaignReport';
  InternalInfo?: Maybe<Array<Scalars['String']['output']>>;
  average?: Maybe<NewCampaignStatsItemRow>;
  campaignInfo: CampaignReportCampaignInfo;
  chartData: Array<CampaignChartReport>;
  currency: Scalars['String']['output'];
  influencerReports: Array<CampaignInfluencerReport>;
  postCount: Scalars['Int']['output'];
  summary: NewCampaignStatsItemRow;
  summaryDaily?: Maybe<Array<CampaignSummaryDaily>>;
};

export type EngagementCampaignSearchSelectInfluencers = {
  __typename?: 'EngagementCampaignSearchSelectInfluencers';
  /** Campaign's category */
  category: Scalars['String']['output'];
  /** Campaign's countries */
  countries: Array<Scalars['String']['output']>;
  /** Campaign's engagement rate */
  engagementRate?: Maybe<Scalars['Float']['output']>;
  /** Campaign's id */
  id: Scalars['Int']['output'];
  /** Campaign's name */
  name: Scalars['String']['output'];
  /** Number of selected influencers in the campaign */
  numberOfInfluencers: Scalars['Int']['output'];
};

/** An enumeration. */
export enum EngagementCampaignSocialAccountPostStatus {
  APPROVED = 'APPROVED',
  EFFECTIVE = 'EFFECTIVE',
  INEFFECTIVE = 'INEFFECTIVE',
  JOINED = 'JOINED',
  JOINED_WITH_PENDING_INVITATION = 'JOINED_WITH_PENDING_INVITATION',
  JOINED_WITH_PENDING_ORDER = 'JOINED_WITH_PENDING_ORDER',
  READY_TO_POST = 'READY_TO_POST',
  REJECT = 'REJECT',
  REVIEWING = 'REVIEWING',
  TTCM_REVIEWING = 'TTCM_REVIEWING',
  WARNING = 'WARNING',
  WARNING_SOLVED = 'WARNING_SOLVED',
}

/** An enumeration. */
export enum EngagementCampaignStatus {
  DEMO = 'DEMO',
  DRAFT = 'DRAFT',
  FINISHED = 'FINISHED',
  LOST = 'LOST',
  ONGOING = 'ONGOING',
  REVIEWING = 'REVIEWING',
  UPCOMING = 'UPCOMING',
}

export type EngagementDetail = {
  __typename?: 'EngagementDetail';
  adAgencyMarginRate?: Maybe<Scalars['Float']['output']>;
  advertiser: AdvertiserName;
  budget: Scalars['Float']['output'];
  campaignCategory?: Maybe<CampaignCategoryName>;
  campaignCreator?: Maybe<CampaignCreator>;
  categories: Array<CategoryName>;
  countries: Array<CountryName>;
  couponList?: Maybe<Scalars['String']['output']>;
  couponUploadFiles?: Maybe<Array<Scalars['String']['output']>>;
  currency?: Maybe<Scalars['String']['output']>;
  defaultPostRequirement?: Maybe<Scalars['String']['output']>;
  endDate: Scalars['Date']['output'];
  genders: Array<Genders>;
  hashtags?: Maybe<Array<Scalars['String']['output']>>;
  hubspotDealIds?: Maybe<Array<Scalars['String']['output']>>;
  id: Scalars['Int']['output'];
  influencerManagementPics?: Maybe<Array<PicName>>;
  isTtcmCampaign: Scalars['Boolean']['output'];
  landingPageUrl?: Maybe<Scalars['String']['output']>;
  marginRate?: Maybe<Scalars['Float']['output']>;
  materialUrl?: Maybe<Scalars['String']['output']>;
  materials?: Maybe<Array<Scalars['String']['output']>>;
  maxAge: Scalars['Int']['output'];
  maxFollowers: Scalars['Int']['output'];
  minAge: Scalars['Int']['output'];
  minFollowers: Scalars['Int']['output'];
  numberOfInfluencers?: Maybe<Scalars['Int']['output']>;
  objective: Scalars['String']['output'];
  reportStartDate?: Maybe<Scalars['Date']['output']>;
  salesPics?: Maybe<Array<PicName>>;
  serviceInformation: Scalars['String']['output'];
  serviceUrl?: Maybe<Scalars['String']['output']>;
  socialMedias: Array<CampaignSocialMediaType>;
  startDate: Scalars['Date']['output'];
  status: EngagementCampaignStatus;
  thumbNail?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  trackingOptions?: Maybe<Array<CampaignTrackingOption>>;
};

export type EngagementDetailForInfluencer = {
  __typename?: 'EngagementDetailForInfluencer';
  /** campaign status using for AnyX */
  anyXStatus: AnyXEngagementCampaignStatus;
  couponCode?: Maybe<Scalars['String']['output']>;
  createdDate: Scalars['Date']['output'];
  currency: Scalars['String']['output'];
  endDate?: Maybe<Scalars['Date']['output']>;
  hasDraftPosts: Scalars['Boolean']['output'];
  /** Whether she uploaded her post on social media or not. */
  hasReport: Scalars['Boolean']['output'];
  hasWarningPosts: Scalars['Boolean']['output'];
  hashtags: Array<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  isCouponCampaign?: Maybe<Scalars['Boolean']['output']>;
  isJoined: Scalars['Boolean']['output'];
  isTtcmCampaign: Scalars['Boolean']['output'];
  isTtcmReady?: Maybe<Scalars['Boolean']['output']>;
  materialUrl?: Maybe<Scalars['String']['output']>;
  materials?: Maybe<Array<MaterialName>>;
  objective?: Maybe<Scalars['String']['output']>;
  /** @deprecated Deprecated since new Influencer app */
  postStatus?: Maybe<CampaignPostStatusForInfluencer>;
  reason?: Maybe<Scalars['String']['output']>;
  requirements?: Maybe<Scalars['String']['output']>;
  /** influencer revenue */
  rewardAmount: Scalars['Float']['output'];
  serviceInformation: Scalars['String']['output'];
  serviceUrl?: Maybe<Scalars['String']['output']>;
  socialMedias: Array<CampaignSocialMediaType>;
  startDate: Scalars['Date']['output'];
  status: CampaignStatusForInfluencer;
  thumbNail: Scalars['String']['output'];
  title: Scalars['String']['output'];
  trackingUrl?: Maybe<Scalars['String']['output']>;
  ttcmCampaignCode?: Maybe<Scalars['String']['output']>;
  ttcmStatus?: Maybe<TTCMStatus>;
  type: CampaignType;
};

export type EngagementDraftPostHistory = {
  __typename?: 'EngagementDraftPostHistory';
  /** Influencer info */
  engagementPostDetailInfluencer: EngagementPostDetailInfluencer;
  /** List of rejected post history */
  historyRecords?: Maybe<Array<EngagementDraftPostHistoryRecord>>;
};

export type EngagementDraftPostHistoryForInfluencer = {
  __typename?: 'EngagementDraftPostHistoryForInfluencer';
  /** List of rejected post history */
  historyRecords?: Maybe<Array<EngagementDraftPostHistoryRecordForInfluencer>>;
};

export type EngagementDraftPostHistoryRecord = {
  __typename?: 'EngagementDraftPostHistoryRecord';
  /** History date */
  createdDate: Scalars['Date']['output'];
  /** Reason detail */
  detailedReason?: Maybe<Scalars['String']['output']>;
  /** History record id */
  id?: Maybe<Scalars['Int']['output']>;
  images?: Maybe<Array<Scalars['String']['output']>>;
  /** Draft post content */
  postContent: Scalars['String']['output'];
  /** History post status */
  postStatus?: Maybe<EngagementCampaignPostStatus>;
  /** Reason for report */
  reason?: Maybe<Scalars['String']['output']>;
};

export type EngagementDraftPostHistoryRecordForInfluencer = {
  __typename?: 'EngagementDraftPostHistoryRecordForInfluencer';
  /** History date */
  createdDate: Scalars['Date']['output'];
  /** Reason detail */
  detailedReason?: Maybe<Scalars['String']['output']>;
  /** History record id */
  id?: Maybe<Scalars['Int']['output']>;
  /** History post status */
  postStatus?: Maybe<EngagementCampaignPostStatus>;
  /** Reason for report */
  reason?: Maybe<Scalars['String']['output']>;
};

export type EngagementForInsightCheck = {
  __typename?: 'EngagementForInsightCheck';
  countries: Array<CountryName>;
  endDate: Scalars['Date']['output'];
  id: Scalars['Int']['output'];
  startDate: Scalars['Date']['output'];
  status: EngagementCampaignPostStatusForInsightCheck;
  title: Scalars['String']['output'];
  totalConfirmations: Scalars['Int']['output'];
  totalSubmissions: Scalars['Int']['output'];
};

export type EngagementInfluencerBudgetInput = {
  influencerCost: Scalars['Float']['input'];
  influencerId: Scalars['Int']['input'];
  netBudget: Scalars['Float']['input'];
  profit: Scalars['Float']['input'];
};

export type EngagementPost = {
  __typename?: 'EngagementPost';
  content?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  influencer: EngagementPostInfluencer;
  planedPostDate?: Maybe<Scalars['Date']['output']>;
  postUrl?: Maybe<Scalars['String']['output']>;
  postedDate?: Maybe<Scalars['Date']['output']>;
  socialAccountId?: Maybe<Scalars['Int']['output']>;
  /** support Tiktok for now */
  socialAccountStatus?: Maybe<SocialAccountStatus>;
  socialMedia: CampaignSocialMediaType;
  status?: Maybe<EngagementCampaignSocialAccountPostStatus>;
  thumbNail?: Maybe<Scalars['String']['output']>;
  warningReason?: Maybe<WarningReason>;
};

export type EngagementPostComment = {
  __typename?: 'EngagementPostComment';
  commentedDate?: Maybe<Scalars['DateTime']['output']>;
  content?: Maybe<Scalars['String']['output']>;
  username?: Maybe<Scalars['String']['output']>;
};

export type EngagementPostDetail = {
  __typename?: 'EngagementPostDetail';
  caption: Scalars['String']['output'];
  comments?: Maybe<Scalars['Int']['output']>;
  detailedReason?: Maybe<Scalars['String']['output']>;
  engagementPostDetailInfluencer: EngagementPostDetailInfluencer;
  id: Scalars['Int']['output'];
  images?: Maybe<Array<Scalars['String']['output']>>;
  likes?: Maybe<Scalars['Int']['output']>;
  postUrl?: Maybe<Scalars['String']['output']>;
  postedDate?: Maybe<Scalars['Date']['output']>;
  reason?: Maybe<Scalars['String']['output']>;
  shares?: Maybe<Scalars['Int']['output']>;
  socialMedia: CampaignSocialMediaType;
  status?: Maybe<EngagementCampaignPostStatus>;
  thumbNail?: Maybe<Scalars['String']['output']>;
  views?: Maybe<Scalars['Int']['output']>;
  warningReason?: Maybe<WarningReason>;
};

export type EngagementPostDetailForInfluencer = {
  __typename?: 'EngagementPostDetailForInfluencer';
  /** Represents draft post or posted post content */
  caption: Scalars['String']['output'];
  engagementPostDetailInfluencer: EngagementPostDetailInfluencer;
  id: Scalars['Int']['output'];
  images?: Maybe<Array<Scalars['String']['output']>>;
  postedDate?: Maybe<Scalars['Date']['output']>;
  socialMedia: CampaignSocialMediaType;
};

export type EngagementPostDetailForInfluencerV2 = {
  __typename?: 'EngagementPostDetailForInfluencerV2';
  content: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  /** represents warning/reject detail reason */
  inappropriateDetailedReason?: Maybe<Scalars['String']['output']>;
  /** represents warning/reject reason */
  inappropriateReason?: Maybe<Scalars['String']['output']>;
  materials?: Maybe<Array<MaterialName>>;
  planedPostDate: Scalars['Date']['output'];
  postStatus: EngagementCampaignPostStatus;
  /** represents selected social account system id */
  socialAccountId?: Maybe<Scalars['Int']['output']>;
  socialAccountMedia?: Maybe<SocialAccountType>;
  socialAccountName?: Maybe<Scalars['String']['output']>;
  /** represents campaign social media type */
  socialMedia: CampaignSocialMediaType;
};

export type EngagementPostDetailInfluencer = {
  __typename?: 'EngagementPostDetailInfluencer';
  avatar: Scalars['String']['output'];
  followersCount: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type EngagementPostEditV2 = {
  __typename?: 'EngagementPostEditV2';
  content?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  influencer: EngagementPostInfluencerWithSocialAccount;
  insightDataAcquisition?: Maybe<Scalars['Date']['output']>;
  insightUrls?: Maybe<Array<Scalars['String']['output']>>;
  materials?: Maybe<Array<MaterialName>>;
  planedPostDate?: Maybe<Scalars['Date']['output']>;
  postUrl?: Maybe<Scalars['String']['output']>;
  postedDate?: Maybe<Scalars['Date']['output']>;
  /** represents campaign social media type */
  socialMedia: CampaignSocialMediaType;
  status?: Maybe<EngagementCampaignPostStatus>;
};

export type EngagementPostForInfluencer = {
  __typename?: 'EngagementPostForInfluencer';
  /** campaign status using for AnyX */
  anyXStatus: AnyXEngagementCampaignStatus;
  content: Scalars['String']['output'];
  /** Post Id */
  id: Scalars['Int']['output'];
  /** represents reason details */
  inappropriateDetailedReason?: Maybe<Scalars['String']['output']>;
  /** represents reason title */
  inappropriateReason?: Maybe<Scalars['String']['output']>;
  /** currently for mobile */
  isPreviouslyModified: Scalars['Boolean']['output'];
  planedPostDate: Scalars['Date']['output'];
  postedDate?: Maybe<Scalars['Date']['output']>;
  socialMedia: CampaignSocialMediaType;
  status: EngagementCampaignPostStatus;
  thumbNail?: Maybe<Scalars['String']['output']>;
  ttcmInviteLink?: Maybe<Scalars['String']['output']>;
};

export type EngagementPostForInsightCheck = {
  __typename?: 'EngagementPostForInsightCheck';
  id: Scalars['Int']['output'];
  /** Influencer ID */
  influencerId: Scalars['Int']['output'];
  /** Influencer Name */
  influencerName: Scalars['String']['output'];
  /** Draft OR Posted content */
  postContent: Scalars['String']['output'];
  socialMedia: CampaignSocialMediaType;
  status: EngagementCampaignPostStatusForInsightCheck;
};

export type EngagementPostInfluencer = {
  __typename?: 'EngagementPostInfluencer';
  avatar: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type EngagementPostInfluencerWithSocialAccount = {
  __typename?: 'EngagementPostInfluencerWithSocialAccount';
  /** represents influencer id */
  id: Scalars['Int']['output'];
  /** represents influencer name */
  name: Scalars['String']['output'];
  /** represents selected social account system id */
  socialAccountId?: Maybe<Scalars['Int']['output']>;
  socialAccountMedia?: Maybe<SocialAccountType>;
  socialAccountName?: Maybe<Scalars['String']['output']>;
};

export type EngagementPostInfluencerWithSocialAccounts = {
  __typename?: 'EngagementPostInfluencerWithSocialAccounts';
  /** Influencer Id */
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  socialAccounts: Array<EngagementPostSocialAccount>;
};

export type EngagementPostMetricsDataForInsightCheck = {
  __typename?: 'EngagementPostMetricsDataForInsightCheck';
  audienceRetention?: Maybe<Scalars['Float']['output']>;
  averageViewDurationHours?: Maybe<Scalars['Float']['output']>;
  averageViewDurationMinutes?: Maybe<Scalars['Float']['output']>;
  back?: Maybe<Scalars['Int']['output']>;
  comments?: Maybe<Scalars['Int']['output']>;
  exited?: Maybe<Scalars['Int']['output']>;
  followerReach?: Maybe<Scalars['Int']['output']>;
  forward?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  impressions?: Maybe<Scalars['Int']['output']>;
  impressionsClickThroughRate?: Maybe<Scalars['Float']['output']>;
  impressionsFromDiscovery?: Maybe<Scalars['Int']['output']>;
  impressionsFromHashtag?: Maybe<Scalars['Int']['output']>;
  impressionsFromHome?: Maybe<Scalars['Int']['output']>;
  impressionsFromOther?: Maybe<Scalars['Int']['output']>;
  impressionsFromProfile?: Maybe<Scalars['Int']['output']>;
  insightUrls?: Maybe<Array<Scalars['String']['output']>>;
  interaction?: Maybe<Scalars['Int']['output']>;
  likes?: Maybe<Scalars['Int']['output']>;
  linkClicks?: Maybe<Scalars['Int']['output']>;
  navigation?: Maybe<Scalars['Int']['output']>;
  newFollowers?: Maybe<Scalars['Int']['output']>;
  nextStory?: Maybe<Scalars['Int']['output']>;
  nonFollowerReach?: Maybe<Scalars['Int']['output']>;
  /** Draft OR Posted content */
  postContent: Scalars['String']['output'];
  profileActivity?: Maybe<Scalars['Int']['output']>;
  reaches?: Maybe<Scalars['Int']['output']>;
  saves?: Maybe<Scalars['Int']['output']>;
  shares?: Maybe<Scalars['Int']['output']>;
  socialMedia: CampaignSocialMediaType;
  status: EngagementCampaignPostStatusForInsightCheck;
  stickerTaps?: Maybe<Scalars['Int']['output']>;
  views?: Maybe<Scalars['Int']['output']>;
};

export type EngagementPostSocialAccount = {
  __typename?: 'EngagementPostSocialAccount';
  /** Represents social account system id */
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  socialMedia: SocialAccountType;
  status: SocialAccountStatus;
  ttcmCampaignInviteLink?: Maybe<Scalars['String']['output']>;
  ttcmStatus?: Maybe<TTCMStatus>;
  username?: Maybe<Scalars['String']['output']>;
};

export type EngagementPostTrafficSource = {
  __typename?: 'EngagementPostTrafficSource';
  estimatedMinutesWatched?: Maybe<Scalars['Float']['output']>;
  sourceType: YoutubeTrafficSource;
  views: Scalars['Int']['output'];
};

export type EngagementProposal = {
  __typename?: 'EngagementProposal';
  activeTtcmCreatorsCount?: Maybe<Scalars['Int']['output']>;
  couponCodeCount?: Maybe<Scalars['Int']['output']>;
  /** Engagement Id */
  id: Scalars['Int']['output'];
  influencers: Array<ProposalInfluencer>;
  jdMode: ProposalJobDescriptionBreakDownMode;
};

/** An enumeration. */
export enum EngagementProposalSocialMediaType {
  FACEBOOK = 'FACEBOOK',
  FACEBOOK_PAGE = 'FACEBOOK_PAGE',
  INSTAGRAM = 'INSTAGRAM',
  INSTAGRAM_STORY = 'INSTAGRAM_STORY',
  THREADS = 'THREADS',
  TIKTOK = 'TIKTOK',
  TWITTER = 'TWITTER',
  YOUTUBE = 'YOUTUBE',
}

export type EngagementSocialAcccountBudgetInput = {
  influencerCost: Scalars['Float']['input'];
  influencerId: Scalars['Int']['input'];
  netBudget: Scalars['Float']['input'];
  profit: Scalars['Float']['input'];
  socialAccountId: Scalars['Int']['input'];
  socialAccountMedia: EngagementProposalSocialMediaType;
};

export type EngagementTracking = {
  __typename?: 'EngagementTracking';
  /** only for Pixel tracking campaign */
  pixelCode?: Maybe<Scalars['String']['output']>;
  /** only for postback URL campaign */
  postbackUrl?: Maybe<Scalars['String']['output']>;
};

export type EngagementsForInsightCheck = {
  __typename?: 'EngagementsForInsightCheck';
  engagementCampaigns: Array<EngagementForInsightCheck>;
};

export type EngagementsForInsightCheckCount = {
  __typename?: 'EngagementsForInsightCheckCount';
  totalConfirmed: Scalars['Int']['output'];
  totalUnconfirmed: Scalars['Int']['output'];
};

export enum ErrorDetail {
  /**
   * The deadline expired before the operation could complete.
   *
   * For operations that change the state of the system, this error
   * may be returned even if the operation has completed successfully.
   * For example, a successful response from a server could have been
   * delayed long enough for the deadline to expire.
   *
   * HTTP Mapping: 504 Gateway Timeout
   * Error Type: UNAVAILABLE
   */
  DEADLINE_EXCEEDED = 'DEADLINE_EXCEEDED',
  /**
   * The server detected that the client is exhibiting a behavior that
   * might be generating excessive load.
   *
   * HTTP Mapping: 429 Too Many Requests or 420 Enhance Your Calm
   * Error Type: UNAVAILABLE
   */
  ENHANCE_YOUR_CALM = 'ENHANCE_YOUR_CALM',
  /**
   * The requested field is not found in the schema.
   *
   * This differs from `NOT_FOUND` in that `NOT_FOUND` should be used when a
   * query is valid, but is unable to return a result (if, for example, a
   * specific video id doesn't exist). `FIELD_NOT_FOUND` is intended to be
   * returned by the server to signify that the requested field is not known to exist.
   * This may be returned in lieu of failing the entire query.
   * See also `PERMISSION_DENIED` for cases where the
   * requested field is invalid only for the given user or class of users.
   *
   * HTTP Mapping: 404 Not Found
   * Error Type: BAD_REQUEST
   */
  FIELD_NOT_FOUND = 'FIELD_NOT_FOUND',
  /**
   * The client specified an invalid argument.
   *
   * Note that this differs from `FAILED_PRECONDITION`.
   * `INVALID_ARGUMENT` indicates arguments that are problematic
   * regardless of the state of the system (e.g., a malformed file name).
   *
   * HTTP Mapping: 400 Bad Request
   * Error Type: BAD_REQUEST
   */
  INVALID_ARGUMENT = 'INVALID_ARGUMENT',
  /**
   * The provided cursor is not valid.
   *
   * The most common usage for this error is when a client is paginating
   * through a list that uses stateful cursors. In that case, the provided
   * cursor may be expired.
   *
   * HTTP Mapping: 404 Not Found
   * Error Type: NOT_FOUND
   */
  INVALID_CURSOR = 'INVALID_CURSOR',
  /**
   * Unable to perform operation because a required resource is missing.
   *
   * Example: Client is attempting to refresh a list, but the specified
   * list is expired. This requires an action by the client to get a new list.
   *
   * If the user is simply trying GET a resource that is not found,
   * use the NOT_FOUND error type. FAILED_PRECONDITION.MISSING_RESOURCE
   * is to be used particularly when the user is performing an operation
   * that requires a particular resource to exist.
   *
   * HTTP Mapping: 400 Bad Request or 500 Internal Server Error
   * Error Type: FAILED_PRECONDITION
   */
  MISSING_RESOURCE = 'MISSING_RESOURCE',
  /**
   * Service Error.
   *
   * There is a problem with an upstream service.
   *
   * This may be returned if a gateway receives an unknown error from a service
   * or if a service is unreachable.
   * If a request times out which waiting on a response from a service,
   * `DEADLINE_EXCEEDED` may be returned instead.
   * If a service returns a more specific error Type, the specific error Type may
   * be returned instead.
   *
   * HTTP Mapping: 502 Bad Gateway
   * Error Type: UNAVAILABLE
   */
  SERVICE_ERROR = 'SERVICE_ERROR',
  /**
   * Request failed due to network errors.
   *
   * HTTP Mapping: 503 Unavailable
   * Error Type: UNAVAILABLE
   */
  TCP_FAILURE = 'TCP_FAILURE',
  /**
   * Request throttled based on server concurrency limits.
   *
   * HTTP Mapping: 503 Unavailable
   * Error Type: UNAVAILABLE
   */
  THROTTLED_CONCURRENCY = 'THROTTLED_CONCURRENCY',
  /**
   * Request throttled based on server CPU limits
   *
   * HTTP Mapping: 503 Unavailable.
   * Error Type: UNAVAILABLE
   */
  THROTTLED_CPU = 'THROTTLED_CPU',
  /**
   * The operation is not implemented or is not currently supported/enabled.
   *
   * HTTP Mapping: 501 Not Implemented
   * Error Type: BAD_REQUEST
   */
  UNIMPLEMENTED = 'UNIMPLEMENTED',
  /**
   * Unknown error.
   *
   * This error should only be returned when no other error detail applies.
   * If a client sees an unknown errorDetail, it will be interpreted as UNKNOWN.
   *
   * HTTP Mapping: 500 Internal Server Error
   */
  UNKNOWN = 'UNKNOWN',
}

export enum ErrorType {
  /**
   * Bad Request.
   *
   * There is a problem with the request.
   * Retrying the same request is not likely to succeed.
   * An example would be a query or argument that cannot be deserialized.
   *
   * HTTP Mapping: 400 Bad Request
   */
  BAD_REQUEST = 'BAD_REQUEST',
  /**
   * The operation was rejected because the system is not in a state
   * required for the operation's execution.  For example, the directory
   * to be deleted is non-empty, an rmdir operation is applied to
   * a non-directory, etc.
   *
   * Service implementers can use the following guidelines to decide
   * between `FAILED_PRECONDITION` and `UNAVAILABLE`:
   *
   * - Use `UNAVAILABLE` if the client can retry just the failing call.
   * - Use `FAILED_PRECONDITION` if the client should not retry until
   * the system state has been explicitly fixed.  E.g., if an "rmdir"
   *      fails because the directory is non-empty, `FAILED_PRECONDITION`
   * should be returned since the client should not retry unless
   * the files are deleted from the directory.
   *
   * HTTP Mapping: 400 Bad Request or 500 Internal Server Error
   */
  FAILED_PRECONDITION = 'FAILED_PRECONDITION',
  /**
   * Internal error.
   *
   * An unexpected internal error was encountered. This means that some
   * invariants expected by the underlying system have been broken.
   * This error code is reserved for serious errors.
   *
   * HTTP Mapping: 500 Internal Server Error
   */
  INTERNAL = 'INTERNAL',
  /**
   * The requested entity was not found.
   *
   * This could apply to a resource that has never existed (e.g. bad resource id),
   * or a resource that no longer exists (e.g. cache expired.)
   *
   * Note to server developers: if a request is denied for an entire class
   * of users, such as gradual feature rollout or undocumented allowlist,
   * `NOT_FOUND` may be used. If a request is denied for some users within
   * a class of users, such as user-based access control, `PERMISSION_DENIED`
   * must be used.
   *
   * HTTP Mapping: 404 Not Found
   */
  NOT_FOUND = 'NOT_FOUND',
  /**
   * The caller does not have permission to execute the specified
   * operation.
   *
   * `PERMISSION_DENIED` must not be used for rejections
   * caused by exhausting some resource or quota.
   * `PERMISSION_DENIED` must not be used if the caller
   * cannot be identified (use `UNAUTHENTICATED`
   * instead for those errors).
   *
   * This error Type does not imply the
   * request is valid or the requested entity exists or satisfies
   * other pre-conditions.
   *
   * HTTP Mapping: 403 Forbidden
   */
  PERMISSION_DENIED = 'PERMISSION_DENIED',
  /**
   * The request does not have valid authentication credentials.
   *
   * This is intended to be returned only for routes that require
   * authentication.
   *
   * HTTP Mapping: 401 Unauthorized
   */
  UNAUTHENTICATED = 'UNAUTHENTICATED',
  /**
   * Currently Unavailable.
   *
   * The service is currently unavailable.  This is most likely a
   * transient condition, which can be corrected by retrying with
   * a backoff.
   *
   * HTTP Mapping: 503 Unavailable
   */
  UNAVAILABLE = 'UNAVAILABLE',
  /**
   * Unknown error.
   *
   * For example, this error may be returned when
   * an error code received from another address space belongs to
   * an error space that is not known in this address space.  Also
   * errors raised by APIs that do not return enough error information
   * may be converted to this error.
   *
   * If a client sees an unknown errorType, it will be interpreted as UNKNOWN.
   * Unknown errors MUST NOT trigger any special behavior. These MAY be treated
   * by an implementation as being equivalent to INTERNAL.
   *
   * When possible, a more specific error should be provided.
   *
   * HTTP Mapping: 520 Unknown Error
   */
  UNKNOWN = 'UNKNOWN',
}

export type ExistingProfileTags = {
  __typename?: 'ExistingProfileTags';
  tags: Array<Scalars['String']['output']>;
};

export type ExportDashboardInstagramAccountAnalytics = {
  __typename?: 'ExportDashboardInstagramAccountAnalytics';
  ok: Scalars['Boolean']['output'];
};

/** An enumeration. */
export enum ExportIGPresentationType {
  GOOGLE_SLIDE = 'GOOGLE_SLIDE',
  PPTX = 'PPTX',
}

export type ExportInstagramDashboardAccountAnalyticsInput = {
  endDate: Scalars['Date']['input'];
  /** Analytics Instagram account id */
  instagramAccountId: Scalars['Int']['input'];
  startDate: Scalars['Date']['input'];
  /** type of a presentation to send on your email address */
  type: ExportIGPresentationType;
};

export type ExportInstagramDashboardAccountInfluencerInput = {
  endDate: Scalars['Date']['input'];
  /** Social account id of an influencer's IG account */
  socialAccountId: Scalars['Int']['input'];
  startDate: Scalars['Date']['input'];
  /** type of a presentation to send on your email address */
  type: ExportIGPresentationType;
};

export enum ExportYoutubeAccountAnalyticsType {
  GOOGLE_SLIDE = 'GOOGLE_SLIDE',
  PPTX = 'PPTX',
}

export type ExportYoutubeDashboardAccountAnalyticsInput = {
  endDate: Scalars['String']['input'];
  startDate: Scalars['String']['input'];
  type?: InputMaybe<ExportYoutubeAccountAnalyticsType>;
  youtubeAccountId: Scalars['Int']['input'];
};

export type ExportYoutubeDashboardAccountAnalyticsOutput = {
  __typename?: 'ExportYoutubeDashboardAccountAnalyticsOutput';
  ok: Scalars['Boolean']['output'];
};

export enum ExternalAnalyticsAuthSocialAccountType {
  FACEBOOK_PAGE = 'FACEBOOK_PAGE',
  INSTAGRAM = 'INSTAGRAM',
  YOUTUBE = 'YOUTUBE',
}

export type ExternalAnalyticsFacebookGetAccountDetailsOutput = {
  __typename?: 'ExternalAnalyticsFacebookGetAccountDetailsOutput';
  fbPageAvatar: Scalars['String']['output'];
  fbPageName: Scalars['String']['output'];
};

export type ExternalAnalyticsFacebookGetTokenWarningsOutput = {
  __typename?: 'ExternalAnalyticsFacebookGetTokenWarningsOutput';
  analyticsAccountsWithInvalidTokens: Array<Scalars['Int']['output']>;
};

export type ExternalAnalyticsInstagramGetAccountDetailsOutput = {
  __typename?: 'ExternalAnalyticsInstagramGetAccountDetailsOutput';
  fbPageAvatar: Scalars['String']['output'];
  fbPageName: Scalars['String']['output'];
  igAvatar: Scalars['String']['output'];
  igUsername: Scalars['String']['output'];
};

export type ExternalAnalyticsInstagramGetTokenWarningsOutput = {
  __typename?: 'ExternalAnalyticsInstagramGetTokenWarningsOutput';
  analyticsAccountsWithInvalidTokens: Array<Scalars['Int']['output']>;
};

/**  Stores a redirect URL that FE uses to communicate with social network APIs */
export type ExternalAnalyticsSocialAuthRedirectUrlPayload = {
  __typename?: 'ExternalAnalyticsSocialAuthRedirectUrlPayload';
  redirectUrl: Scalars['String']['output'];
};

export type ExternalConnectableFacebookPageAccount = {
  __typename?: 'ExternalConnectableFacebookPageAccount';
  avatar: Scalars['String']['output'];
  followers: Scalars['Int']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type ExternalConnectableFacebookPageAnalyticsInput = {
  callbackUrl: Scalars['String']['input'];
  response: Scalars['String']['input'];
};

export type ExternalConnectableFacebookPageAnalyticsPayload = {
  __typename?: 'ExternalConnectableFacebookPageAnalyticsPayload';
  account?: Maybe<ExternalConnectableFacebookPageMainAccount>;
  pages: Array<ExternalConnectableFacebookPageAccount>;
};

export type ExternalConnectableFacebookPageMainAccount = {
  __typename?: 'ExternalConnectableFacebookPageMainAccount';
  avatar: Scalars['String']['output'];
  friendCount: Scalars['Int']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

/**
 *  `id`: social network account id
 *  `avatar`: account's avatar URL from a remote social network server
 *  `name`: account's name
 *  `followersCount`: number of followers
 */
export type ExternalConnectableOauthAccount = {
  __typename?: 'ExternalConnectableOauthAccount';
  avatar?: Maybe<Scalars['String']['output']>;
  followersCount: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

/**
 *  `page`: Instagram account's Facebook page
 *  `igAccount`: Instagram account data
 */
export type ExternalConnectableOauthPageAndIgAccount = {
  __typename?: 'ExternalConnectableOauthPageAndIgAccount';
  igAccount: ExternalConnectableOauthAccount;
  page: ExternalConnectableOauthAccount;
};

export type ExternalCreateFacebookPageAccountAnalyticsInput = {
  facebookPageId: Scalars['String']['input'];
};

export type ExternalCreateFacebookPageAccountAnalyticsOutput = {
  __typename?: 'ExternalCreateFacebookPageAccountAnalyticsOutput';
  analyticsAccountId: Scalars['Int']['output'];
};

export type ExternalCreateInstagramAccountAnalyticsInput = {
  instagramUserId: Scalars['String']['input'];
};

export type ExternalCreateInstagramAccountAnalyticsOutput = {
  __typename?: 'ExternalCreateInstagramAccountAnalyticsOutput';
  analyticsAccountId: Scalars['Int']['output'];
};

export type ExternalCreateYoutubeAccountAnalyticsInput = {
  youtubeChannelId: Scalars['String']['input'];
};

export type ExternalCreateYoutubeAccountAnalyticsOutput = {
  __typename?: 'ExternalCreateYoutubeAccountAnalyticsOutput';
  analyticsAccountId: Scalars['Int']['output'];
};

export type ExternalInstagramAnalyticsPostDetails = {
  __typename?: 'ExternalInstagramAnalyticsPostDetails';
  analysis?: Maybe<InstagramAnalyticsPostAnalysisDetails>;
  avatarUrl: Scalars['String']['output'];
  commentList?: Maybe<Array<InstagramAnalyticsPostCommentDetails>>;
  content: Scalars['String']['output'];
  followers?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  instagramAccountName: Scalars['String']['output'];
  mediaUrls?: Maybe<Array<Scalars['String']['output']>>;
  postDate: Scalars['Date']['output'];
  postUrl: Scalars['String']['output'];
  sentiment?: Maybe<InstagramAnalyticsPostSentimentDetails>;
};

export type ExternalInstagramAnalyticsPostsInDate = {
  __typename?: 'ExternalInstagramAnalyticsPostsInDate';
  feedPosts?: Maybe<Array<ExternalInstagramAnalyticsPostDetails>>;
  reelPosts?: Maybe<Array<ExternalInstagramAnalyticsReelDetails>>;
  storyPosts?: Maybe<Array<ExternalInstagramAnalyticsStoryDetails>>;
};

export type ExternalInstagramAnalyticsReelDetails = {
  __typename?: 'ExternalInstagramAnalyticsReelDetails';
  analysis?: Maybe<InstagramAnalyticsReelAnalysisDetails>;
  avatarUrl: Scalars['String']['output'];
  commentList?: Maybe<Array<InstagramAnalyticsReelCommentDetails>>;
  content: Scalars['String']['output'];
  followers?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  instagramAccountName: Scalars['String']['output'];
  postDate: Scalars['Date']['output'];
  postUrl: Scalars['String']['output'];
  thumbnail?: Maybe<Scalars['String']['output']>;
};

export type ExternalInstagramAnalyticsStoryDetails = {
  __typename?: 'ExternalInstagramAnalyticsStoryDetails';
  avatarUrl: Scalars['String']['output'];
  comments: Scalars['Int']['output'];
  content: Scalars['String']['output'];
  exits: Scalars['Int']['output'];
  followers?: Maybe<Scalars['Int']['output']>;
  /** Instagram analytics story id */
  id: Scalars['Int']['output'];
  impressions: Scalars['Int']['output'];
  instagramAccountName: Scalars['String']['output'];
  postDate: Scalars['Date']['output'];
  postImageUrl?: Maybe<Scalars['String']['output']>;
  postUrl: Scalars['String']['output'];
  reach: Scalars['Int']['output'];
  storyVideoUrl?: Maybe<Scalars['String']['output']>;
  tapsBack: Scalars['Int']['output'];
  tapsForward: Scalars['Int']['output'];
};

export type ExternalInstagramDashboardFollowerAnalytics = {
  __typename?: 'ExternalInstagramDashboardFollowerAnalytics';
  followerAgeRate: InstagramFollowersAgeRate;
  followerGenderRate: InstagramFollowerGenderRate;
  followerRegionRate?: Maybe<Array<InstagramFollowersRegionRate>>;
};

export type ExternalInstagramDashboardOverview = {
  __typename?: 'ExternalInstagramDashboardOverview';
  comments: InstagramAnalyticsOverviewData;
  engagement: InstagramAnalyticsOverviewData;
  engagementRate: InstagramAnalyticsOverviewEngagementRateData;
  followers: InstagramAnalyticsOverviewData;
  /** impressions of posts */
  impressions: InstagramAnalyticsOverviewData;
  likes: InstagramAnalyticsOverviewData;
  posts: InstagramAnalyticsOverviewData;
  profileViews: InstagramAnalyticsOverviewData;
  reach: InstagramAnalyticsOverviewData;
  saved: InstagramAnalyticsOverviewData;
  websiteClicks: InstagramAnalyticsOverviewData;
};

export type ExternalInstagramDashboardPostAnalytics = {
  __typename?: 'ExternalInstagramDashboardPostAnalytics';
  averageEngagement?: Maybe<InstagramPostAverageEngagement>;
  commentAnalytics?: Maybe<InstagramCommentAnalytics>;
  feedPosts?: Maybe<Array<ExternalInstagramFeedPost>>;
  postsHabit?: Maybe<Array<InstagramPostsHabit>>;
  storyPosts?: Maybe<Array<ExternalInstagramStoryPost>>;
};

export type ExternalInstagramFeedPost = {
  __typename?: 'ExternalInstagramFeedPost';
  comments?: Maybe<Scalars['Int']['output']>;
  content?: Maybe<Scalars['String']['output']>;
  engagement?: Maybe<Scalars['Int']['output']>;
  engagementRate?: Maybe<Scalars['Float']['output']>;
  follow?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  impressionFromDiscovery?: Maybe<Scalars['Int']['output']>;
  impressionFromHashtag?: Maybe<Scalars['Int']['output']>;
  impressions?: Maybe<Scalars['Int']['output']>;
  interaction?: Maybe<Scalars['Int']['output']>;
  likes?: Maybe<Scalars['Int']['output']>;
  negativeRate?: Maybe<Scalars['Float']['output']>;
  nonFollowerReach?: Maybe<Scalars['Int']['output']>;
  positiveRate?: Maybe<Scalars['Float']['output']>;
  postDate?: Maybe<Scalars['DateTime']['output']>;
  postUrl: Scalars['String']['output'];
  productButtonClick?: Maybe<Scalars['Int']['output']>;
  productPageView?: Maybe<Scalars['Int']['output']>;
  reach?: Maybe<Scalars['Int']['output']>;
  saved?: Maybe<Scalars['Int']['output']>;
  share?: Maybe<Scalars['Int']['output']>;
  thumbNail?: Maybe<Scalars['String']['output']>;
  views?: Maybe<Scalars['Int']['output']>;
};

export type ExternalInstagramReelPost = {
  __typename?: 'ExternalInstagramReelPost';
  comments?: Maybe<Scalars['Int']['output']>;
  content?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  likes?: Maybe<Scalars['Int']['output']>;
  postDate?: Maybe<Scalars['DateTime']['output']>;
  postUrl: Scalars['String']['output'];
  reach?: Maybe<Scalars['Int']['output']>;
  saved?: Maybe<Scalars['Int']['output']>;
  shares?: Maybe<Scalars['Int']['output']>;
  thumbnail?: Maybe<Scalars['String']['output']>;
  views?: Maybe<Scalars['Int']['output']>;
};

export type ExternalInstagramStoryPost = {
  __typename?: 'ExternalInstagramStoryPost';
  comments?: Maybe<Scalars['Int']['output']>;
  content?: Maybe<Scalars['String']['output']>;
  exits?: Maybe<Scalars['Int']['output']>;
  follow?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  impressions?: Maybe<Scalars['Int']['output']>;
  interaction?: Maybe<Scalars['Int']['output']>;
  linkClicks?: Maybe<Scalars['Int']['output']>;
  postDate?: Maybe<Scalars['DateTime']['output']>;
  postUrl: Scalars['String']['output'];
  productButtonClick?: Maybe<Scalars['Int']['output']>;
  productPageView?: Maybe<Scalars['Int']['output']>;
  reach?: Maybe<Scalars['Int']['output']>;
  stickerTaps?: Maybe<Scalars['Int']['output']>;
  tapsBack?: Maybe<Scalars['Int']['output']>;
  tapsForward?: Maybe<Scalars['Int']['output']>;
  thumbNail?: Maybe<Scalars['String']['output']>;
};

export type ExternalSocialAuthConnectableInstagramAccountsAnalyticsInput = {
  callbackUrl: Scalars['String']['input'];
  response: Scalars['String']['input'];
};

/**  Stores Instagram accounts that can be connected based on a Facebook account */
export type ExternalSocialAuthConnectableInstagramAccountsAnalyticsPayload = {
  __typename?: 'ExternalSocialAuthConnectableInstagramAccountsAnalyticsPayload';
  accounts: Array<ExternalConnectableOauthPageAndIgAccount>;
};

export type ExternalSocialAuthReconnectFacebookPageAccountAnalyticsInput = {
  accountId: Scalars['Int']['input'];
  callbackUrl: Scalars['String']['input'];
  fbPageId: Scalars['String']['input'];
  response: Scalars['String']['input'];
};

export type ExternalSocialAuthReconnectFacebookPageAccountAnalyticsPayload = {
  __typename?: 'ExternalSocialAuthReconnectFacebookPageAccountAnalyticsPayload';
  ok: Scalars['Boolean']['output'];
};

export type ExternalUserSocialAuthReconnectInstagramAccountAnalyticsInput = {
  accountId: Scalars['Int']['input'];
  callbackUrl: Scalars['String']['input'];
  response: Scalars['String']['input'];
};

export type ExternalUserSocialAuthReconnectInstagramAccountAnalyticsPayload = {
  __typename?: 'ExternalUserSocialAuthReconnectInstagramAccountAnalyticsPayload';
  ok: Scalars['Boolean']['output'];
};

export type ExternalYoutubeAnalyticsAudienceAgeBreakdownAgeGroupPayload = {
  __typename?: 'ExternalYoutubeAnalyticsAudienceAgeBreakdownAgeGroupPayload';
  end: Scalars['Int']['output'];
  start: Scalars['Int']['output'];
};

export type ExternalYoutubeAnalyticsAudienceAgeBreakdownPayload = {
  __typename?: 'ExternalYoutubeAnalyticsAudienceAgeBreakdownPayload';
  ageGroup: Array<ExternalYoutubeAnalyticsAudienceAgeBreakdownAgeGroupPayload>;
  femaleRate: Array<Scalars['Float']['output']>;
  maleRate: Array<Scalars['Float']['output']>;
  otherRate: Array<Scalars['Float']['output']>;
};

export type ExternalYoutubeAnalyticsAudienceGenderBreakdownPayload = {
  __typename?: 'ExternalYoutubeAnalyticsAudienceGenderBreakdownPayload';
  femaleRate: Scalars['Float']['output'];
  maleRate: Scalars['Float']['output'];
  otherRate: Scalars['Float']['output'];
};

export type ExternalYoutubeAnalyticsAudiencePayload = {
  __typename?: 'ExternalYoutubeAnalyticsAudiencePayload';
  ageRate: ExternalYoutubeAnalyticsAudienceAgeBreakdownPayload;
  genderRate: ExternalYoutubeAnalyticsAudienceGenderBreakdownPayload;
  regionRate: Array<ExternalYoutubeAnalyticsAudienceRegionPayload>;
};

export type ExternalYoutubeAnalyticsAudienceRegionPayload = {
  __typename?: 'ExternalYoutubeAnalyticsAudienceRegionPayload';
  name: Scalars['String']['output'];
  rate: Scalars['Float']['output'];
};

export type ExternalYoutubeAnalyticsOverviewHistoryItem = {
  __typename?: 'ExternalYoutubeAnalyticsOverviewHistoryItem';
  count: Scalars['Int']['output'];
  date: Scalars['Date']['output'];
};

export type ExternalYoutubeAnalyticsOverviewItem = {
  __typename?: 'ExternalYoutubeAnalyticsOverviewItem';
  growth: Scalars['Int']['output'];
  history: Array<ExternalYoutubeAnalyticsOverviewHistoryItem>;
  percentage: Scalars['Float']['output'];
  total: Scalars['Int']['output'];
};

export type ExternalYoutubeAnalyticsOverviewPayload = {
  __typename?: 'ExternalYoutubeAnalyticsOverviewPayload';
  comment: ExternalYoutubeAnalyticsOverviewItem;
  dislike: ExternalYoutubeAnalyticsOverviewItem;
  like: ExternalYoutubeAnalyticsOverviewItem;
  post: ExternalYoutubeAnalyticsOverviewItem;
  subscriber: ExternalYoutubeAnalyticsOverviewItem;
  view: ExternalYoutubeAnalyticsOverviewItem;
};

export type ExternalYoutubeAnalyticsPostComment = {
  __typename?: 'ExternalYoutubeAnalyticsPostComment';
  commentId: Scalars['String']['output'];
  content: Scalars['String']['output'];
  posterName: Scalars['String']['output'];
};

export type ExternalYoutubeAnalyticsPostListByDatePayload = {
  __typename?: 'ExternalYoutubeAnalyticsPostListByDatePayload';
  posts: Array<ExternalYoutubeAnalyticsPostListItemByDatePayload>;
};

export type ExternalYoutubeAnalyticsPostListItemByDatePayload = {
  __typename?: 'ExternalYoutubeAnalyticsPostListItemByDatePayload';
  averageViewDuration?: Maybe<Scalars['Int']['output']>;
  averageViewPercentage?: Maybe<Scalars['Float']['output']>;
  comments: Scalars['Int']['output'];
  dislikes: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  likes: Scalars['Int']['output'];
  postDate: Scalars['Date']['output'];
  subscribers: Scalars['Int']['output'];
  videoCaption: Scalars['String']['output'];
  videoComments: Array<YoutubeAnalyticsPostComment>;
  videoDuration: Scalars['Int']['output'];
  videoEmbed: Scalars['String']['output'];
  videoId: Scalars['String']['output'];
  videoTags: Array<Scalars['String']['output']>;
  videoThumbnail?: Maybe<Scalars['String']['output']>;
  videoTitle: Scalars['String']['output'];
  views: Scalars['Int']['output'];
};

export type ExternalYoutubeAnalyticsPostListItemPayload = {
  __typename?: 'ExternalYoutubeAnalyticsPostListItemPayload';
  averageViewDuration?: Maybe<Scalars['Int']['output']>;
  averageViewPercentage?: Maybe<Scalars['Float']['output']>;
  comments: Scalars['Int']['output'];
  dislikes: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  likes: Scalars['Int']['output'];
  postDate: Scalars['Date']['output'];
  shares: Scalars['Int']['output'];
  videoDuration: Scalars['Int']['output'];
  videoId: Scalars['String']['output'];
  videoThumbnail?: Maybe<Scalars['String']['output']>;
  videoTitle: Scalars['String']['output'];
  views: Scalars['Int']['output'];
};

export type ExternalYoutubeAnalyticsPostListPayload = {
  __typename?: 'ExternalYoutubeAnalyticsPostListPayload';
  posts: Array<ExternalYoutubeAnalyticsPostListItemPayload>;
};

export type ExternalYoutubeAnalyticsPostsAverageEngagement = {
  __typename?: 'ExternalYoutubeAnalyticsPostsAverageEngagement';
  averageComments: ExternalYoutubeStatisticsData;
  averageDislikes: ExternalYoutubeStatisticsData;
  averageLikes: ExternalYoutubeStatisticsData;
  averagePostsPerWeek?: Maybe<ExternalYoutubeStatisticsData>;
  averageViews: ExternalYoutubeStatisticsData;
};

export type ExternalYoutubeAnalyticsPostsPostingHabit = {
  __typename?: 'ExternalYoutubeAnalyticsPostsPostingHabit';
  averageComments: Scalars['Float']['output'];
  averageLikes: Scalars['Float']['output'];
  engagementRate: Scalars['Float']['output'];
  hour: Scalars['Int']['output'];
  weekday: Scalars['Int']['output'];
};

export type ExternalYoutubeAnalyticsPostsSortInput = {
  field?: InputMaybe<ExternalYoutubeAnalyticsPostsSortOrder>;
  order?: InputMaybe<Order>;
};

export enum ExternalYoutubeAnalyticsPostsSortOrder {
  AVERAGE_VIEW_DURATION = 'AVERAGE_VIEW_DURATION',
  AVERAGE_VIEW_PERCENTAGE = 'AVERAGE_VIEW_PERCENTAGE',
  COMMENT = 'COMMENT',
  DISLIKE = 'DISLIKE',
  DURATION = 'DURATION',
  ENGAGEMENT = 'ENGAGEMENT',
  LIKE = 'LIKE',
  NEGATIVE_RATE = 'NEGATIVE_RATE',
  POSITIVE_RATE = 'POSITIVE_RATE',
  POST_DATE = 'POST_DATE',
  SHARE = 'SHARE',
  VIEWS = 'VIEWS',
}

export type ExternalYoutubeAnalyticsPostsStatisticsPayload = {
  __typename?: 'ExternalYoutubeAnalyticsPostsStatisticsPayload';
  averageEngagement: ExternalYoutubeAnalyticsPostsAverageEngagement;
  postHabit: Array<ExternalYoutubeAnalyticsPostsPostingHabit>;
};

export type ExternalYoutubeAnalyticsRelatedPostPayload = {
  __typename?: 'ExternalYoutubeAnalyticsRelatedPostPayload';
  comments: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  postDate: Scalars['Date']['output'];
  videoCaption: Scalars['String']['output'];
  videoDuration: Scalars['Int']['output'];
  videoEmbed: Scalars['String']['output'];
  videoId: Scalars['String']['output'];
  videoThumbnail?: Maybe<Scalars['String']['output']>;
  videoTitle: Scalars['String']['output'];
  views: Scalars['Int']['output'];
};

export type ExternalYoutubeAnalyticsRelatedPostsPayload = {
  __typename?: 'ExternalYoutubeAnalyticsRelatedPostsPayload';
  posts: Array<ExternalYoutubeAnalyticsRelatedPostPayload>;
};

export type ExternalYoutubeAnalyticsTagRankingPayload = {
  __typename?: 'ExternalYoutubeAnalyticsTagRankingPayload';
  averageComment: Scalars['Float']['output'];
  averageDislike: Scalars['Float']['output'];
  averageEngagement: Scalars['Float']['output'];
  averageLike: Scalars['Float']['output'];
  averageShare: Scalars['Float']['output'];
  engagementRate: Scalars['Float']['output'];
  posts: Scalars['Int']['output'];
  tag: Scalars['String']['output'];
  views: Scalars['Int']['output'];
};

export type ExternalYoutubeAnalyticsTagRankingPayloads = {
  __typename?: 'ExternalYoutubeAnalyticsTagRankingPayloads';
  tags: Array<ExternalYoutubeAnalyticsTagRankingPayload>;
};

export type ExternalYoutubeAnalyticsTagRankingSortInput = {
  field?: InputMaybe<ExternalYoutubeAnalyticsTagRankingSortOrder>;
  order?: InputMaybe<Order>;
};

export enum ExternalYoutubeAnalyticsTagRankingSortOrder {
  AVERAGE_COMMENT = 'AVERAGE_COMMENT',
  AVERAGE_DISLIKE = 'AVERAGE_DISLIKE',
  AVERAGE_ENGAGEMENT = 'AVERAGE_ENGAGEMENT',
  AVERAGE_LIKE = 'AVERAGE_LIKE',
  AVERAGE_SHARE = 'AVERAGE_SHARE',
  ENGAGEMENT_RATE = 'ENGAGEMENT_RATE',
  POST = 'POST',
  VIEWS = 'VIEWS',
}

export type ExternalYoutubePostPayload = {
  __typename?: 'ExternalYoutubePostPayload';
  averageViewDuration?: Maybe<Scalars['Int']['output']>;
  averageViewPercentage?: Maybe<Scalars['Float']['output']>;
  comments: Scalars['Int']['output'];
  dislikes: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  likes: Scalars['Int']['output'];
  postDate: Scalars['Date']['output'];
  subscribers: Scalars['Int']['output'];
  videoCaption: Scalars['String']['output'];
  videoComments: Array<ExternalYoutubeAnalyticsPostComment>;
  videoDuration: Scalars['Int']['output'];
  videoEmbed: Scalars['String']['output'];
  videoId: Scalars['String']['output'];
  videoTags: Array<Scalars['String']['output']>;
  videoThumbnail?: Maybe<Scalars['String']['output']>;
  videoTitle: Scalars['String']['output'];
  views: Scalars['Int']['output'];
};

export type ExternalYoutubeStatisticsData = {
  __typename?: 'ExternalYoutubeStatisticsData';
  growth: Scalars['Float']['output'];
  value: Scalars['Float']['output'];
};

export type FacebookAccountInfluencerSearchResultV2 = {
  __typename?: 'FacebookAccountInfluencerSearchResultV2';
  facebookAccounts: Array<FacebookAccountInfluencerV2>;
  totalNumber: Scalars['Int']['output'];
};

export type FacebookAccountInfluencerV2 = {
  __typename?: 'FacebookAccountInfluencerV2';
  age?: Maybe<Scalars['Int']['output']>;
  avatar?: Maybe<Scalars['String']['output']>;
  averageLikes?: Maybe<Scalars['Float']['output']>;
  averageViews?: Maybe<Scalars['Float']['output']>;
  country: CountryName;
  engagementPostedCount?: Maybe<Scalars['Int']['output']>;
  engagementProposedCount?: Maybe<Scalars['Int']['output']>;
  gender: Genders;
  id: Scalars['ID']['output'];
  influencerEngagement?: Maybe<Scalars['Int']['output']>;
  influencerEngagementRate?: Maybe<Scalars['Float']['output']>;
  isBrandAccount: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  popularPosts?: Maybe<Array<SocialAccountPopularPost>>;
  shares?: Maybe<Scalars['Int']['output']>;
  socialAccountEngagement?: Maybe<Scalars['Int']['output']>;
  socialAccountEngagementRate?: Maybe<Scalars['Float']['output']>;
  socialAccountFollowers?: Maybe<Scalars['Int']['output']>;
  socialAccountId: Scalars['ID']['output'];
  socialAccountStatus: SocialAccountStatus;
};

export type FacebookInfluencer = {
  __typename?: 'FacebookInfluencer';
  age?: Maybe<Scalars['Int']['output']>;
  avatar?: Maybe<Scalars['String']['output']>;
  averageLikes?: Maybe<Scalars['Float']['output']>;
  averageViews?: Maybe<Scalars['Float']['output']>;
  categories: Array<CategoryName>;
  comments?: Maybe<Scalars['Int']['output']>;
  country: CountryName;
  engagementPostedCount?: Maybe<Scalars['Int']['output']>;
  engagementProposedCount?: Maybe<Scalars['Int']['output']>;
  gender: Genders;
  /** Influencer Id */
  id: Scalars['Int']['output'];
  influencerEngagement?: Maybe<Scalars['Int']['output']>;
  influencerEngagementRate?: Maybe<Scalars['Float']['output']>;
  influencerFollowers?: Maybe<Scalars['Int']['output']>;
  isBrandAccount: Scalars['Boolean']['output'];
  likes?: Maybe<Scalars['Int']['output']>;
  marketplaceJoinedCount?: Maybe<Scalars['Int']['output']>;
  marketplacePostedCount?: Maybe<Scalars['Int']['output']>;
  name: Scalars['String']['output'];
  popularPosts?: Maybe<Array<SocialAccountPopularPost>>;
  shares?: Maybe<Scalars['Int']['output']>;
  socialAccountEngagement?: Maybe<Scalars['Int']['output']>;
  socialAccountEngagementRate?: Maybe<Scalars['Float']['output']>;
  socialAccountFollowers?: Maybe<Scalars['Int']['output']>;
  /** Social Account System Id */
  socialAccountId: Scalars['Int']['output'];
  /** Social Account Status */
  socialAccountStatus: SocialAccountStatus;
  views?: Maybe<Scalars['Int']['output']>;
};

export type FacebookOauthInput = {
  callbackUrl: Scalars['String']['input'];
  response: Scalars['String']['input'];
};

export type FacebookPageAnalyticsAudienceAgeBreakdownAgeGroupPayload = {
  __typename?: 'FacebookPageAnalyticsAudienceAgeBreakdownAgeGroupPayload';
  end: Scalars['Int']['output'];
  start: Scalars['Int']['output'];
};

export type FacebookPageAnalyticsAudienceAgeBreakdownPayload = {
  __typename?: 'FacebookPageAnalyticsAudienceAgeBreakdownPayload';
  ageGroup: Array<FacebookPageAnalyticsAudienceAgeBreakdownAgeGroupPayload>;
  femaleRate: Array<Scalars['Float']['output']>;
  maleRate: Array<Scalars['Float']['output']>;
  otherRate: Array<Scalars['Float']['output']>;
};

export type FacebookPageAnalyticsAudienceGenderBreakdownPayload = {
  __typename?: 'FacebookPageAnalyticsAudienceGenderBreakdownPayload';
  femaleRate: Scalars['Float']['output'];
  maleRate: Scalars['Float']['output'];
  otherRate: Scalars['Float']['output'];
};

export type FacebookPageAnalyticsAudiencePayload = {
  __typename?: 'FacebookPageAnalyticsAudiencePayload';
  ageRate: FacebookPageAnalyticsAudienceAgeBreakdownPayload;
  genderRate: FacebookPageAnalyticsAudienceGenderBreakdownPayload;
  regionRate: Array<FacebookPageAnalyticsAudienceRegionPayload>;
};

export type FacebookPageAnalyticsAudienceRegionPayload = {
  __typename?: 'FacebookPageAnalyticsAudienceRegionPayload';
  name: Scalars['String']['output'];
  rate: Scalars['Float']['output'];
};

export type FacebookPageAnalyticsOverviewHistoryItem = {
  __typename?: 'FacebookPageAnalyticsOverviewHistoryItem';
  count: Scalars['Int']['output'];
  date: Scalars['Date']['output'];
};

export type FacebookPageAnalyticsOverviewHistoryRateItem = {
  __typename?: 'FacebookPageAnalyticsOverviewHistoryRateItem';
  count: Scalars['Float']['output'];
  date: Scalars['Date']['output'];
};

export type FacebookPageAnalyticsOverviewItem = {
  __typename?: 'FacebookPageAnalyticsOverviewItem';
  growth: Scalars['Long']['output'];
  history: Array<FacebookPageAnalyticsOverviewHistoryItem>;
  percentage: Scalars['Float']['output'];
  total: Scalars['Long']['output'];
};

export type FacebookPageAnalyticsOverviewPayload = {
  __typename?: 'FacebookPageAnalyticsOverviewPayload';
  comment: FacebookPageAnalyticsOverviewItem;
  engagement: FacebookPageAnalyticsOverviewItem;
  engagementRate: FacebookPageAnalyticsOverviewRateItem;
  follower: FacebookPageAnalyticsOverviewItem;
  impression: FacebookPageAnalyticsOverviewItem;
  lastUpdated: Scalars['DateTime']['output'];
  pageCta: FacebookPageAnalyticsOverviewItem;
  pageImpression: FacebookPageAnalyticsOverviewItem;
  pageLikes: FacebookPageAnalyticsOverviewItem;
  post: FacebookPageAnalyticsOverviewItem;
  reaction: FacebookPageAnalyticsOverviewItem;
  share: FacebookPageAnalyticsOverviewItem;
  view: FacebookPageAnalyticsOverviewItem;
};

export type FacebookPageAnalyticsOverviewRateItem = {
  __typename?: 'FacebookPageAnalyticsOverviewRateItem';
  growth: Scalars['Float']['output'];
  history: Array<FacebookPageAnalyticsOverviewHistoryRateItem>;
  percentage: Scalars['Float']['output'];
  total: Scalars['Float']['output'];
};

export type FacebookPageAnalyticsPostComment = {
  __typename?: 'FacebookPageAnalyticsPostComment';
  commentId: Scalars['String']['output'];
  content: Scalars['String']['output'];
  posterName: Scalars['String']['output'];
};

export type FacebookPageAnalyticsPostPayload = {
  __typename?: 'FacebookPageAnalyticsPostPayload';
  adImpressions: Scalars['Long']['output'];
  comments: Scalars['Int']['output'];
  content: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  image?: Maybe<Scalars['String']['output']>;
  postComments: Array<FacebookPageAnalyticsPostComment>;
  postDate: Scalars['Date']['output'];
  postUrl: Scalars['String']['output'];
  reactions: Scalars['Int']['output'];
  shares: Scalars['Int']['output'];
  subscribers: Scalars['Int']['output'];
  views: Scalars['Int']['output'];
};

export type FacebookPageAnalyticsPostWithPageDetailsPayload = {
  __typename?: 'FacebookPageAnalyticsPostWithPageDetailsPayload';
  adImpressions: Scalars['Long']['output'];
  avatar: Scalars['String']['output'];
  comments: Scalars['Int']['output'];
  content: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  image?: Maybe<Scalars['String']['output']>;
  pageName: Scalars['String']['output'];
  postComments: Array<FacebookPageAnalyticsPostComment>;
  postDate: Scalars['Date']['output'];
  postUrl: Scalars['String']['output'];
  reactions: Scalars['Int']['output'];
  shares: Scalars['Int']['output'];
  subscribers: Scalars['Int']['output'];
  views: Scalars['Int']['output'];
};

export type FacebookPageAnalyticsPostsAnalyticsPayload = {
  __typename?: 'FacebookPageAnalyticsPostsAnalyticsPayload';
  averageEngagement?: Maybe<FacebookPageAnalyticsPostsAverageEngagement>;
  fanActivity: Array<FacebookPagePostsAnalyticsFanActivity>;
};

export type FacebookPageAnalyticsPostsAverageEngagement = {
  __typename?: 'FacebookPageAnalyticsPostsAverageEngagement';
  averageComments?: Maybe<FacebookPageStatisticsData>;
  averageImpressions?: Maybe<FacebookPageStatisticsData>;
  averagePostsPerWeek?: Maybe<FacebookPageStatisticsData>;
  averageReactions?: Maybe<FacebookPageStatisticsData>;
  averageShares?: Maybe<FacebookPageStatisticsData>;
  averageViews?: Maybe<FacebookPageStatisticsData>;
};

export type FacebookPageAnalyticsPostsByDatePayload = {
  __typename?: 'FacebookPageAnalyticsPostsByDatePayload';
  avatar: Scalars['String']['output'];
  followers: Scalars['Int']['output'];
  pageName: Scalars['String']['output'];
  posts: Array<FacebookPageAnalyticsPostPayload>;
};

export type FacebookPageAnalyticsPostsPayload = {
  __typename?: 'FacebookPageAnalyticsPostsPayload';
  adImpressions: Scalars['Long']['output'];
  comments: Scalars['Int']['output'];
  content: Scalars['String']['output'];
  engagement: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  image?: Maybe<Scalars['String']['output']>;
  postDate: Scalars['Date']['output'];
  postUrl: Scalars['String']['output'];
  reactions: Scalars['Int']['output'];
  shares: Scalars['Int']['output'];
  views: Scalars['Int']['output'];
};

export type FacebookPageAnalyticsPostsPayloads = {
  __typename?: 'FacebookPageAnalyticsPostsPayloads';
  posts: Array<FacebookPageAnalyticsPostsPayload>;
};

export type FacebookPageAnalyticsPostsSortInput = {
  field?: InputMaybe<FacebookPageAnalyticsPostsSortOrder>;
  order?: InputMaybe<Order>;
};

export enum FacebookPageAnalyticsPostsSortOrder {
  AD_IMPRESSIONS = 'AD_IMPRESSIONS',
  COMMENT = 'COMMENT',
  ENGAGEMENT = 'ENGAGEMENT',
  NEGATIVE_RATE = 'NEGATIVE_RATE',
  POSITIVE_RATE = 'POSITIVE_RATE',
  POST_DATE = 'POST_DATE',
  REACTIONS = 'REACTIONS',
  SHARE = 'SHARE',
  VIEWS = 'VIEWS',
}

export type FacebookPageForProfileV2 = {
  __typename?: 'FacebookPageForProfileV2';
  analyticsEnabled: Scalars['Boolean']['output'];
  avatar?: Maybe<Scalars['String']['output']>;
  followersCount?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
  profile?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type FacebookPageInfluencer = {
  __typename?: 'FacebookPageInfluencer';
  age?: Maybe<Scalars['Int']['output']>;
  analyticsWarning: Scalars['Boolean']['output'];
  avatar?: Maybe<Scalars['String']['output']>;
  averageLikes?: Maybe<Scalars['Float']['output']>;
  averageViews?: Maybe<Scalars['Float']['output']>;
  categories: Array<CategoryName>;
  comments?: Maybe<Scalars['Int']['output']>;
  country: CountryName;
  engagementPostedCount?: Maybe<Scalars['Int']['output']>;
  engagementProposedCount?: Maybe<Scalars['Int']['output']>;
  gender: Genders;
  /** Influencer Id */
  id: Scalars['Int']['output'];
  influencerEngagement?: Maybe<Scalars['Int']['output']>;
  influencerEngagementRate?: Maybe<Scalars['Float']['output']>;
  influencerFollowers?: Maybe<Scalars['Int']['output']>;
  isBrandAccount: Scalars['Boolean']['output'];
  likes?: Maybe<Scalars['Int']['output']>;
  marketplaceJoinedCount?: Maybe<Scalars['Int']['output']>;
  marketplacePostedCount?: Maybe<Scalars['Int']['output']>;
  name: Scalars['String']['output'];
  popularPosts?: Maybe<Array<SocialAccountPopularPost>>;
  shares?: Maybe<Scalars['Int']['output']>;
  socialAccountEngagement?: Maybe<Scalars['Int']['output']>;
  socialAccountEngagementRate?: Maybe<Scalars['Float']['output']>;
  socialAccountFollowers?: Maybe<Scalars['Int']['output']>;
  /** Social Account System Id */
  socialAccountId: Scalars['Int']['output'];
  /** Social Account Status */
  socialAccountStatus: SocialAccountStatus;
  views?: Maybe<Scalars['Int']['output']>;
};

export type FacebookPageInfluencerSearchResultV2 = {
  __typename?: 'FacebookPageInfluencerSearchResultV2';
  facebookPages: Array<FacebookPageInfluencerV2>;
  totalNumber: Scalars['Int']['output'];
};

export type FacebookPageInfluencerV2 = {
  __typename?: 'FacebookPageInfluencerV2';
  age?: Maybe<Scalars['Int']['output']>;
  analyticsWarning?: Maybe<Scalars['Boolean']['output']>;
  avatar?: Maybe<Scalars['String']['output']>;
  averageLikes?: Maybe<Scalars['Float']['output']>;
  averageViews?: Maybe<Scalars['Float']['output']>;
  bio?: Maybe<Scalars['String']['output']>;
  country: CountryName;
  engagementPostedCount?: Maybe<Scalars['Int']['output']>;
  engagementProposedCount?: Maybe<Scalars['Int']['output']>;
  gender: Genders;
  id: Scalars['ID']['output'];
  influencerEngagement?: Maybe<Scalars['Int']['output']>;
  influencerEngagementRate?: Maybe<Scalars['Float']['output']>;
  isBrandAccount: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  popularPosts?: Maybe<Array<SocialAccountPopularPost>>;
  shares?: Maybe<Scalars['Int']['output']>;
  socialAccountEngagement?: Maybe<Scalars['Int']['output']>;
  socialAccountEngagementRate?: Maybe<Scalars['Float']['output']>;
  socialAccountFollowers?: Maybe<Scalars['Int']['output']>;
  socialAccountId: Scalars['ID']['output'];
  socialAccountStatus: SocialAccountStatus;
};

export type FacebookPagePostsAnalyticsFanActivity = {
  __typename?: 'FacebookPagePostsAnalyticsFanActivity';
  hour: Scalars['Int']['output'];
  onlineCount: Scalars['Int']['output'];
  weekday: Scalars['Int']['output'];
};

export type FacebookPageStatisticsData = {
  __typename?: 'FacebookPageStatisticsData';
  growth: Scalars['Float']['output'];
  value: Scalars['Float']['output'];
};

export type FacebookPostForLinkBio = {
  __typename?: 'FacebookPostForLinkBio';
  id: Scalars['ID']['output'];
  thumbnail: Scalars['String']['output'];
  totalPostsCount: Scalars['Int']['output'];
};

export type FanActivity = {
  __typename?: 'FanActivity';
  activityType: FanActivityType;
  changes: Array<ActivityChange>;
  commenterId?: Maybe<Scalars['Long']['output']>;
  created: Scalars['String']['output'];
  id: Scalars['Long']['output'];
};

export enum FanActivityType {
  COMMENT_CREATE = 'COMMENT_CREATE',
  FAN_CREATE = 'FAN_CREATE',
  FAN_UPDATE = 'FAN_UPDATE',
  ORDER_CANCEL = 'ORDER_CANCEL',
  ORDER_CREATE = 'ORDER_CREATE',
  ORDER_UPDATE = 'ORDER_UPDATE',
}

export type FanDetails = {
  __typename?: 'FanDetails';
  contactNumber?: Maybe<Scalars['String']['output']>;
  countryId?: Maybe<Scalars['String']['output']>;
  created: Scalars['String']['output'];
  email?: Maybe<Scalars['String']['output']>;
  id: Scalars['Long']['output'];
  lineAccount?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  shopifyAccount?: Maybe<Scalars['Long']['output']>;
  tags: Array<FanTag>;
};

export type FanFormAnswer = {
  __typename?: 'FanFormAnswer';
  answerOptionTitles: Array<Scalars['String']['output']>;
  answerText?: Maybe<Scalars['String']['output']>;
  order: Scalars['Int']['output'];
  questionId: Scalars['Long']['output'];
  questionTitle: Scalars['String']['output'];
  questionType: QuestionType;
};

export enum FanGender {
  FEMALE = 'FEMALE',
  MALE = 'MALE',
  OTHER = 'OTHER',
}

export type FanListOrderBy = {
  field: FanListSortableField;
  order: Order;
};

export enum FanListSortableField {
  CREATED = 'CREATED',
}

export type FanOrderDetails = {
  __typename?: 'FanOrderDetails';
  currency: Scalars['String']['output'];
  items: Array<FanOrderItem>;
  orderId: Scalars['String']['output'];
  orderTime: Scalars['String']['output'];
  paymentStatus: ShopifyOrderPaymentStatus;
  shippingStatus?: Maybe<ShopifyOrderShippingStatus>;
  total: Scalars['Float']['output'];
};

export type FanOrderItem = {
  __typename?: 'FanOrderItem';
  image?: Maybe<Scalars['String']['output']>;
  price: Scalars['Float']['output'];
  productName: Scalars['String']['output'];
  quantity: Scalars['Int']['output'];
  variant?: Maybe<Scalars['String']['output']>;
};

export type FanTag = {
  __typename?: 'FanTag';
  id: Scalars['Long']['output'];
  tag: Scalars['String']['output'];
};

export type FileUploadUrls = {
  __typename?: 'FileUploadUrls';
  fileUploadUrls: Array<SignedUrlData>;
};

export enum FilterByDateRange {
  LAST_7_DAYS = 'LAST_7_DAYS',
  LAST_12_MONTHS = 'LAST_12_MONTHS',
  LAST_30_DAYS = 'LAST_30_DAYS',
  LAST_90_DAYS = 'LAST_90_DAYS',
}

export enum FilterByNumber {
  EQUAL = 'EQUAL',
  GREATER_THAN = 'GREATER_THAN',
  LESS_THAN = 'LESS_THAN',
}

export type FollowersAgeGenderGroup = {
  __typename?: 'FollowersAgeGenderGroup';
  ageGroup?: Maybe<Array<FollowersAgeGroup>>;
  femaleRate?: Maybe<Array<Scalars['Float']['output']>>;
  maleRate?: Maybe<Array<Scalars['Float']['output']>>;
};

export type FollowersAgeGroup = {
  __typename?: 'FollowersAgeGroup';
  end: Scalars['Int']['output'];
  start: Scalars['Int']['output'];
};

export type FollowersAgeGroupInput = {
  end: Scalars['Int']['input'];
  start: Scalars['Int']['input'];
};

export type FollowersByTime = {
  __typename?: 'FollowersByTime';
  followersCount?: Maybe<Scalars['Int']['output']>;
  time: Scalars['DateTime']['output'];
};

export type FollowersCountry = {
  __typename?: 'FollowersCountry';
  /** Country ID */
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  rate: Scalars['Float']['output'];
};

export enum FollowersGrowthPeriodEnum {
  ONE_MONTH = 'ONE_MONTH',
  ONE_YEAR = 'ONE_YEAR',
  SIX_MONTHS = 'SIX_MONTHS',
  THREE_MONTHS = 'THREE_MONTHS',
}

export enum FontSize {
  LG = 'LG',
  MD = 'MD',
  SM = 'SM',
  XL = 'XL',
  XXL = 'XXL',
}

export type FormItem = {
  __typename?: 'FormItem';
  id: Scalars['Long']['output'];
  lastUpdated: Scalars['String']['output'];
  status: FormStatus;
  submissionCount: Scalars['Long']['output'];
  title: Scalars['String']['output'];
};

export type FormListOrderBy = {
  field?: InputMaybe<FormListSortableField>;
  order?: InputMaybe<Order>;
};

export enum FormListSortableField {
  CREATED = 'CREATED',
  TITLE = 'TITLE',
  UPDATED = 'UPDATED',
}

export type FormQuestionAnswer = {
  __typename?: 'FormQuestionAnswer';
  answerOptionIds: Array<Scalars['Long']['output']>;
  answerText?: Maybe<Scalars['String']['output']>;
  questionId: Scalars['Long']['output'];
  questionTitle: Scalars['String']['output'];
  questionType: QuestionType;
};

export type FormReportAnswerOption = {
  __typename?: 'FormReportAnswerOption';
  count: Scalars['Int']['output'];
  optionTitle: Scalars['String']['output'];
};

export type FormReportQuestionAnswer = {
  __typename?: 'FormReportQuestionAnswer';
  answerOptions: Array<FormReportAnswerOption>;
  answerTexts: Array<Scalars['String']['output']>;
  numberResponses: Scalars['Int']['output'];
  questionId: Scalars['Long']['output'];
  questionTitle: Scalars['String']['output'];
  questionType: QuestionType;
};

export type FormReportSummaryInput = {
  id: Scalars['Long']['input'];
};

export type FormReportSummaryPayload = {
  __typename?: 'FormReportSummaryPayload';
  answers: Array<FormReportQuestionAnswer>;
};

export type FormRespondentItem = {
  __typename?: 'FormRespondentItem';
  email?: Maybe<Scalars['String']['output']>;
  fanId?: Maybe<Scalars['Long']['output']>;
  firstName: Scalars['String']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  page?: Maybe<Scalars['Long']['output']>;
  submissionId: Scalars['Long']['output'];
};

export type FormRespondentsCountInput = {
  id: Scalars['Long']['input'];
};

export type FormRespondentsCountPayload = {
  __typename?: 'FormRespondentsCountPayload';
  total: Scalars['Long']['output'];
};

export type FormRespondentsListInput = {
  id: Scalars['Long']['input'];
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
};

export type FormRespondentsListPayload = {
  __typename?: 'FormRespondentsListPayload';
  respondents: Array<FormRespondentItem>;
};

export enum FormStatus {
  DRAFT = 'DRAFT',
  PRIVATE = 'PRIVATE',
  PUBLIC = 'PUBLIC',
}

export enum Genders {
  FEMALE = 'FEMALE',
  MALE = 'MALE',
  NOT_APPLICABLE = 'NOT_APPLICABLE',
  UNKNOWN = 'UNKNOWN',
  UPDATE_LATER = 'UPDATE_LATER',
}

export type GenerateImportFansUploadURLPayload = {
  __typename?: 'GenerateImportFansUploadURLPayload';
  fileName: Scalars['String']['output'];
  signedUrl: Scalars['String']['output'];
};

export type GeneratePartnerInfluencerSignUpUrl = {
  __typename?: 'GeneratePartnerInfluencerSignUpUrl';
  url: Scalars['String']['output'];
};

export type GenerateTalentInfluencerSignUpUrl = {
  __typename?: 'GenerateTalentInfluencerSignUpUrl';
  url: Scalars['String']['output'];
};

export type GetAccountInformationPayload = {
  __typename?: 'GetAccountInformationPayload';
  brandName?: Maybe<Scalars['String']['output']>;
  categoryIds: Array<Scalars['Int']['output']>;
  countryId: Scalars['String']['output'];
  dateOfBirth?: Maybe<Scalars['String']['output']>;
  gender: Genders;
  introduce: Scalars['String']['output'];
  name: Scalars['String']['output'];
  regionId?: Maybe<Scalars['Int']['output']>;
};

export type GetAllCampaignsCountForSearchJobInput = {
  categoryIds: Array<Scalars['Int']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  maxPrice?: InputMaybe<Scalars['Float']['input']>;
  maxReward?: InputMaybe<Scalars['Float']['input']>;
  minPrice?: InputMaybe<Scalars['Float']['input']>;
  minReward?: InputMaybe<Scalars['Float']['input']>;
  promotionMethods: Array<CampaignSocialMediaType>;
};

export type GetAllCampaignsCountForSearchJobPayload = {
  __typename?: 'GetAllCampaignsCountForSearchJobPayload';
  count?: Maybe<Scalars['Int']['output']>;
};

export type GetAllCampaignsForSearchJobInput = {
  categoryIds: Array<Scalars['Int']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Int']['input'];
  maxPrice?: InputMaybe<Scalars['Float']['input']>;
  maxReward?: InputMaybe<Scalars['Float']['input']>;
  minPrice?: InputMaybe<Scalars['Float']['input']>;
  minReward?: InputMaybe<Scalars['Float']['input']>;
  offset: Scalars['Int']['input'];
  orderBy: AllCampaignsSearchJobsOrderBy;
  promotionMethods: Array<CampaignSocialMediaType>;
};

export type GetAllCampaignsForSearchJobPayload = {
  __typename?: 'GetAllCampaignsForSearchJobPayload';
  campaigns: Array<CampaignsForSearchJobPayload>;
};

export type GetAnyXAccountsByKeywordInput = {
  countryId: Scalars['String']['input'];
  keyword: Scalars['String']['input'];
};

export type GetAnyXAccountsByKeywordPayload = {
  __typename?: 'GetAnyXAccountsByKeywordPayload';
  accounts: Array<AnyXAccountInfoPayload>;
};

export type GetAutoManagedCampaignByIdInput = {
  id: Scalars['Int']['input'];
};

export type GetAutoManagedCampaignForInfluencerSearchJobInput = {
  id: Scalars['Int']['input'];
};

export type GetAutoManagedCampaignForInfluencerYourJobInput = {
  id: Scalars['Int']['input'];
};

export type GetAutoManagedCampaignsCountInput = {
  categoryIds: Array<Scalars['ID']['input']>;
  countryId?: InputMaybe<Scalars['String']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  marketplaceType?: InputMaybe<AutoManagedCampaignType>;
  maxCommissionRate?: InputMaybe<Scalars['Float']['input']>;
  maxPrice?: InputMaybe<Scalars['Float']['input']>;
  minCommissionRate?: InputMaybe<Scalars['Float']['input']>;
  minPrice?: InputMaybe<Scalars['Float']['input']>;
  status?: InputMaybe<AutoManagedCampaignStatus>;
};

export type GetAutoManagedCampaignsCountPayload = {
  __typename?: 'GetAutoManagedCampaignsCountPayload';
  count: Scalars['Int']['output'];
};

export type GetAutoManagedCampaignsInput = {
  categoryIds: Array<Scalars['ID']['input']>;
  countryId?: InputMaybe<Scalars['String']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Int']['input'];
  marketplaceType?: InputMaybe<AutoManagedCampaignType>;
  maxCommissionRate?: InputMaybe<Scalars['Float']['input']>;
  maxPrice?: InputMaybe<Scalars['Float']['input']>;
  minCommissionRate?: InputMaybe<Scalars['Float']['input']>;
  minPrice?: InputMaybe<Scalars['Float']['input']>;
  offset: Scalars['Int']['input'];
  orderBy: GetAutoManagedCampaignsOrderBy;
  status?: InputMaybe<AutoManagedCampaignStatus>;
};

export type GetAutoManagedCampaignsOrderBy = {
  field?: InputMaybe<GetAutoManagedCampaignsSortField>;
  order?: InputMaybe<Order>;
};

export type GetAutoManagedCampaignsPayload = {
  __typename?: 'GetAutoManagedCampaignsPayload';
  campaigns: Array<AutoManagedCampaignInfoPayload>;
};

export enum GetAutoManagedCampaignsSortField {
  COMMISSION_RATE = 'COMMISSION_RATE',
  JOINED_CREATORS = 'JOINED_CREATORS',
  ORDERS = 'ORDERS',
  SALES = 'SALES',
}

export type GetChatEventsInput = {
  chatId: Scalars['String']['input'];
  keyword?: InputMaybe<Scalars['String']['input']>;
  newerThan?: InputMaybe<Scalars['String']['input']>;
  olderThan?: InputMaybe<Scalars['String']['input']>;
};

export type GetChatEventsPayload = {
  __typename?: 'GetChatEventsPayload';
  chatEvents: Array<ChatEvent>;
  lastCheckedAt?: Maybe<Scalars['String']['output']>;
  userName: Scalars['String']['output'];
};

export type GetCreatorTagsByIdInput = {
  tagIds: Array<Scalars['Long']['input']>;
};

export type GetCreatorTagsByIdPayload = {
  __typename?: 'GetCreatorTagsByIdPayload';
  tags: Array<FanTag>;
};

export type GetCreatorTagsInput = {
  keyword: Scalars['String']['input'];
};

export type GetCreatorTagsPayload = {
  __typename?: 'GetCreatorTagsPayload';
  tags: Array<FanTag>;
};

export type GetCustomerTagsInput = {
  order: CustomerTagOrder;
};

export type GetCustomerTagsPayload = {
  __typename?: 'GetCustomerTagsPayload';
  tags: Array<CustomerTagPayload>;
};

export type GetEmailListCountInput = {
  keyword: Scalars['String']['input'];
  status?: InputMaybe<EmailTemplateStatus>;
};

export type GetEmailListCountPayload = {
  __typename?: 'GetEmailListCountPayload';
  total: Scalars['Long']['output'];
};

export type GetEmailListInput = {
  keyword: Scalars['String']['input'];
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  orderBy: EmailListOrderBy;
  status?: InputMaybe<EmailTemplateStatus>;
};

export type GetEmailListPayload = {
  __typename?: 'GetEmailListPayload';
  emails: Array<EmailInfoForList>;
};

export type GetEmailOverviewInput = {
  emailId: Scalars['Long']['input'];
};

export type GetEmailOverviewPayload = {
  __typename?: 'GetEmailOverviewPayload';
  info: EmailInfo;
  stats: EmailStatistics;
};

export type GetEmailRecipientFan = {
  __typename?: 'GetEmailRecipientFan';
  email?: Maybe<Scalars['String']['output']>;
  id: Scalars['Long']['output'];
  name: Scalars['String']['output'];
};

export type GetEmailRecipientTag = {
  __typename?: 'GetEmailRecipientTag';
  count: Scalars['Long']['output'];
  id: Scalars['Long']['output'];
  tag: Scalars['String']['output'];
};

export type GetEmailRecipientsCountInput = {
  emailId: Scalars['Long']['input'];
  keyword: Scalars['String']['input'];
  status?: InputMaybe<EmailEventStatusForSearch>;
};

export type GetEmailRecipientsCountPayload = {
  __typename?: 'GetEmailRecipientsCountPayload';
  total: Scalars['Long']['output'];
};

export type GetEmailRecipientsInput = {
  emailId: Scalars['Long']['input'];
  keyword: Scalars['String']['input'];
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  status?: InputMaybe<EmailEventStatusForSearch>;
};

export type GetEmailRecipientsPayload = {
  __typename?: 'GetEmailRecipientsPayload';
  recipients: Array<RecipientDetail>;
};

export type GetEmailTemplateInput = {
  id: Scalars['Long']['input'];
};

export type GetEmailTemplatePayload = {
  __typename?: 'GetEmailTemplatePayload';
  fans: Array<Scalars['Long']['output']>;
  id: Scalars['Long']['output'];
  message: Scalars['String']['output'];
  scheduleDate?: Maybe<Scalars['String']['output']>;
  sendNow?: Maybe<Scalars['Boolean']['output']>;
  sender: Scalars['String']['output'];
  status: EmailTemplateStatus;
  subject: Scalars['String']['output'];
  tags: Array<Scalars['Long']['output']>;
};

export type GetFanActivitiesInput = {
  fanId: Scalars['Long']['input'];
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
};

export type GetFanActivitiesPayload = {
  __typename?: 'GetFanActivitiesPayload';
  activities: Array<FanActivity>;
  total: Scalars['Int']['output'];
};

export type GetFanFormResponseInput = {
  fanId: Scalars['Long']['input'];
  submissionId: Scalars['Long']['input'];
};

export type GetFanFormResponsePayload = {
  __typename?: 'GetFanFormResponsePayload';
  answers: Array<FanFormAnswer>;
  formId: Scalars['Long']['output'];
  formTitle: Scalars['String']['output'];
};

export type GetFanHeaderPayload = {
  __typename?: 'GetFanHeaderPayload';
  avatar: Scalars['String']['output'];
  created: Scalars['String']['output'];
  currencyId?: Maybe<Scalars['String']['output']>;
  firstName: Scalars['String']['output'];
  id: Scalars['Long']['output'];
  lastName: Scalars['String']['output'];
  lastOrder?: Maybe<Scalars['String']['output']>;
  lineAccount?: Maybe<Scalars['String']['output']>;
  shopifyAccount?: Maybe<Scalars['Long']['output']>;
  totalOrders?: Maybe<Scalars['Int']['output']>;
  totalSpent?: Maybe<Scalars['Float']['output']>;
};

export type GetFanInput = {
  fanId: Scalars['Long']['input'];
};

export type GetFanOrdersInput = {
  fanId: Scalars['Long']['input'];
};

export type GetFanOrdersPayload = {
  __typename?: 'GetFanOrdersPayload';
  orders: Array<FanOrderDetails>;
};

export type GetFanParticipationFormListInput = {
  fanId: Scalars['Long']['input'];
};

export type GetFanParticipationFormListPayload = {
  __typename?: 'GetFanParticipationFormListPayload';
  forms: Array<ParticipationForm>;
};

export type GetFanPayload = {
  __typename?: 'GetFanPayload';
  address1?: Maybe<Scalars['String']['output']>;
  address2?: Maybe<Scalars['String']['output']>;
  birthday?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  contactNumber?: Maybe<Scalars['String']['output']>;
  countryId?: Maybe<Scalars['String']['output']>;
  editable: Scalars['Boolean']['output'];
  email?: Maybe<Scalars['String']['output']>;
  firstName: Scalars['String']['output'];
  gender?: Maybe<FanGender>;
  id: Scalars['Long']['output'];
  lastName: Scalars['String']['output'];
  postalCode?: Maybe<Scalars['String']['output']>;
  province?: Maybe<Scalars['String']['output']>;
  tags: Array<Scalars['String']['output']>;
};

export type GetFanProvincesInput = {
  countryId: Scalars['String']['input'];
};

export type GetFansCountInput = {
  createdDate?: InputMaybe<FilterByDateRange>;
  exactAmountSpent?: InputMaybe<Scalars['Float']['input']>;
  exactNumberOfOrders?: InputMaybe<Scalars['Int']['input']>;
  keyword: Scalars['String']['input'];
  lastOrderDate?: InputMaybe<FilterByDateRange>;
  maxAmountSpent?: InputMaybe<Scalars['Float']['input']>;
  maxNumberOfOrders?: InputMaybe<Scalars['Int']['input']>;
  minAmountSpent?: InputMaybe<Scalars['Float']['input']>;
  minNumberOfOrders?: InputMaybe<Scalars['Int']['input']>;
  tagIds: Array<Scalars['Long']['input']>;
};

export type GetFansCountPayload = {
  __typename?: 'GetFansCountPayload';
  total: Scalars['Int']['output'];
};

export type GetFansInput = {
  createdDate?: InputMaybe<FilterByDateRange>;
  exactAmountSpent?: InputMaybe<Scalars['Float']['input']>;
  exactNumberOfOrders?: InputMaybe<Scalars['Int']['input']>;
  keyword: Scalars['String']['input'];
  lastOrderDate?: InputMaybe<FilterByDateRange>;
  limit: Scalars['Int']['input'];
  maxAmountSpent?: InputMaybe<Scalars['Float']['input']>;
  maxNumberOfOrders?: InputMaybe<Scalars['Int']['input']>;
  minAmountSpent?: InputMaybe<Scalars['Float']['input']>;
  minNumberOfOrders?: InputMaybe<Scalars['Int']['input']>;
  offset: Scalars['Int']['input'];
  orderBy: FanListOrderBy;
  tagIds: Array<Scalars['Long']['input']>;
};

export type GetFansPayload = {
  __typename?: 'GetFansPayload';
  fans: Array<FanDetails>;
};

export type GetFormInput = {
  id: Scalars['Long']['input'];
};

export type GetFormInputPayload = {
  __typename?: 'GetFormInputPayload';
  description: Scalars['String']['output'];
  hash: Scalars['String']['output'];
  id: Scalars['Long']['output'];
  questions: Array<GetFormQuestion>;
  status: FormStatus;
  thankDescription: Scalars['String']['output'];
  thankTitle: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type GetFormQuestion = {
  __typename?: 'GetFormQuestion';
  id: Scalars['Long']['output'];
  image?: Maybe<Scalars['String']['output']>;
  isRequired: Scalars['Boolean']['output'];
  options: Array<GetFormQuestionOption>;
  order: Scalars['Int']['output'];
  questionType: QuestionType;
  title: Scalars['String']['output'];
};

export type GetFormQuestionOption = {
  __typename?: 'GetFormQuestionOption';
  id: Scalars['Long']['output'];
  optionTitle: Scalars['String']['output'];
  order: Scalars['Int']['output'];
};

export type GetFormResponseByPageInput = {
  formId: Scalars['Long']['input'];
  page: Scalars['Long']['input'];
};

export type GetFormResponseInput = {
  submissionId: Scalars['Long']['input'];
};

export type GetFormResponsePayload = {
  __typename?: 'GetFormResponsePayload';
  answers: Array<FormQuestionAnswer>;
  created: Scalars['String']['output'];
  formTitle: Scalars['String']['output'];
  submissionId: Scalars['Long']['output'];
};

export type GetFormTemplateInput = {
  hash: Scalars['String']['input'];
};

export type GetFormTemplateOption = {
  __typename?: 'GetFormTemplateOption';
  id: Scalars['Long']['output'];
  optionTitle: Scalars['String']['output'];
  order: Scalars['Int']['output'];
};

export type GetFormTemplatePayload = {
  __typename?: 'GetFormTemplatePayload';
  description: Scalars['String']['output'];
  id: Scalars['Long']['output'];
  questions: Array<GetFormTemplateQuestion>;
  thankDescription: Scalars['String']['output'];
  thankTitle: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type GetFormTemplateQuestion = {
  __typename?: 'GetFormTemplateQuestion';
  id: Scalars['Long']['output'];
  image?: Maybe<Scalars['String']['output']>;
  isRequired: Scalars['Boolean']['output'];
  options: Array<GetFormTemplateOption>;
  order: Scalars['Int']['output'];
  questionType: QuestionType;
  title: Scalars['String']['output'];
};

export type GetFormsCountInput = {
  keyword: Scalars['String']['input'];
  status?: InputMaybe<FormStatus>;
};

export type GetFormsCountPayload = {
  __typename?: 'GetFormsCountPayload';
  total: Scalars['Long']['output'];
};

export type GetFormsListInput = {
  keyword: Scalars['String']['input'];
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  orderBy: FormListOrderBy;
  status?: InputMaybe<FormStatus>;
};

export type GetFormsListPayload = {
  __typename?: 'GetFormsListPayload';
  forms: Array<FormItem>;
};

export type GetInfluencerBioAnalyticsInput = {
  endDate: Scalars['String']['input'];
  startDate: Scalars['String']['input'];
};

export type GetInfluencerBioAnalyticsPayload = {
  __typename?: 'GetInfluencerBioAnalyticsPayload';
  audience: InfluencerBioAnalyticsAudience;
  clicks: InfluencerBioAnalyticsOverview;
  sessions: InfluencerBioAnalyticsOverview;
  views: InfluencerBioAnalyticsOverview;
};

export type GetInfluencerBioPayload = {
  __typename?: 'GetInfluencerBioPayload';
  id: Scalars['Int']['output'];
  isPublic: Scalars['Boolean']['output'];
  linkName: Scalars['String']['output'];
  sections: Array<BioSection>;
};

export type GetInfluencerBioPayloadV2 = {
  __typename?: 'GetInfluencerBioPayloadV2';
  id: Scalars['Int']['output'];
  isFinish: Scalars['Boolean']['output'];
  isPublic: Scalars['Boolean']['output'];
  linkName?: Maybe<Scalars['String']['output']>;
  profile: BioInfluencerProfile;
  sections: Array<BioSectionV2>;
  theme?: Maybe<BioTheme>;
  themeV2?: Maybe<BioThemeV2>;
};

export type GetInfluencerChatServicesPayload = {
  __typename?: 'GetInfluencerChatServicesPayload';
  line?: Maybe<GetLineServicePayload>;
};

export type GetLineBroadcastEstimateRecipientsInput = {
  recipientType: RecipientType;
  tagNames: Array<Scalars['String']['input']>;
};

export type GetLineBroadcastEstimateRecipientsPayload = {
  __typename?: 'GetLineBroadcastEstimateRecipientsPayload';
  total: Scalars['Int']['output'];
};

export type GetLineBroadcastMessageInput = {
  lineBroadcastMessageId: Scalars['String']['input'];
};

export type GetLineBroadcastMessagePayload = {
  __typename?: 'GetLineBroadcastMessagePayload';
  content: Array<LineContentItemPayload>;
  createdDateTime: Scalars['String']['output'];
  deliveredDateTime?: Maybe<Scalars['String']['output']>;
  deliveryTiming: DeliveryTimingItemPayload;
  id: Scalars['String']['output'];
  imageUrl: Scalars['String']['output'];
  recipient: RecipientItemPayload;
  status?: Maybe<LineBroadcastStatus>;
  title: Scalars['String']['output'];
};

export type GetLineBroadcastSummaryInput = {
  from?: InputMaybe<Scalars['String']['input']>;
  to?: InputMaybe<Scalars['String']['input']>;
};

export type GetLineBroadcastSummaryPayload = {
  __typename?: 'GetLineBroadcastSummaryPayload';
  clickRate: LineBroadcastFloatValue;
  delivered: LineBroadcastIntValue;
  openRate: LineBroadcastFloatValue;
};

export type GetLineBroadcastsCountInput = {
  from?: InputMaybe<Scalars['String']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<LineBroadcastStatus>;
  tag?: InputMaybe<Scalars['String']['input']>;
  to?: InputMaybe<Scalars['String']['input']>;
};

export type GetLineBroadcastsCountPayload = {
  __typename?: 'GetLineBroadcastsCountPayload';
  total: Scalars['Int']['output'];
};

export type GetLineBroadcastsInput = {
  from?: InputMaybe<Scalars['String']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  status?: InputMaybe<LineBroadcastStatus>;
  tag?: InputMaybe<Scalars['String']['input']>;
  to?: InputMaybe<Scalars['String']['input']>;
};

export type GetLineBroadcastsPayload = {
  __typename?: 'GetLineBroadcastsPayload';
  broadcasts: Array<LineBroadcast>;
};

export type GetLineChannelPayload = {
  __typename?: 'GetLineChannelPayload';
  id: Scalars['Long']['output'];
  lineChannelId: Scalars['String']['output'];
  lineChannelSecret: Scalars['String']['output'];
  lineKid: Scalars['String']['output'];
  publicKey: Scalars['String']['output'];
  publicKeyId: Scalars['Long']['output'];
};

export type GetLineChatCountInput = {
  keyword: Scalars['String']['input'];
};

export type GetLineChatCountPayload = {
  __typename?: 'GetLineChatCountPayload';
  activatedCount: Scalars['Int']['output'];
  assignedToMe: Scalars['Int']['output'];
};

export type GetLineChatInput = {
  chatId: Scalars['String']['input'];
};

export type GetLineChatPayload = {
  __typename?: 'GetLineChatPayload';
  avatar?: Maybe<Scalars['String']['output']>;
  chatId: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type GetLineChatsInput = {
  keyword: Scalars['String']['input'];
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  tagName?: InputMaybe<Scalars['String']['input']>;
  typeFilter: CRMChatTypeFilter;
};

export type GetLineChatsPayload = {
  __typename?: 'GetLineChatsPayload';
  chats: Array<LineChat>;
};

export type GetLineLoginDetailPayload = {
  __typename?: 'GetLineLoginDetailPayload';
  liffId?: Maybe<Scalars['String']['output']>;
  lineLoginChannelId?: Maybe<Scalars['String']['output']>;
};

export type GetLinePublicKeyPayload = {
  __typename?: 'GetLinePublicKeyPayload';
  id: Scalars['Long']['output'];
  publicKey: Scalars['String']['output'];
};

export type GetLineServicePayload = {
  __typename?: 'GetLineServicePayload';
  liffUrl: Scalars['String']['output'];
};

export type GetLinkBioFacebookAccountPostsInput = {
  id: Scalars['ID']['input'];
};

export type GetLinkBioFacebookAccountPostsPayload = {
  __typename?: 'GetLinkBioFacebookAccountPostsPayload';
  posts: Array<GetLinkBioFacebookPostPayload>;
};

export type GetLinkBioFacebookPagePostsInput = {
  id: Scalars['ID']['input'];
};

export type GetLinkBioFacebookPagePostsPayload = {
  __typename?: 'GetLinkBioFacebookPagePostsPayload';
  posts: Array<GetLinkBioFacebookPostPayload>;
};

export type GetLinkBioFacebookPostPayload = {
  __typename?: 'GetLinkBioFacebookPostPayload';
  postSystemId: Scalars['ID']['output'];
  thumbnail: Scalars['String']['output'];
};

export type GetLinkBioThemeColorPayload = {
  __typename?: 'GetLinkBioThemeColorPayload';
  bg: LinkBioBackground;
  button?: Maybe<BioButtonStyle>;
  color: LinkBioPageColor;
  font?: Maybe<BioFontStyle>;
  theme?: Maybe<BioTheme>;
};

export type GetMessageTemplatesPayload = {
  __typename?: 'GetMessageTemplatesPayload';
  templates: Array<MessageTemplateItem>;
};

export type GetPublicInfluencerBioInput = {
  linkName: Scalars['String']['input'];
};

export type GetPublicInfluencerBioPayload = {
  __typename?: 'GetPublicInfluencerBioPayload';
  id: Scalars['Int']['output'];
  isPublic: Scalars['Boolean']['output'];
  profile: BioInfluencerProfile;
  sections: Array<PublicBioSection>;
  themeV2?: Maybe<BioThemeV2>;
  themes?: Maybe<PublicBioTheme>;
};

export type GetSelectableYoutubeAnalyticsChannelsInput = {
  callbackUrl: Scalars['String']['input'];
  response: Scalars['String']['input'];
};

export type GetShopifyAccountPayload = {
  __typename?: 'GetShopifyAccountPayload';
  id: Scalars['Long']['output'];
  shopName: Scalars['String']['output'];
};

export type GetTagsAndFansRecipientsInput = {
  keyword: Scalars['String']['input'];
};

export type GetTagsAndFansRecipientsPayload = {
  __typename?: 'GetTagsAndFansRecipientsPayload';
  fans: Array<GetEmailRecipientFan>;
  tags: Array<GetEmailRecipientTag>;
};

export type GoogleSlideFile = {
  __typename?: 'GoogleSlideFile';
  date: Scalars['Date']['output'];
  slideUrl: Scalars['String']['output'];
};

export type Hashtag = {
  __typename?: 'Hashtag';
  blocked: Scalars['Boolean']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type HashtagType = {
  __typename?: 'HashtagType';
  count?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type HubspotCompanyData = {
  __typename?: 'HubspotCompanyData';
  id: Scalars['String']['output'];
  internalNetsuiteId?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
};

export type HubspotDealData = {
  __typename?: 'HubspotDealData';
  companyId: Scalars['String']['output'];
  country?: Maybe<Scalars['String']['output']>;
  dealName: Scalars['String']['output'];
  endDate?: Maybe<Scalars['Date']['output']>;
  id: Scalars['String']['output'];
  ownerName?: Maybe<Scalars['String']['output']>;
  startDate?: Maybe<Scalars['Date']['output']>;
};

export type HubspotDealDataForSearch = {
  __typename?: 'HubspotDealDataForSearch';
  dealName: Scalars['String']['output'];
  id: Scalars['String']['output'];
};

export type ImageInput = {
  fileName: Scalars['String']['input'];
  mimeType: Scalars['String']['input'];
  previewUrl: Scalars['String']['input'];
  url: Scalars['String']['input'];
};

export type ImageItemInput = {
  imageUrl: Scalars['String']['input'];
  label?: InputMaybe<Scalars['String']['input']>;
  url: Scalars['String']['input'];
};

export type ImageItemPayload = {
  __typename?: 'ImageItemPayload';
  imageUrl: Scalars['String']['output'];
  label?: Maybe<Scalars['String']['output']>;
  url: Scalars['String']['output'];
};

export type ImageMessageInput = {
  images: Array<ImageItemInput>;
};

export type ImageMessagePayload = {
  __typename?: 'ImageMessagePayload';
  images: Array<ImageItemPayload>;
};

export type ImagePayload = {
  __typename?: 'ImagePayload';
  fileName: Scalars['String']['output'];
  mimeType: Scalars['String']['output'];
  previewUrl: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type ImageVideoMessageInput = {
  media: MediaItemInput;
};

export type ImageVideoMessagePayload = {
  __typename?: 'ImageVideoMessagePayload';
  media: MediaItemPayload;
};

export type ImportFansInput = {
  uploadFileUrl: Scalars['String']['input'];
};

export type ImportFansPayload = {
  __typename?: 'ImportFansPayload';
  ok: Scalars['Boolean']['output'];
};

/**  TODO old API. don't forget to remove */
export type Influencer = {
  __typename?: 'Influencer';
  age?: Maybe<Scalars['Int']['output']>;
  avatar: Scalars['String']['output'];
  categories: Array<CategoryName>;
  country: CountryName;
  engagement: InfluencerEngagement;
  engagementPostedCount?: Maybe<Scalars['Int']['output']>;
  engagementProposedCount?: Maybe<Scalars['Int']['output']>;
  facebook?: Maybe<SocialAccount>;
  followersCount?: Maybe<Scalars['Int']['output']>;
  gender: Genders;
  id: Scalars['Int']['output'];
  instagram?: Maybe<SocialAccount>;
  marketplaceJoinedCount?: Maybe<Scalars['Int']['output']>;
  marketplacePostedCount?: Maybe<Scalars['Int']['output']>;
  name: Scalars['String']['output'];
  status: SocialAccountStatus;
  twitter?: Maybe<SocialAccount>;
  youtube?: Maybe<SocialAccount>;
};

export type InfluencerAddYoutubeCompareAccountAnalyticsInput = {
  socialAccountId: Scalars['Int']['input'];
  url: Scalars['String']['input'];
};

export type InfluencerAnalyticsMultipleInstagramAccessTokenWarningPayload = {
  __typename?: 'InfluencerAnalyticsMultipleInstagramAccessTokenWarningPayload';
  reconnectStatuses: Array<InfluencerMultipleInstagramAnalyticsAccessTokenWarningItemPayload>;
};

export type InfluencerAnalyticsSponsoredPostCountPayload = {
  __typename?: 'InfluencerAnalyticsSponsoredPostCountPayload';
  total: Scalars['Long']['output'];
};

export type InfluencerAnalyticsSponsoredPostPayload = {
  __typename?: 'InfluencerAnalyticsSponsoredPostPayload';
  averageEngagement: Scalars['Float']['output'];
  averageEngagementRate: Scalars['Float']['output'];
  posts: Array<InfluencerYoutubeSponsoredPost>;
};

export type InfluencerAnalyticsTwitterRefreshAvailabilityPayload = {
  __typename?: 'InfluencerAnalyticsTwitterRefreshAvailabilityPayload';
  isRefreshAvailable: Scalars['Boolean']['output'];
};

export type InfluencerAnalyticsTwitterRefreshInput = {
  socialAccountId: Scalars['Int']['input'];
};

export type InfluencerAnalyticsTwitterRefreshOutput = {
  __typename?: 'InfluencerAnalyticsTwitterRefreshOutput';
  ok: Scalars['Boolean']['output'];
};

export type InfluencerAudienceSection = {
  __typename?: 'InfluencerAudienceSection';
  ageGenderRates: FollowersAgeGenderGroup;
  femaleRate?: Maybe<Scalars['Float']['output']>;
  maleRate?: Maybe<Scalars['Float']['output']>;
};

export type InfluencerBioAnalyticsAudience = {
  __typename?: 'InfluencerBioAnalyticsAudience';
  cities: Array<BioAnalyticsCity>;
  countries: Array<BioAnalyticsCountry>;
  otherLinkClicks: Array<BioAnalyticsOtherLinkClicks>;
  referral: Array<BioAnalyticsReferral>;
  socialMediaClicks: Array<BioAnalyticsSocialMediaClicks>;
  totalClicks: Array<BioAnalyticsTotalClicks>;
};

export type InfluencerBioAnalyticsHistory = {
  __typename?: 'InfluencerBioAnalyticsHistory';
  count: Scalars['Int']['output'];
  date: Scalars['String']['output'];
};

export type InfluencerBioAnalyticsOverview = {
  __typename?: 'InfluencerBioAnalyticsOverview';
  growth: Scalars['Int']['output'];
  history: Array<InfluencerBioAnalyticsHistory>;
  percentage: Scalars['Float']['output'];
  total: Scalars['Int']['output'];
};

export type InfluencerBrandAccounts = {
  __typename?: 'InfluencerBrandAccounts';
  averageEngagement?: Maybe<Scalars['Float']['output']>;
  averageEngagementRate?: Maybe<Scalars['Float']['output']>;
  brandAccounts?: Maybe<Array<BrandAccount>>;
};

export type InfluencerCampaignDetail = {
  __typename?: 'InfluencerCampaignDetail';
  /** Campaign info */
  campaignInfo: InfluencerCampaignDetailInfo;
  /** User currency */
  currency: Scalars['String']['output'];
  joinedDate: Scalars['Date']['output'];
  /** List of posted posts in the campaign */
  posts: Array<InfluencerCampaignPostData>;
  /** Summary stats for campaign */
  summary: InfluencerCampaignStat;
};

export type InfluencerCampaignDetailByPromotion = {
  __typename?: 'InfluencerCampaignDetailByPromotion';
  /** Campaign info */
  campaignInfo: InfluencerCampaignDetailInfoByPromotion;
  /** User currency */
  currency: Scalars['String']['output'];
  /** Summary stats for campaign */
  summary: InfluencerCampaignStatByPromotion;
};

export type InfluencerCampaignDetailInfo = {
  __typename?: 'InfluencerCampaignDetailInfo';
  hasPostTracking?: Maybe<Scalars['Boolean']['output']>;
  /** Campaign ID */
  id?: Maybe<Scalars['Int']['output']>;
  /** Campaign status */
  status: AllCampaignStatus;
  /** Campaign thumbnail */
  thumbNail?: Maybe<Scalars['String']['output']>;
  /** Campaign title */
  title: Scalars['String']['output'];
  /** Campaign type */
  type: CampaignType;
};

export type InfluencerCampaignDetailInfoByPromotion = {
  __typename?: 'InfluencerCampaignDetailInfoByPromotion';
  /** Campaign ID */
  id?: Maybe<Scalars['Int']['output']>;
  promotionMethod: CampaignPromotionMethod;
  /** Campaign thumbnail */
  thumbNail?: Maybe<Scalars['String']['output']>;
  /** Campaign title */
  title: Scalars['String']['output'];
  /** Campaign type */
  type: CampaignType;
};

/** An enumeration. */
export enum InfluencerCampaignListSortField {
  COMMENT = 'COMMENT',
  COST = 'COST',
  ENGAGEMENT = 'ENGAGEMENT',
  ENGAGEMENT_RATE = 'ENGAGEMENT_RATE',
  JOINED_DATE = 'JOINED_DATE',
  LIKE = 'LIKE',
  REACH = 'REACH',
  SHARE = 'SHARE',
}

export type InfluencerCampaignOrderBy = {
  field?: InputMaybe<InfluencerCampaignListSortField>;
  order?: InputMaybe<ORDER>;
};

export type InfluencerCampaignPostData = {
  __typename?: 'InfluencerCampaignPostData';
  /** Post info */
  postInfo: InfluencerCampaignPostInfo;
  /** Summary stats for post */
  summary: InfluencerCampaignStat;
};

export type InfluencerCampaignPostInfo = {
  __typename?: 'InfluencerCampaignPostInfo';
  content?: Maybe<Scalars['String']['output']>;
  /** Post Id */
  id: Scalars['Int']['output'];
  mkpCampaignType?: Maybe<MarketplaceCampaignType>;
  postUrl?: Maybe<Scalars['String']['output']>;
  promotionMethods: Array<CampaignPromotionMethod>;
  socialMedia: CampaignSocialMediaType;
  thumbNail?: Maybe<Scalars['String']['output']>;
};

export type InfluencerCampaignStat = {
  __typename?: 'InfluencerCampaignStat';
  comment: Scalars['Int']['output'];
  /** Influencer cost */
  cost?: Maybe<Scalars['Float']['output']>;
  engagement: Scalars['Int']['output'];
  engagementRate?: Maybe<Scalars['Float']['output']>;
  like?: Maybe<Scalars['Int']['output']>;
  reach?: Maybe<Scalars['Int']['output']>;
  share?: Maybe<Scalars['Int']['output']>;
};

export type InfluencerCampaignStatByPromotion = {
  __typename?: 'InfluencerCampaignStatByPromotion';
  actions?: Maybe<Scalars['Int']['output']>;
  clicks?: Maybe<Scalars['Int']['output']>;
  orders?: Maybe<Scalars['Int']['output']>;
  sales?: Maybe<Scalars['Int']['output']>;
};

export type InfluencerCampaignSummary = {
  __typename?: 'InfluencerCampaignSummary';
  /** average cost for all posts in auto_managed campaigns */
  autoManagedAvgCost?: Maybe<Scalars['Float']['output']>;
  /** number of joined auto_managed campaigns */
  autoManagedJoinedCount?: Maybe<Scalars['Int']['output']>;
  /** average comments for all posts */
  avgComments?: Maybe<Scalars['Float']['output']>;
  /** average engagement for all posts */
  avgEngagement?: Maybe<Scalars['Float']['output']>;
  /** average likes for all posts */
  avgLikes?: Maybe<Scalars['Float']['output']>;
  /** average reach for all posts */
  avgReach?: Maybe<Scalars['Float']['output']>;
  /** User currency */
  currency: Scalars['String']['output'];
  /** average cost for all posts in engagement campaigns */
  engagementAvgCost?: Maybe<Scalars['Float']['output']>;
  /** number of joined engagement campaigns */
  engagementJoinedCount?: Maybe<Scalars['Int']['output']>;
  /** number of posted engagement posts */
  engagementPostedCount?: Maybe<Scalars['Int']['output']>;
  /** average cost for all posts in marketplace campaigns */
  marketplaceAvgCost?: Maybe<Scalars['Float']['output']>;
  /** number of joined marketplace campaigns */
  marketplaceJoinedCount?: Maybe<Scalars['Int']['output']>;
  /** number of posted marketplace posts */
  marketplacePostedCount?: Maybe<Scalars['Int']['output']>;
};

export type InfluencerDeleteYoutubeCompareAccountAnalyticsInput = {
  compareAccountId: Scalars['Int']['input'];
  socialAccountId: Scalars['Int']['input'];
};

export type InfluencerEngagement = {
  __typename?: 'InfluencerEngagement';
  averageEngagement?: Maybe<Scalars['Float']['output']>;
  engagementRate?: Maybe<Scalars['Float']['output']>;
};

export type InfluencerExportDashboardInstagramAccount = {
  __typename?: 'InfluencerExportDashboardInstagramAccount';
  ok: Scalars['Boolean']['output'];
};

export type InfluencerExportYoutubeDashboardAccountAnalyticsInput = {
  endDate: Scalars['String']['input'];
  socialAccountId: Scalars['Int']['input'];
  startDate: Scalars['String']['input'];
  type?: InputMaybe<ExportYoutubeAccountAnalyticsType>;
};

export type InfluencerFacebookPageAnalyticsAccessTokenWarningPayload = {
  __typename?: 'InfluencerFacebookPageAnalyticsAccessTokenWarningPayload';
  needReconnect: Scalars['Boolean']['output'];
};

export type InfluencerFeedPost = {
  __typename?: 'InfluencerFeedPost';
  caption?: Maybe<Scalars['String']['output']>;
  comments?: Maybe<Scalars['Int']['output']>;
  /** SocialPost ID */
  id: Scalars['Int']['output'];
  likes?: Maybe<Scalars['Int']['output']>;
  reactions?: Maybe<Scalars['Int']['output']>;
  shares?: Maybe<Scalars['Int']['output']>;
  socialMedia: SocialAccountType;
  thumbNail?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  views?: Maybe<Scalars['Int']['output']>;
};

export type InfluencerFollowerGrowthSection = {
  __typename?: 'InfluencerFollowerGrowthSection';
  followersCount: Scalars['Int']['output'];
  followersGrowth: Array<FollowersByTime>;
  hasPost?: Maybe<Scalars['Boolean']['output']>;
  /** last updated datetime in UTC */
  lastUpdatedDatetime: Scalars['DateTime']['output'];
  postsCount: Scalars['Int']['output'];
  postsGrowth: Array<PostsByTime>;
};

export type InfluencerHasEstimateApi = {
  __typename?: 'InfluencerHasEstimateApi';
  accountInterest: Scalars['Boolean']['output'];
  audienceDemographics: Scalars['Boolean']['output'];
  message?: Maybe<ConstantHasEstimateMessage>;
};

export type InfluencerInstagramAnalyticsAccessTokenWarningPayload = {
  __typename?: 'InfluencerInstagramAnalyticsAccessTokenWarningPayload';
  dataForReconnect: InfluencerInstagramDataForReconnectPayload;
  needReconnect: Scalars['Boolean']['output'];
};

export type InfluencerInstagramDataForReconnectPayload = {
  __typename?: 'InfluencerInstagramDataForReconnectPayload';
  analyticsAccountId: Scalars['Int']['output'];
};

export type InfluencerInterest = {
  __typename?: 'InfluencerInterest';
  category: Scalars['String']['output'];
  rate: Scalars['Float']['output'];
};

/** An enumeration. */
export enum InfluencerListSortField {
  DATE = 'DATE',
  ENGAGEMENT = 'ENGAGEMENT',
  ENGAGEMENT_POSTED_COUNT = 'ENGAGEMENT_POSTED_COUNT',
  ENGAGEMENT_PROPOSED_COUNT = 'ENGAGEMENT_PROPOSED_COUNT',
  ENGAGEMENT_RATE = 'ENGAGEMENT_RATE',
  FOLLOWERS = 'FOLLOWERS',
  MARKETPLACE_JOINED_COUNT = 'MARKETPLACE_JOINED_COUNT',
  MARKETPLACE_POSTED_COUNT = 'MARKETPLACE_POSTED_COUNT',
}

export type InfluencerManagerOutput = {
  __typename?: 'InfluencerManagerOutput';
  email: Scalars['String']['output'];
  name: Scalars['String']['output'];
  phoneNumber?: Maybe<Scalars['String']['output']>;
  userId: Scalars['Int']['output'];
};

export type InfluencerMultipleInstagramAnalyticsAccessTokenWarningItemPayload = {
  __typename?: 'InfluencerMultipleInstagramAnalyticsAccessTokenWarningItemPayload';
  dataForReconnect: InfluencerMultipleInstagramDataForReconnectPayload;
  needReconnect: Scalars['Boolean']['output'];
  socialAccountId: Scalars['Int']['output'];
};

export type InfluencerMultipleInstagramDataForReconnectPayload = {
  __typename?: 'InfluencerMultipleInstagramDataForReconnectPayload';
  analyticsAccountId: Scalars['Int']['output'];
};

export type InfluencerNewProfileDetail = {
  __typename?: 'InfluencerNewProfileDetail';
  followers: InfluencerNewProfileFollowers;
  /** last updated datetime in UTC */
  lastUpdatedDatetime: Scalars['DateTime']['output'];
  popularPosts: Array<InfluencerPopularPost>;
  postsHabit: Array<InfluencerNewProfilePostsHabit>;
  postsStats: InfluencerNewProfilePostsStats;
  sentimentAnalysis?: Maybe<InfluencerNewProfileSentimentAnalysis>;
};

export type InfluencerNewProfileEngagement = {
  __typename?: 'InfluencerNewProfileEngagement';
  averageEngagement?: Maybe<Scalars['Float']['output']>;
  engagementRate?: Maybe<Scalars['Float']['output']>;
};

export type InfluencerNewProfileFollowers = {
  __typename?: 'InfluencerNewProfileFollowers';
  ageGenderRates?: Maybe<FollowersAgeGenderGroup>;
  countryRates?: Maybe<Array<FollowersCountry>>;
  femaleRate?: Maybe<Scalars['Float']['output']>;
  followersGrowth?: Maybe<Array<FollowersByTime>>;
  maleRate?: Maybe<Scalars['Float']['output']>;
};

export type InfluencerNewProfileHeaderV2 = {
  __typename?: 'InfluencerNewProfileHeaderV2';
  age?: Maybe<Scalars['Int']['output']>;
  avatar?: Maybe<Scalars['String']['output']>;
  categories: Array<CategoryName>;
  country: CountryNameForInfluencer;
  cover?: Maybe<Scalars['String']['output']>;
  defaultSelectionReason?: Maybe<Scalars['String']['output']>;
  douyinAccounts?: Maybe<Array<SocialAccountForProfileV2>>;
  emailNewsLetters?: Maybe<Array<PromotionMethodEmailNewsLetterForProfile>>;
  engagement: InfluencerNewProfileEngagement;
  facebookAccount?: Maybe<SocialAccountForProfileV2>;
  facebookPages?: Maybe<Array<FacebookPageForProfileV2>>;
  followersCount?: Maybe<Scalars['Int']['output']>;
  gender: Genders;
  hideSensitiveMetrics: Scalars['Boolean']['output'];
  id: Scalars['Int']['output'];
  instagramAccounts?: Maybe<Array<InstagramAccountForProfileV2>>;
  internalMemo?: Maybe<Scalars['String']['output']>;
  introduce?: Maybe<Scalars['String']['output']>;
  isBrandAccount: Scalars['Boolean']['output'];
  isTaggedToCompany?: Maybe<Scalars['Boolean']['output']>;
  mobileApps?: Maybe<Array<PromotionMethodMobileAppForProfile>>;
  name: Scalars['String']['output'];
  offlines?: Maybe<Array<PromotionMethodOfflineForProfile>>;
  partnerIds?: Maybe<Array<Scalars['Int']['output']>>;
  partnerTalentAgencies: Array<Scalars['String']['output']>;
  podCasts?: Maybe<Array<PromotionMethodPodCastForProfile>>;
  region?: Maybe<RegionName>;
  status: SocialAccountStatus;
  tags: Array<Scalars['String']['output']>;
  talentAgencyIds?: Maybe<Array<Scalars['Int']['output']>>;
  threadsAccounts?: Maybe<Array<SocialAccountForProfileV2>>;
  tiktokAccounts?: Maybe<Array<TiktokAccountForProfileV2>>;
  twitterAccounts?: Maybe<Array<TwitterForProfileV2>>;
  websites?: Maybe<Array<PromotionMethodWebsiteForProfile>>;
  youtubeAccounts?: Maybe<Array<YoutubeAccountForProfileV2>>;
};

export type InfluencerNewProfilePostsHabit = {
  __typename?: 'InfluencerNewProfilePostsHabit';
  averageComments: Scalars['Float']['output'];
  averageLikes: Scalars['Float']['output'];
  averageShares: Scalars['Float']['output'];
  averageViews: Scalars['Float']['output'];
  engagementRate: Scalars['Float']['output'];
  hour: Scalars['Int']['output'];
  weekday: Scalars['Int']['output'];
};

export type InfluencerNewProfilePostsStats = {
  __typename?: 'InfluencerNewProfilePostsStats';
  averageComments?: Maybe<Scalars['Float']['output']>;
  averageEngagement?: Maybe<Scalars['Float']['output']>;
  averageLikes?: Maybe<Scalars['Float']['output']>;
  averageShares?: Maybe<Scalars['Float']['output']>;
  averageViews?: Maybe<Scalars['Float']['output']>;
  engagementRate?: Maybe<Scalars['Float']['output']>;
};

export type InfluencerNewProfileSentimentAnalysis = {
  __typename?: 'InfluencerNewProfileSentimentAnalysis';
  negativeRatio?: Maybe<Scalars['Float']['output']>;
  neutralRatio?: Maybe<Scalars['Float']['output']>;
  positiveRatio?: Maybe<Scalars['Float']['output']>;
};

export type InfluencerOrderBy = {
  field?: InputMaybe<InfluencerListSortField>;
  order?: InputMaybe<ORDER>;
};

export type InfluencerPaymentInformation = {
  __typename?: 'InfluencerPaymentInformation';
  accountName?: Maybe<Scalars['String']['output']>;
  accountNumber?: Maybe<Scalars['String']['output']>;
  address?: Maybe<Scalars['String']['output']>;
  bankId?: Maybe<Scalars['Int']['output']>;
  branchId?: Maybe<Scalars['Int']['output']>;
  /** Bank Branch Name */
  branchName?: Maybe<Scalars['String']['output']>;
  cityId?: Maybe<Scalars['Int']['output']>;
  /** necessary for JP influencer */
  fullName?: Maybe<Scalars['String']['output']>;
  /** IBAN */
  iban?: Maybe<Scalars['String']['output']>;
  paymentEmail?: Maybe<Scalars['String']['output']>;
  postalCode?: Maybe<Scalars['String']['output']>;
  subject?: Maybe<Scalars['String']['output']>;
  /** SWIFT Code */
  swiftCode?: Maybe<Scalars['String']['output']>;
};

export type InfluencerPaymentRequest = {
  __typename?: 'InfluencerPaymentRequest';
  campaigns?: Maybe<Array<CampaignPayment>>;
  /** For JP influencer only */
  consumptionTax?: Maybe<Scalars['Float']['output']>;
  currency: Scalars['String']['output'];
  dueDate: Scalars['Date']['output'];
  fee: Scalars['Float']['output'];
  grossRevenue: Scalars['Float']['output'];
  id: Scalars['Int']['output'];
  netRevenue: Scalars['Float']['output'];
  paidDate?: Maybe<Scalars['Date']['output']>;
  requestedDate: Scalars['Date']['output'];
  status: PaymentRequestStatusForInfluencer;
  /** For JP influencer only */
  withholdingFee?: Maybe<Scalars['Float']['output']>;
};

export type InfluencerPaymentRequests = {
  __typename?: 'InfluencerPaymentRequests';
  currency: Scalars['String']['output'];
  paymentRequests: Array<InfluencerPaymentRequest>;
  totalNetRevenue: Scalars['Float']['output'];
};

export type InfluencerPopularPost = {
  __typename?: 'InfluencerPopularPost';
  comments: Scalars['Int']['output'];
  content?: Maybe<Scalars['String']['output']>;
  /** SocialPost ID */
  id: Scalars['Int']['output'];
  likes: Scalars['Int']['output'];
  shares?: Maybe<Scalars['Int']['output']>;
  socialMedia: SocialAccountType;
  thumbNail?: Maybe<Scalars['String']['output']>;
  views?: Maybe<Scalars['Int']['output']>;
};

export type InfluencerPostDetail = {
  __typename?: 'InfluencerPostDetail';
  avatar?: Maybe<Scalars['String']['output']>;
  caption: Scalars['String']['output'];
  comments?: Maybe<Scalars['Int']['output']>;
  commentsContent?: Maybe<Array<CommentContent>>;
  engagement?: Maybe<Scalars['Int']['output']>;
  followersCount?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  influencerId: Scalars['Int']['output'];
  influencerName: Scalars['String']['output'];
  likes?: Maybe<Scalars['Int']['output']>;
  postDate?: Maybe<Scalars['DateTime']['output']>;
  postUrl?: Maybe<Scalars['String']['output']>;
  reactions?: Maybe<Scalars['Int']['output']>;
  sentimentNegativeRatio?: Maybe<Scalars['Float']['output']>;
  sentimentPositiveRatio?: Maybe<Scalars['Float']['output']>;
  shares?: Maybe<Scalars['Int']['output']>;
  similarPosts?: Maybe<Array<SimilarPost>>;
  socialMedia: SocialPostType;
  thumbNail?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  views?: Maybe<Scalars['Int']['output']>;
};

export type InfluencerPostReportStatsEngagement = {
  __typename?: 'InfluencerPostReportStatsEngagement';
  /** campaign status using for AnyX */
  anyXStatus: AnyXEngagementCampaignStatus;
  click?: Maybe<Scalars['Int']['output']>;
  comment: Scalars['Int']['output'];
  conversion?: Maybe<Scalars['Int']['output']>;
  /** post ID */
  id: Scalars['Int']['output'];
  like: Scalars['Int']['output'];
  postCaption: Scalars['String']['output'];
  postSocialMedia: CampaignSocialMediaType;
  postUrl: Scalars['String']['output'];
  share: Scalars['Int']['output'];
  status: EngagementCampaignPostStatus;
  thumbNail?: Maybe<Scalars['String']['output']>;
  view: Scalars['Int']['output'];
};

export type InfluencerPostReportStatsMarketplace = {
  __typename?: 'InfluencerPostReportStatsMarketplace';
  /** campaign status using for AnyX */
  anyXStatus?: Maybe<AnyXMarketplaceCampaignStatus>;
  click?: Maybe<Scalars['Int']['output']>;
  comment: Scalars['Int']['output'];
  conversion?: Maybe<Scalars['Int']['output']>;
  /** post ID */
  id: Scalars['Int']['output'];
  /** represents reason details */
  inappropriateDetailedReason?: Maybe<Scalars['String']['output']>;
  /** represents reason title */
  inappropriateReason?: Maybe<Scalars['String']['output']>;
  like: Scalars['Int']['output'];
  missingCoupon?: Maybe<Scalars['String']['output']>;
  postCaption: Scalars['String']['output'];
  postSocialMedia: CampaignSocialMediaType;
  postUrl: Scalars['String']['output'];
  share: Scalars['Int']['output'];
  status: MarketplaceCampaignPostStatus;
  thumbNail?: Maybe<Scalars['String']['output']>;
  view: Scalars['Int']['output'];
};

export type InfluencerPostSection = {
  __typename?: 'InfluencerPostSection';
  accountInterests: Array<InfluencerInterest>;
  averageComments: Scalars['Float']['output'];
  averageLikes?: Maybe<Scalars['Float']['output']>;
  averagePostsPerWeek: Scalars['Float']['output'];
  averageReactions?: Maybe<Scalars['Float']['output']>;
  averageSaved?: Maybe<Scalars['Float']['output']>;
  averageShares?: Maybe<Scalars['Float']['output']>;
  averageViews?: Maybe<Scalars['Float']['output']>;
  engagementRate: Scalars['Float']['output'];
  estimateReach?: Maybe<Scalars['Float']['output']>;
  feedPosts: Array<InfluencerFeedPost>;
  postsHabit: Array<InfluencerNewProfilePostsHabit>;
  /** available for Instagram */
  reelPosts?: Maybe<Array<InfluencerReelPost>>;
  sentimentNegativeRatio?: Maybe<Scalars['Float']['output']>;
  sentimentNeutralRatio?: Maybe<Scalars['Float']['output']>;
  sentimentPositiveRatio?: Maybe<Scalars['Float']['output']>;
};

export type InfluencerPostV3 = {
  __typename?: 'InfluencerPostV3';
  age?: Maybe<Scalars['Int']['output']>;
  avatar?: Maybe<Scalars['String']['output']>;
  content?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  engagementRate?: Maybe<Scalars['Float']['output']>;
  followers?: Maybe<Scalars['Int']['output']>;
  gender: Genders;
  influencerId: Scalars['Int']['output'];
  isBrandAccount: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  postDate: Scalars['Date']['output'];
  postId?: Maybe<Scalars['Int']['output']>;
  postUrl: Scalars['String']['output'];
  socialAccountId?: Maybe<Scalars['Int']['output']>;
  socialAccountStatus?: Maybe<SocialAccountStatus>;
  socialMedia: SocialAccountType;
  thumbNail?: Maybe<Scalars['String']['output']>;
  views?: Maybe<Scalars['Int']['output']>;
};

export type InfluencerPostsV3 = {
  __typename?: 'InfluencerPostsV3';
  posts: Array<InfluencerPostV3>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type InfluencerProfileDetailForEngagementProposal = {
  __typename?: 'InfluencerProfileDetailForEngagementProposal';
  followers: InfluencerNewProfileFollowers;
  /** last updated datetime in UTC */
  lastUpdatedDatetime: Scalars['DateTime']['output'];
  popularPosts: Array<InfluencerPopularPost>;
  postsHabit: Array<InfluencerNewProfilePostsHabit>;
  postsStats: InfluencerNewProfilePostsStats;
  sentimentAnalysis?: Maybe<InfluencerNewProfileSentimentAnalysis>;
};

export type InfluencerProfileForEngagementProposal = {
  __typename?: 'InfluencerProfileForEngagementProposal';
  age?: Maybe<Scalars['Int']['output']>;
  avatar?: Maybe<Scalars['String']['output']>;
  categories: Array<CategoryName>;
  country: CountryNameForInfluencer;
  cover?: Maybe<Scalars['String']['output']>;
  defaultSelectionReason?: Maybe<Scalars['String']['output']>;
  douyinAccounts?: Maybe<Array<SocialAccountForProfileV2>>;
  emailNewsLetters?: Maybe<Array<PromotionMethodEmailNewsLetterForProfile>>;
  engagement: InfluencerNewProfileEngagement;
  facebookAccount?: Maybe<SocialAccountForProfileV2>;
  facebookPages?: Maybe<Array<FacebookPageForProfileV2>>;
  followersCount?: Maybe<Scalars['Int']['output']>;
  gender: Genders;
  hideSensitiveMetrics: Scalars['Boolean']['output'];
  id: Scalars['Int']['output'];
  instagramAccounts?: Maybe<Array<InstagramAccountForProfileV2>>;
  internalMemo?: Maybe<Scalars['String']['output']>;
  introduce?: Maybe<Scalars['String']['output']>;
  isBrandAccount: Scalars['Boolean']['output'];
  isTaggedToCompany?: Maybe<Scalars['Boolean']['output']>;
  mobileApps?: Maybe<Array<PromotionMethodMobileAppForProfile>>;
  name: Scalars['String']['output'];
  offlines?: Maybe<Array<PromotionMethodOfflineForProfile>>;
  partnerIds?: Maybe<Array<Scalars['Int']['output']>>;
  partnerTalentAgencies: Array<Scalars['String']['output']>;
  podCasts?: Maybe<Array<PromotionMethodPodCastForProfile>>;
  region?: Maybe<RegionName>;
  status: SocialAccountStatus;
  tags: Array<Scalars['String']['output']>;
  talentAgencyIds?: Maybe<Array<Scalars['Int']['output']>>;
  threadsAccounts?: Maybe<Array<SocialAccountForProfileV2>>;
  tiktokAccounts?: Maybe<Array<TiktokAccountForProfileV2>>;
  twitterAccounts?: Maybe<Array<TwitterForProfileV2>>;
  websites?: Maybe<Array<PromotionMethodWebsiteForProfile>>;
  youtubeAccounts?: Maybe<Array<YoutubeAccountForProfileV2>>;
};

export type InfluencerPromotionMethodsPayload = {
  __typename?: 'InfluencerPromotionMethodsPayload';
  emailNewsLetters: Array<PromotionMethodEmailNewsLetterPayload>;
  mobileApps: Array<PromotionMethodMobileAppPayload>;
  offlines: Array<PromotionMethodOfflinePayload>;
  podCasts: Array<PromotionMethodPodCastPayload>;
  websites: Array<PromotionMethodWebsitePayload>;
};

export type InfluencerRateCardData = {
  __typename?: 'InfluencerRateCardData';
  price: Scalars['Float']['output'];
};

export type InfluencerRateCardDetailData = {
  __typename?: 'InfluencerRateCardDetailData';
  description: Scalars['String']['output'];
  price: Scalars['Float']['output'];
};

export type InfluencerRateCardInput = {
  influencerId: Scalars['Int']['input'];
};

export type InfluencerRateCardPayload = {
  __typename?: 'InfluencerRateCardPayload';
  currencyId: Scalars['String']['output'];
  douyin: Array<InfluencerRateCardDetailData>;
  facebook?: Maybe<InfluencerRateCardData>;
  instagram?: Maybe<InfluencerRateCardData>;
  instagramStory?: Maybe<InfluencerRateCardData>;
  threads?: Maybe<InfluencerRateCardData>;
  tiktok?: Maybe<InfluencerRateCardData>;
  twitter?: Maybe<InfluencerRateCardData>;
  youtube?: Maybe<InfluencerRateCardData>;
};

export type InfluencerRateCardPriceInput = {
  price: Scalars['Float']['input'];
};

export type InfluencerRateCardsInput = {
  facebook?: InputMaybe<InfluencerRateCardPriceInput>;
  instagram?: InputMaybe<InfluencerRateCardPriceInput>;
  instagramStory?: InputMaybe<InfluencerRateCardPriceInput>;
  threads?: InputMaybe<InfluencerRateCardPriceInput>;
  tiktok?: InputMaybe<InfluencerRateCardPriceInput>;
  twitter?: InputMaybe<InfluencerRateCardPriceInput>;
  youtube?: InputMaybe<InfluencerRateCardPriceInput>;
};

export type InfluencerReelPost = {
  __typename?: 'InfluencerReelPost';
  caption?: Maybe<Scalars['String']['output']>;
  comments: Scalars['Int']['output'];
  /** SocialPost ID */
  id: Scalars['Int']['output'];
  likes: Scalars['Int']['output'];
  socialMedia: SocialPostType;
  thumbNail?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export enum InfluencerSearchKeywordField {
  BIO = 'BIO',
  EMAIL = 'EMAIL',
  NAME = 'NAME',
  PROFILE = 'PROFILE',
  SOCIAL_USERNAME = 'SOCIAL_USERNAME',
  TAG = 'TAG',
}

export type InfluencerSearchOrderBy = {
  field?: InputMaybe<InfluencerSearchSortField>;
  order?: InputMaybe<Order>;
};

export enum InfluencerSearchSortField {
  CAMPAIGNS_JOINED = 'CAMPAIGNS_JOINED',
  DATE = 'DATE',
  ENGAGEMENT = 'ENGAGEMENT',
  ENGAGEMENT_RATE = 'ENGAGEMENT_RATE',
  FOLLOWERS = 'FOLLOWERS',
}

export type InfluencerSignUpInput = {
  categoryIds: Array<Scalars['Int']['input']>;
  countryId: Scalars['String']['input'];
  dateOfBirth: Scalars['String']['input'];
  email: Scalars['String']['input'];
  gender: Genders;
  name: Scalars['String']['input'];
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  regionId: Scalars['Int']['input'];
};

export type InfluencerSignUpInputPayload = {
  __typename?: 'InfluencerSignUpInputPayload';
  ok: Scalars['Boolean']['output'];
};

export type InfluencerSocialAuthReconnectInstagramAccountAnalyticsInput = {
  analyticsAccountId: Scalars['Int']['input'];
  callbackUrl: Scalars['String']['input'];
  response: Scalars['String']['input'];
};

export type InfluencerSocialAuthReconnectInstagramAccountAnalyticsPayload = {
  __typename?: 'InfluencerSocialAuthReconnectInstagramAccountAnalyticsPayload';
  ok: Scalars['Boolean']['output'];
};

export type InfluencerSocialNetworkAccountInput = {
  username: Scalars['String']['input'];
};

export type InfluencerSocialNetworkAccountOutput = {
  __typename?: 'InfluencerSocialNetworkAccountOutput';
  socialAccountId: Scalars['Int']['output'];
  /** Social Account Status */
  status: SocialAccountStatus;
  username: Scalars['String']['output'];
};

export type InfluencerSocialPost = {
  __typename?: 'InfluencerSocialPost';
  caption?: Maybe<Scalars['String']['output']>;
  comments?: Maybe<Scalars['Int']['output']>;
  date: Scalars['Date']['output'];
  /** SocialPost ID */
  id: Scalars['Int']['output'];
  impressions?: Maybe<Scalars['Int']['output']>;
  likes?: Maybe<Scalars['Int']['output']>;
  postUrl?: Maybe<Scalars['String']['output']>;
  reach?: Maybe<Scalars['Int']['output']>;
  saved?: Maybe<Scalars['Int']['output']>;
  shares?: Maybe<Scalars['Int']['output']>;
  thumbNail?: Maybe<Scalars['String']['output']>;
  views?: Maybe<Scalars['Int']['output']>;
};

export type InfluencerSponsoredPost = {
  __typename?: 'InfluencerSponsoredPost';
  campaignType?: Maybe<CampaignType>;
  comments: Scalars['Int']['output'];
  content?: Maybe<Scalars['String']['output']>;
  /** Post ID */
  id: Scalars['Int']['output'];
  likes: Scalars['Int']['output'];
  postUrl: Scalars['String']['output'];
  postedDate: Scalars['Date']['output'];
  reaches?: Maybe<Scalars['Int']['output']>;
  saved?: Maybe<Scalars['Int']['output']>;
  shares?: Maybe<Scalars['Int']['output']>;
  socialMedia: SocialPostType;
  thumbNail?: Maybe<Scalars['String']['output']>;
  views?: Maybe<Scalars['Int']['output']>;
};

export type InfluencerSponsoredPostCountResponse = {
  __typename?: 'InfluencerSponsoredPostCountResponse';
  total: Scalars['Int']['output'];
};

export type InfluencerSponsoredPostResponse = {
  __typename?: 'InfluencerSponsoredPostResponse';
  averageEngagement?: Maybe<Scalars['Float']['output']>;
  averageEngagementRate?: Maybe<Scalars['Float']['output']>;
  averageReach?: Maybe<Scalars['Float']['output']>;
  averageSaved?: Maybe<Scalars['Float']['output']>;
  posts: Array<InfluencerSponsoredPost>;
};

export enum InfluencerStatusForSearch {
  SCRAPING = 'SCRAPING',
  SIGNED_UP = 'SIGNED_UP',
  SIGNED_UP_EMAIL = 'SIGNED_UP_EMAIL',
}

export type InfluencerTwitterAnalyticsAccessTokenWarningPayload = {
  __typename?: 'InfluencerTwitterAnalyticsAccessTokenWarningPayload';
  needReconnect: Scalars['Boolean']['output'];
};

/** An enumeration. */
export enum InfluencerType {
  NORMAL = 'NORMAL',
  PRO = 'PRO',
}

export type InfluencerUpdateDetailV2 = {
  __typename?: 'InfluencerUpdateDetailV2';
  campaignDisplay: Scalars['Boolean']['output'];
  categories: Array<CategoryName>;
  country: CountryNameForInfluencer;
  dateOfBirth?: Maybe<Scalars['Date']['output']>;
  defaultEngagementSelectionReason?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  facebookAccount?: Maybe<InfluencerSocialNetworkAccountOutput>;
  facebookPages?: Maybe<Array<InfluencerSocialNetworkAccountOutput>>;
  gender: Genders;
  id: Scalars['Int']['output'];
  instagramAccounts?: Maybe<Array<InfluencerSocialNetworkAccountOutput>>;
  internalMemo?: Maybe<Scalars['String']['output']>;
  introduce?: Maybe<Scalars['String']['output']>;
  isBrandAccount: Scalars['Boolean']['output'];
  isProAccount: Scalars['Boolean']['output'];
  managerIds?: Maybe<Array<Scalars['Int']['output']>>;
  name: Scalars['String']['output'];
  notificationSetting: Scalars['Boolean']['output'];
  partners?: Maybe<Array<Partner>>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  region?: Maybe<RegionName>;
  status: SocialAccountStatus;
  tags: Array<Scalars['String']['output']>;
  talentAgencies?: Maybe<Array<TalentAgency>>;
  threadsAccounts?: Maybe<Array<InfluencerSocialNetworkAccountOutput>>;
  tiktokAccounts?: Maybe<Array<InfluencerSocialNetworkAccountOutput>>;
  twitterAccounts?: Maybe<Array<InfluencerSocialNetworkAccountOutput>>;
  youtubeAccounts?: Maybe<Array<InfluencerSocialNetworkAccountOutput>>;
};

export type InfluencerV4 = {
  __typename?: 'InfluencerV4';
  age?: Maybe<Scalars['Int']['output']>;
  avatar: Scalars['String']['output'];
  country: CountryName;
  email?: Maybe<Scalars['Boolean']['output']>;
  engagement: InfluencerEngagement;
  facebook?: Maybe<SocialAccountV2>;
  followersCount?: Maybe<Scalars['Int']['output']>;
  gender: Genders;
  id: Scalars['Int']['output'];
  instagram?: Maybe<SocialAccountV2>;
  isBrandAccount: Scalars['Boolean']['output'];
  isTaggedToCompany?: Maybe<Scalars['Boolean']['output']>;
  line?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  socialAccounts: Array<SocialAccountDescription>;
  status: SocialAccountStatus;
  tags: Array<Scalars['String']['output']>;
  threads?: Maybe<SocialAccountV2>;
  tiktok?: Maybe<SocialAccountV2>;
  twitter?: Maybe<SocialAccountV2>;
  youtube?: Maybe<SocialAccountV2>;
};

export type InfluencerWhitelist = {
  __typename?: 'InfluencerWhitelist';
  /**
   *
   *         If `is_whitelist` is true, this is a whitelist,
   *         which means a list of influencers who can join the marketplace campaign.
   *         If `is_whitelist` is false, this is a blacklist.
   *
   */
  influencerIds: Array<Scalars['Int']['output']>;
  isWhitelist: Scalars['Boolean']['output'];
};

export type InfluencerWordCloud = {
  __typename?: 'InfluencerWordCloud';
  count: Scalars['Int']['output'];
  hashtag: Scalars['String']['output'];
};

export type InfluencerYoutubeAnalyticsAccountCard = {
  __typename?: 'InfluencerYoutubeAnalyticsAccountCard';
  avatarUrl: Scalars['String']['output'];
  channelName: Scalars['String']['output'];
  elementId: Scalars['Int']['output'];
  profileUrl: Scalars['String']['output'];
  socialAccountId: Scalars['Int']['output'];
};

export type InfluencerYoutubeAnalyticsAccountPosts = {
  __typename?: 'InfluencerYoutubeAnalyticsAccountPosts';
  postHabit: Array<YoutubeAnalyticsPostsPostingHabit>;
  posts: Array<YoutubeCompareAccountPost>;
  socialAccountId: Scalars['Int']['output'];
};

export type InfluencerYoutubeAnalyticsAccountPostsInDate = {
  __typename?: 'InfluencerYoutubeAnalyticsAccountPostsInDate';
  posts: Array<YoutubeAnalyticsPostPayload>;
  socialAccountId: Scalars['Int']['output'];
};

export type InfluencerYoutubeAnalyticsAccountTagRanking = {
  __typename?: 'InfluencerYoutubeAnalyticsAccountTagRanking';
  socialAccountId: Scalars['Int']['output'];
  tagsRanking: Array<YoutubeTagRanking>;
};

export type InfluencerYoutubeAnalyticsChannelDetailsPayload = {
  __typename?: 'InfluencerYoutubeAnalyticsChannelDetailsPayload';
  channelAvatar: Scalars['String']['output'];
  channelId: Scalars['String']['output'];
  channelName: Scalars['String']['output'];
};

export type InfluencerYoutubeAnalyticsCompareAccountCard = {
  __typename?: 'InfluencerYoutubeAnalyticsCompareAccountCard';
  accountId: Scalars['Int']['output'];
  avatarUrl: Scalars['String']['output'];
  channelName: Scalars['String']['output'];
  elementId: Scalars['Int']['output'];
  profileUrl: Scalars['String']['output'];
};

export type InfluencerYoutubeAnalyticsCompareAccountCards = {
  __typename?: 'InfluencerYoutubeAnalyticsCompareAccountCards';
  compareAccounts: Array<InfluencerYoutubeAnalyticsCompareAccountCard>;
  mainAccount?: Maybe<InfluencerYoutubeAnalyticsAccountCard>;
};

export type InfluencerYoutubeCompareAccountOverview = {
  __typename?: 'InfluencerYoutubeCompareAccountOverview';
  graph: YoutubeCompareOverviewHistory;
  socialAccountId: Scalars['Int']['output'];
  summary: YoutubeCompareOverviewSummary;
};

export type InfluencerYoutubeCompareAllPostsInDate = {
  __typename?: 'InfluencerYoutubeCompareAllPostsInDate';
  compareAccounts: Array<YoutubeCompareAccountPostsInDate>;
  mainAccount: InfluencerYoutubeAnalyticsAccountPostsInDate;
};

export type InfluencerYoutubeCompareOverview = {
  __typename?: 'InfluencerYoutubeCompareOverview';
  compareAccounts: Array<YoutubeCompareAccountOverview>;
  mainAccount: InfluencerYoutubeCompareAccountOverview;
};

export type InfluencerYoutubeComparePosts = {
  __typename?: 'InfluencerYoutubeComparePosts';
  compareAccounts: Array<YoutubeCompareAccountPosts>;
  mainAccount: InfluencerYoutubeAnalyticsAccountPosts;
};

export type InfluencerYoutubeCompareTagRanking = {
  __typename?: 'InfluencerYoutubeCompareTagRanking';
  compareAccounts: Array<YoutubeCompareAccountTagRanking>;
  mainAccount: InfluencerYoutubeAnalyticsAccountTagRanking;
};

export type InfluencerYoutubeSponsoredPost = {
  __typename?: 'InfluencerYoutubeSponsoredPost';
  comments: Scalars['Long']['output'];
  content: Scalars['String']['output'];
  duration: Scalars['Long']['output'];
  id: Scalars['Long']['output'];
  likes: Scalars['Long']['output'];
  postUrl: Scalars['String']['output'];
  postedDate: Scalars['DateTime']['output'];
  shares: Scalars['Long']['output'];
  thumbnail?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  views: Scalars['Long']['output'];
};

export type InquiryEvent = ChatEvent & {
  __typename?: 'InquiryEvent';
  id: Scalars['String']['output'];
  inquiry: Scalars['String']['output'];
  timestamp: Scalars['String']['output'];
};

export type InquiryFinishEvent = ChatEvent & {
  __typename?: 'InquiryFinishEvent';
  id: Scalars['String']['output'];
  timestamp: Scalars['String']['output'];
  username?: Maybe<Scalars['String']['output']>;
};

export type InstagramAccountForProfileV2 = {
  __typename?: 'InstagramAccountForProfileV2';
  analyticsEnabled: Scalars['Boolean']['output'];
  avatar?: Maybe<Scalars['String']['output']>;
  followersCount?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  isBusiness: Scalars['Boolean']['output'];
  name?: Maybe<Scalars['String']['output']>;
  profile?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type InstagramAnalyticsAccountInfoHeader = {
  __typename?: 'InstagramAnalyticsAccountInfoHeader';
  accountName: Scalars['String']['output'];
  avatar: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  socialAccountUsername: Scalars['String']['output'];
};

export type InstagramAnalyticsComments = {
  __typename?: 'InstagramAnalyticsComments';
  commentId?: Maybe<Scalars['String']['output']>;
  content?: Maybe<Scalars['String']['output']>;
  created?: Maybe<Scalars['Date']['output']>;
  postUrl?: Maybe<Scalars['String']['output']>;
  username?: Maybe<Scalars['String']['output']>;
};

export type InstagramAnalyticsCompareAccount = {
  __typename?: 'InstagramAnalyticsCompareAccount';
  avatarUrl: Scalars['String']['output'];
  blocked: Scalars['Boolean']['output'];
  elementId: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  profileUrl: Scalars['String']['output'];
  username: Scalars['String']['output'];
};

export type InstagramAnalyticsCompareAccounts = {
  __typename?: 'InstagramAnalyticsCompareAccounts';
  accounts?: Maybe<Array<InstagramAnalyticsCompareAccount>>;
};

export type InstagramAnalyticsComparePostsInDate = {
  __typename?: 'InstagramAnalyticsComparePostsInDate';
  accountId: Scalars['Int']['output'];
  feedPosts?: Maybe<Array<InstagramComparePostDetails>>;
};

export type InstagramAnalyticsGetCommentItemsPayload = {
  __typename?: 'InstagramAnalyticsGetCommentItemsPayload';
  commentId: Scalars['String']['output'];
  content: Scalars['String']['output'];
  created: Scalars['String']['output'];
  postUrl: Scalars['String']['output'];
  username: Scalars['String']['output'];
};

export type InstagramAnalyticsGetCommentsPayload = {
  __typename?: 'InstagramAnalyticsGetCommentsPayload';
  comments: Array<InstagramAnalyticsGetCommentItemsPayload>;
};

export type InstagramAnalyticsHashtag = {
  __typename?: 'InstagramAnalyticsHashtag';
  blocked: Scalars['Boolean']['output'];
  hashTag: Scalars['String']['output'];
  hashTagUrl: Scalars['String']['output'];
  id: Scalars['Int']['output'];
};

export type InstagramAnalyticsHashtags = {
  __typename?: 'InstagramAnalyticsHashtags';
  hashTags?: Maybe<Array<InstagramAnalyticsHashtag>>;
};

export type InstagramAnalyticsHistoryDataPoint = {
  __typename?: 'InstagramAnalyticsHistoryDataPoint';
  count?: Maybe<Scalars['Int']['output']>;
  date?: Maybe<Scalars['Date']['output']>;
};

export type InstagramAnalyticsHistoryEngagementRateDataPoint = {
  __typename?: 'InstagramAnalyticsHistoryEngagementRateDataPoint';
  count?: Maybe<Scalars['Float']['output']>;
  date?: Maybe<Scalars['Date']['output']>;
};

export type InstagramAnalyticsOverviewData = {
  __typename?: 'InstagramAnalyticsOverviewData';
  growth?: Maybe<Scalars['Int']['output']>;
  history?: Maybe<Array<InstagramAnalyticsHistoryDataPoint>>;
  percentage?: Maybe<Scalars['Float']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type InstagramAnalyticsOverviewEngagementRateData = {
  __typename?: 'InstagramAnalyticsOverviewEngagementRateData';
  growth?: Maybe<Scalars['Float']['output']>;
  history?: Maybe<Array<InstagramAnalyticsHistoryEngagementRateDataPoint>>;
  percentage?: Maybe<Scalars['Float']['output']>;
  total?: Maybe<Scalars['Float']['output']>;
};

export type InstagramAnalyticsPostAnalysisDetails = {
  __typename?: 'InstagramAnalyticsPostAnalysisDetails';
  comments: Scalars['Int']['output'];
  impressions: Scalars['Int']['output'];
  likes: Scalars['Int']['output'];
  reach: Scalars['Int']['output'];
  saves: Scalars['Int']['output'];
  views: Scalars['Int']['output'];
};

export type InstagramAnalyticsPostCommentDetails = {
  __typename?: 'InstagramAnalyticsPostCommentDetails';
  content: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  username?: Maybe<Scalars['String']['output']>;
};

export type InstagramAnalyticsPostDetails = {
  __typename?: 'InstagramAnalyticsPostDetails';
  analysis?: Maybe<InstagramAnalyticsPostAnalysisDetails>;
  avatarUrl: Scalars['String']['output'];
  commentList?: Maybe<Array<InstagramAnalyticsPostCommentDetails>>;
  content: Scalars['String']['output'];
  followers?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  instagramAccountName: Scalars['String']['output'];
  mediaUrls?: Maybe<Array<Scalars['String']['output']>>;
  postDate: Scalars['Date']['output'];
  postUrl: Scalars['String']['output'];
};

export type InstagramAnalyticsPostSentimentDetails = {
  __typename?: 'InstagramAnalyticsPostSentimentDetails';
  negativeRate: Scalars['Float']['output'];
  positiveRate: Scalars['Float']['output'];
};

export type InstagramAnalyticsPostsInDate = {
  __typename?: 'InstagramAnalyticsPostsInDate';
  feedPosts?: Maybe<Array<InstagramAnalyticsPostDetails>>;
  reelPosts?: Maybe<Array<InstagramAnalyticsReelDetails>>;
  storyPosts?: Maybe<Array<InstagramAnalyticsStoryDetails>>;
};

export type InstagramAnalyticsReelAnalysisDetails = {
  __typename?: 'InstagramAnalyticsReelAnalysisDetails';
  comments: Scalars['Int']['output'];
  likes: Scalars['Int']['output'];
  reach: Scalars['Int']['output'];
  saves: Scalars['Int']['output'];
  shares: Scalars['Int']['output'];
  views: Scalars['Int']['output'];
};

export type InstagramAnalyticsReelCommentDetails = {
  __typename?: 'InstagramAnalyticsReelCommentDetails';
  content: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  username?: Maybe<Scalars['String']['output']>;
};

export type InstagramAnalyticsReelDetails = {
  __typename?: 'InstagramAnalyticsReelDetails';
  analysis?: Maybe<InstagramAnalyticsReelAnalysisDetails>;
  avatarUrl: Scalars['String']['output'];
  commentList?: Maybe<Array<InstagramAnalyticsReelCommentDetails>>;
  content: Scalars['String']['output'];
  followers?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  instagramAccountName: Scalars['String']['output'];
  postDate: Scalars['Date']['output'];
  postUrl: Scalars['String']['output'];
  thumbnail?: Maybe<Scalars['String']['output']>;
};

export type InstagramAnalyticsStoryDetails = {
  __typename?: 'InstagramAnalyticsStoryDetails';
  avatarUrl: Scalars['String']['output'];
  comments: Scalars['Int']['output'];
  content: Scalars['String']['output'];
  exits: Scalars['Int']['output'];
  followers?: Maybe<Scalars['Int']['output']>;
  /** Instagram analytics story id */
  id: Scalars['Int']['output'];
  impressions: Scalars['Int']['output'];
  instagramAccountName: Scalars['String']['output'];
  postDate: Scalars['Date']['output'];
  postImageUrl?: Maybe<Scalars['String']['output']>;
  postUrl: Scalars['String']['output'];
  reach: Scalars['Int']['output'];
  storyVideoUrl?: Maybe<Scalars['String']['output']>;
  tapsBack: Scalars['Int']['output'];
  tapsForward: Scalars['Int']['output'];
};

export type InstagramAnalyticsSummaryPostsInDate = {
  __typename?: 'InstagramAnalyticsSummaryPostsInDate';
  compareAccounts: Array<InstagramAnalyticsComparePostsInDate>;
  mainAccount: InstagramAnalyticsPostsInDate;
};

export type InstagramBrandAmbassadorPost = {
  __typename?: 'InstagramBrandAmbassadorPost';
  comments: Scalars['Int']['output'];
  likes: Scalars['Int']['output'];
  postId: Scalars['String']['output'];
  postUrl: Scalars['String']['output'];
  thumbNail?: Maybe<Scalars['String']['output']>;
};

export type InstagramBrandAmbassadorPostDetails = {
  __typename?: 'InstagramBrandAmbassadorPostDetails';
  comments: Scalars['Int']['output'];
  content: Scalars['String']['output'];
  likes: Scalars['Int']['output'];
  postId: Scalars['String']['output'];
  postUrl: Scalars['String']['output'];
  thumbNail?: Maybe<Scalars['String']['output']>;
  username: Scalars['String']['output'];
};

export type InstagramBrandAmbassadorPosts = {
  __typename?: 'InstagramBrandAmbassadorPosts';
  ambassadorPosts: Array<InstagramBrandAmbassadorPostsByUsername>;
};

export type InstagramBrandAmbassadorPostsByUsername = {
  __typename?: 'InstagramBrandAmbassadorPostsByUsername';
  brandMentionedPosts: Scalars['Int']['output'];
  igUsername: Scalars['String']['output'];
  posts: Array<InstagramBrandAmbassadorPost>;
};

export type InstagramCommentAnalytics = {
  __typename?: 'InstagramCommentAnalytics';
  negativeRatio?: Maybe<Scalars['Float']['output']>;
  neutralRatio?: Maybe<Scalars['Float']['output']>;
  positiveRatio?: Maybe<Scalars['Float']['output']>;
};

export type InstagramCompareAccount = {
  __typename?: 'InstagramCompareAccount';
  avatar: Scalars['String']['output'];
  blocked: Scalars['Boolean']['output'];
  id: Scalars['Int']['output'];
  profileUrl: Scalars['String']['output'];
  username: Scalars['String']['output'];
};

export type InstagramCompareAccountPost = {
  __typename?: 'InstagramCompareAccountPost';
  comments?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  likes?: Maybe<Scalars['Int']['output']>;
  thumbNail?: Maybe<Scalars['String']['output']>;
};

export type InstagramCompareAccountsPayload = {
  __typename?: 'InstagramCompareAccountsPayload';
  compareAccounts: Array<InstagramCompareAccount>;
  mainAccount: InstagramCompareAccount;
};

export type InstagramCompareFeedPost = {
  __typename?: 'InstagramCompareFeedPost';
  comments?: Maybe<Scalars['Int']['output']>;
  content?: Maybe<Scalars['String']['output']>;
  engagement?: Maybe<Scalars['Int']['output']>;
  engagementRate?: Maybe<Scalars['Float']['output']>;
  id: Scalars['Int']['output'];
  likes?: Maybe<Scalars['Int']['output']>;
  postDate?: Maybe<Scalars['DateTime']['output']>;
  postUrl: Scalars['String']['output'];
  thumbNail?: Maybe<Scalars['String']['output']>;
};

export type InstagramCompareFollowers = {
  __typename?: 'InstagramCompareFollowers';
  compareAccounts: Array<InstagramCompareFollowersSecondaryAccount>;
  /** primary compare account */
  mainAccount: InstagramCompareFollowersPrimaryAccount;
};

export type InstagramCompareFollowersPrimaryAccount = {
  __typename?: 'InstagramCompareFollowersPrimaryAccount';
  followerAgeRate: InstagramFollowersAgeRate;
  followerGenderRate: InstagramFollowerGenderRate;
  /** represents Instagram account system id */
  id: Scalars['Int']['output'];
};

export type InstagramCompareFollowersSecondaryAccount = {
  __typename?: 'InstagramCompareFollowersSecondaryAccount';
  followerAgeRate: InstagramFollowersAgeRate;
  followerGenderRate: InstagramFollowerGenderRate;
  /** represents Instagram compare account system id */
  id: Scalars['Int']['output'];
};

export type InstagramCompareOverview = {
  __typename?: 'InstagramCompareOverview';
  /** list of comparable accounts */
  compareAccounts: Array<InstagramCompareSecondaryAccount>;
  /** primary compare account */
  mainAccount: InstagramComparePrimaryAccount;
};

export type InstagramCompareOverviewHistory = {
  __typename?: 'InstagramCompareOverviewHistory';
  comments: Array<InstagramAnalyticsHistoryDataPoint>;
  engagement: Array<InstagramAnalyticsHistoryDataPoint>;
  followers: Array<InstagramAnalyticsHistoryDataPoint>;
  likes: Array<InstagramAnalyticsHistoryDataPoint>;
  posts: Array<InstagramAnalyticsHistoryDataPoint>;
};

export type InstagramCompareOverviewSummary = {
  __typename?: 'InstagramCompareOverviewSummary';
  averageComments: InstagramCompareOverviewSummaryAverageData;
  averageLikes: InstagramCompareOverviewSummaryAverageData;
  maximumLikes: InstagramCompareOverviewSummaryData;
  totalFollowers: InstagramCompareOverviewSummaryData;
  totalPosts: InstagramCompareOverviewSummaryData;
};

export type InstagramCompareOverviewSummaryAverageData = {
  __typename?: 'InstagramCompareOverviewSummaryAverageData';
  growth?: Maybe<Scalars['Float']['output']>;
  percentage?: Maybe<Scalars['Float']['output']>;
  total?: Maybe<Scalars['Float']['output']>;
};

export type InstagramCompareOverviewSummaryData = {
  __typename?: 'InstagramCompareOverviewSummaryData';
  growth?: Maybe<Scalars['Int']['output']>;
  percentage?: Maybe<Scalars['Float']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type InstagramComparePostAnalysisDetails = {
  __typename?: 'InstagramComparePostAnalysisDetails';
  comments: Scalars['Int']['output'];
  likes: Scalars['Int']['output'];
};

export type InstagramComparePostDetails = {
  __typename?: 'InstagramComparePostDetails';
  analysis?: Maybe<InstagramComparePostAnalysisDetails>;
  avatarUrl?: Maybe<Scalars['String']['output']>;
  commentList?: Maybe<Array<InstagramAnalyticsPostCommentDetails>>;
  content: Scalars['String']['output'];
  followers?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  instagramAccountName?: Maybe<Scalars['String']['output']>;
  postDate: Scalars['Date']['output'];
  postImageUrl?: Maybe<Scalars['String']['output']>;
  postUrl: Scalars['String']['output'];
};

export type InstagramComparePosts = {
  __typename?: 'InstagramComparePosts';
  compareAccounts: Array<InstagramComparePostsData>;
  mainAccount: InstagramComparePostsData;
};

export type InstagramComparePostsData = {
  __typename?: 'InstagramComparePostsData';
  feedPosts?: Maybe<Array<InstagramCompareAccountPost>>;
  id: Scalars['Int']['output'];
  popularHashtags?: Maybe<Array<InstagramPopularHashtags>>;
  postsHabit?: Maybe<Array<InstagramPostsHabit>>;
  sentiment?: Maybe<InstagramCommentAnalytics>;
};

export type InstagramComparePrimaryAccount = {
  __typename?: 'InstagramComparePrimaryAccount';
  graph: InstagramCompareOverviewHistory;
  /** represents Instagram account system id */
  id: Scalars['Int']['output'];
  summary: InstagramCompareOverviewSummary;
};

export type InstagramCompareSecondaryAccount = {
  __typename?: 'InstagramCompareSecondaryAccount';
  graph: InstagramCompareOverviewHistory;
  /** represents Instagram compare account system id */
  id: Scalars['Int']['output'];
  summary: InstagramCompareOverviewSummary;
};

export type InstagramDashboardFollowerAnalytics = {
  __typename?: 'InstagramDashboardFollowerAnalytics';
  followerAgeRate: InstagramFollowersAgeRate;
  followerCountryRate?: Maybe<Array<InstagramFollowersCountryRate>>;
  followerGenderRate: InstagramFollowerGenderRate;
  followerRegionRate?: Maybe<Array<InstagramFollowersRegionRate>>;
};

export type InstagramDashboardOverview = {
  __typename?: 'InstagramDashboardOverview';
  comments: InstagramAnalyticsOverviewData;
  engagement: InstagramAnalyticsOverviewData;
  engagementRate: InstagramAnalyticsOverviewEngagementRateData;
  followers: InstagramAnalyticsOverviewData;
  /** impressions of posts */
  impressions: InstagramAnalyticsOverviewData;
  lastUpdated?: Maybe<Scalars['DateTime']['output']>;
  likes: InstagramAnalyticsOverviewData;
  posts: InstagramAnalyticsOverviewData;
  profileViews: InstagramAnalyticsOverviewData;
  reach: InstagramAnalyticsOverviewData;
  saved: InstagramAnalyticsOverviewData;
  websiteClicks: InstagramAnalyticsOverviewData;
};

export type InstagramDashboardPostAnalytics = {
  __typename?: 'InstagramDashboardPostAnalytics';
  averageEngagement?: Maybe<InstagramPostAverageEngagement>;
  commentAnalytics?: Maybe<InstagramCommentAnalytics>;
  comments?: Maybe<Array<InstagramAnalyticsComments>>;
  feedPosts?: Maybe<Array<InstagramFeedPost>>;
  postsHabit?: Maybe<Array<InstagramPostsHabit>>;
  reelsPosts?: Maybe<Array<InstagramReelPost>>;
  storyPosts?: Maybe<Array<InstagramStoryPost>>;
};

export type InstagramDataForReconnectPayload = {
  __typename?: 'InstagramDataForReconnectPayload';
  accountId: Scalars['Int']['output'];
  businessAccountId: Scalars['String']['output'];
  fbPageId: Scalars['String']['output'];
  username: Scalars['String']['output'];
};

export type InstagramFeedPost = {
  __typename?: 'InstagramFeedPost';
  comments?: Maybe<Scalars['Int']['output']>;
  content?: Maybe<Scalars['String']['output']>;
  engagement?: Maybe<Scalars['Int']['output']>;
  engagementRate?: Maybe<Scalars['Float']['output']>;
  follow?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  impressionFromDiscovery?: Maybe<Scalars['Int']['output']>;
  impressionFromHashtag?: Maybe<Scalars['Int']['output']>;
  impressions?: Maybe<Scalars['Int']['output']>;
  interaction?: Maybe<Scalars['Int']['output']>;
  likes?: Maybe<Scalars['Int']['output']>;
  nonFollowerReach?: Maybe<Scalars['Int']['output']>;
  postDate?: Maybe<Scalars['DateTime']['output']>;
  postUrl: Scalars['String']['output'];
  productButtonClick?: Maybe<Scalars['Int']['output']>;
  productPageView?: Maybe<Scalars['Int']['output']>;
  reach?: Maybe<Scalars['Int']['output']>;
  saved?: Maybe<Scalars['Int']['output']>;
  share?: Maybe<Scalars['Int']['output']>;
  thumbNail?: Maybe<Scalars['String']['output']>;
  views?: Maybe<Scalars['Int']['output']>;
};

export type InstagramFeedPostOrderBy = {
  field?: InputMaybe<InstagramFeedPostSortField>;
  order?: InputMaybe<ORDER>;
};

/** An enumeration. */
export enum InstagramFeedPostSortField {
  COMMENT = 'COMMENT',
  ENGAGEMENT = 'ENGAGEMENT',
  ENGAGEMENT_RATE = 'ENGAGEMENT_RATE',
  FOLLOW = 'FOLLOW',
  IMPRESSIONS = 'IMPRESSIONS',
  IMPRESSION_FROM_DISCOVERY = 'IMPRESSION_FROM_DISCOVERY',
  IMPRESSION_FROM_HASHTAG = 'IMPRESSION_FROM_HASHTAG',
  INTERACTION = 'INTERACTION',
  LIKE = 'LIKE',
  NON_FOLLOWER_REACH = 'NON_FOLLOWER_REACH',
  POST_DATE = 'POST_DATE',
  PRODUCT_BUTTON_CLICK = 'PRODUCT_BUTTON_CLICK',
  PRODUCT_PAGE_VIEW = 'PRODUCT_PAGE_VIEW',
  REACH = 'REACH',
  SAVED = 'SAVED',
  SHARE = 'SHARE',
  VIEW = 'VIEW',
}

export type InstagramFollowerGenderRate = {
  __typename?: 'InstagramFollowerGenderRate';
  femaleRate?: Maybe<Scalars['Float']['output']>;
  maleRate?: Maybe<Scalars['Float']['output']>;
  otherRate?: Maybe<Scalars['Float']['output']>;
};

export type InstagramFollowersAgeGroup = {
  __typename?: 'InstagramFollowersAgeGroup';
  end: Scalars['Int']['output'];
  start: Scalars['Int']['output'];
};

export type InstagramFollowersAgeRate = {
  __typename?: 'InstagramFollowersAgeRate';
  ageGroup?: Maybe<Array<InstagramFollowersAgeGroup>>;
  femaleRate?: Maybe<Array<Scalars['Float']['output']>>;
  maleRate?: Maybe<Array<Scalars['Float']['output']>>;
  otherRate?: Maybe<Array<Scalars['Float']['output']>>;
};

export type InstagramFollowersCountryRate = {
  __typename?: 'InstagramFollowersCountryRate';
  /** Country ID */
  id: Scalars['String']['output'];
  rate: Scalars['Float']['output'];
};

export type InstagramFollowersRegionRate = {
  __typename?: 'InstagramFollowersRegionRate';
  name: Scalars['String']['output'];
  rate: Scalars['Float']['output'];
};

export type InstagramHashtagFeedPost = {
  __typename?: 'InstagramHashtagFeedPost';
  comments?: Maybe<Scalars['Int']['output']>;
  content?: Maybe<Scalars['String']['output']>;
  engagement?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  likes?: Maybe<Scalars['Int']['output']>;
  postDate?: Maybe<Scalars['DateTime']['output']>;
  postUrl: Scalars['String']['output'];
  thumbNail?: Maybe<Scalars['String']['output']>;
};

export type InstagramHashtagFeedPostV2 = {
  __typename?: 'InstagramHashtagFeedPostV2';
  comments: Scalars['Int']['output'];
  content?: Maybe<Scalars['String']['output']>;
  engagement: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  likes: Scalars['Int']['output'];
  postDate: Scalars['Date']['output'];
  postUrl: Scalars['String']['output'];
  thumbnail: Scalars['String']['output'];
};

export type InstagramHashtagFeedPostsPayload = {
  __typename?: 'InstagramHashtagFeedPostsPayload';
  count: Scalars['Int']['output'];
  posts: Array<InstagramHashtagFeedPostV2>;
};

export type InstagramHashtagPostAnalysisDetails = {
  __typename?: 'InstagramHashtagPostAnalysisDetails';
  comments: Scalars['Int']['output'];
  likes: Scalars['Int']['output'];
};

export type InstagramHashtagPostDetails = {
  __typename?: 'InstagramHashtagPostDetails';
  analysis?: Maybe<InstagramHashtagPostAnalysisDetails>;
  avatarUrl?: Maybe<Scalars['String']['output']>;
  commentList?: Maybe<Array<InstagramAnalyticsPostCommentDetails>>;
  content: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  instagramAccountName?: Maybe<Scalars['String']['output']>;
  postDate: Scalars['Date']['output'];
  postImageUrl?: Maybe<Scalars['String']['output']>;
  postUrl: Scalars['String']['output'];
};

export type InstagramHashtagPosts = {
  __typename?: 'InstagramHashtagPosts';
  hashtags: Array<InstagramHashtagPostsData>;
};

export type InstagramHashtagPostsData = {
  __typename?: 'InstagramHashtagPostsData';
  feedPosts?: Maybe<Array<InstagramHashtagFeedPost>>;
  id: Scalars['Int']['output'];
};

export type InstagramHashtagPostsPayload = {
  __typename?: 'InstagramHashtagPostsPayload';
  hashtags: Array<Hashtag>;
  postsByHashtag: Array<PostByHashtag>;
};

export type InstagramHashtagsMetricsData = {
  __typename?: 'InstagramHashtagsMetricsData';
  comments: Array<InstagramAnalyticsHistoryDataPoint>;
  /** Hashtag system id */
  id: Scalars['Int']['output'];
  likes: Array<InstagramAnalyticsHistoryDataPoint>;
  posts: Array<InstagramAnalyticsHistoryDataPoint>;
};

export type InstagramHashtagsOverview = {
  __typename?: 'InstagramHashtagsOverview';
  graph: InstagramHashtagsOverviewHistory;
  summaries: Array<InstagramHashtagsOverviewSummary>;
};

export type InstagramHashtagsOverviewHistory = {
  __typename?: 'InstagramHashtagsOverviewHistory';
  /** Historical followers data of Analytics account */
  followers: Array<InstagramAnalyticsHistoryDataPoint>;
  hashtags: Array<InstagramHashtagsMetricsData>;
};

export type InstagramHashtagsOverviewSummary = {
  __typename?: 'InstagramHashtagsOverviewSummary';
  comments: InstagramHashtagsOverviewSummaryData;
  hashtag: InstagramAnalyticsHashtag;
  likes: InstagramHashtagsOverviewSummaryData;
  posts: InstagramHashtagsOverviewSummaryData;
};

export type InstagramHashtagsOverviewSummaryData = {
  __typename?: 'InstagramHashtagsOverviewSummaryData';
  growth?: Maybe<Scalars['Int']['output']>;
  percentage?: Maybe<Scalars['Float']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type InstagramInfluencerSearchResultV3 = {
  __typename?: 'InstagramInfluencerSearchResultV3';
  instagramAccounts: Array<InstagramInfluencerV3>;
  totalNumber: Scalars['Int']['output'];
};

export type InstagramInfluencerV3 = {
  __typename?: 'InstagramInfluencerV3';
  age?: Maybe<Scalars['Int']['output']>;
  analyticsWarning?: Maybe<Scalars['Boolean']['output']>;
  avatar?: Maybe<Scalars['String']['output']>;
  averageLikes?: Maybe<Scalars['Float']['output']>;
  averageReach?: Maybe<Scalars['Float']['output']>;
  averageViews?: Maybe<Scalars['Float']['output']>;
  country: CountryName;
  gender: Genders;
  id: Scalars['ID']['output'];
  isBrandAccount: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  popularPosts?: Maybe<Array<SocialAccountPopularPost>>;
  socialAccountEngagement?: Maybe<Scalars['Int']['output']>;
  socialAccountEngagementRate?: Maybe<Scalars['Float']['output']>;
  socialAccountFollowers?: Maybe<Scalars['Int']['output']>;
  socialAccountId: Scalars['ID']['output'];
  socialAccountStatus: SocialAccountStatus;
};

export type InstagramInteractionCommonPostData = {
  __typename?: 'InstagramInteractionCommonPostData';
  comments?: Maybe<Scalars['Int']['output']>;
  content?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  likes?: Maybe<Scalars['Int']['output']>;
  mediaUrl?: Maybe<Scalars['String']['output']>;
  postDate: Scalars['Date']['output'];
  postUrl: Scalars['String']['output'];
};

export type InstagramInteractionCommonTaggedPostData = {
  __typename?: 'InstagramInteractionCommonTaggedPostData';
  comments?: Maybe<Scalars['Int']['output']>;
  content?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  likes?: Maybe<Scalars['Int']['output']>;
  postDate: Scalars['Date']['output'];
  postUrl: Scalars['String']['output'];
  thumbNail?: Maybe<Scalars['String']['output']>;
};

export type InstagramInteractionOverview = {
  __typename?: 'InstagramInteractionOverview';
  graph: InstagramInteractionOverviewHistory;
  summaries: InstagramInteractionOverviewSummary;
};

export type InstagramInteractionOverviewHistory = {
  __typename?: 'InstagramInteractionOverviewHistory';
  /** Historical followers data of Analytics account */
  followers: Array<InstagramAnalyticsHistoryDataPoint>;
  mentionedComments: Array<InstagramAnalyticsHistoryDataPoint>;
  mentionedLikes: Array<InstagramAnalyticsHistoryDataPoint>;
  mentionedPosts: Array<InstagramAnalyticsHistoryDataPoint>;
  taggedComments: Array<InstagramAnalyticsHistoryDataPoint>;
  taggedLikes: Array<InstagramAnalyticsHistoryDataPoint>;
  taggedPosts: Array<InstagramAnalyticsHistoryDataPoint>;
};

export type InstagramInteractionOverviewSummary = {
  __typename?: 'InstagramInteractionOverviewSummary';
  mentionComments: InstagramInteractionOverviewSummaryData;
  mentionLikes: InstagramInteractionOverviewSummaryData;
  mentionPosts: InstagramInteractionOverviewSummaryData;
  taggedComments: InstagramInteractionOverviewSummaryData;
  taggedLikes: InstagramInteractionOverviewSummaryData;
  taggedPosts: InstagramInteractionOverviewSummaryData;
};

export type InstagramInteractionOverviewSummaryData = {
  __typename?: 'InstagramInteractionOverviewSummaryData';
  growth?: Maybe<Scalars['Int']['output']>;
  percentage?: Maybe<Scalars['Float']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type InstagramInteractionPost = {
  __typename?: 'InstagramInteractionPost';
  comments?: Maybe<Scalars['Int']['output']>;
  content?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  likes?: Maybe<Scalars['Int']['output']>;
  mediaUrl?: Maybe<Scalars['String']['output']>;
  postDate?: Maybe<Scalars['DateTime']['output']>;
  postUrl: Scalars['String']['output'];
};

export type InstagramInteractionPostDetails = {
  __typename?: 'InstagramInteractionPostDetails';
  avatarUrl?: Maybe<Scalars['String']['output']>;
  commentList?: Maybe<Array<InstagramAnalyticsPostCommentDetails>>;
  comments: Scalars['Int']['output'];
  content: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  instagramAccountName?: Maybe<Scalars['String']['output']>;
  likes: Scalars['Int']['output'];
  postDate: Scalars['Date']['output'];
  postImageUrl?: Maybe<Scalars['String']['output']>;
  postUrl: Scalars['String']['output'];
};

export type InstagramInteractionPosts = {
  __typename?: 'InstagramInteractionPosts';
  mentionedPosts: Array<InstagramInteractionCommonPostData>;
  taggedPosts: Array<InstagramInteractionCommonTaggedPostData>;
  userAnalytics: Array<InstagramInteractionUserAnalytics>;
};

export type InstagramInteractionTaggedPost = {
  __typename?: 'InstagramInteractionTaggedPost';
  comments?: Maybe<Scalars['Int']['output']>;
  content?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  likes?: Maybe<Scalars['Int']['output']>;
  postDate?: Maybe<Scalars['DateTime']['output']>;
  postUrl: Scalars['String']['output'];
  thumbNail?: Maybe<Scalars['String']['output']>;
};

export type InstagramInteractionUserAnalytics = {
  __typename?: 'InstagramInteractionUserAnalytics';
  comments?: Maybe<Scalars['Int']['output']>;
  likes?: Maybe<Scalars['Int']['output']>;
  postsCount?: Maybe<Scalars['Int']['output']>;
  username: Scalars['String']['output'];
};

export type InstagramInteractionUserAnalyticsDetails = {
  __typename?: 'InstagramInteractionUserAnalyticsDetails';
  /** average total comments for Mentioned only */
  avgMentionedComments: Scalars['Float']['output'];
  /** average total likes for Mentioned only */
  avgMentionedLikes: Scalars['Float']['output'];
  /** average total comments for Tagged only */
  avgTaggedComments: Scalars['Float']['output'];
  /** average total likes for Tagged only */
  avgTaggedLikes: Scalars['Float']['output'];
  /** average total comments for Tagged OR Mentioned */
  avgTotalComments: Scalars['Float']['output'];
  /** average total likes for Tagged OR Mentioned */
  avgTotalLikes: Scalars['Float']['output'];
  /** total posts count for Mentioned only */
  mentionedPosts: Scalars['Int']['output'];
  /** total posts count for Tagged only */
  taggedPosts: Scalars['Int']['output'];
  /** total posts count for Tagged OR Mentioned */
  totalPostsCount: Scalars['Int']['output'];
  username: Scalars['String']['output'];
};

export type InstagramInteractionUserAnalyticsDetailsAndCount = {
  __typename?: 'InstagramInteractionUserAnalyticsDetailsAndCount';
  totalNumber: Scalars['Int']['output'];
  usernames?: Maybe<Array<InstagramInteractionUserAnalyticsDetails>>;
};

export type InstagramInteractionUserOrderBy = {
  field?: InputMaybe<InstagramInteractionUserSortField>;
  order?: InputMaybe<ORDER>;
};

/** An enumeration. */
export enum InstagramInteractionUserSortField {
  COMMENT = 'COMMENT',
  LIKE = 'LIKE',
  MENTIONED_COMMENT = 'MENTIONED_COMMENT',
  MENTIONED_LIKE = 'MENTIONED_LIKE',
  MENTIONED_POST = 'MENTIONED_POST',
  POST = 'POST',
  TAGGED_COMMENT = 'TAGGED_COMMENT',
  TAGGED_LIKE = 'TAGGED_LIKE',
  TAGGED_POST = 'TAGGED_POST',
}

export type InstagramPopularHashtags = {
  __typename?: 'InstagramPopularHashtags';
  averageComments?: Maybe<Scalars['Int']['output']>;
  averageEngagement?: Maybe<Scalars['Int']['output']>;
  averageLikes?: Maybe<Scalars['Int']['output']>;
  hashtag?: Maybe<Scalars['String']['output']>;
  usedPosts?: Maybe<Scalars['Int']['output']>;
};

export type InstagramPostAverageEngagement = {
  __typename?: 'InstagramPostAverageEngagement';
  averageComments?: Maybe<Scalars['Float']['output']>;
  averageCommentsGrowth?: Maybe<Scalars['Float']['output']>;
  averageLikes?: Maybe<Scalars['Float']['output']>;
  averageLikesGrowth?: Maybe<Scalars['Float']['output']>;
  averagePostsGrowth?: Maybe<Scalars['Float']['output']>;
  averagePostsPerWeek?: Maybe<Scalars['Float']['output']>;
  averageSaved?: Maybe<Scalars['Float']['output']>;
  averageSavedGrowth?: Maybe<Scalars['Float']['output']>;
};

export type InstagramPostSortInput = {
  field?: InputMaybe<InstagramPostSortOrder>;
  order?: InputMaybe<Order>;
};

export enum InstagramPostSortOrder {
  COMMENTS = 'COMMENTS',
  ENGAGEMENT = 'ENGAGEMENT',
  LIKES = 'LIKES',
  POST_DATE = 'POST_DATE',
}

export type InstagramPostsHabit = {
  __typename?: 'InstagramPostsHabit';
  averageComments: Scalars['Float']['output'];
  averageLikes: Scalars['Float']['output'];
  engagementRate: Scalars['Float']['output'];
  hour: Scalars['Int']['output'];
  weekday: Scalars['Int']['output'];
};

export type InstagramReelPost = {
  __typename?: 'InstagramReelPost';
  comments?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  likes?: Maybe<Scalars['Int']['output']>;
  postUrl: Scalars['String']['output'];
  thumbNail?: Maybe<Scalars['String']['output']>;
};

export type InstagramReelPostItem = {
  __typename?: 'InstagramReelPostItem';
  comments?: Maybe<Scalars['Int']['output']>;
  content?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  likes?: Maybe<Scalars['Int']['output']>;
  postDate?: Maybe<Scalars['DateTime']['output']>;
  postUrl: Scalars['String']['output'];
  reach?: Maybe<Scalars['Int']['output']>;
  saved?: Maybe<Scalars['Int']['output']>;
  shares?: Maybe<Scalars['Int']['output']>;
  thumbNail?: Maybe<Scalars['String']['output']>;
  views?: Maybe<Scalars['Int']['output']>;
};

export type InstagramReelPostOrderBy = {
  field?: InputMaybe<InstagramReelPostSortField>;
  order?: InputMaybe<ORDER>;
};

/** An enumeration. */
export enum InstagramReelPostSortField {
  COMMENT = 'COMMENT',
  LIKES = 'LIKES',
  POST_DATE = 'POST_DATE',
  REACH = 'REACH',
  SAVED = 'SAVED',
  SHARES = 'SHARES',
  VIEWS = 'VIEWS',
}

export type InstagramStoryPost = {
  __typename?: 'InstagramStoryPost';
  comments?: Maybe<Scalars['Int']['output']>;
  content?: Maybe<Scalars['String']['output']>;
  exits?: Maybe<Scalars['Int']['output']>;
  follow?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  impressions?: Maybe<Scalars['Int']['output']>;
  interaction?: Maybe<Scalars['Int']['output']>;
  linkClicks?: Maybe<Scalars['Int']['output']>;
  postDate?: Maybe<Scalars['DateTime']['output']>;
  postUrl: Scalars['String']['output'];
  productButtonClick?: Maybe<Scalars['Int']['output']>;
  productPageView?: Maybe<Scalars['Int']['output']>;
  reach?: Maybe<Scalars['Int']['output']>;
  stickerTaps?: Maybe<Scalars['Int']['output']>;
  swipeForward?: Maybe<Scalars['Int']['output']>;
  tapsBack?: Maybe<Scalars['Int']['output']>;
  tapsForward?: Maybe<Scalars['Int']['output']>;
  thumbNail?: Maybe<Scalars['String']['output']>;
};

export type InstagramStoryPostOrderBy = {
  field?: InputMaybe<InstagramStoryPostSortField>;
  order?: InputMaybe<ORDER>;
};

/** An enumeration. */
export enum InstagramStoryPostSortField {
  COMMENT = 'COMMENT',
  EXITS = 'EXITS',
  FOLLOW = 'FOLLOW',
  IMPRESSIONS = 'IMPRESSIONS',
  INTERACTION = 'INTERACTION',
  LINK_CLICKS = 'LINK_CLICKS',
  POST_DATE = 'POST_DATE',
  PRODUCT_BUTTON_CLICK = 'PRODUCT_BUTTON_CLICK',
  PRODUCT_PAGE_VIEW = 'PRODUCT_PAGE_VIEW',
  REACH = 'REACH',
  STICKER_TAPS = 'STICKER_TAPS',
  SWIPE_FORWARD = 'SWIPE_FORWARD',
  TAPS_BACK = 'TAPS_BACK',
  TAPS_FORWARD = 'TAPS_FORWARD',
}

export type InstagramUGCAddedHashtagPayload = {
  __typename?: 'InstagramUGCAddedHashtagPayload';
  hashtag: Scalars['String']['output'];
  hashtagSystemId: Scalars['Int']['output'];
};

export type InstagramUGCManagementBasicInfoPayload = {
  __typename?: 'InstagramUGCManagementBasicInfoPayload';
  addedHashtags: Array<InstagramUGCAddedHashtagPayload>;
  brandInstagramUserName: Scalars['String']['output'];
};

export type InstagramUGCManagementListPostsCountPayload = {
  __typename?: 'InstagramUGCManagementListPostsCountPayload';
  totalNumber: Scalars['Int']['output'];
};

export type InstagramUGCManagementListPostsItemPayload = {
  __typename?: 'InstagramUGCManagementListPostsItemPayload';
  comments: Scalars['Int']['output'];
  likes: Scalars['Int']['output'];
  postSnsId: Scalars['String']['output'];
  status: UGCPostStatus;
  thumbnail?: Maybe<Scalars['String']['output']>;
};

export type InstagramUGCManagementListPostsPayload = {
  __typename?: 'InstagramUGCManagementListPostsPayload';
  posts: Array<InstagramUGCManagementListPostsItemPayload>;
};

export type InstagramUGCManagementPostDetailsPayload = {
  __typename?: 'InstagramUGCManagementPostDetailsPayload';
  commentingAllowed: Scalars['Boolean']['output'];
  comments: Scalars['Int']['output'];
  content: Scalars['String']['output'];
  likes: Scalars['Int']['output'];
  mediaURLs: Array<Scalars['String']['output']>;
  postDate: Scalars['Date']['output'];
  postSNSId: Scalars['String']['output'];
  postUrl: Scalars['String']['output'];
  showStaffRequest: Scalars['Boolean']['output'];
  staffComment?: Maybe<StaffComment>;
  status: UGCPostStatus;
};

export type InstagramUGCPostsSortInput = {
  field: InstagramUGCPostsSortOrder;
  order: Order;
};

export enum InstagramUGCPostsSortOrder {
  ENGAGEMENT = 'ENGAGEMENT',
  POST_DATE = 'POST_DATE',
}

export type IsInfluencerUUUM = {
  __typename?: 'IsInfluencerUUUM';
  isUuum: Scalars['Boolean']['output'];
};

export type JoinAutoManagedCampaignInput = {
  campaignId: Scalars['ID']['input'];
  methodIds: Array<Scalars['ID']['input']>;
};

export type JoinAutoManagedCampaignPayload = {
  __typename?: 'JoinAutoManagedCampaignPayload';
  id: Scalars['ID']['output'];
  ok: Scalars['Boolean']['output'];
};

export type JoinMarketplaceV2 = {
  __typename?: 'JoinMarketplaceV2';
  ok: Scalars['Boolean']['output'];
};

export type JoinMarketplaceV2Input = {
  campaignId: Scalars['Int']['input'];
  isMobile?: InputMaybe<Scalars['Boolean']['input']>;
  methodIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  socialAccountId?: InputMaybe<Scalars['Int']['input']>;
  socialMedia?: InputMaybe<SocialAccountType>;
};

export type JoinableAccount = {
  __typename?: 'JoinableAccount';
  activeToken: Scalars['Boolean']['output'];
  avatar?: Maybe<Scalars['String']['output']>;
  disabled: Scalars['Boolean']['output'];
  followersCount: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  username: Scalars['String']['output'];
};

export type JoinableFbAccount = {
  __typename?: 'JoinableFbAccount';
  activeToken: Scalars['Boolean']['output'];
  avatar?: Maybe<Scalars['String']['output']>;
  disabled: Scalars['Boolean']['output'];
  followersCount: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  userId: Scalars['String']['output'];
  username: Scalars['String']['output'];
};

export type JoinableInstagramAccount = {
  __typename?: 'JoinableInstagramAccount';
  activeToken: Scalars['Boolean']['output'];
  avatar?: Maybe<Scalars['String']['output']>;
  disabled: Scalars['Boolean']['output'];
  followersCount: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  isBusinessAccount: Scalars['Boolean']['output'];
  username: Scalars['String']['output'];
};

export type JoinablePromotionMethodEmailNewsLetterPayload = {
  __typename?: 'JoinablePromotionMethodEmailNewsLetterPayload';
  description: Scalars['String']['output'];
  email: Scalars['String']['output'];
  id: Scalars['ID']['output'];
};

export type JoinablePromotionMethodMobileAppPayload = {
  __typename?: 'JoinablePromotionMethodMobileAppPayload';
  id: Scalars['ID']['output'];
  mobileAppType: PromotionMobileAppType;
  url: Scalars['String']['output'];
};

export type JoinablePromotionMethodOfflinePayload = {
  __typename?: 'JoinablePromotionMethodOfflinePayload';
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
};

export type JoinablePromotionMethodPodCastPayload = {
  __typename?: 'JoinablePromotionMethodPodCastPayload';
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  url: Scalars['String']['output'];
};

export type JoinablePromotionMethodSocialAccountPayload = {
  __typename?: 'JoinablePromotionMethodSocialAccountPayload';
  avatar: Scalars['String']['output'];
  followersCount: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type JoinablePromotionMethodWebsitePayload = {
  __typename?: 'JoinablePromotionMethodWebsitePayload';
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  url: Scalars['String']['output'];
};

export type JoinedCampaign = {
  __typename?: 'JoinedCampaign';
  id: Scalars['Int']['output'];
  socialAccountId?: Maybe<Scalars['Int']['output']>;
  socialAccountType?: Maybe<SocialAccountType>;
  title: Scalars['String']['output'];
  type: CampaignType;
};

export type JoinedPromotionMethodEmailNewsLetterPayload = {
  __typename?: 'JoinedPromotionMethodEmailNewsLetterPayload';
  description: Scalars['String']['output'];
  email: Scalars['String']['output'];
  id: Scalars['ID']['output'];
};

export type JoinedPromotionMethodMobileAppPayload = {
  __typename?: 'JoinedPromotionMethodMobileAppPayload';
  id: Scalars['ID']['output'];
  mobileAppType: PromotionMobileAppType;
  url: Scalars['String']['output'];
};

export type JoinedPromotionMethodOfflinePayload = {
  __typename?: 'JoinedPromotionMethodOfflinePayload';
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
};

export type JoinedPromotionMethodPodCastPayload = {
  __typename?: 'JoinedPromotionMethodPodCastPayload';
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  url: Scalars['String']['output'];
};

export type JoinedPromotionMethodSocialAccountPayload = {
  __typename?: 'JoinedPromotionMethodSocialAccountPayload';
  avatar: Scalars['String']['output'];
  followersCount: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type JoinedPromotionMethodWebsitePayload = {
  __typename?: 'JoinedPromotionMethodWebsitePayload';
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  url: Scalars['String']['output'];
};

export type LineBroadcast = {
  __typename?: 'LineBroadcast';
  click?: Maybe<Scalars['Int']['output']>;
  clickRate?: Maybe<Scalars['Float']['output']>;
  createdDateTime: Scalars['String']['output'];
  delivered?: Maybe<Scalars['Int']['output']>;
  deliveredDateTime?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  imageUrl: Scalars['String']['output'];
  open?: Maybe<Scalars['Int']['output']>;
  openRate?: Maybe<Scalars['Float']['output']>;
  status: LineBroadcastStatus;
  tags: Array<Scalars['String']['output']>;
  title: Scalars['String']['output'];
};

export type LineBroadcastFloatValue = {
  __typename?: 'LineBroadcastFloatValue';
  count: Scalars['Float']['output'];
  growth?: Maybe<Scalars['Float']['output']>;
  percentage?: Maybe<Scalars['Float']['output']>;
};

export type LineBroadcastIntValue = {
  __typename?: 'LineBroadcastIntValue';
  count: Scalars['Int']['output'];
  growth?: Maybe<Scalars['Int']['output']>;
  percentage?: Maybe<Scalars['Float']['output']>;
};

export enum LineBroadcastStatus {
  DELIVERED = 'DELIVERED',
  DRAFT = 'DRAFT',
  ERROR = 'ERROR',
  SCHEDULED = 'SCHEDULED',
  SENDING = 'SENDING',
}

export type LineChat = {
  __typename?: 'LineChat';
  chatId: Scalars['String']['output'];
  checked: Scalars['Boolean']['output'];
  checkedAt?: Maybe<Scalars['Long']['output']>;
  fanId?: Maybe<Scalars['Long']['output']>;
  lastMessage: Scalars['String']['output'];
  lastMessageAt: Scalars['Long']['output'];
  pic?: Maybe<LineChatPIC>;
  resolved: Scalars['Boolean']['output'];
  userAvatar?: Maybe<Scalars['String']['output']>;
  userEmail?: Maybe<Scalars['String']['output']>;
  userId: Scalars['String']['output'];
  userName: Scalars['String']['output'];
};

export type LineChatPIC = {
  __typename?: 'LineChatPIC';
  id: Scalars['Long']['output'];
  name: Scalars['String']['output'];
};

export type LineContentItemInput = {
  buttonMessage?: InputMaybe<ButtonMessageInput>;
  imageMessage?: InputMaybe<ImageMessageInput>;
  mediaMessage?: InputMaybe<ImageVideoMessageInput>;
  plainTextMessage?: InputMaybe<PlainTextMessageInput>;
  richMessage?: InputMaybe<RichMessageInput>;
  type: LineMessageType;
};

export type LineContentItemPayload = {
  __typename?: 'LineContentItemPayload';
  buttonMessage?: Maybe<ButtonMessagePayload>;
  imageMessage?: Maybe<ImageMessagePayload>;
  mediaMessage?: Maybe<ImageVideoMessagePayload>;
  plainTextMessage?: Maybe<PlainTextMessagePayload>;
  richMessage?: Maybe<RichMessagePayload>;
  type: LineMessageType;
};

export enum LineMessageType {
  BUTTON = 'BUTTON',
  IMAGES = 'IMAGES',
  IMAGE_VIDEO = 'IMAGE_VIDEO',
  PLAIN_TEXT = 'PLAIN_TEXT',
  RICH = 'RICH',
}

export type LinkBioBackground = {
  __typename?: 'LinkBioBackground';
  bgColor?: Maybe<Scalars['String']['output']>;
  media?: Maybe<Scalars['String']['output']>;
};

export type LinkBioPageColor = {
  __typename?: 'LinkBioPageColor';
  buttonColor?: Maybe<Scalars['String']['output']>;
  buttonFontColor?: Maybe<Scalars['String']['output']>;
  fontColor?: Maybe<Scalars['String']['output']>;
};

export type LinkCmsChannelAssets = {
  __typename?: 'LinkCmsChannelAssets';
  ok: Scalars['Boolean']['output'];
};

export type LinkCmsChannelAssetsInput = {
  records: Array<CmsChannelAssetInput>;
};

export type Logo = {
  __typename?: 'Logo';
  logoUrl?: Maybe<Scalars['String']['output']>;
};

export type MadeMarketplaceCampaignPost = {
  __typename?: 'MadeMarketplaceCampaignPost';
  ok: Scalars['Boolean']['output'];
};

export type MadeMarketplaceCampaignPostInput = {
  campaignId: Scalars['Int']['input'];
};

export type MajorCategory = {
  __typename?: 'MajorCategory';
  id: Scalars['Int']['output'];
  minorCategories: Array<MinorCategory>;
  name: Scalars['String']['output'];
};

/**  `MarkReadNotificationByAppInput` input accepts `appType`: a enum type which represents the notification scope of application */
export type MarkReadNotificationByAppInput = {
  appType: AppType;
};

/**  `MarkReadNotificationPayload` payload houses an `ok` boolean to determine the success of the operation. */
export type MarkReadNotificationPayload = {
  __typename?: 'MarkReadNotificationPayload';
  ok: Scalars['Boolean']['output'];
};

export type Marketplace = {
  __typename?: 'Marketplace';
  advertiser: AdvertiserName;
  agencyMarginRate?: Maybe<Scalars['Float']['output']>;
  budget: Scalars['Float']['output'];
  campaignType: MarketplaceCampaignDetailType;
  commissionRate?: Maybe<Scalars['Float']['output']>;
  /** for TUNE Tracking */
  costPerAction?: Maybe<Scalars['Float']['output']>;
  /** for TUNE Tracking */
  costPerClick?: Maybe<Scalars['Float']['output']>;
  costPerComment?: Maybe<Scalars['Float']['output']>;
  costPerFollower?: Maybe<Scalars['Float']['output']>;
  costPerLike?: Maybe<Scalars['Float']['output']>;
  /** for referral code Tracking */
  costPerOrder?: Maybe<Scalars['Float']['output']>;
  costPerPost?: Maybe<Scalars['Float']['output']>;
  /** for referral code Tracking */
  costPerSaleReferral?: Maybe<Scalars['Float']['output']>;
  /** for TUNE Tracking */
  costPerSaleTune?: Maybe<Scalars['Float']['output']>;
  costPerShare?: Maybe<Scalars['Float']['output']>;
  costPerView?: Maybe<Scalars['Float']['output']>;
  country: CountryName;
  currency: Scalars['String']['output'];
  currentCost?: Maybe<Scalars['Int']['output']>;
  endDate?: Maybe<Scalars['Date']['output']>;
  id: Scalars['Int']['output'];
  influencerManagementPics?: Maybe<Array<PicName>>;
  isPostTracking: Scalars['Boolean']['output'];
  isSelectionCampaign: Scalars['Boolean']['output'];
  marginRate: Scalars['Float']['output'];
  salesPics?: Maybe<Array<PicName>>;
  socialMedia: CampaignSocialMediaType;
  startDate: Scalars['Date']['output'];
  status: MarketplaceCampaignStatus;
  title: Scalars['String']['output'];
};

export type MarketplaceAffiliateCommissionFeeInput = {
  commissionFee: Scalars['Float']['input'];
  influencerId: Scalars['Int']['input'];
};

export type MarketplaceAffiliateCommissionInfluencer = {
  __typename?: 'MarketplaceAffiliateCommissionInfluencer';
  currency: Scalars['String']['output'];
  endCoverageDate: Scalars['Date']['output'];
  fixedDate: Scalars['Date']['output'];
  influencer: MarketplacePostInfluencer;
  postId: Scalars['Int']['output'];
  revenue: Scalars['Float']['output'];
  startCoverageDate: Scalars['Date']['output'];
  status: MarketplaceAffiliateCommissionStatus;
};

export type MarketplaceAffiliateCommissionRateInput = {
  commissionRate: Scalars['Float']['input'];
  influencerId: Scalars['Int']['input'];
};

/** An enumeration. */
export enum MarketplaceAffiliateCommissionStatus {
  APPROVED = 'APPROVED',
  UNAPPROVED = 'UNAPPROVED',
}

export type MarketplaceAffiliateJoinedInfluencer = {
  __typename?: 'MarketplaceAffiliateJoinedInfluencer';
  commissionFee?: Maybe<Scalars['Float']['output']>;
  commissionFeeUpdatedDate?: Maybe<Scalars['Date']['output']>;
  commissionRate?: Maybe<Scalars['Float']['output']>;
  commissionRateUpdatedDate?: Maybe<Scalars['Date']['output']>;
  couponCode?: Maybe<Scalars['String']['output']>;
  influencer: MarketplacePostInfluencer;
  joinedDate: Scalars['Date']['output'];
  promotionMethods: Array<CampaignPromotionMethod>;
};

export type MarketplaceAffiliatePayoutPeriodInput = {
  fixedDate: Scalars['Date']['input'];
  postId: Scalars['Int']['input'];
};

export type MarketplaceAffiliateReportByDate = {
  __typename?: 'MarketplaceAffiliateReportByDate';
  clicks: Scalars['Int']['output'];
  conversions: Scalars['Int']['output'];
  cost: Scalars['Float']['output'];
  cvr?: Maybe<Scalars['Float']['output']>;
  date: Scalars['Date']['output'];
  grossProfit: Scalars['Float']['output'];
  influencerCost: Scalars['Float']['output'];
  sales: Scalars['Float']['output'];
};

export type MarketplaceAffiliateReportByInfluencer = {
  __typename?: 'MarketplaceAffiliateReportByInfluencer';
  clicks: Scalars['Int']['output'];
  commissionRate: Scalars['Float']['output'];
  conversions: Scalars['Int']['output'];
  cost: Scalars['Float']['output'];
  cvr?: Maybe<Scalars['Float']['output']>;
  influencerCost: Scalars['Float']['output'];
  influencerInfo: CampaignInfluencerInfo;
  promotionMethods: Array<CampaignPromotionMethod>;
  sales: Scalars['Float']['output'];
};

/** An enumeration. */
export enum MarketplaceAffiliateReportByInfluencerField {
  CLICK = 'CLICK',
  COST = 'COST',
  CVR = 'CVR',
  INFLUENCER_COMMISSION_RATE = 'INFLUENCER_COMMISSION_RATE',
  INFLUENCER_COST = 'INFLUENCER_COST',
  ORDER = 'ORDER',
  SALE = 'SALE',
}

export type MarketplaceAffiliateReportByInfluencerOrderBy = {
  field?: InputMaybe<MarketplaceAffiliateReportByInfluencerField>;
  order?: InputMaybe<ORDER>;
};

export type MarketplaceAffiliateReportForInfluencer = {
  __typename?: 'MarketplaceAffiliateReportForInfluencer';
  clicks: Scalars['Int']['output'];
  commission: Scalars['Float']['output'];
  conversions: Scalars['Int']['output'];
  date: Scalars['Date']['output'];
};

export type MarketplaceAffiliateReportSummary = {
  __typename?: 'MarketplaceAffiliateReportSummary';
  average: MarketplaceAffiliateStatsItem;
  chartData: Array<AffiliateCampaignChartReport>;
  joinedCount: Scalars['Int']['output'];
  summary: MarketplaceAffiliateStatsItem;
};

export type MarketplaceAffiliateReportSummaryForInfluencer = {
  __typename?: 'MarketplaceAffiliateReportSummaryForInfluencer';
  clicks: Scalars['Int']['output'];
  commission: Scalars['Float']['output'];
  conversions: Scalars['Int']['output'];
  currency: Scalars['String']['output'];
};

export type MarketplaceAffiliateStatsItem = {
  __typename?: 'MarketplaceAffiliateStatsItem';
  clicks: Scalars['Int']['output'];
  conversions: Scalars['Int']['output'];
  cost: Scalars['Float']['output'];
  cvr?: Maybe<Scalars['Float']['output']>;
  grossProfit: Scalars['Float']['output'];
  influencerCost: Scalars['Float']['output'];
  sales: Scalars['Float']['output'];
};

/** An enumeration. */
export enum MarketplaceCampaignAppliedStatus {
  APPLYING = 'APPLYING',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
}

export type MarketplaceCampaignCouponTrackingInput = {
  campaignId: Scalars['Int']['input'];
  couponCode: Scalars['String']['input'];
  saleAmount: Scalars['Float']['input'];
  transactionId: Scalars['String']['input'];
};

export type MarketplaceCampaignCouponTrackingPayload = {
  __typename?: 'MarketplaceCampaignCouponTrackingPayload';
  ok: Scalars['Boolean']['output'];
};

export enum MarketplaceCampaignDetailType {
  E_COMMERCE = 'E_COMMERCE',
  NORMAL = 'NORMAL',
  REFERRAL_CODE = 'REFERRAL_CODE',
  SELECTION = 'SELECTION',
  URL_TRACKING = 'URL_TRACKING',
}

/** An enumeration. */
export enum MarketplaceCampaignDraftPostStatus {
  APPROVED = 'APPROVED',
  DRAFT = 'DRAFT',
  REJECTED = 'REJECTED',
}

/** An enumeration. */
export enum MarketplaceCampaignPostHistoryStatus {
  APPROVED = 'APPROVED',
  CREATED = 'CREATED',
  DRAFT = 'DRAFT',
  DRAFT_REJECTED = 'DRAFT_REJECTED',
  JOINED = 'JOINED',
  READY_TO_POST = 'READY_TO_POST',
  REJECTED = 'REJECTED',
}

export type MarketplaceCampaignPostReportForInfluencer = {
  __typename?: 'MarketplaceCampaignPostReportForInfluencer';
  currency: Scalars['String']['output'];
  /** campaign ID */
  id: Scalars['Int']['output'];
  postReport: InfluencerPostReportStatsMarketplace;
  postReports: Array<InfluencerPostReportStatsMarketplace>;
  /** campaign revenue */
  revenue: Scalars['Float']['output'];
  /** campaign title */
  title: Scalars['String']['output'];
};

/** An enumeration. */
export enum MarketplaceCampaignPostStatus {
  AFFILIATE = 'AFFILIATE',
  APPROVED = 'APPROVED',
  EFFECTIVE = 'EFFECTIVE',
  INEFFECTIVE = 'INEFFECTIVE',
  SHIPPED = 'SHIPPED',
  TRACKING = 'TRACKING',
  WARNING = 'WARNING',
  WARNING_SOLVED = 'WARNING_SOLVED',
}

/** An enumeration. */
export enum MarketplaceCampaignPostStatusForAdvertiser {
  APPROVED = 'APPROVED',
  DRAFT = 'DRAFT',
  DRAFT_REJECTED = 'DRAFT_REJECTED',
  EFFECTIVE = 'EFFECTIVE',
  INEFFECTIVE = 'INEFFECTIVE',
  JOINED = 'JOINED',
  READY_TO_POST = 'READY_TO_POST',
  SHIPPED = 'SHIPPED',
  TRACKING = 'TRACKING',
  WARNING = 'WARNING',
}

/** An enumeration. */
export enum MarketplaceCampaignPostStatusForSearch {
  AD_FRAUD = 'AD_FRAUD',
  API_ERROR = 'API_ERROR',
  APPROVED = 'APPROVED',
  DELETED_POST = 'DELETED_POST',
  DRAFT = 'DRAFT',
  EFFECTIVE = 'EFFECTIVE',
  INEFFECTIVE = 'INEFFECTIVE',
  INVALID_CONTENT = 'INVALID_CONTENT',
  INVALID_TOKEN = 'INVALID_TOKEN',
  JOINED = 'JOINED',
  SCRAPING_ERROR = 'SCRAPING_ERROR',
  TRACKING = 'TRACKING',
  WARNING = 'WARNING',
}

/** An enumeration. */
export enum MarketplaceCampaignRejectedStatus {
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  REVIEWING = 'REVIEWING',
}

export type MarketplaceCampaignReport = {
  __typename?: 'MarketplaceCampaignReport';
  InternalInfo?: Maybe<Array<Scalars['String']['output']>>;
  average?: Maybe<NewCampaignStatsItemRow>;
  campaignInfo: CampaignReportCampaignInfo;
  chartData: Array<CampaignChartReport>;
  currency: Scalars['String']['output'];
  influencerReports: Array<CampaignInfluencerReport>;
  postCount: Scalars['Int']['output'];
  summary: NewCampaignStatsItemRow;
  summaryDaily?: Maybe<Array<CampaignSummaryDaily>>;
};

export enum MarketplaceCampaignStatus {
  FINISHED = 'FINISHED',
  ONGOING = 'ONGOING',
  OUT_OF_COUPON = 'OUT_OF_COUPON',
  REJECTED = 'REJECTED',
  REVIEWING = 'REVIEWING',
  SUSPENDED = 'SUSPENDED',
  UPCOMING = 'UPCOMING',
}

/** An enumeration. */
export enum MarketplaceCampaignType {
  AFFILIATE = 'AFFILIATE',
  AFFILIATE_SPECIAL_DEAL = 'AFFILIATE_SPECIAL_DEAL',
  NORMAL = 'NORMAL',
  SELECTION = 'SELECTION',
}

export type MarketplaceDetail = {
  __typename?: 'MarketplaceDetail';
  advertiser: AdvertiserName;
  agencyMarginRate?: Maybe<Scalars['Float']['output']>;
  allowNewInfluencer?: Maybe<Scalars['Boolean']['output']>;
  brandName?: Maybe<Scalars['String']['output']>;
  budget: Scalars['Float']['output'];
  campaignCategory?: Maybe<CampaignCategoryName>;
  campaignCreator?: Maybe<CampaignCreatorWithName>;
  campaignType: MarketplaceCampaignDetailType;
  campaignUrl?: Maybe<Scalars['String']['output']>;
  categories: Array<CategoryName>;
  clickUrl?: Maybe<Scalars['String']['output']>;
  commissionRate?: Maybe<Scalars['Float']['output']>;
  /** for TUNE Tracking */
  costPerAction?: Maybe<Scalars['Float']['output']>;
  /** for TUNE Tracking */
  costPerClick?: Maybe<Scalars['Float']['output']>;
  costPerComment?: Maybe<Scalars['Float']['output']>;
  costPerFollower?: Maybe<Scalars['Float']['output']>;
  costPerLike?: Maybe<Scalars['Float']['output']>;
  /** for referral code Tracking */
  costPerOrder?: Maybe<Scalars['Float']['output']>;
  costPerPost?: Maybe<Scalars['Float']['output']>;
  /** for referral code Tracking */
  costPerSaleReferral?: Maybe<Scalars['Float']['output']>;
  /** for TUNE Tracking */
  costPerSaleTune?: Maybe<Scalars['Float']['output']>;
  costPerShare?: Maybe<Scalars['Float']['output']>;
  costPerView?: Maybe<Scalars['Float']['output']>;
  country: CountryName;
  couponUploadFiles?: Maybe<Array<Scalars['String']['output']>>;
  currency: Scalars['String']['output'];
  currentCost?: Maybe<Scalars['Int']['output']>;
  endDate?: Maybe<Scalars['Date']['output']>;
  genders: Array<Genders>;
  hashtags: Array<Scalars['String']['output']>;
  hubspotDealIds?: Maybe<Array<Scalars['String']['output']>>;
  id: Scalars['Int']['output'];
  influencerManagementPics?: Maybe<Array<PicName>>;
  isAutoInfluencerApproval?: Maybe<Scalars['Boolean']['output']>;
  isGiftingCampaign: Scalars['Boolean']['output'];
  isPostTracking: Scalars['Boolean']['output'];
  isSelectionCampaign: Scalars['Boolean']['output'];
  landingPageUrl?: Maybe<Scalars['String']['output']>;
  marginRate: Scalars['Float']['output'];
  materialUrl?: Maybe<Scalars['String']['output']>;
  materials?: Maybe<Array<Scalars['String']['output']>>;
  maxAge: Scalars['Int']['output'];
  maxFollowers: Scalars['Int']['output'];
  maximumRevenuePerInfluencer?: Maybe<Scalars['Float']['output']>;
  minAge: Scalars['Int']['output'];
  minFollowers: Scalars['Int']['output'];
  mkpServiceCampaignId?: Maybe<Scalars['Int']['output']>;
  needCouponsCount?: Maybe<Scalars['Int']['output']>;
  preLaunchDate?: Maybe<Scalars['Date']['output']>;
  productThumbNail?: Maybe<Scalars['String']['output']>;
  regions?: Maybe<Array<RegionName>>;
  rejectedInfo?: Maybe<MarketplaceRejectedAdvertiserInfo>;
  requireDraftPost: Scalars['Boolean']['output'];
  requirement: Scalars['String']['output'];
  salesPics?: Maybe<Array<PicName>>;
  sampleUrl?: Maybe<Scalars['String']['output']>;
  serviceInformation: Scalars['String']['output'];
  socialMedia: CampaignSocialMediaType;
  startDate: Scalars['Date']['output'];
  status: MarketplaceCampaignStatus;
  thumbnails: Array<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  trackingOptions?: Maybe<Array<CampaignTrackingOption>>;
};

export type MarketplaceDetailForInfluencer = {
  __typename?: 'MarketplaceDetailForInfluencer';
  ableToJoin?: Maybe<Scalars['Boolean']['output']>;
  /** campaign status using for AnyX */
  anyXStatus?: Maybe<AnyXMarketplaceCampaignStatus>;
  /** exists when campaign type is SELECTION */
  appliedStatus?: Maybe<MarketplaceCampaignAppliedStatus>;
  campaignDetailType: MarketplaceCampaignDetailType;
  /** @deprecated not useful anymore */
  campaignType: MarketplaceCampaignType;
  clickUrl?: Maybe<Scalars['String']['output']>;
  commissionRate?: Maybe<Scalars['Float']['output']>;
  confirmMadePostAvailability?: Maybe<Scalars['Boolean']['output']>;
  couponCode?: Maybe<Scalars['String']['output']>;
  createdDate: Scalars['Date']['output'];
  currency: Scalars['String']['output'];
  endDate?: Maybe<Scalars['Date']['output']>;
  /** Whether she uploaded her post on social media or not. */
  hasReport: Scalars['Boolean']['output'];
  hashtags: Array<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  isApplied: Scalars['Boolean']['output'];
  isAutoInfluencerApproval?: Maybe<Scalars['Boolean']['output']>;
  isCouponCampaign?: Maybe<Scalars['Boolean']['output']>;
  isGiftingCampaign?: Maybe<Scalars['Boolean']['output']>;
  isJoined: Scalars['Boolean']['output'];
  isPostTracking?: Maybe<Scalars['Boolean']['output']>;
  isSelectionCampaign?: Maybe<Scalars['Boolean']['output']>;
  materialUrl?: Maybe<Scalars['String']['output']>;
  materials?: Maybe<Array<MaterialName>>;
  maxFollowers: Scalars['Int']['output'];
  maximumRevenuePerInfluencer?: Maybe<Scalars['Float']['output']>;
  minFollowers: Scalars['Int']['output'];
  minimumPaymentAmount: Scalars['Float']['output'];
  postStatus?: Maybe<CampaignPostStatusForInfluencer>;
  postTrackingUrl?: Maybe<Scalars['String']['output']>;
  postUrl?: Maybe<Scalars['String']['output']>;
  preLaunchDate?: Maybe<Scalars['Date']['output']>;
  remainingAmount: Scalars['Float']['output'];
  requireDraftPost: Scalars['Boolean']['output'];
  requirement: Scalars['String']['output'];
  revenuePerAction?: Maybe<Scalars['Float']['output']>;
  revenuePerClick?: Maybe<Scalars['Float']['output']>;
  revenuePerComment?: Maybe<Scalars['Float']['output']>;
  revenuePerFollower?: Maybe<Scalars['Float']['output']>;
  revenuePerLike?: Maybe<Scalars['Float']['output']>;
  revenuePerOrder?: Maybe<Scalars['Float']['output']>;
  revenuePerPost?: Maybe<Scalars['Float']['output']>;
  revenuePerShare?: Maybe<Scalars['Float']['output']>;
  revenuePerView?: Maybe<Scalars['Float']['output']>;
  sampleUrl?: Maybe<Scalars['String']['output']>;
  serviceInformation: Scalars['String']['output'];
  socialMedias: Array<CampaignSocialMediaType>;
  startDate: Scalars['Date']['output'];
  status: CampaignStatusForInfluencer;
  thumbNail: Scalars['String']['output'];
  thumbnails: Array<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  totalAmount: Scalars['Float']['output'];
  trackingOptions?: Maybe<Array<CampaignTrackingOption>>;
  trackingUrl?: Maybe<Scalars['String']['output']>;
  type: CampaignType;
  warningReason?: Maybe<WarningReason>;
};

export type MarketplaceDraftPostDetail = {
  __typename?: 'MarketplaceDraftPostDetail';
  caption: Scalars['String']['output'];
  createdDate?: Maybe<Scalars['Date']['output']>;
  id: Scalars['Int']['output'];
  images?: Maybe<Array<Scalars['String']['output']>>;
  influencer: MarketplacePostDetailInfluencer;
  rejectedDate?: Maybe<Scalars['Date']['output']>;
  rejectedMessage?: Maybe<Scalars['String']['output']>;
  status: MarketplaceCampaignDraftPostStatus;
};

export type MarketplaceDraftPostForInfluencer = {
  __typename?: 'MarketplaceDraftPostForInfluencer';
  content: Scalars['String']['output'];
  materials: Array<Scalars['String']['output']>;
  rejectedMessage?: Maybe<Scalars['String']['output']>;
  status: MarketplaceCampaignDraftPostStatus;
};

export type MarketplaceForReorder = {
  __typename?: 'MarketplaceForReorder';
  id: Scalars['Int']['output'];
  status: MarketplaceCampaignStatus;
  thumbnail: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type MarketplaceJoinableAccountsPayload = {
  __typename?: 'MarketplaceJoinableAccountsPayload';
  facebookAccount?: Maybe<JoinableFbAccount>;
  facebookPages: Array<JoinableAccount>;
  instagramAccounts: Array<JoinableInstagramAccount>;
  tiktokAccounts: Array<JoinableAccount>;
  twitterAccounts: Array<JoinableAccount>;
  youtubeAccounts: Array<JoinableAccount>;
};

export type MarketplaceJoinablePromotionMethodsPayload = {
  __typename?: 'MarketplaceJoinablePromotionMethodsPayload';
  emailNewsLetters: Array<JoinablePromotionMethodEmailNewsLetterPayload>;
  facebookAccount?: Maybe<JoinablePromotionMethodSocialAccountPayload>;
  facebookPages: Array<JoinablePromotionMethodSocialAccountPayload>;
  instagramAccounts: Array<JoinablePromotionMethodSocialAccountPayload>;
  mobileApps: Array<JoinablePromotionMethodMobileAppPayload>;
  offlines: Array<JoinablePromotionMethodOfflinePayload>;
  podCasts: Array<JoinablePromotionMethodPodCastPayload>;
  tiktokAccounts: Array<JoinablePromotionMethodSocialAccountPayload>;
  twitterAccounts: Array<JoinablePromotionMethodSocialAccountPayload>;
  websites: Array<JoinablePromotionMethodWebsitePayload>;
  youtubeAccounts: Array<JoinablePromotionMethodSocialAccountPayload>;
};

export type MarketplaceJoinedAccountPayload = {
  __typename?: 'MarketplaceJoinedAccountPayload';
  avatar?: Maybe<Scalars['String']['output']>;
  followersCount: Scalars['Int']['output'];
  profileUrl: Scalars['String']['output'];
  socialAccountType: SocialAccountType;
  username: Scalars['String']['output'];
};

export type MarketplaceJoinedMethodsInput = {
  campaignId: Scalars['ID']['input'];
};

export type MarketplaceJoinedMethodsPayload = {
  __typename?: 'MarketplaceJoinedMethodsPayload';
  emailNewsLetters: Array<JoinedPromotionMethodEmailNewsLetterPayload>;
  facebookAccount?: Maybe<JoinedPromotionMethodSocialAccountPayload>;
  facebookPages: Array<JoinedPromotionMethodSocialAccountPayload>;
  instagramAccounts: Array<JoinedPromotionMethodSocialAccountPayload>;
  mobileApps: Array<JoinedPromotionMethodMobileAppPayload>;
  offlines: Array<JoinedPromotionMethodOfflinePayload>;
  podCasts: Array<JoinedPromotionMethodPodCastPayload>;
  tiktokAccounts: Array<JoinedPromotionMethodSocialAccountPayload>;
  twitterAccounts: Array<JoinedPromotionMethodSocialAccountPayload>;
  websites: Array<JoinedPromotionMethodWebsitePayload>;
  youtubeAccounts: Array<JoinedPromotionMethodSocialAccountPayload>;
};

export type MarketplacePost = {
  __typename?: 'MarketplacePost';
  content?: Maybe<Scalars['String']['output']>;
  couponCode?: Maybe<Scalars['String']['output']>;
  draftId?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  influencer: MarketplacePostInfluencer;
  joinedDate: Scalars['Date']['output'];
  needReview: Scalars['Boolean']['output'];
  postUrl?: Maybe<Scalars['String']['output']>;
  postedDate?: Maybe<Scalars['Date']['output']>;
  socialAccountId?: Maybe<Scalars['Int']['output']>;
  socialMedia: CampaignSocialMediaType;
  status?: Maybe<MarketplaceCampaignPostStatusForAdvertiser>;
  thumbNail?: Maybe<Scalars['String']['output']>;
  warningReason?: Maybe<WarningReason>;
};

export type MarketplacePostComment = {
  __typename?: 'MarketplacePostComment';
  commentedDate?: Maybe<Scalars['DateTime']['output']>;
  content?: Maybe<Scalars['String']['output']>;
  username?: Maybe<Scalars['String']['output']>;
};

export type MarketplacePostDetail = {
  __typename?: 'MarketplacePostDetail';
  caption: Scalars['String']['output'];
  comments?: Maybe<Scalars['Int']['output']>;
  detailedReason?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  images?: Maybe<Array<Scalars['String']['output']>>;
  likes?: Maybe<Scalars['Int']['output']>;
  postDetailInfluencer: MarketplacePostDetailInfluencer;
  postUrl?: Maybe<Scalars['String']['output']>;
  postedDate?: Maybe<Scalars['Date']['output']>;
  reach?: Maybe<Scalars['Int']['output']>;
  reason?: Maybe<Scalars['String']['output']>;
  shares?: Maybe<Scalars['Int']['output']>;
  socialMedia: CampaignSocialMediaType;
  status?: Maybe<MarketplaceCampaignPostStatus>;
  views?: Maybe<Scalars['Int']['output']>;
  warningReason?: Maybe<WarningReason>;
};

export type MarketplacePostDetailInfluencer = {
  __typename?: 'MarketplacePostDetailInfluencer';
  avatar: Scalars['String']['output'];
  followersCount: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type MarketplacePostHistory = {
  __typename?: 'MarketplacePostHistory';
  /** exist only status is REJECTED */
  detailedReason?: Maybe<Scalars['String']['output']>;
  /** Draft post Id */
  draftId?: Maybe<Scalars['Int']['output']>;
  draftPostContent?: Maybe<Scalars['String']['output']>;
  draftPostMaterials?: Maybe<Array<Scalars['String']['output']>>;
  id?: Maybe<Scalars['Int']['output']>;
  operatedDate: Scalars['Date']['output'];
  /** exist only statuses are REJECTED and APPROVED */
  operatedUserName?: Maybe<Scalars['String']['output']>;
  /** exist only status is REJECTED */
  reason?: Maybe<Scalars['String']['output']>;
  status: MarketplaceCampaignPostHistoryStatus;
};

export type MarketplacePostInfluencer = {
  __typename?: 'MarketplacePostInfluencer';
  avatar: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type MarketplaceRejectedAdvertiserInfo = {
  __typename?: 'MarketplaceRejectedAdvertiserInfo';
  operatedDate: Scalars['Date']['output'];
  operatedUser: OperatedUserInfo;
  reason?: Maybe<Scalars['String']['output']>;
  status: MarketplaceCampaignRejectedStatus;
};

export type MarketplaceTracking = {
  __typename?: 'MarketplaceTracking';
  /** only for Pixel tracking campaign */
  pixelCode?: Maybe<Scalars['String']['output']>;
  /** only for postback URL campaign */
  postbackUrl?: Maybe<Scalars['String']['output']>;
};

export type MarketplaceTrackingPostUrlForInfluencer = {
  __typename?: 'MarketplaceTrackingPostUrlForInfluencer';
  /** Available only if influencer joined on-going campaign and post is not fetched yet */
  postUrl?: Maybe<Scalars['String']['output']>;
};

export type MaterialName = {
  __typename?: 'MaterialName';
  id: Scalars['Int']['output'];
  url: Scalars['String']['output'];
};

export type MediaItemInput = {
  image?: InputMaybe<ImageInput>;
  type: MediaType;
  video?: InputMaybe<VideoInput>;
};

export type MediaItemPayload = {
  __typename?: 'MediaItemPayload';
  image?: Maybe<ImagePayload>;
  type: MediaType;
  video?: Maybe<VideoPayload>;
};

export enum MediaType {
  IMAGE = 'IMAGE',
  VIDEO = 'VIDEO',
}

export type MessageTemplateEvent = ChatEvent & {
  __typename?: 'MessageTemplateEvent';
  id: Scalars['String']['output'];
  templateId: Scalars['String']['output'];
  templateName: Scalars['String']['output'];
  timestamp: Scalars['String']['output'];
};

export type MessageTemplateItem = {
  __typename?: 'MessageTemplateItem';
  name: Scalars['String']['output'];
  templateId: Scalars['String']['output'];
  text: Scalars['String']['output'];
};

export type MinorCategory = {
  __typename?: 'MinorCategory';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type Mutation = {
  __typename?: 'Mutation';
  addAllSelectedInfluencers?: Maybe<AddAllSelectedInfluencers>;
  addComment?: Maybe<AddCommentPayload>;
  addEmailTemplate?: Maybe<AddEmailTemplatePayload>;
  addFan?: Maybe<AddFanPayload>;
  addFanTags?: Maybe<AddFanTagsPayload>;
  addForm?: Maybe<AddFormPayload>;
  /**
   * Adds an Instagram account to a 'compare' list.
   *         May be used by admin, staff, agency and advertiser users.
   */
  addInstagramCompareAccountAnalytics?: Maybe<AddCompareInstagramAccountAnalytics>;
  /**
   * Adds an Instagram hashtag to a 'hashtag' list.
   *         May be used by admin, staff, agency and advertiser users.
   */
  addInstagramHashtagAnalytics?: Maybe<AddInstagramHashtagAnalytics>;
  /**
   *
   *         Add tracking post url for marketplace campaign on Advertiser platform
   *
   */
  addMarketplacePostUrl?: Maybe<AddMarketplacePostUrl>;
  /**
   *
   *         Add tracking post url for marketplace campaign on Influencer platform
   *
   */
  addMarketplacePostUrlForInfluencer?: Maybe<AddMarketplacePostUrlForInfluencer>;
  addReadTutorial?: Maybe<AddReadTutorial>;
  addSelectedInfluencers?: Maybe<AddSelectedInfluencers>;
  /** Add Staff's Advertisers To Another Staff */
  addStaffAdvertisersToAnotherStaff?: Maybe<AddStaffAdvertisersToAnotherStaff>;
  /**
   *  Adds a Youtube account to a 'compare' list.
   *  May be used by admin, staff, agency and advertiser users.
   */
  addYoutubeCompareAccountAnalytics: AddYoutubeCompareAccountAnalyticsOutput;
  allowEngagementInfluencerProposalList?: Maybe<AllowEngagementInfluencerProposalList>;
  /**
   * Sets a subscription plan for a specific advertiser.
   *         May be used by admin and staff users only.
   */
  analyticsSelectSubscriptionPlan?: Maybe<AnalyticsSelectSubscriptionPlan>;
  /**  validate the email availability */
  anyXCheckEmail?: Maybe<AnyXCheckEmailPayload>;
  /**  Allow creator user to Connect Social account on AnyX Platform */
  anyXSocialAuthConnect?: Maybe<AnyXSocialAuthConnectPayload>;
  /**  Allow creator user to Connect Social account for LinkBio on AnyX Platform */
  anyXSocialAuthConnectForLinkBio?: Maybe<AnyXSocialAuthConnectForLinkBioPayload>;
  /**  Allow creator user to Reconnect Social accounts on LinkBio feature */
  anyXSocialAuthReconnectForLinkBio?: Maybe<AnyXSocialAuthReconnectForLinkBioPayload>;
  /**  Allow creator user to Reconnect TikTok account on AnyX Platform */
  anyXSocialAuthReconnectTikTok?: Maybe<AnyXSocialAuthReconnectTikTokPayload>;
  anyXSocialAuthReconnectTtcm?: Maybe<AnyXSocialAuthReconnectTTCMPayload>;
  /**  Allow creator user to SignIn with Social account on AnyX Platform */
  anyXSocialAuthSignIn?: Maybe<AnyXSocialAuthSignInPayload>;
  /**  Allow creator user to SignIn with Instagram and LineIDToken by LIFF URL */
  anyXSocialAuthSignInInstagramLine?: Maybe<AnyXSocialAuthSignInInstagramLinePayload>;
  /**  To check SignIn or SignUp is enable of logged-in account from social media platform */
  anyXSocialAuthSignInOrSignUp?: Maybe<AnyXSocialAuthSignInOrSignUpPayload>;
  /**  Allow creator user to SignUp with Social account on AnyX Platform */
  anyXSocialAuthSignUp?: Maybe<AnyXSocialAuthSignUpPayload>;
  /**  Allow creator user to SignUp with Social account on AnyX Platform as Partner Influencer account. */
  anyXSocialAuthSignUpPartner?: Maybe<AnyXSocialAuthSignUpPartnerPayload>;
  /**  Allow creator user to SignUp with Social account on AnyX Platform as Talent Influencer account. */
  anyXSocialAuthSignUpTalent?: Maybe<AnyXSocialAuthSignUpTalentPayload>;
  /**
   *
   *         Approve Applicant influencers to Selection Marketplace campaign
   *
   */
  approveApplicantInfluencers?: Maybe<ApproveApplicantInfluencers>;
  approveAutoManagedPayout?: Maybe<ApproveAutoManagedPayout>;
  approveMarketplace?: Maybe<ApproveMarketplace>;
  approveMarketplaceAffiliatePayout?: Maybe<ApproveMarketplaceAffiliatePayout>;
  /** approve a draft post */
  approveMarketplaceDraftPost?: Maybe<ApproveMarketplaceDraftPost>;
  assignAdvertiserToStaffs?: Maybe<AssignAdvertiserForStaffs>;
  assignChatPic?: Maybe<AssignChatPICPayload>;
  /** Generates a local JWT token from a given Auth0 ID token */
  auth0ToLocalJwt?: Maybe<Auth0ToLocalJWT>;
  /** Bulk update influencers budget */
  bulkUpdateEngagementInfluencersBudget?: Maybe<BulkUpdateEngagementInfluencersBudget>;
  /** Bulk update social accounts budget */
  bulkUpdateEngagementSocialAccountsBudget?: Maybe<BulkUpdateEngagementSocialAccountsBudget>;
  cancelSubscriptionPlan?: Maybe<CancelSubscriptionPlan>;
  /**  Changes a UGC post's status */
  changeInstagramUGCManagementPostStatus: ChangeInstagramUGCManagementPostStatusOutput;
  clearCmsChannelAssetOwner?: Maybe<ClearCmsChannelAssetOwner>;
  /**
   *  `clickNotification` mutation sets a notification as clicked.
   *  The user is known through the `Authorization` header.
   *  Click is defined as a user click.
   */
  clickNotification?: Maybe<ClickNotificationPayload>;
  /**
   *  `clickNotificationByEventId` mutation sets a notification as clicked by event_id.
   *  The user is known through the `Authorization` header.
   *  Click is defined as a user click.
   */
  clickNotificationByEventId?: Maybe<ClickNotificationByEventIdPayload>;
  connectFacebookPagesAndInstagramAccounts?: Maybe<ConnectFacebookPagesAndInstagramAccountsPayload>;
  connectNewShopifyAccount?: Maybe<ConnectNewShopifyAccountPayload>;
  connectPackageToEngagementProposal?: Maybe<ConnectPackageToEngagementProposal>;
  connectPackageWithInfluencerAccounts?: Maybe<ConnectPackageWithInfluencerAccounts>;
  connectPackageWithSocialAccounts?: Maybe<ConnectPackageWithSocialAccounts>;
  /**  Allow influencer to connect promotion methods */
  connectPromotionMethods?: Maybe<ConnectPromotionMethodsPayload>;
  createAdminUser?: Maybe<CreateAdmin>;
  createAdvertiser?: Maybe<CreateAdvertiser>;
  /** Create Advertiser User */
  createAdvertiserUser?: Maybe<CreateAdvertiserUser>;
  createAdvertiserWithUser?: Maybe<CreateAdvertiserWithUser>;
  /** create AdvertiserId if selected id not exist, and create advertiser user */
  createAdvertiserWithUserV2?: Maybe<CreateAdvertiserWithUserV2>;
  /** Create Ad Agency User */
  createAgencyUser?: Maybe<CreateAgency>;
  createAgencyWithUser?: Maybe<CreateAgencyWithUser>;
  createCreatorStaff?: Maybe<CreateCreatorStaffPayload>;
  createEngagement?: Maybe<CreateEngagement>;
  createEngagementPostForInfluencerV2?: Maybe<CreateEngagementPostForInfluencerV2>;
  createEngagementPostV2?: Maybe<CreateEngagementPostV2>;
  /**  Influencer bio */
  createInfluencerBio?: Maybe<CreateInfluencerBioPayload>;
  createInfluencerV2?: Maybe<CreateInfluencerV2>;
  /**  Creates hashtag for UGC management */
  createInstagramUGCManagementHashtag: CreateInstagramUGCManagementHashtagOutput;
  createLineBroadcastMessage?: Maybe<CreateLineBroadcastMessagePayload>;
  createLineChannel?: Maybe<CreateLineChannelPayload>;
  createMarketplace?: Maybe<CreateMarketplace>;
  /** Influencer submit a draft post */
  createMarketplaceDraftPost?: Maybe<CreateMarketplaceDraftPost>;
  createPackage?: Maybe<CreatePackage>;
  createPackageWithInfluencerAccounts?: Maybe<CreatePackageWithInfluencerAccounts>;
  createPackageWithSocialAccounts?: Maybe<CreatePackageWithSocialAccounts>;
  /** Create Partner User */
  createPartnerUser?: Maybe<CreatePartnerUser>;
  createPaymentRequest?: Maybe<CreatePaymentRequest>;
  /**
   *
   *         Creates a self registered advertiser and an advertiser user attached to it with no authentication.
   *
   */
  createSelfRegisteredAdvertiserUser?: Maybe<CreateSelfRegisteredAdvertiserUser>;
  /**
   *  Creates an Analytics account with a connected Youtube or Instagram account.
   *  May be used by admin, staff and agency users.
   */
  createSocialAccountAnalyticsAdminV2: CreateSocialAccountAnalyticsAdminOutputV2;
  /**
   *  Creates an Analytics account with a connected Youtube or Instagram account.
   *  May be used by advertiser users only.
   */
  createSocialAccountAnalyticsAdvertiserV2: CreateSocialAccountAnalyticsAdvertiserOutputV2;
  createStaffUser?: Maybe<CreateStaff>;
  /** Create Talent Agency User */
  createTalentAgencyUser?: Maybe<CreateTalentAgencyUser>;
  createTalentAgencyWithUser?: Maybe<CreateTalentAgencyWithUser>;
  /**  Create Talent Influencer by Talent agency user with multiple social accounts (Facebook, Instagram, Youtube, and Twitter) */
  createTalentInfluencer?: Maybe<CreateTalentInfluencerPayload>;
  deleteAdvertiser?: Maybe<DeleteAdvertiser>;
  deleteAgency?: Maybe<DeleteAgency>;
  /**
   *  Delete brand account and relations to facebook, instagram, youtube accounts and managers
   *  May be used by admin, staff
   */
  deleteBrandAccountAnalytics: DeleteBrandAccountAnalyticsOutput;
  deleteComment?: Maybe<DeleteCommentPayload>;
  deleteEngagement?: Maybe<DeleteEngagement>;
  deleteEngagementInfluencerProposal?: Maybe<DeleteEngagementInfluencerProposal>;
  deleteEngagementPostForInfluencer?: Maybe<DeleteEngagementPostForInfluencer>;
  deleteEngagementProposalJoinedAccount?: Maybe<DeleteEngagementProposalJoinedAccount>;
  deleteEngagementSocialAccountProposal?: Maybe<DeleteEngagementSocialAccountProposal>;
  deleteFanTags?: Maybe<DeleteFanTagsPayload>;
  deleteInfluencer?: Maybe<DeleteInfluencer>;
  /**
   * Removes a specified 'compare' account from the 'compare' page.
   *         May be used by admin, staff, agency and advertiser users.
   */
  deleteInstagramCompareAccountAnalytics?: Maybe<DeleteCompareInstagramAccountAnalytics>;
  /**
   * Removes an Instagram hashtag.
   *         May be used by admin, staff, agency and advertiser users.
   */
  deleteInstagramHashtagAnalytics?: Maybe<DeleteInstagramHashtagAnalytics>;
  /**  Deletes hashtag for UGC management */
  deleteInstagramUGCManagementHashtag: DeleteInstagramUGCManagementHashtagOutput;
  deleteMarketplace?: Maybe<DeleteMarketplace>;
  deletePackage?: Maybe<DeletePackage>;
  deleteSocialAccountPackage?: Maybe<DeleteSocialAccountPackage>;
  deleteTalentAgency?: Maybe<DeleteTalentAgency>;
  deleteUser?: Maybe<DeleteUser>;
  /**
   *  Deletes a Youtube account from a 'compare' list
   *  May be used by admin, staff, agency and advertiser users.
   */
  deleteYoutubeCompareAccountAnalytics: DeleteYoutubeCompareAccountAnalyticsOutput;
  denyEngagementInfluencerProposalList?: Maybe<DenyEngagementInfluencerProposalList>;
  /**
   *  Edit an Analytics account with a connected Youtube or Instagram account.
   *  May be used by admin, staff and agency users.
   */
  editSocialAccountAnalyticsAdminV2: EditSocialAccountAnalyticsAdminOutputV2;
  /**
   *  Edit an Analytics account with a connected Youtube or Instagram account.
   *  May be used by advertiser users only.
   */
  editSocialAccountAnalyticsAdvertiserV2: EditSocialAccountAnalyticsAdvertiserOutputV2;
  /** Adds all influencers from one campaign to another */
  engagementAddInfluencersToProposalFromAnotherCampaign?: Maybe<EngagementAddInfluencersToProposalFromAnotherCampaign>;
  /**  Public API to save Engagement Coupon Tracking */
  engagementCampaignCouponTracking?: Maybe<EngagementCampaignCouponTrackingPayload>;
  /** Creates a presentation with the dashboard data and sends it to the user's email address as a file */
  exportInstagramAccountDashboardAnalytics?: Maybe<ExportDashboardInstagramAccountAnalytics>;
  /**
   *  Get the exported google slide/pptx and send to email.
   *  May be used by admin, staff, advertiser, agency, talent agency and partner users
   */
  exportYoutubeDashboardAccountAnalytics: ExportYoutubeDashboardAccountAnalyticsOutput;
  /**
   *  Get the exported google slide/pptx and send to email for an influencer.
   *  May be used by admin, staff, partner, and talent agency users
   */
  exportYoutubeDashboardAccountAnalyticsForInfluencer: ExportYoutubeDashboardAccountAnalyticsOutput;
  /**
   *  Creates a facebook page analutics account.
   *  May be used by external users only.
   */
  externalCreateFacebookPageAccountAnalytics: ExternalCreateFacebookPageAccountAnalyticsOutput;
  /**
   *  Creates an instagram analytics account.
   *  The service is idempotent. You may create the same account multiple times. The returned id is the same in this situation.
   *  May be used by external users only.
   */
  externalCreateInstagramAccountAnalytics: ExternalCreateInstagramAccountAnalyticsOutput;
  /**
   *  Creates an youtube analytics account.
   *  The service is idempotent. You may create the same account multiple times. The returned id is the same in this situation.
   *  May be used by external users only.
   */
  externalCreateYoutubeAccountAnalytics: ExternalCreateYoutubeAccountAnalyticsOutput;
  /**
   *  Returns a list of Facebook page accounts that can be connected based on your Facebook account.
   *  May be used by external users only.
   *  The mutation looks like a query but actually it also saves access tokens in the DB.
   */
  externalSocialAuthConnectableFacebookPageAnalytics?: Maybe<ExternalConnectableFacebookPageAnalyticsPayload>;
  /**
   *  Returns a list of Instagram accounts that can be connected based on your Facebook account.
   *  May be used by external users only.
   *  The mutation looks like a query but actually it also saves access tokens in the DB.
   */
  externalSocialAuthConnectableInstagramAccountsAnalytics?: Maybe<ExternalSocialAuthConnectableInstagramAccountsAnalyticsPayload>;
  /**
   *  Reconnects a Facebook Page analytics account.
   *  May be used by external users only.
   */
  externalSocialAuthReconnectFacebookPageAccountAnalytics?: Maybe<ExternalSocialAuthReconnectFacebookPageAccountAnalyticsPayload>;
  /**
   *  Reconnects an Instagram analytics account.
   *  May be used by external users only.
   */
  externalUserSocialAuthReconnectInstagramAccountAnalytics?: Maybe<ExternalUserSocialAuthReconnectInstagramAccountAnalyticsPayload>;
  fetchAutoManagedCustomShopeeCampaigns?: Maybe<ShopeeCustomCampaignPayload>;
  /** Generates Partner Influencer sign-up url */
  generatePartnerInfluencerSignUpUrl?: Maybe<GeneratePartnerInfluencerSignUpUrl>;
  /** Generates Talent Influencer sign-up url */
  generateTalentInfluencerSignUpUrl?: Maybe<GenerateTalentInfluencerSignUpUrl>;
  importFans?: Maybe<ImportFansPayload>;
  /**  Adds a Youtube account to a 'compare' list. For influencers only. */
  influencerAddYoutubeCompareAccountAnalytics: AddYoutubeCompareAccountAnalyticsOutput;
  /**
   *  Updates twitter account data
   *  May be used by admin, staff, agency, advertisers, and influencers
   */
  influencerAnalyticsTwitterRefresh: InfluencerAnalyticsTwitterRefreshOutput;
  /**  Deletes a Youtube account from a 'compare' list. For influencers only. */
  influencerDeleteYoutubeCompareAccountAnalytics: DeleteYoutubeCompareAccountAnalyticsOutput;
  /**
   * Creates a presentation with the dashboard data and
   *             sends it to the user's email address as a file. For influencers only.
   */
  influencerExportInstagramAccountDashboard?: Maybe<InfluencerExportDashboardInstagramAccount>;
  /**  Allow influencer to sign-up by email */
  influencerSignUp?: Maybe<InfluencerSignUpInputPayload>;
  /**
   *  Reconnects an Instagram analytics account.
   *  May be used for influencers only.
   */
  influencerSocialAuthReconnectInstagramAccountAnalytics?: Maybe<InfluencerSocialAuthReconnectInstagramAccountAnalyticsPayload>;
  joinAutoManagedCampaign?: Maybe<JoinAutoManagedCampaignPayload>;
  joinMarketplaceV2?: Maybe<JoinMarketplaceV2>;
  linkCmsChannelAssets?: Maybe<LinkCmsChannelAssets>;
  /** Influencer made a post */
  madeMarketplaceCampaignPost?: Maybe<MadeMarketplaceCampaignPost>;
  /**
   *  `markReadNotification` mutation marks all notification of a influencer user as read.
   *  The user is known through the `Authorization` header.
   *  Read is defined as the occurence of the notification being rendered by front-end.
   */
  markReadNotification: MarkReadNotificationPayload;
  /**
   *  `markReadNotificationByApp` mutation marks all specific app notification of a influencer user as read.
   *  The user is known through the `Authorization` header.
   *  Read is defined as the occurence of the notification being rendered by front-end.
   */
  markReadNotificationByApp: MarkReadNotificationPayload;
  /**  Public API to save Marketplace Coupon Tracking */
  marketplaceCampaignCouponTracking?: Maybe<MarketplaceCampaignCouponTrackingPayload>;
  publishInfluencerBio?: Maybe<PublishInfluencerBioPayload>;
  reSendBcaRequest?: Maybe<ReSendBcaRequestPayload>;
  refreshPassword?: Maybe<RefreshPasswordPayload>;
  refreshProfileAccount?: Maybe<RefreshProfileAccountPayload>;
  refreshTwitterAccount?: Maybe<RefreshTwitterAccountPayload>;
  registerPushToken?: Maybe<RegisterPushToken>;
  /** Reject engagement draft post (it can be used by admin, staff, agency and advertiser) */
  rejectEngagementDraftPost?: Maybe<RejectEngagementDraftPost>;
  /** Reject Marketplace campaign that have been created by Advertiser */
  rejectMarketplaceCampaign?: Maybe<RejectMarketplaceCampaign>;
  /** reject a draft post */
  rejectMarketplaceDraftPost?: Maybe<RejectMarketplaceDraftPost>;
  removeAllFormResponses?: Maybe<RemoveAllFormResponsesPayload>;
  removeAllSelectedInfluencers?: Maybe<RemoveAllSelectedInfluencers>;
  removeCreatorStaff?: Maybe<RemoveCreatorStaffPayload>;
  removeEmails?: Maybe<RemoveEmailsPayload>;
  removeFans?: Maybe<RemoveFansPayload>;
  removeFormResponse?: Maybe<RemoveFormResponsePayload>;
  removeForms?: Maybe<RemoveFormsPayload>;
  removeSelectedInfluencers?: Maybe<RemoveSelectedInfluencers>;
  removeShopifyAccount?: Maybe<RemoveShopifyAccountPayload>;
  reorderMarketplaceCampaigns?: Maybe<ReorderMarketplaceCampaigns>;
  reportEngagementPostStatus?: Maybe<ReportEngagementPostStatus>;
  reportMarketplacePostStatus?: Maybe<ReportMarketplacePostStatus>;
  requestDeleteAccount?: Maybe<RequestDeleteAccount>;
  requestGoogleSlideCustomisedReport?: Maybe<RequestGoogleSlideCustomisedReport>;
  requestGoogleSlideReport?: Maybe<RequestGoogleSlideReport>;
  requestInfluencerProfilePptx?: Maybe<RequestInfluencerProfilePptx>;
  requestInfluencersProfilePptx?: Maybe<RequestInfluencersProfilePptx>;
  requestInfluencersSocialAccountPptx?: Maybe<RequestInfluencersSocialAccountPptx>;
  /**  Request a UGC post's via comment */
  requestInstagramUGCManagementPost: RequestInstagramUGCManagementPostOutput;
  requestPackageSpreadsheet?: Maybe<RequestPackageSpreadsheet>;
  requestPowerpointCustomisedReport?: Maybe<RequestPowerpointCustomisedReport>;
  requestPowerpointReport?: Maybe<RequestPowerpointReport>;
  requestProposalExcel?: Maybe<RequestProposalExcel>;
  requestProposalPptx?: Maybe<RequestProposalPptx>;
  requestProposalSpreadsheet?: Maybe<RequestProposalSpreadsheet>;
  /** Re-send an email for self-registered Advertiser */
  resendEmailForAdvertiser?: Maybe<ResendEmailForAdvertiser>;
  /**  Resend forgot password's email to influencer which contains generated hash url to let influencer to set new password */
  resendInfluencerForgotPasswordEmail?: Maybe<ResendInfluencerForgotPasswordEmailPayload>;
  /**  Resend sign-up's email to influencer which contains generated hash url to let influencer to set new password */
  resendInfluencerSignUpEmail?: Maybe<ResendInfluencerSignUpEmailPayload>;
  /** temporary internal api */
  resendPartnerEmails?: Maybe<ResendPartnerEmails>;
  resetPassword?: Maybe<ResetPasswordPayload>;
  resetPasswordAndLogin?: Maybe<ResetPasswordAndLoginPayload>;
  resolveChat?: Maybe<ResolveChatPayload>;
  sendBcaRequest?: Maybe<SendBcaRequestPayload>;
  sendContact?: Maybe<SendContact>;
  sendLineBroadcastMessage?: Maybe<SendLineBroadcastMessagePayload>;
  sendMessage?: Maybe<SendMessagePayload>;
  shopifyValidateAuth?: Maybe<ShopifyValidateAuthPayload>;
  /**
   *  Returns a list of Facebook page accounts that can be connected based on your Facebook account.
   *  May be used by admin, staff, agency and advertiser users.
   *  The mutation looks like a query but actually it also saves access tokens in the DB.
   */
  socialAuthConnectableFacebookPageAnalytics?: Maybe<SocialAuthConnectableFacebookPageAnalyticsPayload>;
  /**
   *  Returns a list of Instagram accounts that can be connected based on your Facebook account.
   *  May be used by admin, staff, agency and advertiser users.
   *  The mutation looks like a query but actually it also saves access tokens in the DB.
   */
  socialAuthConnectableInstagramAccountsAnalytics?: Maybe<SocialAuthConnectableInstagramAccountsAnalyticsPayload>;
  /**
   *  Returns a twitter account that can be connected to analytics account.
   *  May be used by admin, staff, agency and advertiser users.
   *  The mutation looks like a query but actually it also saves access tokens in the DB.
   */
  socialAuthConnectableTwitterAccountAnalyticsV2: SocialAuthConnectableTwitterAccountAnalyticsOutput;
  /**
   *  Get selectable youtube analytics account channels and save tokens in DB.
   *  May be used by admin, staff, agency and advertiser users.
   *  The mutation looks like a query but actually it also saves access tokens in the DB.
   */
  socialAuthConnectableYouTubeChannelsAnalyticsV2: Array<SelectableYoutubeAnalyticsChannel>;
  socialAuthForJoinCampaign?: Maybe<SocialAuthForJoinCampaignPayload>;
  socialAuthReConnectInstagram?: Maybe<SocialAuthReConnectInstagramPayload>;
  /**
   *  Reconnects a Facebook Page analytics account.
   *  May be used by admin, staff, agency and advertiser users. We need the mutation to reconnect an account if its token invalid.
   */
  socialAuthReconnectFacebookPageAccountAnalytics?: Maybe<SocialAuthReconnectFacebookPageAccountAnalyticsPayload>;
  /**
   *  Reconnects an Instagram analytics account.
   *  May be used by admin, staff, agency and advertiser users. We need the mutation to reconnect an account if its token is not valid no more.
   */
  socialAuthReconnectInstagramAccountAnalytics?: Maybe<SocialAuthReconnectInstagramAccountAnalyticsPayload>;
  /**
   *  Reconnects a Twitter analytics account.
   *  May be used by admin, staff, agency and advertiser users. We need the mutation to reconnect an account if its token invalid.
   */
  socialAuthReconnectTwitterAccountAnalyticsV2?: Maybe<SocialAuthReconnectTwitterAccountAnalyticsPayload>;
  /**
   *  Reconnects a YouTube analytics account.
   *  May be used by admin, staff, agency and advertiser users. We need the mutation to reconnect an account if its token invalid.
   */
  socialAuthReconnectYouTubeAccountAnalyticsV2?: Maybe<SocialAuthReconnectYouTubeAccountAnalyticsPayload>;
  /**
   *  Make Facebook OAuth when signing-in and returns Facebook UserId and account name
   *  Used by only Talent Agency user
   */
  socialAuthTalentSignInFacebook?: Maybe<SocialAuthTalentSignInFacebookPayload>;
  /**
   *  Make Facebook OAuth when signing-in and returns a list of Page & Instagram account pairs and your Facebook account.
   *  Used by only Talent Agency user
   */
  socialAuthTalentSignInInstagram?: Maybe<SocialAuthTalentSignInInstagramPayload>;
  /**
   *  Make Twitter OAuth when signing-in and returns Twitter user id  and account name
   *  Used by only Talent Agency user
   */
  socialAuthTalentSignInTwitter?: Maybe<SocialAuthTalentSignInTwitterPayload>;
  /**
   *  Make YouTube OAuth when signing-in and returns a list of YouTube Channels.
   *  Used by only Talent Agency user
   */
  socialAuthTalentSignInYouTube?: Maybe<SocialAuthTalentSignInYouTubePayload>;
  submitForm?: Maybe<SubmitFormPayload>;
  submitFormInternal?: Maybe<SubmitFormInternalPayload>;
  subscribeAnalyticsPlan?: Maybe<SubscribeAnalyticsPlan>;
  subscribeAnalyticsTrialPlan?: Maybe<SubscribeAnalyticsTrialPlan>;
  /** Allow creator staff to change account */
  switchInfluencerForStaff?: Maybe<SwitchInfluencerForStaffPayload>;
  switchRoleToAdmin?: Maybe<SwitchRoleToAdmin>;
  switchRoleToAdvertiser?: Maybe<SwitchRoleToAdvertiser>;
  switchRoleToAgency?: Maybe<SwitchRoleToAgency>;
  switchRoleToPartner?: Maybe<SwitchRoleToPartner>;
  switchRoleToStaff?: Maybe<SwitchRoleToStaff>;
  switchRoleToTalentAgency?: Maybe<SwitchRoleToTalentAgency>;
  tokenAuth?: Maybe<ObtainJSONWebTokenPayload>;
  /** for internal use */
  tokenAuthLongLive?: Maybe<TokenAuthLongLive>;
  /**
   *
   *             Unapprove Applicant influencers to Selection Marketplace campaign
   *
   */
  unApproveApplicantInfluencers?: Maybe<UnApproveApplicantInfluencers>;
  unassignChatPic?: Maybe<UnassignChatPICPayload>;
  unresolveChat?: Maybe<UnresolveChatPayload>;
  /**  Update crm account information */
  updateAccountInformation?: Maybe<UpdateAccountInformationPayload>;
  updateAddress?: Maybe<UpdateAddress>;
  /**  Update crm address information */
  updateAddressInformation?: Maybe<UpdateAddressInformationPayload>;
  updateAdminUser?: Maybe<UpdateAdmin>;
  updateAdvertiser?: Maybe<UpdateAdvertiser>;
  updateAdvertiserByAdvertiser?: Maybe<UpdateAdvertiser>;
  /** Update Advertiser User */
  updateAdvertiserUser?: Maybe<UpdateAdvertiserUser>;
  updateAdvertiserUserSettings?: Maybe<UpdateAdvertiserUserSettings>;
  updateAgency?: Maybe<UpdatePlainAgency>;
  /** Update Ad Agency User */
  updateAgencyUser?: Maybe<UpdateAgency>;
  updateAutoManagedAffiliateCommissionRates?: Maybe<UpdateAutoManagedAffiliateCommissionRates>;
  updateAutoManagedCampaign?: Maybe<UpdateAutoManagedCampaignPayload>;
  /**  Update auto-managed campaign */
  updateAutoManagedCampaignsStatus?: Maybe<UpdateAutoManagedCampaignsStatusPayload>;
  updateBioSections?: Maybe<UpdateBioSectionsPayload>;
  updateCmsAssetShareRate?: Maybe<UpdateCmsAssetShareRate>;
  updateCmsChannelShareRate?: Maybe<UpdateCmsChannelShareRate>;
  updateComment?: Maybe<UpdateCommentPayload>;
  updateEmailTemplate?: Maybe<UpdateEmailTemplatePayload>;
  updateEngagement?: Maybe<UpdateEngagement>;
  /** Change budget mode for engagement campaign */
  updateEngagementBreakdownMode?: Maybe<UpdateEngagementBreakDownMode>;
  updateEngagementCouponList?: Maybe<UpdateEngagementCouponList>;
  /**
   *
   *         Join Influencers and their social accounts to Engagement Proposal
   *
   */
  updateEngagementInfluencerProposalList?: Maybe<UpdateEngagementInfluencerProposalList>;
  updateEngagementInfluencersBudget?: Maybe<UpdateEngagementInfluencersBudget>;
  updateEngagementPostForInfluencerV2?: Maybe<UpdateEngagementPostForInfluencerV2>;
  updateEngagementPostStatus?: Maybe<UpdateEngagementPostStatus>;
  updateEngagementPostV2?: Maybe<UpdateEngagementPostV2>;
  /**
   *
   *         Join a specific social account and its own influencer to Engagement Proposal
   *
   */
  updateEngagementSocialAccountProposalList?: Maybe<UpdateEngagementSocialAccountProposalList>;
  updateEngagementSocialAccountsBudget?: Maybe<UpdateEngagementSocialAccountsBudget>;
  updateEngagementStatus?: Maybe<UpdateEngagementStatus>;
  updateFan?: Maybe<UpdateFanPayload>;
  updateForm?: Maybe<UpdateFormPayload>;
  updateFormStatus?: Maybe<UpdateFormStatusPayload>;
  updateInfluencerBio?: Maybe<UpdateInfluencerBioPayload>;
  updateInfluencerProfile?: Maybe<UpdateInfluencerProfile>;
  /**  Update the influencer rate card for specific social account type */
  updateInfluencerRateCard?: Maybe<UpdateInfluencerRateCardPayload>;
  /**  Update the influencer rate cards for specific social account type */
  updateInfluencerRateCards?: Maybe<UpdateInfluencerRateCardsPayload>;
  updateInfluencerRateCardsByStaff?: Maybe<UpdateInfluencerRateCardsByStaff>;
  updateInfluencerTagsByStaff?: Maybe<UpdateInfluencerTagsByStaff>;
  updateInfluencerV2?: Maybe<UpdateInfluencerV2>;
  updateInternalMemo?: Maybe<UpdateInternalMemo>;
  updateLineBroadcastMessage?: Maybe<UpdateLineBroadcastMessagePayload>;
  /**  update LinkBio Theme and Colors */
  updateLinkBioThemeColor?: Maybe<UpdateLinkBioThemeColorPayload>;
  updateMarketplace?: Maybe<UpdateMarketplace>;
  updateMarketplaceAffiliateCommissionFees?: Maybe<UpdateMarketplaceAffiliateCommissionFees>;
  updateMarketplaceAffiliateCommissionRates?: Maybe<UpdateMarketplaceAffiliateCommissionRates>;
  updateMarketplaceCouponList?: Maybe<UpdateMarketplaceCouponList>;
  /**
   *
   *         Update Marketplace post status v2
   *
   */
  updateMarketplacePostStatusV2?: Maybe<UpdateMarketplacePostStatusV2>;
  /** Influencer update post tracking url */
  updateMarketplacePostTrackingUrl?: Maybe<UpdateMarketplacePostTrackingUrl>;
  updatePackage?: Maybe<UpdatePackage>;
  updatePartnerAgency?: Maybe<UpdatePartnerAgency>;
  /** Update Partner User */
  updatePartnerUser?: Maybe<UpdatePartnerUser>;
  updatePasswordByAdvertiserUser?: Maybe<UpdatePasswordByAdvertiserUser>;
  updatePaymentInformation?: Maybe<UpdatePaymentInformation>;
  updatePaymentStatus?: Maybe<UpdatePaymentStatus>;
  /**  Update crm personal user detail */
  updatePersonalUserDetail?: Maybe<UpdatePersoanlUserDetailPayload>;
  /**  Update all signed-up/ scraping social accounts */
  updateSocialAccounts?: Maybe<UpdateSocialAccountsPayload>;
  updateSocialUsername?: Maybe<UpdateSocialUsername>;
  updateStaffUser?: Maybe<UpdateStaff>;
  updateSubscriptionPlan?: Maybe<UpdateSubscriptionPlan>;
  updateTalentAgency?: Maybe<UpdateTalentAgency>;
  /** Update Talent Agency User */
  updateTalentAgencyUser?: Maybe<UpdateTalentAgencyUser>;
  /**  Update Talent Influencer by Talent agency user with multiple social accounts (Facebook, Instagram, Youtube, and Twitter) */
  updateTalentInfluencer?: Maybe<UpdateTalentInfluencerPayload>;
  updateTtcmPost?: Maybe<UpdateTtcmPost>;
  /**  Update crm user detail */
  updateUserDetail?: Maybe<UpdateUserDetailPayload>;
  /**  Update the credentials(tokens, owner id) for Youtube MCN influencers */
  updateYoutubeMCNInfluencerCredentials?: Maybe<UpdateYoutubeMCNCredentialsPayload>;
  /**
   *
   *             Upload csv report file for engagement campaign
   *
   */
  uploadEngagementReportFile?: Maybe<UploadEngagementReportFile>;
  /**
   *
   *                Upload report of engagement campaign from Insight screenshot check
   *
   */
  uploadEngagementReportFromInsightCheck?: Maybe<UploadEngagementReportFromInsightCheck>;
  /**  Signs-up UUUM influencers using youtube */
  uuumSocialAuthSignUp?: Maybe<UUUMSocialAuthSignUpPayload>;
  verifyLineIDToken?: Maybe<VerifyLineIDTokenPayload>;
  verifyToken?: Maybe<VerifyPayload>;
};

export type MutationaddAllSelectedInfluencersArgs = {
  input: AddAllSelectedInfluencersInput;
};

export type MutationaddCommentArgs = {
  input: AddCommentInput;
};

export type MutationaddEmailTemplateArgs = {
  input: AddEmailTemplateInput;
};

export type MutationaddFanArgs = {
  input: AddFanInput;
};

export type MutationaddFanTagsArgs = {
  input: AddFanTagsInput;
};

export type MutationaddFormArgs = {
  input: AddFormInput;
};

export type MutationaddInstagramCompareAccountAnalyticsArgs = {
  input: AddInstagramCompareAccountAnalyticsInput;
};

export type MutationaddInstagramHashtagAnalyticsArgs = {
  input: AddInstagramHashtagAnalyticsInput;
};

export type MutationaddMarketplacePostUrlArgs = {
  input: AddMarketplacePostUrlInput;
};

export type MutationaddMarketplacePostUrlForInfluencerArgs = {
  input: AddMarketplacePostUrlInputForInfluencer;
};

export type MutationaddReadTutorialArgs = {
  input: AddReadTutorialInput;
};

export type MutationaddSelectedInfluencersArgs = {
  input: AddSelectedInfluencersInput;
};

export type MutationaddStaffAdvertisersToAnotherStaffArgs = {
  input: AddStaffAdvertisersToAnotherStaffInput;
};

export type MutationaddYoutubeCompareAccountAnalyticsArgs = {
  input: AddYoutubeCompareAccountAnalyticsInput;
};

export type MutationallowEngagementInfluencerProposalListArgs = {
  input: AllowEngagementInfluencerProposalListInput;
};

export type MutationanalyticsSelectSubscriptionPlanArgs = {
  input: AnalyticsSelectSubscriptionPlanInput;
};

export type MutationanyXCheckEmailArgs = {
  input: AnyXCheckEmailInput;
};

export type MutationanyXSocialAuthConnectArgs = {
  input: AnyXSocialAuthConnectInput;
};

export type MutationanyXSocialAuthConnectForLinkBioArgs = {
  input: AnyXSocialAuthConnectForLinkBioInput;
};

export type MutationanyXSocialAuthReconnectForLinkBioArgs = {
  input: AnyXSocialAuthReconnectForLinkBioInput;
};

export type MutationanyXSocialAuthReconnectTikTokArgs = {
  input: AnyXSocialAuthReconnectTikTokInput;
};

export type MutationanyXSocialAuthReconnectTtcmArgs = {
  input: AnyXSocialAuthReconnectTTCMInput;
};

export type MutationanyXSocialAuthSignInArgs = {
  input: AnyXSocialAuthSignInInput;
};

export type MutationanyXSocialAuthSignInInstagramLineArgs = {
  input: AnyXSocialAuthSignInInstagramLineInput;
};

export type MutationanyXSocialAuthSignInOrSignUpArgs = {
  input: AnyXSocialAuthSignInOrSignUpInput;
};

export type MutationanyXSocialAuthSignUpArgs = {
  input: AnyXSocialAuthSignUpInput;
};

export type MutationanyXSocialAuthSignUpPartnerArgs = {
  input: AnyXSocialAuthSignUpPartnerInput;
};

export type MutationanyXSocialAuthSignUpTalentArgs = {
  input: AnyXSocialAuthSignUpTalentInput;
};

export type MutationapproveApplicantInfluencersArgs = {
  input: ApproveApplicantInfluencersInput;
};

export type MutationapproveAutoManagedPayoutArgs = {
  input: ApproveAutoManagedPayoutInput;
};

export type MutationapproveMarketplaceArgs = {
  input: ApproveMarketplaceInput;
};

export type MutationapproveMarketplaceAffiliatePayoutArgs = {
  input: ApproveMarketplaceAffiliatePayoutInput;
};

export type MutationapproveMarketplaceDraftPostArgs = {
  input: ApproveMarketplaceDraftPostInput;
};

export type MutationassignAdvertiserToStaffsArgs = {
  input: AssignAdvertiserForStaffsInput;
};

export type MutationassignChatPicArgs = {
  input: AssignChatPICInput;
};

export type Mutationauth0ToLocalJwtArgs = {
  input: Auth0ToLocalJWTInput;
};

export type MutationbulkUpdateEngagementInfluencersBudgetArgs = {
  input: BulkUpdateEngagementInfluencersBudgetInput;
};

export type MutationbulkUpdateEngagementSocialAccountsBudgetArgs = {
  input: BulkUpdateEngagementSocialAccountsBudgetInput;
};

export type MutationcancelSubscriptionPlanArgs = {
  input: CancelSubscriptionPlanInput;
};

export type MutationchangeInstagramUGCManagementPostStatusArgs = {
  input: ChangeInstagramUGCManagementPostStatusInput;
};

export type MutationclearCmsChannelAssetOwnerArgs = {
  input: ClearCmsChannelAssetOwnerInput;
};

export type MutationclickNotificationArgs = {
  input?: InputMaybe<ClickNotificationInput>;
};

export type MutationclickNotificationByEventIdArgs = {
  input?: InputMaybe<ClickNotificationByEventIdInput>;
};

export type MutationconnectFacebookPagesAndInstagramAccountsArgs = {
  input?: InputMaybe<ConnectFacebookPagesAndInstagramAccountsInput>;
};

export type MutationconnectNewShopifyAccountArgs = {
  input: ShopifyValidateAuthInput;
};

export type MutationconnectPackageToEngagementProposalArgs = {
  input: ConnectPackageToEngagementProposalInput;
};

export type MutationconnectPackageWithInfluencerAccountsArgs = {
  input: ConnectPackageWithInfluencerAccountsInput;
};

export type MutationconnectPackageWithSocialAccountsArgs = {
  input: ConnectPackageWithSocialAccountsInput;
};

export type MutationconnectPromotionMethodsArgs = {
  input: ConnectPromotionMethodsInput;
};

export type MutationcreateAdminUserArgs = {
  input: CreateAdminInput;
};

export type MutationcreateAdvertiserArgs = {
  input: CreateAdvertiserInput;
};

export type MutationcreateAdvertiserUserArgs = {
  input: CreateAdvertiserUserInput;
};

export type MutationcreateAdvertiserWithUserArgs = {
  input: CreateAdvertiserWithUserInput;
};

export type MutationcreateAdvertiserWithUserV2Args = {
  input: CreateAdvertiserWithUserInputV2;
};

export type MutationcreateAgencyUserArgs = {
  input: CreateAgencyInput;
};

export type MutationcreateAgencyWithUserArgs = {
  input: CreateAgencyWithUserInput;
};

export type MutationcreateCreatorStaffArgs = {
  input: CreateCreatorStaffInput;
};

export type MutationcreateEngagementArgs = {
  input: CreateEngagementInput;
};

export type MutationcreateEngagementPostForInfluencerV2Args = {
  input: CreateEngagementPostInputForInfluencerV2;
};

export type MutationcreateEngagementPostV2Args = {
  input: CreateEngagementPostInputV2;
};

export type MutationcreateInfluencerBioArgs = {
  input: CreateInfluencerBioInput;
};

export type MutationcreateInfluencerV2Args = {
  input: CreateInfluencerInputV2;
};

export type MutationcreateInstagramUGCManagementHashtagArgs = {
  input: CreateInstagramUGCManagementHashtagInput;
};

export type MutationcreateLineBroadcastMessageArgs = {
  input: CreateLineBroadcastMessageInput;
};

export type MutationcreateLineChannelArgs = {
  input: CreateLineChannelInput;
};

export type MutationcreateMarketplaceArgs = {
  input: CreateMarketplaceInput;
};

export type MutationcreateMarketplaceDraftPostArgs = {
  input: CreateMarketplaceDraftPostInput;
};

export type MutationcreatePackageArgs = {
  input: CreatePackageInput;
};

export type MutationcreatePackageWithInfluencerAccountsArgs = {
  input: CreatePackageWithInfluencerAccountsInput;
};

export type MutationcreatePackageWithSocialAccountsArgs = {
  input: CreatePackageWithSocialAccountsInput;
};

export type MutationcreatePartnerUserArgs = {
  input: CreatePartnerUserInput;
};

export type MutationcreateSelfRegisteredAdvertiserUserArgs = {
  input: CreateSelfRegisteredAdvertiserUserInput;
};

export type MutationcreateSocialAccountAnalyticsAdminV2Args = {
  input: CreateSocialAccountAnalyticsAdminInputV2;
};

export type MutationcreateSocialAccountAnalyticsAdvertiserV2Args = {
  input: CreateSocialAccountAnalyticsAdvertiserInputV2;
};

export type MutationcreateStaffUserArgs = {
  input: CreateStaffInput;
};

export type MutationcreateTalentAgencyUserArgs = {
  input: CreateTalentAgencyUserInput;
};

export type MutationcreateTalentAgencyWithUserArgs = {
  input: CreateTalentAgencyWithUserInput;
};

export type MutationcreateTalentInfluencerArgs = {
  input: CreateTalentInfluencerInput;
};

export type MutationdeleteAdvertiserArgs = {
  id: Scalars['Int']['input'];
};

export type MutationdeleteAgencyArgs = {
  id: Scalars['Int']['input'];
};

export type MutationdeleteBrandAccountAnalyticsArgs = {
  input: DeleteBrandAccountAnalyticsInput;
};

export type MutationdeleteCommentArgs = {
  input: DeleteCommentInput;
};

export type MutationdeleteEngagementArgs = {
  id: Scalars['Int']['input'];
};

export type MutationdeleteEngagementInfluencerProposalArgs = {
  input: DeleteEngagementInfluencerProposalInput;
};

export type MutationdeleteEngagementPostForInfluencerArgs = {
  id: Scalars['Int']['input'];
};

export type MutationdeleteEngagementProposalJoinedAccountArgs = {
  input: DeleteEngagementProposalJoinedAccountInput;
};

export type MutationdeleteEngagementSocialAccountProposalArgs = {
  input: DeleteEngagementSocialAccountProposalInput;
};

export type MutationdeleteFanTagsArgs = {
  input: DeleteFanTagsInput;
};

export type MutationdeleteInfluencerArgs = {
  id: Scalars['Int']['input'];
};

export type MutationdeleteInstagramCompareAccountAnalyticsArgs = {
  input: DeleteInstagramCompareAccountAnalyticsInput;
};

export type MutationdeleteInstagramHashtagAnalyticsArgs = {
  input: DeleteInstagramHashtagAnalyticsInput;
};

export type MutationdeleteInstagramUGCManagementHashtagArgs = {
  input: DeleteInstagramUGCManagementHashtagInput;
};

export type MutationdeleteMarketplaceArgs = {
  id: Scalars['Int']['input'];
};

export type MutationdeletePackageArgs = {
  id: Scalars['Int']['input'];
};

export type MutationdeleteSocialAccountPackageArgs = {
  influencerId: Scalars['Int']['input'];
  packageId: Scalars['Int']['input'];
};

export type MutationdeleteTalentAgencyArgs = {
  id: Scalars['Int']['input'];
};

export type MutationdeleteUserArgs = {
  id: Scalars['Int']['input'];
};

export type MutationdeleteYoutubeCompareAccountAnalyticsArgs = {
  input: DeleteYoutubeCompareAccountAnalyticsInput;
};

export type MutationdenyEngagementInfluencerProposalListArgs = {
  input: DenyEngagementInfluencerProposalListInput;
};

export type MutationeditSocialAccountAnalyticsAdminV2Args = {
  input: EditSocialAccountAnalyticsAdminInputV2;
};

export type MutationeditSocialAccountAnalyticsAdvertiserV2Args = {
  input: EditSocialAccountAnalyticsAdvertiserInputV2;
};

export type MutationengagementAddInfluencersToProposalFromAnotherCampaignArgs = {
  input: EngagementAddInfluencersToProposalFromAnotherCampaignInput;
};

export type MutationengagementCampaignCouponTrackingArgs = {
  input: EngagementCampaignCouponTrackingInput;
};

export type MutationexportInstagramAccountDashboardAnalyticsArgs = {
  input: ExportInstagramDashboardAccountAnalyticsInput;
};

export type MutationexportYoutubeDashboardAccountAnalyticsArgs = {
  input: ExportYoutubeDashboardAccountAnalyticsInput;
};

export type MutationexportYoutubeDashboardAccountAnalyticsForInfluencerArgs = {
  input: InfluencerExportYoutubeDashboardAccountAnalyticsInput;
};

export type MutationexternalCreateFacebookPageAccountAnalyticsArgs = {
  input: ExternalCreateFacebookPageAccountAnalyticsInput;
};

export type MutationexternalCreateInstagramAccountAnalyticsArgs = {
  input: ExternalCreateInstagramAccountAnalyticsInput;
};

export type MutationexternalCreateYoutubeAccountAnalyticsArgs = {
  input: ExternalCreateYoutubeAccountAnalyticsInput;
};

export type MutationexternalSocialAuthConnectableFacebookPageAnalyticsArgs = {
  input: ExternalConnectableFacebookPageAnalyticsInput;
};

export type MutationexternalSocialAuthConnectableInstagramAccountsAnalyticsArgs = {
  input: ExternalSocialAuthConnectableInstagramAccountsAnalyticsInput;
};

export type MutationexternalSocialAuthReconnectFacebookPageAccountAnalyticsArgs = {
  input: ExternalSocialAuthReconnectFacebookPageAccountAnalyticsInput;
};

export type MutationexternalUserSocialAuthReconnectInstagramAccountAnalyticsArgs = {
  input: ExternalUserSocialAuthReconnectInstagramAccountAnalyticsInput;
};

export type MutationfetchAutoManagedCustomShopeeCampaignsArgs = {
  input: ShopeeCustomCampaignInput;
};

export type MutationimportFansArgs = {
  input: ImportFansInput;
};

export type MutationinfluencerAddYoutubeCompareAccountAnalyticsArgs = {
  input: InfluencerAddYoutubeCompareAccountAnalyticsInput;
};

export type MutationinfluencerAnalyticsTwitterRefreshArgs = {
  input: InfluencerAnalyticsTwitterRefreshInput;
};

export type MutationinfluencerDeleteYoutubeCompareAccountAnalyticsArgs = {
  input: InfluencerDeleteYoutubeCompareAccountAnalyticsInput;
};

export type MutationinfluencerExportInstagramAccountDashboardArgs = {
  input: ExportInstagramDashboardAccountInfluencerInput;
};

export type MutationinfluencerSignUpArgs = {
  input: InfluencerSignUpInput;
};

export type MutationinfluencerSocialAuthReconnectInstagramAccountAnalyticsArgs = {
  input: InfluencerSocialAuthReconnectInstagramAccountAnalyticsInput;
};

export type MutationjoinAutoManagedCampaignArgs = {
  input: JoinAutoManagedCampaignInput;
};

export type MutationjoinMarketplaceV2Args = {
  input: JoinMarketplaceV2Input;
};

export type MutationlinkCmsChannelAssetsArgs = {
  input: LinkCmsChannelAssetsInput;
};

export type MutationmadeMarketplaceCampaignPostArgs = {
  input: MadeMarketplaceCampaignPostInput;
};

export type MutationmarkReadNotificationByAppArgs = {
  input?: InputMaybe<MarkReadNotificationByAppInput>;
};

export type MutationmarketplaceCampaignCouponTrackingArgs = {
  input: MarketplaceCampaignCouponTrackingInput;
};

export type MutationpublishInfluencerBioArgs = {
  input: PublishInfluencerBioInput;
};

export type MutationreSendBcaRequestArgs = {
  input: ReSendBcaRequestInput;
};

export type MutationrefreshPasswordArgs = {
  input: RefreshPasswordInput;
};

export type MutationrefreshProfileAccountArgs = {
  input: RefreshProfileAccountInput;
};

export type MutationrefreshTwitterAccountArgs = {
  input: RefreshTwitterAccountInput;
};

export type MutationregisterPushTokenArgs = {
  input: RegisterPushTokenInput;
};

export type MutationrejectEngagementDraftPostArgs = {
  input: RejectEngagementDraftPostInput;
};

export type MutationrejectMarketplaceCampaignArgs = {
  input: RejectMarketplaceCampaignInput;
};

export type MutationrejectMarketplaceDraftPostArgs = {
  input: RejectMarketplaceDraftPostInput;
};

export type MutationremoveAllFormResponsesArgs = {
  input: RemoveAllFormResponsesInput;
};

export type MutationremoveAllSelectedInfluencersArgs = {
  input: RemoveAllSelectedInfluencersInput;
};

export type MutationremoveCreatorStaffArgs = {
  input: RemoveCreatorStaffInput;
};

export type MutationremoveEmailsArgs = {
  input: RemoveEmailsInput;
};

export type MutationremoveFansArgs = {
  input: RemoveFansInput;
};

export type MutationremoveFormResponseArgs = {
  input: RemoveFormResponseInput;
};

export type MutationremoveFormsArgs = {
  input: RemoveFormsInput;
};

export type MutationremoveSelectedInfluencersArgs = {
  input: RemoveSelectedInfluencersInput;
};

export type MutationremoveShopifyAccountArgs = {
  input: RemoveShopifyAccountInput;
};

export type MutationreorderMarketplaceCampaignsArgs = {
  campaignIds: Array<InputMaybe<Scalars['Int']['input']>>;
};

export type MutationreportEngagementPostStatusArgs = {
  input: ReportEngagementPostStatusInput;
};

export type MutationreportMarketplacePostStatusArgs = {
  input: ReportMarketplacePostStatusInput;
};

export type MutationrequestDeleteAccountArgs = {
  input: RequestDeleteAccountInput;
};

export type MutationrequestGoogleSlideCustomisedReportArgs = {
  campaignId: Scalars['Int']['input'];
  campaignType: CampaignType;
  selectedMetrics: Array<Scalars['String']['input']>;
};

export type MutationrequestGoogleSlideReportArgs = {
  campaignId: Scalars['Int']['input'];
  campaignType: CampaignType;
};

export type MutationrequestInfluencerProfilePptxArgs = {
  format: Scalars['String']['input'];
  influencerId: Scalars['Int']['input'];
  socialAccountId: Scalars['Int']['input'];
  socialMedia: Scalars['String']['input'];
};

export type MutationrequestInfluencersProfilePptxArgs = {
  format: Scalars['String']['input'];
  influencerIds: Array<InputMaybe<Scalars['Int']['input']>>;
  socialMedias: Array<Scalars['String']['input']>;
};

export type MutationrequestInfluencersSocialAccountPptxArgs = {
  format: Scalars['String']['input'];
  influencerSocialAccountIds: Array<InputMaybe<SocialAccountPairInput>>;
  socialMedia: Scalars['String']['input'];
};

export type MutationrequestInstagramUGCManagementPostArgs = {
  input: RequestInstagramUGCManagementPostInput;
};

export type MutationrequestPackageSpreadsheetArgs = {
  keyword?: InputMaybe<Scalars['String']['input']>;
  packageId: Scalars['Int']['input'];
};

export type MutationrequestPowerpointCustomisedReportArgs = {
  campaignId: Scalars['Int']['input'];
  campaignType: CampaignType;
  selectedMetrics: Array<Scalars['String']['input']>;
};

export type MutationrequestPowerpointReportArgs = {
  campaignId: Scalars['Int']['input'];
  campaignType: CampaignType;
};

export type MutationrequestProposalExcelArgs = {
  engagementId: Scalars['Int']['input'];
  influencerIds?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type MutationrequestProposalPptxArgs = {
  engagementId: Scalars['Int']['input'];
  format: Scalars['String']['input'];
  influencerIds?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type MutationrequestProposalSpreadsheetArgs = {
  engagementId: Scalars['Int']['input'];
  influencerIds?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type MutationresendEmailForAdvertiserArgs = {
  input: ResendEmailForAdvertiserInput;
};

export type MutationresendInfluencerForgotPasswordEmailArgs = {
  input: ResendInfluencerForgotPasswordEmailInput;
};

export type MutationresendInfluencerSignUpEmailArgs = {
  input: ResendInfluencerSignUpEmailInput;
};

export type MutationresendPartnerEmailsArgs = {
  input: ResendPartnerEmailsInput;
};

export type MutationresetPasswordArgs = {
  input: ResetPasswordInput;
};

export type MutationresetPasswordAndLoginArgs = {
  input: ResetPasswordInput;
};

export type MutationresolveChatArgs = {
  input: ResolveChatInput;
};

export type MutationsendBcaRequestArgs = {
  input: SendBcaRequestInput;
};

export type MutationsendContactArgs = {
  input: SendContactInput;
};

export type MutationsendLineBroadcastMessageArgs = {
  input: SendLineBroadcastMessageInput;
};

export type MutationsendMessageArgs = {
  input: SendMessageInput;
};

export type MutationshopifyValidateAuthArgs = {
  input: ShopifyValidateAuthInput;
};

export type MutationsocialAuthConnectableFacebookPageAnalyticsArgs = {
  input: SocialAuthConnectableFacebookPageAnalyticsInput;
};

export type MutationsocialAuthConnectableInstagramAccountsAnalyticsArgs = {
  input: SocialAuthConnectableInstagramAccountsAnalyticsInput;
};

export type MutationsocialAuthConnectableTwitterAccountAnalyticsV2Args = {
  input?: InputMaybe<SocialAuthConnectableTwitterAccountAnalyticsInput>;
};

export type MutationsocialAuthConnectableYouTubeChannelsAnalyticsV2Args = {
  input?: InputMaybe<GetSelectableYoutubeAnalyticsChannelsInput>;
};

export type MutationsocialAuthForJoinCampaignArgs = {
  input: SocialAuthForJoinCampaignInput;
};

export type MutationsocialAuthReConnectInstagramArgs = {
  input: SocialAuthReConnectInstagramInput;
};

export type MutationsocialAuthReconnectFacebookPageAccountAnalyticsArgs = {
  input: SocialAuthReconnectFacebookPageAccountAnalyticsInput;
};

export type MutationsocialAuthReconnectInstagramAccountAnalyticsArgs = {
  input: SocialAuthReconnectInstagramAccountAnalyticsInput;
};

export type MutationsocialAuthReconnectTwitterAccountAnalyticsV2Args = {
  input: SocialAuthReconnectTwitterAccountAnalyticsInput;
};

export type MutationsocialAuthReconnectYouTubeAccountAnalyticsV2Args = {
  input: SocialAuthReconnectYouTubeAccountAnalyticsInput;
};

export type MutationsocialAuthTalentSignInFacebookArgs = {
  input: SocialAuthTalentSignInFacebookInput;
};

export type MutationsocialAuthTalentSignInInstagramArgs = {
  input: SocialAuthTalentSignInInstagramInput;
};

export type MutationsocialAuthTalentSignInTwitterArgs = {
  input: SocialAuthTalentSignInTwitterInput;
};

export type MutationsocialAuthTalentSignInYouTubeArgs = {
  input: SocialAuthTalentSignInYouTubeInput;
};

export type MutationsubmitFormArgs = {
  input: SubmitFormInput;
};

export type MutationsubmitFormInternalArgs = {
  input: SubmitFormInternalInput;
};

export type MutationsubscribeAnalyticsPlanArgs = {
  input: SubscribeAnalyticsPlanInput;
};

export type MutationswitchInfluencerForStaffArgs = {
  input: SwitchInfluencerForStaffInput;
};

export type MutationswitchRoleToAdminArgs = {
  input: SwitchRoleToAdminInput;
};

export type MutationswitchRoleToAdvertiserArgs = {
  input: SwitchRoleToAdvertiserInput;
};

export type MutationswitchRoleToAgencyArgs = {
  input: SwitchRoleToAgencyInput;
};

export type MutationswitchRoleToPartnerArgs = {
  input: SwitchRoleToPartnerInput;
};

export type MutationswitchRoleToStaffArgs = {
  input: SwitchRoleToStaffInput;
};

export type MutationswitchRoleToTalentAgencyArgs = {
  input: SwitchRoleToTalentAgencyInput;
};

export type MutationtokenAuthArgs = {
  input: ObtainJSONWebTokenInput;
};

export type MutationtokenAuthLongLiveArgs = {
  input: TokenAuthLongLiveInput;
};

export type MutationunApproveApplicantInfluencersArgs = {
  input: UnApproveApplicantInfluencersInput;
};

export type MutationunassignChatPicArgs = {
  input: UnassignChatPICInput;
};

export type MutationunresolveChatArgs = {
  input: UnresolveChatInput;
};

export type MutationupdateAccountInformationArgs = {
  input: UpdateAccountInformationInput;
};

export type MutationupdateAddressArgs = {
  input: UpdateAddressInput;
};

export type MutationupdateAddressInformationArgs = {
  input: UpdateAddressInformationInput;
};

export type MutationupdateAdminUserArgs = {
  input: UpdateAdminInput;
};

export type MutationupdateAdvertiserArgs = {
  input: UpdateAdvertiserInput;
};

export type MutationupdateAdvertiserByAdvertiserArgs = {
  input: UpdateAdvertiserInput;
};

export type MutationupdateAdvertiserUserArgs = {
  input: UpdateAdvertiserUserInput;
};

export type MutationupdateAdvertiserUserSettingsArgs = {
  input: UpdateAdvertiserUserSettingsInput;
};

export type MutationupdateAgencyArgs = {
  input: UpdatePlainAgencyInput;
};

export type MutationupdateAgencyUserArgs = {
  input: UpdateAgencyInput;
};

export type MutationupdateAutoManagedAffiliateCommissionRatesArgs = {
  input: UpdateAutoManagedAffiliateCommissionRatesInput;
};

export type MutationupdateAutoManagedCampaignArgs = {
  input: UpdateAutoManagedCampaignInput;
};

export type MutationupdateAutoManagedCampaignsStatusArgs = {
  input: UpdateAutoManagedCampaignsStatusInput;
};

export type MutationupdateBioSectionsArgs = {
  input: UpdateBioSectionsInput;
};

export type MutationupdateCmsAssetShareRateArgs = {
  input: UpdateCmsAssetShareRateInput;
};

export type MutationupdateCmsChannelShareRateArgs = {
  input: UpdateCmsChannelShareRateInput;
};

export type MutationupdateCommentArgs = {
  input: UpdateCommentInput;
};

export type MutationupdateEmailTemplateArgs = {
  input: UpdateEmailTemplateInput;
};

export type MutationupdateEngagementArgs = {
  input: UpdateEngagementInput;
};

export type MutationupdateEngagementBreakdownModeArgs = {
  input: UpdateEngagementBreakDownModeInput;
};

export type MutationupdateEngagementCouponListArgs = {
  input: UpdateEngagementCouponListInput;
};

export type MutationupdateEngagementInfluencerProposalListArgs = {
  input: UpdateEngagementInfluencerProposalListInput;
};

export type MutationupdateEngagementInfluencersBudgetArgs = {
  input: UpdateEngagementInfluencersBudgetInput;
};

export type MutationupdateEngagementPostForInfluencerV2Args = {
  input: UpdateEngagementPostInputForInfluencerV2;
};

export type MutationupdateEngagementPostStatusArgs = {
  input: UpdateEngagementPostStatusInput;
};

export type MutationupdateEngagementPostV2Args = {
  input: UpdateEngagementPostInputV2;
};

export type MutationupdateEngagementSocialAccountProposalListArgs = {
  input: UpdateEngagementSocialAccountProposalListInput;
};

export type MutationupdateEngagementSocialAccountsBudgetArgs = {
  input: UpdateEngagementSocialAccountsBudgetInput;
};

export type MutationupdateEngagementStatusArgs = {
  input: UpdateEngagementStatusInput;
};

export type MutationupdateFanArgs = {
  input: UpdateFanInput;
};

export type MutationupdateFormArgs = {
  input: UpdateFormInput;
};

export type MutationupdateFormStatusArgs = {
  input: UpdateFormStatusInput;
};

export type MutationupdateInfluencerBioArgs = {
  input: UpdateInfluencerBioInput;
};

export type MutationupdateInfluencerProfileArgs = {
  input: UpdateInfluencerProfileInput;
};

export type MutationupdateInfluencerRateCardArgs = {
  input: UpdateInfluencerRateCardInput;
};

export type MutationupdateInfluencerRateCardsArgs = {
  input: UpdateInfluencerRateCardsInput;
};

export type MutationupdateInfluencerRateCardsByStaffArgs = {
  input: UpdateInfluencerRateCardsByStaffInput;
};

export type MutationupdateInfluencerTagsByStaffArgs = {
  input: UpdateInfluencerTagsByStaffInput;
};

export type MutationupdateInfluencerV2Args = {
  input: UpdateInfluencerInputV2;
};

export type MutationupdateInternalMemoArgs = {
  input: UpdateInternalMemoInput;
};

export type MutationupdateLineBroadcastMessageArgs = {
  input: UpdateLineBroadcastMessageInput;
};

export type MutationupdateLinkBioThemeColorArgs = {
  input: UpdateLinkBioThemeColorInput;
};

export type MutationupdateMarketplaceArgs = {
  input: UpdateMarketplaceInput;
};

export type MutationupdateMarketplaceAffiliateCommissionFeesArgs = {
  input: UpdateMarketplaceAffiliateCommissionFeesInput;
};

export type MutationupdateMarketplaceAffiliateCommissionRatesArgs = {
  input: UpdateMarketplaceAffiliateCommissionRatesInput;
};

export type MutationupdateMarketplaceCouponListArgs = {
  input: UpdateMarketplaceCouponListInput;
};

export type MutationupdateMarketplacePostStatusV2Args = {
  input: UpdateMarketplacePostStatusInputV2;
};

export type MutationupdateMarketplacePostTrackingUrlArgs = {
  input: UpdateMarketplacePostTrackingUrlInput;
};

export type MutationupdatePackageArgs = {
  input: UpdatePackageInput;
};

export type MutationupdatePartnerAgencyArgs = {
  input: UpdatePartnerAgencyInput;
};

export type MutationupdatePartnerUserArgs = {
  input: UpdatePartnerUserInput;
};

export type MutationupdatePasswordByAdvertiserUserArgs = {
  input: UpdatePasswordByAdvertiserUserInput;
};

export type MutationupdatePaymentInformationArgs = {
  input: UpdatePaymentInformationInput;
};

export type MutationupdatePaymentStatusArgs = {
  input: UpdatePaymentStatusInput;
};

export type MutationupdatePersonalUserDetailArgs = {
  input: UpdatePersonalUserDetailInput;
};

export type MutationupdateSocialAccountsArgs = {
  input?: InputMaybe<UpdateSocialAccountsInput>;
};

export type MutationupdateSocialUsernameArgs = {
  input: UpdateSocialUsernameInput;
};

export type MutationupdateStaffUserArgs = {
  input: UpdateStaffInput;
};

export type MutationupdateSubscriptionPlanArgs = {
  input: UpdateSubscriptionPlanInput;
};

export type MutationupdateTalentAgencyArgs = {
  input: UpdateTalentAgencyInput;
};

export type MutationupdateTalentAgencyUserArgs = {
  input: UpdateTalentAgencyUserInput;
};

export type MutationupdateTalentInfluencerArgs = {
  input: UpdateTalentInfluencerInput;
};

export type MutationupdateTtcmPostArgs = {
  input: UpdateTtcmPostInput;
};

export type MutationupdateUserDetailArgs = {
  input: UpdateUserDetailInput;
};

export type MutationupdateYoutubeMCNInfluencerCredentialsArgs = {
  input: UpdateYoutubeMCNCredentialsInput;
};

export type MutationuploadEngagementReportFileArgs = {
  input: UploadEngagementReportFileInput;
};

export type MutationuploadEngagementReportFromInsightCheckArgs = {
  input: UploadEngagementReportFromInsightCheckInput;
};

export type MutationuuumSocialAuthSignUpArgs = {
  input: UUUMSocialAuthSignUpInput;
};

export type MutationverifyLineIDTokenArgs = {
  input: VerifyLineIDTokenInput;
};

export type MutationverifyTokenArgs = {
  input: VerifyInput;
};

export type NeedReconnectForFacebookPayload = {
  __typename?: 'NeedReconnectForFacebookPayload';
  need: Scalars['Boolean']['output'];
};

export type NeedReviewMarketplacePost = {
  __typename?: 'NeedReviewMarketplacePost';
  id: Scalars['Int']['output'];
  influencer: MarketplacePostInfluencer;
};

export type NetSuiteCustomerData = {
  __typename?: 'NetSuiteCustomerData';
  companyUrl?: Maybe<Scalars['String']['output']>;
  countryId?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  phoneNumber?: Maybe<Scalars['String']['output']>;
};

export type NetSuiteCustomerDataForSearch = {
  __typename?: 'NetSuiteCustomerDataForSearch';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type NetSuiteVendorData = {
  __typename?: 'NetSuiteVendorData';
  companyUrl?: Maybe<Scalars['String']['output']>;
  countryId?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  phoneNumber?: Maybe<Scalars['String']['output']>;
};

export type NetSuiteVendorDataForSearch = {
  __typename?: 'NetSuiteVendorDataForSearch';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

/** An enumeration. */
export enum NetSuiteVerifiedStatus {
  NOT_VERIFIED = 'NOT_VERIFIED',
  NO_STATUS = 'NO_STATUS',
  VERIFIED = 'VERIFIED',
}

export type NewAutoManagedCampaignForInfluencerForYourJob = {
  __typename?: 'NewAutoManagedCampaignForInfluencerForYourJob';
  brandName?: Maybe<Scalars['String']['output']>;
  commissionRate?: Maybe<Scalars['Float']['output']>;
  currency: Scalars['String']['output'];
  endDate?: Maybe<Scalars['Date']['output']>;
  /** Whether she uploaded her post on social media or not. */
  hasReport: Scalars['Boolean']['output'];
  id: Scalars['Int']['output'];
  mkpServerId: Scalars['Int']['output'];
  startDate?: Maybe<Scalars['Date']['output']>;
  status: AutoManagedAffiliateCampaignStatus;
  thumbNail: Scalars['String']['output'];
  title: Scalars['String']['output'];
  type: CampaignType;
};

export type NewCampaignPostReport = {
  __typename?: 'NewCampaignPostReport';
  InternalInfo?: Maybe<Array<Scalars['String']['output']>>;
  postInfo: CampaignPostInfo;
  summary: NewCampaignStatsItemRow;
  summaryDaily?: Maybe<Array<CampaignSummaryDaily>>;
};

export type NewCampaignReportSummary = {
  __typename?: 'NewCampaignReportSummary';
  campaignSocialMedias: Array<CampaignSocialMediaType>;
  comment: Scalars['Int']['output'];
  conversions?: Maybe<Scalars['Int']['output']>;
  cost: Scalars['Float']['output'];
  currency: Scalars['String']['output'];
  engagement: Scalars['Float']['output'];
  engagementRate: Scalars['Float']['output'];
  like?: Maybe<Scalars['Int']['output']>;
  orders?: Maybe<Scalars['Int']['output']>;
  postCount: Scalars['Int']['output'];
  /** @deprecated please use sales_tune or sales_referral */
  sales?: Maybe<Scalars['Float']['output']>;
  salesReferral?: Maybe<Scalars['Float']['output']>;
  salesTune?: Maybe<Scalars['Float']['output']>;
};

export type NewCampaignStatsItemRow = {
  __typename?: 'NewCampaignStatsItemRow';
  age35UpPercentage?: Maybe<Scalars['Float']['output']>;
  age1824Percentage?: Maybe<Scalars['Float']['output']>;
  age2534Percentage?: Maybe<Scalars['Float']['output']>;
  averageViewDurationMinutes?: Maybe<Scalars['Float']['output']>;
  averageViewDurationPercentage?: Maybe<Scalars['Float']['output']>;
  averageViewDurationSeconds?: Maybe<Scalars['Int']['output']>;
  averageViewTime?: Maybe<Scalars['Float']['output']>;
  back?: Maybe<Scalars['Int']['output']>;
  click?: Maybe<Scalars['Int']['output']>;
  comment: Scalars['Int']['output'];
  conversion?: Maybe<Scalars['Int']['output']>;
  cost: Scalars['Float']['output'];
  costsPerAction?: Maybe<Scalars['Float']['output']>;
  costsPerClick?: Maybe<Scalars['Float']['output']>;
  /** For Vietnam campaigns */
  costsPerComment?: Maybe<Scalars['Float']['output']>;
  costsPerEngagement?: Maybe<Scalars['Float']['output']>;
  costsPerFollower?: Maybe<Scalars['Float']['output']>;
  costsPerImpressions?: Maybe<Scalars['Float']['output']>;
  costsPerLike?: Maybe<Scalars['Float']['output']>;
  costsPerOrder?: Maybe<Scalars['Float']['output']>;
  costsPerReach?: Maybe<Scalars['Float']['output']>;
  costsPerSale?: Maybe<Scalars['Float']['output']>;
  costsPerSaleReferral?: Maybe<Scalars['Float']['output']>;
  costsPerSaleTune?: Maybe<Scalars['Float']['output']>;
  costsPerSaved?: Maybe<Scalars['Float']['output']>;
  costsPerShare?: Maybe<Scalars['Float']['output']>;
  costsPerView?: Maybe<Scalars['Float']['output']>;
  dislikes?: Maybe<Scalars['Int']['output']>;
  engagement: Scalars['Float']['output'];
  engagementRate?: Maybe<Scalars['Float']['output']>;
  exited?: Maybe<Scalars['Int']['output']>;
  femalePercentage?: Maybe<Scalars['Float']['output']>;
  followerReach?: Maybe<Scalars['Int']['output']>;
  followerReachRate?: Maybe<Scalars['Float']['output']>;
  followers: Scalars['Int']['output'];
  forward?: Maybe<Scalars['Int']['output']>;
  impressionRate?: Maybe<Scalars['Float']['output']>;
  impressions?: Maybe<Scalars['Int']['output']>;
  impressionsFromDiscovery?: Maybe<Scalars['Int']['output']>;
  impressionsFromHashtag?: Maybe<Scalars['Int']['output']>;
  impressionsFromHome?: Maybe<Scalars['Int']['output']>;
  impressionsFromOther?: Maybe<Scalars['Int']['output']>;
  impressionsFromProfile?: Maybe<Scalars['Int']['output']>;
  interaction?: Maybe<Scalars['Int']['output']>;
  like?: Maybe<Scalars['Int']['output']>;
  linkClicks?: Maybe<Scalars['Int']['output']>;
  malePercentage?: Maybe<Scalars['Float']['output']>;
  navigation?: Maybe<Scalars['Int']['output']>;
  /** For admin or staff user, and EngagementCampaign */
  net?: Maybe<Scalars['Float']['output']>;
  newFollowers?: Maybe<Scalars['Int']['output']>;
  nextStory?: Maybe<Scalars['Int']['output']>;
  nonFollowerReach?: Maybe<Scalars['Int']['output']>;
  nonFollowerReachRate?: Maybe<Scalars['Float']['output']>;
  order?: Maybe<Scalars['Int']['output']>;
  profileActivity?: Maybe<Scalars['Int']['output']>;
  /** For admin or staff user, and EngagementCampaign */
  profit?: Maybe<Scalars['Float']['output']>;
  /** For admin or staff user, and EngagementCampaign */
  profitRate?: Maybe<Scalars['Float']['output']>;
  reach?: Maybe<Scalars['Int']['output']>;
  reachRate?: Maybe<Scalars['Float']['output']>;
  /** @deprecated please use sales_tune or sales_referral */
  sales?: Maybe<Scalars['Float']['output']>;
  salesReferral?: Maybe<Scalars['Float']['output']>;
  salesTune?: Maybe<Scalars['Float']['output']>;
  saved?: Maybe<Scalars['Int']['output']>;
  share?: Maybe<Scalars['Int']['output']>;
  stickerTaps?: Maybe<Scalars['Int']['output']>;
  totalPlayTime?: Maybe<Scalars['Int']['output']>;
  videoCompletionRate?: Maybe<Scalars['Float']['output']>;
  view?: Maybe<Scalars['Int']['output']>;
};

export type NewEngagementCampaignForInfluencerForYourJob = {
  __typename?: 'NewEngagementCampaignForInfluencerForYourJob';
  currency: Scalars['String']['output'];
  endDate: Scalars['Date']['output'];
  hasDraftPosts: Scalars['Boolean']['output'];
  /** Whether she uploaded her post on social media or not. */
  hasReport: Scalars['Boolean']['output'];
  hasWarningPosts: Scalars['Boolean']['output'];
  id: Scalars['Int']['output'];
  isTtcmCampaign: Scalars['Boolean']['output'];
  /** influencer revenue */
  rewardAmount?: Maybe<Scalars['Float']['output']>;
  socialMedias: Array<CampaignSocialMediaType>;
  startDate: Scalars['Date']['output'];
  status: AnyXEngagementCampaignStatus;
  thumbNail: Scalars['String']['output'];
  title: Scalars['String']['output'];
  ttcmStatus?: Maybe<TTCMStatus>;
  type: CampaignType;
};

export type NewMarketplaceCampaignForInfluencerForYourJob = {
  __typename?: 'NewMarketplaceCampaignForInfluencerForYourJob';
  /** exists when campaign type is SELECTION */
  appliedStatus?: Maybe<MarketplaceCampaignAppliedStatus>;
  campaignDetailType: MarketplaceCampaignDetailType;
  campaignStatus?: Maybe<CampaignStatusForInfluencer>;
  commissionRate?: Maybe<Scalars['Float']['output']>;
  currency: Scalars['String']['output'];
  endDate?: Maybe<Scalars['Date']['output']>;
  hasPostsCouponMissing?: Maybe<Scalars['Boolean']['output']>;
  /** Whether she uploaded her post on social media or not. */
  hasReport: Scalars['Boolean']['output'];
  id: Scalars['Int']['output'];
  /** For Selection Marketplace Campaign */
  isApplying?: Maybe<Scalars['Boolean']['output']>;
  isAutoInfluencerApproval?: Maybe<Scalars['Boolean']['output']>;
  isGiftingCampaign?: Maybe<Scalars['Boolean']['output']>;
  isPostTracking?: Maybe<Scalars['Boolean']['output']>;
  isSelectionCampaign?: Maybe<Scalars['Boolean']['output']>;
  mkpServerId?: Maybe<Scalars['Int']['output']>;
  preLaunchDate?: Maybe<Scalars['Date']['output']>;
  remainingAmount?: Maybe<Scalars['Float']['output']>;
  revenuePerAction?: Maybe<Scalars['Float']['output']>;
  revenuePerClick?: Maybe<Scalars['Float']['output']>;
  revenuePerComment?: Maybe<Scalars['Float']['output']>;
  revenuePerFollower?: Maybe<Scalars['Float']['output']>;
  revenuePerLike?: Maybe<Scalars['Float']['output']>;
  revenuePerOrder?: Maybe<Scalars['Float']['output']>;
  revenuePerPost?: Maybe<Scalars['Float']['output']>;
  revenuePerShare?: Maybe<Scalars['Float']['output']>;
  revenuePerView?: Maybe<Scalars['Float']['output']>;
  socialMedias: Array<CampaignSocialMediaType>;
  startDate: Scalars['Date']['output'];
  status?: Maybe<AnyXMarketplaceCampaignStatus>;
  thumbNail: Scalars['String']['output'];
  title: Scalars['String']['output'];
  totalAmount?: Maybe<Scalars['Float']['output']>;
  type: CampaignType;
};

export enum NotificationId {
  ENGAGEMENT_DRAFT_POST_APPROVED = 'ENGAGEMENT_DRAFT_POST_APPROVED',
  ENGAGEMENT_DRAFT_POST_REJECTED = 'ENGAGEMENT_DRAFT_POST_REJECTED',
  ENGAGEMENT_FETCHED = 'ENGAGEMENT_FETCHED',
  ENGAGEMENT_FINISH = 'ENGAGEMENT_FINISH',
  MARKETPLACE_CAMPAIGN_FINISHED = 'MARKETPLACE_CAMPAIGN_FINISHED',
  MARKETPLACE_CAMPAIGN_JOINED = 'MARKETPLACE_CAMPAIGN_JOINED',
  MARKETPLACE_CAMPAIGN_REACH_BUDGET = 'MARKETPLACE_CAMPAIGN_REACH_BUDGET',
  MARKETPLACE_CAMPAIGN_STARTED = 'MARKETPLACE_CAMPAIGN_STARTED',
  MARKETPLACE_CAMPAIGN_UPCOMING = 'MARKETPLACE_CAMPAIGN_UPCOMING',
  MARKETPLACE_CAMPAIGN_UPDATE = 'MARKETPLACE_CAMPAIGN_UPDATE',
  MARKETPLACE_DRAFT_POST_APPROVED = 'MARKETPLACE_DRAFT_POST_APPROVED',
  MARKETPLACE_DRAFT_POST_REJECTED = 'MARKETPLACE_DRAFT_POST_REJECTED',
  MARKETPLACE_SHIPPED = 'MARKETPLACE_SHIPPED',
  MP_CAMPAIGNS_APPLICANT_INFLS_APPROVED = 'MP_CAMPAIGNS_APPLICANT_INFLS_APPROVED',
  MP_CAMPAIGNS_APPLICANT_INFLS_INAPPROPRIATE = 'MP_CAMPAIGNS_APPLICANT_INFLS_INAPPROPRIATE',
  MP_CAMPAIGNS_APPLICANT_INFLS_REJECTED = 'MP_CAMPAIGNS_APPLICANT_INFLS_REJECTED',
  PAYMENT_COMPLETED = 'PAYMENT_COMPLETED',
  PAYMENT_FAILED = 'PAYMENT_FAILED',
  PAYMENT_REQUEST_DONE = 'PAYMENT_REQUEST_DONE',
}

/**  `NotificationPayload` payload consists of the data of a single notification. */
export type NotificationPayload = {
  __typename?: 'NotificationPayload';
  content: Scalars['JSON']['output'];
  created: Scalars['DateTime']['output'];
  id: Scalars['Long']['output'];
  isClicked: Scalars['Boolean']['output'];
  notificationId: NotificationId;
};

/**
 *  `checkpoint`: a checkpoint to get notifications beyond the checkpoint. A value of `null` can be provided to start from the latest notification.
 *  `appType`: a enum type which represents the notification scope of application
 */
export type NotificationsByAppInput = {
  appType: AppType;
  checkpoint?: InputMaybe<Scalars['Long']['input']>;
};

/**
 *  `checkpoint`: an offset used for the next query for the API to know where we left off. (Lookup keyset pagination for more info)
 *  `hasMore`: a boolean informing whether is there any more notifications available to grab
 */
export type NotificationsPayload = {
  __typename?: 'NotificationsPayload';
  checkpoint: Scalars['Long']['output'];
  hasMore: Scalars['Boolean']['output'];
  notifications: Array<NotificationPayload>;
};

/** An enumeration. */
export enum ORDER {
  ASC = 'ASC',
  DESC = 'DESC',
}

export type ObtainJSONWebTokenInput = {
  appName?: InputMaybe<AppName>;
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type ObtainJSONWebTokenPayload = {
  __typename?: 'ObtainJSONWebTokenPayload';
  /** Users's country's id */
  countryId?: Maybe<Scalars['String']['output']>;
  /** Users's country's currency id  */
  currencyId: Scalars['String']['output'];
  hash?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  refreshToken?: Maybe<Scalars['String']['output']>;
  role: UserRoles;
  token: Scalars['String']['output'];
  userId: Scalars['Int']['output'];
};

export type OperatedUserInfo = {
  __typename?: 'OperatedUserInfo';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type OptionInput = {
  optionTitle: Scalars['String']['input'];
  order: Scalars['Int']['input'];
};

export enum Order {
  ASC = 'ASC',
  DESC = 'DESC',
}

export type PackageCompany = {
  __typename?: 'PackageCompany';
  /** id of company */
  companyId: Scalars['Int']['output'];
  /** unique result index used for FE purpose */
  id?: Maybe<Scalars['Int']['output']>;
  /** name of company */
  name: Scalars['String']['output'];
  /** company role */
  role: UserRoles;
};

export type ParticipationForm = {
  __typename?: 'ParticipationForm';
  formName: Scalars['String']['output'];
  submissionId: Scalars['Long']['output'];
  submittedAt: Scalars['String']['output'];
};

export type Partner = {
  __typename?: 'Partner';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type PartnerAgencyUserWithRole = {
  __typename?: 'PartnerAgencyUserWithRole';
  companyName?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  role: UserRoles;
};

export type PartnerAgencyWithCompanies = {
  __typename?: 'PartnerAgencyWithCompanies';
  advertiserIds: Array<Scalars['Int']['output']>;
  agencyIds: Array<Scalars['Int']['output']>;
  companyUrl?: Maybe<Scalars['String']['output']>;
  country?: Maybe<CountryName>;
  id: Scalars['Int']['output'];
  influencerCostPermission: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  netsuiteId?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  privateInfluencers: Array<PrivatePartnerInfluencer>;
};

export type PartnerAgencyWithNSVerification = {
  __typename?: 'PartnerAgencyWithNSVerification';
  companyUrl?: Maybe<Scalars['String']['output']>;
  country?: Maybe<CountryName>;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  netsuiteId?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  users: Scalars['Int']['output'];
  verifiedStatus?: Maybe<NetSuiteVerifiedStatus>;
};

export type PartnerAgencyWithPermission = {
  __typename?: 'PartnerAgencyWithPermission';
  id: Scalars['Int']['output'];
  influencerCostPermission: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
};

/**  Stores a redirect URL that FE uses to reconnect partner influencer accounts */
export type PartnerReconnectAnalyticsSocialAuthRedirectUrlPayload = {
  __typename?: 'PartnerReconnectAnalyticsSocialAuthRedirectUrlPayload';
  redirectUri: Scalars['String']['output'];
};

export type PayableCampaign = {
  __typename?: 'PayableCampaign';
  campaignType: CampaignType;
  currency: Scalars['String']['output'];
  /** Marketplace OR AutoManaged campaign */
  id: Scalars['Int']['output'];
  revenue: Scalars['Float']['output'];
  socialMedia?: Maybe<SocialAccountType>;
  thumbNail: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type Payment = {
  __typename?: 'Payment';
  currency: Scalars['String']['output'];
  dueDate: Scalars['Date']['output'];
  fee: Scalars['Float']['output'];
  grossRevenue: Scalars['Float']['output'];
  id: Scalars['Int']['output'];
  influencer: PaymentInfluencerAccount;
  netRevenue: Scalars['Float']['output'];
  paidDate?: Maybe<Scalars['Date']['output']>;
  requestedDate: Scalars['Date']['output'];
  status: PaymentRequestStatus;
};

export type PaymentInfluencerAccount = {
  __typename?: 'PaymentInfluencerAccount';
  email: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type PaymentInformation = {
  __typename?: 'PaymentInformation';
  info?: Maybe<PaymentInformationDetails>;
  isEditable: Scalars['Boolean']['output'];
};

export type PaymentInformationDetails = {
  __typename?: 'PaymentInformationDetails';
  accountName: Scalars['String']['output'];
  accountNumber: Scalars['String']['output'];
  bankName: Scalars['String']['output'];
  branchName: Scalars['String']['output'];
};

export type PaymentInformationInput = {
  accountName: Scalars['String']['input'];
  accountNumber: Scalars['String']['input'];
  bankName: Scalars['String']['input'];
  branchName: Scalars['String']['input'];
};

/** An enumeration. */
export enum PaymentPlanType {
  ANALYTICS = 'ANALYTICS',
  MARKETPLACE = 'MARKETPLACE',
}

/** An enumeration. */
export enum PaymentPriceType {
  SIX_MONTHS = 'SIX_MONTHS',
  TWELVE_MONTHS = 'TWELVE_MONTHS',
}

export type PaymentRequest = {
  __typename?: 'PaymentRequest';
  currency: Scalars['String']['output'];
  fee: Scalars['Float']['output'];
  grossRevenue: Scalars['Float']['output'];
  minimumPaymentAmount: Scalars['Float']['output'];
};

/** An enumeration. */
export enum PaymentRequestStatus {
  FAILED = 'FAILED',
  PAID = 'PAID',
  REJECTED = 'REJECTED',
  REVIEWING = 'REVIEWING',
}

/** An enumeration. */
export enum PaymentRequestStatusForInfluencer {
  FAILED = 'FAILED',
  REJECTED = 'REJECTED',
  REVIEWING = 'REVIEWING',
  TRANSFERRED = 'TRANSFERRED',
}

export type PersonalUserDetailPayload = {
  __typename?: 'PersonalUserDetailPayload';
  crmNotificationSetting?: Maybe<Scalars['Boolean']['output']>;
  displayLanguage?: Maybe<DisplayLanguage>;
  eggNotificationSetting?: Maybe<Scalars['Boolean']['output']>;
  email: Scalars['String']['output'];
  mkpNotificationSetting?: Maybe<Scalars['Boolean']['output']>;
  name: Scalars['String']['output'];
  notificationSetting?: Maybe<Scalars['Boolean']['output']>;
};

export type PicName = {
  __typename?: 'PicName';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type PicUser = {
  __typename?: 'PicUser';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type PlainTextMessageInput = {
  text: Scalars['String']['input'];
};

export type PlainTextMessagePayload = {
  __typename?: 'PlainTextMessagePayload';
  text: Scalars['String']['output'];
};

export type PostByHashtag = {
  __typename?: 'PostByHashtag';
  hashtagId: Scalars['Int']['output'];
  posts: Array<PostsForHashtagDashboard>;
};

/** An enumeration. */
export enum PostListSortField {
  AGE = 'AGE',
  COMMENT = 'COMMENT',
  COUNTRY = 'COUNTRY',
  ENGAGEMENT = 'ENGAGEMENT',
  ENGAGEMENT_RATE = 'ENGAGEMENT_RATE',
  FOLLOWERS = 'FOLLOWERS',
  GENDER = 'GENDER',
  IMPRESSION = 'IMPRESSION',
  LIKE = 'LIKE',
  POST_DATE = 'POST_DATE',
  REACH = 'REACH',
  SAVED = 'SAVED',
  SHARE = 'SHARE',
  VIEW = 'VIEW',
}

export enum PostListSortFieldV3 {
  ENGAGEMENT_RATE = 'ENGAGEMENT_RATE',
  FOLLOWERS = 'FOLLOWERS',
  POST_DATE = 'POST_DATE',
  RELEVANT = 'RELEVANT',
}

export type PostOrderBy = {
  field?: InputMaybe<PostListSortField>;
  order?: InputMaybe<ORDER>;
};

export type PostOrderByV3 = {
  field?: InputMaybe<PostListSortFieldV3>;
  order?: InputMaybe<Order>;
};

export type PostsByTime = {
  __typename?: 'PostsByTime';
  date: Scalars['Date']['output'];
  postIds: Array<Scalars['Int']['output']>;
  postsCount: Scalars['Int']['output'];
};

export type PostsForHashtagDashboard = {
  __typename?: 'PostsForHashtagDashboard';
  id: Scalars['Int']['output'];
  likes: Scalars['Int']['output'];
  thumbnail: Scalars['String']['output'];
};

export type PostsMetricsGridReport = {
  __typename?: 'PostsMetricsGridReport';
  averageMetricRate?: Maybe<Scalars['Float']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  posts: Array<CampaignPostReportGrid>;
};

export type PricingTable = {
  __typename?: 'PricingTable';
  analytics: Array<PricingTableItem>;
  marketplace: Array<PricingTableItem>;
};

export type PricingTableItem = {
  __typename?: 'PricingTableItem';
  currency: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  features?: Maybe<Array<Scalars['String']['output']>>;
  /** price id */
  id: Scalars['Int']['output'];
  isCurrentPlan: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  price: Scalars['Float']['output'];
  priceType: PaymentPriceType;
  trialDays?: Maybe<Scalars['Int']['output']>;
};

export type PrivatePartnerInfluencer = {
  __typename?: 'PrivatePartnerInfluencer';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type PrivateTalentInfluencer = {
  __typename?: 'PrivateTalentInfluencer';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type PromotionMethodEmailNewsLetterForProfile = {
  __typename?: 'PromotionMethodEmailNewsLetterForProfile';
  description: Scalars['String']['output'];
  email: Scalars['String']['output'];
  id: Scalars['Int']['output'];
};

export type PromotionMethodEmailNewsLetterPayload = {
  __typename?: 'PromotionMethodEmailNewsLetterPayload';
  description: Scalars['String']['output'];
  email: Scalars['String']['output'];
  id: Scalars['ID']['output'];
};

export type PromotionMethodInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  method: CampaignPromotionMethod;
  mobileAppType?: InputMaybe<PromotionMobileAppType>;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type PromotionMethodMobileAppForProfile = {
  __typename?: 'PromotionMethodMobileAppForProfile';
  id: Scalars['Int']['output'];
  mobileAppType: PromotionMobileAppType;
  url: Scalars['String']['output'];
};

export type PromotionMethodMobileAppPayload = {
  __typename?: 'PromotionMethodMobileAppPayload';
  id: Scalars['ID']['output'];
  mobileAppType: PromotionMobileAppType;
  url?: Maybe<Scalars['String']['output']>;
};

export type PromotionMethodOfflineForProfile = {
  __typename?: 'PromotionMethodOfflineForProfile';
  description: Scalars['String']['output'];
  id: Scalars['Int']['output'];
};

export type PromotionMethodOfflinePayload = {
  __typename?: 'PromotionMethodOfflinePayload';
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
};

export type PromotionMethodPodCastForProfile = {
  __typename?: 'PromotionMethodPodCastForProfile';
  description: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  url: Scalars['String']['output'];
};

export type PromotionMethodPodCastPayload = {
  __typename?: 'PromotionMethodPodCastPayload';
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  url: Scalars['String']['output'];
};

export type PromotionMethodWebsiteForProfile = {
  __typename?: 'PromotionMethodWebsiteForProfile';
  description: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  url: Scalars['String']['output'];
};

export type PromotionMethodWebsitePayload = {
  __typename?: 'PromotionMethodWebsitePayload';
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  url: Scalars['String']['output'];
};

export enum PromotionMobileAppType {
  ANDROID = 'ANDROID',
  APPLE = 'APPLE',
}

export type ProposalInfluencer = {
  __typename?: 'ProposalInfluencer';
  accountList: Array<ProposalSocialAccount>;
  avatar?: Maybe<Scalars['String']['output']>;
  couponCode?: Maybe<Scalars['String']['output']>;
  deniedReason?: Maybe<Scalars['String']['output']>;
  /** Influencer id */
  id: Scalars['Int']['output'];
  isDenied?: Maybe<Scalars['Boolean']['output']>;
  jdMode: ProposalJobDescriptionBreakDownMode;
  /** Influencer name */
  name: Scalars['String']['output'];
  reasons?: Maybe<Scalars['String']['output']>;
  requirements?: Maybe<Scalars['String']['output']>;
  status: SocialAccountStatus;
  summary: ProposalJobSummary;
  ttcmCampaignCode?: Maybe<Scalars['String']['output']>;
  ttcmCampaignInviteLink?: Maybe<Scalars['String']['output']>;
  ttcmStatus?: Maybe<TTCMStatus>;
};

/** An enumeration. */
export enum ProposalJobDescriptionBreakDownMode {
  INFLUENCER_BREAKDOWN = 'INFLUENCER_BREAKDOWN',
  SOCIAL_BREAKDOWN = 'SOCIAL_BREAKDOWN',
}

export type ProposalJobSummary = {
  __typename?: 'ProposalJobSummary';
  adAgencyMarginRate?: Maybe<Scalars['Float']['output']>;
  adAgencyMarginValue?: Maybe<Scalars['Int']['output']>;
  averageEngagement?: Maybe<Scalars['Float']['output']>;
  budget?: Maybe<Scalars['Float']['output']>;
  engagementRate?: Maybe<Scalars['Float']['output']>;
  followersCount?: Maybe<Scalars['Int']['output']>;
  influencerCost?: Maybe<Scalars['Float']['output']>;
  marginRate?: Maybe<Scalars['Float']['output']>;
  netBudget?: Maybe<Scalars['Float']['output']>;
  posts?: Maybe<Scalars['Int']['output']>;
  profit?: Maybe<Scalars['Float']['output']>;
};

export type ProposalSocialAccount = {
  __typename?: 'ProposalSocialAccount';
  /** Social account id */
  id: Scalars['Int']['output'];
  /** Social account name */
  name?: Maybe<Scalars['String']['output']>;
  /** Social account type */
  socialMedia: EngagementProposalSocialMediaType;
  summary: ProposalJobSummary;
  ttcmCampaignInviteLink?: Maybe<Scalars['String']['output']>;
  ttcmStatus?: Maybe<TTCMStatus>;
};

export type Province = {
  __typename?: 'Province';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type ProvincesPayload = {
  __typename?: 'ProvincesPayload';
  provinces: Array<Province>;
};

export type PublicBioSection = {
  __typename?: 'PublicBioSection';
  id: Scalars['Int']['output'];
  image?: Maybe<BioImage>;
  link?: Maybe<BioUrlLink>;
  sectionType: BioSectionType;
  socialMedia?: Maybe<BioSocialMedia>;
  text?: Maybe<BioText>;
};

export type PublicBioTheme = {
  __typename?: 'PublicBioTheme';
  bg: PublicLinkBioBackground;
  button?: Maybe<BioButtonStyle>;
  color: PublicLinkBioPageColor;
  font?: Maybe<BioFontStyle>;
  theme?: Maybe<BioTheme>;
};

export type PublicLinkBioBackground = {
  __typename?: 'PublicLinkBioBackground';
  bgColor?: Maybe<Scalars['String']['output']>;
  media?: Maybe<Scalars['String']['output']>;
};

export type PublicLinkBioPageColor = {
  __typename?: 'PublicLinkBioPageColor';
  buttonColor?: Maybe<Scalars['String']['output']>;
  buttonFontColor?: Maybe<Scalars['String']['output']>;
  fontColor?: Maybe<Scalars['String']['output']>;
};

export type PublishInfluencerBioInput = {
  isPublic: Scalars['Boolean']['input'];
};

export type PublishInfluencerBioPayload = {
  __typename?: 'PublishInfluencerBioPayload';
  ok: Scalars['Boolean']['output'];
};

export type Query = {
  __typename?: 'Query';
  _service: _Service;
  /**
   *  Gets a specific account analytics based on the input id
   *  May be used by admin, staff, agency, and advertisers
   *  Multiple social accounts should be expected
   */
  accountAnalyticDetailsV2: AccountAnalyticsDetail;
  /**
   *  Returns basic information of an analytics account
   *  May be used by admin, staff, agency, and advertisers
   */
  accountAnalyticsBasicDetailsV2: BasicAccountAnalyticsPayload;
  address?: Maybe<Address>;
  addressForInfluencer?: Maybe<AddressForInfluencer>;
  advertiser?: Maybe<Advertiser>;
  advertiserForAdvertiserUser?: Maybe<AdvertiserForAdvertiserUser>;
  advertiserUserSettingsDetails?: Maybe<AdvertiserUserSettingsDetails>;
  agency?: Maybe<AgencyWithAdvertisers>;
  agencyBusinessAssociateCompanies?: Maybe<Array<AssociateCompany>>;
  agencyBusinessAssociateCompaniesCount?: Maybe<TotalNumber>;
  agencyLogo?: Maybe<AgencyLogo>;
  /**
   *  Returns the total number of analytic accounts.
   *  May be used by admin, staff, and agency
   */
  allAccountAnalyticsCountForAdminV2: TotalNumber;
  /**
   *  Returns a paginated list of analytic accounts.
   *  May be used by admin, staff, and agency
   *  Multiple social accounts should be expected
   */
  allAccountAnalyticsForAdminV2: AllAccountAnalyticsForAdminPayload;
  /**
   *  Returns a paginated list of analytic accounts.
   *  May be used by advertisers
   *  Multiple social accounts should be expected
   */
  allAccountAnalyticsForAdvertiserV2: AllAccountAnalyticsForAdvertiserPayload;
  allAdvertiserPics?: Maybe<Array<AdvertiserUserWithRole>>;
  allAdvertisers?: Maybe<Array<AdvertiserWithNSVerification>>;
  allAdvertisersCount?: Maybe<TotalNumber>;
  allAdvertisersForSearch: AllAdvertisersForSearch;
  allAgencies?: Maybe<Array<AgencyWithNSVerification>>;
  allAgenciesCount?: Maybe<TotalNumber>;
  allAgenciesForSearch?: Maybe<Array<AgenciesForSearch>>;
  allAgencyUsers?: Maybe<Array<AgencyUserWithRole>>;
  allAnalyticsSubscriptionPlans?: Maybe<Array<AnalyticsSubscriptionPlanName>>;
  allApplicantInfluencersForMarketplace?: Maybe<Array<ApplicantInfluencer>>;
  allApplicantInfluencersForMarketplaceCount?: Maybe<TotalNumber>;
  allAvailableCountries: Array<CountryAndCurrency>;
  allBankBranches?: Maybe<Array<Maybe<BankBranch>>>;
  allBankBranchesForInfluencer?: Maybe<Array<Maybe<BankBranchForInfluencer>>>;
  allBanks?: Maybe<Array<Maybe<Bank>>>;
  allBanksForInfluencer?: Maybe<Array<Maybe<BankForInfluencer>>>;
  allCampaignCategories?: Maybe<Array<CampaignCategoryName>>;
  allCampaignPosts: CampaignPosts;
  allCampaignsForFilter: CampaignForPostFilter;
  allCampaignsForSearch?: Maybe<Array<CampaignForSearch>>;
  allCampaignsForYourJob?: Maybe<Array<CampaignForInfluencerForYourJob>>;
  allCampaignsForYourJobCounts?: Maybe<CampaignForInfluencerForYourJobCounts>;
  allCampaignsReport?: Maybe<Array<CampaignDetailReport>>;
  allCampaignsSummary?: Maybe<AllCampaignsReportSummary>;
  allCategories?: Maybe<Array<MajorCategory>>;
  allCities?: Maybe<Array<Maybe<City>>>;
  allCitiesForInfluencer?: Maybe<Array<Maybe<CityForInfluencer>>>;
  allConnectedFacebookPages?: Maybe<Array<ConnectedFacebookPage>>;
  allCountries: Array<CountryAndCurrency>;
  allEngagementPosts?: Maybe<Array<EngagementPost>>;
  allEngagementPostsCount?: Maybe<TotalNumber>;
  allEngagementPostsForInfluencer?: Maybe<Array<EngagementPostForInfluencer>>;
  allEngagementPostsForInsightCheck?: Maybe<EngagementCampaignPostsForInsightCheck>;
  allEngagements?: Maybe<Array<Engagement>>;
  allEngagementsCount?: Maybe<TotalNumber>;
  allEngagementsForInsightCheck?: Maybe<EngagementsForInsightCheck>;
  allEngagementsForInsightCheckCount?: Maybe<EngagementsForInsightCheckCount>;
  allExistingPackages?: Maybe<Array<ReadPackage>>;
  allFacebookAccountInfluencers?: Maybe<Array<FacebookInfluencer>>;
  allFacebookAccountInfluencersCount?: Maybe<TotalNumber>;
  /**  Searches facebook accounts */
  allFacebookAccountInfluencersV2: FacebookAccountInfluencerSearchResultV2;
  allFacebookPageInfluencers?: Maybe<Array<FacebookPageInfluencer>>;
  allFacebookPageInfluencersCount?: Maybe<TotalNumber>;
  /**  Searches facebook pages */
  allFacebookPageInfluencersV2: FacebookPageInfluencerSearchResultV2;
  allInfluencerAccountPosts?: Maybe<Array<InfluencerSocialPost>>;
  allInfluencerAccountPostsCount?: Maybe<TotalNumber>;
  allInfluencerCategories?: Maybe<Array<CategoryName>>;
  allInfluencerManagers?: Maybe<Array<InfluencerManagerOutput>>;
  allInfluencerPostsV3: InfluencerPostsV3;
  allInfluencersCountForMarketplace?: Maybe<TotalNumber>;
  allInfluencersForMarketplaceV2?: Maybe<Array<CampaignInfluencerV2>>;
  /**  Searches for influencers */
  allInfluencersV4: AllInfluencerV4;
  allInstagramAmbassadorPosts?: Maybe<InstagramBrandAmbassadorPosts>;
  allInstagramAmbassadorPostsCount?: Maybe<TotalNumber>;
  /** Returns a list of feed posts for a specified compare account */
  allInstagramCompareFeedPosts?: Maybe<Array<InstagramCompareFeedPost>>;
  allInstagramCompareFeedPostsCount?: Maybe<TotalNumber>;
  /**
   * Returns a list of posts in a specific date(not a date range!)
   *         for the main account and compare accounts
   */
  allInstagramComparePostsInDate?: Maybe<InstagramAnalyticsSummaryPostsInDate>;
  /**
   * Returns Instagram feed posts for a specific analytics account
   *         that were posted in a specified period of time.
   *
   */
  allInstagramFeedPosts?: Maybe<Array<InstagramFeedPost>>;
  allInstagramFeedPostsCount?: Maybe<TotalNumber>;
  allInstagramHashtagFeedPosts?: Maybe<Array<InstagramHashtagFeedPost>>;
  allInstagramHashtagFeedPostsCount?: Maybe<TotalNumber>;
  /**  Searches instagram accounts */
  allInstagramInfluencersV3: InstagramInfluencerSearchResultV3;
  allInstagramInteractionUsers: InstagramInteractionUserAnalyticsDetailsAndCount;
  allInstagramMentionedPosts?: Maybe<Array<InstagramInteractionPost>>;
  allInstagramMentionedPostsCount?: Maybe<TotalNumber>;
  /**
   * Returns a list of Instagram story, reel and feed posts of a specific analytics account
   *         that were posted on a specific date .
   */
  allInstagramPostsInDate?: Maybe<InstagramAnalyticsPostsInDate>;
  /**
   * Returns Instagram reel posts for a specific analytics account
   *         that were posted in a specified period of time.
   *
   */
  allInstagramReelPosts?: Maybe<Array<InstagramReelPostItem>>;
  allInstagramReelPostsCount?: Maybe<TotalNumber>;
  /**  Searches similar instagram accounts */
  allInstagramSimilarInfluencersV3: Array<InstagramInfluencerV3>;
  /**
   * Returns Instagram story posts that were posted in a specified period of time
   *         for a specific analytics account.
   */
  allInstagramStoryPosts?: Maybe<Array<InstagramStoryPost>>;
  allInstagramStoryPostsCount?: Maybe<TotalNumber>;
  allInstagramTaggedPosts?: Maybe<Array<InstagramInteractionTaggedPost>>;
  allInstagramTaggedPostsCount?: Maybe<TotalNumber>;
  allMarketplacePosts?: Maybe<Array<MarketplacePost>>;
  allMarketplacePostsCount?: Maybe<TotalNumber>;
  allMarketplaces?: Maybe<Array<Marketplace>>;
  allMarketplacesCount?: Maybe<TotalNumber>;
  allMarketplacesForReorder?: Maybe<Array<MarketplaceForReorder>>;
  allNeedReviewMarketplacePosts?: Maybe<Array<NeedReviewMarketplacePost>>;
  allPackageCompanies?: Maybe<Array<PackageCompany>>;
  allPackages?: Maybe<Array<ReadPackageAccount>>;
  allPackagesCount?: Maybe<TotalNumber>;
  allPartnerAgenciesCount?: Maybe<TotalNumber>;
  allPartnerAgencyCompany?: Maybe<Array<PartnerAgencyWithNSVerification>>;
  allPartnerAgencyUsers?: Maybe<Array<PartnerAgencyUserWithRole>>;
  allPartners?: Maybe<Array<Partner>>;
  allPayments?: Maybe<Array<Payment>>;
  allPaymentsCount?: Maybe<TotalNumber>;
  allPicUsers?: Maybe<Array<PicUser>>;
  allRegions?: Maybe<Array<Maybe<Region>>>;
  allStaffs?: Maybe<Array<AllStaffDetail>>;
  allStaffsCount?: Maybe<TotalNumber>;
  allTalentAgencies?: Maybe<Array<TalentAgency>>;
  allTalentAgenciesCount?: Maybe<TotalNumber>;
  allTalentAgenciesPartners?: Maybe<Array<TalentAgencyOrPartner>>;
  allTalentAgencyCompany?: Maybe<Array<TalentAgencyWithNSVerification>>;
  allTalentAgencyUsers?: Maybe<Array<TalentAgencyUserWithRole>>;
  allThreadsInfluencers?: Maybe<Array<ThreadsInfluencer>>;
  allThreadsInfluencersCount?: Maybe<TotalNumber>;
  allTiktokInfluencers?: Maybe<Array<TiktokInfluencer>>;
  allTiktokInfluencersCount?: Maybe<TotalNumber>;
  allTiktokSimilarInfluencers?: Maybe<Array<TiktokInfluencer>>;
  allTwitterInfluencers?: Maybe<Array<TwitterInfluencer>>;
  allTwitterInfluencersCount?: Maybe<TotalNumber>;
  allTwitterSimilarInfluencers?: Maybe<Array<TwitterInfluencer>>;
  allUsers?: Maybe<Array<AllUserDetail>>;
  allUsersCount?: Maybe<TotalNumber>;
  allYoutubeCmsAssets?: Maybe<Array<YoutubeCmsAsset>>;
  allYoutubeCmsAssetsCount?: Maybe<TotalNumber>;
  allYoutubeCmsChannels?: Maybe<Array<YoutubeCmsChannel>>;
  allYoutubeCmsChannelsCount?: Maybe<TotalNumber>;
  /**  Searches youtube accounts */
  allYoutubeInfluencersV3: YoutubeInfluencerSearchResultV3;
  allYoutubeSimilarInfluencers?: Maybe<Array<YouTubeInfluencer>>;
  /**
   *  Returns a URL that FE uses to communicate with social network APIs.
   *  May be used by anyone with no authentication.
   */
  analyticsSocialAuthRedirectUrl?: Maybe<AnalyticsSocialAuthRedirectUrlPayload>;
  analyticsSubscriptionPlan?: Maybe<AnalyticsSubscriptionPlan>;
  analyticsSubscriptionPlanByAdvId?: Maybe<AnalyticsSubscriptionPlan>;
  anyXCheckConnectedSocialAccounts?: Maybe<AnyXCheckConnectedSocialAccountsPayload>;
  anyXCheckConnectedSocialAccountsForLinkBio?: Maybe<AnyXCheckConnectedSocialAccountsForLinkBioPayload>;
  autoManagedCommissionFixedDates?: Maybe<Array<Scalars['Date']['output']>>;
  autoManagedCommissionInfluencers?: Maybe<Array<AutoManagedCommissionInfluencer>>;
  autoManagedCommissionInfluencersCounts?: Maybe<TotalNumber>;
  autoManagedJoinedInfluencers?: Maybe<Array<AutoManagedJoinedInfluencer>>;
  autoManagedJoinedInfluencersCount?: Maybe<TotalNumber>;
  autoManagedJoinedMethods?: Maybe<AutoManagedJoinedMethodsPayload>;
  autoManagedReportByDate?: Maybe<Array<AutoManagedReportByDate>>;
  autoManagedReportByDateCount?: Maybe<TotalNumber>;
  autoManagedReportByInfluencer?: Maybe<Array<AutoManagedReportByInfluencer>>;
  autoManagedReportByInfluencerCount?: Maybe<TotalNumber>;
  autoManagedReportCountForInfluencer?: Maybe<TotalNumber>;
  autoManagedReportForInfluencer?: Maybe<Array<AutoManagedReportForInfluencer>>;
  autoManagedReportSummary?: Maybe<AutoManagedReportSummary>;
  autoManagedReportSummaryForInfluencer?: Maybe<AutoManagedReportSummaryForInfluencer>;
  billingInformation?: Maybe<BillingInformation>;
  brandAccounts?: Maybe<InfluencerBrandAccounts>;
  campaignHashtagCloud?: Maybe<Array<HashtagType>>;
  campaignsChartForAllTime: Array<CampaignChartReportForAllTime>;
  checkBioLinkNameAvailability?: Maybe<CheckBioLinkNameAvailabilityPayload>;
  checkoutSessionLink?: Maybe<StripeDeepLink>;
  connectableFacebookPages?: Maybe<ConnectableFacebookPagesPayload>;
  connectableFacebookPagesAndInstagramAccounts?: Maybe<ConnectableFacebookPagesAndInstagramAccountsPayload>;
  connectableInstagramAccounts?: Maybe<ConnectableInstagramAccountsPayload>;
  /**
   *  `countUnreadNotifications` query gets the count of notifications that are not read.
   *  The user is known thorugh the `Authorization` header.
   *  Read is defined as the occurence of the notification being rendered by front-end.
   */
  countUnreadNotifications?: Maybe<UnreadNotificationCountPayload>;
  /**
   *  `countUnreadNotificationsByApp` query gets the count of notifications that are not read of specific application.
   *  The user is known thorugh the `Authorization` header.
   *  Read is defined as the occurence of the notification being rendered by front-end.
   */
  countUnreadNotificationsByApp?: Maybe<UnreadNotificationCountByAppPayload>;
  creatorAddressInformation?: Maybe<CreatorAddressPayload>;
  customerPortalLink?: Maybe<StripeDeepLink>;
  engagement?: Maybe<EngagementDetail>;
  engagementCampaignPostReportAudienceBreakdown?: Maybe<EngagementCampaignPostReportAudienceBreakdown>;
  engagementCampaignPostReportForInfluencer?: Maybe<EngagementCampaignPostReportForInfluencer>;
  engagementCampaignReport?: Maybe<EngagementCampaignReport>;
  engagementCampaignReportGrid?: Maybe<CampaignGridReport>;
  engagementCampaignReportSummary?: Maybe<NewCampaignReportSummary>;
  engagementCampaignTrafficSource?: Maybe<Array<EngagementPostTrafficSource>>;
  /** Returns history list for a specific engagement draft post */
  engagementDraftPostHistory?: Maybe<EngagementDraftPostHistory>;
  /** Returns history list for a specific engagement draft post for influencer */
  engagementDraftPostHistoryForInfluencer?: Maybe<EngagementDraftPostHistoryForInfluencer>;
  engagementForInfluencer?: Maybe<EngagementDetailForInfluencer>;
  engagementHashtagCloud?: Maybe<Array<HashtagType>>;
  engagementPostComments?: Maybe<Array<EngagementPostComment>>;
  engagementPostDetail?: Maybe<EngagementPostDetail>;
  /**
   *
   *                                                            To fetch Engagement post details to show on Influencer platform
   *
   */
  engagementPostDetailForInfluencer?: Maybe<EngagementPostDetailForInfluencer>;
  engagementPostForInfluencerV2?: Maybe<EngagementPostDetailForInfluencerV2>;
  engagementPostInfluencers?: Maybe<Array<EngagementPostInfluencerWithSocialAccounts>>;
  engagementPostMetricsForInsightCheck?: Maybe<EngagementPostMetricsDataForInsightCheck>;
  engagementPostSocialAccountsForInfluencer?: Maybe<Array<EngagementPostSocialAccount>>;
  engagementPostTrafficSource?: Maybe<Array<EngagementPostTrafficSource>>;
  engagementPostV2?: Maybe<EngagementPostEditV2>;
  engagementProposal?: Maybe<EngagementProposal>;
  engagementTracking?: Maybe<EngagementTracking>;
  existingProfileTags?: Maybe<ExistingProfileTags>;
  /**
   * Returns Instagram feed posts for a specified external analytics account
   *             that were posted in a specified period of time.
   *
   */
  externalAllInstagramFeedPosts?: Maybe<Array<ExternalInstagramFeedPost>>;
  externalAllInstagramFeedPostsCount?: Maybe<TotalNumber>;
  /**
   * Returns a list of Instagram story, reel and feed posts that were posted on a specified date
   *             made by a given external analytics account.
   */
  externalAllInstagramPostsInDate?: Maybe<ExternalInstagramAnalyticsPostsInDate>;
  /**
   * Returns Instagram reel posts for a specified external analytics account
   *             that were posted in a specified period of time.
   *
   */
  externalAllInstagramReelPosts?: Maybe<Array<ExternalInstagramReelPost>>;
  externalAllInstagramReelPostsCount?: Maybe<TotalNumber>;
  /**
   * Returns Instagram story posts that were posted in a specified period of time
   *             for a specified external analytics account.
   */
  externalAllInstagramStoryPosts?: Maybe<Array<ExternalInstagramStoryPost>>;
  externalAllInstagramStoryPostsCount?: Maybe<TotalNumber>;
  /**
   *  Returns basic FB account details
   *  May be used by external users only
   */
  externalAnalyticsFacebookGetAccountDetails: ExternalAnalyticsFacebookGetAccountDetailsOutput;
  /**
   *  Checks if reconnection is needed
   *  May be used by external users only
   */
  externalAnalyticsFacebookGetTokenWarnings: ExternalAnalyticsFacebookGetTokenWarningsOutput;
  /**
   *  Returns basic IG account details
   *  May be used by external users only
   */
  externalAnalyticsInstagramGetAccountDetails: ExternalAnalyticsInstagramGetAccountDetailsOutput;
  /**
   *  Checks if reconnection is needed
   *  May be used by external users only
   */
  externalAnalyticsInstagramGetTokenWarnings: ExternalAnalyticsInstagramGetTokenWarningsOutput;
  /**
   *  Returns a URL that FE uses to communicate with social network APIs.
   *  May be used by external users only.
   */
  externalAnalyticsSocialAuthRedirectUrl?: Maybe<ExternalAnalyticsSocialAuthRedirectUrlPayload>;
  /**
   *  Returns the audience of the facebook page account by `facebookPageAnalyticsAccountId` within the specified period
   *  May be used by external users
   */
  externalFacebookPageAnalyticsAudience: FacebookPageAnalyticsAudiencePayload;
  /**  Returns the overview data of the facebook page account by `facebookPageAnalyticsAccountId` within the specified period */
  externalFacebookPageAnalyticsOverview: FacebookPageAnalyticsOverviewPayload;
  /**
   *  Returns a post specified by `facebookPageAnalyticsAccountId`
   *  May be used by external users only
   */
  externalFacebookPageAnalyticsPost: FacebookPageAnalyticsPostPayload;
  /**
   *  Returns a list of the posts made by the account `facebookPageAnalyticsAccountId`
   *  May be used by external users only
   */
  externalFacebookPageAnalyticsPosts: FacebookPageAnalyticsPostsPayloads;
  /**
   *  Returns the statistics of the posts by `facebookPageAnalyticsAccountId` within the specified period
   *  May be used by external users only
   */
  externalFacebookPageAnalyticsPostsAnalytics: FacebookPageAnalyticsPostsAnalyticsPayload;
  /**  Returns a list of the posts made by the account `facebookPageAnalyticsAccountId` by date */
  externalFacebookPageAnalyticsPostsByDate: FacebookPageAnalyticsPostsByDatePayload;
  /**
   *  Returns the total count of posts made by the account `facebookPageAnalyticsAccountId`
   *  May be used by external users
   */
  externalFacebookPageAnalyticsPostsCount: TotalNumber;
  /**
   * Returns details for a specified Instagram feed post. For example, the content,
   *             comments, and etc.
   *
   */
  externalInstagramAnalyticsPostDetails?: Maybe<ExternalInstagramAnalyticsPostDetails>;
  /** Returns details for a specific Instagram reel post. For external users only. */
  externalInstagramAnalyticsReelDetails?: Maybe<ExternalInstagramAnalyticsReelDetails>;
  /** Returns details of a specified Instagram story post made by an external analytics account. */
  externalInstagramAnalyticsStoryDetails?: Maybe<ExternalInstagramAnalyticsStoryDetails>;
  /**
   * Returns follower demographics analytics information for a specified external analytics account
   *             in a specified period of time. For example, age-gender distribution, followers countries, and etc.
   */
  externalInstagramDashboardFollowerAnalytics?: Maybe<ExternalInstagramDashboardFollowerAnalytics>;
  /**
   * Returns the main analytics information for a specified external analytics account
   *             in a specified period of time. For example, the number of followers, posting frequency/activity,
   *             comments growth, and etc.
   */
  externalInstagramDashboardOverview?: Maybe<ExternalInstagramDashboardOverview>;
  /**
   * Returns Instagram posts analytics data for a specified external analytics account
   *             in a specified period of time. For example, posting habits, last feed and story posts, and etc.
   */
  externalInstagramDashboardPostAnalytics?: Maybe<ExternalInstagramDashboardPostAnalytics>;
  /**
   *  Returns the audience of the youtube account by `youtubeAnalyticsAccountId` within the specified period
   *  May be used by external
   */
  externalYoutubeAnalyticsAudience: ExternalYoutubeAnalyticsAudiencePayload;
  /**
   *  Returns the overview data of the youtube account by `youtubeAnalyticsAccountId` within the specified period
   *  May be used by external
   */
  externalYoutubeAnalyticsOverview: ExternalYoutubeAnalyticsOverviewPayload;
  /**
   *  Returns a post specified by `youtubeAnalyticsPostId`
   *  May be used by external
   *  Views can be larger than 32-bit, please keep note
   */
  externalYoutubeAnalyticsPost: ExternalYoutubePostPayload;
  /**
   *  Returns a list of the posts made by the account `youtubeAnalyticsAccountId`
   *  May be used by external
   *  Views can be larger than 32-bit, please keep note
   */
  externalYoutubeAnalyticsPosts: ExternalYoutubeAnalyticsPostListPayload;
  /**  Returns posts for external user for account `socialAccountId` */
  externalYoutubeAnalyticsPostsByDate: ExternalYoutubeAnalyticsPostListByDatePayload;
  /**
   *  Returns the total count of posts made by the account `youtubeAnalyticsAccountId`
   *  May be used by external
   */
  externalYoutubeAnalyticsPostsCount: TotalNumber;
  /**
   *  Returns the statistics of the posts by `youtubeAnalyticsAccountId` within the specified period
   *  May be used by external
   */
  externalYoutubeAnalyticsPostsStatistics: ExternalYoutubeAnalyticsPostsStatisticsPayload;
  /**
   *  Returns the related posts with the same tag of the same analytics account
   *  May be used by external
   *  `youtubeAnalyticsPostId` is the id of the current shown post. This will be excluded from the result
   */
  externalYoutubeAnalyticsRelatedPosts: ExternalYoutubeAnalyticsRelatedPostsPayload;
  /**
   *  Returns a paginated list of the tags sorted by the specified field.
   *  May be used by external
   *  views can be larger than 32-bit, please keep note.
   */
  externalYoutubeAnalyticsTagsTopRanking: ExternalYoutubeAnalyticsTagRankingPayloads;
  /**
   *  Returns the total count of the tags specified by the inputs
   *  May be used by external
   *  views can be larger than 32-bit, please keep note.
   */
  externalYoutubeAnalyticsTagsTopRankingCount: TotalNumber;
  /**
   *  Returns the audience of the facebook page account by `facebookPageAnalyticsAccountId` within the specified period
   *  May be used by admin, staff, agency, and advertisers
   */
  facebookPageAnalyticsAudience: FacebookPageAnalyticsAudiencePayload;
  /**  Returns the overview data of the facebook page account by `facebookPageAnalyticsAccountId` within the specified period */
  facebookPageAnalyticsOverview: FacebookPageAnalyticsOverviewPayload;
  /**
   *  Returns a post specified by `facebookPageAnalyticsAccountId`
   *  May be used by admin, staff, agency, and advertisers
   */
  facebookPageAnalyticsPost: FacebookPageAnalyticsPostWithPageDetailsPayload;
  /**
   *  Returns a list of the posts made by the account `facebookPageAnalyticsAccountId`
   *  May be used by admin, staff, agency, and advertisers
   */
  facebookPageAnalyticsPosts: FacebookPageAnalyticsPostsPayloads;
  /**
   *  Returns the statistics of the posts by `facebookPageAnalyticsAccountId` within the specified period
   *  May be used by admin, staff, agency, and advertisers
   */
  facebookPageAnalyticsPostsAnalytics: FacebookPageAnalyticsPostsAnalyticsPayload;
  /**  Returns a list of the posts made by the account `facebookPageAnalyticsAccountId` by date */
  facebookPageAnalyticsPostsByDate: FacebookPageAnalyticsPostsByDatePayload;
  /**
   *  Returns the total count of posts made by the account `facebookPageAnalyticsAccountId`
   *  May be used by admin, staff, agency, and advertisers
   */
  facebookPageAnalyticsPostsCount: TotalNumber;
  findJoinedCampaigns?: Maybe<Array<JoinedCampaign>>;
  generateAgencyLogoSignedUrl: FileUploadUrls;
  generateImportFansUploadURL?: Maybe<GenerateImportFansUploadURLPayload>;
  generateMaterialSignedUrl: FileUploadUrls;
  generatePostFileSignedUrl?: Maybe<SignedUrlData>;
  generatePostFileSignedUrls: FileUploadUrls;
  generateThumbnailSignedUrl: FileUploadUrls;
  generateUploadReportSignedUrl: FileUploadUrls;
  getAccountInformation?: Maybe<GetAccountInformationPayload>;
  getAllCampaignsCountForSearchJob?: Maybe<GetAllCampaignsCountForSearchJobPayload>;
  getAllCampaignsForSearchJob?: Maybe<GetAllCampaignsForSearchJobPayload>;
  getAnyXAccountsByKeyword?: Maybe<GetAnyXAccountsByKeywordPayload>;
  getAutoManagedCampaignById?: Maybe<AutoManagedCampaignPayload>;
  /**  query to get AM campaign detail from search jobs. Id input is remote campaign id */
  getAutoManagedCampaignForInfluencerSearchJob?: Maybe<AutoManagedCampaignForInfluencerSearchJobPayload>;
  /**  query to get AM campaign detail from your jobs. Id input is local campaign id */
  getAutoManagedCampaignForInfluencerYourJob?: Maybe<AutoManagedCampaignForInfluencerYourJobPayload>;
  getAutoManagedCampaigns?: Maybe<GetAutoManagedCampaignsPayload>;
  getAutoManagedCampaignsCount?: Maybe<GetAutoManagedCampaignsCountPayload>;
  getAvailableInfluencersForStaff?: Maybe<AvailableInfluencersForStaffPayload>;
  getChatEvents?: Maybe<GetChatEventsPayload>;
  getCouponsList?: Maybe<Array<Scalars['String']['output']>>;
  getCreatorStaffs?: Maybe<CreatorStaffsPayload>;
  getCreatorStaffsCount?: Maybe<CreatorStaffsCountPayload>;
  getCreatorTags?: Maybe<GetCreatorTagsPayload>;
  getCreatorTagsById?: Maybe<GetCreatorTagsByIdPayload>;
  getCurrentUserCurrency: CurrentUserCurrency;
  getCustomerTags?: Maybe<GetCustomerTagsPayload>;
  getEmailList?: Maybe<GetEmailListPayload>;
  getEmailListCount?: Maybe<GetEmailListCountPayload>;
  getEmailOverview?: Maybe<GetEmailOverviewPayload>;
  getEmailRecipients?: Maybe<GetEmailRecipientsPayload>;
  getEmailRecipientsCount?: Maybe<GetEmailRecipientsCountPayload>;
  getEmailTemplate?: Maybe<GetEmailTemplatePayload>;
  getFan?: Maybe<GetFanPayload>;
  getFanActivities?: Maybe<GetFanActivitiesPayload>;
  getFanCountries?: Maybe<CountriesPayload>;
  getFanFormResponse?: Maybe<GetFanFormResponsePayload>;
  getFanHeader?: Maybe<GetFanHeaderPayload>;
  getFanOrders?: Maybe<GetFanOrdersPayload>;
  getFanParticipationFormList?: Maybe<GetFanParticipationFormListPayload>;
  getFanProvinces?: Maybe<ProvincesPayload>;
  getFans?: Maybe<GetFansPayload>;
  getFansCount?: Maybe<GetFansCountPayload>;
  getForm?: Maybe<GetFormInputPayload>;
  getFormReportSummary?: Maybe<FormReportSummaryPayload>;
  getFormRespondentsCount?: Maybe<FormRespondentsCountPayload>;
  getFormRespondentsList?: Maybe<FormRespondentsListPayload>;
  getFormResponse?: Maybe<GetFormResponsePayload>;
  getFormResponseByPage?: Maybe<GetFormResponsePayload>;
  getFormTemplate?: Maybe<GetFormTemplatePayload>;
  getFormsCount?: Maybe<GetFormsCountPayload>;
  getFormsList?: Maybe<GetFormsListPayload>;
  getInfluencerBio?: Maybe<GetInfluencerBioPayload>;
  getInfluencerBioAnalytics?: Maybe<GetInfluencerBioAnalyticsPayload>;
  getInfluencerBioV2?: Maybe<GetInfluencerBioPayloadV2>;
  getInfluencerChatServices?: Maybe<GetInfluencerChatServicesPayload>;
  getInfluencerRateCard?: Maybe<InfluencerRateCardPayload>;
  getInfluencerRateCardForInfluencer?: Maybe<InfluencerRateCardPayload>;
  getLineBroadcastEstimateRecipients?: Maybe<GetLineBroadcastEstimateRecipientsPayload>;
  getLineBroadcastMessage?: Maybe<GetLineBroadcastMessagePayload>;
  getLineBroadcastSummary?: Maybe<GetLineBroadcastSummaryPayload>;
  getLineBroadcasts?: Maybe<GetLineBroadcastsPayload>;
  getLineBroadcastsCount?: Maybe<GetLineBroadcastsCountPayload>;
  getLineChannels?: Maybe<GetLineChannelPayload>;
  getLineChat?: Maybe<GetLineChatPayload>;
  getLineChatCount?: Maybe<GetLineChatCountPayload>;
  getLineChats?: Maybe<GetLineChatsPayload>;
  getLineLoginDetail?: Maybe<GetLineLoginDetailPayload>;
  getLinePublicKey?: Maybe<GetLinePublicKeyPayload>;
  getLinkBioFacebookAccountPosts?: Maybe<GetLinkBioFacebookAccountPostsPayload>;
  getLinkBioFacebookPagePosts?: Maybe<GetLinkBioFacebookPagePostsPayload>;
  getLinkBioThemeColor?: Maybe<GetLinkBioThemeColorPayload>;
  getMarketplaceCampaignForInfluencer?: Maybe<MarketplaceDetailForInfluencer>;
  getMessageTemplates?: Maybe<GetMessageTemplatesPayload>;
  /**
   *  `getNotifications` query gets the notificataions of an  influencer user.
   *  The user is known through the `Authorization` header.
   */
  getNotifications?: Maybe<NotificationsPayload>;
  /**
   *  `getNotificationsByApp` query gets the notificataions a specific application type of an  influencer user.
   *  The user is known through the `Authorization` header.
   */
  getNotificationsByApp?: Maybe<NotificationsPayload>;
  getPersonalUserDetail?: Maybe<PersonalUserDetailPayload>;
  getPublicInfluencerBio?: Maybe<GetPublicInfluencerBioPayload>;
  getReadTutorials?: Maybe<ReadTutorials>;
  getShopifyAccount?: Maybe<GetShopifyAccountPayload>;
  /**  Returns required permissions for each social network */
  getSocialAccountPermissions: Array<Scalars['String']['output']>;
  getTagsAndFansRecipients?: Maybe<GetTagsAndFansRecipientsPayload>;
  getUserDefaultPermissions?: Maybe<UserDefaultPermissions>;
  getUserDetail?: Maybe<UserDetailPayload>;
  getUserPermissions?: Maybe<UserDefaultPermissions>;
  getYoutubeMCNRedirectUrlForAdmin?: Maybe<YoutubeMCNAdminRedirectUrlPayload>;
  hubspotCompanyList?: Maybe<Array<HubspotCompanyData>>;
  hubspotCompanyName?: Maybe<HubspotCompanyData>;
  hubspotDealName?: Maybe<Array<HubspotDealDataForSearch>>;
  hubspotDealsList?: Maybe<Array<HubspotDealData>>;
  /**
   * Returns Instagram feed posts for a specified influencer analytics account
   *             that were posted in a specified period of time.
   *
   */
  influencerAllInstagramFeedPosts?: Maybe<Array<InstagramFeedPost>>;
  influencerAllInstagramFeedPostsCount?: Maybe<TotalNumber>;
  influencerAllInstagramInteractionUsers: InstagramInteractionUserAnalyticsDetailsAndCount;
  influencerAllInstagramMentionedPosts?: Maybe<Array<InstagramInteractionPost>>;
  influencerAllInstagramMentionedPostsCount?: Maybe<TotalNumber>;
  /**
   * Returns a list of Instagram story, reel and feed posts that were posted on a specified date
   *             made by a given influencer analytics account.
   */
  influencerAllInstagramPostsInDate?: Maybe<InstagramAnalyticsPostsInDate>;
  /**
   * Returns Instagram reel posts for a specified influencer analytics account
   *             that were posted in a specified period of time.
   *
   */
  influencerAllInstagramReelPosts?: Maybe<Array<InstagramReelPostItem>>;
  influencerAllInstagramReelPostsCount?: Maybe<TotalNumber>;
  /**
   * Returns Instagram story posts that were posted in a specified period of time
   *             for a specified influencer analytics account.
   */
  influencerAllInstagramStoryPosts?: Maybe<Array<InstagramStoryPost>>;
  influencerAllInstagramStoryPostsCount?: Maybe<TotalNumber>;
  influencerAllInstagramTaggedPosts?: Maybe<Array<InstagramInteractionTaggedPost>>;
  influencerAllInstagramTaggedPostsCount?: Maybe<TotalNumber>;
  /**  Checks if reconnection is needed + provides data for successfull reconnection for influencers */
  influencerAnalyticsFacebookPageAccessTokenWarning: InfluencerFacebookPageAnalyticsAccessTokenWarningPayload;
  /**  Checks if reconnection is needed + provides data for successfull reconnection for influencers */
  influencerAnalyticsInstagramAccessTokenWarning: InfluencerInstagramAnalyticsAccessTokenWarningPayload;
  /**  Checks if reconnection is needed + provides data for successfull reconnection for multiple influencer IG accounts */
  influencerAnalyticsMultipleInstagramAccessTokenWarning: InfluencerAnalyticsMultipleInstagramAccessTokenWarningPayload;
  /**  Checks if reconnection is needed for twitter accounts */
  influencerAnalyticsTwitterAccessTokenWarning: InfluencerTwitterAnalyticsAccessTokenWarningPayload;
  /**
   *  Returns true if twitter account available to update
   *  May be used by admin, staff, agency, advertisers, and influencers
   */
  influencerAnalyticsTwitterRefreshAvailability: InfluencerAnalyticsTwitterRefreshAvailabilityPayload;
  /** List of joined campaign for influencers */
  influencerCampaignList?: Maybe<Array<InfluencerCampaignDetail>>;
  /** List of joined MKP and Auto-Managed campaign for influencers independently by promotion method id */
  influencerCampaignListByPromotionMethod?: Maybe<Array<InfluencerCampaignDetailByPromotion>>;
  /** campaigns summary for influencer */
  influencerCampaignSummary: InfluencerCampaignSummary;
  influencerFacebookPageAnalyticsAudience: FacebookPageAnalyticsAudiencePayload;
  /**
   *  Returns the overview data of the influencer facebook page account by `socialAccountId` and `influencerId`
   *  within the specified period
   */
  influencerFacebookPageAnalyticsOverview: FacebookPageAnalyticsOverviewPayload;
  /**  Returns a post specified by `socialAccountId`, `influencerId` and `postId` */
  influencerFacebookPageAnalyticsPost: FacebookPageAnalyticsPostWithPageDetailsPayload;
  /**  Returns a list of the posts made by the account specified by `socialAccountId`, `influencerId` and `postId` */
  influencerFacebookPageAnalyticsPosts: FacebookPageAnalyticsPostsPayloads;
  /**
   *  Returns the statistics of the posts by `influencerFacebookPageAnalyticsPostsAnalytics` within the specified period
   *  May be used by admin, staff, agency, and advertisers
   */
  influencerFacebookPageAnalyticsPostsAnalytics: FacebookPageAnalyticsPostsAnalyticsPayload;
  /**  Returns a list of the posts made by the account specified by `socialAccountId`, `influencerId` and by date */
  influencerFacebookPageAnalyticsPostsByDate: FacebookPageAnalyticsPostsByDatePayload;
  /**  Returns the total count of posts made by the account specified by `socialAccountId`, `influencerId` and `postId` */
  influencerFacebookPageAnalyticsPostsCount: TotalNumber;
  /** checking social account has estimate api */
  influencerHasEstimateApi: InfluencerHasEstimateApi;
  /**
   * Returns details for a specified Instagram feed post. For example, the content,
   *             comments, and etc.
   */
  influencerInstagramAnalyticsPostDetails?: Maybe<InstagramAnalyticsPostDetails>;
  /** Returns details for a specific Instagram reel post. For influencers only. */
  influencerInstagramAnalyticsReelDetails?: Maybe<InstagramAnalyticsReelDetails>;
  /** Returns details of a specified Instagram story post made by an influencer analytics account. */
  influencerInstagramAnalyticsStoryDetails?: Maybe<InstagramAnalyticsStoryDetails>;
  /**
   * Returns follower demographics analytics information for a specified influencer analytics account
   *             in a specified period of time. For example, age-gender distribution, followers countries, and etc.
   */
  influencerInstagramDashboardFollowerAnalytics?: Maybe<InstagramDashboardFollowerAnalytics>;
  /**
   * Returns the main analytics information for a specified influencer analytics account
   *             in a specified period of time. For example, the number of followers, posting frequency/activity,
   *             comments growth, and etc.
   */
  influencerInstagramDashboardOverview?: Maybe<InstagramDashboardOverview>;
  /**
   * Returns Instagram posts analytics data for a specified influencer analytics account
   *             in a specified period of time. For example, posting habits, last feed and story posts, and etc.
   */
  influencerInstagramDashboardPostAnalytics?: Maybe<InstagramDashboardPostAnalytics>;
  influencerInstagramInteractionMentionedPostDetails?: Maybe<InstagramInteractionPostDetails>;
  influencerInstagramInteractionOverview?: Maybe<InstagramInteractionOverview>;
  influencerInstagramInteractionPosts?: Maybe<InstagramInteractionPosts>;
  influencerInstagramInteractionTaggedPostDetails?: Maybe<InstagramInteractionPostDetails>;
  influencerInterests?: Maybe<Array<InfluencerInterest>>;
  influencerPaymentInformation?: Maybe<InfluencerPaymentInformation>;
  influencerPaymentRequests: InfluencerPaymentRequests;
  influencerPostDetail?: Maybe<InfluencerPostDetail>;
  influencerPostDetailForTalentAgency?: Maybe<InfluencerPostDetail>;
  influencerProfileAudience?: Maybe<InfluencerAudienceSection>;
  influencerProfileDetailForEngagementProposal?: Maybe<InfluencerProfileDetailForEngagementProposal>;
  influencerProfileDetailV2?: Maybe<InfluencerNewProfileDetail>;
  influencerProfileFollowerGrowth?: Maybe<InfluencerFollowerGrowthSection>;
  influencerProfileForEngagementProposal?: Maybe<InfluencerProfileForEngagementProposal>;
  influencerProfilePosts?: Maybe<InfluencerPostSection>;
  influencerProfileV2?: Maybe<InfluencerNewProfileHeaderV2>;
  /**  query to return list of influencer connected promotion methods */
  influencerPromotionMethods?: Maybe<InfluencerPromotionMethodsPayload>;
  /**
   *  Returns the overview data of the twitter account with specified `socialAccountId` and specified period
   *  May be used by admin, staff, agency, advertisers, influencers
   */
  influencerTwitterAnalyticsOverview: TwitterAnalyticsOverviewPayload;
  /**
   *  Returns a post specified by `socialAccountId` and `twitterAnalyticsPostId`
   *  May be used by admin, staff, agency, and advertisers, influencers
   */
  influencerTwitterAnalyticsPost: TwitterAnalyticsPostByIdPayload;
  /**
   *  Returns a list of the posts made by the account with specified `socialAccountId`
   *  May be used by admin, staff, agency, advertisers, influencers
   */
  influencerTwitterAnalyticsPosts: TwitterAnalyticsPostsPayload;
  /**  Returns the posts that are made on the date */
  influencerTwitterAnalyticsPostsByDate: TwitterAnalyticsPostsByDatePayload;
  /**  Returns the total count of posts made for influencer account `socialAccountId` */
  influencerTwitterAnalyticsPostsCount: TotalNumber;
  /**  Posts section for twitter dashboard for influencer account `socialAccountId` */
  influencerTwitterAnalyticsPostsStatistic?: Maybe<TwitterAnalyticsPostsStatisticPayload>;
  influencerUpdateDetailV2?: Maybe<InfluencerUpdateDetailV2>;
  influencerWhitelist?: Maybe<InfluencerWhitelist>;
  /**  Returns the audience of the youtube account by `socialAccountId` within the specified period */
  influencerYoutubeAnalyticsAudience: YoutubeAnalyticsAudiencePayload;
  /**
   *  Returns basic details of a youtube channel by a given 'socialAccountId'.
   *  May be used for influencers only
   */
  influencerYoutubeAnalyticsChannelDetails: InfluencerYoutubeAnalyticsChannelDetailsPayload;
  /**  Returns a list of Youtube accounts compared with account `socialAccountId`. For influencers only. */
  influencerYoutubeAnalyticsCompareAccountCards: InfluencerYoutubeAnalyticsCompareAccountCards;
  /**
   *  Returns the related posts with the same tag of the same compare account. For influencers only
   *  `postId` is the id of the current shown post. This will be excluded from the result
   */
  influencerYoutubeAnalyticsCompareRelatedPosts: YoutubeAnalyticsCompareRelatedPosts;
  /**  Returns the overview data of the youtube account by `socialAccountId` within the specified period for influencers */
  influencerYoutubeAnalyticsOverview: YoutubeAnalyticsOverviewPayload;
  /**
   *  Returns a post specified by `socialAccountId` for influencers
   *  Views can be larger than 32-bit, please keep note
   */
  influencerYoutubeAnalyticsPost: YoutubeAnalyticsPostWithChannelDetailsPayload;
  /**  Returns a list of the posts made by the account `socialAccountId` for influencers */
  influencerYoutubeAnalyticsPosts: YoutubeAnalyticsPostsPayloads;
  /**  Returns the posts that are made on the date for influencers */
  influencerYoutubeAnalyticsPostsByDate: YoutubeAnalyticsPostPayloads;
  /**  Returns the total count of posts made by the account `socialAccountId` for influencers */
  influencerYoutubeAnalyticsPostsCount: TotalNumber;
  /**  Returns the statistics of the posts by `socialAccountId` within the specified period for influencers */
  influencerYoutubeAnalyticsPostsStatistics: YoutubeAnalyticsPostsStatisticsPayload;
  /**
   *  Returns the related posts with the same tag of the same analytics account
   *  `youtubeAnalyticsPostId` is the id of the current shown post. This will be excluded from the result. For influencers.
   */
  influencerYoutubeAnalyticsRelatedPosts: YoutubeAnalyticsRelatedPostsPayload;
  /**  Returns a list of the posts made by the account `socialAccountId` for influencers */
  influencerYoutubeAnalyticsShorts: YoutubeAnalyticsShortsPayloads;
  /**  Returns the total count of shorts made by the account `socialAccountId` for influencers */
  influencerYoutubeAnalyticsShortsCount: TotalNumber;
  influencerYoutubeAnalyticsSponsoredPosts: InfluencerAnalyticsSponsoredPostPayload;
  influencerYoutubeAnalyticsSponsoredPostsCount: InfluencerAnalyticsSponsoredPostCountPayload;
  /**
   *  Returns a paginated list of the tags sorted by the specified field.
   *  For influencers.
   *  views can be larger than 32-bit, please keep note.
   */
  influencerYoutubeAnalyticsTagsTopRanking: YoutubeAnalyticsTagRankingPayloads;
  /**
   *  Returns the total count of the tags specified by the inputs
   *  For influencers.
   *  views can be larger than 32-bit, please keep note.
   */
  influencerYoutubeAnalyticsTagsTopRankingCount: TotalNumber;
  /**  Returns all Youtube compare posts in date. For influencers only. */
  influencerYoutubeCompareAllPostsInDate?: Maybe<InfluencerYoutubeCompareAllPostsInDate>;
  /**  Returns the Youtube overview data for the main analytics account and compare accounts. For influencers only. */
  influencerYoutubeCompareOverview: InfluencerYoutubeCompareOverview;
  /**  Returns a compare youtube post by id. For influencers only. */
  influencerYoutubeComparePostById?: Maybe<YoutubeComparePost>;
  /**  Returns Youtube compare posts with pagination. For influencers only. */
  influencerYoutubeComparePostList: YoutubeComparePostList;
  /**  Returns comparable posts for Youtube. For influencers only. */
  influencerYoutubeComparePosts: InfluencerYoutubeComparePosts;
  /**  Returns the total count of compare posts. For influencers only. */
  influencerYoutubeComparePostsCount: TotalNumber;
  /**  Returns the Youtube compare accounts tag ranking data. For influencers only. */
  influencerYoutubeCompareTagRanking: InfluencerYoutubeCompareTagRanking;
  instagramAccountInfo?: Maybe<InstagramAnalyticsAccountInfoHeader>;
  instagramAmbassadorPostDetails?: Maybe<InstagramBrandAmbassadorPostDetails>;
  instagramAnalyticsHashtags?: Maybe<InstagramAnalyticsHashtags>;
  /**
   *  Returns a list of comments of `instagramAnalyticsAccountId` account, comments of posts where it mentioned,
   *  comments where it mentioned, and regular comments
   *  May be used by admin, staff, agency, and advertisers
   */
  instagramAnalyticsListComments: InstagramAnalyticsGetCommentsPayload;
  /**
   *  Returns the total count of comments of `instagramAnalyticsAccountId` account, comments of posts where it mentioned
   *  comments where it mentioned, and regular comments
   *  May be used by admin, staff, agency, and advertisers
   */
  instagramAnalyticsListCommentsCount: TotalNumber;
  /**
   * Returns details for a specific Instagram feed post. For example, the content,
   *         comments, and etc.
   *
   */
  instagramAnalyticsPostDetails?: Maybe<InstagramAnalyticsPostDetails>;
  /** Returns details for a specific Instagram reel post. */
  instagramAnalyticsReelDetails?: Maybe<InstagramAnalyticsReelDetails>;
  /** Returns details for a specific Instagram story post. */
  instagramAnalyticsStoryDetails?: Maybe<InstagramAnalyticsStoryDetails>;
  instagramCompareAccounts: InstagramCompareAccountsPayload;
  /** Returns followers age-gender breakdown data for the main account and compare accounts */
  instagramCompareFollowers?: Maybe<InstagramCompareFollowers>;
  /** Returns a list of accounts to compare */
  instagramCompareGetAccounts?: Maybe<InstagramAnalyticsCompareAccounts>;
  /** Returns the overview data for the main analytics account and compare accounts */
  instagramCompareOverview?: Maybe<InstagramCompareOverview>;
  /** Returns details of a specific post of a compare account */
  instagramComparePostDetails?: Maybe<InstagramComparePostDetails>;
  /**
   * Returns posts analytics data(posting habits, recent posts, popular hashtags, and etc)
   *         for the main account and compare accounts
   */
  instagramComparePosts?: Maybe<InstagramComparePosts>;
  /**
   * Returns follower demographics analytics information for a specific analytics account
   *         in a specified period of time. For example, age-gender distribution, followers countries, and etc.
   */
  instagramDashboardFollowerAnalytics?: Maybe<InstagramDashboardFollowerAnalytics>;
  /**
   * Returns the main analytics information for a specific analytics account
   *         in a specified period of time. For example, the number of followers, posting frequency/activity,
   *         comments growth, and etc.
   */
  instagramDashboardOverview?: Maybe<InstagramDashboardOverview>;
  /**
   * Returns Instagram posts analytics data for a specific analytics account
   *         in a specified period of time. For example, posting habits, last feed and story posts, and etc.
   */
  instagramDashboardPostAnalytics?: Maybe<InstagramDashboardPostAnalytics>;
  instagramHashtagFeedPostsV2: InstagramHashtagFeedPostsPayload;
  instagramHashtagPostDetails?: Maybe<InstagramHashtagPostDetails>;
  instagramHashtagPosts?: Maybe<InstagramHashtagPosts>;
  instagramHashtagPostsV2: InstagramHashtagPostsPayload;
  instagramHashtagsOverview?: Maybe<InstagramHashtagsOverview>;
  instagramInteractionMentionedPostDetails?: Maybe<InstagramInteractionPostDetails>;
  instagramInteractionOverview?: Maybe<InstagramInteractionOverview>;
  instagramInteractionPosts?: Maybe<InstagramInteractionPosts>;
  instagramInteractionTaggedPostDetails?: Maybe<InstagramInteractionPostDetails>;
  /**  Returns basic info for UGC management: ig username, added hashtags */
  instagramUGCManagementBasicInfo: InstagramUGCManagementBasicInfoPayload;
  /**  Returns UGC posts */
  instagramUGCManagementListPosts: InstagramUGCManagementListPostsPayload;
  /**  Returns UGC posts' count. Used for pagination */
  instagramUGCManagementListPostsCount: InstagramUGCManagementListPostsCountPayload;
  /**  Returns UGC post's details */
  instagramUGCManagementPostDetails: InstagramUGCManagementPostDetailsPayload;
  /** Tells if an influencer belongs to UUUM */
  isInfluencerUuum?: Maybe<IsInfluencerUUUM>;
  logo?: Maybe<Logo>;
  marketplace?: Maybe<MarketplaceDetail>;
  marketplaceAffiliateCommissionFixedDates?: Maybe<Array<Scalars['Date']['output']>>;
  marketplaceAffiliateCommissionInfluencers?: Maybe<Array<MarketplaceAffiliateCommissionInfluencer>>;
  marketplaceAffiliateCommissionInfluencersCount?: Maybe<TotalNumber>;
  marketplaceAffiliateJoinedInfluencers?: Maybe<Array<MarketplaceAffiliateJoinedInfluencer>>;
  marketplaceAffiliateJoinedInfluencersCount?: Maybe<TotalNumber>;
  marketplaceAffiliateReportByDate?: Maybe<Array<MarketplaceAffiliateReportByDate>>;
  marketplaceAffiliateReportByDateCount?: Maybe<TotalNumber>;
  marketplaceAffiliateReportByInfluencer?: Maybe<Array<MarketplaceAffiliateReportByInfluencer>>;
  marketplaceAffiliateReportByInfluencerCount?: Maybe<TotalNumber>;
  marketplaceAffiliateReportCountForInfluencer?: Maybe<TotalNumber>;
  marketplaceAffiliateReportForInfluencer?: Maybe<Array<MarketplaceAffiliateReportForInfluencer>>;
  marketplaceAffiliateReportSummary?: Maybe<MarketplaceAffiliateReportSummary>;
  marketplaceAffiliateReportSummaryForInfluencer?: Maybe<MarketplaceAffiliateReportSummaryForInfluencer>;
  marketplaceCampaignPostReportForInfluencer?: Maybe<MarketplaceCampaignPostReportForInfluencer>;
  marketplaceCampaignReport?: Maybe<MarketplaceCampaignReport>;
  marketplaceCampaignReportGrid?: Maybe<CampaignGridReport>;
  marketplaceCampaignReportSummary?: Maybe<NewCampaignReportSummary>;
  marketplaceDraftPost?: Maybe<MarketplaceDraftPostDetail>;
  marketplaceDraftPostsForInfluencer?: Maybe<Array<MarketplaceDraftPostForInfluencer>>;
  marketplaceForInfluencer?: Maybe<MarketplaceDetailForInfluencer>;
  marketplaceGoogleSlidesHistory?: Maybe<Array<GoogleSlideFile>>;
  marketplaceHashtagCloud?: Maybe<Array<HashtagType>>;
  marketplaceJoinableAccounts?: Maybe<MarketplaceJoinableAccountsPayload>;
  marketplaceJoinablePromotionMethods?: Maybe<MarketplaceJoinablePromotionMethodsPayload>;
  marketplaceJoinedAccount?: Maybe<MarketplaceJoinedAccountPayload>;
  marketplaceJoinedMethods?: Maybe<MarketplaceJoinedMethodsPayload>;
  marketplacePost?: Maybe<MarketplacePostDetail>;
  marketplacePostComments?: Maybe<Array<MarketplacePostComment>>;
  marketplacePostHistory?: Maybe<Array<MarketplacePostHistory>>;
  marketplaceTracking?: Maybe<MarketplaceTracking>;
  /**
   *
   *         Returns Marketplace tracking post url of its influencer if campaign is on-going and post is not fetched
   *
   */
  marketplaceTrackingPostUrlForInfluencer?: Maybe<MarketplaceTrackingPostUrlForInfluencer>;
  needReconnectForFacebook?: Maybe<NeedReconnectForFacebookPayload>;
  netsuiteCustomerList?: Maybe<Array<NetSuiteCustomerData>>;
  netsuiteCustomerName?: Maybe<NetSuiteCustomerDataForSearch>;
  netsuiteVendorList?: Maybe<Array<NetSuiteVendorData>>;
  netsuiteVendorName?: Maybe<NetSuiteVendorDataForSearch>;
  package?: Maybe<ReadPackage>;
  packageInfluencers?: Maybe<Array<ReadPackageInfluencers>>;
  partnerAgency?: Maybe<PartnerAgencyWithCompanies>;
  partnerAgencyBusinessAssociates?: Maybe<Array<AssociateCompany>>;
  partnerAgencyBusinessAssociatesCount?: Maybe<TotalNumber>;
  partnerAgencyPermission?: Maybe<PartnerAgencyWithPermission>;
  /**
   *  Returns a URL that FE uses to reconnect partner influencer accounts
   *  May be used by anyone with no authentication.
   */
  partnerReconnectAnalyticsSocialAuthRedirectUrl?: Maybe<PartnerReconnectAnalyticsSocialAuthRedirectUrlPayload>;
  /**
   *  Returns a post specified by `partnerInfluencerAccountId` for partner influencers
   *  Views can be larger than 32-bit, please keep note
   */
  partnerYoutubeAnalyticsPost: YoutubeAnalyticsPostPayload;
  payableCampaign?: Maybe<Array<PayableCampaign>>;
  paymentInformation?: Maybe<PaymentInformation>;
  paymentRequest?: Maybe<PaymentRequest>;
  pricingTable?: Maybe<PricingTable>;
  refreshProfileAccountAvailability?: Maybe<RefreshProfileAccountAvailabilityPayload>;
  refreshTwitterAccountAvailability?: Maybe<RefreshTwitterAccountAvailabilityPayload>;
  reportForInfluencer?: Maybe<Array<ReportForInfluencer>>;
  requestedBcaBrands?: Maybe<Array<RequestedBCABrand>>;
  searchEngCampaignsSelectInfluencers?: Maybe<Array<EngagementCampaignSearchSelectInfluencers>>;
  searchEngCampaignsSelectInfluencersCount?: Maybe<TotalNumber>;
  /**
   * Searches for private partner influencers by a keyword and partner id.
   *         Already added influencers are excluded
   */
  searchPrivatePartnerInfluencers?: Maybe<Array<PrivatePartnerInfluencer>>;
  /**
   * Searches for private talent influencers by a keyword and talent agency id.
   *         Already added influencers are excluded
   */
  searchPrivateTalentInfluencers?: Maybe<Array<PrivateTalentInfluencer>>;
  selectableBcaBrands?: Maybe<SelectableBCABrands>;
  shopifyAuthRedirectUrl?: Maybe<ShopifyAuthRedirectUrlPayload>;
  socialAuthCheckFacebookToken?: Maybe<SocialAuthCheckFacebookTokenPayload>;
  /**  Returns a simplified redirect URL that FE uses to communicate with social network APIs for AnyTag/AnyCreator. */
  socialAuthRedirectUrlForInfluencer?: Maybe<SocialAuthRedirectUrlForInfluencerPayload>;
  sponsoredPosts?: Maybe<InfluencerSponsoredPostResponse>;
  sponsoredPostsCount?: Maybe<InfluencerSponsoredPostCountResponse>;
  subscriptionPlan?: Maybe<SubscriptionPlan>;
  talentAgency?: Maybe<TalentAgencyWithCompanies>;
  talentAgencyBusinessAssociateCompanies?: Maybe<Array<AssociateCompany>>;
  talentAgencyBusinessAssociateCompaniesCount?: Maybe<TotalNumber>;
  /**
   *  Returns a URL that FE uses to reconnect talent influencer accounts
   *  May be used by anyone with no authentication.
   */
  talentAgencyReconnectAnalyticsSocialAuthRedirectUrl?: Maybe<TalentAgencyReconnectAnalyticsSocialAuthRedirectUrlPayload>;
  /**  Returns Talent Influencer details for Edit page including connected social accounts */
  talentInfluencerDetailsForEdit?: Maybe<TalentInfluencerDetailsForEditPayload>;
  tiktokProfileAudience?: Maybe<TiktokAudienceSection>;
  totalInfluencerReport?: Maybe<TotalInfluencerReport>;
  /**  Returns trending facebook influencers */
  trendingFacebookInfluencers: TrendingFacebookInfluencerSearchResult;
  /**  Returns trending instagram influencers */
  trendingInstagramInfluencersV2: TrendingInstagramInfluencerSearchResultV2;
  /**  Returns trending youtube influencers */
  trendingYoutubeInfluencersV2: TrendingYoutubeInfluencerSearchResultV2;
  /**
   *  Returns the overview data of the twitter account by `twitterAnalyticsAccountId` within the specified period
   *  May be used by admin, staff, agency, and advertisers
   */
  twitterAnalyticsOverview: TwitterAnalyticsOverviewPayload;
  /**
   *  Returns a post specified by `twitterAnalyticsAccountId`
   *  May be used by admin, staff, agency, and advertisers
   */
  twitterAnalyticsPost: TwitterAnalyticsPostByIdPayload;
  /**
   *  Returns a list of the posts made by the account `twitterAnalyticsAccountId`
   *  May be used by admin, staff, agency, and advertisers
   */
  twitterAnalyticsPosts: TwitterAnalyticsPostsPayload;
  /**
   *  Returns the posts that are made on the date
   *  May be used by admin, staff, agency, and advertisers
   */
  twitterAnalyticsPostsByDate: TwitterAnalyticsPostsByDatePayload;
  /**
   *  Returns the total count of posts made by the account `twitterAnalyticsAccountId`
   *  May be used by admin, staff, agency, and advertisers
   */
  twitterAnalyticsPostsCount: TotalNumber;
  /**
   *  Posts section for twitter dashboard for account `twitterAnalyticsAccountId`
   *  May be used by admin, staff, agency, and advertisers
   */
  twitterAnalyticsPostsStatistic?: Maybe<TwitterAnalyticsPostsStatisticPayload>;
  updatePaymentMethodLink?: Maybe<StripeDeepLink>;
  user?: Maybe<UserDetail>;
  /** List of influencer word cloud */
  wordCloudList?: Maybe<Array<InfluencerWordCloud>>;
  /**
   *  Returns the audience of the youtube account by `youtubeAnalyticsAccountId` within the specified period
   *  May be used by admin, staff, agency, and advertisers
   */
  youtubeAnalyticsAudience: YoutubeAnalyticsAudiencePayload;
  /**
   *  Returns a list of the comments made at videos of the account `youtubeAnalyticsAccountId`
   *  May be used by admin, staff, agency, and advertisers
   */
  youtubeAnalyticsComments: YoutubeAnalyticsCommentsPayload;
  /**
   *  Returns the total count of comments made at videos of the account `youtubeAnalyticsAccountId`
   *  May be used by admin, staff, agency, and advertisers
   */
  youtubeAnalyticsCommentsCount: TotalNumber;
  /**
   *  Returns a list of Youtube accounts compared with account `mainAccountId`
   *  May be used by admin, agency, staff, advertiser, talent_agency, partner
   */
  youtubeAnalyticsCompareAccountCards: YoutubeAnalyticsCompareAccountCards;
  /**
   *  Returns the related posts with the same tag of the same compare account
   *  May be used by administrator, agency, staff, advertiser, talent_agency, partner.
   *  `postId` is the id of the current shown post. This will be excluded from the result
   */
  youtubeAnalyticsCompareRelatedPosts: YoutubeAnalyticsCompareRelatedPosts;
  youtubeAnalyticsCountriesCategories: YoutubeAnalyticsCountriesCategoriesPayload;
  /**
   *  Returns the overview data of the youtube account by `youtubeAnalyticsAccountId` within the specified period
   *  May be used by admin, staff, agency, and advertisers
   */
  youtubeAnalyticsOverview: YoutubeAnalyticsOverviewPayload;
  /**
   *  Returns a post specified by `youtubeAnalyticsPostId`
   *  May be used by admin, staff, agency, and advertisers
   *  Views can be larger than 32-bit, please keep note
   */
  youtubeAnalyticsPost: YoutubeAnalyticsPostWithChannelDetailsPayload;
  /**
   *  Returns a list of the posts made by the account `youtubeAnalyticsAccountId`
   *  May be used by admin, staff, agency, and advertisers
   *  Views can be larger than 32-bit, please keep note
   */
  youtubeAnalyticsPosts: YoutubeAnalyticsPostsPayloads;
  /**
   *  Returns the posts that are made on the date
   *  May be used by admin, staff, agency, and advertisers
   */
  youtubeAnalyticsPostsByDate: YoutubeAnalyticsPostPayloads;
  /**
   *  Returns the total count of posts made by the account `youtubeAnalyticsAccountId`
   *  May be used by admin, staff, agency, and advertisers
   */
  youtubeAnalyticsPostsCount: TotalNumber;
  /**
   *  Returns the statistics of the posts by `youtubeAnalyticsAccountId` within the specified period
   *  May be used by admin, staff, agency, and advertisers
   */
  youtubeAnalyticsPostsStatistics: YoutubeAnalyticsPostsStatisticsPayload;
  /**
   *  Returns the related posts with the same tag of the same analytics account
   *  May be used by admin, staff, agency, and advertisers
   *  `youtubeAnalyticsPostId` is the id of the current shown post. This will be excluded from the result
   */
  youtubeAnalyticsRelatedPosts: YoutubeAnalyticsRelatedPostsPayload;
  youtubeAnalyticsShorts: YoutubeAnalyticsShortsPayloads;
  youtubeAnalyticsShortsCount: TotalNumber;
  /**
   *  Returns a paginated list of the tags sorted by the specified field.
   *  May be used by admin, staff, agency, and advertisers
   *  views can be larger than 32-bit, please keep note.
   */
  youtubeAnalyticsTagsTopRanking: YoutubeAnalyticsTagRankingPayloads;
  /**
   *  Returns the total count of the tags specified by the inputs
   *  May be used by admin, staff, agency, and advertisers
   *  views can be larger than 32-bit, please keep note.
   */
  youtubeAnalyticsTagsTopRankingCount: TotalNumber;
  /**
   *  Returns a trending post by id
   *  May be used by any logged in user
   */
  youtubeAnalyticsTrendPostById: YoutubeAnalyticsTrendPostDetailsPayload;
  /**
   *  Returns a list of the trends for country `country` and category `category` in date `date`
   *  sorted by position in chart from 1 to 100
   *  May be used by any logged in user
   */
  youtubeAnalyticsTrends: YoutubeAnalyticsTrendsPayloads;
  /**
   *  Returns count of the trends for country `country` and category `category` in date `date`
   *  May be used by any logged in user
   */
  youtubeAnalyticsTrendsCount: TotalNumber;
  youtubeBrandAccounts?: Maybe<YouTubeBrandAccounts>;
  youtubeCmsAssetsSummary?: Maybe<YoutubeCmsChannelSummary>;
  youtubeCmsChannelDetail?: Maybe<YoutubeCmsChannelDetail>;
  youtubeCmsChannelInfo?: Maybe<Array<YoutubeCmsChannelInfo>>;
  youtubeCmsChannelsForInfluencer: Array<YoutubeCmsChannelForInfluencer>;
  youtubeCmsChannelsSummary?: Maybe<YoutubeCmsChannelSummary>;
  youtubeCmsMusicsForInfluencer: Array<YoutubeCmsMusicForInfluencer>;
  youtubeCmsRevenueForInfluencer?: Maybe<TotalCmsRevenue>;
  youtubeCmsVideosForInfluencer: Array<YoutubeCmsVideo>;
  /**
   *  Returns all Youtube compare posts in date
   *  May be used by administrator, agency, staff, advertiser, talent_agency, partner.
   */
  youtubeCompareAllPostsInDate?: Maybe<YoutubeCompareAllPostsInDate>;
  /**
   *  Returns the Youtube overview data for the main analytics account and compare accounts
   *  May be used by administrator, agency, staff, advertiser, talent_agency, partner.
   */
  youtubeCompareOverview: YoutubeCompareOverview;
  /**  Returns a compare youtube post by id */
  youtubeComparePostById?: Maybe<YoutubeComparePost>;
  /**
   *  Returns the Youtube compare posts with pagination
   *  May be used by administrator, agency, staff, advertiser, talent_agency, partner.
   */
  youtubeComparePostList: YoutubeComparePostList;
  /**
   *  Returns comparable posts for Youtube
   *  May be used by administrator, agency, staff, advertiser, talent_agency, partner.
   */
  youtubeComparePosts: YoutubeComparePosts;
  /**  Returns the total count of compare posts made for the account `compareAccountId` */
  youtubeComparePostsCount: TotalNumber;
  /**
   *  Returns the Youtube compare accounts tag ranking data
   *  May be used by administrator, agency, staff, advertiser, talent_agency, partner.
   */
  youtubeCompareTagRanking: YoutubeCompareTagRanking;
};

export type QueryaccountAnalyticDetailsV2Args = {
  id: Scalars['Int']['input'];
};

export type QueryaccountAnalyticsBasicDetailsV2Args = {
  brandAccountId: Scalars['Int']['input'];
};

export type QueryaddressArgs = {
  influencerId: Scalars['Int']['input'];
};

export type QueryadvertiserArgs = {
  pk: Scalars['Int']['input'];
};

export type QueryagencyArgs = {
  pk: Scalars['Int']['input'];
};

export type QueryagencyBusinessAssociateCompaniesArgs = {
  agencyId: Scalars['Int']['input'];
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
};

export type QueryagencyBusinessAssociateCompaniesCountArgs = {
  agencyId: Scalars['Int']['input'];
  keyword?: InputMaybe<Scalars['String']['input']>;
};

export type QueryagencyLogoArgs = {
  hash: Scalars['String']['input'];
};

export type QueryallAccountAnalyticsCountForAdminV2Args = {
  advertiserId?: InputMaybe<Scalars['Int']['input']>;
  countryId?: InputMaybe<Scalars['String']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  subscriptionPlan?: InputMaybe<AnalyticsSubscriptionPlanType>;
};

export type QueryallAccountAnalyticsForAdminV2Args = {
  advertiserId?: InputMaybe<Scalars['Int']['input']>;
  countryId?: InputMaybe<Scalars['String']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  subscriptionPlan?: InputMaybe<AnalyticsSubscriptionPlanType>;
};

export type QueryallAccountAnalyticsForAdvertiserV2Args = {
  keyword?: InputMaybe<Scalars['String']['input']>;
  subscriptionPlan?: InputMaybe<AnalyticsSubscriptionPlanType>;
};

export type QueryallAdvertiserPicsArgs = {
  advertiserId: Scalars['Int']['input'];
};

export type QueryallAdvertisersArgs = {
  countryIds?: InputMaybe<Array<Scalars['String']['input']>>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  verifiedStatus?: InputMaybe<NetSuiteVerifiedStatus>;
};

export type QueryallAdvertisersCountArgs = {
  countryIds?: InputMaybe<Array<Scalars['String']['input']>>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  verifiedStatus?: InputMaybe<NetSuiteVerifiedStatus>;
};

export type QueryallAdvertisersForSearchArgs = {
  countryId?: InputMaybe<Scalars['String']['input']>;
  includeIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  keyword?: InputMaybe<Scalars['String']['input']>;
};

export type QueryallAgenciesArgs = {
  countryIds?: InputMaybe<Array<Scalars['String']['input']>>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  verifiedStatus?: InputMaybe<NetSuiteVerifiedStatus>;
};

export type QueryallAgenciesCountArgs = {
  countryIds?: InputMaybe<Array<Scalars['String']['input']>>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  verifiedStatus?: InputMaybe<NetSuiteVerifiedStatus>;
};

export type QueryallAgencyUsersArgs = {
  agencyId: Scalars['Int']['input'];
};

export type QueryallApplicantInfluencersForMarketplaceArgs = {
  appliedStatus?: InputMaybe<MarketplaceCampaignAppliedStatus>;
  categoryIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  genders?: InputMaybe<Array<Genders>>;
  id: Scalars['Int']['input'];
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Int']['input'];
  maxAge?: InputMaybe<Scalars['Int']['input']>;
  maxEngagementRate?: InputMaybe<Scalars['Float']['input']>;
  maxFollowers?: InputMaybe<Scalars['Int']['input']>;
  minAge?: InputMaybe<Scalars['Int']['input']>;
  minEngagementRate?: InputMaybe<Scalars['Float']['input']>;
  minFollowers?: InputMaybe<Scalars['Int']['input']>;
  offset: Scalars['Int']['input'];
  orderBy?: InputMaybe<InfluencerOrderBy>;
};

export type QueryallApplicantInfluencersForMarketplaceCountArgs = {
  appliedStatus?: InputMaybe<MarketplaceCampaignAppliedStatus>;
  categoryIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  genders?: InputMaybe<Array<Genders>>;
  id: Scalars['Int']['input'];
  keyword?: InputMaybe<Scalars['String']['input']>;
  maxAge?: InputMaybe<Scalars['Int']['input']>;
  maxEngagementRate?: InputMaybe<Scalars['Float']['input']>;
  maxFollowers?: InputMaybe<Scalars['Int']['input']>;
  minAge?: InputMaybe<Scalars['Int']['input']>;
  minEngagementRate?: InputMaybe<Scalars['Float']['input']>;
  minFollowers?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryallBankBranchesArgs = {
  bankId: Scalars['Int']['input'];
};

export type QueryallBankBranchesForInfluencerArgs = {
  bankId: Scalars['Int']['input'];
};

export type QueryallBanksArgs = {
  countryId: Scalars['String']['input'];
};

export type QueryallCampaignPostsArgs = {
  advertiserIds: Array<Scalars['Int']['input']>;
  campaignTypes: Array<CampaignIdentifierType>;
  campaigns: Array<CampaignIdentifierInput>;
  categoryIds: Array<Scalars['Int']['input']>;
  countryIds: Array<Scalars['String']['input']>;
  influencerIds: Array<Scalars['Int']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Int']['input'];
  maxPostDate?: InputMaybe<Scalars['Date']['input']>;
  minPostDate?: InputMaybe<Scalars['Date']['input']>;
  offset: Scalars['Int']['input'];
  orderBy?: InputMaybe<CampaignPostOrderBy>;
  socialPostTypes: Array<CampaignPostSocialType>;
};

export type QueryallCampaignsForFilterArgs = {
  campaignTypes?: InputMaybe<Array<CampaignType>>;
  campaigns?: InputMaybe<Array<CampaignFilter>>;
};

export type QueryallCampaignsForSearchArgs = {
  campaignTypes?: InputMaybe<Array<CampaignType>>;
};

export type QueryallCampaignsForYourJobArgs = {
  isFinished: Scalars['Boolean']['input'];
};

export type QueryallCampaignsReportArgs = {
  campaignCategoryIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  campaignTypes?: InputMaybe<Array<CampaignType>>;
  campaigns?: InputMaybe<Array<CampaignFilter>>;
  countryId?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['Date']['input']>;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  orderBy?: InputMaybe<CampaignReportOrderBy>;
  socialMedias?: InputMaybe<Array<CampaignSocialMediaType>>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
};

export type QueryallCampaignsSummaryArgs = {
  campaignCategoryIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  campaignTypes?: InputMaybe<Array<CampaignType>>;
  campaigns?: InputMaybe<Array<CampaignFilter>>;
  countryId?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['Date']['input']>;
  socialMedias?: InputMaybe<Array<CampaignSocialMediaType>>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
};

export type QueryallCitiesArgs = {
  countryId: Scalars['String']['input'];
};

export type QueryallConnectedFacebookPagesArgs = {
  pk: Scalars['Int']['input'];
};

export type QueryallEngagementPostsArgs = {
  engagementId: Scalars['Int']['input'];
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  status?: InputMaybe<EngagementCampaignPostStatusForSearch>;
};

export type QueryallEngagementPostsCountArgs = {
  engagementId: Scalars['Int']['input'];
  keyword?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<EngagementCampaignPostStatusForSearch>;
};

export type QueryallEngagementPostsForInfluencerArgs = {
  engagementId: Scalars['Int']['input'];
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
};

export type QueryallEngagementPostsForInsightCheckArgs = {
  campaignId: Scalars['Int']['input'];
  status?: InputMaybe<EngagementCampaignPostStatusForInsightCheck>;
};

export type QueryallEngagementsArgs = {
  campaignCategoryIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  countryIds?: InputMaybe<Array<Scalars['String']['input']>>;
  hubspotDealIds?: InputMaybe<Array<Scalars['String']['input']>>;
  influencerPicIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  salesPicIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  status?: InputMaybe<EngagementCampaignStatus>;
  tiktokCampaignTypes?: InputMaybe<Array<TikTokCampaignType>>;
};

export type QueryallEngagementsCountArgs = {
  campaignCategoryIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  countryIds?: InputMaybe<Array<Scalars['String']['input']>>;
  hubspotDealIds?: InputMaybe<Array<Scalars['String']['input']>>;
  influencerPicIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  salesPicIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  status?: InputMaybe<EngagementCampaignStatus>;
  tiktokCampaignTypes?: InputMaybe<Array<TikTokCampaignType>>;
};

export type QueryallEngagementsForInsightCheckArgs = {
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  status?: InputMaybe<EngagementCampaignPostStatusForInsightCheck>;
};

export type QueryallEngagementsForInsightCheckCountArgs = {
  keyword?: InputMaybe<Scalars['String']['input']>;
};

export type QueryallFacebookAccountInfluencersArgs = {
  audience?: InputMaybe<SocialAccountInfluencerAudience>;
  categoryIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  countryIds?: InputMaybe<Array<Scalars['String']['input']>>;
  genders?: InputMaybe<Array<Genders>>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  keywordField?: InputMaybe<InfluencerSearchKeywordField>;
  limit: Scalars['Int']['input'];
  maxAge?: InputMaybe<Scalars['Int']['input']>;
  maxEngagementRate?: InputMaybe<Scalars['Float']['input']>;
  maxFollowers?: InputMaybe<Scalars['Int']['input']>;
  maxPostPrice?: InputMaybe<Scalars['Float']['input']>;
  minAge?: InputMaybe<Scalars['Int']['input']>;
  minEngagementRate?: InputMaybe<Scalars['Float']['input']>;
  minFollowers?: InputMaybe<Scalars['Int']['input']>;
  minPostPrice?: InputMaybe<Scalars['Float']['input']>;
  offset: Scalars['Int']['input'];
  orderBy?: InputMaybe<SocialAccountInfluencerOrderBy>;
  partnerIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  regionIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  status?: InputMaybe<SocialAccountStatus>;
  talentAgencyIds?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type QueryallFacebookAccountInfluencersCountArgs = {
  audience?: InputMaybe<SocialAccountInfluencerAudience>;
  categoryIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  countryIds?: InputMaybe<Array<Scalars['String']['input']>>;
  genders?: InputMaybe<Array<Genders>>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  keywordField?: InputMaybe<InfluencerSearchKeywordField>;
  maxAge?: InputMaybe<Scalars['Int']['input']>;
  maxEngagementRate?: InputMaybe<Scalars['Float']['input']>;
  maxFollowers?: InputMaybe<Scalars['Int']['input']>;
  maxPostPrice?: InputMaybe<Scalars['Float']['input']>;
  minAge?: InputMaybe<Scalars['Int']['input']>;
  minEngagementRate?: InputMaybe<Scalars['Float']['input']>;
  minFollowers?: InputMaybe<Scalars['Int']['input']>;
  minPostPrice?: InputMaybe<Scalars['Float']['input']>;
  partnerIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  regionIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  status?: InputMaybe<SocialAccountStatus>;
  talentAgencyIds?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type QueryallFacebookAccountInfluencersV2Args = {
  audience?: InputMaybe<SocialAccountInfluencerAudience>;
  categoryIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  countryIds?: InputMaybe<Array<Scalars['String']['input']>>;
  genders?: InputMaybe<Array<Genders>>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  keywordField?: InputMaybe<InfluencerSearchKeywordField>;
  limit: Scalars['Int']['input'];
  maxAge?: InputMaybe<Scalars['Int']['input']>;
  maxEngagementRate?: InputMaybe<Scalars['Float']['input']>;
  maxFollowers?: InputMaybe<Scalars['Int']['input']>;
  maxPostPrice?: InputMaybe<Scalars['Float']['input']>;
  minAge?: InputMaybe<Scalars['Int']['input']>;
  minEngagementRate?: InputMaybe<Scalars['Float']['input']>;
  minFollowers?: InputMaybe<Scalars['Int']['input']>;
  minPostPrice?: InputMaybe<Scalars['Float']['input']>;
  offset: Scalars['Int']['input'];
  orderBy?: InputMaybe<SocialAccountInfluencerOrderByV2>;
  partnerIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  regionIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  status?: InputMaybe<SocialAccountStatus>;
  talentAgencyIds?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type QueryallFacebookPageInfluencersArgs = {
  audience?: InputMaybe<SocialAccountInfluencerAudience>;
  categoryIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  countryIds?: InputMaybe<Array<Scalars['String']['input']>>;
  genders?: InputMaybe<Array<Genders>>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  keywordField?: InputMaybe<InfluencerSearchKeywordField>;
  limit: Scalars['Int']['input'];
  maxAge?: InputMaybe<Scalars['Int']['input']>;
  maxEngagementRate?: InputMaybe<Scalars['Float']['input']>;
  maxFollowers?: InputMaybe<Scalars['Int']['input']>;
  maxPostPrice?: InputMaybe<Scalars['Float']['input']>;
  minAge?: InputMaybe<Scalars['Int']['input']>;
  minEngagementRate?: InputMaybe<Scalars['Float']['input']>;
  minFollowers?: InputMaybe<Scalars['Int']['input']>;
  minPostPrice?: InputMaybe<Scalars['Float']['input']>;
  offset: Scalars['Int']['input'];
  orderBy?: InputMaybe<SocialAccountInfluencerOrderBy>;
  partnerIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  regionIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  status?: InputMaybe<SocialAccountStatus>;
  talentAgencyIds?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type QueryallFacebookPageInfluencersCountArgs = {
  audience?: InputMaybe<SocialAccountInfluencerAudience>;
  categoryIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  countryIds?: InputMaybe<Array<Scalars['String']['input']>>;
  genders?: InputMaybe<Array<Genders>>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  keywordField?: InputMaybe<InfluencerSearchKeywordField>;
  maxAge?: InputMaybe<Scalars['Int']['input']>;
  maxEngagementRate?: InputMaybe<Scalars['Float']['input']>;
  maxFollowers?: InputMaybe<Scalars['Int']['input']>;
  maxPostPrice?: InputMaybe<Scalars['Float']['input']>;
  minAge?: InputMaybe<Scalars['Int']['input']>;
  minEngagementRate?: InputMaybe<Scalars['Float']['input']>;
  minFollowers?: InputMaybe<Scalars['Int']['input']>;
  minPostPrice?: InputMaybe<Scalars['Float']['input']>;
  partnerIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  regionIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  status?: InputMaybe<SocialAccountStatus>;
  talentAgencyIds?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type QueryallFacebookPageInfluencersV2Args = {
  audience?: InputMaybe<SocialAccountInfluencerAudience>;
  categoryIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  countryIds?: InputMaybe<Array<Scalars['String']['input']>>;
  genders?: InputMaybe<Array<Genders>>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  keywordField?: InputMaybe<InfluencerSearchKeywordField>;
  limit: Scalars['Int']['input'];
  maxAge?: InputMaybe<Scalars['Int']['input']>;
  maxEngagementRate?: InputMaybe<Scalars['Float']['input']>;
  maxFollowers?: InputMaybe<Scalars['Int']['input']>;
  maxPostPrice?: InputMaybe<Scalars['Float']['input']>;
  minAge?: InputMaybe<Scalars['Int']['input']>;
  minEngagementRate?: InputMaybe<Scalars['Float']['input']>;
  minFollowers?: InputMaybe<Scalars['Int']['input']>;
  minPostPrice?: InputMaybe<Scalars['Float']['input']>;
  offset: Scalars['Int']['input'];
  orderBy?: InputMaybe<SocialAccountInfluencerOrderByV2>;
  partnerIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  regionIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  status?: InputMaybe<SocialAccountStatus>;
  talentAgencyIds?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type QueryallInfluencerAccountPostsArgs = {
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  orderBy?: InputMaybe<PostOrderBy>;
  socialAccountId: Scalars['Int']['input'];
  socialPostType: SocialPostType;
};

export type QueryallInfluencerAccountPostsCountArgs = {
  keyword?: InputMaybe<Scalars['String']['input']>;
  socialAccountId: Scalars['Int']['input'];
  socialPostType: SocialPostType;
};

export type QueryallInfluencerPostsV3Args = {
  categoryIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  countryId?: InputMaybe<Scalars['String']['input']>;
  genders?: InputMaybe<Array<Genders>>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Int']['input'];
  maxEngagementRate?: InputMaybe<Scalars['Float']['input']>;
  maxFollowers?: InputMaybe<Scalars['Int']['input']>;
  maxPostDate?: InputMaybe<Scalars['Date']['input']>;
  maxViews?: InputMaybe<Scalars['Int']['input']>;
  minEngagementRate?: InputMaybe<Scalars['Float']['input']>;
  minFollowers?: InputMaybe<Scalars['Int']['input']>;
  minPostDate?: InputMaybe<Scalars['Date']['input']>;
  minViews?: InputMaybe<Scalars['Int']['input']>;
  offset: Scalars['Int']['input'];
  orderBy?: InputMaybe<PostOrderByV3>;
  socialPostType?: InputMaybe<SocialPostType>;
};

export type QueryallInfluencersCountForMarketplaceArgs = {
  categoryIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  genders?: InputMaybe<Array<Genders>>;
  id: Scalars['Int']['input'];
  keyword?: InputMaybe<Scalars['String']['input']>;
  maxAge?: InputMaybe<Scalars['Int']['input']>;
  maxEngagementRate?: InputMaybe<Scalars['Float']['input']>;
  maxFollowers?: InputMaybe<Scalars['Int']['input']>;
  minAge?: InputMaybe<Scalars['Int']['input']>;
  minEngagementRate?: InputMaybe<Scalars['Float']['input']>;
  minFollowers?: InputMaybe<Scalars['Int']['input']>;
  partnerIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  selectedInfluencers?: InputMaybe<Array<SelectedInfluencers>>;
  talentAgencyIds?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type QueryallInfluencersForMarketplaceV2Args = {
  categoryIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  genders?: InputMaybe<Array<Genders>>;
  id: Scalars['Int']['input'];
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Int']['input'];
  maxAge?: InputMaybe<Scalars['Int']['input']>;
  maxEngagementRate?: InputMaybe<Scalars['Float']['input']>;
  maxFollowers?: InputMaybe<Scalars['Int']['input']>;
  minAge?: InputMaybe<Scalars['Int']['input']>;
  minEngagementRate?: InputMaybe<Scalars['Float']['input']>;
  minFollowers?: InputMaybe<Scalars['Int']['input']>;
  offset: Scalars['Int']['input'];
  orderBy?: InputMaybe<InfluencerOrderBy>;
  partnerIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  selectedInfluencers?: InputMaybe<Array<SelectedInfluencers>>;
  talentAgencyIds?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type QueryallInfluencersV4Args = {
  campaignDisplay?: InputMaybe<Scalars['Boolean']['input']>;
  categoryIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  countryIds?: InputMaybe<Array<Scalars['String']['input']>>;
  genders?: InputMaybe<Array<Genders>>;
  includeIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  keywordField?: InputMaybe<InfluencerSearchKeywordField>;
  limit: Scalars['Int']['input'];
  maxAge?: InputMaybe<Scalars['Int']['input']>;
  maxAvgPostPrice?: InputMaybe<Scalars['Float']['input']>;
  maxCampaignsJoined?: InputMaybe<Scalars['Int']['input']>;
  maxEngagementRate?: InputMaybe<Scalars['Float']['input']>;
  maxFollowers?: InputMaybe<Scalars['Int']['input']>;
  minAge?: InputMaybe<Scalars['Int']['input']>;
  minAvgPostPrice?: InputMaybe<Scalars['Float']['input']>;
  minCampaignsJoined?: InputMaybe<Scalars['Int']['input']>;
  minEngagementRate?: InputMaybe<Scalars['Float']['input']>;
  minFollowers?: InputMaybe<Scalars['Int']['input']>;
  offset: Scalars['Int']['input'];
  orderBy?: InputMaybe<InfluencerSearchOrderBy>;
  partnerIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  regionIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  socialMedias?: InputMaybe<Array<QuerySocialAccountType>>;
  statuses?: InputMaybe<Array<InfluencerStatusForSearch>>;
  talentAgencyIds?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type QueryallInstagramAmbassadorPostsArgs = {
  accountId: Scalars['Int']['input'];
  endDate?: InputMaybe<Scalars['Date']['input']>;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  startDate?: InputMaybe<Scalars['Date']['input']>;
};

export type QueryallInstagramAmbassadorPostsCountArgs = {
  accountId: Scalars['Int']['input'];
  endDate?: InputMaybe<Scalars['Date']['input']>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
};

export type QueryallInstagramCompareFeedPostsArgs = {
  accountId: Scalars['Int']['input'];
  endDate?: InputMaybe<Scalars['Date']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  orderBy?: InputMaybe<InstagramFeedPostOrderBy>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
};

export type QueryallInstagramCompareFeedPostsCountArgs = {
  accountId: Scalars['Int']['input'];
  endDate?: InputMaybe<Scalars['Date']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
};

export type QueryallInstagramComparePostsInDateArgs = {
  accountId: Scalars['Int']['input'];
  comparableAccountIds: Array<Scalars['Int']['input']>;
  postDate: Scalars['Date']['input'];
};

export type QueryallInstagramFeedPostsArgs = {
  accountId: Scalars['Int']['input'];
  endDate?: InputMaybe<Scalars['Date']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  orderBy?: InputMaybe<InstagramFeedPostOrderBy>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
};

export type QueryallInstagramFeedPostsCountArgs = {
  accountId: Scalars['Int']['input'];
  endDate?: InputMaybe<Scalars['Date']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
};

export type QueryallInstagramHashtagFeedPostsArgs = {
  endDate?: InputMaybe<Scalars['Date']['input']>;
  hashtagId: Scalars['Int']['input'];
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  orderBy?: InputMaybe<InstagramFeedPostOrderBy>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
};

export type QueryallInstagramHashtagFeedPostsCountArgs = {
  endDate?: InputMaybe<Scalars['Date']['input']>;
  hashtagId: Scalars['Int']['input'];
  keyword?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
};

export type QueryallInstagramInfluencersV3Args = {
  audience?: InputMaybe<SocialAccountInfluencerAudience>;
  categoryIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  countryIds?: InputMaybe<Array<Scalars['String']['input']>>;
  followerGrowthPercentage?: InputMaybe<Scalars['Float']['input']>;
  followerGrowthPeriod?: InputMaybe<FollowersGrowthPeriodEnum>;
  genders?: InputMaybe<Array<Genders>>;
  interests?: InputMaybe<Array<Scalars['String']['input']>>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  keywordField?: InputMaybe<InfluencerSearchKeywordField>;
  limit: Scalars['Int']['input'];
  maxAge?: InputMaybe<Scalars['Int']['input']>;
  maxEngagementRate?: InputMaybe<Scalars['Float']['input']>;
  maxFollowers?: InputMaybe<Scalars['Int']['input']>;
  maxPostPrice?: InputMaybe<Scalars['Float']['input']>;
  maxReach?: InputMaybe<Scalars['Float']['input']>;
  maxSaveRate?: InputMaybe<Scalars['Float']['input']>;
  maxStoryPrice?: InputMaybe<Scalars['Float']['input']>;
  minAge?: InputMaybe<Scalars['Int']['input']>;
  minEngagementRate?: InputMaybe<Scalars['Float']['input']>;
  minFollowers?: InputMaybe<Scalars['Int']['input']>;
  minPostPrice?: InputMaybe<Scalars['Float']['input']>;
  minReach?: InputMaybe<Scalars['Float']['input']>;
  minSaveRate?: InputMaybe<Scalars['Float']['input']>;
  minStoryPrice?: InputMaybe<Scalars['Float']['input']>;
  offset: Scalars['Int']['input'];
  orderBy?: InputMaybe<SocialAccountInfluencerOrderByV2>;
  partnerIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  regionIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  status?: InputMaybe<SocialAccountStatus>;
  talentAgencyIds?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type QueryallInstagramInteractionUsersArgs = {
  accountId: Scalars['Int']['input'];
  endDate?: InputMaybe<Scalars['Date']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  orderBy?: InputMaybe<InstagramInteractionUserOrderBy>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
};

export type QueryallInstagramMentionedPostsArgs = {
  accountId: Scalars['Int']['input'];
  endDate?: InputMaybe<Scalars['Date']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  orderBy?: InputMaybe<InstagramFeedPostOrderBy>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
};

export type QueryallInstagramMentionedPostsCountArgs = {
  accountId: Scalars['Int']['input'];
  endDate?: InputMaybe<Scalars['Date']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
};

export type QueryallInstagramPostsInDateArgs = {
  accountId: Scalars['Int']['input'];
  postDate: Scalars['Date']['input'];
};

export type QueryallInstagramReelPostsArgs = {
  accountId: Scalars['Int']['input'];
  endDate?: InputMaybe<Scalars['Date']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  orderBy?: InputMaybe<InstagramReelPostOrderBy>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
};

export type QueryallInstagramReelPostsCountArgs = {
  accountId: Scalars['Int']['input'];
  endDate?: InputMaybe<Scalars['Date']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
};

export type QueryallInstagramSimilarInfluencersV3Args = {
  accountId?: InputMaybe<Scalars['Int']['input']>;
  genders?: InputMaybe<Array<Genders>>;
  limit: Scalars['Int']['input'];
  maxAge?: InputMaybe<Scalars['Int']['input']>;
  maxFollowers?: InputMaybe<Scalars['Int']['input']>;
  minAge?: InputMaybe<Scalars['Int']['input']>;
  minFollowers?: InputMaybe<Scalars['Int']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};

export type QueryallInstagramStoryPostsArgs = {
  accountId: Scalars['Int']['input'];
  endDate?: InputMaybe<Scalars['Date']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  orderBy?: InputMaybe<InstagramStoryPostOrderBy>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
};

export type QueryallInstagramStoryPostsCountArgs = {
  accountId: Scalars['Int']['input'];
  endDate?: InputMaybe<Scalars['Date']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
};

export type QueryallInstagramTaggedPostsArgs = {
  accountId: Scalars['Int']['input'];
  endDate?: InputMaybe<Scalars['Date']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  orderBy?: InputMaybe<InstagramFeedPostOrderBy>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
};

export type QueryallInstagramTaggedPostsCountArgs = {
  accountId: Scalars['Int']['input'];
  endDate?: InputMaybe<Scalars['Date']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
};

export type QueryallMarketplacePostsArgs = {
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Int']['input'];
  marketplaceId: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  status?: InputMaybe<MarketplaceCampaignPostStatusForSearch>;
};

export type QueryallMarketplacePostsCountArgs = {
  keyword?: InputMaybe<Scalars['String']['input']>;
  marketplaceId: Scalars['Int']['input'];
  status?: InputMaybe<MarketplaceCampaignPostStatusForSearch>;
};

export type QueryallMarketplacesArgs = {
  advertiserIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  campaignDetailType?: InputMaybe<MarketplaceCampaignDetailType>;
  countryId?: InputMaybe<Scalars['String']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  status?: InputMaybe<MarketplaceCampaignStatus>;
};

export type QueryallMarketplacesCountArgs = {
  campaignDetailType?: InputMaybe<MarketplaceCampaignDetailType>;
  countryId?: InputMaybe<Scalars['String']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<MarketplaceCampaignStatus>;
};

export type QueryallMarketplacesForReorderArgs = {
  countryId?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
};

export type QueryallNeedReviewMarketplacePostsArgs = {
  marketplaceId: Scalars['Int']['input'];
};

export type QueryallPackagesArgs = {
  countryIds?: InputMaybe<Array<Scalars['String']['input']>>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
};

export type QueryallPackagesCountArgs = {
  countryIds?: InputMaybe<Array<Scalars['String']['input']>>;
  keyword?: InputMaybe<Scalars['String']['input']>;
};

export type QueryallPartnerAgenciesCountArgs = {
  countryIds?: InputMaybe<Array<Scalars['String']['input']>>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  verifiedStatus?: InputMaybe<NetSuiteVerifiedStatus>;
};

export type QueryallPartnerAgencyCompanyArgs = {
  countryIds?: InputMaybe<Array<Scalars['String']['input']>>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  verifiedStatus?: InputMaybe<NetSuiteVerifiedStatus>;
};

export type QueryallPartnerAgencyUsersArgs = {
  partnerAgencyId: Scalars['Int']['input'];
};

export type QueryallPaymentsArgs = {
  countryId?: InputMaybe<Scalars['String']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Int']['input'];
  maxDueDate?: InputMaybe<Scalars['Date']['input']>;
  minDueDate?: InputMaybe<Scalars['Date']['input']>;
  offset: Scalars['Int']['input'];
  status?: InputMaybe<PaymentRequestStatus>;
};

export type QueryallPaymentsCountArgs = {
  countryId?: InputMaybe<Scalars['String']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  maxDueDate?: InputMaybe<Scalars['Date']['input']>;
  minDueDate?: InputMaybe<Scalars['Date']['input']>;
  status?: InputMaybe<PaymentRequestStatus>;
};

export type QueryallRegionsArgs = {
  countryId: Scalars['String']['input'];
};

export type QueryallStaffsArgs = {
  countryId?: InputMaybe<Scalars['String']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryallStaffsCountArgs = {
  countryId?: InputMaybe<Scalars['String']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
};

export type QueryallTalentAgenciesCountArgs = {
  countryIds?: InputMaybe<Array<Scalars['String']['input']>>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  verifiedStatus?: InputMaybe<NetSuiteVerifiedStatus>;
};

export type QueryallTalentAgencyCompanyArgs = {
  countryIds?: InputMaybe<Array<Scalars['String']['input']>>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  verifiedStatus?: InputMaybe<NetSuiteVerifiedStatus>;
};

export type QueryallTalentAgencyUsersArgs = {
  talentAgencyId: Scalars['Int']['input'];
};

export type QueryallThreadsInfluencersArgs = {
  categoryIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  countryIds?: InputMaybe<Array<Scalars['String']['input']>>;
  genders?: InputMaybe<Array<Genders>>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  keywordField?: InputMaybe<InfluencerSearchKeywordField>;
  limit: Scalars['Int']['input'];
  maxAge?: InputMaybe<Scalars['Int']['input']>;
  maxEngagementRate?: InputMaybe<Scalars['Float']['input']>;
  maxFollowers?: InputMaybe<Scalars['Int']['input']>;
  maxPostPrice?: InputMaybe<Scalars['Float']['input']>;
  minAge?: InputMaybe<Scalars['Int']['input']>;
  minEngagementRate?: InputMaybe<Scalars['Float']['input']>;
  minFollowers?: InputMaybe<Scalars['Int']['input']>;
  minPostPrice?: InputMaybe<Scalars['Float']['input']>;
  offset: Scalars['Int']['input'];
  orderBy?: InputMaybe<SocialAccountInfluencerOrderBy>;
  partnerIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  regionIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  status?: InputMaybe<SocialAccountStatus>;
  talentAgencyIds?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type QueryallThreadsInfluencersCountArgs = {
  categoryIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  countryIds?: InputMaybe<Array<Scalars['String']['input']>>;
  genders?: InputMaybe<Array<Genders>>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  keywordField?: InputMaybe<InfluencerSearchKeywordField>;
  maxAge?: InputMaybe<Scalars['Int']['input']>;
  maxEngagementRate?: InputMaybe<Scalars['Float']['input']>;
  maxFollowers?: InputMaybe<Scalars['Int']['input']>;
  maxPostPrice?: InputMaybe<Scalars['Float']['input']>;
  minAge?: InputMaybe<Scalars['Int']['input']>;
  minEngagementRate?: InputMaybe<Scalars['Float']['input']>;
  minFollowers?: InputMaybe<Scalars['Int']['input']>;
  minPostPrice?: InputMaybe<Scalars['Float']['input']>;
  partnerIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  regionIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  status?: InputMaybe<SocialAccountStatus>;
  talentAgencyIds?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type QueryallTiktokInfluencersArgs = {
  categoryIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  countryIds?: InputMaybe<Array<Scalars['String']['input']>>;
  genders?: InputMaybe<Array<Genders>>;
  isTtcm?: InputMaybe<Scalars['Boolean']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  keywordField?: InputMaybe<InfluencerSearchKeywordField>;
  limit: Scalars['Int']['input'];
  maxAge?: InputMaybe<Scalars['Int']['input']>;
  maxEngagementRate?: InputMaybe<Scalars['Float']['input']>;
  maxFollowers?: InputMaybe<Scalars['Int']['input']>;
  maxPostPrice?: InputMaybe<Scalars['Float']['input']>;
  minAge?: InputMaybe<Scalars['Int']['input']>;
  minEngagementRate?: InputMaybe<Scalars['Float']['input']>;
  minFollowers?: InputMaybe<Scalars['Int']['input']>;
  minPostPrice?: InputMaybe<Scalars['Float']['input']>;
  offset: Scalars['Int']['input'];
  orderBy?: InputMaybe<SocialAccountInfluencerOrderBy>;
  partnerIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  regionIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  status?: InputMaybe<SocialAccountStatus>;
  talentAgencyIds?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type QueryallTiktokInfluencersCountArgs = {
  categoryIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  countryIds?: InputMaybe<Array<Scalars['String']['input']>>;
  genders?: InputMaybe<Array<Genders>>;
  isTtcm?: InputMaybe<Scalars['Boolean']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  keywordField?: InputMaybe<InfluencerSearchKeywordField>;
  maxAge?: InputMaybe<Scalars['Int']['input']>;
  maxEngagementRate?: InputMaybe<Scalars['Float']['input']>;
  maxFollowers?: InputMaybe<Scalars['Int']['input']>;
  maxPostPrice?: InputMaybe<Scalars['Float']['input']>;
  minAge?: InputMaybe<Scalars['Int']['input']>;
  minEngagementRate?: InputMaybe<Scalars['Float']['input']>;
  minFollowers?: InputMaybe<Scalars['Int']['input']>;
  minPostPrice?: InputMaybe<Scalars['Float']['input']>;
  partnerIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  regionIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  status?: InputMaybe<SocialAccountStatus>;
  talentAgencyIds?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type QueryallTiktokSimilarInfluencersArgs = {
  accountId: Scalars['Int']['input'];
  genders?: InputMaybe<Array<Genders>>;
  limit: Scalars['Int']['input'];
  maxAge?: InputMaybe<Scalars['Int']['input']>;
  maxFollowers?: InputMaybe<Scalars['Int']['input']>;
  minAge?: InputMaybe<Scalars['Int']['input']>;
  minFollowers?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryallTwitterInfluencersArgs = {
  categoryIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  countryIds?: InputMaybe<Array<Scalars['String']['input']>>;
  genders?: InputMaybe<Array<Genders>>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  keywordField?: InputMaybe<InfluencerSearchKeywordField>;
  limit: Scalars['Int']['input'];
  maxAge?: InputMaybe<Scalars['Int']['input']>;
  maxEngagementRate?: InputMaybe<Scalars['Float']['input']>;
  maxFollowers?: InputMaybe<Scalars['Int']['input']>;
  maxPostPrice?: InputMaybe<Scalars['Float']['input']>;
  minAge?: InputMaybe<Scalars['Int']['input']>;
  minEngagementRate?: InputMaybe<Scalars['Float']['input']>;
  minFollowers?: InputMaybe<Scalars['Int']['input']>;
  minPostPrice?: InputMaybe<Scalars['Float']['input']>;
  offset: Scalars['Int']['input'];
  orderBy?: InputMaybe<SocialAccountInfluencerOrderBy>;
  partnerIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  regionIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  status?: InputMaybe<SocialAccountStatus>;
  talentAgencyIds?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type QueryallTwitterInfluencersCountArgs = {
  categoryIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  countryIds?: InputMaybe<Array<Scalars['String']['input']>>;
  genders?: InputMaybe<Array<Genders>>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  keywordField?: InputMaybe<InfluencerSearchKeywordField>;
  maxAge?: InputMaybe<Scalars['Int']['input']>;
  maxEngagementRate?: InputMaybe<Scalars['Float']['input']>;
  maxFollowers?: InputMaybe<Scalars['Int']['input']>;
  maxPostPrice?: InputMaybe<Scalars['Float']['input']>;
  minAge?: InputMaybe<Scalars['Int']['input']>;
  minEngagementRate?: InputMaybe<Scalars['Float']['input']>;
  minFollowers?: InputMaybe<Scalars['Int']['input']>;
  minPostPrice?: InputMaybe<Scalars['Float']['input']>;
  partnerIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  regionIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  status?: InputMaybe<SocialAccountStatus>;
  talentAgencyIds?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type QueryallTwitterSimilarInfluencersArgs = {
  accountId: Scalars['Int']['input'];
  genders?: InputMaybe<Array<Genders>>;
  limit: Scalars['Int']['input'];
  maxAge?: InputMaybe<Scalars['Int']['input']>;
  maxFollowers?: InputMaybe<Scalars['Int']['input']>;
  minAge?: InputMaybe<Scalars['Int']['input']>;
  minFollowers?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryallUsersArgs = {
  advertiserIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  countryId?: InputMaybe<Scalars['String']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
};

export type QueryallUsersCountArgs = {
  advertiserIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  countryId?: InputMaybe<Scalars['String']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
};

export type QueryallYoutubeCmsAssetsArgs = {
  cmsChannelId?: InputMaybe<Scalars['Int']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Int']['input'];
  maxDate?: InputMaybe<Scalars['Date']['input']>;
  minDate?: InputMaybe<Scalars['Date']['input']>;
  offset: Scalars['Int']['input'];
};

export type QueryallYoutubeCmsAssetsCountArgs = {
  cmsChannelId?: InputMaybe<Scalars['Int']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
};

export type QueryallYoutubeCmsChannelsArgs = {
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Int']['input'];
  maxDate?: InputMaybe<Scalars['Date']['input']>;
  minDate?: InputMaybe<Scalars['Date']['input']>;
  offset: Scalars['Int']['input'];
};

export type QueryallYoutubeCmsChannelsCountArgs = {
  keyword?: InputMaybe<Scalars['String']['input']>;
};

export type QueryallYoutubeInfluencersV3Args = {
  audience?: InputMaybe<SocialAccountInfluencerAudience>;
  categoryIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  countryIds?: InputMaybe<Array<Scalars['String']['input']>>;
  followerGrowthPercentage?: InputMaybe<Scalars['Float']['input']>;
  followerGrowthPeriod?: InputMaybe<FollowersGrowthPeriodEnum>;
  genders?: InputMaybe<Array<Genders>>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  keywordField?: InputMaybe<InfluencerSearchKeywordField>;
  limit: Scalars['Int']['input'];
  maxAge?: InputMaybe<Scalars['Int']['input']>;
  maxEngagementRate?: InputMaybe<Scalars['Float']['input']>;
  maxFollowers?: InputMaybe<Scalars['Int']['input']>;
  maxPostPrice?: InputMaybe<Scalars['Float']['input']>;
  maxViews?: InputMaybe<Scalars['Int']['input']>;
  minAge?: InputMaybe<Scalars['Int']['input']>;
  minEngagementRate?: InputMaybe<Scalars['Float']['input']>;
  minFollowers?: InputMaybe<Scalars['Int']['input']>;
  minPostPrice?: InputMaybe<Scalars['Float']['input']>;
  minViews?: InputMaybe<Scalars['Int']['input']>;
  offset: Scalars['Int']['input'];
  orderBy?: InputMaybe<SocialAccountInfluencerOrderByV2>;
  partnerIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  regionIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  status?: InputMaybe<SocialAccountStatus>;
  talentAgencyIds?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type QueryallYoutubeSimilarInfluencersArgs = {
  accountId: Scalars['Int']['input'];
  genders?: InputMaybe<Array<Genders>>;
  limit: Scalars['Int']['input'];
  maxAge?: InputMaybe<Scalars['Int']['input']>;
  maxFollowers?: InputMaybe<Scalars['Int']['input']>;
  minAge?: InputMaybe<Scalars['Int']['input']>;
  minFollowers?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryanalyticsSocialAuthRedirectUrlArgs = {
  callbackUrl: Scalars['String']['input'];
  provider: AnalyticsAuthSocialAccountType;
};

export type QueryanalyticsSubscriptionPlanArgs = {
  accountId: Scalars['Int']['input'];
};

export type QueryanalyticsSubscriptionPlanByAdvIdArgs = {
  advId: Scalars['Int']['input'];
};

export type QueryautoManagedCommissionFixedDatesArgs = {
  id: Scalars['Int']['input'];
};

export type QueryautoManagedCommissionInfluencersArgs = {
  fixedDate?: InputMaybe<Scalars['Date']['input']>;
  id: Scalars['Int']['input'];
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  status?: InputMaybe<AutoManagedCommissionStatus>;
};

export type QueryautoManagedCommissionInfluencersCountsArgs = {
  fixedDate?: InputMaybe<Scalars['Date']['input']>;
  id: Scalars['Int']['input'];
  keyword?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<AutoManagedCommissionStatus>;
};

export type QueryautoManagedJoinedInfluencersArgs = {
  id: Scalars['Int']['input'];
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
};

export type QueryautoManagedJoinedInfluencersCountArgs = {
  id: Scalars['Int']['input'];
  keyword?: InputMaybe<Scalars['String']['input']>;
};

export type QueryautoManagedJoinedMethodsArgs = {
  input: AutoManagedJoinedMethodsInput;
};

export type QueryautoManagedReportByDateArgs = {
  endDate?: InputMaybe<Scalars['Date']['input']>;
  id: Scalars['Int']['input'];
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  startDate?: InputMaybe<Scalars['Date']['input']>;
};

export type QueryautoManagedReportByDateCountArgs = {
  endDate?: InputMaybe<Scalars['Date']['input']>;
  id: Scalars['Int']['input'];
  startDate?: InputMaybe<Scalars['Date']['input']>;
};

export type QueryautoManagedReportByInfluencerArgs = {
  endDate?: InputMaybe<Scalars['Date']['input']>;
  id: Scalars['Int']['input'];
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  orderBy?: InputMaybe<MarketplaceAffiliateReportByInfluencerOrderBy>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
};

export type QueryautoManagedReportByInfluencerCountArgs = {
  endDate?: InputMaybe<Scalars['Date']['input']>;
  id: Scalars['Int']['input'];
  startDate?: InputMaybe<Scalars['Date']['input']>;
};

export type QueryautoManagedReportCountForInfluencerArgs = {
  campaignId: Scalars['Int']['input'];
};

export type QueryautoManagedReportForInfluencerArgs = {
  campaignId: Scalars['Int']['input'];
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
};

export type QueryautoManagedReportSummaryArgs = {
  endDate?: InputMaybe<Scalars['Date']['input']>;
  id: Scalars['Int']['input'];
  startDate?: InputMaybe<Scalars['Date']['input']>;
};

export type QueryautoManagedReportSummaryForInfluencerArgs = {
  campaignId: Scalars['Int']['input'];
};

export type QuerybrandAccountsArgs = {
  influencerId: Scalars['Int']['input'];
  socialAccountId: Scalars['Int']['input'];
  socialMedia: SocialAccountType;
};

export type QuerycampaignHashtagCloudArgs = {
  campaignObjective?: InputMaybe<Scalars['String']['input']>;
  caption?: InputMaybe<Scalars['String']['input']>;
  socialMedia?: InputMaybe<CampaignSocialMediaType>;
  tags?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type QuerycampaignsChartForAllTimeArgs = {
  campaignCategoryIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  campaignTypes?: InputMaybe<Array<CampaignType>>;
  campaigns?: InputMaybe<Array<CampaignFilter>>;
  countryId?: InputMaybe<Scalars['String']['input']>;
  socialMedias?: InputMaybe<Array<CampaignSocialMediaType>>;
};

export type QuerycheckBioLinkNameAvailabilityArgs = {
  input?: InputMaybe<CheckBioLinkNameAvailabilityInput>;
};

export type QuerycheckoutSessionLinkArgs = {
  planType: PaymentPlanType;
  priceId: Scalars['Int']['input'];
  redirectUrl: Scalars['String']['input'];
};

export type QueryconnectableInstagramAccountsArgs = {
  fbPageIds: Array<InputMaybe<Scalars['String']['input']>>;
};

export type QuerycountUnreadNotificationsByAppArgs = {
  input?: InputMaybe<CountUnreadNotificationsByAppInput>;
};

export type QueryengagementArgs = {
  pk: Scalars['Int']['input'];
};

export type QueryengagementCampaignPostReportAudienceBreakdownArgs = {
  campaignId: Scalars['Int']['input'];
};

export type QueryengagementCampaignPostReportForInfluencerArgs = {
  campaignId: Scalars['Int']['input'];
};

export type QueryengagementCampaignReportArgs = {
  campaignId: Scalars['Int']['input'];
  endDate?: InputMaybe<Scalars['Date']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<CampaignReportOrderBy>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
};

export type QueryengagementCampaignReportGridArgs = {
  campaignId: Scalars['Int']['input'];
};

export type QueryengagementCampaignReportSummaryArgs = {
  campaignId: Scalars['Int']['input'];
  endDate?: InputMaybe<Scalars['Date']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
};

export type QueryengagementCampaignTrafficSourceArgs = {
  endDate?: InputMaybe<Scalars['Date']['input']>;
  pk: Scalars['Int']['input'];
  startDate?: InputMaybe<Scalars['Date']['input']>;
};

export type QueryengagementDraftPostHistoryArgs = {
  pk: Scalars['Int']['input'];
};

export type QueryengagementDraftPostHistoryForInfluencerArgs = {
  pk: Scalars['Int']['input'];
};

export type QueryengagementForInfluencerArgs = {
  pk: Scalars['Int']['input'];
};

export type QueryengagementHashtagCloudArgs = {
  advertiser?: InputMaybe<Scalars['String']['input']>;
  campaignCountry?: InputMaybe<Scalars['String']['input']>;
  campaignObjective?: InputMaybe<Scalars['String']['input']>;
  campaignTitle?: InputMaybe<Scalars['String']['input']>;
  influencerCategory?: InputMaybe<Array<Scalars['Int']['input']>>;
  productInfo?: InputMaybe<Scalars['String']['input']>;
  socialMedia?: InputMaybe<CampaignSocialMediaType>;
  tags?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type QueryengagementPostCommentsArgs = {
  limit: Scalars['Int']['input'];
  postId: Scalars['Int']['input'];
};

export type QueryengagementPostDetailArgs = {
  pk: Scalars['Int']['input'];
};

export type QueryengagementPostDetailForInfluencerArgs = {
  pk: Scalars['Int']['input'];
};

export type QueryengagementPostForInfluencerV2Args = {
  pk: Scalars['Int']['input'];
};

export type QueryengagementPostInfluencersArgs = {
  pk: Scalars['Int']['input'];
};

export type QueryengagementPostMetricsForInsightCheckArgs = {
  postId: Scalars['Int']['input'];
};

export type QueryengagementPostSocialAccountsForInfluencerArgs = {
  pk: Scalars['Int']['input'];
};

export type QueryengagementPostTrafficSourceArgs = {
  endDate?: InputMaybe<Scalars['Date']['input']>;
  pk: Scalars['Int']['input'];
  startDate?: InputMaybe<Scalars['Date']['input']>;
};

export type QueryengagementPostV2Args = {
  pk: Scalars['Int']['input'];
};

export type QueryengagementProposalArgs = {
  pk: Scalars['Int']['input'];
};

export type QueryengagementTrackingArgs = {
  engagementId: Scalars['Int']['input'];
};

export type QueryexistingProfileTagsArgs = {
  keyword: Scalars['String']['input'];
};

export type QueryexternalAllInstagramFeedPostsArgs = {
  accountId: Scalars['Int']['input'];
  endDate?: InputMaybe<Scalars['Date']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  orderBy?: InputMaybe<InstagramFeedPostOrderBy>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
};

export type QueryexternalAllInstagramFeedPostsCountArgs = {
  accountId: Scalars['Int']['input'];
  endDate?: InputMaybe<Scalars['Date']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
};

export type QueryexternalAllInstagramPostsInDateArgs = {
  accountId: Scalars['Int']['input'];
  postDate: Scalars['Date']['input'];
};

export type QueryexternalAllInstagramReelPostsArgs = {
  accountId: Scalars['Int']['input'];
  endDate?: InputMaybe<Scalars['Date']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  orderBy?: InputMaybe<InstagramReelPostOrderBy>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
};

export type QueryexternalAllInstagramReelPostsCountArgs = {
  accountId: Scalars['Int']['input'];
  endDate?: InputMaybe<Scalars['Date']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
};

export type QueryexternalAllInstagramStoryPostsArgs = {
  accountId: Scalars['Int']['input'];
  endDate?: InputMaybe<Scalars['Date']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  orderBy?: InputMaybe<InstagramStoryPostOrderBy>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
};

export type QueryexternalAllInstagramStoryPostsCountArgs = {
  accountId: Scalars['Int']['input'];
  endDate?: InputMaybe<Scalars['Date']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
};

export type QueryexternalAnalyticsFacebookGetAccountDetailsArgs = {
  analyticsAccountId: Scalars['Int']['input'];
};

export type QueryexternalAnalyticsFacebookGetTokenWarningsArgs = {
  analyticsAccountIdsToCheck: Array<Scalars['Int']['input']>;
};

export type QueryexternalAnalyticsInstagramGetAccountDetailsArgs = {
  analyticsAccountId: Scalars['Int']['input'];
};

export type QueryexternalAnalyticsInstagramGetTokenWarningsArgs = {
  analyticsAccountIdsToCheck: Array<Scalars['Int']['input']>;
};

export type QueryexternalAnalyticsSocialAuthRedirectUrlArgs = {
  callbackUrl: Scalars['String']['input'];
  provider: ExternalAnalyticsAuthSocialAccountType;
};

export type QueryexternalFacebookPageAnalyticsAudienceArgs = {
  endDate: Scalars['Date']['input'];
  facebookPageAnalyticsAccountId: Scalars['Int']['input'];
  startDate: Scalars['Date']['input'];
};

export type QueryexternalFacebookPageAnalyticsOverviewArgs = {
  endDate: Scalars['Date']['input'];
  facebookPageAnalyticsAccountId: Scalars['Int']['input'];
  startDate: Scalars['Date']['input'];
};

export type QueryexternalFacebookPageAnalyticsPostArgs = {
  facebookPageAnalyticsAccountId: Scalars['Int']['input'];
  facebookPageAnalyticsPostId: Scalars['Int']['input'];
};

export type QueryexternalFacebookPageAnalyticsPostsArgs = {
  endDate?: InputMaybe<Scalars['Date']['input']>;
  facebookPageAnalyticsAccountId: Scalars['Int']['input'];
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  orderBy: FacebookPageAnalyticsPostsSortInput;
  startDate?: InputMaybe<Scalars['Date']['input']>;
};

export type QueryexternalFacebookPageAnalyticsPostsAnalyticsArgs = {
  endDate: Scalars['Date']['input'];
  facebookPageAnalyticsAccountId: Scalars['Int']['input'];
  startDate: Scalars['Date']['input'];
  timezoneOffset: Scalars['Int']['input'];
};

export type QueryexternalFacebookPageAnalyticsPostsByDateArgs = {
  date: Scalars['Date']['input'];
  facebookPageAnalyticsAccountId: Scalars['Int']['input'];
};

export type QueryexternalFacebookPageAnalyticsPostsCountArgs = {
  endDate?: InputMaybe<Scalars['Date']['input']>;
  facebookPageAnalyticsAccountId: Scalars['Int']['input'];
  keyword?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
};

export type QueryexternalInstagramAnalyticsPostDetailsArgs = {
  accountId: Scalars['Int']['input'];
  postId: Scalars['Int']['input'];
};

export type QueryexternalInstagramAnalyticsReelDetailsArgs = {
  accountId: Scalars['Int']['input'];
  postId: Scalars['Int']['input'];
};

export type QueryexternalInstagramAnalyticsStoryDetailsArgs = {
  accountId: Scalars['Int']['input'];
  storyId: Scalars['Int']['input'];
};

export type QueryexternalInstagramDashboardFollowerAnalyticsArgs = {
  accountId: Scalars['Int']['input'];
  endDate?: InputMaybe<Scalars['Date']['input']>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
};

export type QueryexternalInstagramDashboardOverviewArgs = {
  accountId: Scalars['Int']['input'];
  endDate?: InputMaybe<Scalars['Date']['input']>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
};

export type QueryexternalInstagramDashboardPostAnalyticsArgs = {
  accountId: Scalars['Int']['input'];
  endDate?: InputMaybe<Scalars['Date']['input']>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
  timezoneOffset: Scalars['Int']['input'];
};

export type QueryexternalYoutubeAnalyticsAudienceArgs = {
  endDate: Scalars['Date']['input'];
  startDate: Scalars['Date']['input'];
  youtubeAnalyticsAccountId: Scalars['Int']['input'];
};

export type QueryexternalYoutubeAnalyticsOverviewArgs = {
  endDate: Scalars['Date']['input'];
  startDate: Scalars['Date']['input'];
  youtubeAnalyticsAccountId: Scalars['Int']['input'];
};

export type QueryexternalYoutubeAnalyticsPostArgs = {
  youtubeAnalyticsAccountId: Scalars['Int']['input'];
  youtubeAnalyticsPostId: Scalars['Int']['input'];
};

export type QueryexternalYoutubeAnalyticsPostsArgs = {
  endDate?: InputMaybe<Scalars['Date']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  orderBy: ExternalYoutubeAnalyticsPostsSortInput;
  startDate?: InputMaybe<Scalars['Date']['input']>;
  youtubeAnalyticsAccountId: Scalars['Int']['input'];
};

export type QueryexternalYoutubeAnalyticsPostsByDateArgs = {
  date: Scalars['Date']['input'];
  youtubeAnalyticsAccountId: Scalars['Int']['input'];
};

export type QueryexternalYoutubeAnalyticsPostsCountArgs = {
  endDate?: InputMaybe<Scalars['Date']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
  youtubeAnalyticsAccountId: Scalars['Int']['input'];
};

export type QueryexternalYoutubeAnalyticsPostsStatisticsArgs = {
  endDate: Scalars['Date']['input'];
  startDate: Scalars['Date']['input'];
  timeDifferenceHour: Scalars['Int']['input'];
  youtubeAnalyticsAccountId: Scalars['Int']['input'];
};

export type QueryexternalYoutubeAnalyticsRelatedPostsArgs = {
  tag: Scalars['String']['input'];
  youtubeAnalyticsAccountId: Scalars['Int']['input'];
  youtubeAnalyticsPostId: Scalars['Int']['input'];
};

export type QueryexternalYoutubeAnalyticsTagsTopRankingArgs = {
  endDate?: InputMaybe<Scalars['Date']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  orderBy: ExternalYoutubeAnalyticsTagRankingSortInput;
  startDate?: InputMaybe<Scalars['Date']['input']>;
  youtubeAnalyticsAccountId: Scalars['Int']['input'];
};

export type QueryexternalYoutubeAnalyticsTagsTopRankingCountArgs = {
  endDate?: InputMaybe<Scalars['Date']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
  youtubeAnalyticsAccountId: Scalars['Int']['input'];
};

export type QueryfacebookPageAnalyticsAudienceArgs = {
  endDate: Scalars['Date']['input'];
  facebookPageAnalyticsAccountId: Scalars['Int']['input'];
  startDate: Scalars['Date']['input'];
};

export type QueryfacebookPageAnalyticsOverviewArgs = {
  endDate: Scalars['Date']['input'];
  facebookPageAnalyticsAccountId: Scalars['Int']['input'];
  startDate: Scalars['Date']['input'];
};

export type QueryfacebookPageAnalyticsPostArgs = {
  facebookPageAnalyticsAccountId: Scalars['Int']['input'];
  facebookPageAnalyticsPostId: Scalars['Int']['input'];
};

export type QueryfacebookPageAnalyticsPostsArgs = {
  endDate?: InputMaybe<Scalars['Date']['input']>;
  facebookPageAnalyticsAccountId: Scalars['Int']['input'];
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  orderBy: FacebookPageAnalyticsPostsSortInput;
  startDate?: InputMaybe<Scalars['Date']['input']>;
};

export type QueryfacebookPageAnalyticsPostsAnalyticsArgs = {
  endDate: Scalars['Date']['input'];
  facebookPageAnalyticsAccountId: Scalars['Int']['input'];
  startDate: Scalars['Date']['input'];
};

export type QueryfacebookPageAnalyticsPostsByDateArgs = {
  date: Scalars['Date']['input'];
  facebookPageAnalyticsAccountId: Scalars['Int']['input'];
};

export type QueryfacebookPageAnalyticsPostsCountArgs = {
  endDate?: InputMaybe<Scalars['Date']['input']>;
  facebookPageAnalyticsAccountId: Scalars['Int']['input'];
  keyword?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
};

export type QueryfindJoinedCampaignsArgs = {
  influencerId: Scalars['Int']['input'];
};

export type QuerygenerateAgencyLogoSignedUrlArgs = {
  fileNames: Array<Scalars['String']['input']>;
};

export type QuerygenerateMaterialSignedUrlArgs = {
  fileNames: Array<Scalars['String']['input']>;
};

export type QuerygeneratePostFileSignedUrlArgs = {
  fileName: Scalars['String']['input'];
};

export type QuerygeneratePostFileSignedUrlsArgs = {
  fileNames: Array<Scalars['String']['input']>;
};

export type QuerygenerateThumbnailSignedUrlArgs = {
  fileNames: Array<Scalars['String']['input']>;
};

export type QuerygenerateUploadReportSignedUrlArgs = {
  fileNames: Array<Scalars['String']['input']>;
};

export type QuerygetAllCampaignsCountForSearchJobArgs = {
  input: GetAllCampaignsCountForSearchJobInput;
};

export type QuerygetAllCampaignsForSearchJobArgs = {
  input: GetAllCampaignsForSearchJobInput;
};

export type QuerygetAnyXAccountsByKeywordArgs = {
  input: GetAnyXAccountsByKeywordInput;
};

export type QuerygetAutoManagedCampaignByIdArgs = {
  input: GetAutoManagedCampaignByIdInput;
};

export type QuerygetAutoManagedCampaignForInfluencerSearchJobArgs = {
  input: GetAutoManagedCampaignForInfluencerSearchJobInput;
};

export type QuerygetAutoManagedCampaignForInfluencerYourJobArgs = {
  input: GetAutoManagedCampaignForInfluencerYourJobInput;
};

export type QuerygetAutoManagedCampaignsArgs = {
  input: GetAutoManagedCampaignsInput;
};

export type QuerygetAutoManagedCampaignsCountArgs = {
  input: GetAutoManagedCampaignsCountInput;
};

export type QuerygetChatEventsArgs = {
  input: GetChatEventsInput;
};

export type QuerygetCouponsListArgs = {
  fileUrl: Scalars['String']['input'];
};

export type QuerygetCreatorStaffsArgs = {
  input: CreatorStaffsInput;
};

export type QuerygetCreatorTagsArgs = {
  input: GetCreatorTagsInput;
};

export type QuerygetCreatorTagsByIdArgs = {
  input: GetCreatorTagsByIdInput;
};

export type QuerygetCustomerTagsArgs = {
  input: GetCustomerTagsInput;
};

export type QuerygetEmailListArgs = {
  input: GetEmailListInput;
};

export type QuerygetEmailListCountArgs = {
  input: GetEmailListCountInput;
};

export type QuerygetEmailOverviewArgs = {
  input: GetEmailOverviewInput;
};

export type QuerygetEmailRecipientsArgs = {
  input: GetEmailRecipientsInput;
};

export type QuerygetEmailRecipientsCountArgs = {
  input: GetEmailRecipientsCountInput;
};

export type QuerygetEmailTemplateArgs = {
  input: GetEmailTemplateInput;
};

export type QuerygetFanArgs = {
  input: GetFanInput;
};

export type QuerygetFanActivitiesArgs = {
  input: GetFanActivitiesInput;
};

export type QuerygetFanFormResponseArgs = {
  input: GetFanFormResponseInput;
};

export type QuerygetFanHeaderArgs = {
  input: GetFanInput;
};

export type QuerygetFanOrdersArgs = {
  input: GetFanOrdersInput;
};

export type QuerygetFanParticipationFormListArgs = {
  input: GetFanParticipationFormListInput;
};

export type QuerygetFanProvincesArgs = {
  input: GetFanProvincesInput;
};

export type QuerygetFansArgs = {
  input: GetFansInput;
};

export type QuerygetFansCountArgs = {
  input: GetFansCountInput;
};

export type QuerygetFormArgs = {
  input: GetFormInput;
};

export type QuerygetFormReportSummaryArgs = {
  input: FormReportSummaryInput;
};

export type QuerygetFormRespondentsCountArgs = {
  input: FormRespondentsCountInput;
};

export type QuerygetFormRespondentsListArgs = {
  input: FormRespondentsListInput;
};

export type QuerygetFormResponseArgs = {
  input: GetFormResponseInput;
};

export type QuerygetFormResponseByPageArgs = {
  input: GetFormResponseByPageInput;
};

export type QuerygetFormTemplateArgs = {
  input: GetFormTemplateInput;
};

export type QuerygetFormsCountArgs = {
  input: GetFormsCountInput;
};

export type QuerygetFormsListArgs = {
  input: GetFormsListInput;
};

export type QuerygetInfluencerBioAnalyticsArgs = {
  input: GetInfluencerBioAnalyticsInput;
};

export type QuerygetInfluencerRateCardArgs = {
  input: InfluencerRateCardInput;
};

export type QuerygetLineBroadcastEstimateRecipientsArgs = {
  input: GetLineBroadcastEstimateRecipientsInput;
};

export type QuerygetLineBroadcastMessageArgs = {
  input: GetLineBroadcastMessageInput;
};

export type QuerygetLineBroadcastSummaryArgs = {
  input: GetLineBroadcastSummaryInput;
};

export type QuerygetLineBroadcastsArgs = {
  input: GetLineBroadcastsInput;
};

export type QuerygetLineBroadcastsCountArgs = {
  input: GetLineBroadcastsCountInput;
};

export type QuerygetLineChatArgs = {
  input: GetLineChatInput;
};

export type QuerygetLineChatCountArgs = {
  input: GetLineChatCountInput;
};

export type QuerygetLineChatsArgs = {
  input: GetLineChatsInput;
};

export type QuerygetLinkBioFacebookAccountPostsArgs = {
  input: GetLinkBioFacebookAccountPostsInput;
};

export type QuerygetLinkBioFacebookPagePostsArgs = {
  input: GetLinkBioFacebookPagePostsInput;
};

export type QuerygetMarketplaceCampaignForInfluencerArgs = {
  pk: Scalars['Int']['input'];
};

export type QuerygetNotificationsArgs = {
  input?: InputMaybe<UnreadNotificationInput>;
};

export type QuerygetNotificationsByAppArgs = {
  input?: InputMaybe<NotificationsByAppInput>;
};

export type QuerygetPublicInfluencerBioArgs = {
  input: GetPublicInfluencerBioInput;
};

export type QuerygetSocialAccountPermissionsArgs = {
  input: SocialAccountPermissionsInput;
};

export type QuerygetTagsAndFansRecipientsArgs = {
  input: GetTagsAndFansRecipientsInput;
};

export type QuerygetUserDefaultPermissionsArgs = {
  role: UserRoles;
};

export type QuerygetUserDetailArgs = {
  input: UserDetailInput;
};

export type QuerygetYoutubeMCNRedirectUrlForAdminArgs = {
  input: YoutubeMCNAdminRedirectUrlInput;
};

export type QueryhubspotCompanyListArgs = {
  keyword?: InputMaybe<Scalars['String']['input']>;
};

export type QueryhubspotCompanyNameArgs = {
  hubspotId: Scalars['String']['input'];
};

export type QueryhubspotDealNameArgs = {
  hubspotDealIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type QueryhubspotDealsListArgs = {
  countryId?: InputMaybe<Scalars['String']['input']>;
  keyword: Scalars['String']['input'];
};

export type QueryinfluencerAllInstagramFeedPostsArgs = {
  endDate?: InputMaybe<Scalars['Date']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  orderBy?: InputMaybe<InstagramFeedPostOrderBy>;
  socialAccountId: Scalars['Int']['input'];
  startDate?: InputMaybe<Scalars['Date']['input']>;
};

export type QueryinfluencerAllInstagramFeedPostsCountArgs = {
  endDate?: InputMaybe<Scalars['Date']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  socialAccountId: Scalars['Int']['input'];
  startDate?: InputMaybe<Scalars['Date']['input']>;
};

export type QueryinfluencerAllInstagramInteractionUsersArgs = {
  endDate?: InputMaybe<Scalars['Date']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  orderBy?: InputMaybe<InstagramInteractionUserOrderBy>;
  socialAccountId: Scalars['Int']['input'];
  startDate?: InputMaybe<Scalars['Date']['input']>;
};

export type QueryinfluencerAllInstagramMentionedPostsArgs = {
  endDate?: InputMaybe<Scalars['Date']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  orderBy?: InputMaybe<InstagramFeedPostOrderBy>;
  socialAccountId: Scalars['Int']['input'];
  startDate?: InputMaybe<Scalars['Date']['input']>;
};

export type QueryinfluencerAllInstagramMentionedPostsCountArgs = {
  endDate?: InputMaybe<Scalars['Date']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  socialAccountId: Scalars['Int']['input'];
  startDate?: InputMaybe<Scalars['Date']['input']>;
};

export type QueryinfluencerAllInstagramPostsInDateArgs = {
  postDate: Scalars['Date']['input'];
  socialAccountId: Scalars['Int']['input'];
};

export type QueryinfluencerAllInstagramReelPostsArgs = {
  endDate?: InputMaybe<Scalars['Date']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  orderBy?: InputMaybe<InstagramReelPostOrderBy>;
  socialAccountId: Scalars['Int']['input'];
  startDate?: InputMaybe<Scalars['Date']['input']>;
};

export type QueryinfluencerAllInstagramReelPostsCountArgs = {
  endDate?: InputMaybe<Scalars['Date']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  socialAccountId: Scalars['Int']['input'];
  startDate?: InputMaybe<Scalars['Date']['input']>;
};

export type QueryinfluencerAllInstagramStoryPostsArgs = {
  endDate?: InputMaybe<Scalars['Date']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  orderBy?: InputMaybe<InstagramStoryPostOrderBy>;
  socialAccountId: Scalars['Int']['input'];
  startDate?: InputMaybe<Scalars['Date']['input']>;
};

export type QueryinfluencerAllInstagramStoryPostsCountArgs = {
  endDate?: InputMaybe<Scalars['Date']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  socialAccountId: Scalars['Int']['input'];
  startDate?: InputMaybe<Scalars['Date']['input']>;
};

export type QueryinfluencerAllInstagramTaggedPostsArgs = {
  endDate?: InputMaybe<Scalars['Date']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  orderBy?: InputMaybe<InstagramFeedPostOrderBy>;
  socialAccountId: Scalars['Int']['input'];
  startDate?: InputMaybe<Scalars['Date']['input']>;
};

export type QueryinfluencerAllInstagramTaggedPostsCountArgs = {
  endDate?: InputMaybe<Scalars['Date']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  socialAccountId: Scalars['Int']['input'];
  startDate?: InputMaybe<Scalars['Date']['input']>;
};

export type QueryinfluencerAnalyticsFacebookPageAccessTokenWarningArgs = {
  influencerId: Scalars['Int']['input'];
  socialAccountId: Scalars['Int']['input'];
};

export type QueryinfluencerAnalyticsInstagramAccessTokenWarningArgs = {
  socialAccountId: Scalars['Int']['input'];
};

export type QueryinfluencerAnalyticsMultipleInstagramAccessTokenWarningArgs = {
  socialAccountIds: Array<Scalars['Int']['input']>;
};

export type QueryinfluencerAnalyticsTwitterAccessTokenWarningArgs = {
  socialAccountId: Scalars['Int']['input'];
};

export type QueryinfluencerAnalyticsTwitterRefreshAvailabilityArgs = {
  socialAccountId: Scalars['Int']['input'];
};

export type QueryinfluencerCampaignListArgs = {
  campaignType?: InputMaybe<CampaignType>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<InfluencerCampaignOrderBy>;
  pk: Scalars['Int']['input'];
  socialMedias?: InputMaybe<Array<CampaignSocialMediaType>>;
};

export type QueryinfluencerCampaignListByPromotionMethodArgs = {
  influencerId: Scalars['Int']['input'];
  promotionMethodId: Scalars['Int']['input'];
};

export type QueryinfluencerCampaignSummaryArgs = {
  pk: Scalars['Int']['input'];
};

export type QueryinfluencerFacebookPageAnalyticsAudienceArgs = {
  endDate?: InputMaybe<Scalars['Date']['input']>;
  influencerId: Scalars['Int']['input'];
  socialAccountId: Scalars['Int']['input'];
  startDate?: InputMaybe<Scalars['Date']['input']>;
};

export type QueryinfluencerFacebookPageAnalyticsOverviewArgs = {
  endDate?: InputMaybe<Scalars['Date']['input']>;
  influencerId: Scalars['Int']['input'];
  socialAccountId: Scalars['Int']['input'];
  startDate?: InputMaybe<Scalars['Date']['input']>;
};

export type QueryinfluencerFacebookPageAnalyticsPostArgs = {
  influencerId: Scalars['Int']['input'];
  postId: Scalars['Int']['input'];
  socialAccountId: Scalars['Int']['input'];
};

export type QueryinfluencerFacebookPageAnalyticsPostsArgs = {
  endDate?: InputMaybe<Scalars['Date']['input']>;
  influencerId: Scalars['Int']['input'];
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  orderBy: FacebookPageAnalyticsPostsSortInput;
  socialAccountId: Scalars['Int']['input'];
  startDate?: InputMaybe<Scalars['Date']['input']>;
};

export type QueryinfluencerFacebookPageAnalyticsPostsAnalyticsArgs = {
  endDate: Scalars['Date']['input'];
  influencerId: Scalars['Int']['input'];
  socialAccountId: Scalars['Int']['input'];
  startDate: Scalars['Date']['input'];
};

export type QueryinfluencerFacebookPageAnalyticsPostsByDateArgs = {
  date: Scalars['Date']['input'];
  influencerId: Scalars['Int']['input'];
  socialAccountId: Scalars['Int']['input'];
};

export type QueryinfluencerFacebookPageAnalyticsPostsCountArgs = {
  endDate?: InputMaybe<Scalars['Date']['input']>;
  influencerId: Scalars['Int']['input'];
  keyword?: InputMaybe<Scalars['String']['input']>;
  socialAccountId: Scalars['Int']['input'];
  startDate?: InputMaybe<Scalars['Date']['input']>;
};

export type QueryinfluencerHasEstimateApiArgs = {
  pk: Scalars['Int']['input'];
  socialAccountId: Scalars['Int']['input'];
  socialMedia: SocialAccountType;
};

export type QueryinfluencerInstagramAnalyticsPostDetailsArgs = {
  postId: Scalars['Int']['input'];
  socialAccountId: Scalars['Int']['input'];
};

export type QueryinfluencerInstagramAnalyticsReelDetailsArgs = {
  postId: Scalars['Int']['input'];
  socialAccountId: Scalars['Int']['input'];
};

export type QueryinfluencerInstagramAnalyticsStoryDetailsArgs = {
  socialAccountId: Scalars['Int']['input'];
  storyId: Scalars['Int']['input'];
};

export type QueryinfluencerInstagramDashboardFollowerAnalyticsArgs = {
  endDate?: InputMaybe<Scalars['Date']['input']>;
  socialAccountId: Scalars['Int']['input'];
  startDate?: InputMaybe<Scalars['Date']['input']>;
};

export type QueryinfluencerInstagramDashboardOverviewArgs = {
  endDate?: InputMaybe<Scalars['Date']['input']>;
  socialAccountId: Scalars['Int']['input'];
  startDate?: InputMaybe<Scalars['Date']['input']>;
};

export type QueryinfluencerInstagramDashboardPostAnalyticsArgs = {
  endDate?: InputMaybe<Scalars['Date']['input']>;
  socialAccountId: Scalars['Int']['input'];
  startDate?: InputMaybe<Scalars['Date']['input']>;
};

export type QueryinfluencerInstagramInteractionMentionedPostDetailsArgs = {
  postId: Scalars['Int']['input'];
  socialAccountId: Scalars['Int']['input'];
};

export type QueryinfluencerInstagramInteractionOverviewArgs = {
  endDate?: InputMaybe<Scalars['Date']['input']>;
  socialAccountId: Scalars['Int']['input'];
  startDate?: InputMaybe<Scalars['Date']['input']>;
};

export type QueryinfluencerInstagramInteractionPostsArgs = {
  endDate?: InputMaybe<Scalars['Date']['input']>;
  socialAccountId: Scalars['Int']['input'];
  startDate?: InputMaybe<Scalars['Date']['input']>;
};

export type QueryinfluencerInstagramInteractionTaggedPostDetailsArgs = {
  postId: Scalars['Int']['input'];
  socialAccountId: Scalars['Int']['input'];
};

export type QueryinfluencerInterestsArgs = {
  influencerId: Scalars['Int']['input'];
  socialAccountId: Scalars['Int']['input'];
  socialMedia: SocialAccountType;
};

export type QueryinfluencerPaymentRequestsArgs = {
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
};

export type QueryinfluencerPostDetailArgs = {
  needSimilarPosts?: InputMaybe<Scalars['Boolean']['input']>;
  pk: Scalars['Int']['input'];
  postId: Scalars['Int']['input'];
};

export type QueryinfluencerPostDetailForTalentAgencyArgs = {
  needSimilarPosts?: InputMaybe<Scalars['Boolean']['input']>;
  pk: Scalars['Int']['input'];
  postId: Scalars['Int']['input'];
};

export type QueryinfluencerProfileAudienceArgs = {
  pk: Scalars['Int']['input'];
  socialAccountId: Scalars['Int']['input'];
  socialMedia: SocialAccountType;
};

export type QueryinfluencerProfileDetailForEngagementProposalArgs = {
  engagementId: Scalars['Int']['input'];
  influencerId: Scalars['Int']['input'];
  socialAccountId: Scalars['Int']['input'];
  socialMedia: SocialAccountType;
};

export type QueryinfluencerProfileDetailV2Args = {
  pk: Scalars['Int']['input'];
  socialAccountId: Scalars['Int']['input'];
  socialMedia: SocialAccountType;
};

export type QueryinfluencerProfileFollowerGrowthArgs = {
  pk: Scalars['Int']['input'];
  socialAccountId: Scalars['Int']['input'];
  socialMedia: SocialAccountType;
};

export type QueryinfluencerProfileForEngagementProposalArgs = {
  engagementId: Scalars['Int']['input'];
  influencerId: Scalars['Int']['input'];
};

export type QueryinfluencerProfilePostsArgs = {
  pk: Scalars['Int']['input'];
  socialAccountId: Scalars['Int']['input'];
  socialMedia: SocialAccountType;
};

export type QueryinfluencerProfileV2Args = {
  pk: Scalars['Int']['input'];
};

export type QueryinfluencerTwitterAnalyticsOverviewArgs = {
  endDate?: InputMaybe<Scalars['Date']['input']>;
  socialAccountId: Scalars['Int']['input'];
  startDate?: InputMaybe<Scalars['Date']['input']>;
};

export type QueryinfluencerTwitterAnalyticsPostArgs = {
  socialAccountId: Scalars['Int']['input'];
  twitterAnalyticsPostId: Scalars['Int']['input'];
};

export type QueryinfluencerTwitterAnalyticsPostsArgs = {
  endDate?: InputMaybe<Scalars['Date']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  orderBy: TwitterAnalyticsPostsSortInput;
  socialAccountId: Scalars['Int']['input'];
  startDate?: InputMaybe<Scalars['Date']['input']>;
};

export type QueryinfluencerTwitterAnalyticsPostsByDateArgs = {
  date: Scalars['Date']['input'];
  socialAccountId: Scalars['Int']['input'];
};

export type QueryinfluencerTwitterAnalyticsPostsCountArgs = {
  endDate?: InputMaybe<Scalars['Date']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  socialAccountId: Scalars['Int']['input'];
  startDate?: InputMaybe<Scalars['Date']['input']>;
};

export type QueryinfluencerTwitterAnalyticsPostsStatisticArgs = {
  endDate: Scalars['Date']['input'];
  socialAccountId: Scalars['Int']['input'];
  startDate: Scalars['Date']['input'];
};

export type QueryinfluencerUpdateDetailV2Args = {
  pk: Scalars['Int']['input'];
};

export type QueryinfluencerWhitelistArgs = {
  marketplaceId: Scalars['Int']['input'];
};

export type QueryinfluencerYoutubeAnalyticsAudienceArgs = {
  endDate?: InputMaybe<Scalars['Date']['input']>;
  socialAccountId: Scalars['Int']['input'];
  startDate?: InputMaybe<Scalars['Date']['input']>;
};

export type QueryinfluencerYoutubeAnalyticsChannelDetailsArgs = {
  socialAccountId: Scalars['Int']['input'];
};

export type QueryinfluencerYoutubeAnalyticsCompareAccountCardsArgs = {
  socialAccountId: Scalars['Int']['input'];
};

export type QueryinfluencerYoutubeAnalyticsCompareRelatedPostsArgs = {
  compareAccountId: Scalars['Int']['input'];
  postId: Scalars['Int']['input'];
  socialAccountId: Scalars['Int']['input'];
  tag: Scalars['String']['input'];
};

export type QueryinfluencerYoutubeAnalyticsOverviewArgs = {
  endDate?: InputMaybe<Scalars['Date']['input']>;
  socialAccountId: Scalars['Int']['input'];
  startDate?: InputMaybe<Scalars['Date']['input']>;
};

export type QueryinfluencerYoutubeAnalyticsPostArgs = {
  socialAccountId: Scalars['Int']['input'];
  youtubeAnalyticsPostId: Scalars['Int']['input'];
};

export type QueryinfluencerYoutubeAnalyticsPostsArgs = {
  endDate?: InputMaybe<Scalars['Date']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  orderBy: YoutubeAnalyticsPostsSortInput;
  socialAccountId: Scalars['Int']['input'];
  sponsoredPostsOnly?: InputMaybe<Scalars['Boolean']['input']>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
};

export type QueryinfluencerYoutubeAnalyticsPostsByDateArgs = {
  date: Scalars['Date']['input'];
  socialAccountId: Scalars['Int']['input'];
};

export type QueryinfluencerYoutubeAnalyticsPostsCountArgs = {
  endDate?: InputMaybe<Scalars['Date']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  socialAccountId: Scalars['Int']['input'];
  sponsoredPostsOnly?: InputMaybe<Scalars['Boolean']['input']>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
};

export type QueryinfluencerYoutubeAnalyticsPostsStatisticsArgs = {
  endDate: Scalars['Date']['input'];
  socialAccountId: Scalars['Int']['input'];
  startDate: Scalars['Date']['input'];
};

export type QueryinfluencerYoutubeAnalyticsRelatedPostsArgs = {
  socialAccountId: Scalars['Int']['input'];
  tag: Scalars['String']['input'];
  youtubeAnalyticsPostId: Scalars['Int']['input'];
};

export type QueryinfluencerYoutubeAnalyticsShortsArgs = {
  endDate?: InputMaybe<Scalars['Date']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  orderBy: YoutubeAnalyticsPostsSortInput;
  socialAccountId: Scalars['Int']['input'];
  sponsoredPostsOnly?: InputMaybe<Scalars['Boolean']['input']>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
};

export type QueryinfluencerYoutubeAnalyticsShortsCountArgs = {
  endDate?: InputMaybe<Scalars['Date']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  socialAccountId: Scalars['Int']['input'];
  sponsoredPostsOnly?: InputMaybe<Scalars['Boolean']['input']>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
};

export type QueryinfluencerYoutubeAnalyticsSponsoredPostsArgs = {
  endDate?: InputMaybe<Scalars['Date']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<YoutubeAnalyticsSponsoredPostsSortInput>;
  socialAccountId: Scalars['Long']['input'];
  startDate?: InputMaybe<Scalars['Date']['input']>;
};

export type QueryinfluencerYoutubeAnalyticsSponsoredPostsCountArgs = {
  endDate?: InputMaybe<Scalars['Date']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  socialAccountId: Scalars['Long']['input'];
  startDate?: InputMaybe<Scalars['Date']['input']>;
};

export type QueryinfluencerYoutubeAnalyticsTagsTopRankingArgs = {
  endDate?: InputMaybe<Scalars['Date']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  orderBy: YoutubeAnalyticsTagRankingSortInput;
  socialAccountId: Scalars['Int']['input'];
  startDate?: InputMaybe<Scalars['Date']['input']>;
};

export type QueryinfluencerYoutubeAnalyticsTagsTopRankingCountArgs = {
  endDate?: InputMaybe<Scalars['Date']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  socialAccountId: Scalars['Int']['input'];
  startDate?: InputMaybe<Scalars['Date']['input']>;
};

export type QueryinfluencerYoutubeCompareAllPostsInDateArgs = {
  date: Scalars['Date']['input'];
  socialAccountId: Scalars['Int']['input'];
};

export type QueryinfluencerYoutubeCompareOverviewArgs = {
  endDate: Scalars['Date']['input'];
  socialAccountId: Scalars['Int']['input'];
  startDate: Scalars['Date']['input'];
};

export type QueryinfluencerYoutubeComparePostByIdArgs = {
  compareAccountId: Scalars['Int']['input'];
  postId: Scalars['Int']['input'];
  socialAccountId: Scalars['Int']['input'];
};

export type QueryinfluencerYoutubeComparePostListArgs = {
  compareAccountId: Scalars['Int']['input'];
  endDate?: InputMaybe<Scalars['Date']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  orderBy: YoutubeAnalyticsComparePostsSort;
  socialAccountId: Scalars['Int']['input'];
  startDate?: InputMaybe<Scalars['Date']['input']>;
};

export type QueryinfluencerYoutubeComparePostsArgs = {
  endDate?: InputMaybe<Scalars['Date']['input']>;
  socialAccountId: Scalars['Int']['input'];
  startDate?: InputMaybe<Scalars['Date']['input']>;
};

export type QueryinfluencerYoutubeComparePostsCountArgs = {
  compareAccountId: Scalars['Int']['input'];
  endDate?: InputMaybe<Scalars['Date']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  socialAccountId: Scalars['Int']['input'];
  startDate?: InputMaybe<Scalars['Date']['input']>;
};

export type QueryinfluencerYoutubeCompareTagRankingArgs = {
  endDate: Scalars['Date']['input'];
  socialAccountId: Scalars['Int']['input'];
  startDate: Scalars['Date']['input'];
};

export type QueryinstagramAccountInfoArgs = {
  accountId: Scalars['Int']['input'];
};

export type QueryinstagramAmbassadorPostDetailsArgs = {
  accountId: Scalars['Int']['input'];
  postId: Scalars['String']['input'];
};

export type QueryinstagramAnalyticsHashtagsArgs = {
  accountId: Scalars['Int']['input'];
};

export type QueryinstagramAnalyticsListCommentsArgs = {
  instagramAnalyticsAccountId: Scalars['Int']['input'];
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
};

export type QueryinstagramAnalyticsListCommentsCountArgs = {
  instagramAnalyticsAccountId: Scalars['Int']['input'];
  keyword?: InputMaybe<Scalars['String']['input']>;
};

export type QueryinstagramAnalyticsPostDetailsArgs = {
  postId: Scalars['Int']['input'];
};

export type QueryinstagramAnalyticsReelDetailsArgs = {
  accountId: Scalars['Int']['input'];
  postId: Scalars['Int']['input'];
};

export type QueryinstagramAnalyticsStoryDetailsArgs = {
  accountId: Scalars['Int']['input'];
  storyId: Scalars['Int']['input'];
};

export type QueryinstagramCompareAccountsArgs = {
  instagramAnalyticsAccountId: Scalars['Int']['input'];
};

export type QueryinstagramCompareFollowersArgs = {
  accountId: Scalars['Int']['input'];
  comparableAccountIds: Array<Scalars['Int']['input']>;
  endDate?: InputMaybe<Scalars['Date']['input']>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
};

export type QueryinstagramCompareGetAccountsArgs = {
  accountToCompareWithId: Scalars['Int']['input'];
};

export type QueryinstagramCompareOverviewArgs = {
  comparableAccountIds: Array<Scalars['Int']['input']>;
  endDate?: InputMaybe<Scalars['Date']['input']>;
  mainAccountId: Scalars['Int']['input'];
  startDate?: InputMaybe<Scalars['Date']['input']>;
};

export type QueryinstagramComparePostDetailsArgs = {
  postId: Scalars['Int']['input'];
};

export type QueryinstagramComparePostsArgs = {
  accountId: Scalars['Int']['input'];
  comparableAccountIds: Array<Scalars['Int']['input']>;
  endDate?: InputMaybe<Scalars['Date']['input']>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
};

export type QueryinstagramDashboardFollowerAnalyticsArgs = {
  accountId: Scalars['Int']['input'];
  endDate?: InputMaybe<Scalars['Date']['input']>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
};

export type QueryinstagramDashboardOverviewArgs = {
  accountId: Scalars['Int']['input'];
  endDate?: InputMaybe<Scalars['Date']['input']>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
};

export type QueryinstagramDashboardPostAnalyticsArgs = {
  accountId: Scalars['Int']['input'];
  endDate?: InputMaybe<Scalars['Date']['input']>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
};

export type QueryinstagramHashtagFeedPostsV2Args = {
  date: Scalars['Date']['input'];
  hashtagId: Scalars['Int']['input'];
  instagramAnalyticsAccountId: Scalars['Int']['input'];
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  orderBy?: InputMaybe<InstagramPostSortInput>;
};

export type QueryinstagramHashtagPostDetailsArgs = {
  postId: Scalars['Int']['input'];
};

export type QueryinstagramHashtagPostsArgs = {
  analyticsAccountId: Scalars['Int']['input'];
  endDate?: InputMaybe<Scalars['Date']['input']>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
};

export type QueryinstagramHashtagPostsV2Args = {
  date: Scalars['Date']['input'];
  instagramAnalyticsAccountId: Scalars['Int']['input'];
};

export type QueryinstagramHashtagsOverviewArgs = {
  analyticsAccountId: Scalars['Int']['input'];
  endDate?: InputMaybe<Scalars['Date']['input']>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
};

export type QueryinstagramInteractionMentionedPostDetailsArgs = {
  accountId: Scalars['Int']['input'];
  postId: Scalars['Int']['input'];
};

export type QueryinstagramInteractionOverviewArgs = {
  analyticsAccountId: Scalars['Int']['input'];
  endDate?: InputMaybe<Scalars['Date']['input']>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
};

export type QueryinstagramInteractionPostsArgs = {
  analyticsAccountId: Scalars['Int']['input'];
  endDate?: InputMaybe<Scalars['Date']['input']>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
};

export type QueryinstagramInteractionTaggedPostDetailsArgs = {
  accountId: Scalars['Int']['input'];
  postId: Scalars['Int']['input'];
};

export type QueryinstagramUGCManagementBasicInfoArgs = {
  instagramAnalyticsAccountId: Scalars['Int']['input'];
};

export type QueryinstagramUGCManagementListPostsArgs = {
  endDate?: InputMaybe<Scalars['Date']['input']>;
  hashtagSystemId?: InputMaybe<Scalars['Int']['input']>;
  instagramAnalyticsAccountId: Scalars['Int']['input'];
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  orderBy: InstagramUGCPostsSortInput;
  postType?: InputMaybe<UGCPostType>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
  statuses?: InputMaybe<Array<UGCPostStatus>>;
};

export type QueryinstagramUGCManagementListPostsCountArgs = {
  endDate?: InputMaybe<Scalars['Date']['input']>;
  hashtagSystemId?: InputMaybe<Scalars['Int']['input']>;
  instagramAnalyticsAccountId: Scalars['Int']['input'];
  keyword?: InputMaybe<Scalars['String']['input']>;
  postType?: InputMaybe<UGCPostType>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
  statuses?: InputMaybe<Array<UGCPostStatus>>;
};

export type QueryinstagramUGCManagementPostDetailsArgs = {
  instagramAnalyticsAccountId: Scalars['Int']['input'];
  postSNSId: Scalars['String']['input'];
};

export type QuerymarketplaceArgs = {
  pk: Scalars['Int']['input'];
};

export type QuerymarketplaceAffiliateCommissionFixedDatesArgs = {
  marketplaceId: Scalars['Int']['input'];
};

export type QuerymarketplaceAffiliateCommissionInfluencersArgs = {
  fixedDate?: InputMaybe<Scalars['Date']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Int']['input'];
  marketplaceId: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  status?: InputMaybe<MarketplaceAffiliateCommissionStatus>;
};

export type QuerymarketplaceAffiliateCommissionInfluencersCountArgs = {
  fixedDate?: InputMaybe<Scalars['Date']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  marketplaceId: Scalars['Int']['input'];
  status?: InputMaybe<MarketplaceAffiliateCommissionStatus>;
};

export type QuerymarketplaceAffiliateJoinedInfluencersArgs = {
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Int']['input'];
  marketplaceId: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
};

export type QuerymarketplaceAffiliateJoinedInfluencersCountArgs = {
  keyword?: InputMaybe<Scalars['String']['input']>;
  marketplaceId: Scalars['Int']['input'];
};

export type QuerymarketplaceAffiliateReportByDateArgs = {
  campaignId: Scalars['Int']['input'];
  endDate?: InputMaybe<Scalars['Date']['input']>;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  startDate?: InputMaybe<Scalars['Date']['input']>;
};

export type QuerymarketplaceAffiliateReportByDateCountArgs = {
  campaignId: Scalars['Int']['input'];
  endDate?: InputMaybe<Scalars['Date']['input']>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
};

export type QuerymarketplaceAffiliateReportByInfluencerArgs = {
  campaignId: Scalars['Int']['input'];
  endDate?: InputMaybe<Scalars['Date']['input']>;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  orderBy?: InputMaybe<MarketplaceAffiliateReportByInfluencerOrderBy>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
};

export type QuerymarketplaceAffiliateReportByInfluencerCountArgs = {
  campaignId: Scalars['Int']['input'];
  endDate?: InputMaybe<Scalars['Date']['input']>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
};

export type QuerymarketplaceAffiliateReportCountForInfluencerArgs = {
  campaignId: Scalars['Int']['input'];
};

export type QuerymarketplaceAffiliateReportForInfluencerArgs = {
  campaignId: Scalars['Int']['input'];
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
};

export type QuerymarketplaceAffiliateReportSummaryArgs = {
  campaignId: Scalars['Int']['input'];
  endDate?: InputMaybe<Scalars['Date']['input']>;
  showBy?: InputMaybe<AffiliateReportShowBy>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
};

export type QuerymarketplaceAffiliateReportSummaryForInfluencerArgs = {
  campaignId: Scalars['Int']['input'];
};

export type QuerymarketplaceCampaignPostReportForInfluencerArgs = {
  campaignId: Scalars['Int']['input'];
};

export type QuerymarketplaceCampaignReportArgs = {
  campaignId: Scalars['Int']['input'];
  endDate?: InputMaybe<Scalars['Date']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<CampaignReportOrderBy>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
};

export type QuerymarketplaceCampaignReportGridArgs = {
  campaignId: Scalars['Int']['input'];
};

export type QuerymarketplaceCampaignReportSummaryArgs = {
  campaignId: Scalars['Int']['input'];
  endDate?: InputMaybe<Scalars['Date']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
};

export type QuerymarketplaceDraftPostArgs = {
  draftId: Scalars['Int']['input'];
};

export type QuerymarketplaceDraftPostsForInfluencerArgs = {
  marketplaceId: Scalars['Int']['input'];
};

export type QuerymarketplaceForInfluencerArgs = {
  pk: Scalars['Int']['input'];
};

export type QuerymarketplaceGoogleSlidesHistoryArgs = {
  campaignId: Scalars['Int']['input'];
};

export type QuerymarketplaceHashtagCloudArgs = {
  advertiser?: InputMaybe<Scalars['String']['input']>;
  campaignCountry?: InputMaybe<Scalars['String']['input']>;
  campaignTitle?: InputMaybe<Scalars['String']['input']>;
  influencerCategory?: InputMaybe<Array<Scalars['Int']['input']>>;
  postRequirements?: InputMaybe<Scalars['String']['input']>;
  productInfo?: InputMaybe<Scalars['String']['input']>;
  socialMedia?: InputMaybe<CampaignSocialMediaType>;
  tags?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type QuerymarketplaceJoinableAccountsArgs = {
  campaignId: Scalars['Int']['input'];
};

export type QuerymarketplaceJoinedAccountArgs = {
  campaignId: Scalars['Int']['input'];
};

export type QuerymarketplaceJoinedMethodsArgs = {
  input: MarketplaceJoinedMethodsInput;
};

export type QuerymarketplacePostArgs = {
  pk: Scalars['Int']['input'];
};

export type QuerymarketplacePostCommentsArgs = {
  limit: Scalars['Int']['input'];
  postId: Scalars['Int']['input'];
};

export type QuerymarketplacePostHistoryArgs = {
  draftId?: InputMaybe<Scalars['Int']['input']>;
  pk?: InputMaybe<Scalars['Int']['input']>;
};

export type QuerymarketplaceTrackingArgs = {
  marketplaceId: Scalars['Int']['input'];
};

export type QuerymarketplaceTrackingPostUrlForInfluencerArgs = {
  marketplaceId: Scalars['Int']['input'];
};

export type QuerynetsuiteCustomerListArgs = {
  keyword?: InputMaybe<Scalars['String']['input']>;
};

export type QuerynetsuiteCustomerNameArgs = {
  netsuiteId: Scalars['String']['input'];
};

export type QuerynetsuiteVendorListArgs = {
  keyword?: InputMaybe<Scalars['String']['input']>;
};

export type QuerynetsuiteVendorNameArgs = {
  netsuiteId: Scalars['String']['input'];
};

export type QuerypackageArgs = {
  packageId: Scalars['Int']['input'];
};

export type QuerypackageInfluencersArgs = {
  keyword?: InputMaybe<Scalars['String']['input']>;
  packageId: Scalars['Int']['input'];
};

export type QuerypartnerAgencyArgs = {
  pk: Scalars['Int']['input'];
};

export type QuerypartnerAgencyBusinessAssociatesArgs = {
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  partnerAgencyId: Scalars['Int']['input'];
};

export type QuerypartnerAgencyBusinessAssociatesCountArgs = {
  keyword?: InputMaybe<Scalars['String']['input']>;
  partnerAgencyId: Scalars['Int']['input'];
};

export type QuerypartnerReconnectAnalyticsSocialAuthRedirectUrlArgs = {
  callbackUrl: Scalars['String']['input'];
  provider: AnalyticsAuthSocialAccountType;
};

export type QuerypartnerYoutubeAnalyticsPostArgs = {
  partnerInfluencerAccountId: Scalars['Int']['input'];
  youtubeAnalyticsPostId: Scalars['Int']['input'];
};

export type QuerypaymentInformationArgs = {
  influencerId: Scalars['Int']['input'];
};

export type QueryrefreshProfileAccountAvailabilityArgs = {
  input: RefreshProfileAccountAvailabilityInput;
};

export type QueryrefreshTwitterAccountAvailabilityArgs = {
  input: RefreshTwitterAccountAvailabilityInput;
};

export type QueryreportForInfluencerArgs = {
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
};

export type QueryrequestedBcaBrandsArgs = {
  influencerId: Scalars['Int']['input'];
};

export type QuerysearchEngCampaignsSelectInfluencersArgs = {
  categoryIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  countryIds?: InputMaybe<Array<Scalars['String']['input']>>;
  currentCampaignId: Scalars['Int']['input'];
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
};

export type QuerysearchEngCampaignsSelectInfluencersCountArgs = {
  categoryIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  countryIds?: InputMaybe<Array<Scalars['String']['input']>>;
  currentCampaignId: Scalars['Int']['input'];
  keyword?: InputMaybe<Scalars['String']['input']>;
};

export type QuerysearchPrivatePartnerInfluencersArgs = {
  alreadySelectedInfluencers: Array<Scalars['Int']['input']>;
  keyword: Scalars['String']['input'];
  partnerId: Scalars['Int']['input'];
};

export type QuerysearchPrivateTalentInfluencersArgs = {
  alreadySelectedInfluencers: Array<Scalars['Int']['input']>;
  keyword: Scalars['String']['input'];
  talentAgencyId: Scalars['Int']['input'];
};

export type QueryselectableBcaBrandsArgs = {
  influencerId: Scalars['Int']['input'];
};

export type QueryshopifyAuthRedirectUrlArgs = {
  input: ShopifyAuthRedirectUrlInput;
};

export type QuerysocialAuthRedirectUrlForInfluencerArgs = {
  input: SocialAuthRedirectUrlForInfluencerInput;
};

export type QuerysponsoredPostsArgs = {
  influencerId: Scalars['Int']['input'];
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<SponsoredPostOrderBy>;
  socialAccountId: Scalars['Int']['input'];
  socialMedia: SocialAccountType;
};

export type QuerysponsoredPostsCountArgs = {
  influencerId: Scalars['Int']['input'];
  keyword?: InputMaybe<Scalars['String']['input']>;
  socialAccountId: Scalars['Int']['input'];
  socialMedia: SocialAccountType;
};

export type QuerytalentAgencyArgs = {
  pk: Scalars['Int']['input'];
};

export type QuerytalentAgencyBusinessAssociateCompaniesArgs = {
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  talentAgencyId: Scalars['Int']['input'];
};

export type QuerytalentAgencyBusinessAssociateCompaniesCountArgs = {
  keyword?: InputMaybe<Scalars['String']['input']>;
  talentAgencyId: Scalars['Int']['input'];
};

export type QuerytalentAgencyReconnectAnalyticsSocialAuthRedirectUrlArgs = {
  callbackUrl: Scalars['String']['input'];
  provider: AnalyticsAuthSocialAccountType;
};

export type QuerytalentInfluencerDetailsForEditArgs = {
  input: TalentInfluencerDetailsForEditInput;
};

export type QuerytiktokProfileAudienceArgs = {
  pk: Scalars['Int']['input'];
  socialAccountId: Scalars['Int']['input'];
};

export type QuerytrendingFacebookInfluencersArgs = {
  categoryIds: Array<Scalars['Int']['input']>;
  countryId?: InputMaybe<Scalars['String']['input']>;
  followerGrowthPeriod: FollowersGrowthPeriodEnum;
  limit: Scalars['Int']['input'];
  minFollowers?: InputMaybe<Scalars['Int']['input']>;
  offset: Scalars['Int']['input'];
};

export type QuerytrendingInstagramInfluencersV2Args = {
  categoryIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  countryId?: InputMaybe<Scalars['String']['input']>;
  followerGrowthPeriod: FollowersGrowthPeriodEnum;
  limit: Scalars['Int']['input'];
  minFollowers?: InputMaybe<Scalars['Int']['input']>;
  offset: Scalars['Int']['input'];
};

export type QuerytrendingYoutubeInfluencersV2Args = {
  categoryIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  countryId?: InputMaybe<Scalars['String']['input']>;
  followerGrowthPeriod: FollowersGrowthPeriodEnum;
  limit: Scalars['Int']['input'];
  minFollowers?: InputMaybe<Scalars['Int']['input']>;
  offset: Scalars['Int']['input'];
};

export type QuerytwitterAnalyticsOverviewArgs = {
  endDate: Scalars['Date']['input'];
  startDate: Scalars['Date']['input'];
  twitterAnalyticsAccountId: Scalars['Int']['input'];
};

export type QuerytwitterAnalyticsPostArgs = {
  twitterAnalyticsAccountId: Scalars['Int']['input'];
  twitterAnalyticsPostId: Scalars['Int']['input'];
};

export type QuerytwitterAnalyticsPostsArgs = {
  endDate?: InputMaybe<Scalars['Date']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  orderBy: TwitterAnalyticsPostsSortInput;
  startDate?: InputMaybe<Scalars['Date']['input']>;
  twitterAnalyticsAccountId: Scalars['Int']['input'];
};

export type QuerytwitterAnalyticsPostsByDateArgs = {
  date: Scalars['Date']['input'];
  twitterAnalyticsAccountId: Scalars['Int']['input'];
};

export type QuerytwitterAnalyticsPostsCountArgs = {
  endDate?: InputMaybe<Scalars['Date']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
  twitterAnalyticsAccountId: Scalars['Int']['input'];
};

export type QuerytwitterAnalyticsPostsStatisticArgs = {
  endDate: Scalars['Date']['input'];
  startDate: Scalars['Date']['input'];
  twitterAnalyticsAccountId: Scalars['Int']['input'];
};

export type QueryuserArgs = {
  pk: Scalars['Int']['input'];
};

export type QuerywordCloudListArgs = {
  influencerId: Scalars['Int']['input'];
  socialAccountId: Scalars['Int']['input'];
  socialMedia: SocialAccountType;
};

export type QueryyoutubeAnalyticsAudienceArgs = {
  endDate: Scalars['Date']['input'];
  startDate: Scalars['Date']['input'];
  youtubeAnalyticsAccountId: Scalars['Int']['input'];
};

export type QueryyoutubeAnalyticsCommentsArgs = {
  endDate?: InputMaybe<Scalars['Date']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  startDate?: InputMaybe<Scalars['Date']['input']>;
  youtubeAnalyticsAccountId: Scalars['Int']['input'];
};

export type QueryyoutubeAnalyticsCommentsCountArgs = {
  endDate?: InputMaybe<Scalars['Date']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
  youtubeAnalyticsAccountId: Scalars['Int']['input'];
};

export type QueryyoutubeAnalyticsCompareAccountCardsArgs = {
  mainAccountId: Scalars['Int']['input'];
};

export type QueryyoutubeAnalyticsCompareRelatedPostsArgs = {
  compareAccountId: Scalars['Int']['input'];
  mainAccountId: Scalars['Int']['input'];
  postId: Scalars['Int']['input'];
  tag: Scalars['String']['input'];
};

export type QueryyoutubeAnalyticsOverviewArgs = {
  endDate: Scalars['Date']['input'];
  startDate: Scalars['Date']['input'];
  youtubeAnalyticsAccountId: Scalars['Int']['input'];
};

export type QueryyoutubeAnalyticsPostArgs = {
  youtubeAnalyticsAccountId: Scalars['Int']['input'];
  youtubeAnalyticsPostId: Scalars['Int']['input'];
};

export type QueryyoutubeAnalyticsPostsArgs = {
  endDate?: InputMaybe<Scalars['Date']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  orderBy: YoutubeAnalyticsPostsSortInput;
  startDate?: InputMaybe<Scalars['Date']['input']>;
  youtubeAnalyticsAccountId: Scalars['Int']['input'];
};

export type QueryyoutubeAnalyticsPostsByDateArgs = {
  date: Scalars['Date']['input'];
  youtubeAnalyticsAccountId: Scalars['Int']['input'];
};

export type QueryyoutubeAnalyticsPostsCountArgs = {
  endDate?: InputMaybe<Scalars['Date']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
  youtubeAnalyticsAccountId: Scalars['Int']['input'];
};

export type QueryyoutubeAnalyticsPostsStatisticsArgs = {
  endDate: Scalars['Date']['input'];
  startDate: Scalars['Date']['input'];
  youtubeAnalyticsAccountId: Scalars['Int']['input'];
};

export type QueryyoutubeAnalyticsRelatedPostsArgs = {
  tag: Scalars['String']['input'];
  youtubeAnalyticsAccountId: Scalars['Int']['input'];
  youtubeAnalyticsPostId: Scalars['Int']['input'];
};

export type QueryyoutubeAnalyticsShortsArgs = {
  endDate?: InputMaybe<Scalars['Date']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  orderBy: YoutubeAnalyticsPostsSortInput;
  startDate?: InputMaybe<Scalars['Date']['input']>;
  youtubeAnalyticsAccountId: Scalars['Int']['input'];
};

export type QueryyoutubeAnalyticsShortsCountArgs = {
  endDate?: InputMaybe<Scalars['Date']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
  youtubeAnalyticsAccountId: Scalars['Int']['input'];
};

export type QueryyoutubeAnalyticsTagsTopRankingArgs = {
  endDate?: InputMaybe<Scalars['Date']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  orderBy: YoutubeAnalyticsTagRankingSortInput;
  startDate?: InputMaybe<Scalars['Date']['input']>;
  youtubeAnalyticsAccountId: Scalars['Int']['input'];
};

export type QueryyoutubeAnalyticsTagsTopRankingCountArgs = {
  endDate?: InputMaybe<Scalars['Date']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
  youtubeAnalyticsAccountId: Scalars['Int']['input'];
};

export type QueryyoutubeAnalyticsTrendPostByIdArgs = {
  id: Scalars['Int']['input'];
};

export type QueryyoutubeAnalyticsTrendsArgs = {
  category: Scalars['Int']['input'];
  country: Scalars['String']['input'];
  date?: InputMaybe<Scalars['Date']['input']>;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
};

export type QueryyoutubeAnalyticsTrendsCountArgs = {
  category: Scalars['Int']['input'];
  country: Scalars['String']['input'];
  date?: InputMaybe<Scalars['Date']['input']>;
};

export type QueryyoutubeBrandAccountsArgs = {
  influencerId: Scalars['Int']['input'];
  youtubeAccountSystemId: Scalars['Int']['input'];
};

export type QueryyoutubeCmsAssetsSummaryArgs = {
  cmsChannelId?: InputMaybe<Scalars['Int']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  maxDate?: InputMaybe<Scalars['Date']['input']>;
  minDate?: InputMaybe<Scalars['Date']['input']>;
};

export type QueryyoutubeCmsChannelDetailArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryyoutubeCmsChannelInfoArgs = {
  channelIds: Array<Scalars['String']['input']>;
};

export type QueryyoutubeCmsChannelsSummaryArgs = {
  keyword?: InputMaybe<Scalars['String']['input']>;
  maxDate?: InputMaybe<Scalars['Date']['input']>;
  minDate?: InputMaybe<Scalars['Date']['input']>;
};

export type QueryyoutubeCompareAllPostsInDateArgs = {
  date: Scalars['Date']['input'];
  mainAccountId: Scalars['Int']['input'];
};

export type QueryyoutubeCompareOverviewArgs = {
  endDate: Scalars['Date']['input'];
  mainAccountId: Scalars['Int']['input'];
  startDate: Scalars['Date']['input'];
};

export type QueryyoutubeComparePostByIdArgs = {
  compareAccountId: Scalars['Int']['input'];
  mainAccountId: Scalars['Int']['input'];
  postId: Scalars['Int']['input'];
};

export type QueryyoutubeComparePostListArgs = {
  compareAccountId: Scalars['Int']['input'];
  endDate?: InputMaybe<Scalars['Date']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Int']['input'];
  mainAccountId: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  orderBy: YoutubeAnalyticsComparePostsSort;
  startDate?: InputMaybe<Scalars['Date']['input']>;
};

export type QueryyoutubeComparePostsArgs = {
  endDate?: InputMaybe<Scalars['Date']['input']>;
  mainAccountId: Scalars['Int']['input'];
  startDate?: InputMaybe<Scalars['Date']['input']>;
};

export type QueryyoutubeComparePostsCountArgs = {
  compareAccountId: Scalars['Int']['input'];
  endDate?: InputMaybe<Scalars['Date']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  mainAccountId: Scalars['Int']['input'];
  startDate?: InputMaybe<Scalars['Date']['input']>;
};

export type QueryyoutubeCompareTagRankingArgs = {
  endDate: Scalars['Date']['input'];
  mainAccountId: Scalars['Int']['input'];
  startDate: Scalars['Date']['input'];
};

export enum QuerySocialAccountType {
  FACEBOOK = 'FACEBOOK',
  FACEBOOK_PAGE = 'FACEBOOK_PAGE',
  INSTAGRAM = 'INSTAGRAM',
  INSTAGRAM_BUSINESS = 'INSTAGRAM_BUSINESS',
  LINE = 'LINE',
  THREADS = 'THREADS',
  TIKTOK = 'TIKTOK',
  TWITTER = 'TWITTER',
  YOUTUBE = 'YOUTUBE',
}

export enum QuestionType {
  CHECKBOX = 'CHECKBOX',
  DROPDOWN = 'DROPDOWN',
  EMAIL = 'EMAIL',
  NAME = 'NAME',
  SHORT_ANSWER = 'SHORT_ANSWER',
}

export type QuestionsInput = {
  image?: InputMaybe<Scalars['String']['input']>;
  isRequired: Scalars['Boolean']['input'];
  options: Array<OptionInput>;
  order: Scalars['Int']['input'];
  questionType: QuestionType;
  title: Scalars['String']['input'];
};

export type RateCardsInput = {
  facebook?: InputMaybe<InfluencerRateCardPriceInput>;
  instagram?: InputMaybe<InfluencerRateCardPriceInput>;
  instagramStory?: InputMaybe<InfluencerRateCardPriceInput>;
  tiktok?: InputMaybe<InfluencerRateCardPriceInput>;
  twitter?: InputMaybe<InfluencerRateCardPriceInput>;
  youtube?: InputMaybe<InfluencerRateCardPriceInput>;
};

export type ReSendBcaRequestInput = {
  brandIgId: Scalars['Int']['input'];
  creatorIgId: Scalars['Int']['input'];
};

export type ReSendBcaRequestPayload = {
  __typename?: 'ReSendBcaRequestPayload';
  ok: Scalars['Boolean']['output'];
};

export type ReadPackage = {
  __typename?: 'ReadPackage';
  companies?: Maybe<Array<Maybe<PackageCompany>>>;
  country: CountryName;
  /** Package Id */
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type ReadPackageAccount = {
  __typename?: 'ReadPackageAccount';
  accounts?: Maybe<Scalars['Int']['output']>;
  companies?: Maybe<Array<PackageCompany>>;
  country: CountryName;
  /** Package Id */
  id: Scalars['Int']['output'];
  isDefault: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
};

export type ReadPackageInfluencers = {
  __typename?: 'ReadPackageInfluencers';
  age: Scalars['Int']['output'];
  avatar: Scalars['String']['output'];
  averageEngagement: Scalars['Int']['output'];
  averageEngagementRate: Scalars['Float']['output'];
  categories: Array<Scalars['String']['output']>;
  country: CountryName;
  engagementPostedCount: Scalars['Int']['output'];
  engagementProposedCount: Scalars['Int']['output'];
  followersCount: Scalars['Int']['output'];
  gender: Genders;
  influencerId: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  socialAccounts: Array<ReadSocialAccountForPackage>;
};

export type ReadSocialAccountForPackage = {
  __typename?: 'ReadSocialAccountForPackage';
  averageEngagement: Scalars['Int']['output'];
  averageEngagementRate: Scalars['Float']['output'];
  followersCount: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  socialAccountId: Scalars['Int']['output'];
  socialMedia: SocialAccountType;
};

export type ReadTutorials = {
  __typename?: 'ReadTutorials';
  readTutorials: Array<TutorialName>;
};

export type RecipientDetail = {
  __typename?: 'RecipientDetail';
  email: Scalars['String']['output'];
  fanId: Scalars['Long']['output'];
  name: Scalars['String']['output'];
  statuses: Array<EmailEventStatus>;
};

export type RecipientItemInput = {
  tagNames: Array<Scalars['String']['input']>;
  type: RecipientType;
};

export type RecipientItemPayload = {
  __typename?: 'RecipientItemPayload';
  tagNames: Array<Scalars['String']['output']>;
  type: RecipientType;
};

export enum RecipientType {
  ALL = 'ALL',
  WITH_CUSTOMER_TAGS = 'WITH_CUSTOMER_TAGS',
}

export type ReconnectedAccountForLinkBio = {
  __typename?: 'ReconnectedAccountForLinkBio';
  avatar: Scalars['String']['output'];
  followersCount?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  socialAccountType: SocialAccountType;
  url: Scalars['String']['output'];
};

export enum RedirectType {
  CONNECT = 'CONNECT',
  JOIN_CAMPAIGN = 'JOIN_CAMPAIGN',
  RECONNECT_IG = 'RECONNECT_IG',
  SIGNIN = 'SIGNIN',
  SIGNUP = 'SIGNUP',
  SIGNUP_CONNECT = 'SIGNUP_CONNECT',
  SIGNUP_ENABLED = 'SIGNUP_ENABLED',
  TALENT_SIGNIN = 'TALENT_SIGNIN',
  TALENT_SIGNUP = 'TALENT_SIGNUP',
}

export type RefreshPasswordInput = {
  email?: InputMaybe<Scalars['String']['input']>;
};

export type RefreshPasswordPayload = {
  __typename?: 'RefreshPasswordPayload';
  ok?: Maybe<Scalars['Boolean']['output']>;
};

export type RefreshProfileAccountAvailabilityInput = {
  socialAccountId: Scalars['Int']['input'];
  socialMedia: SocialAccountType;
};

export type RefreshProfileAccountAvailabilityPayload = {
  __typename?: 'RefreshProfileAccountAvailabilityPayload';
  status: RefreshProfileAvailabilityStatus;
};

export type RefreshProfileAccountInput = {
  socialAccountId: Scalars['Int']['input'];
  socialMedia: SocialAccountType;
};

export type RefreshProfileAccountPayload = {
  __typename?: 'RefreshProfileAccountPayload';
  ok: Scalars['Boolean']['output'];
};

export enum RefreshProfileAvailabilityStatus {
  AVAILABLE = 'AVAILABLE',
  NOT_AVAILABLE = 'NOT_AVAILABLE',
  NO_DATA = 'NO_DATA',
}

export type RefreshTwitterAccountAvailabilityInput = {
  socialAccountId: Scalars['Int']['input'];
};

export type RefreshTwitterAccountAvailabilityPayload = {
  __typename?: 'RefreshTwitterAccountAvailabilityPayload';
  isRefreshAvailable: Scalars['Boolean']['output'];
};

export type RefreshTwitterAccountInput = {
  socialAccountId: Scalars['Int']['input'];
};

export type RefreshTwitterAccountPayload = {
  __typename?: 'RefreshTwitterAccountPayload';
  ok: Scalars['Boolean']['output'];
};

export type Region = {
  __typename?: 'Region';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type RegionName = {
  __typename?: 'RegionName';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type RegisterPushToken = {
  __typename?: 'RegisterPushToken';
  ok: Scalars['Boolean']['output'];
};

export type RegisterPushTokenInput = {
  influencerId: Scalars['Int']['input'];
  token: Scalars['String']['input'];
};

export type RejectEngagementDraftPost = {
  __typename?: 'RejectEngagementDraftPost';
  ok: Scalars['Boolean']['output'];
};

export type RejectEngagementDraftPostInput = {
  detailedReason?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Int']['input'];
  reason: Scalars['String']['input'];
};

export type RejectMarketplaceCampaign = {
  __typename?: 'RejectMarketplaceCampaign';
  ok: Scalars['Boolean']['output'];
};

export type RejectMarketplaceCampaignInput = {
  marketplaceId: Scalars['Int']['input'];
  reason?: InputMaybe<Scalars['String']['input']>;
};

export type RejectMarketplaceDraftPost = {
  __typename?: 'RejectMarketplaceDraftPost';
  ok: Scalars['Boolean']['output'];
};

export type RejectMarketplaceDraftPostInput = {
  draftId: Scalars['Int']['input'];
  message: Scalars['String']['input'];
};

export type RemoveAllFormResponsesInput = {
  formId: Scalars['Long']['input'];
};

export type RemoveAllFormResponsesPayload = {
  __typename?: 'RemoveAllFormResponsesPayload';
  ok: Scalars['Boolean']['output'];
};

export type RemoveAllSelectedInfluencers = {
  __typename?: 'RemoveAllSelectedInfluencers';
  ok: Scalars['Boolean']['output'];
};

export type RemoveAllSelectedInfluencersInput = {
  marketplaceId: Scalars['Int']['input'];
};

export type RemoveCreatorStaffInput = {
  creatorStaffId: Scalars['Int']['input'];
};

export type RemoveCreatorStaffPayload = {
  __typename?: 'RemoveCreatorStaffPayload';
  ok: Scalars['Boolean']['output'];
};

export type RemoveEmailsInput = {
  emailIds: Array<Scalars['Long']['input']>;
};

export type RemoveEmailsPayload = {
  __typename?: 'RemoveEmailsPayload';
  ok: Scalars['Boolean']['output'];
};

export type RemoveFansInput = {
  fanIds: Array<Scalars['Long']['input']>;
};

export type RemoveFansPayload = {
  __typename?: 'RemoveFansPayload';
  ok: Scalars['Boolean']['output'];
};

export type RemoveFormResponseInput = {
  submissionId: Scalars['Long']['input'];
};

export type RemoveFormResponsePayload = {
  __typename?: 'RemoveFormResponsePayload';
  ok: Scalars['Boolean']['output'];
};

export type RemoveFormsInput = {
  formIds: Array<Scalars['Long']['input']>;
};

export type RemoveFormsPayload = {
  __typename?: 'RemoveFormsPayload';
  ok: Scalars['Boolean']['output'];
};

export type RemoveSelectedInfluencers = {
  __typename?: 'RemoveSelectedInfluencers';
  ok: Scalars['Boolean']['output'];
};

export type RemoveSelectedInfluencersInput = {
  influencerIds: Array<Scalars['Int']['input']>;
  marketplaceId: Scalars['Int']['input'];
};

export type RemoveShopifyAccountInput = {
  id: Scalars['Long']['input'];
};

export type RemoveShopifyAccountPayload = {
  __typename?: 'RemoveShopifyAccountPayload';
  ok: Scalars['Boolean']['output'];
};

export type ReorderMarketplaceCampaigns = {
  __typename?: 'ReorderMarketplaceCampaigns';
  ok: Scalars['Boolean']['output'];
};

export type ReportEngagementPostStatus = {
  __typename?: 'ReportEngagementPostStatus';
  ok: Scalars['Boolean']['output'];
};

export type ReportEngagementPostStatusInput = {
  detailedReason?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Int']['input'];
  reason: Scalars['String']['input'];
};

export type ReportForInfluencer = {
  __typename?: 'ReportForInfluencer';
  campaign: CampaignReportCampaignInfoForInfluencer;
  click?: Maybe<Scalars['Int']['output']>;
  comment?: Maybe<Scalars['Int']['output']>;
  conversion?: Maybe<Scalars['Int']['output']>;
  currency: Scalars['String']['output'];
  like?: Maybe<Scalars['Int']['output']>;
  postStatus?: Maybe<CampaignPostStatusForInfluencer>;
  revenue?: Maybe<Scalars['Float']['output']>;
  share?: Maybe<Scalars['Int']['output']>;
  view?: Maybe<Scalars['Int']['output']>;
};

export type ReportMarketplacePostStatus = {
  __typename?: 'ReportMarketplacePostStatus';
  ok: Scalars['Boolean']['output'];
};

export type ReportMarketplacePostStatusInput = {
  detailedReason: Scalars['String']['input'];
  id: Scalars['Int']['input'];
  reason: Scalars['String']['input'];
};

export type RequestDeleteAccount = {
  __typename?: 'RequestDeleteAccount';
  ok: Scalars['Boolean']['output'];
};

export type RequestDeleteAccountInput = {
  comments?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  otherServices?: InputMaybe<Scalars['String']['input']>;
  reasons: Array<Scalars['String']['input']>;
};

export type RequestGoogleSlideCustomisedReport = {
  __typename?: 'RequestGoogleSlideCustomisedReport';
  ok: Scalars['Boolean']['output'];
};

export type RequestGoogleSlideReport = {
  __typename?: 'RequestGoogleSlideReport';
  ok: Scalars['Boolean']['output'];
};

export type RequestInfluencerProfilePptx = {
  __typename?: 'RequestInfluencerProfilePptx';
  ok: Scalars['Boolean']['output'];
};

export type RequestInfluencersProfilePptx = {
  __typename?: 'RequestInfluencersProfilePptx';
  ok: Scalars['Boolean']['output'];
};

export type RequestInfluencersSocialAccountPptx = {
  __typename?: 'RequestInfluencersSocialAccountPptx';
  ok: Scalars['Boolean']['output'];
};

export type RequestInstagramUGCManagementPostInput = {
  analyticsAccountId: Scalars['Int']['input'];
  content: Scalars['String']['input'];
  postSnsId: Scalars['String']['input'];
};

export type RequestInstagramUGCManagementPostOutput = {
  __typename?: 'RequestInstagramUGCManagementPostOutput';
  ok: Scalars['Boolean']['output'];
};

export type RequestPackageSpreadsheet = {
  __typename?: 'RequestPackageSpreadsheet';
  ok: Scalars['Boolean']['output'];
};

export type RequestPowerpointCustomisedReport = {
  __typename?: 'RequestPowerpointCustomisedReport';
  ok: Scalars['Boolean']['output'];
};

export type RequestPowerpointReport = {
  __typename?: 'RequestPowerpointReport';
  ok: Scalars['Boolean']['output'];
};

export type RequestProposalExcel = {
  __typename?: 'RequestProposalExcel';
  ok: Scalars['Boolean']['output'];
};

export type RequestProposalPptx = {
  __typename?: 'RequestProposalPptx';
  ok: Scalars['Boolean']['output'];
};

export type RequestProposalSpreadsheet = {
  __typename?: 'RequestProposalSpreadsheet';
  ok: Scalars['Boolean']['output'];
};

export type RequestedBCABrand = {
  __typename?: 'RequestedBCABrand';
  brand: RequestedBrandForBCA;
  instagramAccount: RequestedInstagramAccountForBCA;
  isResendable: Scalars['Boolean']['output'];
  status: BcaRequestStatus;
};

export type RequestedBrandForBCA = {
  __typename?: 'RequestedBrandForBCA';
  /** Analytics Instagram Account System Id */
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type RequestedInstagramAccountForBCA = {
  __typename?: 'RequestedInstagramAccountForBCA';
  /** Instagram Account System Id */
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type ResendEmailForAdvertiser = {
  __typename?: 'ResendEmailForAdvertiser';
  ok?: Maybe<Scalars['Boolean']['output']>;
};

export type ResendEmailForAdvertiserInput = {
  email: Scalars['String']['input'];
};

export type ResendInfluencerForgotPasswordEmailInput = {
  email: Scalars['String']['input'];
};

export type ResendInfluencerForgotPasswordEmailPayload = {
  __typename?: 'ResendInfluencerForgotPasswordEmailPayload';
  ok: Scalars['Boolean']['output'];
};

export type ResendInfluencerSignUpEmailInput = {
  email: Scalars['String']['input'];
};

export type ResendInfluencerSignUpEmailPayload = {
  __typename?: 'ResendInfluencerSignUpEmailPayload';
  ok: Scalars['Boolean']['output'];
};

export type ResendPartnerEmails = {
  __typename?: 'ResendPartnerEmails';
  ok: Scalars['Boolean']['output'];
};

export type ResendPartnerEmailsInput = {
  emails: Array<Scalars['String']['input']>;
};

export type ResetPasswordAndLoginPayload = {
  __typename?: 'ResetPasswordAndLoginPayload';
  hash?: Maybe<Scalars['String']['output']>;
  refreshToken?: Maybe<Scalars['String']['output']>;
  role: UserRoles;
  token: Scalars['String']['output'];
  userId: Scalars['Int']['output'];
};

export type ResetPasswordInput = {
  password?: InputMaybe<Scalars['String']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
};

export type ResetPasswordPayload = {
  __typename?: 'ResetPasswordPayload';
  ok?: Maybe<Scalars['Boolean']['output']>;
};

export type ResolveChatInput = {
  chatId: Scalars['String']['input'];
};

export type ResolveChatPayload = {
  __typename?: 'ResolveChatPayload';
  ok: Scalars['Boolean']['output'];
};

export type RichMessageInput = {
  imageUrl: Scalars['String']['input'];
  url?: InputMaybe<Scalars['String']['input']>;
};

export type RichMessagePayload = {
  __typename?: 'RichMessagePayload';
  imageUrl: Scalars['String']['output'];
  url?: Maybe<Scalars['String']['output']>;
};

export enum SearchMode {
  ITEM_SEARCH = 'ITEM_SEARCH',
  KEYWORD_SEARCH = 'KEYWORD_SEARCH',
}

export type SelectableBCABrands = {
  __typename?: 'SelectableBCABrands';
  brands: Array<SelectableBrandForBCA>;
  instagramAccounts: Array<SelectableInstagramAccountForBCA>;
  isBcaAvailable: Scalars['Boolean']['output'];
};

export type SelectableBrandForBCA = {
  __typename?: 'SelectableBrandForBCA';
  /** Analytics Instagram Account System Id */
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type SelectableInstagramAccountForBCA = {
  __typename?: 'SelectableInstagramAccountForBCA';
  /** Instagram Account System Id */
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type SelectableYoutubeAnalyticsChannel = {
  __typename?: 'SelectableYoutubeAnalyticsChannel';
  avatar: Scalars['String']['output'];
  channelId: Scalars['String']['output'];
  channelName: Scalars['String']['output'];
  isUsed: Scalars['Boolean']['output'];
  subscribersCount: Scalars['Int']['output'];
};

/** An enumeration. */
export enum SelectedInfluencers {
  SELECTED = 'SELECTED',
  UNSELECTED = 'UNSELECTED',
}

export type SelectedOauthPageAndIgAccount = {
  igUserId: Scalars['String']['input'];
  pageId: Scalars['String']['input'];
};

export type SelectedSocialAccounts = {
  fbUserId?: InputMaybe<Scalars['String']['input']>;
  pageIds: Array<Scalars['String']['input']>;
  pagesAndIgAccounts: Array<SelectedOauthPageAndIgAccount>;
  twitterUserIds: Array<Scalars['String']['input']>;
  youTubeChannelIds: Array<Scalars['String']['input']>;
};

export type SendBcaRequestInput = {
  brandIgId: Scalars['Int']['input'];
  creatorIgId: Scalars['Int']['input'];
};

export type SendBcaRequestPayload = {
  __typename?: 'SendBcaRequestPayload';
  ok: Scalars['Boolean']['output'];
};

export type SendContact = {
  __typename?: 'SendContact';
  ok: Scalars['Boolean']['output'];
};

export type SendContactInput = {
  enquiry: Scalars['String']['input'];
};

export type SendLineBroadcastMessageInput = {
  isTest: Scalars['Boolean']['input'];
  lineBroadcastMessageId: Scalars['String']['input'];
};

export type SendLineBroadcastMessagePayload = {
  __typename?: 'SendLineBroadcastMessagePayload';
  ok: Scalars['Boolean']['output'];
};

export type SendMessageInput = {
  chatId: Scalars['String']['input'];
  message: Scalars['String']['input'];
};

export type SendMessagePayload = {
  __typename?: 'SendMessagePayload';
  ok: Scalars['Boolean']['output'];
};

export type ShopeeCustomCampaignInput = {
  countryId: Scalars['String']['input'];
  itemId?: InputMaybe<Scalars['String']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  searchMode: SearchMode;
};

export type ShopeeCustomCampaignPayload = {
  __typename?: 'ShopeeCustomCampaignPayload';
  ok: Scalars['Boolean']['output'];
};

export type ShopifyAuthRedirectUrlInput = {
  callbackUrl: Scalars['String']['input'];
  shopName: Scalars['String']['input'];
};

export type ShopifyAuthRedirectUrlPayload = {
  __typename?: 'ShopifyAuthRedirectUrlPayload';
  redirectUri: Scalars['String']['output'];
};

export enum ShopifyOrderPaymentStatus {
  AUTHORIZED = 'AUTHORIZED',
  PAID = 'PAID',
  PARTIALLY_PAID = 'PARTIALLY_PAID',
  PARTIALLY_REFUNDED = 'PARTIALLY_REFUNDED',
  PENDING = 'PENDING',
  REFUNDED = 'REFUNDED',
  VOIDED = 'VOIDED',
}

export enum ShopifyOrderShippingStatus {
  FULFILLED = 'FULFILLED',
  PARTIAL = 'PARTIAL',
  RESTOCKED = 'RESTOCKED',
}

export type ShopifyValidateAuthInput = {
  response: Scalars['String']['input'];
  shopName: Scalars['String']['input'];
};

export type ShopifyValidateAuthPayload = {
  __typename?: 'ShopifyValidateAuthPayload';
  ok: Scalars['Boolean']['output'];
};

export type SignedUrlData = {
  __typename?: 'SignedUrlData';
  fileName: Scalars['String']['output'];
  signedUrl: Scalars['String']['output'];
};

export type SimilarPost = {
  __typename?: 'SimilarPost';
  image?: Maybe<Scalars['String']['output']>;
  influencerId: Scalars['Int']['output'];
  postId: Scalars['Int']['output'];
  postType: SocialPostType;
  socialAccountId: Scalars['Int']['output'];
  username: Scalars['String']['output'];
};

/**  TODO old API. don't forget to remove */
export type SocialAccount = {
  __typename?: 'SocialAccount';
  exclusive?: Maybe<Scalars['Boolean']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type SocialAccountDescription = {
  __typename?: 'SocialAccountDescription';
  socialAccountName: Scalars['String']['output'];
  socialAccountType: SocialAccountType;
};

export type SocialAccountForProfileV2 = {
  __typename?: 'SocialAccountForProfileV2';
  avatar?: Maybe<Scalars['String']['output']>;
  followersCount?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
  profile?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type SocialAccountInfluencerAudience = {
  age?: InputMaybe<FollowersAgeGroupInput>;
  gender: Genders;
  minPercentage?: InputMaybe<Scalars['Float']['input']>;
};

/** An enumeration. */
export enum SocialAccountInfluencerListSortField {
  AGE = 'AGE',
  AVG_LIKE = 'AVG_LIKE',
  AVG_REACH = 'AVG_REACH',
  AVG_VIEW = 'AVG_VIEW',
  CATEGORY = 'CATEGORY',
  COMMENT = 'COMMENT',
  COUNTRY = 'COUNTRY',
  DISLIKE = 'DISLIKE',
  ENGAGEMENT_POSTED_COUNT = 'ENGAGEMENT_POSTED_COUNT',
  ENGAGEMENT_PROPOSED_COUNT = 'ENGAGEMENT_PROPOSED_COUNT',
  GENDER = 'GENDER',
  ID = 'ID',
  INFLUENCER_ENGAGEMENT = 'INFLUENCER_ENGAGEMENT',
  INFLUENCER_ENGAGEMENT_RATE = 'INFLUENCER_ENGAGEMENT_RATE',
  INFLUENCER_FOLLOWERS = 'INFLUENCER_FOLLOWERS',
  LIKE = 'LIKE',
  MARKETPLACE_JOINED_COUNT = 'MARKETPLACE_JOINED_COUNT',
  MARKETPLACE_POSTED_COUNT = 'MARKETPLACE_POSTED_COUNT',
  ML_API = 'ML_API',
  REACH = 'REACH',
  REPLY = 'REPLY',
  RETWEET = 'RETWEET',
  SAVED = 'SAVED',
  SHARE = 'SHARE',
  SOCIAL_ACCOUNT_AVG_ENGAGEMENT = 'SOCIAL_ACCOUNT_AVG_ENGAGEMENT',
  SOCIAL_ACCOUNT_ENGAGEMENT = 'SOCIAL_ACCOUNT_ENGAGEMENT',
  SOCIAL_ACCOUNT_ENGAGEMENT_RATE = 'SOCIAL_ACCOUNT_ENGAGEMENT_RATE',
  SOCIAL_ACCOUNT_FOLLOWERS = 'SOCIAL_ACCOUNT_FOLLOWERS',
  STATUS = 'STATUS',
  VIEW = 'VIEW',
}

export enum SocialAccountInfluencerListSortFieldV2 {
  AVG_LIKE = 'AVG_LIKE',
  AVG_REACH = 'AVG_REACH',
  AVG_VIEW = 'AVG_VIEW',
  CREATED = 'CREATED',
  RELEVANT = 'RELEVANT',
  SOCIAL_ACCOUNT_ENGAGEMENT_RATE = 'SOCIAL_ACCOUNT_ENGAGEMENT_RATE',
  SOCIAL_ACCOUNT_FOLLOWERS = 'SOCIAL_ACCOUNT_FOLLOWERS',
}

export type SocialAccountInfluencerOrderBy = {
  field?: InputMaybe<SocialAccountInfluencerListSortField>;
  order?: InputMaybe<ORDER>;
};

export type SocialAccountInfluencerOrderByV2 = {
  field?: InputMaybe<SocialAccountInfluencerListSortFieldV2>;
  order?: InputMaybe<Order>;
};

export type SocialAccountPairInput = {
  influencerId: Scalars['Int']['input'];
  socialAccountId?: InputMaybe<Scalars['Int']['input']>;
  socialType?: InputMaybe<SocialAccountType>;
};

export type SocialAccountPermissionsInput = {
  type: SocialAccountType;
};

export type SocialAccountPopularPost = {
  __typename?: 'SocialAccountPopularPost';
  caption: Scalars['String']['output'];
  postId: Scalars['ID']['output'];
  thumbNail?: Maybe<Scalars['String']['output']>;
  type: SocialPostType;
};

export enum SocialAccountStatus {
  SCRAPING = 'SCRAPING',
  SIGNED_UP = 'SIGNED_UP',
}

export enum SocialAccountType {
  DOUYIN = 'DOUYIN',
  FACEBOOK = 'FACEBOOK',
  FACEBOOK_PAGE = 'FACEBOOK_PAGE',
  INSTAGRAM = 'INSTAGRAM',
  LINE = 'LINE',
  THREADS = 'THREADS',
  TIKTOK = 'TIKTOK',
  TWITTER = 'TWITTER',
  YOUTUBE = 'YOUTUBE',
}

export type SocialAccountV2 = {
  __typename?: 'SocialAccountV2';
  isAnySignedUpAccount: Scalars['Boolean']['output'];
};

export type SocialAuthCheckFacebookTokenPayload = {
  __typename?: 'SocialAuthCheckFacebookTokenPayload';
  needReconnect: Scalars['Boolean']['output'];
};

export type SocialAuthConnectableFacebookPageAnalyticsInput = {
  callbackUrl: Scalars['String']['input'];
  response: Scalars['String']['input'];
};

export type SocialAuthConnectableFacebookPageAnalyticsPayload = {
  __typename?: 'SocialAuthConnectableFacebookPageAnalyticsPayload';
  account?: Maybe<ConnectableFacebookPageMainAccount>;
  pages: Array<ConnectableFacebookPageAccount>;
};

export type SocialAuthConnectableInstagramAccountsAnalyticsInput = {
  callbackUrl: Scalars['String']['input'];
  response: Scalars['String']['input'];
};

/**  Stores Instagram accounts that can be connected based on a Facebook account */
export type SocialAuthConnectableInstagramAccountsAnalyticsPayload = {
  __typename?: 'SocialAuthConnectableInstagramAccountsAnalyticsPayload';
  accounts: Array<ConnectableOauthPageAndIgAccount>;
};

export type SocialAuthConnectableTwitterAccountAnalyticsInput = {
  callbackUrl: Scalars['String']['input'];
  response: Scalars['String']['input'];
};

export type SocialAuthConnectableTwitterAccountAnalyticsOutput = {
  __typename?: 'SocialAuthConnectableTwitterAccountAnalyticsOutput';
  avatar: Scalars['String']['output'];
  followersCount: Scalars['Int']['output'];
  isUsed: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  twitterUserId: Scalars['String']['output'];
  username: Scalars['String']['output'];
};

/**  socialAuthForJoinCampaign */
export type SocialAuthForJoinCampaignInput = {
  callbackUrl?: InputMaybe<Scalars['String']['input']>;
  provider: AuthSocialAccountType;
  response: Scalars['String']['input'];
  selectedSocialAccountId?: InputMaybe<Scalars['ID']['input']>;
};

export type SocialAuthForJoinCampaignPayload = {
  __typename?: 'SocialAuthForJoinCampaignPayload';
  ok: Scalars['Boolean']['output'];
};

export type SocialAuthReConnectInstagramInput = {
  callbackUrl: Scalars['String']['input'];
  response: Scalars['String']['input'];
};

export type SocialAuthReConnectInstagramPayload = {
  __typename?: 'SocialAuthReConnectInstagramPayload';
  token: Scalars['String']['output'];
};

/**
 *  `response`: response from Facebook API. Used to get the access token
 *  `callbackUrl`: callback URL from Facebook API. Used to get the access token
 *  `accountId`: analytics account id.
 *  `fbPageId`: id of the Facebook page the reconnected account is connected to.
 */
export type SocialAuthReconnectFacebookPageAccountAnalyticsInput = {
  accountId: Scalars['Int']['input'];
  callbackUrl: Scalars['String']['input'];
  fbPageId: Scalars['String']['input'];
  response: Scalars['String']['input'];
};

export type SocialAuthReconnectFacebookPageAccountAnalyticsPayload = {
  __typename?: 'SocialAuthReconnectFacebookPageAccountAnalyticsPayload';
  ok: Scalars['Boolean']['output'];
};

/**
 *  `businessAccountId`: Instagram business account id.
 *  `response`: response from Facebook API. Used to get the access token
 *  `callbackUrl`: callback URL from Facebook API. Used to get the access token
 *  `redirectType`: type of redirect. We have many pages to redirect to when Facebook is done with processing our requests.
 *   We can go back to "account list", "add accounts" page, and etc. Used to get the access token
 *  `accountId`: analytics account id.
 *  `username`: reconnected Instagram account username.
 *  `fbPageId`: id of the Facebook page the reconnected account is connected to.
 */
export type SocialAuthReconnectInstagramAccountAnalyticsInput = {
  accountId: Scalars['Int']['input'];
  businessAccountId: Scalars['String']['input'];
  callbackUrl: Scalars['String']['input'];
  fbPageId?: InputMaybe<Scalars['String']['input']>;
  redirectType: AnalyticsRedirectType;
  response: Scalars['String']['input'];
  username: Scalars['String']['input'];
};

export type SocialAuthReconnectInstagramAccountAnalyticsPayload = {
  __typename?: 'SocialAuthReconnectInstagramAccountAnalyticsPayload';
  ok: Scalars['Boolean']['output'];
};

export type SocialAuthReconnectTwitterAccountAnalyticsInput = {
  callbackUrl: Scalars['String']['input'];
  response: Scalars['String']['input'];
};

export type SocialAuthReconnectTwitterAccountAnalyticsPayload = {
  __typename?: 'SocialAuthReconnectTwitterAccountAnalyticsPayload';
  ok: Scalars['Boolean']['output'];
};

/**
 *  `channelId`: id of the YouTube channel the reconnected account is connected to.
 *  `callbackUrl`: callback URL from Google API. Used to get the access token
 *  `response`: response from Google API. Used to get the access token
 */
export type SocialAuthReconnectYouTubeAccountAnalyticsInput = {
  callbackUrl: Scalars['String']['input'];
  channelId: Scalars['String']['input'];
  response: Scalars['String']['input'];
};

export type SocialAuthReconnectYouTubeAccountAnalyticsPayload = {
  __typename?: 'SocialAuthReconnectYouTubeAccountAnalyticsPayload';
  ok: Scalars['Boolean']['output'];
};

export type SocialAuthRedirectUrlForInfluencerInput = {
  callbackUrl: Scalars['String']['input'];
  provider: AuthSocialAccountType;
};

export type SocialAuthRedirectUrlForInfluencerPayload = {
  __typename?: 'SocialAuthRedirectUrlForInfluencerPayload';
  redirectUri: Scalars['String']['output'];
};

/**  Talent & Partner */
export type SocialAuthTalentSignInFacebookInput = {
  callbackUrl: Scalars['String']['input'];
  influencerId?: InputMaybe<Scalars['Int']['input']>;
  response: Scalars['String']['input'];
};

export type SocialAuthTalentSignInFacebookPayload = {
  __typename?: 'SocialAuthTalentSignInFacebookPayload';
  fbAvatar: Scalars['String']['output'];
  fbFollowersCount: Scalars['Int']['output'];
  fbName: Scalars['String']['output'];
  fbUserId: Scalars['String']['output'];
  pages: Array<ConnectableOauthPageAndIgAccountForSignInFacebook>;
  status: SocialAccountStatus;
};

export type SocialAuthTalentSignInInstagramInput = {
  callbackUrl: Scalars['String']['input'];
  influencerId?: InputMaybe<Scalars['Int']['input']>;
  response: Scalars['String']['input'];
  username?: InputMaybe<Scalars['String']['input']>;
};

export type SocialAuthTalentSignInInstagramPayload = {
  __typename?: 'SocialAuthTalentSignInInstagramPayload';
  facebook: ConnectableOauthFacebookAccountForTalentSignIn;
  pagesAndIgAccounts: Array<ConnectableOauthPageAndIgAccountForTalentSignIn>;
};

export type SocialAuthTalentSignInTwitterInput = {
  callbackUrl: Scalars['String']['input'];
  response: Scalars['String']['input'];
  screenName?: InputMaybe<Scalars['String']['input']>;
};

export type SocialAuthTalentSignInTwitterPayload = {
  __typename?: 'SocialAuthTalentSignInTwitterPayload';
  status: SocialAccountStatus;
  twName: Scalars['String']['output'];
  twScreenName: Scalars['String']['output'];
  twUserId: Scalars['String']['output'];
};

export type SocialAuthTalentSignInYouTubeInput = {
  callbackUrl: Scalars['String']['input'];
  channelId?: InputMaybe<Scalars['String']['input']>;
  response: Scalars['String']['input'];
};

export type SocialAuthTalentSignInYouTubePayload = {
  __typename?: 'SocialAuthTalentSignInYouTubePayload';
  channels: Array<ConnectableOauthYouTubeAccountForTalentSignIn>;
};

export enum SocialMediaTypeForRateCard {
  FACEBOOK = 'FACEBOOK',
  INSTAGRAM = 'INSTAGRAM',
  INSTAGRAM_STORY = 'INSTAGRAM_STORY',
  TIKTOK = 'TIKTOK',
  TWITTER = 'TWITTER',
  YOUTUBE = 'YOUTUBE',
}

export enum SocialPostType {
  FACEBOOK = 'FACEBOOK',
  FACEBOOK_PAGE = 'FACEBOOK_PAGE',
  INSTAGRAM = 'INSTAGRAM',
  INSTAGRAM_REEL = 'INSTAGRAM_REEL',
  INSTAGRAM_STORY = 'INSTAGRAM_STORY',
  THREADS = 'THREADS',
  TIKTOK = 'TIKTOK',
  TWITTER = 'TWITTER',
  YOUTUBE = 'YOUTUBE',
}

export type SponsoredPostOrderBy = {
  field?: InputMaybe<SponsoredPostSortField>;
  order?: InputMaybe<ORDER>;
};

/** An enumeration. */
export enum SponsoredPostSortField {
  COMMENT = 'COMMENT',
  ENGAGEMENT = 'ENGAGEMENT',
  LIKE = 'LIKE',
  POST_DATE = 'POST_DATE',
  REACH = 'REACH',
  SAVED = 'SAVED',
  SHARE = 'SHARE',
  VIEW = 'VIEW',
}

export type StaffComment = {
  __typename?: 'StaffComment';
  content: Scalars['String']['output'];
  date: Scalars['String']['output'];
};

export type StateUpdateEvent = ChatEvent & {
  __typename?: 'StateUpdateEvent';
  id: Scalars['String']['output'];
  timestamp: Scalars['String']['output'];
};

export type StripeDeepLink = {
  __typename?: 'StripeDeepLink';
  url: Scalars['String']['output'];
};

export type SubmitFormInput = {
  id: Scalars['Long']['input'];
  questions: Array<SubmitQuestion>;
  recaptchaResponse: Scalars['String']['input'];
};

export type SubmitFormInternalInput = {
  id: Scalars['Long']['input'];
  questions: Array<SubmitQuestionInternal>;
};

export type SubmitFormInternalPayload = {
  __typename?: 'SubmitFormInternalPayload';
  ok: Scalars['Boolean']['output'];
};

export type SubmitFormPayload = {
  __typename?: 'SubmitFormPayload';
  ok: Scalars['Boolean']['output'];
};

export type SubmitQuestion = {
  answeredOptionIds: Array<Scalars['Long']['input']>;
  answeredTexts: Array<Scalars['String']['input']>;
  questionId: Scalars['Long']['input'];
};

export type SubmitQuestionInternal = {
  answeredOptionIds: Array<Scalars['Long']['input']>;
  answeredTexts: Array<Scalars['String']['input']>;
  questionId: Scalars['Long']['input'];
};

export type SubscribeAnalyticsPlan = {
  __typename?: 'SubscribeAnalyticsPlan';
  ok: Scalars['Boolean']['output'];
};

export type SubscribeAnalyticsPlanInput = {
  /** advertiser's system id */
  advertiserId: Scalars['Int']['input'];
  /** the selected plan */
  plan: AnalyticsSubscriptionPlanType;
};

export type SubscribeAnalyticsTrialPlan = {
  __typename?: 'SubscribeAnalyticsTrialPlan';
  ok: Scalars['Boolean']['output'];
};

export type SubscriptionAvailableFeatures = {
  __typename?: 'SubscriptionAvailableFeatures';
  compareEnabled: Scalars['Boolean']['output'];
  dashboardEnabled: Scalars['Boolean']['output'];
  hashtagsEnabled: Scalars['Boolean']['output'];
  interactionEnabled: Scalars['Boolean']['output'];
};

export type SubscriptionCapabilities = {
  __typename?: 'SubscriptionCapabilities';
  maxCompareAccounts: Scalars['Int']['output'];
  maxHashtags: Scalars['Int']['output'];
};

export type SubscriptionPlan = {
  __typename?: 'SubscriptionPlan';
  analytics?: Maybe<SubscriptionPlanDetail>;
  marketplace?: Maybe<SubscriptionPlanDetail>;
};

export type SubscriptionPlanDetail = {
  __typename?: 'SubscriptionPlanDetail';
  currency: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  expiredAt?: Maybe<Scalars['DateTime']['output']>;
  /** price id */
  id?: Maybe<Scalars['Int']['output']>;
  isCanceled: Scalars['Boolean']['output'];
  isExpired: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  price: Scalars['Float']['output'];
};

export type SubscriptionPlanForAdvertiserUserSettings = {
  __typename?: 'SubscriptionPlanForAdvertiserUserSettings';
  availableFeatures: SubscriptionAvailableFeatures;
  capabilities: SubscriptionCapabilities;
  id: Scalars['Int']['output'];
  type: AnalyticsSubscriptionPlanType;
};

export type SwitchInfluencerForStaffInput = {
  influencerId: Scalars['Int']['input'];
};

export type SwitchInfluencerForStaffPayload = {
  __typename?: 'SwitchInfluencerForStaffPayload';
  token: Scalars['String']['output'];
};

export type SwitchRoleToAdmin = {
  __typename?: 'SwitchRoleToAdmin';
  ok: Scalars['Boolean']['output'];
};

export type SwitchRoleToAdminInput = {
  countryId: Scalars['String']['input'];
  email: Scalars['String']['input'];
  id: Scalars['Int']['input'];
  name: Scalars['String']['input'];
  password?: InputMaybe<Scalars['String']['input']>;
  permissions?: InputMaybe<CustomPermissionsInput>;
};

export type SwitchRoleToAdvertiser = {
  __typename?: 'SwitchRoleToAdvertiser';
  ok: Scalars['Boolean']['output'];
};

export type SwitchRoleToAdvertiserInput = {
  advertiserId: Scalars['Int']['input'];
  countryId: Scalars['String']['input'];
  email: Scalars['String']['input'];
  id: Scalars['Int']['input'];
  name: Scalars['String']['input'];
  notificationSetting: Scalars['Boolean']['input'];
  password?: InputMaybe<Scalars['String']['input']>;
  permissions?: InputMaybe<CustomPermissionsInput>;
};

export type SwitchRoleToAgency = {
  __typename?: 'SwitchRoleToAgency';
  ok: Scalars['Boolean']['output'];
};

export type SwitchRoleToAgencyInput = {
  agencyId: Scalars['Int']['input'];
  countryId: Scalars['String']['input'];
  email: Scalars['String']['input'];
  id: Scalars['Int']['input'];
  name: Scalars['String']['input'];
  password?: InputMaybe<Scalars['String']['input']>;
  permissions?: InputMaybe<CustomPermissionsInput>;
};

export type SwitchRoleToPartner = {
  __typename?: 'SwitchRoleToPartner';
  ok: Scalars['Boolean']['output'];
};

export type SwitchRoleToPartnerInput = {
  countryId: Scalars['String']['input'];
  email: Scalars['String']['input'];
  id: Scalars['Int']['input'];
  name: Scalars['String']['input'];
  partnerId: Scalars['Int']['input'];
  password?: InputMaybe<Scalars['String']['input']>;
  permissions?: InputMaybe<CustomPermissionsInput>;
};

export type SwitchRoleToStaff = {
  __typename?: 'SwitchRoleToStaff';
  ok: Scalars['Boolean']['output'];
};

export type SwitchRoleToStaffInput = {
  advertiserIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  countryId: Scalars['String']['input'];
  email: Scalars['String']['input'];
  id: Scalars['Int']['input'];
  name: Scalars['String']['input'];
  password?: InputMaybe<Scalars['String']['input']>;
  permissions?: InputMaybe<CustomPermissionsInput>;
};

export type SwitchRoleToTalentAgency = {
  __typename?: 'SwitchRoleToTalentAgency';
  ok: Scalars['Boolean']['output'];
};

export type SwitchRoleToTalentAgencyInput = {
  countryId: Scalars['String']['input'];
  email: Scalars['String']['input'];
  id: Scalars['Int']['input'];
  name: Scalars['String']['input'];
  password?: InputMaybe<Scalars['String']['input']>;
  permissions?: InputMaybe<CustomPermissionsInput>;
  talentAgencyId: Scalars['Int']['input'];
};

/** An enumeration. */
export enum TTCMStatus {
  APPROVED = 'APPROVED',
  INVITED = 'INVITED',
  NOT_INVITED = 'NOT_INVITED',
  REJECTED = 'REJECTED',
}

export type TalentAgency = {
  __typename?: 'TalentAgency';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type TalentAgencyOrPartner = {
  __typename?: 'TalentAgencyOrPartner';
  /** unique result index used for FE purpose */
  id: Scalars['Int']['output'];
  /** id of Talent Agency or Partner */
  idOfTalentOrPartner: Scalars['Int']['output'];
  /** name of Talent Agency or Partner */
  name: Scalars['String']['output'];
  /** TALENT_AGENCY or PARTNER */
  role: UserRoles;
};

export enum TalentAgencyReconnectAnalyticsRedirectType {
  ANY_CREATOR_INFLUENCER_PROFILE_CONNECT = 'ANY_CREATOR_INFLUENCER_PROFILE_CONNECT',
  ANY_TAG_INFLUENCER_PROFILE_CONNECT = 'ANY_TAG_INFLUENCER_PROFILE_CONNECT',
}

/**  Stores a redirect URL that FE uses to reconnect talent influencer accounts */
export type TalentAgencyReconnectAnalyticsSocialAuthRedirectUrlPayload = {
  __typename?: 'TalentAgencyReconnectAnalyticsSocialAuthRedirectUrlPayload';
  redirectUri: Scalars['String']['output'];
};

export type TalentAgencyUserWithRole = {
  __typename?: 'TalentAgencyUserWithRole';
  companyName?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  role: UserRoles;
};

export type TalentAgencyWithCompanies = {
  __typename?: 'TalentAgencyWithCompanies';
  advertiserIds: Array<Scalars['Int']['output']>;
  agencyIds: Array<Scalars['Int']['output']>;
  companyUrl?: Maybe<Scalars['String']['output']>;
  country?: Maybe<CountryName>;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  netsuiteId?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  privateInfluencers: Array<PrivateTalentInfluencer>;
};

export type TalentAgencyWithNSVerification = {
  __typename?: 'TalentAgencyWithNSVerification';
  companyUrl?: Maybe<Scalars['String']['output']>;
  country?: Maybe<CountryName>;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  netsuiteId?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  users: Scalars['Int']['output'];
  verifiedStatus?: Maybe<NetSuiteVerifiedStatus>;
};

export type TalentInfluencerDetailsForEditInput = {
  influencerId: Scalars['Int']['input'];
};

export type TalentInfluencerDetailsForEditPayload = {
  __typename?: 'TalentInfluencerDetailsForEditPayload';
  category: Array<Category>;
  connectedFacebook?: Maybe<ConnectedFacebook>;
  connectedFacebookPages: Array<ConnectedPage>;
  connectedInstagrams: Array<ConnectedPageAndIgAccount>;
  connectedTwitters: Array<ConnectedTwitter>;
  connectedYouTubes: Array<ConnectedYouTube>;
  country: Country;
  dateOfBirth?: Maybe<Scalars['String']['output']>;
  defaultEngagementSelectionReason: Scalars['String']['output'];
  email?: Maybe<Scalars['String']['output']>;
  gender: Genders;
  internalMemo: Scalars['String']['output'];
  introduce: Scalars['String']['output'];
  isProAccount: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  phoneNumber?: Maybe<Scalars['String']['output']>;
  region?: Maybe<Region>;
  tags: Array<Scalars['String']['output']>;
};

export type TextComponentInput = {
  fontSize: FontSize;
  text: Scalars['String']['input'];
  weighted: Scalars['Boolean']['input'];
};

export type TextComponentPayload = {
  __typename?: 'TextComponentPayload';
  fontSize: FontSize;
  text: Scalars['String']['output'];
  weighted: Scalars['Boolean']['output'];
};

export type ThreadsInfluencer = {
  __typename?: 'ThreadsInfluencer';
  age?: Maybe<Scalars['Int']['output']>;
  avatar?: Maybe<Scalars['String']['output']>;
  averageLikes?: Maybe<Scalars['Float']['output']>;
  categories: Array<CategoryName>;
  comments?: Maybe<Scalars['Int']['output']>;
  country: CountryName;
  engagementPostedCount?: Maybe<Scalars['Int']['output']>;
  engagementProposedCount?: Maybe<Scalars['Int']['output']>;
  gender: Genders;
  /** Influencer Id */
  id: Scalars['Int']['output'];
  influencerEngagement?: Maybe<Scalars['Int']['output']>;
  influencerEngagementRate?: Maybe<Scalars['Float']['output']>;
  influencerFollowers?: Maybe<Scalars['Int']['output']>;
  isBrandAccount: Scalars['Boolean']['output'];
  likes?: Maybe<Scalars['Int']['output']>;
  marketplaceJoinedCount?: Maybe<Scalars['Int']['output']>;
  marketplacePostedCount?: Maybe<Scalars['Int']['output']>;
  name: Scalars['String']['output'];
  popularPosts?: Maybe<Array<SocialAccountPopularPost>>;
  socialAccountAverageEngagement?: Maybe<Scalars['Int']['output']>;
  socialAccountEngagement?: Maybe<Scalars['Int']['output']>;
  socialAccountEngagementRate?: Maybe<Scalars['Float']['output']>;
  socialAccountFollowers?: Maybe<Scalars['Int']['output']>;
  /** Social Account System Id */
  socialAccountId: Scalars['Int']['output'];
  /** Social Account Status */
  socialAccountStatus: SocialAccountStatus;
};

/** An enumeration. */
export enum TikTokCampaignType {
  NORMAL = 'NORMAL',
  TTCM = 'TTCM',
}

export type TiktokAccountForProfileV2 = {
  __typename?: 'TiktokAccountForProfileV2';
  avatar?: Maybe<Scalars['String']['output']>;
  followersCount?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
  profile?: Maybe<Scalars['String']['output']>;
  ttcmStatus?: Maybe<TTCMStatus>;
  url?: Maybe<Scalars['String']['output']>;
};

export type TiktokAudienceSection = {
  __typename?: 'TiktokAudienceSection';
  ageRates: TiktokFollowersAgeGroup;
  countryRates?: Maybe<Array<TiktokFollowerCountryRate>>;
  femaleRate?: Maybe<Scalars['Float']['output']>;
  maleRate?: Maybe<Scalars['Float']['output']>;
};

export type TiktokFollowerCountryRate = {
  __typename?: 'TiktokFollowerCountryRate';
  countryId: Scalars['String']['output'];
  name: Scalars['String']['output'];
  rate: Scalars['Float']['output'];
};

export type TiktokFollowersAgeGroup = {
  __typename?: 'TiktokFollowersAgeGroup';
  ageGroup?: Maybe<Array<FollowersAgeGroup>>;
  rate?: Maybe<Array<Scalars['Float']['output']>>;
};

export type TiktokInfluencer = {
  __typename?: 'TiktokInfluencer';
  age?: Maybe<Scalars['Int']['output']>;
  avatar?: Maybe<Scalars['String']['output']>;
  averageLikes?: Maybe<Scalars['Float']['output']>;
  averageViews?: Maybe<Scalars['Float']['output']>;
  categories: Array<CategoryName>;
  comments?: Maybe<Scalars['Int']['output']>;
  country: CountryName;
  engagementPostedCount?: Maybe<Scalars['Int']['output']>;
  engagementProposedCount?: Maybe<Scalars['Int']['output']>;
  gender: Genders;
  /** Influencer Id */
  id: Scalars['Int']['output'];
  influencerEngagement?: Maybe<Scalars['Int']['output']>;
  influencerEngagementRate?: Maybe<Scalars['Float']['output']>;
  influencerFollowers?: Maybe<Scalars['Int']['output']>;
  isBrandAccount: Scalars['Boolean']['output'];
  likes?: Maybe<Scalars['Int']['output']>;
  marketplaceJoinedCount?: Maybe<Scalars['Int']['output']>;
  marketplacePostedCount?: Maybe<Scalars['Int']['output']>;
  name: Scalars['String']['output'];
  popularPosts?: Maybe<Array<SocialAccountPopularPost>>;
  shares?: Maybe<Scalars['Int']['output']>;
  socialAccountEngagement?: Maybe<Scalars['Int']['output']>;
  socialAccountEngagementRate?: Maybe<Scalars['Float']['output']>;
  socialAccountFollowers?: Maybe<Scalars['Int']['output']>;
  /** Social Account System Id */
  socialAccountId: Scalars['Int']['output'];
  /** Social Account Status */
  socialAccountStatus: SocialAccountStatus;
  ttcmStatus?: Maybe<TTCMStatus>;
  views?: Maybe<Scalars['BigInt']['output']>;
};

export type TokenAuthLongLive = {
  __typename?: 'TokenAuthLongLive';
  role: UserRoles;
  token: Scalars['String']['output'];
  userId: Scalars['Int']['output'];
};

export type TokenAuthLongLiveInput = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type TotalCmsRevenue = {
  __typename?: 'TotalCmsRevenue';
  currency: Scalars['String']['output'];
  total: Scalars['Float']['output'];
};

export type TotalInfluencerReport = {
  __typename?: 'TotalInfluencerReport';
  click?: Maybe<Scalars['Int']['output']>;
  comment: Scalars['Int']['output'];
  conversion?: Maybe<Scalars['Int']['output']>;
  currency: Scalars['String']['output'];
  like: Scalars['Int']['output'];
  pendingRevenue: Scalars['Float']['output'];
  revenue: Scalars['Float']['output'];
  share: Scalars['Int']['output'];
  view: Scalars['Int']['output'];
};

export type TotalNumber = {
  __typename?: 'TotalNumber';
  totalNumber: Scalars['Int']['output'];
};

export type TrendingFacebookInfluencer = {
  __typename?: 'TrendingFacebookInfluencer';
  age?: Maybe<Scalars['Int']['output']>;
  avatar?: Maybe<Scalars['String']['output']>;
  country: CountryName;
  facebookType: SocialAccountType;
  followersGrowthRate: Scalars['Float']['output'];
  gender: Genders;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  popularPosts?: Maybe<Array<SocialAccountPopularPost>>;
  rank: Scalars['Int']['output'];
  socialAccountEngagementRate: Scalars['Float']['output'];
  socialAccountFollowers: Scalars['Int']['output'];
  socialAccountId: Scalars['ID']['output'];
  status: SocialAccountStatus;
};

export type TrendingFacebookInfluencerSearchResult = {
  __typename?: 'TrendingFacebookInfluencerSearchResult';
  totalNumber: Scalars['Int']['output'];
  trending: Array<TrendingFacebookInfluencer>;
};

export type TrendingInstagramInfluencerSearchResultV2 = {
  __typename?: 'TrendingInstagramInfluencerSearchResultV2';
  totalNumber: Scalars['Int']['output'];
  trendingAccounts: Array<TrendingInstagramInfluencerV2>;
};

export type TrendingInstagramInfluencerV2 = {
  __typename?: 'TrendingInstagramInfluencerV2';
  age?: Maybe<Scalars['Int']['output']>;
  avatar?: Maybe<Scalars['String']['output']>;
  country: CountryName;
  followersGrowthRate: Scalars['Float']['output'];
  gender: Genders;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  popularPosts?: Maybe<Array<SocialAccountPopularPost>>;
  rank: Scalars['Int']['output'];
  socialAccountEngagementRate: Scalars['Float']['output'];
  socialAccountFollowers: Scalars['Int']['output'];
  socialAccountId: Scalars['ID']['output'];
  status: SocialAccountStatus;
};

export type TrendingYoutubeInfluencerSearchResultV2 = {
  __typename?: 'TrendingYoutubeInfluencerSearchResultV2';
  totalNumber: Scalars['Int']['output'];
  trendingAccounts: Array<TrendingYoutubeInfluencerV2>;
};

export type TrendingYoutubeInfluencerV2 = {
  __typename?: 'TrendingYoutubeInfluencerV2';
  age?: Maybe<Scalars['Int']['output']>;
  avatar?: Maybe<Scalars['String']['output']>;
  country: CountryName;
  followersGrowthRate: Scalars['Float']['output'];
  gender: Genders;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  popularPosts?: Maybe<Array<SocialAccountPopularPost>>;
  rank: Scalars['Int']['output'];
  socialAccountAverageViews: Scalars['Float']['output'];
  socialAccountFollowers: Scalars['Int']['output'];
  socialAccountId: Scalars['ID']['output'];
  status: SocialAccountStatus;
};

/** An enumeration. */
export enum TutorialName {
  PAYMENT = 'PAYMENT',
  SEARCH_JOB = 'SEARCH_JOB',
  YOUR_JOB = 'YOUR_JOB',
}

export type TwitterAnalyticsFeedPostPayload = {
  __typename?: 'TwitterAnalyticsFeedPostPayload';
  id: Scalars['Int']['output'];
  likes: Scalars['Int']['output'];
  replies: Scalars['Int']['output'];
  retweets: Scalars['Int']['output'];
  thumbnail?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
};

export type TwitterAnalyticsOverviewHistoryItem = {
  __typename?: 'TwitterAnalyticsOverviewHistoryItem';
  count: Scalars['Int']['output'];
  date: Scalars['String']['output'];
};

export type TwitterAnalyticsOverviewHistoryRealItem = {
  __typename?: 'TwitterAnalyticsOverviewHistoryRealItem';
  count: Scalars['Float']['output'];
  date: Scalars['String']['output'];
};

export type TwitterAnalyticsOverviewItem = {
  __typename?: 'TwitterAnalyticsOverviewItem';
  growth: Scalars['Int']['output'];
  history: Array<TwitterAnalyticsOverviewHistoryItem>;
  percentage: Scalars['Float']['output'];
  total: Scalars['Int']['output'];
};

export type TwitterAnalyticsOverviewPayload = {
  __typename?: 'TwitterAnalyticsOverviewPayload';
  engagement: TwitterAnalyticsOverviewItem;
  engagementRate: TwitterAnalyticsOverviewRealTotalItem;
  engagementRates?: Maybe<TwitterAnalyticsOverviewRealItem>;
  follower: TwitterAnalyticsOverviewItem;
  impression: TwitterAnalyticsOverviewItem;
  lastUpdated: Scalars['DateTime']['output'];
  like: TwitterAnalyticsOverviewItem;
  post: TwitterAnalyticsOverviewItem;
  profileClick: TwitterAnalyticsOverviewTotalItem;
  profileClicks?: Maybe<TwitterAnalyticsOverviewItem>;
  quotedTweet: TwitterAnalyticsOverviewItem;
  reply: TwitterAnalyticsOverviewItem;
  retweet: TwitterAnalyticsOverviewItem;
  urlClick: TwitterAnalyticsOverviewTotalItem;
  urlClicks?: Maybe<TwitterAnalyticsOverviewItem>;
  view: TwitterAnalyticsOverviewItem;
};

export type TwitterAnalyticsOverviewRealItem = {
  __typename?: 'TwitterAnalyticsOverviewRealItem';
  growth: Scalars['Float']['output'];
  history: Array<TwitterAnalyticsOverviewHistoryRealItem>;
  percentage: Scalars['Float']['output'];
  total: Scalars['Float']['output'];
};

export type TwitterAnalyticsOverviewRealTotalItem = {
  __typename?: 'TwitterAnalyticsOverviewRealTotalItem';
  growth: Scalars['Float']['output'];
  percentage: Scalars['Float']['output'];
  total: Scalars['Float']['output'];
};

export type TwitterAnalyticsOverviewTotalItem = {
  __typename?: 'TwitterAnalyticsOverviewTotalItem';
  growth: Scalars['Int']['output'];
  percentage: Scalars['Float']['output'];
  total: Scalars['Int']['output'];
};

export type TwitterAnalyticsPostByIdPayload = {
  __typename?: 'TwitterAnalyticsPostByIdPayload';
  avatar: Scalars['String']['output'];
  followers: Scalars['Int']['output'];
  post: TwitterAnalyticsPostPayload;
  username: Scalars['String']['output'];
};

export type TwitterAnalyticsPostComment = {
  __typename?: 'TwitterAnalyticsPostComment';
  commentId: Scalars['String']['output'];
  content: Scalars['String']['output'];
  posterName: Scalars['String']['output'];
};

export type TwitterAnalyticsPostItemPayload = {
  __typename?: 'TwitterAnalyticsPostItemPayload';
  engagement: Scalars['Int']['output'];
  engagementRate: Scalars['Float']['output'];
  id: Scalars['Int']['output'];
  impression: Scalars['Int']['output'];
  like: Scalars['Int']['output'];
  postDate: Scalars['String']['output'];
  postUrl: Scalars['String']['output'];
  reply: Scalars['Int']['output'];
  retweet: Scalars['Int']['output'];
  thumbnail?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  view?: Maybe<Scalars['Int']['output']>;
};

export type TwitterAnalyticsPostPayload = {
  __typename?: 'TwitterAnalyticsPostPayload';
  comments: Array<TwitterAnalyticsPostComment>;
  content: Scalars['String']['output'];
  engagement: Scalars['Float']['output'];
  id: Scalars['Int']['output'];
  images: Array<Scalars['String']['output']>;
  impression: Scalars['Int']['output'];
  like: Scalars['Int']['output'];
  postDate: Scalars['Date']['output'];
  postUrl: Scalars['String']['output'];
  quoteTweet: Scalars['Int']['output'];
  reply: Scalars['Int']['output'];
  retweet: Scalars['Int']['output'];
  view: Scalars['Int']['output'];
};

export type TwitterAnalyticsPostsByDatePayload = {
  __typename?: 'TwitterAnalyticsPostsByDatePayload';
  avatar: Scalars['String']['output'];
  followers: Scalars['Int']['output'];
  posts: Array<TwitterAnalyticsPostPayload>;
  username: Scalars['String']['output'];
};

export type TwitterAnalyticsPostsPayload = {
  __typename?: 'TwitterAnalyticsPostsPayload';
  posts: Array<TwitterAnalyticsPostItemPayload>;
};

export type TwitterAnalyticsPostsSortInput = {
  field?: InputMaybe<TwitterAnalyticsPostsSortOrder>;
  order?: InputMaybe<Order>;
};

export enum TwitterAnalyticsPostsSortOrder {
  ENGAGEMENT = 'ENGAGEMENT',
  ENGAGEMENT_RATE = 'ENGAGEMENT_RATE',
  IMPRESSIONS = 'IMPRESSIONS',
  LIKES = 'LIKES',
  NEGATIVE_RATE = 'NEGATIVE_RATE',
  POSITIVE_RATE = 'POSITIVE_RATE',
  POST_DATE = 'POST_DATE',
  REPLIES = 'REPLIES',
  RETWEETS = 'RETWEETS',
  VIEWS = 'VIEWS',
}

export type TwitterAnalyticsPostsStatisticPayload = {
  __typename?: 'TwitterAnalyticsPostsStatisticPayload';
  averageEngagement: TwitterAverageEngagementPayload;
  postingHabits: Array<TwitterPostingHabitsPayload>;
};

export type TwitterAverageEngagementItemPayload = {
  __typename?: 'TwitterAverageEngagementItemPayload';
  growth: Scalars['Float']['output'];
  total: Scalars['Float']['output'];
};

export type TwitterAverageEngagementPayload = {
  __typename?: 'TwitterAverageEngagementPayload';
  avgImpression: TwitterAverageEngagementItemPayload;
  avgLike: TwitterAverageEngagementItemPayload;
  avgPostsWeek?: Maybe<TwitterAverageEngagementItemPayload>;
  avgReply: TwitterAverageEngagementItemPayload;
  avgRetweet: TwitterAverageEngagementItemPayload;
  avgView?: Maybe<TwitterAverageEngagementItemPayload>;
};

export type TwitterForProfileV2 = {
  __typename?: 'TwitterForProfileV2';
  analyticsEnabled: Scalars['Boolean']['output'];
  avatar?: Maybe<Scalars['String']['output']>;
  followersCount?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
  profile?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type TwitterInfluencer = {
  __typename?: 'TwitterInfluencer';
  age?: Maybe<Scalars['Int']['output']>;
  analyticsWarning: Scalars['Boolean']['output'];
  avatar?: Maybe<Scalars['String']['output']>;
  averageLikes?: Maybe<Scalars['Float']['output']>;
  categories: Array<CategoryName>;
  country: CountryName;
  engagementPostedCount?: Maybe<Scalars['Int']['output']>;
  engagementProposedCount?: Maybe<Scalars['Int']['output']>;
  gender: Genders;
  /** Influencer Id */
  id: Scalars['Int']['output'];
  influencerEngagement?: Maybe<Scalars['Int']['output']>;
  influencerEngagementRate?: Maybe<Scalars['Float']['output']>;
  influencerFollowers?: Maybe<Scalars['Int']['output']>;
  isBrandAccount: Scalars['Boolean']['output'];
  likes?: Maybe<Scalars['Int']['output']>;
  marketplaceJoinedCount?: Maybe<Scalars['Int']['output']>;
  marketplacePostedCount?: Maybe<Scalars['Int']['output']>;
  name: Scalars['String']['output'];
  popularPosts?: Maybe<Array<SocialAccountPopularPost>>;
  replies?: Maybe<Scalars['Int']['output']>;
  retweets?: Maybe<Scalars['Int']['output']>;
  /** only for Twitter to show on UI */
  socialAccountAverageEngagement?: Maybe<Scalars['Int']['output']>;
  socialAccountEngagement?: Maybe<Scalars['Int']['output']>;
  socialAccountEngagementRate?: Maybe<Scalars['Float']['output']>;
  socialAccountFollowers?: Maybe<Scalars['Int']['output']>;
  /** Social Account System Id */
  socialAccountId: Scalars['Int']['output'];
  /** Social Account Status */
  socialAccountStatus: SocialAccountStatus;
  views?: Maybe<Scalars['Int']['output']>;
};

export type TwitterPostingHabitsPayload = {
  __typename?: 'TwitterPostingHabitsPayload';
  averageComments: Scalars['Float']['output'];
  averageLikes: Scalars['Float']['output'];
  engagementRate: Scalars['Float']['output'];
  hour: Scalars['Int']['output'];
  weekday: Scalars['Int']['output'];
};

export enum UGCPostStatus {
  APPROVED = 'APPROVED',
  NOT_REQUESTED = 'NOT_REQUESTED',
  REJECTED = 'REJECTED',
  REQUESTED = 'REQUESTED',
}

export enum UGCPostType {
  HASHTAG = 'HASHTAG',
  MENTIONED = 'MENTIONED',
  TAGGED = 'TAGGED',
}

export type UUUMSocialAuthSignUpInput = {
  categoryIds: Array<Scalars['Int']['input']>;
  countryId: Scalars['String']['input'];
  dateOfBirth: Scalars['String']['input'];
  email: Scalars['String']['input'];
  gender: Genders;
  name: Scalars['String']['input'];
  phoneNumber: Scalars['String']['input'];
  regionId: Scalars['Int']['input'];
  uuid: Scalars['ID']['input'];
};

export type UUUMSocialAuthSignUpPayload = {
  __typename?: 'UUUMSocialAuthSignUpPayload';
  token?: Maybe<Scalars['String']['output']>;
};

export type UnApproveApplicantInfluencers = {
  __typename?: 'UnApproveApplicantInfluencers';
  ok: Scalars['Boolean']['output'];
};

export type UnApproveApplicantInfluencersInput = {
  influencerIds: Array<Scalars['Int']['input']>;
  marketplaceCampaignId: Scalars['Int']['input'];
};

export type UnassignChatPICInput = {
  chatId: Scalars['String']['input'];
};

export type UnassignChatPICPayload = {
  __typename?: 'UnassignChatPICPayload';
  ok: Scalars['Boolean']['output'];
};

/**  `UnreadNotificationCountByAppPayload` payload houses a `count` integers to show how many notification are not read yet. */
export type UnreadNotificationCountByAppPayload = {
  __typename?: 'UnreadNotificationCountByAppPayload';
  count: Scalars['Long']['output'];
};

/**  `UnreadNotificationCountPayload` payload houses a `count` integers to show how many notification are not read yet. */
export type UnreadNotificationCountPayload = {
  __typename?: 'UnreadNotificationCountPayload';
  count: Scalars['Long']['output'];
};

/**
 *  `UnreadNotificationInput` input accepts a `checkpoint` to get notifications beyond the checkpoint. A value of `null`
 *  can be provided to start from the latest notification.
 */
export type UnreadNotificationInput = {
  checkpoint?: InputMaybe<Scalars['Long']['input']>;
};

export type UnresolveChatInput = {
  chatId: Scalars['String']['input'];
};

export type UnresolveChatPayload = {
  __typename?: 'UnresolveChatPayload';
  ok: Scalars['Boolean']['output'];
};

export type UpdateAccountInformationInput = {
  brandName: Scalars['String']['input'];
  categoryIds: Array<Scalars['Int']['input']>;
  countryId: Scalars['String']['input'];
  dateOfBirth: Scalars['String']['input'];
  gender: Genders;
  introduce: Scalars['String']['input'];
  name: Scalars['String']['input'];
  regionId: Scalars['Int']['input'];
};

export type UpdateAccountInformationPayload = {
  __typename?: 'UpdateAccountInformationPayload';
  ok: Scalars['Boolean']['output'];
};

export type UpdateAddress = {
  __typename?: 'UpdateAddress';
  ok: Scalars['Boolean']['output'];
};

export type UpdateAddressInformationInput = {
  address: Scalars['String']['input'];
  address2: Scalars['String']['input'];
  city: Scalars['String']['input'];
  fullName: Scalars['String']['input'];
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  postalCode: Scalars['String']['input'];
  province: Scalars['String']['input'];
};

export type UpdateAddressInformationPayload = {
  __typename?: 'UpdateAddressInformationPayload';
  ok: Scalars['Boolean']['output'];
};

export type UpdateAddressInput = {
  address: Scalars['String']['input'];
  address2: Scalars['String']['input'];
  city: Scalars['String']['input'];
  fullName: Scalars['String']['input'];
  postalCode: Scalars['String']['input'];
  province: Scalars['String']['input'];
};

export type UpdateAdmin = {
  __typename?: 'UpdateAdmin';
  ok: Scalars['Boolean']['output'];
};

export type UpdateAdminInput = {
  countryId?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Int']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  permissions?: InputMaybe<CustomPermissionsInput>;
};

export type UpdateAdvertiser = {
  __typename?: 'UpdateAdvertiser';
  ok: Scalars['Boolean']['output'];
};

export type UpdateAdvertiserInput = {
  anyxMasterId?: InputMaybe<Scalars['Int']['input']>;
  companyUrl?: InputMaybe<Scalars['String']['input']>;
  countryId?: InputMaybe<Scalars['String']['input']>;
  hubspotId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Int']['input'];
  internalNetsuiteId?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateAdvertiserUser = {
  __typename?: 'UpdateAdvertiserUser';
  ok: Scalars['Boolean']['output'];
};

export type UpdateAdvertiserUserInput = {
  advertiserId?: InputMaybe<Scalars['Int']['input']>;
  countryId?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Int']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  notificationSetting?: InputMaybe<Scalars['Boolean']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  permissions?: InputMaybe<CustomPermissionsInput>;
};

export type UpdateAdvertiserUserSettings = {
  __typename?: 'UpdateAdvertiserUserSettings';
  ok: Scalars['Boolean']['output'];
};

export type UpdateAdvertiserUserSettingsInput = {
  advertiserCompanyUrl: Scalars['String']['input'];
  advertiserCountryId: Scalars['String']['input'];
  advertiserName: Scalars['String']['input'];
  advertiserPhoneNumber: Scalars['String']['input'];
  countryId: Scalars['String']['input'];
  email: Scalars['String']['input'];
  name: Scalars['String']['input'];
  notificationSetting: Scalars['Boolean']['input'];
};

export type UpdateAgency = {
  __typename?: 'UpdateAgency';
  ok: Scalars['Boolean']['output'];
};

export type UpdateAgencyInput = {
  agencyId: Scalars['Int']['input'];
  countryId?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Int']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  permissions?: InputMaybe<CustomPermissionsInput>;
};

export type UpdateAutoManagedAffiliateCommissionRates = {
  __typename?: 'UpdateAutoManagedAffiliateCommissionRates';
  ok: Scalars['Boolean']['output'];
};

export type UpdateAutoManagedAffiliateCommissionRatesInput = {
  id: Scalars['Int']['input'];
  influencers: Array<AutoManagedAffiliateCommissionRateInput>;
};

export type UpdateAutoManagedCampaignInput = {
  campaignId: Scalars['Int']['input'];
  marginRate: Scalars['Float']['input'];
  requirement: Scalars['String']['input'];
  serviceInfo: Scalars['String']['input'];
  status: AutoManagedCampaignStatus;
};

export type UpdateAutoManagedCampaignPayload = {
  __typename?: 'UpdateAutoManagedCampaignPayload';
  ok: Scalars['Boolean']['output'];
};

export type UpdateAutoManagedCampaignsStatusInput = {
  campaignIds: Array<Scalars['Int']['input']>;
  status: AutoManagedCampaignStatus;
};

export type UpdateAutoManagedCampaignsStatusPayload = {
  __typename?: 'UpdateAutoManagedCampaignsStatusPayload';
  ok: Scalars['Boolean']['output'];
};

export type UpdateBioSectionsInput = {
  sections: Array<BioSectionsInput>;
};

export type UpdateBioSectionsPayload = {
  __typename?: 'UpdateBioSectionsPayload';
  ok: Scalars['Boolean']['output'];
};

export type UpdateCmsAssetShareRate = {
  __typename?: 'UpdateCmsAssetShareRate';
  ok: Scalars['Boolean']['output'];
};

export type UpdateCmsAssetShareRateInput = {
  assets: Array<CmsAssetShareRateInput>;
  /** Channel System Id */
  cmsChannelId: Scalars['Int']['input'];
};

export type UpdateCmsChannelShareRate = {
  __typename?: 'UpdateCmsChannelShareRate';
  ok: Scalars['Boolean']['output'];
};

export type UpdateCmsChannelShareRateInput = {
  channels: Array<CmsChannelShareRateInput>;
};

export type UpdateCommentInput = {
  activityId: Scalars['Long']['input'];
  comment: Scalars['String']['input'];
};

export type UpdateCommentPayload = {
  __typename?: 'UpdateCommentPayload';
  ok: Scalars['Boolean']['output'];
};

export type UpdateEmailTemplateInput = {
  fans: Array<Scalars['Long']['input']>;
  id: Scalars['Long']['input'];
  isDraft: Scalars['Boolean']['input'];
  message: Scalars['String']['input'];
  scheduleDate?: InputMaybe<Scalars['String']['input']>;
  sendNow: Scalars['Boolean']['input'];
  sender: Scalars['String']['input'];
  subject: Scalars['String']['input'];
  tags: Array<Scalars['Long']['input']>;
};

export type UpdateEmailTemplatePayload = {
  __typename?: 'UpdateEmailTemplatePayload';
  ok: Scalars['Boolean']['output'];
};

export type UpdateEngagement = {
  __typename?: 'UpdateEngagement';
  ok: Scalars['Boolean']['output'];
};

export type UpdateEngagementBreakDownMode = {
  __typename?: 'UpdateEngagementBreakDownMode';
  ok: Scalars['Boolean']['output'];
};

export type UpdateEngagementBreakDownModeInput = {
  /** Engagement campaign id */
  campaignId: Scalars['Int']['input'];
  /** breakdown mode */
  jdMode: ProposalJobDescriptionBreakDownMode;
};

export type UpdateEngagementCouponList = {
  __typename?: 'UpdateEngagementCouponList';
  ok: Scalars['Boolean']['output'];
};

export type UpdateEngagementCouponListInput = {
  couponUploadFileUrl: Scalars['String']['input'];
  id: Scalars['Int']['input'];
};

export type UpdateEngagementInfluencerProposalList = {
  __typename?: 'UpdateEngagementInfluencerProposalList';
  ok: Scalars['Boolean']['output'];
};

export type UpdateEngagementInfluencerProposalListInput = {
  campaignId: Scalars['Int']['input'];
  influencerIds: Array<Scalars['Int']['input']>;
};

export type UpdateEngagementInfluencersBudget = {
  __typename?: 'UpdateEngagementInfluencersBudget';
  ok: Scalars['Boolean']['output'];
};

export type UpdateEngagementInfluencersBudgetInput = {
  campaignId: Scalars['Int']['input'];
  influencers: Array<EngagementInfluencerBudgetInput>;
};

export type UpdateEngagementInput = {
  adAgencyMarginRate?: InputMaybe<Scalars['Float']['input']>;
  advertiserId: Scalars['Int']['input'];
  budget: Scalars['Float']['input'];
  campaignCategoryId: Scalars['Int']['input'];
  categoryIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  countryIds: Array<Scalars['String']['input']>;
  couponList?: InputMaybe<Scalars['String']['input']>;
  couponUploadFiles?: InputMaybe<Array<Scalars['String']['input']>>;
  currency?: InputMaybe<Scalars['String']['input']>;
  defaultPostRequirement?: InputMaybe<Scalars['String']['input']>;
  endDate: Scalars['Date']['input'];
  hashtags?: InputMaybe<Array<Scalars['String']['input']>>;
  hubspotDealIds?: InputMaybe<Array<Scalars['String']['input']>>;
  id: Scalars['Int']['input'];
  influencerManagementPicIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  isTtcmCampaign?: InputMaybe<Scalars['Boolean']['input']>;
  landingPageUrl?: InputMaybe<Scalars['String']['input']>;
  marginRate?: InputMaybe<Scalars['Float']['input']>;
  materialUrl?: InputMaybe<Scalars['String']['input']>;
  materials?: InputMaybe<Array<Scalars['String']['input']>>;
  numberOfInfluencers?: InputMaybe<Scalars['Int']['input']>;
  objective: Scalars['String']['input'];
  salesPicIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  serviceInformation?: InputMaybe<Scalars['String']['input']>;
  serviceUrl?: InputMaybe<Scalars['String']['input']>;
  socialMedias: Array<CampaignSocialMediaType>;
  startDate: Scalars['Date']['input'];
  thumbNail: Scalars['String']['input'];
  title: Scalars['String']['input'];
  trackingOptions?: InputMaybe<Array<CampaignTrackingOption>>;
};

export type UpdateEngagementPostForInfluencerV2 = {
  __typename?: 'UpdateEngagementPostForInfluencerV2';
  ok: Scalars['Boolean']['output'];
};

export type UpdateEngagementPostInputForInfluencerV2 = {
  campaignId: Scalars['Int']['input'];
  content: Scalars['String']['input'];
  id: Scalars['Int']['input'];
  materialsUrl?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  planedPostDate: Scalars['Date']['input'];
  /** Represents social account system id */
  socialAccountId: Scalars['Int']['input'];
  /** Represents social account media type */
  socialAccountMedia: SocialAccountType;
  socialMedia: CampaignSocialMediaType;
};

export type UpdateEngagementPostInputV2 = {
  campaignId: Scalars['Int']['input'];
  content?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Int']['input'];
  influencerId: Scalars['Int']['input'];
  insightDataAcquisition?: InputMaybe<Scalars['Date']['input']>;
  insightUrls?: InputMaybe<Array<Scalars['String']['input']>>;
  materialsUrl?: InputMaybe<Array<Scalars['String']['input']>>;
  planedPostDate?: InputMaybe<Scalars['Date']['input']>;
  postUrl?: InputMaybe<Scalars['String']['input']>;
  /** Represents social account system id */
  socialAccountId: Scalars['Int']['input'];
  /** Represents social account media type */
  socialAccountMedia: SocialAccountType;
};

export type UpdateEngagementPostStatus = {
  __typename?: 'UpdateEngagementPostStatus';
  ok: Scalars['Boolean']['output'];
};

export type UpdateEngagementPostStatusInput = {
  id: Scalars['Int']['input'];
  status: EngagementCampaignPostStatus;
};

export type UpdateEngagementPostV2 = {
  __typename?: 'UpdateEngagementPostV2';
  ok: Scalars['Boolean']['output'];
};

export type UpdateEngagementSocialAccountProposalList = {
  __typename?: 'UpdateEngagementSocialAccountProposalList';
  ok: Scalars['Boolean']['output'];
};

export type UpdateEngagementSocialAccountProposalListInput = {
  campaignId: Scalars['Int']['input'];
  influencerIds: Array<Scalars['Int']['input']>;
  socialAccountIds: Array<Scalars['Int']['input']>;
  socialAccountMedia: SocialAccountType;
};

export type UpdateEngagementSocialAccountsBudget = {
  __typename?: 'UpdateEngagementSocialAccountsBudget';
  ok: Scalars['Boolean']['output'];
};

export type UpdateEngagementSocialAccountsBudgetInput = {
  campaignId: Scalars['Int']['input'];
  socialAccounts: Array<EngagementSocialAcccountBudgetInput>;
};

export type UpdateEngagementStatus = {
  __typename?: 'UpdateEngagementStatus';
  ok: Scalars['Boolean']['output'];
};

export type UpdateEngagementStatusInput = {
  id: Scalars['Int']['input'];
  status: EngagementCampaignStatus;
};

export type UpdateFanInput = {
  address1?: InputMaybe<Scalars['String']['input']>;
  address2?: InputMaybe<Scalars['String']['input']>;
  birthday?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  contactNumber?: InputMaybe<Scalars['String']['input']>;
  countryId?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName: Scalars['String']['input'];
  gender?: InputMaybe<FanGender>;
  id: Scalars['Long']['input'];
  lastName: Scalars['String']['input'];
  postalCode?: InputMaybe<Scalars['String']['input']>;
  province?: InputMaybe<Scalars['String']['input']>;
  tags: Array<Scalars['String']['input']>;
};

export type UpdateFanPayload = {
  __typename?: 'UpdateFanPayload';
  ok: Scalars['Boolean']['output'];
};

export type UpdateFormInput = {
  description: Scalars['String']['input'];
  id: Scalars['Long']['input'];
  questions: Array<UpdateQuestionInput>;
  thankDescription: Scalars['String']['input'];
  thankTitle: Scalars['String']['input'];
  title: Scalars['String']['input'];
};

export type UpdateFormPayload = {
  __typename?: 'UpdateFormPayload';
  ok: Scalars['Boolean']['output'];
};

export type UpdateFormStatusInput = {
  id: Scalars['Long']['input'];
  status: FormStatus;
};

export type UpdateFormStatusPayload = {
  __typename?: 'UpdateFormStatusPayload';
  ok: Scalars['Boolean']['output'];
};

export type UpdateInfluencerBioInput = {
  isFinish: Scalars['Boolean']['input'];
  isPublic: Scalars['Boolean']['input'];
  linkName: Scalars['String']['input'];
  profile: BioProfileInput;
  sections: Array<BioSectionsInputV2>;
  theme?: InputMaybe<BioTheme>;
  themeV2?: InputMaybe<BioThemeV2>;
};

export type UpdateInfluencerBioPayload = {
  __typename?: 'UpdateInfluencerBioPayload';
  ok: Scalars['Boolean']['output'];
};

export type UpdateInfluencerInputV2 = {
  addressInformation?: InputMaybe<AddressInput>;
  campaignDisplay: Scalars['Boolean']['input'];
  categoryIds: Array<Scalars['Int']['input']>;
  /** allow only scraping influencer */
  countryId: Scalars['String']['input'];
  dateOfBirth?: InputMaybe<Scalars['Date']['input']>;
  defaultEngagementSelectionReason?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  facebookAccount?: InputMaybe<InfluencerSocialNetworkAccountInput>;
  facebookPages?: InputMaybe<Array<InfluencerSocialNetworkAccountInput>>;
  gender: Genders;
  id: Scalars['Int']['input'];
  instagramAccounts?: InputMaybe<Array<InfluencerSocialNetworkAccountInput>>;
  internalMemo?: InputMaybe<Scalars['String']['input']>;
  introduce?: InputMaybe<Scalars['String']['input']>;
  isBrandAccount?: InputMaybe<Scalars['Boolean']['input']>;
  isProAccount?: InputMaybe<Scalars['Boolean']['input']>;
  managerIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  name: Scalars['String']['input'];
  notificationSetting: Scalars['Boolean']['input'];
  partnerIds: Array<Scalars['Int']['input']>;
  paymentInformation?: InputMaybe<PaymentInformationInput>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  rateCards?: InputMaybe<InfluencerRateCardsInput>;
  regionId?: InputMaybe<Scalars['Int']['input']>;
  tags: Array<Scalars['String']['input']>;
  talentAgencyIds: Array<Scalars['Int']['input']>;
  threadsAccounts?: InputMaybe<Array<InfluencerSocialNetworkAccountInput>>;
  tiktokAccounts?: InputMaybe<Array<InfluencerSocialNetworkAccountInput>>;
  twitterAccounts?: InputMaybe<Array<InfluencerSocialNetworkAccountInput>>;
  youtubeAccounts?: InputMaybe<Array<InfluencerSocialNetworkAccountInput>>;
};

export type UpdateInfluencerProfile = {
  __typename?: 'UpdateInfluencerProfile';
  ok: Scalars['Boolean']['output'];
};

export type UpdateInfluencerProfileInput = {
  categoryIds: Array<Scalars['Int']['input']>;
  dateOfBirth: Scalars['Date']['input'];
  email: Scalars['String']['input'];
  gender: Genders;
  id: Scalars['Int']['input'];
  introduce?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  notificationSetting: Scalars['Boolean']['input'];
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  regionId: Scalars['Int']['input'];
};

export type UpdateInfluencerRateCardInput = {
  price: Scalars['Float']['input'];
  socialAccountType: SocialMediaTypeForRateCard;
};

export type UpdateInfluencerRateCardPayload = {
  __typename?: 'UpdateInfluencerRateCardPayload';
  ok: Scalars['Boolean']['output'];
};

export type UpdateInfluencerRateCardsByStaff = {
  __typename?: 'UpdateInfluencerRateCardsByStaff';
  ok: Scalars['Boolean']['output'];
};

export type UpdateInfluencerRateCardsByStaffInput = {
  influencerId: Scalars['Int']['input'];
  rateCards: InfluencerRateCardsInput;
};

export type UpdateInfluencerRateCardsInput = {
  facebook?: InputMaybe<Scalars['Float']['input']>;
  instagram?: InputMaybe<Scalars['Float']['input']>;
  instagramStory?: InputMaybe<Scalars['Float']['input']>;
  tiktok?: InputMaybe<Scalars['Float']['input']>;
  twitter?: InputMaybe<Scalars['Float']['input']>;
  youtube?: InputMaybe<Scalars['Float']['input']>;
};

export type UpdateInfluencerRateCardsPayload = {
  __typename?: 'UpdateInfluencerRateCardsPayload';
  ok: Scalars['Boolean']['output'];
};

export type UpdateInfluencerTagsByStaff = {
  __typename?: 'UpdateInfluencerTagsByStaff';
  ok: Scalars['Boolean']['output'];
};

export type UpdateInfluencerTagsByStaffInput = {
  influencerId: Scalars['Int']['input'];
  tags: Array<Scalars['String']['input']>;
};

export type UpdateInfluencerV2 = {
  __typename?: 'UpdateInfluencerV2';
  ok: Scalars['Boolean']['output'];
};

export type UpdateInternalMemo = {
  __typename?: 'UpdateInternalMemo';
  ok: Scalars['Boolean']['output'];
};

export type UpdateInternalMemoInput = {
  id: Scalars['Int']['input'];
  internalMemo: Scalars['String']['input'];
};

export type UpdateLineBroadcastMessageInput = {
  deliveryTiming: DeliveryTimingItemInput;
  lineBroadcastMessageId: Scalars['String']['input'];
  lineContent: Array<LineContentItemInput>;
  recipient: RecipientItemInput;
  title: Scalars['String']['input'];
};

export type UpdateLineBroadcastMessagePayload = {
  __typename?: 'UpdateLineBroadcastMessagePayload';
  ok: Scalars['Boolean']['output'];
};

export type UpdateLinkBioThemeColorInput = {
  bgColor?: InputMaybe<Scalars['String']['input']>;
  button?: InputMaybe<BioButtonStyle>;
  buttonBgColor?: InputMaybe<Scalars['String']['input']>;
  buttonFontColor?: InputMaybe<Scalars['String']['input']>;
  font?: InputMaybe<BioFontStyle>;
  fontColor?: InputMaybe<Scalars['String']['input']>;
  media?: InputMaybe<Scalars['String']['input']>;
  theme?: InputMaybe<BioTheme>;
};

export type UpdateLinkBioThemeColorPayload = {
  __typename?: 'UpdateLinkBioThemeColorPayload';
  ok: Scalars['Boolean']['output'];
};

export type UpdateMarketplace = {
  __typename?: 'UpdateMarketplace';
  ok: Scalars['Boolean']['output'];
};

export type UpdateMarketplaceAffiliateCommissionFees = {
  __typename?: 'UpdateMarketplaceAffiliateCommissionFees';
  ok: Scalars['Boolean']['output'];
};

export type UpdateMarketplaceAffiliateCommissionFeesInput = {
  influencers: Array<MarketplaceAffiliateCommissionFeeInput>;
  marketplaceId: Scalars['Int']['input'];
};

export type UpdateMarketplaceAffiliateCommissionRates = {
  __typename?: 'UpdateMarketplaceAffiliateCommissionRates';
  ok: Scalars['Boolean']['output'];
};

export type UpdateMarketplaceAffiliateCommissionRatesInput = {
  influencers: Array<MarketplaceAffiliateCommissionRateInput>;
  marketplaceId: Scalars['Int']['input'];
};

export type UpdateMarketplaceCouponList = {
  __typename?: 'UpdateMarketplaceCouponList';
  ok: Scalars['Boolean']['output'];
};

export type UpdateMarketplaceCouponListInput = {
  couponUploadFileUrl: Scalars['String']['input'];
  id: Scalars['Int']['input'];
};

export type UpdateMarketplaceInput = {
  advertiserId: Scalars['Int']['input'];
  agencyMarginRate?: InputMaybe<Scalars['Float']['input']>;
  allowNewInfluencer: Scalars['Boolean']['input'];
  brandName?: InputMaybe<Scalars['String']['input']>;
  budget: Scalars['Float']['input'];
  campaignCategoryId: Scalars['Int']['input'];
  campaignType: MarketplaceCampaignDetailType;
  campaignUrl?: InputMaybe<Scalars['String']['input']>;
  categoryIds: Array<Scalars['Int']['input']>;
  clickUrl?: InputMaybe<Scalars['String']['input']>;
  /** for TUNE Tracking */
  costPerAction?: InputMaybe<Scalars['Float']['input']>;
  /** for TUNE Tracking */
  costPerClick?: InputMaybe<Scalars['Float']['input']>;
  costPerComment?: InputMaybe<Scalars['Float']['input']>;
  costPerFollower?: InputMaybe<Scalars['Float']['input']>;
  costPerLike?: InputMaybe<Scalars['Float']['input']>;
  /** for referral code Tracking */
  costPerOrder?: InputMaybe<Scalars['Float']['input']>;
  costPerPost?: InputMaybe<Scalars['Float']['input']>;
  /** for referral code Tracking */
  costPerSaleReferral?: InputMaybe<Scalars['Float']['input']>;
  /** for TUNE Tracking */
  costPerSaleTune?: InputMaybe<Scalars['Float']['input']>;
  costPerShare?: InputMaybe<Scalars['Float']['input']>;
  costPerView?: InputMaybe<Scalars['Float']['input']>;
  countryId: Scalars['String']['input'];
  couponUploadFiles?: InputMaybe<Array<Scalars['String']['input']>>;
  endDate?: InputMaybe<Scalars['Date']['input']>;
  genders: Array<Genders>;
  hashtags: Array<Scalars['String']['input']>;
  hubspotDealIds?: InputMaybe<Array<Scalars['String']['input']>>;
  id: Scalars['Int']['input'];
  influencerManagementPicIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  isAutoInfluencerApproval?: InputMaybe<Scalars['Boolean']['input']>;
  isGiftingCampaign?: InputMaybe<Scalars['Boolean']['input']>;
  landingPageUrl?: InputMaybe<Scalars['String']['input']>;
  marginRate: Scalars['Float']['input'];
  materialUrl?: InputMaybe<Scalars['String']['input']>;
  materials?: InputMaybe<Array<Scalars['String']['input']>>;
  maxAge: Scalars['Int']['input'];
  maxFollowers: Scalars['Int']['input'];
  maximumRevenuePerInfluencer?: InputMaybe<Scalars['Float']['input']>;
  minAge: Scalars['Int']['input'];
  minFollowers: Scalars['Int']['input'];
  mkpServiceCampaignId?: InputMaybe<Scalars['Int']['input']>;
  preLaunchDate?: InputMaybe<Scalars['Date']['input']>;
  productThumbNail?: InputMaybe<Scalars['String']['input']>;
  regionIds: Array<Scalars['Int']['input']>;
  requireDraftPost?: InputMaybe<Scalars['Boolean']['input']>;
  requirement: Scalars['String']['input'];
  salesPicIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  sampleUrl?: InputMaybe<Scalars['String']['input']>;
  serviceInformation: Scalars['String']['input'];
  socialMedia: CampaignSocialMediaType;
  startDate: Scalars['Date']['input'];
  thumbnails: Array<Scalars['String']['input']>;
  title: Scalars['String']['input'];
  trackingOptions?: InputMaybe<Array<CampaignTrackingOption>>;
};

export type UpdateMarketplacePostStatusInputV2 = {
  influencerIds: Array<Scalars['Int']['input']>;
  /** Campaign Id */
  marketplaceId: Scalars['Int']['input'];
  postIds: Array<InputMaybe<Scalars['Int']['input']>>;
  status: MarketplaceCampaignPostStatus;
};

export type UpdateMarketplacePostStatusV2 = {
  __typename?: 'UpdateMarketplacePostStatusV2';
  ok: Scalars['Boolean']['output'];
};

export type UpdateMarketplacePostTrackingUrl = {
  __typename?: 'UpdateMarketplacePostTrackingUrl';
  ok: Scalars['Boolean']['output'];
};

export type UpdateMarketplacePostTrackingUrlInput = {
  campaignId: Scalars['Int']['input'];
  postTrackingUrl: Scalars['String']['input'];
};

export type UpdateOptionInput = {
  id?: InputMaybe<Scalars['Long']['input']>;
  optionTitle: Scalars['String']['input'];
  order: Scalars['Int']['input'];
};

export type UpdatePackage = {
  __typename?: 'UpdatePackage';
  ok: Scalars['Boolean']['output'];
};

export type UpdatePackageInput = {
  companyAndRolePairs: Array<CompanyAndRolePair>;
  countryId: Scalars['String']['input'];
  id: Scalars['Int']['input'];
  name: Scalars['String']['input'];
};

export type UpdatePartnerAgency = {
  __typename?: 'UpdatePartnerAgency';
  ok: Scalars['Boolean']['output'];
};

export type UpdatePartnerAgencyInput = {
  advertiserIds: Array<Scalars['Int']['input']>;
  agencyIds: Array<Scalars['Int']['input']>;
  companyUrl?: InputMaybe<Scalars['String']['input']>;
  countryId: Scalars['String']['input'];
  id: Scalars['Int']['input'];
  influencerCostPermission: Scalars['Boolean']['input'];
  netsuiteId?: InputMaybe<Scalars['String']['input']>;
  partnerAgencyName: Scalars['String']['input'];
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  privateInfluencersIds: Array<Scalars['Int']['input']>;
};

export type UpdatePartnerUser = {
  __typename?: 'UpdatePartnerUser';
  ok: Scalars['Boolean']['output'];
};

export type UpdatePartnerUserInput = {
  /** Partner User Country */
  countryId: Scalars['String']['input'];
  /** Partner User Email */
  email: Scalars['String']['input'];
  /** Partner User Id */
  id: Scalars['Int']['input'];
  /** Partner User Name */
  name: Scalars['String']['input'];
  /** Partner User Password */
  password?: InputMaybe<Scalars['String']['input']>;
  permissions?: InputMaybe<CustomPermissionsInput>;
};

export type UpdatePasswordByAdvertiserUser = {
  __typename?: 'UpdatePasswordByAdvertiserUser';
  ok: Scalars['Boolean']['output'];
};

export type UpdatePasswordByAdvertiserUserInput = {
  newPassword: Scalars['String']['input'];
  originalPassword: Scalars['String']['input'];
};

export type UpdatePaymentInformation = {
  __typename?: 'UpdatePaymentInformation';
  ok: Scalars['Boolean']['output'];
};

export type UpdatePaymentInformationInput = {
  accountName: Scalars['String']['input'];
  accountNumber: Scalars['String']['input'];
  address?: InputMaybe<Scalars['String']['input']>;
  bankId: Scalars['Int']['input'];
  /** Branch ID */
  branchId?: InputMaybe<Scalars['Int']['input']>;
  /** Branch Name when user manually inserts it */
  branchName?: InputMaybe<Scalars['String']['input']>;
  cityId?: InputMaybe<Scalars['Int']['input']>;
  email: Scalars['String']['input'];
  fullName?: InputMaybe<Scalars['String']['input']>;
  /** IBAN */
  iban?: InputMaybe<Scalars['String']['input']>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  subject?: InputMaybe<Scalars['String']['input']>;
  /** SWIFT Code */
  swiftCode?: InputMaybe<Scalars['String']['input']>;
};

export type UpdatePaymentStatus = {
  __typename?: 'UpdatePaymentStatus';
  ok: Scalars['Boolean']['output'];
};

export type UpdatePaymentStatusInput = {
  id: Scalars['Int']['input'];
  status: PaymentRequestStatus;
};

export type UpdatePersoanlUserDetailPayload = {
  __typename?: 'UpdatePersoanlUserDetailPayload';
  ok: Scalars['Boolean']['output'];
};

export type UpdatePersonalUserDetailInput = {
  crmNotificationSetting?: InputMaybe<Scalars['Boolean']['input']>;
  displayLanguage: DisplayLanguage;
  eggNotificationSetting?: InputMaybe<Scalars['Boolean']['input']>;
  email: Scalars['String']['input'];
  mkpNotificationSetting?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  notificationSetting?: InputMaybe<Scalars['Boolean']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
};

export type UpdatePlainAgency = {
  __typename?: 'UpdatePlainAgency';
  ok: Scalars['Boolean']['output'];
};

export type UpdatePlainAgencyInput = {
  advertiserIds: Array<Scalars['Int']['input']>;
  agencyName: Scalars['String']['input'];
  companyUrl?: InputMaybe<Scalars['String']['input']>;
  countryId: Scalars['String']['input'];
  hubspotId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Int']['input'];
  internalNetsuiteId?: InputMaybe<Scalars['String']['input']>;
  logo?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateQuestionInput = {
  id?: InputMaybe<Scalars['Long']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  isRequired: Scalars['Boolean']['input'];
  options: Array<UpdateOptionInput>;
  order: Scalars['Int']['input'];
  questionType: QuestionType;
  title: Scalars['String']['input'];
};

export type UpdateSocialAccountsInput = {
  influencerId: Scalars['Int']['input'];
};

export type UpdateSocialAccountsPayload = {
  __typename?: 'UpdateSocialAccountsPayload';
  ok: Scalars['Boolean']['output'];
};

export type UpdateSocialUsername = {
  __typename?: 'UpdateSocialUsername';
  ok: Scalars['Boolean']['output'];
};

export type UpdateSocialUsernameInput = {
  influencerId: Scalars['Int']['input'];
  socialAccountId: Scalars['Int']['input'];
  socialMedia: SocialAccountType;
  username: Scalars['String']['input'];
};

export type UpdateStaff = {
  __typename?: 'UpdateStaff';
  ok: Scalars['Boolean']['output'];
};

export type UpdateStaffInput = {
  advertiserIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  autoLinkToAdvertiser: Scalars['Boolean']['input'];
  countryId?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Int']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  permissions?: InputMaybe<CustomPermissionsInput>;
};

export type UpdateSubscriptionPlan = {
  __typename?: 'UpdateSubscriptionPlan';
  ok: Scalars['Boolean']['output'];
};

export type UpdateSubscriptionPlanInput = {
  planType: PaymentPlanType;
  priceId: Scalars['Int']['input'];
};

export type UpdateTalentAgency = {
  __typename?: 'UpdateTalentAgency';
  ok: Scalars['Boolean']['output'];
};

export type UpdateTalentAgencyInput = {
  advertiserIds: Array<Scalars['Int']['input']>;
  agencyIds: Array<Scalars['Int']['input']>;
  companyUrl?: InputMaybe<Scalars['String']['input']>;
  countryId: Scalars['String']['input'];
  id: Scalars['Int']['input'];
  netsuiteId?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  privateInfluencersIds: Array<Scalars['Int']['input']>;
  talentAgencyName: Scalars['String']['input'];
};

export type UpdateTalentAgencyUser = {
  __typename?: 'UpdateTalentAgencyUser';
  ok: Scalars['Boolean']['output'];
};

export type UpdateTalentAgencyUserInput = {
  /** Talent Agency User Country */
  countryId: Scalars['String']['input'];
  /** Talent Agency User Email */
  email: Scalars['String']['input'];
  /** Talent Agency User Id */
  id: Scalars['Int']['input'];
  /** Talent Agency User Name */
  name: Scalars['String']['input'];
  /** Talent Agency User Password */
  password?: InputMaybe<Scalars['String']['input']>;
  permissions?: InputMaybe<CustomPermissionsInput>;
};

export type UpdateTalentInfluencerInput = {
  categoryIds: Array<Scalars['Int']['input']>;
  dateOfBirth: Scalars['String']['input'];
  defaultEngagementSelectionReason?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  gender: Genders;
  influencerId: Scalars['Int']['input'];
  internalMemo?: InputMaybe<Scalars['String']['input']>;
  introduce?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  phoneNumber: Scalars['String']['input'];
  rateCards?: InputMaybe<RateCardsInput>;
  regionId: Scalars['Int']['input'];
  selectedSocialAccounts: SelectedSocialAccounts;
  tags: Array<Scalars['String']['input']>;
};

export type UpdateTalentInfluencerPayload = {
  __typename?: 'UpdateTalentInfluencerPayload';
  ok: Scalars['Boolean']['output'];
};

export type UpdateTtcmPost = {
  __typename?: 'UpdateTtcmPost';
  ok: Scalars['Boolean']['output'];
};

export type UpdateTtcmPostInput = {
  content: Scalars['String']['input'];
  /** Post id */
  id: Scalars['Int']['input'];
  thumbnailUrl: Scalars['String']['input'];
};

export type UpdateUserDetailInput = {
  crmNotificationSetting?: InputMaybe<Scalars['Boolean']['input']>;
  eggNotificationSetting?: InputMaybe<Scalars['Boolean']['input']>;
  email: Scalars['String']['input'];
  mkpNotificationSetting?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  notificationSetting?: InputMaybe<Scalars['Boolean']['input']>;
  userId: Scalars['Int']['input'];
};

export type UpdateUserDetailPayload = {
  __typename?: 'UpdateUserDetailPayload';
  ok: Scalars['Boolean']['output'];
};

export type UpdateYoutubeMCNCredentialsInput = {
  callbackUrl: Scalars['String']['input'];
  response: Scalars['String']['input'];
};

export type UpdateYoutubeMCNCredentialsPayload = {
  __typename?: 'UpdateYoutubeMCNCredentialsPayload';
  ok: Scalars['Boolean']['output'];
};

export type UploadEngagementReportFile = {
  __typename?: 'UploadEngagementReportFile';
  ok: Scalars['Boolean']['output'];
};

export type UploadEngagementReportFileInput = {
  /** Engagement Campaign Id */
  id: Scalars['Int']['input'];
  /** Upload file url */
  uploadFileUrl: Scalars['String']['input'];
};

export type UploadEngagementReportFromInsightCheck = {
  __typename?: 'UploadEngagementReportFromInsightCheck';
  ok: Scalars['Boolean']['output'];
};

export type UploadEngagementReportFromInsightCheckInput = {
  audienceRetention?: InputMaybe<Scalars['Float']['input']>;
  averageViewDurationHours?: InputMaybe<Scalars['Float']['input']>;
  averageViewDurationMinutes?: InputMaybe<Scalars['Float']['input']>;
  back?: InputMaybe<Scalars['Int']['input']>;
  comments?: InputMaybe<Scalars['Int']['input']>;
  exited?: InputMaybe<Scalars['Int']['input']>;
  followerReach?: InputMaybe<Scalars['Int']['input']>;
  forward?: InputMaybe<Scalars['Int']['input']>;
  impressions?: InputMaybe<Scalars['Int']['input']>;
  impressionsClickThroughRate?: InputMaybe<Scalars['Float']['input']>;
  impressionsFromDiscovery?: InputMaybe<Scalars['Int']['input']>;
  impressionsFromHashtag?: InputMaybe<Scalars['Int']['input']>;
  impressionsFromHome?: InputMaybe<Scalars['Int']['input']>;
  impressionsFromOther?: InputMaybe<Scalars['Int']['input']>;
  impressionsFromProfile?: InputMaybe<Scalars['Int']['input']>;
  interaction?: InputMaybe<Scalars['Int']['input']>;
  likes?: InputMaybe<Scalars['Int']['input']>;
  linkClicks?: InputMaybe<Scalars['Int']['input']>;
  navigation?: InputMaybe<Scalars['Int']['input']>;
  newFollowers?: InputMaybe<Scalars['Int']['input']>;
  nextStory?: InputMaybe<Scalars['Int']['input']>;
  nonFollowerReach?: InputMaybe<Scalars['Int']['input']>;
  /** Engagement Campaign Post Id */
  postId: Scalars['Int']['input'];
  profileActivity?: InputMaybe<Scalars['Int']['input']>;
  reaches?: InputMaybe<Scalars['Int']['input']>;
  saves?: InputMaybe<Scalars['Int']['input']>;
  shares?: InputMaybe<Scalars['Int']['input']>;
  stickerTaps?: InputMaybe<Scalars['Int']['input']>;
  views?: InputMaybe<Scalars['Int']['input']>;
};

export type UserAudioMessageEvent = ChatEvent & {
  __typename?: 'UserAudioMessageEvent';
  contentUrl: Scalars['String']['output'];
  duration: Scalars['Int']['output'];
  id: Scalars['String']['output'];
  timestamp: Scalars['String']['output'];
};

export type UserDefaultPermissions = {
  __typename?: 'UserDefaultPermissions';
  permissions?: Maybe<CustomPermissions>;
};

export type UserDetail = {
  __typename?: 'UserDetail';
  advertisers: Array<AdvertiserName>;
  /** Agency Id */
  agencyId?: Maybe<Scalars['Int']['output']>;
  autoLinkToAdvertiser?: Maybe<Scalars['Boolean']['output']>;
  country: CountryName;
  email: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  notificationSetting?: Maybe<Scalars['Boolean']['output']>;
  /** Partner Id */
  partnerId?: Maybe<Scalars['Int']['output']>;
  permissions?: Maybe<CustomPermissions>;
  role: UserRoles;
  /** Talent Agency Id */
  talentAgencyId?: Maybe<Scalars['Int']['output']>;
};

export type UserDetailInput = {
  userId: Scalars['Int']['input'];
};

export type UserDetailPayload = {
  __typename?: 'UserDetailPayload';
  crmNotificationSetting?: Maybe<Scalars['Boolean']['output']>;
  eggNotificationSetting?: Maybe<Scalars['Boolean']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  mkpNotificationSetting?: Maybe<Scalars['Boolean']['output']>;
  name: Scalars['String']['output'];
  notificationSetting?: Maybe<Scalars['Boolean']['output']>;
};

export type UserImageMessageEvent = ChatEvent & {
  __typename?: 'UserImageMessageEvent';
  id: Scalars['String']['output'];
  imageUrl: Scalars['String']['output'];
  timestamp: Scalars['String']['output'];
};

export type UserPostbackEvent = ChatEvent & {
  __typename?: 'UserPostbackEvent';
  displayText: Scalars['String']['output'];
  id: Scalars['String']['output'];
  timestamp: Scalars['String']['output'];
};

/** An enumeration. */
export enum UserRoles {
  ADMINISTRATOR = 'ADMINISTRATOR',
  ADVERTISER = 'ADVERTISER',
  AGENCY = 'AGENCY',
  CREATOR_STAFF = 'CREATOR_STAFF',
  EXTERNAL = 'EXTERNAL',
  INFLUENCER = 'INFLUENCER',
  PARTNER = 'PARTNER',
  STAFF = 'STAFF',
  TALENT_AGENCY = 'TALENT_AGENCY',
}

export type UserTextMessageEvent = ChatEvent & {
  __typename?: 'UserTextMessageEvent';
  id: Scalars['String']['output'];
  text: Scalars['String']['output'];
  timestamp: Scalars['String']['output'];
};

export type UserVideoMessageEvent = ChatEvent & {
  __typename?: 'UserVideoMessageEvent';
  contentUrl: Scalars['String']['output'];
  duration: Scalars['Int']['output'];
  id: Scalars['String']['output'];
  timestamp: Scalars['String']['output'];
};

export type VerifyInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  token: Scalars['String']['input'];
};

export type VerifyLineIDTokenInput = {
  idToken: Scalars['String']['input'];
};

export type VerifyLineIDTokenPayload = {
  __typename?: 'VerifyLineIDTokenPayload';
  ok: Scalars['Boolean']['output'];
};

export type VerifyPayload = {
  __typename?: 'VerifyPayload';
  /** Users's country's id */
  countryId?: Maybe<Scalars['String']['output']>;
  creatorType?: Maybe<InfluencerType>;
  /** Users's country's currency id */
  currencyId?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  hash?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  ok: Scalars['Boolean']['output'];
  role?: Maybe<UserRoles>;
  userId?: Maybe<Scalars['Int']['output']>;
};

export type VideoInput = {
  fileName: Scalars['String']['input'];
  mimeType: Scalars['String']['input'];
  previewUrl: Scalars['String']['input'];
  url: Scalars['String']['input'];
};

export type VideoPayload = {
  __typename?: 'VideoPayload';
  fileName: Scalars['String']['output'];
  mimeType: Scalars['String']['output'];
  previewUrl: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

/** An enumeration. */
export enum WarningReason {
  AD_FRAUD = 'AD_FRAUD',
  API_ERROR = 'API_ERROR',
  COUPON_MISSING = 'COUPON_MISSING',
  DELETED_POST = 'DELETED_POST',
  EXPIRED_TOKEN = 'EXPIRED_TOKEN',
  INVALID_CONTENT = 'INVALID_CONTENT',
  INVALID_TOKEN = 'INVALID_TOKEN',
  SCRAPING_ERROR = 'SCRAPING_ERROR',
}

export type YouTubeBrandAccount = {
  __typename?: 'YouTubeBrandAccount';
  averageViews: Scalars['Float']['output'];
  /** youtube mentioned brand account id */
  id: Scalars['Int']['output'];
  mentionPostsCount: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  postIds: Array<Scalars['Int']['output']>;
};

export type YouTubeBrandAccounts = {
  __typename?: 'YouTubeBrandAccounts';
  averageEngagementRate?: Maybe<Scalars['Float']['output']>;
  averageViews?: Maybe<Scalars['Float']['output']>;
  brandAccounts?: Maybe<Array<YouTubeBrandAccount>>;
};

export type YouTubeInfluencer = {
  __typename?: 'YouTubeInfluencer';
  age?: Maybe<Scalars['Int']['output']>;
  analyticsWarning: Scalars['Boolean']['output'];
  avatar?: Maybe<Scalars['String']['output']>;
  averageLikes?: Maybe<Scalars['Float']['output']>;
  averageViews?: Maybe<Scalars['Float']['output']>;
  categories: Array<CategoryName>;
  country: CountryName;
  engagementPostedCount?: Maybe<Scalars['Int']['output']>;
  engagementProposedCount?: Maybe<Scalars['Int']['output']>;
  gender: Genders;
  /** Influencer Id */
  id: Scalars['Int']['output'];
  influencerEngagement?: Maybe<Scalars['Int']['output']>;
  influencerEngagementRate?: Maybe<Scalars['Float']['output']>;
  influencerFollowers?: Maybe<Scalars['Int']['output']>;
  isBrandAccount: Scalars['Boolean']['output'];
  marketplaceJoinedCount?: Maybe<Scalars['Int']['output']>;
  marketplacePostedCount?: Maybe<Scalars['Int']['output']>;
  name: Scalars['String']['output'];
  popularPosts?: Maybe<Array<SocialAccountPopularPost>>;
  socialAccountEngagement?: Maybe<Scalars['Int']['output']>;
  socialAccountEngagementRate?: Maybe<Scalars['Float']['output']>;
  socialAccountFollowers?: Maybe<Scalars['Int']['output']>;
  /** Social Account System Id */
  socialAccountId: Scalars['Int']['output'];
  /** Social Account Status */
  socialAccountStatus: SocialAccountStatus;
};

export type YoutubeAccountForProfileV2 = {
  __typename?: 'YoutubeAccountForProfileV2';
  analyticsEnabled: Scalars['Boolean']['output'];
  avatar?: Maybe<Scalars['String']['output']>;
  followersCount?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
  profile?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type YoutubeAnalyticsAccountCard = {
  __typename?: 'YoutubeAnalyticsAccountCard';
  accountId: Scalars['Int']['output'];
  avatarUrl: Scalars['String']['output'];
  channelName: Scalars['String']['output'];
  elementId: Scalars['Int']['output'];
  profileUrl: Scalars['String']['output'];
};

export type YoutubeAnalyticsAccountOverview = {
  __typename?: 'YoutubeAnalyticsAccountOverview';
  accountId: Scalars['Int']['output'];
  graph: YoutubeCompareOverviewHistory;
  summary: YoutubeCompareOverviewSummary;
};

export type YoutubeAnalyticsAccountPosts = {
  __typename?: 'YoutubeAnalyticsAccountPosts';
  accountId: Scalars['Int']['output'];
  postHabit: Array<YoutubeAnalyticsPostsPostingHabit>;
  posts: Array<YoutubeCompareAccountPost>;
};

export type YoutubeAnalyticsAccountPostsInDate = {
  __typename?: 'YoutubeAnalyticsAccountPostsInDate';
  accountId: Scalars['Int']['output'];
  posts: Array<YoutubeAnalyticsPostPayload>;
};

export type YoutubeAnalyticsAccountTagRanking = {
  __typename?: 'YoutubeAnalyticsAccountTagRanking';
  accountId: Scalars['Int']['output'];
  tagsRanking: Array<YoutubeTagRanking>;
};

export type YoutubeAnalyticsAudienceAgeBreakdownAgeGroupPayload = {
  __typename?: 'YoutubeAnalyticsAudienceAgeBreakdownAgeGroupPayload';
  end: Scalars['Int']['output'];
  start: Scalars['Int']['output'];
};

export type YoutubeAnalyticsAudienceAgeBreakdownPayload = {
  __typename?: 'YoutubeAnalyticsAudienceAgeBreakdownPayload';
  ageGroup: Array<YoutubeAnalyticsAudienceAgeBreakdownAgeGroupPayload>;
  femaleRate: Array<Scalars['Float']['output']>;
  maleRate: Array<Scalars['Float']['output']>;
  otherRate: Array<Scalars['Float']['output']>;
};

export type YoutubeAnalyticsAudienceGenderBreakdownPayload = {
  __typename?: 'YoutubeAnalyticsAudienceGenderBreakdownPayload';
  femaleRate: Scalars['Float']['output'];
  maleRate: Scalars['Float']['output'];
  otherRate: Scalars['Float']['output'];
};

export type YoutubeAnalyticsAudiencePayload = {
  __typename?: 'YoutubeAnalyticsAudiencePayload';
  ageRate: YoutubeAnalyticsAudienceAgeBreakdownPayload;
  genderRate: YoutubeAnalyticsAudienceGenderBreakdownPayload;
  regionRate: Array<YoutubeAnalyticsAudienceRegionPayload>;
};

export type YoutubeAnalyticsAudienceRegionPayload = {
  __typename?: 'YoutubeAnalyticsAudienceRegionPayload';
  name: Scalars['String']['output'];
  rate: Scalars['Float']['output'];
};

export type YoutubeAnalyticsCategory = {
  __typename?: 'YoutubeAnalyticsCategory';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type YoutubeAnalyticsCommentsPayload = {
  __typename?: 'YoutubeAnalyticsCommentsPayload';
  comments: Array<YoutubeAnalyticsPostCommentItem>;
};

export type YoutubeAnalyticsCompareAccountCard = {
  __typename?: 'YoutubeAnalyticsCompareAccountCard';
  accountId: Scalars['Int']['output'];
  avatarUrl: Scalars['String']['output'];
  channelName: Scalars['String']['output'];
  elementId: Scalars['Int']['output'];
  profileUrl: Scalars['String']['output'];
};

export type YoutubeAnalyticsCompareAccountCards = {
  __typename?: 'YoutubeAnalyticsCompareAccountCards';
  compareAccounts: Array<YoutubeAnalyticsCompareAccountCard>;
  mainAccount?: Maybe<YoutubeAnalyticsAccountCard>;
};

export type YoutubeAnalyticsComparePostComment = {
  __typename?: 'YoutubeAnalyticsComparePostComment';
  commentId: Scalars['String']['output'];
  content: Scalars['String']['output'];
  posterName: Scalars['String']['output'];
};

export type YoutubeAnalyticsComparePostsSort = {
  field?: InputMaybe<YoutubeAnalyticsComparePostsSortOrder>;
  order?: InputMaybe<Order>;
};

export enum YoutubeAnalyticsComparePostsSortOrder {
  COMMENT = 'COMMENT',
  DISLIKE = 'DISLIKE',
  DURATION = 'DURATION',
  LIKE = 'LIKE',
  NEGATIVE_RATE = 'NEGATIVE_RATE',
  POSITIVE_RATE = 'POSITIVE_RATE',
  POST_DATE = 'POST_DATE',
  VIEWS = 'VIEWS',
}

export type YoutubeAnalyticsCompareRelatedPost = {
  __typename?: 'YoutubeAnalyticsCompareRelatedPost';
  channelName: Scalars['String']['output'];
  comments: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  postDate: Scalars['DateTime']['output'];
  videoCaption: Scalars['String']['output'];
  videoDuration: Scalars['Int']['output'];
  videoId: Scalars['String']['output'];
  videoThumbnail?: Maybe<Scalars['String']['output']>;
  videoTitle: Scalars['String']['output'];
  views: Scalars['Int']['output'];
};

export type YoutubeAnalyticsCompareRelatedPosts = {
  __typename?: 'YoutubeAnalyticsCompareRelatedPosts';
  posts: Array<YoutubeAnalyticsCompareRelatedPost>;
  tag: Scalars['String']['output'];
};

export type YoutubeAnalyticsCountriesCategoriesPayload = {
  __typename?: 'YoutubeAnalyticsCountriesCategoriesPayload';
  categories: Array<YoutubeAnalyticsCategory>;
  countries: Array<YoutubeAnalyticsCountry>;
  date?: Maybe<Scalars['String']['output']>;
};

export type YoutubeAnalyticsCountry = {
  __typename?: 'YoutubeAnalyticsCountry';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type YoutubeAnalyticsHistoryDataPoint = {
  __typename?: 'YoutubeAnalyticsHistoryDataPoint';
  count: Scalars['Int']['output'];
  date: Scalars['Date']['output'];
};

export type YoutubeAnalyticsOverviewHistoryItem = {
  __typename?: 'YoutubeAnalyticsOverviewHistoryItem';
  count: Scalars['Int']['output'];
  date: Scalars['Date']['output'];
};

export type YoutubeAnalyticsOverviewItem = {
  __typename?: 'YoutubeAnalyticsOverviewItem';
  growth: Scalars['Long']['output'];
  history: Array<YoutubeAnalyticsOverviewHistoryItem>;
  percentage: Scalars['Float']['output'];
  total: Scalars['Long']['output'];
};

export type YoutubeAnalyticsOverviewPayload = {
  __typename?: 'YoutubeAnalyticsOverviewPayload';
  comment: YoutubeAnalyticsOverviewItem;
  dislike: YoutubeAnalyticsOverviewItem;
  lastUpdated: Scalars['DateTime']['output'];
  like: YoutubeAnalyticsOverviewItem;
  post: YoutubeAnalyticsOverviewItem;
  subscriber: YoutubeAnalyticsOverviewItem;
  view: YoutubeAnalyticsOverviewItem;
};

export type YoutubeAnalyticsPostComment = {
  __typename?: 'YoutubeAnalyticsPostComment';
  commentId: Scalars['String']['output'];
  content: Scalars['String']['output'];
  posterName: Scalars['String']['output'];
};

export type YoutubeAnalyticsPostCommentItem = {
  __typename?: 'YoutubeAnalyticsPostCommentItem';
  commentId: Scalars['String']['output'];
  content: Scalars['String']['output'];
  created: Scalars['Date']['output'];
  postUrl: Scalars['String']['output'];
  username: Scalars['String']['output'];
};

export type YoutubeAnalyticsPostPayload = {
  __typename?: 'YoutubeAnalyticsPostPayload';
  averageViewDuration?: Maybe<Scalars['Long']['output']>;
  averageViewPercentage?: Maybe<Scalars['Float']['output']>;
  comments: Scalars['Long']['output'];
  dislikes: Scalars['Long']['output'];
  id: Scalars['Int']['output'];
  likes: Scalars['Long']['output'];
  postDate: Scalars['Date']['output'];
  subscribers: Scalars['Int']['output'];
  videoCaption: Scalars['String']['output'];
  videoComments: Array<YoutubeAnalyticsPostComment>;
  videoDuration: Scalars['Int']['output'];
  videoEmbed: Scalars['String']['output'];
  videoId: Scalars['String']['output'];
  videoTags: Array<Scalars['String']['output']>;
  videoThumbnail?: Maybe<Scalars['String']['output']>;
  videoTitle: Scalars['String']['output'];
  views: Scalars['Long']['output'];
};

export type YoutubeAnalyticsPostPayloads = {
  __typename?: 'YoutubeAnalyticsPostPayloads';
  avatar: Scalars['String']['output'];
  channelName: Scalars['String']['output'];
  posts: Array<YoutubeAnalyticsPostPayload>;
  subscribers: Scalars['Int']['output'];
};

export type YoutubeAnalyticsPostWithChannelDetailsPayload = {
  __typename?: 'YoutubeAnalyticsPostWithChannelDetailsPayload';
  avatar: Scalars['String']['output'];
  averageViewDuration?: Maybe<Scalars['Long']['output']>;
  averageViewPercentage?: Maybe<Scalars['Float']['output']>;
  channelName: Scalars['String']['output'];
  comments: Scalars['Long']['output'];
  dislikes: Scalars['Long']['output'];
  id: Scalars['Int']['output'];
  likes: Scalars['Long']['output'];
  postDate: Scalars['Date']['output'];
  subscribers: Scalars['Int']['output'];
  videoCaption: Scalars['String']['output'];
  videoComments: Array<YoutubeAnalyticsPostComment>;
  videoDuration: Scalars['Int']['output'];
  videoEmbed: Scalars['String']['output'];
  videoId: Scalars['String']['output'];
  videoTags: Array<Scalars['String']['output']>;
  videoThumbnail?: Maybe<Scalars['String']['output']>;
  videoTitle: Scalars['String']['output'];
  views: Scalars['Long']['output'];
};

export type YoutubeAnalyticsPostsAverageEngagement = {
  __typename?: 'YoutubeAnalyticsPostsAverageEngagement';
  averageComments: YoutubeStatisticsData;
  averageDislikes: YoutubeStatisticsData;
  averageLikes: YoutubeStatisticsData;
  averagePostsPerWeek?: Maybe<YoutubeStatisticsData>;
  averageViews: YoutubeStatisticsData;
};

/**
 *  YoutubeAnalyticsPostsPayload is the individual data
 *    for a list of YoutubeAnalyticsPost
 *  Some video information such as video embed, comments, descriptions are not included
 *  Some video analytics data such as revenue are not included
 *  Duration is in seconds
 */
export type YoutubeAnalyticsPostsPayload = {
  __typename?: 'YoutubeAnalyticsPostsPayload';
  averageViewDuration?: Maybe<Scalars['Long']['output']>;
  averageViewPercentage?: Maybe<Scalars['Float']['output']>;
  comments: Scalars['Long']['output'];
  dislikes: Scalars['Long']['output'];
  id: Scalars['Int']['output'];
  isSponsoredPost: Scalars['Boolean']['output'];
  likes: Scalars['Long']['output'];
  postDate: Scalars['DateTime']['output'];
  shares: Scalars['Long']['output'];
  videoDuration: Scalars['Int']['output'];
  videoId: Scalars['String']['output'];
  videoThumbnail?: Maybe<Scalars['String']['output']>;
  videoTitle: Scalars['String']['output'];
  views: Scalars['Long']['output'];
};

export type YoutubeAnalyticsPostsPayloads = {
  __typename?: 'YoutubeAnalyticsPostsPayloads';
  posts: Array<YoutubeAnalyticsPostsPayload>;
};

export type YoutubeAnalyticsPostsPostingHabit = {
  __typename?: 'YoutubeAnalyticsPostsPostingHabit';
  averageComments: Scalars['Float']['output'];
  averageLikes: Scalars['Float']['output'];
  engagementRate: Scalars['Float']['output'];
  hour: Scalars['Int']['output'];
  weekday: Scalars['Int']['output'];
};

export type YoutubeAnalyticsPostsSortInput = {
  field?: InputMaybe<YoutubeAnalyticsPostsSortOrder>;
  order?: InputMaybe<Order>;
};

export enum YoutubeAnalyticsPostsSortOrder {
  AVERAGE_VIEW_DURATION = 'AVERAGE_VIEW_DURATION',
  AVERAGE_VIEW_PERCENTAGE = 'AVERAGE_VIEW_PERCENTAGE',
  COMMENT = 'COMMENT',
  DISLIKE = 'DISLIKE',
  DURATION = 'DURATION',
  ENGAGEMENT = 'ENGAGEMENT',
  LIKE = 'LIKE',
  NEGATIVE_RATE = 'NEGATIVE_RATE',
  POSITIVE_RATE = 'POSITIVE_RATE',
  POST_DATE = 'POST_DATE',
  SHARE = 'SHARE',
  VIEWS = 'VIEWS',
}

export type YoutubeAnalyticsPostsStatisticsPayload = {
  __typename?: 'YoutubeAnalyticsPostsStatisticsPayload';
  averageEngagement: YoutubeAnalyticsPostsAverageEngagement;
  postHabit: Array<YoutubeAnalyticsPostsPostingHabit>;
};

export type YoutubeAnalyticsRelatedPostPayload = {
  __typename?: 'YoutubeAnalyticsRelatedPostPayload';
  comments: Scalars['Long']['output'];
  id: Scalars['Int']['output'];
  postDate: Scalars['DateTime']['output'];
  videoCaption: Scalars['String']['output'];
  videoDuration: Scalars['Int']['output'];
  videoEmbed: Scalars['String']['output'];
  videoId: Scalars['String']['output'];
  videoThumbnail?: Maybe<Scalars['String']['output']>;
  videoTitle: Scalars['String']['output'];
  views: Scalars['Long']['output'];
};

export type YoutubeAnalyticsRelatedPostsPayload = {
  __typename?: 'YoutubeAnalyticsRelatedPostsPayload';
  posts: Array<YoutubeAnalyticsRelatedPostPayload>;
};

export type YoutubeAnalyticsShortsPayload = {
  __typename?: 'YoutubeAnalyticsShortsPayload';
  averageViewDuration?: Maybe<Scalars['Long']['output']>;
  averageViewPercentage?: Maybe<Scalars['Float']['output']>;
  comments: Scalars['Long']['output'];
  dislikes: Scalars['Long']['output'];
  id: Scalars['Int']['output'];
  isSponsoredPost: Scalars['Boolean']['output'];
  likes: Scalars['Long']['output'];
  postDate: Scalars['DateTime']['output'];
  shares: Scalars['Long']['output'];
  videoDuration: Scalars['Int']['output'];
  videoId: Scalars['String']['output'];
  videoThumbnail?: Maybe<Scalars['String']['output']>;
  videoTitle: Scalars['String']['output'];
  views: Scalars['Long']['output'];
};

export type YoutubeAnalyticsShortsPayloads = {
  __typename?: 'YoutubeAnalyticsShortsPayloads';
  shorts: Array<YoutubeAnalyticsShortsPayload>;
};

export type YoutubeAnalyticsSponsoredPostsSortInput = {
  field?: InputMaybe<YoutubeAnalyticsSponsoredPostsSortOrder>;
  order?: InputMaybe<Order>;
};

export enum YoutubeAnalyticsSponsoredPostsSortOrder {
  COMMENT = 'COMMENT',
  DURATION = 'DURATION',
  LIKES = 'LIKES',
  POST_DATE = 'POST_DATE',
  SHARED = 'SHARED',
  VIEWS = 'VIEWS',
}

export type YoutubeAnalyticsTagRankingPayload = {
  __typename?: 'YoutubeAnalyticsTagRankingPayload';
  averageComment: Scalars['Float']['output'];
  averageDislike: Scalars['Float']['output'];
  averageEngagement: Scalars['Float']['output'];
  averageLike: Scalars['Float']['output'];
  averageShare: Scalars['Float']['output'];
  engagementRate: Scalars['Float']['output'];
  posts: Scalars['Int']['output'];
  tag: Scalars['String']['output'];
  views: Scalars['Long']['output'];
};

export type YoutubeAnalyticsTagRankingPayloads = {
  __typename?: 'YoutubeAnalyticsTagRankingPayloads';
  tags: Array<YoutubeAnalyticsTagRankingPayload>;
};

export type YoutubeAnalyticsTagRankingSortInput = {
  field?: InputMaybe<YoutubeAnalyticsTagRankingSortOrder>;
  order?: InputMaybe<Order>;
};

export enum YoutubeAnalyticsTagRankingSortOrder {
  AVERAGE_COMMENT = 'AVERAGE_COMMENT',
  AVERAGE_DISLIKE = 'AVERAGE_DISLIKE',
  AVERAGE_ENGAGEMENT = 'AVERAGE_ENGAGEMENT',
  AVERAGE_LIKE = 'AVERAGE_LIKE',
  AVERAGE_SHARE = 'AVERAGE_SHARE',
  ENGAGEMENT_RATE = 'ENGAGEMENT_RATE',
  POST = 'POST',
  VIEWS = 'VIEWS',
}

export type YoutubeAnalyticsTrendPayload = {
  __typename?: 'YoutubeAnalyticsTrendPayload';
  channel: Scalars['String']['output'];
  channelAvatar: Scalars['String']['output'];
  comments: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  influencerId?: Maybe<Scalars['Int']['output']>;
  likes: Scalars['Int']['output'];
  position: Scalars['Int']['output'];
  postDate: Scalars['DateTime']['output'];
  socialAccountId?: Maybe<Scalars['Int']['output']>;
  videoCaption: Scalars['String']['output'];
  videoDuration: Scalars['Int']['output'];
  videoId: Scalars['String']['output'];
  videoThumbnail?: Maybe<Scalars['String']['output']>;
  videoTitle: Scalars['String']['output'];
  views: Scalars['Int']['output'];
};

export type YoutubeAnalyticsTrendPostDetailsPayload = {
  __typename?: 'YoutubeAnalyticsTrendPostDetailsPayload';
  channel: Scalars['String']['output'];
  channelAvatar: Scalars['String']['output'];
  comments: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  likes: Scalars['Int']['output'];
  postDate: Scalars['DateTime']['output'];
  videoCaption: Scalars['String']['output'];
  videoDuration: Scalars['Int']['output'];
  videoId: Scalars['String']['output'];
  videoThumbnail?: Maybe<Scalars['String']['output']>;
  videoTitle: Scalars['String']['output'];
  views: Scalars['Int']['output'];
};

export type YoutubeAnalyticsTrendsPayloads = {
  __typename?: 'YoutubeAnalyticsTrendsPayloads';
  posts: Array<YoutubeAnalyticsTrendPayload>;
};

export type YoutubeCmsAsset = {
  __typename?: 'YoutubeCmsAsset';
  assetId: Scalars['String']['output'];
  assetName?: Maybe<Scalars['String']['output']>;
  belongMultipleChannels: Scalars['Boolean']['output'];
  channelId?: Maybe<Scalars['String']['output']>;
  channelName?: Maybe<Scalars['String']['output']>;
  cmsChannelId?: Maybe<Scalars['Int']['output']>;
  currency: Scalars['String']['output'];
  estPartnerRevenue?: Maybe<Scalars['Float']['output']>;
  estShareCreator?: Maybe<Scalars['Float']['output']>;
  id: Scalars['Int']['output'];
  isOwner: Scalars['Boolean']['output'];
  lastUpdatedDate?: Maybe<Scalars['Date']['output']>;
  shareRate?: Maybe<Scalars['Float']['output']>;
};

export type YoutubeCmsChannel = {
  __typename?: 'YoutubeCmsChannel';
  avatar?: Maybe<Scalars['String']['output']>;
  channelId: Scalars['String']['output'];
  channelName?: Maybe<Scalars['String']['output']>;
  currency: Scalars['String']['output'];
  estPartnerRevenue?: Maybe<Scalars['Float']['output']>;
  estShareCreator?: Maybe<Scalars['Float']['output']>;
  id: Scalars['Int']['output'];
  lastUpdatedDate?: Maybe<Scalars['Date']['output']>;
  shareRate?: Maybe<Scalars['Float']['output']>;
};

export type YoutubeCmsChannelDetail = {
  __typename?: 'YoutubeCmsChannelDetail';
  avatar?: Maybe<Scalars['String']['output']>;
  channelId: Scalars['String']['output'];
  channelName?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
};

export type YoutubeCmsChannelForInfluencer = {
  __typename?: 'YoutubeCmsChannelForInfluencer';
  currency: Scalars['String']['output'];
  id: Scalars['String']['output'];
  name?: Maybe<Scalars['String']['output']>;
  revenue: Scalars['Float']['output'];
  shareRate: Scalars['Float']['output'];
  thumbnail?: Maybe<Scalars['String']['output']>;
};

export type YoutubeCmsChannelInfo = {
  __typename?: 'YoutubeCmsChannelInfo';
  avatar?: Maybe<Scalars['String']['output']>;
  channelId: Scalars['String']['output'];
  channelName?: Maybe<Scalars['String']['output']>;
};

export type YoutubeCmsChannelSummary = {
  __typename?: 'YoutubeCmsChannelSummary';
  currency: Scalars['String']['output'];
  estPartnerRevenue: Scalars['Float']['output'];
  estShareCreator: Scalars['Float']['output'];
};

export type YoutubeCmsMusicForInfluencer = {
  __typename?: 'YoutubeCmsMusicForInfluencer';
  currency: Scalars['String']['output'];
  id: Scalars['String']['output'];
  name?: Maybe<Scalars['String']['output']>;
  revenue: Scalars['Float']['output'];
  shareRate: Scalars['Float']['output'];
};

export type YoutubeCmsVideo = {
  __typename?: 'YoutubeCmsVideo';
  currency: Scalars['String']['output'];
  id: Scalars['String']['output'];
  revenue: Scalars['Float']['output'];
  shareRate: Scalars['Float']['output'];
  thumbnail?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type YoutubeCompareAccountOverview = {
  __typename?: 'YoutubeCompareAccountOverview';
  accountId: Scalars['Int']['output'];
  graph: YoutubeCompareOverviewHistory;
  summary: YoutubeCompareOverviewSummary;
};

export type YoutubeCompareAccountPost = {
  __typename?: 'YoutubeCompareAccountPost';
  comments: Scalars['Int']['output'];
  duration: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  likes: Scalars['Int']['output'];
  thumbnail?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  views: Scalars['Int']['output'];
};

export type YoutubeCompareAccountPosts = {
  __typename?: 'YoutubeCompareAccountPosts';
  accountId: Scalars['Int']['output'];
  postHabit: Array<YoutubeAnalyticsPostsPostingHabit>;
  posts: Array<YoutubeCompareAccountPost>;
};

export type YoutubeCompareAccountPostsInDate = {
  __typename?: 'YoutubeCompareAccountPostsInDate';
  accountId: Scalars['Int']['output'];
  posts: Array<YoutubeComparePost>;
};

export type YoutubeCompareAccountTagRanking = {
  __typename?: 'YoutubeCompareAccountTagRanking';
  accountId: Scalars['Int']['output'];
  tagsRanking: Array<YoutubeTagRanking>;
};

export type YoutubeCompareAllPostsInDate = {
  __typename?: 'YoutubeCompareAllPostsInDate';
  compareAccounts: Array<YoutubeCompareAccountPostsInDate>;
  mainAccount: YoutubeAnalyticsAccountPostsInDate;
};

export type YoutubeCompareOverview = {
  __typename?: 'YoutubeCompareOverview';
  compareAccounts: Array<YoutubeCompareAccountOverview>;
  mainAccount: YoutubeCompareAccountOverview;
};

export type YoutubeCompareOverviewHistory = {
  __typename?: 'YoutubeCompareOverviewHistory';
  comments: Array<YoutubeAnalyticsHistoryDataPoint>;
  dislikes: Array<YoutubeAnalyticsHistoryDataPoint>;
  likes: Array<YoutubeAnalyticsHistoryDataPoint>;
  posts: Array<YoutubeAnalyticsHistoryDataPoint>;
  subscribers: Array<YoutubeAnalyticsHistoryDataPoint>;
  views: Array<YoutubeAnalyticsHistoryDataPoint>;
};

export type YoutubeCompareOverviewSummary = {
  __typename?: 'YoutubeCompareOverviewSummary';
  averageComments: YoutubeCompareOverviewSummaryData;
  averageLikes: YoutubeCompareOverviewSummaryData;
  averageViews: YoutubeCompareOverviewSummaryData;
  maximumLikes: YoutubeCompareOverviewSummaryData;
  maximumViews: YoutubeCompareOverviewSummaryData;
  totalPosts: YoutubeCompareOverviewSummaryData;
  totalSubscribers: YoutubeCompareOverviewSummaryData;
};

export type YoutubeCompareOverviewSummaryData = {
  __typename?: 'YoutubeCompareOverviewSummaryData';
  growth: Scalars['Int']['output'];
  percentage: Scalars['Float']['output'];
  total: Scalars['Int']['output'];
};

export type YoutubeComparePost = {
  __typename?: 'YoutubeComparePost';
  comments: Scalars['Int']['output'];
  dislikes: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  likes: Scalars['Int']['output'];
  postDate: Scalars['Date']['output'];
  subscribers: Scalars['Int']['output'];
  videoCaption: Scalars['String']['output'];
  videoComments: Array<YoutubeAnalyticsComparePostComment>;
  videoDuration: Scalars['Int']['output'];
  videoEmbed: Scalars['String']['output'];
  videoId: Scalars['String']['output'];
  videoTags: Array<Scalars['String']['output']>;
  videoThumbnail?: Maybe<Scalars['String']['output']>;
  videoTitle: Scalars['String']['output'];
  views: Scalars['Int']['output'];
};

export type YoutubeComparePostList = {
  __typename?: 'YoutubeComparePostList';
  accountId: Scalars['Int']['output'];
  posts: Array<YoutubeComparePostListItem>;
};

export type YoutubeComparePostListItem = {
  __typename?: 'YoutubeComparePostListItem';
  comments: Scalars['Int']['output'];
  dislikes: Scalars['Int']['output'];
  duration: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  likes: Scalars['Int']['output'];
  postDate: Scalars['Date']['output'];
  subscribers: Scalars['Int']['output'];
  thumbnail?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  url: Scalars['String']['output'];
  views: Scalars['Int']['output'];
};

export type YoutubeComparePosts = {
  __typename?: 'YoutubeComparePosts';
  compareAccounts: Array<YoutubeCompareAccountPosts>;
  mainAccount: YoutubeAnalyticsAccountPosts;
};

export type YoutubeCompareTagRanking = {
  __typename?: 'YoutubeCompareTagRanking';
  compareAccounts: Array<YoutubeCompareAccountTagRanking>;
  mainAccount: YoutubeAnalyticsAccountTagRanking;
};

export type YoutubeInfluencerSearchResultV3 = {
  __typename?: 'YoutubeInfluencerSearchResultV3';
  totalNumber: Scalars['Int']['output'];
  youtubeAccounts: Array<YoutubeInfluencerV3>;
};

export type YoutubeInfluencerV3 = {
  __typename?: 'YoutubeInfluencerV3';
  age?: Maybe<Scalars['Int']['output']>;
  avatar?: Maybe<Scalars['String']['output']>;
  averageLikes?: Maybe<Scalars['Float']['output']>;
  averageViews?: Maybe<Scalars['Float']['output']>;
  country: CountryName;
  gender: Genders;
  hasSimilarAccounts: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  isBrandAccount: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  popularPosts?: Maybe<Array<SocialAccountPopularPost>>;
  socialAccountEngagement?: Maybe<Scalars['Int']['output']>;
  socialAccountEngagementRate?: Maybe<Scalars['Float']['output']>;
  socialAccountFollowers?: Maybe<Scalars['Int']['output']>;
  socialAccountId: Scalars['ID']['output'];
  socialAccountStatus: SocialAccountStatus;
};

export type YoutubeMCNAdminRedirectUrlInput = {
  callbackUrl: Scalars['String']['input'];
};

export type YoutubeMCNAdminRedirectUrlPayload = {
  __typename?: 'YoutubeMCNAdminRedirectUrlPayload';
  redirectUri: Scalars['String']['output'];
};

export type YoutubeStatisticsData = {
  __typename?: 'YoutubeStatisticsData';
  growth: Scalars['Float']['output'];
  value: Scalars['Float']['output'];
};

export type YoutubeTagRanking = {
  __typename?: 'YoutubeTagRanking';
  rank: Scalars['Int']['output'];
  tag: Scalars['String']['output'];
  views: Scalars['Int']['output'];
};

/** An enumeration. */
export enum YoutubeTrafficSource {
  ADVERTISING = 'ADVERTISING',
  ANNOTATION = 'ANNOTATION',
  CAMPAIGN_CARD = 'CAMPAIGN_CARD',
  END_SCREEN = 'END_SCREEN',
  EXT_URL = 'EXT_URL',
  HASHTAGS = 'HASHTAGS',
  LIVE_REDIRECT = 'LIVE_REDIRECT',
  NOTIFICATION = 'NOTIFICATION',
  NO_LINK_EMBEDDED = 'NO_LINK_EMBEDDED',
  NO_LINK_OTHER = 'NO_LINK_OTHER',
  PLAYLIST = 'PLAYLIST',
  PRODUCT_PAGE = 'PRODUCT_PAGE',
  PROMOTED = 'PROMOTED',
  RELATED_VIDEO = 'RELATED_VIDEO',
  SHORTS = 'SHORTS',
  SOUND_PAGE = 'SOUND_PAGE',
  SUBSCRIBER = 'SUBSCRIBER',
  VIDEO_REMIXES = 'VIDEO_REMIXES',
  YT_CHANNEL = 'YT_CHANNEL',
  YT_OTHER_PAGE = 'YT_OTHER_PAGE',
  YT_PLAYLIST_PAGE = 'YT_PLAYLIST_PAGE',
  YT_SEARCH = 'YT_SEARCH',
}

export type _Service = {
  __typename?: '_Service';
  sdl: Scalars['String']['output'];
};

export type GetPublicInfluencerBioQueryVariables = Exact<{
  input: GetPublicInfluencerBioInput;
}>;

export type GetPublicInfluencerBioQuery = { __typename?: 'Query', getPublicInfluencerBio?: { __typename?: 'GetPublicInfluencerBioPayload', id: number, isPublic: boolean, themeV2?: BioThemeV2 | null, profile: { __typename?: 'BioInfluencerProfile', avatar?: string | null, description?: string | null, title?: string | null }, sections: Array<{ __typename?: 'PublicBioSection', id: number, sectionType: BioSectionType, image?: { __typename?: 'BioImage', image: string, linkUrl?: string | null } | null, link?: { __typename?: 'BioUrlLink', linkIcon?: BioLinkIcon | null, linkName: string, linkUrl: string } | null, socialMedia?: { __typename?: 'BioSocialMedia', linkName: string, linkUrl: string, socialAccountId?: string | null, socialAccountType?: SocialAccountType | null, socialMedia: BioSocialMediaType } | null, text?: { __typename?: 'BioText', text: string } | null }>, themes?: { __typename?: 'PublicBioTheme', button?: BioButtonStyle | null, font?: BioFontStyle | null, theme?: BioTheme | null, bg: { __typename?: 'PublicLinkBioBackground', bgColor?: string | null, media?: string | null }, color: { __typename?: 'PublicLinkBioPageColor', buttonColor?: string | null, buttonFontColor?: string | null, fontColor?: string | null } } | null } | null };

export const GetPublicInfluencerBioDocument = gql`
    query GetPublicInfluencerBio($input: GetPublicInfluencerBioInput!) {
  getPublicInfluencerBio(input: $input) {
    id
    isPublic
    profile {
      avatar
      description
      title
    }
    sections {
      id
      image {
        image
        linkUrl
      }
      link {
        linkIcon
        linkName
        linkUrl
      }
      sectionType
      socialMedia {
        linkName
        linkUrl
        socialAccountId
        socialAccountType
        socialMedia
      }
      text {
        text
      }
    }
    themeV2
    themes {
      bg {
        bgColor
        media
      }
      button
      color {
        buttonColor
        buttonFontColor
        fontColor
      }
      font
      theme
    }
  }
}
    `;

/**
 * __useGetPublicInfluencerBioQuery__
 *
 * To run a query within a React component, call `useGetPublicInfluencerBioQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPublicInfluencerBioQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPublicInfluencerBioQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetPublicInfluencerBioQuery(baseOptions: Apollo.QueryHookOptions<GetPublicInfluencerBioQuery, GetPublicInfluencerBioQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPublicInfluencerBioQuery, GetPublicInfluencerBioQueryVariables>(GetPublicInfluencerBioDocument, options);
}
export function useGetPublicInfluencerBioLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPublicInfluencerBioQuery, GetPublicInfluencerBioQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPublicInfluencerBioQuery, GetPublicInfluencerBioQueryVariables>(GetPublicInfluencerBioDocument, options);
}
export function useGetPublicInfluencerBioSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetPublicInfluencerBioQuery, GetPublicInfluencerBioQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetPublicInfluencerBioQuery, GetPublicInfluencerBioQueryVariables>(GetPublicInfluencerBioDocument, options);
}
export type GetPublicInfluencerBioQueryHookResult = ReturnType<typeof useGetPublicInfluencerBioQuery>;
export type GetPublicInfluencerBioLazyQueryHookResult = ReturnType<typeof useGetPublicInfluencerBioLazyQuery>;
export type GetPublicInfluencerBioSuspenseQueryHookResult = ReturnType<typeof useGetPublicInfluencerBioSuspenseQuery>;
export type GetPublicInfluencerBioQueryResult = Apollo.QueryResult<GetPublicInfluencerBioQuery, GetPublicInfluencerBioQueryVariables>;
