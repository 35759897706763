import themeBgVOne1 from '@src/assets/images/linkInBio/theme1Bg.png';
import themeBgVOne2 from '@src/assets/images/linkInBio/theme2Bg.png';
import themeBgVOne3 from '@src/assets/images/linkInBio/theme3Bg.png';
import themeBgVOne4 from '@src/assets/images/linkInBio/theme4Bg.png';
import themeBgVOne5 from '@src/assets/images/linkInBio/theme5Bg.png';
import themeBgVOne6 from '@src/assets/images/linkInBio/theme6Bg.png';
import themeBgVOne7 from '@src/assets/images/linkInBio/theme7Bg.png';
import themeBgVOne8 from '@src/assets/images/linkInBio/theme8Bg.png';
import themeBgVOne9 from '@src/assets/images/linkInBio/theme9Bg.png';
import themeBgVOne10 from '@src/assets/images/linkInBio/theme10Bg.png';
import themeBgVOne11 from '@src/assets/images/linkInBio/theme11Bg.png';
import themeBgVTwo1 from '@src/assets/images/linkInBio/themeBg2_1.png';
import themeBgVTwo4 from '@src/assets/images/linkInBio/themeBg2_4.png';
import themeBgVTwo5 from '@src/assets/images/linkInBio/themeBg2_5.png';
import themeBgVTwo6 from '@src/assets/images/linkInBio/themeBg2_6.png';
import themeBgVTwo7 from '@src/assets/images/linkInBio/themeBg2_7.png';
import themeBgVTwo8 from '@src/assets/images/linkInBio/themeBg2_8.png';
import themeBgVTwo9 from '@src/assets/images/linkInBio/themeBg2_9.png';
import themeBgVTwo10 from '@src/assets/images/linkInBio/themeBg2_10.png';
import themeBgVTwo11 from '@src/assets/images/linkInBio/themeBg2_11.png';
import themeBgVTwo12 from '@src/assets/images/linkInBio/themeBg2_12.png';
import themeBgVTwo13 from '@src/assets/images/linkInBio/themeBg2_13.png';
import themeBgVTwo14 from '@src/assets/images/linkInBio/themeBg2_14.png';
import themeBgVTwo15 from '@src/assets/images/linkInBio/themeBg2_15.png';
import themeBgVTwo16 from '@src/assets/images/linkInBio/themeBg2_16.png';
import themeBgVTwo17 from '@src/assets/images/linkInBio/themeBg2_17.png';
import themeMobileBgVOne1 from '@src/assets/images/linkInBio/theme1MobileBg.png';
import themeMobileBgVOne2 from '@src/assets/images/linkInBio/theme2MobileBg.png';
import themeMobileBgVOne3 from '@src/assets/images/linkInBio/theme3MobileBg.png';
import themeMobileBgVOne4 from '@src/assets/images/linkInBio/theme4MobileBg.png';
import themeMobileBgVOne5 from '@src/assets/images/linkInBio/theme5MobileBg.png';
import themeMobileBgVOne6 from '@src/assets/images/linkInBio/theme6MobileBg.png';
import themeMobileBgVOne7 from '@src/assets/images/linkInBio/theme7MobileBg.png';
import themeMobileBgVOne8 from '@src/assets/images/linkInBio/theme8MobileBg.png';
import themeMobileBgVOne9 from '@src/assets/images/linkInBio/theme9MobileBg.png';
import themeMobileBgVOne10 from '@src/assets/images/linkInBio/theme10MobileBg.png';
import themeMobileBgVOne11 from '@src/assets/images/linkInBio/theme11MobileBg.png';
import themeMobileBgVTwo1 from '@src/assets/images/linkInBio/themeMobileBg2_1.png';
import themeMobileBgVTwo4 from '@src/assets/images/linkInBio/themeMobileBg2_4.png';
import themeMobileBgVTwo5 from '@src/assets/images/linkInBio/themeMobileBg2_5.png';
import themeMobileBgVTwo6 from '@src/assets/images/linkInBio/themeMobileBg2_6.png';
import themeMobileBgVTwo7 from '@src/assets/images/linkInBio/themeMobileBg2_7.png';
import themeMobileBgVTwo8 from '@src/assets/images/linkInBio/themeMobileBg2_8.png';
import themeMobileBgVTwo9 from '@src/assets/images/linkInBio/themeMobileBg2_9.png';
import themeMobileBgVTwo10 from '@src/assets/images/linkInBio/themeMobileBg2_10.png';
import themeMobileBgVTwo11 from '@src/assets/images/linkInBio/themeMobileBg2_11.png';
import themeMobileBgVTwo12 from '@src/assets/images/linkInBio/themeMobileBg2_12.png';
import themeMobileBgVTwo13 from '@src/assets/images/linkInBio/themeMobileBg2_13.png';
import themeMobileBgVTwo14 from '@src/assets/images/linkInBio/themeMobileBg2_14.png';
import themeMobileBgVTwo15 from '@src/assets/images/linkInBio/themeMobileBg2_15.png';
import themeMobileBgVTwo16 from '@src/assets/images/linkInBio/themeMobileBg2_16.png';
import themeMobileBgVTwo17 from '@src/assets/images/linkInBio/themeMobileBg2_17.png';
import { BioButtonStyle, BioTheme, BioThemeV2 } from '@src/__generated__/globalTypes';

enum FontTypes {
  KAISEI_DECOL = 'KAISEI_DECOL',
  KOSUGI = 'KOSUGI',
  M_PLUS_1 = 'M_PLUS_1',
  NOTO_SANS_JP = 'NOTO_SANS_JP',
  NOTO_SERIF_JP = 'NOTO_SERIF_JP',
  PLUS_JAKARTA_SANS = 'PLUS_JAKARTA_SANS',
  POPPINS = 'POPPINS',
  POTTA_ONE = 'POTTA_ONE',
  SAWARABI_MINCHO = 'SAWARABI_MINCHO',
  SHIPPORI_MINCHO = 'SHIPPORI_MINCHO',
  SYNE = 'SYNE',
  TRAIN_ONE = 'TRAIN_ONE',
}

interface Theme {
  background: string;
  backgroundMobile: string;
  button: BioButtonStyle;
  colors: {
    buttonColor: string;
    buttonFontColor: string;
    fontColor: string;
  };
  font: FontTypes;
  image?: string;
  value: BioTheme | BioThemeV2;
}

export const buttonOptions = [
  { borderRadius: 40, value: BioButtonStyle.WHITE_FULL_ROUNDED_EDGE_BUTTON },
  { borderRadius: 5, value: BioButtonStyle.WHITE_ROUNDED_EDGE_BUTTON },
  { borderRadius: 0, value: BioButtonStyle.WHITE_SHARP_EDGE_BUTTON },
  { borderRadius: 40, value: BioButtonStyle.BLACK_FULL_ROUNDED_EDGE_BUTTON },
  { borderRadius: 5, value: BioButtonStyle.BLACK_ROUNDED_EDGE_BUTTON },
  { borderRadius: 0, value: BioButtonStyle.BLACK_SHARP_EDGE_BUTTON },
];

export const fontOptions = [
  { fontFamily: 'Kaisel Decol', value: FontTypes.KAISEI_DECOL },
  { fontFamily: 'Kosugi', value: FontTypes.KOSUGI },
  { fontFamily: 'M PLUS 1', value: FontTypes.M_PLUS_1 },
  { fontFamily: 'Noto Sans JP', value: FontTypes.NOTO_SANS_JP },
  { fontFamily: 'Noto Serif JP', value: FontTypes.NOTO_SERIF_JP },
  { fontFamily: 'Plus Jakarta Sans', value: FontTypes.PLUS_JAKARTA_SANS },
  { fontFamily: 'Poppins', value: FontTypes.POPPINS },
  { fontFamily: 'Potta One', value: FontTypes.POTTA_ONE },
  { fontFamily: 'Sawarabi Mincho', value: FontTypes.SAWARABI_MINCHO },
  { fontFamily: 'Shippori Mincho', value: FontTypes.SHIPPORI_MINCHO },
  { fontFamily: 'Syne', value: FontTypes.SYNE },
  { fontFamily: 'Train One', value: FontTypes.TRAIN_ONE },
];

export const themeOptions: Theme[] = [
  {
    background: themeBgVOne1,
    backgroundMobile: themeMobileBgVOne1,
    button: BioButtonStyle.BLACK_FULL_ROUNDED_EDGE_BUTTON,
    colors: {
      buttonColor: '#E89AB2',
      buttonFontColor: '#FFFFFF',
      fontColor: '#FFFFFF',
    },
    font: FontTypes.POTTA_ONE,
    value: BioTheme.THEME_1,
  },
  {
    background: themeBgVOne2,
    backgroundMobile: themeMobileBgVOne2,
    button: BioButtonStyle.BLACK_FULL_ROUNDED_EDGE_BUTTON,
    colors: {
      buttonColor: '#FFFFFF',
      buttonFontColor: '#ECC1F5',
      fontColor: '#FFFFFF',
    },
    font: FontTypes.KAISEI_DECOL,
    value: BioTheme.THEME_2,
  },
  {
    background: themeBgVOne3,
    backgroundMobile: themeMobileBgVOne3,
    button: BioButtonStyle.BLACK_ROUNDED_EDGE_BUTTON,
    colors: {
      buttonColor: '#845D99',
      buttonFontColor: '#E0D7E6',
      fontColor: '#845D99',
    },
    font: FontTypes.KAISEI_DECOL,
    value: BioTheme.THEME_3,
  },
  {
    background: themeBgVOne4,
    backgroundMobile: themeMobileBgVOne4,
    button: BioButtonStyle.BLACK_FULL_ROUNDED_EDGE_BUTTON,
    colors: {
      buttonColor: '#40341D',
      buttonFontColor: '#FFFFFF',
      fontColor: '#392807',
    },
    font: FontTypes.TRAIN_ONE,
    value: BioTheme.THEME_4,
  },
  {
    background: themeBgVOne5,
    backgroundMobile: themeMobileBgVOne5,
    button: BioButtonStyle.BLACK_SHARP_EDGE_BUTTON,
    colors: {
      buttonColor: '#27313B',
      buttonFontColor: '#ECEAE7',
      fontColor: '#27313B',
    },
    font: FontTypes.KAISEI_DECOL,
    value: BioTheme.THEME_5,
  },
  {
    background: themeBgVOne6,
    backgroundMobile: themeMobileBgVOne6,
    button: BioButtonStyle.BLACK_FULL_ROUNDED_EDGE_BUTTON,
    colors: {
      buttonColor: '#58A4EA',
      buttonFontColor: '#FFFFFF',
      fontColor: '#ECBE1D',
    },
    font: FontTypes.KOSUGI,
    value: BioTheme.THEME_6,
  },
  {
    background: themeBgVOne7,
    backgroundMobile: themeMobileBgVOne7,
    button: BioButtonStyle.BLACK_FULL_ROUNDED_EDGE_BUTTON,
    colors: {
      buttonColor: '#3B4D5F',
      buttonFontColor: '#FFFFFF',
      fontColor: '#FFFFFF',
    },
    font: FontTypes.M_PLUS_1,
    value: BioTheme.THEME_7,
  },
  {
    background: themeBgVOne8,
    backgroundMobile: themeMobileBgVOne8,
    button: BioButtonStyle.WHITE_SHARP_EDGE_BUTTON,
    colors: {
      buttonColor: '#FFFFFF',
      buttonFontColor: '#FFFFFF',
      fontColor: '#FFFFFF',
    },
    font: FontTypes.M_PLUS_1,
    value: BioTheme.THEME_8,
  },
  {
    background: themeBgVOne9,
    backgroundMobile: themeMobileBgVOne9,
    button: BioButtonStyle.BLACK_FULL_ROUNDED_EDGE_BUTTON,
    colors: {
      buttonColor: '#FFFFFF',
      buttonFontColor: '#CFA3F1',
      fontColor: '#FFFFFF',
    },
    font: FontTypes.M_PLUS_1,
    value: BioTheme.THEME_9,
  },
  {
    background: themeBgVOne10,
    backgroundMobile: themeMobileBgVOne10,
    button: BioButtonStyle.BLACK_FULL_ROUNDED_EDGE_BUTTON,
    colors: {
      buttonColor: '#9EE5A2',
      buttonFontColor: '#126128',
      fontColor: '#126128',
    },
    font: FontTypes.M_PLUS_1,
    value: BioTheme.THEME_10,
  },
  {
    background: themeBgVOne11,
    backgroundMobile: themeMobileBgVOne11,
    button: BioButtonStyle.BLACK_SHARP_EDGE_BUTTON,
    colors: {
      buttonColor: '#FFA95C',
      buttonFontColor: '#FFFFFF',
      fontColor: '#D95105',
    },
    font: FontTypes.POTTA_ONE,
    value: BioTheme.THEME_11,
  },
];

export const themeV2Options: Theme[] = [
  {
    background: themeBgVTwo1,
    backgroundMobile: themeMobileBgVTwo1,
    button: BioButtonStyle.BLACK_ROUNDED_EDGE_BUTTON,
    colors: {
      buttonColor: '#E8EEF3',
      buttonFontColor: '#000000',
      fontColor: '#27313B',
    },
    font: FontTypes.PLUS_JAKARTA_SANS,
    value: BioThemeV2.THEME2_1,
  },
  {
    background: themeBgVTwo1,
    backgroundMobile: themeMobileBgVTwo1,
    button: BioButtonStyle.BLACK_ROUNDED_EDGE_BUTTON,
    colors: {
      buttonColor: '#6FFDC1',
      buttonFontColor: '#424242',
      fontColor: '#27313B',
    },
    font: FontTypes.PLUS_JAKARTA_SANS,
    value: BioThemeV2.THEME2_2,
  },
  {
    background: themeBgVTwo1,
    backgroundMobile: themeMobileBgVTwo1,
    button: BioButtonStyle.BLACK_ROUNDED_EDGE_BUTTON,
    colors: {
      buttonColor: '#C76FFD',
      buttonFontColor: '#000000',
      fontColor: '#27313B',
    },
    font: FontTypes.PLUS_JAKARTA_SANS,
    value: BioThemeV2.THEME2_3,
  },
  {
    background: themeBgVTwo4,
    backgroundMobile: themeMobileBgVTwo4,
    button: BioButtonStyle.BLACK_ROUNDED_EDGE_BUTTON,
    colors: {
      buttonColor: '#777676',
      buttonFontColor: '#FFFFFF',
      fontColor: '#FFFFFF',
    },
    font: FontTypes.PLUS_JAKARTA_SANS,
    value: BioThemeV2.THEME2_4,
  },
  {
    background: themeBgVTwo5,
    backgroundMobile: themeMobileBgVTwo5,
    button: BioButtonStyle.BLACK_ROUNDED_EDGE_BUTTON,
    colors: {
      buttonColor: '#E5B3B5',
      buttonFontColor: '#FFFFFF',
      fontColor: '#D89396',
    },
    font: FontTypes.POPPINS,
    value: BioThemeV2.THEME2_5,
  },
  {
    background: themeBgVTwo6,
    backgroundMobile: themeMobileBgVTwo6,
    button: BioButtonStyle.BLACK_ROUNDED_EDGE_BUTTON,
    colors: {
      buttonColor: '#D5C6B3',
      buttonFontColor: '#FFFFFF',
      fontColor: '#AA8F6E',
    },
    font: FontTypes.POPPINS,
    value: BioThemeV2.THEME2_6,
  },
  {
    background: themeBgVTwo7,
    backgroundMobile: themeMobileBgVTwo7,
    button: BioButtonStyle.WHITE_FULL_ROUNDED_EDGE_BUTTON,
    colors: {
      buttonColor: '#FFFFFF',
      buttonFontColor: '#FFFFFF',
      fontColor: '#FFFFFF',
    },
    font: FontTypes.POPPINS,
    value: BioThemeV2.THEME2_7,
  },
  {
    background: themeBgVTwo8,
    backgroundMobile: themeMobileBgVTwo8,
    button: BioButtonStyle.BLACK_FULL_ROUNDED_EDGE_BUTTON,
    colors: {
      buttonColor: '#FFA95C',
      buttonFontColor: '#FFFFFF',
      fontColor: '#D95105',
    },
    font: FontTypes.M_PLUS_1,
    value: BioThemeV2.THEME2_8,
  },
  {
    background: themeBgVTwo9,
    backgroundMobile: themeMobileBgVTwo9,
    button: BioButtonStyle.BLACK_FULL_ROUNDED_EDGE_BUTTON,
    colors: {
      buttonColor: '#9EE5A2',
      buttonFontColor: '#126128',
      fontColor: '#126128',
    },
    font: FontTypes.M_PLUS_1,
    value: BioThemeV2.THEME2_9,
  },
  {
    background: themeBgVTwo10,
    backgroundMobile: themeMobileBgVTwo10,
    button: BioButtonStyle.BLACK_FULL_ROUNDED_EDGE_BUTTON,
    colors: {
      buttonColor: '#FFB7C4',
      buttonFontColor: '#FFFFFF',
      fontColor: '#4F9BAB',
    },
    font: FontTypes.POPPINS,
    value: BioThemeV2.THEME2_10,
  },
  {
    background: themeBgVTwo11,
    backgroundMobile: themeMobileBgVTwo11,
    button: BioButtonStyle.BLACK_FULL_ROUNDED_EDGE_BUTTON,
    colors: {
      buttonColor: '#ECFF0F',
      buttonFontColor: '#539DA8',
      fontColor: '#ECFF0F',
    },
    font: FontTypes.POPPINS,
    value: BioThemeV2.THEME2_11,
  },
  {
    background: themeBgVTwo12,
    backgroundMobile: themeMobileBgVTwo12,
    button: BioButtonStyle.BLACK_SHARP_EDGE_BUTTON,
    colors: {
      buttonColor: '#000000',
      buttonFontColor: '#FFFFFF',
      fontColor: '#030303',
    },
    font: FontTypes.SYNE,
    value: BioThemeV2.THEME2_12,
  },
  {
    background: themeBgVTwo13,
    backgroundMobile: themeMobileBgVTwo13,
    button: BioButtonStyle.BLACK_ROUNDED_EDGE_BUTTON,
    colors: {
      buttonColor: '#645850',
      buttonFontColor: '#F1F0ED',
      fontColor: '#645850',
    },
    font: FontTypes.SYNE,
    value: BioThemeV2.THEME2_13,
  },
  {
    background: themeBgVTwo14,
    backgroundMobile: themeMobileBgVTwo14,
    button: BioButtonStyle.BLACK_FULL_ROUNDED_EDGE_BUTTON,
    colors: {
      buttonColor: '#FFFFFF',
      buttonFontColor: '#40341D',
      fontColor: '#40341D',
    },
    font: FontTypes.TRAIN_ONE,
    value: BioThemeV2.THEME2_14,
  },
  {
    background: themeBgVTwo15,
    backgroundMobile: themeMobileBgVTwo15,
    button: BioButtonStyle.BLACK_FULL_ROUNDED_EDGE_BUTTON,
    colors: {
      buttonColor: '#58A4EA',
      buttonFontColor: '#FFFFFF',
      fontColor: '#ECBE1D',
    },
    font: FontTypes.KOSUGI,
    value: BioThemeV2.THEME2_15,
  },
  {
    background: themeBgVTwo16,
    backgroundMobile: themeMobileBgVTwo16,
    button: BioButtonStyle.BLACK_FULL_ROUNDED_EDGE_BUTTON,
    colors: {
      buttonColor: '#FFFFFF',
      buttonFontColor: '#ECC1F5',
      fontColor: '#FFFFFF',
    },
    font: FontTypes.POPPINS,
    value: BioThemeV2.THEME2_16,
  },
  {
    background: themeBgVTwo17,
    backgroundMobile: themeMobileBgVTwo17,
    button: BioButtonStyle.BLACK_FULL_ROUNDED_EDGE_BUTTON,
    colors: {
      buttonColor: '#E89AB2',
      buttonFontColor: '#FFFFFF',
      fontColor: '#FFFFFF',
    },
    font: FontTypes.POTTA_ONE,
    value: BioThemeV2.THEME2_17,
  },
];
