import React, { ReactElement } from 'react';
import {
  ApolloClient, ApolloProvider, InMemoryCache,
} from '@apollo/client';

interface ApolloConfigProps {
  children: ReactElement | Array<ReactElement>
}

const client = new ApolloClient({
  cache: new InMemoryCache(),
  uri: process.env.REACT_APP_API_URL,
});

function ApolloConfig({ children }: ApolloConfigProps) {
  return (
    <ApolloProvider client={client}>
      {children}
    </ApolloProvider>
  );
}

export default ApolloConfig;
