import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { useTranslation } from 'react-i18next';
import { createBrowserRouter, Outlet, RouterProvider } from 'react-router-dom';
import ApolloProvider from './ApolloConfig';
import Notification from './components/organisms/Notification';
import './libs/i18n';
import * as Pages from './pages';
import { ROUTES } from './shares/routes';

const App = () => {
  const { t } = useTranslation();
  const routes = [
    { path: ROUTES.LINK_IN_BIO_USERNAME, element: <Pages.LinkInBio /> },
    { path: '*', element: <Pages.PageNotFound /> },
  ];
  const router = createBrowserRouter([{ element: <Outlet />, children: routes }]);

  useEffect(() => {
    document.title = t('My Link');
    const gaTrackingCode = process.env.REACT_APP_GA_TRACKING_CODE;
    if (gaTrackingCode) {
      const { href, pathname, search } = window.location;
      ReactGA.initialize(gaTrackingCode);
      ReactGA.send({ hitType: 'pageview', page: pathname + search });
      ReactGA.event('session_start', {
        page_location: href,
      });
    }
  }, []);

  return (
    <ApolloProvider>
      <Notification />
      <RouterProvider router={router} />
    </ApolloProvider>
  );
};

export default App;
